import React, { useEffect, useState } from "react";
import { Badge, Button, Collapse, Divider, Menu, Popover, Tooltip } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import MarkAsReadButton from "./MarkAsReadButton";
import { customMenuItemStyle, customMenuStyle } from "../../constant";
const { Panel } = Collapse;

interface FilterSelectProps {
  queryBy: {
    readStatus: string;
    section: string;
  };
  setQueryBy: (value: { readStatus: string; section: string }) => void;
}

const FilterSelect: React.FC<FilterSelectProps> = ({ queryBy, setQueryBy }) => {
  const [visible, setVisible] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState({
    readStatus: queryBy.readStatus,
    section: queryBy.section,
  });

  // Sync selectedKeys with queryBy whenever queryBy changes
  useEffect(() => {
    setSelectedKeys({
      readStatus: queryBy.readStatus,
      section: queryBy.section,
    });
  }, [queryBy]);

  const handleMenuClick =
    (panelKey: "readStatus" | "section") => (e: { key: string }) => {
      const selectedKey = e.key;
      const isDeselect = selectedKeys[panelKey] === selectedKey;

      const updatedKeys = {
        ...selectedKeys,
        [panelKey]: isDeselect ? "" : selectedKey,
      };
      setSelectedKeys(updatedKeys);
      setQueryBy(updatedKeys);
    };

  const clearAll = () => {
    const clearedKeys = { readStatus: "", section: "" };
    setSelectedKeys(clearedKeys);
    setQueryBy(clearedKeys);
    setVisible(false);
  };

  const getBadgeCount = () => {
    let count = 0;
    if (selectedKeys.readStatus && selectedKeys.readStatus !== "all") count++;
    if (selectedKeys.section) count++;
    return count;
  };

  const title = (
    <div className="h-full w-full">
      <div className="flex justify-between items-center pl-3">
        <div>Filter</div>
        <Button type="link" onClick={clearAll} style={{ color: "#4B48A5" }}>
          Clear All
        </Button>
      </div>
      <Divider style={{ margin: "8px 0" }} />
    </div>
  );

  const customPanelStyle = {
    backgroundColor: "#fff",
    borderRadius: 4,
    border: 0,
    overflow: "hidden",
  };


  const content = (
    <div
      className="flex flex-col"
      style={{ width: 300, minHeight: 100, overflowY: "auto", margin: "0" }}
    >
      <Collapse
        accordion
        bordered={false}
        defaultActiveKey={selectedKeys.readStatus}
        expandIconPosition="left"
      >
        <Panel
          header={
            <span>
              Read/Unread
              {selectedKeys.readStatus && selectedKeys.readStatus !== "all" && (
                <Badge
                  count={1}
                  style={{ marginLeft: 8, backgroundColor: "#4B48A5" }}
                />
              )}
            </span>
          }
          key="1"
          style={customPanelStyle}
        >
          <Menu
            onClick={handleMenuClick("readStatus")}
            selectedKeys={[selectedKeys.readStatus]}
            style={customMenuStyle}
          >
            <Menu.Item key="read" style={customMenuItemStyle}>
              Read
            </Menu.Item>
            <Menu.Item key="unread" style={customMenuItemStyle}>
              Unread
            </Menu.Item>
          </Menu>
        </Panel>

        <Panel
          header={
            <span>
              Section
              {selectedKeys.section && (
                <Badge
                  count={1}
                  style={{ marginLeft: 8, backgroundColor: "#4B48A5" }}
                />
              )}
            </span>
          }
          key="2"
          style={customPanelStyle}
        >
          <Menu
            onClick={handleMenuClick("section")}
            selectedKeys={[selectedKeys.section]}
            style={customMenuStyle}
          >
            <Menu.Item key="ats" style={customMenuItemStyle}>
              ATS
            </Menu.Item>
            <Menu.Item key="review" style={customMenuItemStyle}>
              Review
            </Menu.Item>
            <Menu.Item key="Talent pool" style={customMenuItemStyle}>
              Talent pool
            </Menu.Item>
            <Menu.Item key="calendar" style={customMenuItemStyle}>
              Calendar
            </Menu.Item>
            <Menu.Item key="inbox" style={customMenuItemStyle}>
              Inbox
            </Menu.Item>
          </Menu>
        </Panel>
      </Collapse>
    </div>
  );

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {selectedKeys.readStatus === "unread" && <MarkAsReadButton />}
      <Popover
        title={title}
        content={content}
        trigger="click"
        visible={visible}
        onVisibleChange={setVisible}
        style={{ margin: 0, padding: 0 }}
        placement="bottomRight"
      >
        <Tooltip title="Apply filter">
          <Button
            type="default"
            icon={<FilterOutlined />}
            onClick={() => setVisible(!visible)}
            size="small"
            style={{minWidth:'28px',height:'28px'}}
          >
            {getBadgeCount() > 0 && (
              <Badge
                count={getBadgeCount()}
                style={{ backgroundColor: "#4B48A5"}}
              />
            )}
          </Button>
        </Tooltip>
      </Popover>
    </div>
  );
};

export default FilterSelect;
