export const hexToRGBA = (hexCode: string, opacity: number) => {
    // Remove '#' if present
    hexCode = hexCode.replace('#', '')

    // Convert to RGBA
    var red = parseInt(hexCode.substring(0, 2), 16)
    var green = parseInt(hexCode.substring(2, 4), 16)
    var blue = parseInt(hexCode.substring(4, 6), 16)
    var alpha = opacity !== undefined ? opacity : 1.0 // Default to fully opaque if opacity is not provided

    return "rgb(" + red + ", " + green + ", " + blue + "," + alpha + " )"
}