import React, { useRef, useState } from "react";
import { usePopper } from "react-popper";
import { connect } from "react-redux";
import CloseIcon from "../../../assets/Close";
import { attachBulkLabelsToCandidates } from "../../../lib/api";
import { deleteLabelFromCandidateReq } from "../../../lib/apiReq";
import { InitLabel, Scope } from "../../../type/type.d";
import Label from "../../boardPanel/label/Label";
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";
import { Overlay } from "../../hook/Overlay";
import AddLabelPopup from "./AddLabelPopup";
import { Button } from "antd"
import { AxiosError } from "axios"
import { throwSuccess } from "../../../service/throwError";
type AddLabelProps = {
  applicationId: string;
  selectedItems: string[];
  candidateLabels: InitLabel;
  close: () => void;
  clientId: string;
};
const AddLabel = (props: AddLabelProps) => {
  let refer = useRef(null);
  const [loading, setLoading] = useState(false);
  const [labelName, setLabelName] = useState<string>("");
  const [labels, selectedLabel] = useState<string[]>([]);
  const [open, setOpen] = useState(false);
  let [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>();
  let [popperElement, setPoperElement] = useState<HTMLDivElement | null>();
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "top-start",
    modifiers: [
      {
        name: "flip",
        options: {
          fallbackPlacements: ["top", "right"],
        },
      },
    ],
  });
  const validateLabelName = (e: any) => {
    setLabelName(e.target.value);
    if (!e.target.value) setOpen(false);
    else setOpen(true);
  };
  const addLabels = (l: string) => {
    // addLabelToCDB()
    selectedLabel([...labels, l]);
  };
  
  const addLabelToCandidate = () => {
    if (labels.length > 0) {
      setLoading(true)
      let req: deleteLabelFromCandidateReq = {
        candidate_ids: props.selectedItems,
        label_ids: labels,
        socket_client_id: props.clientId,
      }
      attachBulkLabelsToCandidates(req).then((res) => {
        if (res) {
          setLoading(false)
          selectedLabel([])
          throwSuccess("Labels attached successfully.")
        }
      }).catch((reason: AxiosError) => {
        if (reason)
          setLoading(false)
      });
    }
  };
  const clearSearchField = () => {
    setLabelName("");
    referenceElement?.focus();
  };

  return (
    <>
      <div>
        <div className="justify-between flex">
          <div className="m-1">
            <div className="text-sm mb-1 font-medium">
              {capitalizeFirstLetter("Add labels")}
            </div>
            <input
              type="text"
              className={`px-2 w-72 placeholder-italic rounded outline-none py-2 inputCSS text-sm mt-1`}
              placeholder={"search "}
              onChange={validateLabelName}
              ref={setReferenceElement}
              // ref={inputRef}
              value={labelName}
              // onBlur={() => inputRef.current?.blur()}
            />
          </div>

          <div className="flex justify-center items-start h-8 pt-1  w-8 hover:bg-gray-200 rounded-default">
            <button tabIndex={0} className="p-1" onClick={props.close}>
              <CloseIcon />
            </button>
          </div>
        </div>
        {open ? (
          <Overlay onClose={() => setOpen(!open)} scope={Scope.Popover}>
            <div
              ref={setPoperElement}
              style={styles.popper}
              {...attributes.popper}
            >
              <AddLabelPopup
                click={(e: boolean) => {
                  setOpen(e);
                }}
                refer={refer}
                searchLabelChar={labelName}
                selectedLabel={(l: any) => addLabels(l)}
                clearSearchField={clearSearchField}
              />
            </div>
          </Overlay>
        ) : null}{" "}
      </div>
      {labels.length > 0 && (
        <div className=" h-full max-h-full m-2 overflow-y-scroll ">
          <div className="flex flex-wrap ">
            {labels.map(
              (l: string) =>
                l && (
                  <>
                    <Label
                      color={props.candidateLabels[l].color}
                      name={props.candidateLabels[l].name}
                      flag={true}
                      click={() => {}}
                    />
                  </>
                )
            )}
          </div>
        </div>
      )}

      <div className="justify-end flex items-end">
        <Button type="primary" onClick={addLabelToCandidate} loading={loading} >
          {capitalizeFirstLetter("Add label")}
        </Button>
      </div>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  members: state.opening.members,
  candidateLabels: state.opening.candidateLabels,
  clientId: state.opening.clientId,
});
export default connect(mapStateToProps)(AddLabel);
