import React from "react";
import {  Layout } from "antd";

const { Content } = Layout;

interface LoadingProps {
  message?: string;
  loaderIcon: React.ReactNode;
  content: React.ReactNode;
  footer: React.ReactNode;
}

const PageLoader: React.FC<LoadingProps> = ({ message, loaderIcon, content, footer }) => {
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Content className="flex flex-col space-y-6" style={{ padding: "50px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
        <div>{loaderIcon}</div>
        <div>{message}</div>
        <div>{content}</div>
        <div style={{ position: "absolute", bottom: "20px" }}>{footer}</div>
      </Content>
    </Layout>
  );
};

export default PageLoader;
