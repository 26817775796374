// Note:
// - This migration script starts with version 1. Any future migrations will be conducted under this version (1) until a new version (2) is introduced.
// - Once a new version (2) is introduced, corresponding migration functions should be added to handle state transformations for the new version.
// - The purpose of migrations is to ensure that new keys and values are added to the state correctly without disrupting the existing state structure.


import { applicantStatus, InitOrgTotalBoard, initSources, jobPlatform } from "./reducers/initialState"
import { RootState } from "./reducers/root"

export const migrations = {
    1: (state: RootState) => {
        return {
            ...state,
            candidateSources: initSources,
            jobPlatform: jobPlatform,
            applicantStatus: applicantStatus,
        }
    },
    2: (state: RootState) => {
        return {
            ...state,
        totalBoards:InitOrgTotalBoard,
        }
    },
}


