import { addAttachmentToMail } from "../../../lib/api";
import { Attachment } from "../../../type/type.d";
import { createFormData } from "../../../utils/createFormData";

export const uploadFile = (
  file: any, 
  props: any, 
  uploadedFiles: any[], 
  setUploadedFiles: (files: any[]) => void, 
  setUploadedAttachments: (attachments: Attachment[]) => void, 
  uploadedAttchments: Attachment[]
) => {
  const files: any = [];
  const formData = createFormData(file, props.entity);
  formData.append("socket_client_id", props.clientId);
  for (let i = 0; i < file.target.files.length; i++) {
    formData.append(`file`, file.target.files[i]);
    const fileData = {
      name: file.target.files[i].name,
      progress: 0,
    };
    files.push(fileData);
  }

  setUploadedFiles(uploadedFiles.concat(files));

  const onUploadProgress = (progressEvent: any) => {
    const progress = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );
    const updatedFiles = [...files];
    updatedFiles.forEach((fileData) => {
      fileData.progress = progress;
    });
    setUploadedFiles(uploadedFiles.concat(files));
  };

  addAttachmentToMail(formData, onUploadProgress).then((res: any) => {
    setUploadedAttachments([...uploadedAttchments, ...res]);
  });
};
