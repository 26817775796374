import React from "react";
import { connect } from "react-redux";
import { InitLabel, Label } from "../../../type/type";
import ShowLabelPreview from "../../card/cardLabel/labelPreview/ShowLabelPreview";
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";
type AddLabelCDBProps = {
  click: (searchInput: string) => void;
  change?: (e: any) => void;
  update: (labelID: any) => void;
  createLabel?: () => void;
  flag?: boolean;
  close: (e: boolean) => void;
  candidateLabels: InitLabel;
  searchLabelChar: string;
  selectedLabel: (label: any) => void;
};
const AddLabelCDB = (props: AddLabelCDBProps) => {
  let candidateLabels = Object.values(props.candidateLabels);
  candidateLabels = candidateLabels.filter((label: Label) => {
    return label.name
      .toUpperCase()
      .includes(props.searchLabelChar.toUpperCase());
  });

  /* here check if key press is tab and focus is at last then close the popup and if key is enter then allow to create label  */
  const setFocusToParent = (e: any) => {
    if (e.key == "Tab") {
      document.getElementById("card-input")?.focus();
      props.close(false);
    } else {
      if (e.key == 13 || e.button == 0) {
        props.click(props.searchLabelChar);
      }
    }
  };

  return (
    <React.Fragment>
      <div className="flex flex-col">
        {candidateLabels
          ? candidateLabels.map(
            (label: Label) =>
                label && (
                <ShowLabelPreview
                  key={label.id}
                    clickOnLabel={(label) => props.selectedLabel(label.id)}
                    label={label}
                    updateLabel={(label) => props.update(label)}
                  />
                )
            )
          : null}
        <button
          className="px-4 py-1.5 flex  bg-gray-300 bg-opacity-25 hover:bg-gray-300 hover:bg-opacity-40 mb-2 mx-2  rounded text-sm font-light"
          onClick={() => props.click(props.searchLabelChar)}
          data-testid="label-button"
          tabIndex={0}
          onKeyDown={(e: any) => setFocusToParent(e)}
        >
          {capitalizeFirstLetter("create a new label")}
        </button>
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (state: any) => ({
  candidateLabels: state.opening.candidateLabels,
});
export default connect(mapStateToProps)(AddLabelCDB);
