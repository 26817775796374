import React, { useEffect, useState } from "react";
import { Select } from "antd";

const { Option } = Select;

type dropDownProps = {
  option: string[];
  name: string;
  setValue: (option: string) => void;
  selectedValue: string;
  width?: string;
};

const EventDuration = (props: dropDownProps) => {
  const [custom, setCustom] = useState(false);

  useEffect(() => {
    props.selectedValue === "custom..." ? setCustom(true) : setCustom(false);
  }, [props.selectedValue]);

  const handleSelectChange = (value: string) => {
    if (value === "custom...") {
      setCustom(true);
    } else {
      props.setValue(value);
    }
  };

  return (
    <Select
      className={` ${props.width && props.width}`}
      value={props.selectedValue}
      onChange={handleSelectChange}
      dropdownRender={menu => (
        <div role="button" tabIndex={0}
          onMouseDown={e => {
            e.preventDefault(); // Prevent blur event when clicking on scrollbar
            e.stopPropagation();
          }}
        >
          {menu}
        </div>
      )}
    >
      {props.option.map((option: string, i: number) => (
        <Option key={i} value={option}>
          {option}
        </Option>
      ))}
      {custom && (
        <Option value="custom...">Custom</Option>
      )}
    </Select>
  );
};

export default EventDuration;
