import React from "react";
const AttachmentIcon = (props: any) => {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Attachment</title>
      <g
        id="Icons-/-16x16-/-Attachment-"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M3.5,4 C3.77614237,4 4,4.22385763 4,4.5 C4,4.77614237 3.77614237,5 3.5,5 L3.5,5 L2,5 C1.44771525,5 1,5.44771525 1,6 L1,6 L1,14 C1,14.5522847 1.44771525,15 2,15 L2,15 L14,15 C14.5522847,15 15,14.5522847 15,14 L15,14 L15,4.5 C15,4.22385763 15.2238576,4 15.5,4 C15.7761424,4 16,4.22385763 16,4.5 L16,4.5 L16,14 C16,15.1045695 15.1045695,16 14,16 L14,16 L2,16 C0.8954305,16 1.3527075e-16,15.1045695 0,14 L0,14 L0,6 C-1.3527075e-16,4.8954305 0.8954305,4 2,4 L2,4 Z M7.46891109,2.15192379 C8.57348059,0.238753295 11.0198422,-0.416747326 12.9330127,0.687822174 L12.9330127,0.687822174 L15.5310889,2.18782217 L15.0310889,3.05384758 L12.4330127,1.55384758 C10.9981348,0.725420453 9.16336362,1.21704592 8.33493649,2.65192379 L8.33493649,2.65192379 L5.08493649,8.28108891 C4.39458055,9.47682047 4.80426844,11.0057965 6,11.6961524 C7.19573156,12.3865084 8.72470757,11.9768205 9.41506351,10.7810889 L9.41506351,10.7810889 L12.4150635,5.58493649 C12.8292771,4.86749756 12.5834643,3.95011195 11.8660254,3.53589838 C11.1485865,3.12168482 10.2312009,3.36749756 9.8169873,4.08493649 L9.8169873,4.08493649 L6.8169873,9.28108891 C6.67891611,9.52023522 6.76085369,9.82603043 7,9.96410162 C7.23914631,10.1021728 7.54494151,10.0202352 7.6830127,9.78108891 L7.6830127,9.78108891 L10.6830127,4.58493649 C10.8210839,4.34579018 11.1268791,4.2638526 11.3660254,4.40192379 C11.6051717,4.53999498 11.6871093,4.84579018 11.5490381,5.08493649 L11.5490381,5.08493649 L8.54903811,10.2810889 C8.13482454,10.9985278 7.21743894,11.2443406 6.5,10.830127 C5.78256106,10.4159135 5.53674833,9.49852785 5.95096189,8.78108891 L5.95096189,8.78108891 L8.95096189,3.58493649 C9.64131783,2.38920493 11.1702938,1.97951704 12.3660254,2.66987298 C13.561757,3.36022892 13.9714449,4.88920493 13.2810889,6.08493649 L13.2810889,6.08493649 L10.2810889,11.2810889 C9.3145906,12.9551131 7.17402418,13.5286761 5.5,12.5621778 C3.82597582,11.5956795 3.25241277,9.4551131 4.21891109,7.78108891 L4.21891109,7.78108891 Z"
          id="Combined-Shape"
          fill={`${props.fill ? props.fill : "#161E25"}`}
        ></path>
      </g>
    </svg>
  );
};
export default AttachmentIcon;
