import { useEffect } from "react";
import { connect } from "react-redux";
import { Opening, PermissionAccess } from "./type/type";

type AccessControlType = {
  children: any;
  currentOpening:Opening;
  access?: PermissionAccess;
  type?: boolean; // If true, the component will not display any content. This is used to hide a specific component from certain roles such as members and commenters.
};
const AccessControl = (props: AccessControlType) => {
  useEffect(() => {
    if (props.access && props.access.comment_only || props.currentOpening.archive) {
      var el: any = document.querySelectorAll(".isEditable");
      var i;
      for (i = 0; i < el.length; i++) {
        el[i].setAttribute("disabled", true);
      }
    }
    return () => {};
  }, [props.children]);
  return props.access && props.access.comment_only || props.currentOpening.archive
    ? null
    : props.access?.admin
    ? props.children
    : props.type || props.currentOpening.archive
    ? null
    : props.children;
};
const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
});

export default connect(mapStateToProps)(AccessControl);
