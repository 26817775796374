import React, { useEffect, useState } from "react";
import { Draggable } from "react-beautiful-dnd/";

import { connect } from "react-redux";
import { Dispatch } from "redux";
import { showApplicantFlag } from "../../../redux/actions/opening/action";
import { Link } from "react-router-dom";
import { CardPreviewProps } from "../../../type/boardPanal";
import CardPreviewLabel from "../label/CardPreviewLabel";
import CardPreviewMember from "../cardPreviewMember/CardPreviewMember";
import CommentIcon from "../../../assets/Comments";
import DescriptionIcon from "../../../assets/Description";
import AttachmentIcon from "../../../assets/Attachment"
import { Badge } from "antd"
import { TrackerRoute } from "../../../constant";

const ShowIcon = ({ showLine, icon, value }: any) => {
  if (!showLine) return null;

  return (
    <div className="flex flex-row items-center space-x-4 mt-1.5">
      <div className="flex items-center mx-1 space-x-1">
        <div className="">{icon}</div>{" "}
        <div className="text-xs leading-4 ">{value}</div>
      </div>
    </div>
  );
};
const CardPreview = (props: CardPreviewProps) => {
  const [cardDots,setCardDots]=useState<any>()
  useEffect(()=>{
    setCardDots(props.notificationCenter.unreadDotsofServices.ats.cards)
  },[props.notificationCenter])
  return (
    <React.Fragment>
      <Draggable draggableId={props.applicant.id} index={props.index}>
        {(provided) => (
          <>
            <div
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
              tabIndex={-1}
            >
              <div

                className={
                  "bg-white hover:bg-white hover:bg-opacity-90 flex flex-col mb-1.5 h-auto p-1.5 rounded cursor-pointer w-full"}
              >
                <Link
                  to={`/o/${props.applicationId}/b/${props.currentOpening.id}/${TrackerRoute}/${props.applicant.id}`}
                >
                  {props.applicant.labels ? (
                    <CardPreviewLabel
                      applicantID={props.applicant.id}
                      flag={props.flag}
                    />
                  ) : null}

                  <div
                    role="button"
                    onClick={() => {
                      props.showApplicantPopup(
                        props.applicant.id,
                        props.applicant.list_id
                      );
                    }}
                    onKeyDown={() =>
                      props.showApplicantPopup(
                        props.applicant.id,
                        props.applicant.list_id
                      )
                    }
                    tabIndex={-1}
                  >
                    <div ref={props.refCard}>
                      <div className="flex justify-between">
                        <div className=" text-sm break-words  font-normal leading-4  w-full max-w-full tracking-wide  ">
                          {props.applicant.name}
                        </div>
                      </div>
                      <span className="flex flex-wrap flex-row  space-x-1 text-gray-600  ">
                        <ShowIcon
                          showLine={props.applicant.description}
                          icon={<DescriptionIcon />}
                        />
                        <ShowIcon
                          showLine={props.applicant.comment_count}
                          icon={<CommentIcon />}
                          value={props.applicant.comment_count}
                        />
                        <ShowIcon
                          showLine={props.applicant.attachment_count}
                          icon={<AttachmentIcon />}
                          value={props.applicant.attachment_count}
                        />
                      </span>
                      <CardPreviewMember applicant={props.applicant} />
                      <div className="w-full flex justify-end items-end">
                        {cardDots ?(
                         cardDots[
                            props.applicant.id
                          ] ? (
                            <Badge status="processing"  size={"default"} style={{height:15}} />
                          ) : null
                        ): null}
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </>
        )}
      </Draggable>
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  flag: state.opening.collapseLabel,
  showApplicant: state.opening.showApplicantPreview,
  currentOpening: state.opening.currentOpening,
  applicationId: state.opening.applicationId,
  notificationCenter: state.opening.notificationCenter,
});
const mapPropsToState = (dispatch: Dispatch) => ({
  showApplicantPopup: (id: string, listId: string) =>
    dispatch(showApplicantFlag(id, listId)),
});
export default connect(mapStateToProps, mapPropsToState)(CardPreview);
