import React, { useState, useEffect } from "react";
import SearchOperator from "./SearchOperator";
import SearchAfter from "./SearchAfter";
import SearchIcon from "../../assets/SearchIcon";
import CloseIcon from "../../assets/Close";
import SearchBefore from "./SearchBefore";
import { searchDocument } from "../../lib/api";
import { SearchType } from "../../type/type";
import { COLLECTION_ATS } from "../../constant";
type SearchPanalProps = {
  close: () => void;
  refer: any;
};
const SearchPanal = (props: SearchPanalProps) => {
  const [search, onSearch] = useState(false);
  const [applicantResult, setApplicantResult] = useState<SearchType>([]);
  const [openingResult, setOpeningResult] = useState<SearchType>([]);
  const [searchedData, getSearchedData] = useState("");
  const [queryBy, setQueryBy] = useState<string[]>([]);

  const updateQueryBy = (value: string, add: boolean) => {
    if (add && !queryBy.includes(value)) {
      setQueryBy([...queryBy, value]);
    } else if (!add && queryBy.includes(value)) {
      setQueryBy(queryBy.filter((operator) => operator !== value));
    }
  };

  useEffect(() => {
    // Calling API only when searchData is present
    // Calling API with request according to whether parameter is selected or not
    if (searchedData != "") {
      if (searchedData.length > 3) {
        let searchRequest = {};
        if (queryBy.length > 0) {
          searchRequest = {
            q: searchedData,
            collection: COLLECTION_ATS,
            query_by: queryBy.join(","),
          };
        } else {
          searchRequest = {
            q: searchedData,
            collection: COLLECTION_ATS,
          };
        }
        searchDocument(searchRequest).then((res) => {
          setApplicantResult(res[1]);
          setOpeningResult(res[0]);
          onSearch(true);
        });
      } else {
        getSearchedData(""), onSearch(false);
      }
    }
  }, [queryBy, searchedData]);

  return (
    <div
     
    >
      <div className="flex flex-wrap w-full"  ref={props.refer}>
        <div className="flex flex-row w-full p-1  border-b border-tacitbase-tertiary1 border-opacity-30  items-center">
          <span className="m-2">
            <SearchIcon />
          </span>

          <div className=" flex w-full ">
            <div className="w-full  ">
              <div className="justify-center items-center flex w-full">
                <div className="w-full flex ">
                  <div className=" flex px-1 pb-1 flex-wrap w-full searchDivBox ">
                    {queryBy.map((operator: string, index: number) => (
                      <span
                        className="text-xs flex font-medium flex-none items-center mt-1 mr-1 bg-gray-200 px-1 rounded-default space-x-1 queryByItem"
                        key={index}
                        data-testid={`${operator}QueryByElementTestBtn`}
                      >
                        <div className="space-x-1 flex">
                          <div>{operator}</div>
                        </div>
                        <button
                          className=""
                          data-testid={`${operator}RemoveQueryFromArrTestBtn`}
                          onClick={() => updateQueryBy(operator, false)}
                        >
                          <CloseIcon size="12px" />
                        </button>
                      </span>
                    ))}

                    <input
                      type="text"
                      className=" mt-1 text-xs p-2 rounded  tracking-normal font-light  bg-white inputCSS w-100  "
                      placeholder="Search..."
                      onChange={(e: any) =>
                        e && getSearchedData(e.target.value.trim())
                      }
                      ref={(i) => i && i.focus()}
                      maxLength={200}
                      data-testid="searchInputTestBtn"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <span
            onClick={() => props.close()}
            aria-hidden
            data-testid="closeTestIcon"
            className="cursor-pointer m-2"
          >
            <CloseIcon />
          </span>
        </div>

        <SearchOperator
          setQueryBy={(value) => updateQueryBy(value, true)}
          queryBy={queryBy}
        />
        {search ? (
          <SearchAfter
            queryBy={queryBy}
            getApplicants={applicantResult}
            getOpenings={openingResult}
            searchedData={searchedData}
          />
        ) : (
          <SearchBefore />
        )}
      </div>
    </div>
  );
};

export default SearchPanal;
