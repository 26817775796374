import React from "react";
const AddIcon = (props: any) => {
  return (
    <svg
      width={props.size ? props.size : "16px"}
      height={props.size ? props.size : "16px"}
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Add</title>
      <g
        id="Icons-/-16x16-/-Add"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M8,2 C8.27614237,2 8.5,2.22385763 8.5,2.5 L8.5,7.5 L13.5,7.5 C13.7761424,7.5 14,7.72385763 14,8 C14,8.27614237 13.7761424,8.5 13.5,8.5 L8.5,8.5 L8.5,13.5 C8.5,13.7761424 8.27614237,14 8,14 C7.72385763,14 7.5,13.7761424 7.5,13.5 L7.5,8.5 L2.5,8.5 C2.22385763,8.5 2,8.27614237 2,8 C2,7.72385763 2.22385763,7.5 2.5,7.5 L7.5,7.5 L7.5,2.5 C7.5,2.22385763 7.72385763,2 8,2 Z"
          id=""
          fill={`${props.fill ? props.fill : "#161E25"}`}
        ></path>
      </g>
    </svg>
  );
};
export default AddIcon;
