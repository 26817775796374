import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

type SearchJobProps = {
  openings: any;
  applicationId: string;
};
const SearchBefore = (props: SearchJobProps) => {
  const history = useHistory();
  const showBoard = (opening_id: string) => {
    history.push({
      pathname: `/o/${props.applicationId}/b/${opening_id}/tracker`,
    });
  };
  return (
    <>
      <div className="flex flex-row w-full text-black  divide-x divide-tacitbase-tertiary1 divide-opacity-30 ">
        <div className="flex flex-col items-start  w-1/2 h-200 max-h-200  overflow-y-scroll">
          <div className="flex flex-col py-4 pl-4 pr-3 w-full space-y-2  ">
            <div className="hover:bg-gray-300 p-2 flex items-center justify-between  bg-gray-200   text-black   text-md    tracking-wide font-semibold w-full text-xs leading-4 tracking-normal font-medium  rounded capitalize    ">
              <div className="overflow-hidden text-sm">Cards</div>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-start  w-1/2 h-200 max-h-200  overflow-y-scroll">
          <div className="flex flex-col py-4 pl-4 pr-3 w-full space-y-2  ">
            <div className="hover:bg-gray-300 p-2 flex items-center justify-between  bg-gray-200   text-black   text-md    tracking-wide font-semibold w-full text-xs leading-4 tracking-normal font-medium  rounded capitalize    ">
              <div className="overflow-hidden text-sm">Boards</div>
            </div>
            <div className="flex flex-col space-y-2 ">
              <div className="w-1/2   flex flex-col rounded space-y-2">
                <div className="flex flex-row  items-center w-full">
                  <div className="flex flex-col space-y-2 w-full">
                    {props.openings
                      ? Object.keys(props.openings).map(
                          (opening: any, i: number) => (
                            <div
                              className="shadow-md truncate bg-gray-100  hover:bg-gray-200  break-words  text-xs leading-4 tracking-normal font-medium  p-1 rounded capitalize cursor-pointer"
                              key={i}
                              onClick={() =>
                                showBoard(props.openings[opening].id)
                              }
                              role="button"
                              tabIndex={0}
                              data-testid={"boardTestBtn"+props.openings[opening].id}
                              onKeyDown={() =>
                                showBoard(props.openings[opening].id)
                              }
                            >
                              {props.openings[opening].name}
                            </div>
                          )
                        )
                      : null}
                  </div>
                </div>
              </div>
              <div className="w-1/2 "></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  openings: state.opening.openings.pageSection.openings,
  applicationId: state.opening.applicationId,
});
export default connect(mapStateToProps)(SearchBefore);
