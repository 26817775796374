import React, { useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { deleteAttachmentOfCandidate as deleteAttachmentApi } from "./../../../lib/api";
import { deleteAttachmentOfCandidate as deleteAction } from "./../../../redux/actions/opening/action";
import { loginUser } from "./../../../type/type.d";
import { DeleteOutlined } from "@ant-design/icons";
import { Button, Popover } from "antd";
import DeleteAttachmentPopup from "../../card/attachement/DeleteAttachmentPopup";

type DeleteAttachmentType = {
  loginUser: loginUser;
  attachment: any;
  clientId: string;
  currentApplicant: any;
  refer?:any;
  deleteAttachmentOfCandidate(attachment: string): void;
};

const DeleteAttachmentOfCandidate = (props: DeleteAttachmentType) => {
  const [open, setOpen] = useState(false);

  const handleDeleteClick = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setOpen(true);
  };

  const handleDeleteAttachment = (e: any) => {
    e.stopPropagation();

    if ((e.keyCode === 13 && !e.shiftKey) || e.button === 0) {
      e.preventDefault();
      deleteAttachmentApi(props.attachment.id).then((attachment: any) => {
        if (attachment) {
          props.deleteAttachmentOfCandidate(props.attachment.id);
          setOpen(false);
        }
      });
    }
  };

  return (
    <div className="flex flex-row items-center justify-end">
      <Popover
        content={
          <DeleteAttachmentPopup
            click={(e: any, flag: boolean) => setOpen(flag)}
            attachment={props.attachment}
            deleteAttchement={handleDeleteAttachment}
          />
        }
        trigger="click"
        visible={open}
        onVisibleChange={(visible) => setOpen(visible)}
      >
        <Button
          size="small"
          type="text"
          icon={<DeleteOutlined />}
          onClick={handleDeleteClick}
        >
          Delete
        </Button>
      </Popover>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  loginUser: state.opening.loginUser,
  applicationId: state.opening.applicationId,
  currentApplicant: state.opening.currentApplicant,
  attachmentLoader: state.opening.attachmentLoader,
  clientId: state.opening.clientId,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  deleteAttachmentOfCandidate: (attachment: any) => {
    dispatch(deleteAction(attachment));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAttachmentOfCandidate);
