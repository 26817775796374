import React, { useEffect, useRef, useState } from "react";
import { COLOR_DATA_EVENT } from "../../../../constant";
import { Scope, updatedColor } from "../../../../type/type.d";
import EventColorSelectionPopup from "../../../sidePanal/calendarSettings/EventColorSelection";
import CommonDropDown from "./Dropdown";
import { usePopper } from "react-popper";
import { Overlay } from "../../../hook/Overlay";
type reminderProps = {
  setColor: (color: any) => void;
  setReminder: (e: any) => void;
  value: any;
  selectedColor: string;
};
type color = {
  name: string | undefined;
  code: string;
};
const Reminder = (props: reminderProps) => {
  const [defaultColors, setColors] = useState(COLOR_DATA_EVENT);
  const [selectedColor, setSelectedColor] = useState<color>({
    name: "",
    code: "",
  });
  let [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>();
  const popupRef = useRef(null);

  let [popperElement, setPoperElement] = useState<HTMLDivElement | null>();
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 2.25],
        },
      },
      {
        name: "flip",
        options: {
          fallbackPlacements: ["top", "right"],
        },
      },
    ],
  });
  useEffect(() => {
    COLOR_DATA_EVENT.filter((e) => {
      e.colorCode == props.selectedColor
        ? (setSelectedColor({ name: e.name, code: e.colorCode }),
          (e.isSet = true))
        : (e.isSet = false);
    });
  }, [props.selectedColor]);
  const [showPopup, setShowPopup] = useState(false);

  const reminders = [
    "10 min before",
    "15 min before",
    "30 min before",
    "60 min before",
  ];

  const setSelectedColorFunc = (index: number) => {
    let updatedColors: any = [];
    setTimeout(() => {
      setShowPopup(false);
    }, 500);
    COLOR_DATA_EVENT.map((color: updatedColor, i) => {
      if (i === index) {
        color.isSet = true;
        setSelectedColor({ name: color.name, code: color.colorCode });
        props.setColor((prev: any) => ({
          ...prev,
          color: color.colorCode,
        }));
        updatedColors.push(color);
      } else {
        color.isSet = false;
        updatedColors.push(color);
      }
    });
    setColors(updatedColors);
  };
  return (
    <div className="flex flex-col space-y-2  w-full">
      <div className="flex w-full space-x-4">
        {" "}
        <div className="flex flex-col w-1/2 space-y-2">
          <div className="input-label">Set Reminder</div>{" "}
          <div>
            <CommonDropDown
              name="reminder"
              option={reminders}
              setValue={(value) =>
                props.setReminder((prev: any) => ({
                  ...prev,

                  reminders: parseInt(value),
                }))
              }
              selectedValue={props.value ? props.value : "Select"}
            />
          </div>
        </div>
        <div className="flex flex-col w-1/2 space-y-2">
          {" "}
          <div className="input-label">Set Color</div>
          <div>
            <div
              ref={setReferenceElement}
              role="button"
              tabIndex={0}
              onKeyDown={() => {}}
            >
              {" "}
              <button
                className=" input-box cursor-pointer flex items-center justify-between  "
                onClick={() => setShowPopup(!showPopup)}
              >
                <div className="overflow-hidden text-sm">
                  {selectedColor ? (
                    <div className="flex items-center space-x-2">
                      {" "}
                      <div
                        className={`h-3 w-3 rounded  `}
                        style={{
                          backgroundColor: selectedColor.code,
                        }}
                      ></div>
                      <div>{selectedColor.name}</div>
                    </div>
                  ) : (
                    "Select"
                  )}
                </div>
                <div className="text-xl mb-3"> &#8964;</div>
              </button>
              {showPopup ? (
                <Overlay
                  onClose={() => setShowPopup(false)}
                  scope={Scope.Dialog}
                  reference={popupRef}
                  className=""
                >
                  <div
                    ref={setPoperElement}
                    style={styles.popper}
                    {...attributes.popper}
                    className="popupDiv z-10   p-2 bg-white w-80  top-9 rounded shadow-2xl "
                  >
                    <div className=" flex    flex-wrap " ref={popupRef}>
                      {" "}
                      {defaultColors.map((label: updatedColor, idx) => (
                        <div key={idx}>
                          <EventColorSelectionPopup
                            colorCode={`${label.colorCode}`}
                            isSet={label.isSet}
                            click={() => setSelectedColorFunc(idx)}
                            showMore={label.showMore}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </Overlay>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Reminder;
