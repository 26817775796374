import React, { useState } from "react";
import Label from "../../boardPanel/label/CardPreviewLabel";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { removeArchiveApplicant } from "../../../redux/actions/opening/action";
import { ArchiveCardProps } from "../../../type/sidePanalSlider";
import ArchiveIcon from "../../../assets/Archive";

const ShowIcon = ({ showLine, label }: any) => {
  if (!showLine) return null;
  return <i className={`${label} mr-4 text-gray-400`}></i>;
};
const ArchiveCard = (props: ArchiveCardProps) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div className="bg-white flex flex-col hover:bg-white hover:bg-opacity-40 py-2 px-2 rounded mb-1.5 border-b border-grey cursor-pointer w-72">
      <div className="flex flex-wrap text-white w-full ">
        <Label applicantID={props.id} flag={props.flag} />
      </div>
      <div
        role="button"
        data-testid="archive-card"
        onClick={() => setShowModal(!showModal)}
        onKeyDown={() => setShowModal(!showModal)}
        tabIndex={0}
      >
        <div className="mb-1">{props.applicantName}</div>
        <div className="flow-root">
          <div className="float-left ">
            <span className="flex flex-wrap items-center">
              {/* <DateBadge applicantID={props.id} /> */}
              <ShowIcon showLine={props.description} label="fa fa-align-left" />
              <ShowIcon showLine={props.noOfComments} label="fa fa-comments" />
              <div className="text-gray-500 flex space-x-2">
                <ArchiveIcon />
                <span className="text-xs">Archived</span>
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  flag: state.opening.collapseLabel,
  showApplicant: state.opening.showApplicantPreview,
  currentArchivedApplicant: state.opening.CurrentArchivedApplicant,
});
const mapPropsToState = (dispatch: Dispatch) => ({
  removedCurrentArchivedApplicant: () => dispatch(removeArchiveApplicant()),
});
export default connect(mapStateToProps, mapPropsToState)(ArchiveCard);
