import React from "react";
import ReactECharts from "echarts-for-react";
import TeamPerformanceTableComponent from "./TeamPerformanceReportComponent";
import GenericKpi from "./GenericKpi";
import GraphHeader from "./GraphHeader";
import UnsupportedChart from "./UnsupportedChart";
import LoadingIndicator from "./LodingIndicator";
import Empty from "antd/lib/empty";
import { BarChartOutlined } from "@ant-design/icons/lib/icons";

interface GraphComponentProps {
  data?: any;
  loading: boolean;
  dataerror?: boolean;
  chart_type: string;
  templateId: string;
  name: string;
  tooltipMessage: string;
  entity_names: string[]; // Include entity_names in the interface
  start_time?: string | undefined; // Include start_time in the interface
  entity_type: string;
}
const GraphComponent: React.FC<GraphComponentProps> = ({
  data,
  loading,
  dataerror,
  chart_type,
  templateId,
  name,
  tooltipMessage,
  entity_names,
  entity_type,
  start_time,
}) => {
  return (
    <div className="h-full flex flex-col justify-between">
      <div className="pb-4" style={{ height: "10%" }}>
        <GraphHeader Header={name} hover_title={tooltipMessage} />
      </div>
      <div className="h-4/5" style={{ height: "90%" }}>
        {loading ? (
          <LoadingIndicator loader_message="Loading data..." />
        ) : dataerror ? (
          <div className="flex justify-center items-center h-full ">
            <Empty
              image={
                <BarChartOutlined
                  style={{ fontSize: 80, color: "grey", marginBottom: "0" }}
                />
              }
              description="Unable to fetch data , please try again..."
            />
          </div>
        ) : (
          <>
            {chart_type === "Echart" && data?.[0].options ? (
              <ReactECharts
                option={data?.[0].options}
                style={{ height: "100%", cursor: "grab" }}
              />
            ) : chart_type === "Table" ? (
              <TeamPerformanceTableComponent
                data={data?.[0]}
                entityType={entity_type}
                entityNames={entity_names}
                start_time={start_time}
                template_id={templateId}
              />
            ) : chart_type === "KPI" ? (
              <GenericKpi data={data?.[0]} />
            ) : (
              <UnsupportedChart />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default GraphComponent;
