import React from "react";
import Tacitbase from "../../images/TB_26046_login.png";
const ProductName = () => {
  return (
    <React.Fragment>
      <div className={`flex mt-6 mb-8 items-center justify-center`}>
        <img
          src={Tacitbase}
          alt=""
          width="350px"
          className={`flex p-0.5 rounded focus:outline-none `}
        />
      </div>{" "}
    </React.Fragment>
  );
};
export default ProductName;
