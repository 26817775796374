import React from "react";
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";
type ButtonProps = {
  buttonName: string;
  bgColor?: string;
  click?: (e: any) => void;
  isDisabled?: boolean;
  width?: string;
  textColor?: string;
  svg?: any;
  shadow?: string;
  testId?: string;
};

const Button1 = (props: ButtonProps) => {
  return (
    <React.Fragment>
      <button
        type="submit"
        data-testid={props.testId ? props.testId : "submit-btn"}
        onClick={props.click}
        disabled={props.isDisabled}
        className={`  ${
          props.isDisabled
            ? props.bgColor
              ? props.textColor
                ? `bg-${props.bgColor} cursor-not-allowed bg-opacity-60 text-${props.textColor}`
                : `bg-${props.bgColor} cursor-not-allowed bg-opacity-60 text-white`
              : `bg-tacitbase-secondary3 bg-opacity-60 cursor-not-allowed text-white`
            : props.bgColor
            ? props.textColor
              ? props.shadow
                ? `text-${props.textColor} bg-${props.bgColor} hover:bg-tacitbase-primary2 shadow-${props.shadow}  active:shadow-5xl active:ring-${props.bgColor} active:ring-2 active:bg-blue-600 hover:bg-blue-400 `
                : `text-${props.textColor} bg-${props.bgColor} hover:bg-tacitbase-primary2  `
              : `text-white  `
            : `bg-tacitbase-secondary3 svgButton active:ring-tacitbase-secondary3 hover:text-black active:ring-2  active:bg-tacitbase-secondary3 text-white`
        } font-normal rounded text-md text-sm  w-auto truncate max-w-full px-4 py-1.5  focus:outline-none  cursor-pointer flex items-center justify-center`}
      >
        <span className="flex items-center">
          {props.svg ? <span className="mr-2">{props.svg}</span> : null}
          {capitalizeFirstLetter(props.buttonName)}
        </span>
      </button>
    </React.Fragment>
  );
};
export default Button1;
