import React, { useState } from "react";
import { useEffect } from "react";
// import { useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
  archieveUnArchiveApplicant,
  getArchivedApplicants,
} from "../../../lib/api";
import {
  initArchivedItems,
  sendBackArchivedItem,
} from "../../../redux/actions/opening/action";
import { ArchiveItemsProps } from "../../../type/sidePanalSlider";
import { currentApplicant } from "../../../type/type";
import PopupTitle from "../../common/popupTitle/PopupTitle";
import ArchiveCard from "../archiveCard/ArchiveCard";
import ArchiveCards from "./ArchiveLists";
import { SOCKET_CLIENT_ID } from "../../../constant";

const ArchiveItems = (props: ArchiveItemsProps) => {
  const [swicher, switchToList] = useState(false);
  useEffect(() => {
    getArchivedApplicants(props.currentOpening.id).then((applicants: any) => {
      props.initArchivedApplicants(applicants, props.currentOpening.id);
    });
  }, []);

  const sendBackToBoard = (archivedItem: any) => {
    const req = {
      id: archivedItem.id,
      archive: false,
      socket_client_id: sessionStorage.getItem(SOCKET_CLIENT_ID),
    };
    archieveUnArchiveApplicant(req).then((res: any) => {
      props.sendBackArchivedApplicant(res);
    });
  };

  return (
    <React.Fragment>
      <div className="">
        <PopupTitle
          PopupTitle="Archive"
          back={props.prev}
          font="text-base font-medium text-black"
          close={props.close}
        />
      </div>
      <div className="flex mx-4 mb-1 ">
        <button
          onClick={() => switchToList(!swicher)}
          className=" text-black px-2 w-32 py-1 rounded text-sm font-light float-right bg-gray-300 bg-opacity-25 hover:bg-gray-300 hover:bg-opacity-40  focus:outline-none"
        >
          {swicher ? "Switch to card" : "Switch to list"}
        </button>
      </div>
      {!swicher ? (
        props.archivedApplicants[props.currentOpening.id] ? (
          props.archivedApplicants[props.currentOpening.id].map(
            (card: currentApplicant, i: number) => (
              <div className="mx-4 text-sm" key={i}>
                <ArchiveCard
                  applicantName={card.name}
                  description={card.description}
                  noOfComments={card.comment_count}
                  id={card.id}
                  index={i}
                  labels_IDs={card.labels}
                />
                <div className="flex space-x-1 px-2 py-0.5 underline text-gray-500 cursor-pointer">
                  <div
                    className=""
                    role="button"
                    tabIndex={0}
                    data-testid="send-back"
                    onKeyDown={() => sendBackToBoard(card)}
                    onClick={() => sendBackToBoard(card)}
                  >
                    Send to tracker
                  </div>
                </div>
              </div>
            )
          )
        ) : null
      ) : (
        <ArchiveCards click={() => switchToList(!swicher)} switcher={swicher} />
      )}
    </React.Fragment>
  );
};
const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
  archivedApplicants: state.opening.archivedApplicants,
  clientId: state.opening.clientId,
});
const mapPropsToState = (dispatch: Dispatch) => ({
  initArchivedApplicants: (archivedApplicants: any, openingID: string) =>
    dispatch(initArchivedItems(archivedApplicants, openingID)),
  sendBackArchivedApplicant: (archivedApplicant: any) =>
    dispatch(sendBackArchivedItem(archivedApplicant)),
});
export default connect(mapStateToProps, mapPropsToState)(ArchiveItems);
