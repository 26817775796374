import React from "react";
import { DatePicker, Divider } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { colors, DateFormate, DIVIDER_HEIGHT } from "../../constant";
import { connect } from "react-redux";
import { ReloadOutlined } from "@ant-design/icons";
import { IconButton } from "../../ant/Button";
import AnalyticsContent from "../../components/analyticsVersion1/AnalyticsContent";
import { Opening } from "../../type/type";
import {
  disabledDate,
  useDateRangeSelection,
} from "../../components/analyticsVersion1/useDateRangeSelection";
dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;
const startOfMonth = dayjs().startOf("month");

// Get the start of the previous month
const startOfPrevMonth = startOfMonth.subtract(1, "month");

type analyticsMainPageProps = {
  applicationName: string;
  applicationId: string;
  currentOpening: Opening;
};

function AnalyticsMainPage(props: analyticsMainPageProps) {
  const {
    selectedDates,
    selectedEndDates,
    setSelectedDates,
    setSelectedEndDates,
    tomorrow,
    handleDateChange,
  } = useDateRangeSelection();

  function handleRefreshTab() {
    setSelectedDates("");
    setSelectedEndDates(tomorrow);
  }

  return (
    <>
      <div
        className="mx-4 mb-4 rounded-b"
        style={{ height: "calc(100vh - 132px)" }}
      >
        <div className="flex flex-col">
          <div className="flex space-x-2 pb-2 pt-2 pr-4 bg-white items-center justify-end h-12 rounded-t">
            <RangePicker
              value={[
                selectedDates ? dayjs(selectedDates) : null,
                selectedEndDates && selectedEndDates != tomorrow
                  ? dayjs(selectedEndDates)
                  : null,
              ]}
              onChange={handleDateChange}
              defaultPickerValue={[startOfPrevMonth, startOfPrevMonth]}
              format={DateFormate}
              disabledDate={disabledDate}
              className="w-60 h-7"
              // size="small"
            />

            <Divider
              type="vertical"
              style={{
                height: DIVIDER_HEIGHT,
                borderColor: `${colors.divider}`,
              }}
            />

            <IconButton
              tooltipTitle="Refresh tab"
              icon={<ReloadOutlined />}
              onClick={handleRefreshTab}
              className="h-7 w-7"
            />
          </div>
        </div>
        <div className="h-full rounded-b">
          <AnalyticsContent
            entity_type="board_id"
            entity_names={[props.currentOpening.id]}
            start_time={selectedDates ? selectedDates : ""}
            end_time={selectedEndDates ? selectedEndDates : ""}
            size="2810px"
          />
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state: any) => ({
  loginUser: state.opening.loginUser,
  applicationId: state.opening.applicationId,
  applicationName: state.opening.applicationName,
  currentOpening: state.opening.currentOpening,
});

export default connect(mapStateToProps)(AnalyticsMainPage);
