import React, { useEffect, useRef, useState } from "react";

import NameWithPosition from "./cardPopupContentComponents/CardName";
import Details from "./cardPopupContentComponents/MeetingDetails";
import Guests from "./cardPopupContentComponents/Guests";
import QuickAdd from "./cardPopupContentComponents/QuickAddGuest";
import { connect } from "react-redux";
import {
  ApplicantMembers,
  currentApplicant,
  loginUser,
  Opening,
  openingMemberType,
  orgMember,
  Scope,
} from "../../../type/type.d";

import MeetingPopupTitleComponent from "./cardPopupContentComponents/MeetingPopupTitle";
import AddLocation from "./cardPopupContentComponents/Location";
import AddDescription from "./cardPopupContentComponents/Description";
import Reminder from "./cardPopupContentComponents/SetReminder";
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";
import CalenderIcon from "../../../assets/CalendarHoverable";
import {
  // addAttachmentInCalEvent,
  createCardEvent,
  deleteCalEvent,
  deleteCalEventAttachments,
  editCalEvent,
  getAttachmentByEventID,
  getCalenderByBoardID,
  getEventByID,
} from "../../../lib/api";
import moment from "moment-timezone";

import * as momentTz from 'moment-timezone';

import {
  CreateCardEventRes,
  EventAttachment,
  getEventByIDRes,
} from "../../../lib/apiRes";
import { Dispatch } from "redux";
import {
  deleteBoardEvent,
  deleteCardEvent,
  editBoardEvent,
  editCardEvent,
  updateCardEvents,
} from "../../../redux/actions/opening/action";
import { roles } from "../../../constant";
import ConferenceOnCard from "./cardPopupContentComponents/ConferenceOnCard";
import { Button } from "antd";
import { validateInput } from "../../../utils/Validations";
import { Overlay } from "../../hook/Overlay";
import CloseIcon from "../../../assets/Close";
import { usePopper } from "react-popper";
export type attendeeType = { email: "" };
export type cardEventProps = {
  width?: string;
  type: string;
  currentOpening: Opening;
  currentApplicant: currentApplicant;
  loginUser: loginUser;
  members: orgMember;
  calenderId: string;
  click: (e: boolean) => void;
  editEventFromBoard: (data: any) => void;
  editEventFromCard: (data: any) => void;
  applicantMembers: ApplicantMembers;
  openingMembers: any;
  refer?: any;
  event_id?: string;
  card_id?: string;
  updateCardEventsAction: any;
  applicationId: string;
  clientId: string;
  deleteEventWithIdFromBoard: (id: string) => void;
  deleteEventWithIdFromCard: (id: string) => void;
};
const CardEventSchedularPopup = (props: cardEventProps) => {
  const [guestObj, setGuestObj] = useState<string[] | []>([]);
  const [editEventObj, setEditEventObj] = useState<getEventByIDRes>();
  const [selectedTool, setSelectedTool] = useState<string>("");
  const [gmt, setGMT] = useState("");
  const [deleteEventState, setDeleteEvent] = useState(false);
  const deletepopupRefer=useRef(null);
  let [referenceElement, setReferenceElement] = useState<
  HTMLDivElement | null | HTMLButtonElement
>();
let [popperElement, setPoperElement] = useState<HTMLDivElement | null>();
const { styles, attributes } = usePopper(referenceElement, popperElement, {
  placement: "bottom-start",
});

  const [fileArr, setFileArr] = useState<string[]>([]);
  const [memberArrForReq, setMemberArrForReq] = useState<any[]>([]);
  const [loadings, setLoadings] = useState<boolean[]>([]);  const formData = new FormData();
  const [validations, setValidationState] = useState({
    nameIsBlank: 0,
    dateIsBlank: 0,
  });
  var updatdedArr = [...memberArrForReq];
  const [attachment, setAttachment] = useState<EventAttachment[]>();
  const [selectedTimezone, setSelectedTimezone] = useState<string>(() => {
    return momentTz.tz.guess();
  });
  // default event state
  const [event, setInputEventObj] = useState({
    zoom_meeting_id: 0,
    members: [],
    member_only: true,
    zoom_join_url: "",
    meet_join_url: "",
    status: "active",
    reminders: 10,
    deleted_attachments: [],
    card_id: "",
    zoom: false,
    meet: false,
    meeting: {
      name: "",
      zone: "",
      gmtString: "",
      date: "",
      duration: "Select",
      suggested_slots: "",
    },
    location: "",
    attendees: [],
    deleted_attendees: [],
    color: "",
    description: "",
  });

  useEffect(() => {
    if (selectedTimezone) {
      const gmtString = moment().tz(selectedTimezone).format("Z");

      setGMT(gmtString);
    } else {
      setGMT("");
    }
  }, [selectedTimezone]);

  useEffect(() => {
    setInputEventObj((prev: any) => ({
      ...prev,
      meeting: { ...prev.meeting, zone: selectedTimezone },
    }));
    let m = {};
    (props.openingMembers[props.currentOpening.id]?.map(
          (orgMember: openingMemberType) => {
            (m = {
              id: orgMember.id,
              role:
                !orgMember.access.admin && !orgMember.access.comment_only
                  ? roles.MEMBER
                  : orgMember.access.admin && !orgMember.access.comment_only
                  ? roles.ADMIN
                  : !orgMember.access.admin && orgMember.access.comment_only
                  ? roles.COMMENTOR
                  : null,
              is_secrete_visible: true,
            }),
              updatdedArr.push(m);
          }
        ),
        setInputEventObj((prev: any) => ({
          ...prev,
          member_only: false,
        })));
    setMemberArrForReq(updatdedArr),
      props.currentOpening.id
        ? getCalenderByBoardID(props.currentOpening.id).then((res) => {
            setInputEventObj((prev: any) => ({
              ...prev,
              meeting: {
                ...prev.meeting,
                duration: res.calendar.default_event_duration + " min",
              },

              // Checking if default color is not valid hex code
              color: validateInput(res.calendar.default_event_color, "color")
                ? res.calendar.default_event_color
                : "#C2CA51",
            }));
          })
        : null;

    // fetching event API call
    props.event_id
      ? props.type == "edit"
        ? (getEventByID(props.event_id).then((res) => {
            getAttachmentByEventID(res.event.id).then((res) => {
              setAttachment(res.attachments);
            });
            setEditEventObj(res);
          }),
          setInputEventObj((prev: any) => ({
            ...prev,
            card_id: props.card_id,
          })))
        : setInputEventObj((prev: any) => ({
            ...prev,
            card_id: props.currentApplicant.id,
          }))
      : null;
    return () => {};
  }, []);
  //  setting default values if user wants to edit the event

  useEffect(() => {
    editEventObj &&
      (setInputEventObj((prev: any) => ({
        ...prev,
        meeting: {
          name: editEventObj.event.summary,
          zone: editEventObj.event.timezone,
          date: moment
            .utc(editEventObj.event.start_date)
            .tz(editEventObj.event.timezone)
            .format("YYYY-MM-DDTHH:mm:ss"),
          duration: editEventObj.event.duration + " min",
          suggested_slots: "",
        },
        location: editEventObj.event.location,
        attendees: editEventObj.event.attendees,
        description: editEventObj.event.description,
        color: editEventObj.event.color,
        zoom_join_url: editEventObj.event.zoom_join_url,
        meet_join_url: editEventObj.event.meet_join_url,
        reminders: editEventObj.event.reminders
          ? editEventObj.event.reminders[0].minutes
          : 0,
      })),
      setSelectedTimezone(editEventObj.event.timezone),
      setSelectedTool(
        editEventObj.event.meet_join_url
          ? "meet"
          : editEventObj.event.zoom_join_url
          ? "zoom"
          : ""
      ));
    return () => {};
  }, [editEventObj]);

  useEffect(() => {
    selectedTool == "zoom"
      ? setInputEventObj((prev: any) => ({
          ...prev,
          zoom: true,
          meet: false,
        }))
      : selectedTool == "meet"
      ? setInputEventObj((prev: any) => ({
          ...prev,
          meet: true,
          zoom: false,
        }))
      : setInputEventObj((prev: any) => ({
          ...prev,
          meet: false,
          zoom: false,
        }));
  }, [selectedTool]);

  // different req for create and edit event form
  const req =
    props.type == "edit"
      ? {
          summary: event.meeting.name,
          timezone: selectedTimezone,
          description: event.description,
          deleted_attachments: event.deleted_attachments,
          start_date: event.meeting.date + gmt,
          members: updatdedArr,
          member_only: event.member_only,
          duration: parseInt(event.meeting.duration),
          rrule: "RRULE:FREQ=DAILY;COUNT=1",
          organizer: editEventObj?.event.organizer,
          attendees: event.attendees,
          deleted_attendees:
            event.deleted_attendees.length > 0 ? event.deleted_attendees : [],
          status: "active",
          reminders: [{ type: "email", minutes: event.reminders }],
          location: event.location,
          zoom_meeting_id: editEventObj?.event.zoom_meeting_id,
          zoom_join_url: event.zoom_join_url,
          meet_join_url: event.meet_join_url,
          zoom: event.zoom,
          meet: event.meet,
          color: event.color,
          card_id: event.card_id,
          calendar_id: props.calenderId,
          socket_client_id: props.clientId,
        }
      : {
          summary: event.meeting.name,
          timezone: selectedTimezone,
          description: event.description,
          start_date: event.meeting.date + gmt,
          duration: parseInt(event.meeting.duration.split(" ")[0]),
          rrule: "RRULE:FREQ=DAILY;COUNT=1",
          organizer: "",
          attendees: event.attendees,
          status: "active",
          zoom_meeting_id: 0,
          zoom_join_url: "",
          zoom: event.zoom,
          members: updatdedArr,
          member_only: event.member_only,
          meet: event.meet,
          reminders: [{ type: "email", minutes: event.reminders }],
          location: event.location,
          color: event.color,
          card_id: props.currentApplicant.id,
          calendar_id: props.calenderId,
          socket_client_id: props.clientId,
        };
  //  create event API call

  const createEvent = () => {
    if (validations.dateIsBlank == 1 && validations.nameIsBlank == 1) {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[0] = true;
        return newLoadings;
      });      for (var i = 0; i < fileArr.length; i++) {
        formData.append("file", fileArr[i]);
      }
      formData.append("event", JSON.stringify(req));
      createCardEvent(formData).then((res) => {
        setTimeout(() =>    setLoadings((prevLoadings) => {
          const newLoadings = [...prevLoadings];
          newLoadings[0] = false;
          return newLoadings;
        }), 2000);
        setInputEventObj((prev: any) => ({
          ...prev,
          meeting: { ...prev.meeting, zone: "" },
        }));
        props.updateCardEventsAction(res);
        props.click(false);
      });
    } else if (validations.dateIsBlank == 0) {
      setValidationState((prev: any) => ({
        ...prev,
        dateIsBlank: 2,
      }));
    }
    if (validations.nameIsBlank == 0) {
      setValidationState((prev: any) => ({
        ...prev,
        nameIsBlank: 2,
      }));
    }
    if (guestObj.length == 0) {
      setValidationState((prev: any) => ({
        ...prev,
        guestIsBlank: 2,
      }));
    }
  };

  //  edit event API call
  const editEvent = () => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[1] = true;
      return newLoadings;
    });    for (var i = 0; i < fileArr.length; i++) {
      formData.append("new_attachments", fileArr[i]);
    }
    formData.append("event", JSON.stringify(req));
    editEventObj
      ? editCalEvent(editEventObj.event.id, formData).then((res) => {
          setTimeout(() =>    setLoadings((prevLoadings) => {
            const newLoadings = [...prevLoadings];
            newLoadings[1] = false;
            return newLoadings;
          }), 2000);
          setInputEventObj((prev: any) => ({
            ...prev,
            meeting: { ...prev.meeting, zone: "" },
          }));
          props.editEventFromBoard({
            eventID: editEventObj.event.id,
            value: res,
          });
          props.editEventFromCard({
            eventID: editEventObj.event.id,
            value: res,
          });

          props.click(false);
        })
      : null;
  };
  useEffect(() => {
    let orgMember = Object.values(props.members);

    const arr =
      event.attendees.length > 0
        ? event.attendees.map((obj: attendeeType) => {
            const email = obj.email;
            let id = "";
            const isPresent = orgMember.some(
              (someObj) => ((id = someObj.id), someObj.email === email)
            );
            if (isPresent) {
              return id;
            } else {
              return email;
            }
          })
        : [];

    setGuestObj(arr);
  }, [event.attendees]);
  const deleteEvent = () => {
    editEventObj&&(    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[2] = true;
      return newLoadings;
    }),
    deleteCalEvent(editEventObj.event.id, props.clientId).then((res) => {
      if (res) {
        setLoadings((prevLoadings) => {
          const newLoadings = [...prevLoadings];
          newLoadings[2] = false;
          return newLoadings;
        });        deleteCalEventAttachments(editEventObj.event.id).then((res) => {
          console.log(res, "deleted attachment");
        });
        props.deleteEventWithIdFromBoard(editEventObj.event.id);
        props.deleteEventWithIdFromCard(editEventObj.event.id);
        props.click(false);
      }
    }));
  };
  return (
    <React.Fragment>
      <div className="justify-center bg-black bg-opacity-50  items-center flex   fixed inset-0  outline-none focus:outline-none ">
        {" "}
        <button tabIndex={0} id="card-close"></button>
        <div className="flex items-start rounded relative h-full justify-center overflow-y-scroll z-20 w-full">
          <div
            className={` bg-gray-100 w-192  rounded  my-12 p-2`} ref={props.refer}
          >
            <div className=" bg-yellow-50 w-full"></div>

            <div className="m-4">
              {" "}
              <div className="my-3">
                <MeetingPopupTitleComponent
                  PopupTitle="Schedule A Meeting"
                  close={props.click}
                />
              </div>{" "}
              {props.type == "edit" ? (
                <div className="flex popupContent  flex-col space-y-3">
                  <NameWithPosition
                    card_name={
                      props.currentApplicant ? props.currentApplicant.name : ""
                    }
                    position_name={
                      props.currentOpening ? props.currentOpening.name : ""
                    }
                    position_color={
                      props.currentOpening ? props.currentOpening.color : ""
                    }
                  />
                  <Details
                    setMeetingInput={(meeting) => setInputEventObj(meeting)}
                    value={event.meeting}
                    attendees={event.attendees}
                    members={props.members}
                    setSelectedTimezone={(tz) => setSelectedTimezone(tz)}
                    selectedTz={selectedTimezone}
                    setValidationState={(value) => setValidationState(value)}
                    validations={validations}

                    // data={}
                  />
                  <QuickAdd
                    arr={(guests) => setGuestObj(guests)}
                    guest={guestObj}
                    deletedAttendees={event.deleted_attendees}
                    attendees={event.attendees}
                    type="edit"
                    cardID={props.card_id}
                    setMeetingInput={(attendees) => setInputEventObj(attendees)}
                    setValidationState={(value) => setValidationState(value)}
                    validations={validations}
                  />

                  <Guests
                    guest={guestObj}
                    attendees={event.attendees}
                    arr={(guests) => setGuestObj(guests)}
                    type="edit"
                    setMeetingInput={(attendees) => setInputEventObj(attendees)}
                  />
                  <div className="flex flex-col space-y-2 w-6/12">
                    <div className="tracking-normal  items-center text-sm font-medium text-black w-full z-0">
                      Video Conference Tool
                    </div>{" "}
                    <ConferenceOnCard
                      selected={selectedTool}
                      urlObj={
                        event.meet_join_url
                          ? { tool: "meet", url: event.meet_join_url }
                          : event.zoom_join_url
                          ? { tool: "zoom", url: event.zoom_join_url }
                          : { tool: "", url: "" }
                      }
                      setSelectedTool={(value) => setSelectedTool(value)}
                    />
                  </div>

                  <AddLocation
                    setLocation={(location) => setInputEventObj(location)}
                    value={event.location}
                  />
                  <AddDescription
                    setDesc={(desc) => setInputEventObj(desc)}
                    value={event.description}
                    removeAttachment={(attachmentId) =>
                      setInputEventObj((obj: any) => ({
                        ...obj,
                        deleted_attachments: [
                          ...obj.deleted_attachments,
                          attachmentId,
                        ],
                      }))
                    }
                    prevAttachments={attachment}
                    setPrevAttachment={(data) => setAttachment(data)}
                    file={(file) => setFileArr(file)}
                  />
                  <Reminder
                    setColor={(color) => setInputEventObj(color)}
                    setReminder={(reminder) => setInputEventObj(reminder)}
                    value={event.reminders + " min before"}
                    selectedColor={event.color}
                  />
                

                <div className="w-full flex justify-end space-x-4">

{" "}
                    <div className="flex space-x-2 w-6/12 justify-end ">
                    <button
            className="text-xs  text-tacitbase-primary1   rounded tracking-wider  text-opacity-90 underline cursor-pointer  "
            data-testid="delete-event-btn"
            onClick={() =>setDeleteEvent(!deleteEventState)} ref={setReferenceElement}
          >
            <span className="">{`
                 Delete
              `}</span>{" "}
          </button>


          <Button type="default"  onClick={() => props.click(false)}   >
          {capitalizeFirstLetter("Cancel")}
        </Button>   
                      <Button type="primary"  onClick={()=>editEvent()} loading={loadings[1]} icon={ <CalenderIcon size="13px" />} >
          {capitalizeFirstLetter("save")}
        </Button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex popupContent  flex-col space-y-3">
                  <NameWithPosition
                    card_name={
                      props.currentApplicant ? props.currentApplicant.name : ""
                    }
                    position_name={
                      props.currentOpening ? props.currentOpening.name : ""
                    }
                    position_color={
                      props.currentOpening ? props.currentOpening.color : ""
                    }
                  />
                  <Details
                    attendees={event.attendees}
                    members={props.members}
                    setMeetingInput={(meeting) => setInputEventObj(meeting)}
                    value={event.meeting}
                    setSelectedTimezone={(tz) => setSelectedTimezone(tz)}
                    selectedTz={selectedTimezone}
                    setValidationState={(value) => setValidationState(value)}
                    validations={validations}
                  />
                  <QuickAdd
                    type="create"
                    arr={(guests) => setGuestObj(guests)}
                    guest={guestObj}
                    setMeetingInput={(attendees) => setInputEventObj(attendees)}
                    setValidationState={(value) => setValidationState(value)}
                    validations={validations}
                    attendees={event.attendees}
                  />

                  <Guests
                    guest={guestObj}
                    attendees={event.attendees}
                    setMeetingInput={(attendees) => setInputEventObj(attendees)}
                    arr={(guests) => setGuestObj(guests)}
                    type="create"
                  />
                  <div className="flex flex-col space-y-2 w-6/12">
                    <div className="tracking-normal  items-center text-sm font-medium text-black w-full z-0">
                      Video Conference Tool
                    </div>{" "}
                    <ConferenceOnCard
                      selected={selectedTool}
                      setSelectedTool={(value) => setSelectedTool(value)}
                    />
                  </div>

                  <AddLocation
                    setLocation={(location) => setInputEventObj(location)}
                  />
                  <AddDescription
                    setDesc={(desc) => setInputEventObj(desc)}
                    setPrevAttachment={(data) => setAttachment(data)}
                    file={(file) => setFileArr(file)}
                  />
                  <Reminder
                    setColor={(color) => setInputEventObj(color)}
                    selectedColor={event.color}
                    setReminder={(reminder) => setInputEventObj(reminder)}
                    value={event.reminders + " min before"}
                  />

                  <div className="w-full flex justify-end">
                    {" "}
                    <div className="flex space-x-2 w-6/12 justify-end ">

                      <Button type="default"  onClick={() => props.click(false)}   >
          {capitalizeFirstLetter("Cancel")}
        </Button>                          
<Button type="primary" onClick={()=>createEvent()}  loading={loadings[0]} icon={ <CalenderIcon size="13px" />} >
          {capitalizeFirstLetter("set meeting")}
        </Button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {deleteEventState ? (
 <Overlay
 onClose={() => {
  setDeleteEvent(!deleteEventState)
 }}
 scope={Scope.Popover}
 reference={deletepopupRefer}
>          <div
            ref={setPoperElement}
            style={styles.popper}
            {...attributes.popper} data-testid="deleteConfirmationPopup"
          >
            <div
              className="flex flex-col h-auto mt-1 z-20 w-72 bg-white text-gray-800 tracking-normal border border-gray-300  text-sm font-normal rounded shadow-4xl"
              ref={deletepopupRefer}
            >
              <div className="flex mt-2">
                <div
                  className={`flex justify-center tracking-normal font-normal  items-center 
            text-sm text-gray-500
           w-full`}
                >
                  Delete event?{" "}
                </div>

                <button
                  className="flex cursor-pointer justify-center items-center focus:outline-none mx-2"
                  data-testid="deletebtn1"
                  onClick={() => setDeleteEvent(!deleteEventState)}
                >
                  <CloseIcon />
                </button>
              </div>
              <div className="flex">
                <hr
                  className=" w-full m-2"
                  style={{ borderColor: "#0F0A0D", opacity: "0.3" }}
                ></hr>
              </div>{" "}
              <div className="m-2">
                <div
                  className="text-sm flex w-full items-end "
                  onClick={(e: any) => e.stopPropagation()}
                  onKeyDown={(e: any) => e.stopPropagation()}
                  data-testid="testbtn"
                  tabIndex={0}
                  role="button"
                >
                  Deleting an event is permanent. There is no undo.
                </div>
             
                <Button type="primary" onClick={()=>deleteEvent()} size="small"  loading={loadings[2]} danger>
          {capitalizeFirstLetter("Delete")}
        </Button>
              </div>
            </div>
          </div>
        </Overlay>
      ) : null}

    </React.Fragment>
  );
};

const mapPropsToState = (dispatch: Dispatch) => ({
  updateCardEventsAction: (events: CreateCardEventRes) =>
    dispatch(updateCardEvents(events)),
  editEventFromBoard: (data: any) => dispatch(editBoardEvent(data)),
  editEventFromCard: (data: any) => dispatch(editCardEvent(data)),
  deleteEventWithIdFromBoard: (event_id: string) =>
  dispatch(deleteBoardEvent(event_id)),deleteEventWithIdFromCard: (event_id: string) =>
  dispatch(deleteCardEvent(event_id)),
});
const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
  loginUser: state.opening.loginUser,
  members: state.opening.members,
  calenderId: state.opening.calendar.currentCalenderId,
  currentApplicant: state.opening.currentApplicant,
  openingMembers: state.opening.openingMembers,
  applicationId: state.opening.applicationId,
  clientId: state.opening.clientId,

  applicantMembers: state.opening.applicantMembers,
});

export default connect(
  mapStateToProps,
  mapPropsToState
)(CardEventSchedularPopup);
