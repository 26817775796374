import React, { useEffect, useRef, useState } from "react";
import ArrowDownIcon from "../../../../assets/ArrowDown";

type dropDownProps = {
  option: string[];
  name: string;
  setValue: (option: string) => void;
  selectedValue: string;
  width?: string
};
const CommonDropDown = (props: dropDownProps) => {
  const [showPopup, setShowPopup] = useState(false);
  const popupRef = useRef<HTMLDivElement | null>(null);
  const [custom, setCustom] = useState(false);
  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  // closes dropdown on outsideclick
  const handleClick = (e: any) => {
    if (popupRef.current && !popupRef.current.contains(e.target)) {
      setShowPopup(false);
      return;
    }
  };
  useEffect(() => {
    props.selectedValue == "custom..." ? setCustom(true) : setCustom(false);
  }, [props.selectedValue]);

  return (
    <>
      <div className="relative ">
        {" "}
        {custom ? (
          <div className="flex items-center space-x-2 justify-between  input-box">
            <input
              id="customDuration"
              type="number"
              className="appearance-none focus:outline-none outline-none w-8/12 "
              onBlur={(e) => (
                props.setValue(e.target.value + " min"), setCustom(true)
              )}
              onChange={() => props.setValue("custom...")}
            />
            <div className="w-4/12">min</div>{" "}
          </div>
        ) : (
          <button
              className={`input-box cursor-pointer flex items-center justify-between z-10 ${props.width && props.width}`}
            onClick={() => setShowPopup(!showPopup)}
          >
            <div className="overflow-hidden text-sm">
              {props.selectedValue ? props.selectedValue : "Select"}
            </div>
              <ArrowDownIcon />{" "}
          </button>
        )}
        {showPopup ? (
          <div
            className="popupDiv absolute  p-2 bg-white w-full top-9 rounded shadow-2xl z-20 "
            ref={popupRef}
          >
            <div className=" flex flex-col space-y-1 min-h-min max-h-48 overflow-y-scroll ">
              {" "}
              {props.option?.map((option: string, i: number) => (
                <div
                  className=" p-1.5 flex items-center justify-between bg-gray-300 bg-opacity-25 hover:bg-gray-300 hover:bg-opacity-40 rounded  text-sm cursor-pointer"
                  key={i}
                  aria-hidden
                  onClick={() => (props.setValue(option), setShowPopup(false))}
                >
                  {option}
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};
export default CommonDropDown;
