import React, { useEffect, useRef, useState } from "react";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import CardEventSchedularPopup from "../cardScheduler/CardEventPopupIndex";

import EventPopupCal from "./fullCalender/EventPopup";
import { Event } from "../../../lib/apiRes";
import { getBoardCalenderEvents } from "../../../lib/api";
import { Opening } from "../../../type/type";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { setBoardCalEvents } from "../../../redux/actions/opening/action";

type calenderPopupProps = {
  cal_id: string;
  setBoardCalEvents(events: Event[]): void;
  boardEvents: Event[];
  currentOpening: Opening;
};
export const MyCalendar = (props: calenderPopupProps) => {
  const [events, setEvents] = useState<any[]>([]);
  const [boardEvents, setBoardEvents] = useState<Event[]>([]);
  const [modal, showModal] = useState(false);
  const [editEventPopup, setEditEventPopup] = useState(false);
  const popupRef = useRef<HTMLDivElement | null>(null);
  const [left, setLeft] = useState(0);

  const [top, setTop] = useState(0);
  const [selectedEvent, setSelectedEvent] = useState<any>();

  let ev = boardEvents?.map((item) => {
    return {
      customEvent: {
        id: item.id,
        title: item.summary,
        date: new Date(item.start_date),
        backgroundColor: item.color,
        color: item.color,
        description: item.description,
        guest: item.attendees,
        cardID: item.card_id,
        duration: item.duration,
        meet_join_url: item.meet_join_url,
        zoom_join_url: item.zoom_join_url,
      },
      id: item.id,
      title: item.summary,
      date: new Date(item.start_date),
      backgroundColor: item.color,
      color: item.color,
    };
  });
  const calEvents = {
    events: ev,

    eventClick: function (info: any) {
      info.jsEvent.preventDefault(); // don't let the browser navigate
    },
  };

  useEffect(() => {
    // fetching all board calender events
    getBoardCalenderEvents(props.cal_id).then((res) => {
      props.setBoardCalEvents(res.Board_Calendar_Events);
    });
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  useEffect(() => {
    setBoardEvents(Object.values(props.boardEvents));
  }, [props.boardEvents]);

  // function to close a popup on outside click
  const handleClick = (e: any) => {
    if (popupRef.current && !popupRef.current.contains(e.target)) {
      return;
    }
  };
  const view = React.useMemo(() => {
    return {
      calendar: { popover: true, count: true },
    };
  }, []);

  const handleSelect = (info: any) => {
    const { start, end } = info;
    showModal(false);

    if (modal) {
      setEvents([
        ...events,
        {
          start,
          end,
          title: modal,
          // id: uuid(),
        },
      ]);
    }
  };

  const moreClick = (e: any) => {
    var parent = e.jsEvent.target.parentElement;

    let p: any = parent;
    for (let i = 4; i > 0; i--) {
      p = p.parentNode;
    }
  };

  useEffect(() => {
    if (selectedEvent) {
      const popupWidth = popupRef.current?.clientWidth;
      const popupHeight = popupRef.current?.clientHeight;
      const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth;

      if (selectedEvent.jsEvent.pageX + popupWidth > windowWidth) {
        if (popupRef.current && popupWidth) {
          popupRef.current.style.left =
            selectedEvent.jsEvent.pageX - popupWidth + "px";
        }
      } else {
        if (popupRef.current) {
          popupRef.current.style.left = selectedEvent.left + "px";
        }
      }

      if (popupRef.current && popupHeight) {
        if (selectedEvent.jsEvent.pageY + popupHeight > windowHeight) {
          popupRef.current.style.top =
            selectedEvent.jsEvent.pageY - popupHeight + "px";
        }
      } else {
        if (popupRef.current) {
          popupRef.current.style.top = selectedEvent.top + "px";
        }
      }
    }
    return () => {};
  }, [left, top]);

  return (
    <div className="    h-full flex flex-col">
    {/* <TabAction><></>
    </TabAction> */}

    <div className="cal-panal rounded  h-full px-4 pb-4" style={{height:"calc(100vh - 95.4px)"}}>
      {editEventPopup ? (
        <div
          className={` animate-popover_fadeIn justify-center bg-black bg-opacity-50 items-center flex   fixed inset-0  outline-none focus:outline-none z-20`}
        >
          <div className="z-50 w-192 bg-white rounded  mt-12 ">
            <CardEventSchedularPopup
              type="edit"
              card_id={selectedEvent.event.extendedProps.customEvent.cardID}
              event_id={selectedEvent.event.extendedProps.customEvent.id}
              click={(e: boolean) => setEditEventPopup(e)}
            />
          </div>
        </div>
      ) : null}

      <div className="  rounded  w-full h-full    z-0  ">
        <FullCalendar
          dayMaxEvents={window.screen.height > 900 ? 2 : 1}
          height="parent"
          editable
          eventClick={(eventObj) => {
            (setSelectedEvent(eventObj), showModal(true)),
              setTop(eventObj.jsEvent.pageY),
              setLeft(eventObj.jsEvent.pageX);
          }}
          selectable
          select={handleSelect}
          events={calEvents.events}
          eventDisplay="block"
          titleFormat={{
            year: "numeric",
            month: "long",
          }}
          navLinks={true}
          weekNumberCalculation="ISO"
          // customButtons={{
          //   new: {
          //     text: "new",
          //     click: () => showModal(true),
          //   },
          //   custom1: {
          //     text: `Calendar - ${props.currentOpening.name}`,
          //   },
          // }}
          headerToolbar={{
            left: "today prevYear,prev,next,nextYear title",
            center: "custom1",
            right: " dayGridMonth,timeGridWeek,timeGridDay",
          }} 
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          views={view}
          moreLinkClick={(e) => moreClick(e)}
        />{" "}
        <div
          ref={popupRef}
          style={{
            left: selectedEvent?.jsEvent.pageX + "px",
            top: selectedEvent?.jsEvent.pageY + "px",
            position: "absolute",
            zIndex: 10000,
          }}
          className=""
        >
          {modal ? (
            <EventPopupCal
              currentEvent={selectedEvent}
              click={(open) => showModal(open)}
              edit={(editEventPopup) => setEditEventPopup(editEventPopup)}
            />
          ) : null}
        </div>
      </div>
    </div>  </div>

  
  );
};

const mapStateToProps = (state: any) => ({
  boardEvents: state.opening.calendar.currentBoardEvents,
});

const mapPropsToState = (dispatch: Dispatch) => ({
  setBoardCalEvents: (events: Event[]) => dispatch(setBoardCalEvents(events)),
});
export default connect(mapStateToProps, mapPropsToState)(MyCalendar);
