import React from "react";
import { determineTextColor } from "../../../utils/CalculateBrightnessOfColor"
type ButtonProps = {
  buttonName: string;
  color?: string;
  click?: (e: any) => void;
  isDisabled?: boolean;
  width?: string;
  tabindex?: number;
};

const Button = (props: ButtonProps) => {
  return (
    <React.Fragment>
      <button
        onClick={props.click}
        tabIndex={props.tabindex ? props.tabindex : 0}
        disabled={props.isDisabled}
        style={{ backgroundColor: props.color, color: props.color && determineTextColor(props.color) }}
        className={` w-auto ${
          props.isDisabled
            ? props.color
              ? `bg-color-${props.color}  cursor-not-allowed bg-opacity-60`
              : `bg-blue-900 bg-opacity-50 cursor-not-allowed`
            : props.color
            ? `bg-color-${props.color}  `
            : `bg-blue-900`
        } font-normal  text-sm w-auto truncate max-w-full px-3 py-1.5 mr-1 rounded outline-none focus:outline-none cursor-pointer`}
      >
        {props.buttonName}
      </button>
    </React.Fragment>
  );
};
export default Button;
