import React from "react";
const TopBubble = () => {
  return (
    <>
      <div className="absolute ">
        <div className="relative -top-80 right-150 rounded-full h-96 w-96 bg-tacitbase-secondary3  mt-4 bg-opacity-60  ml-20 2xl:-top-100 2xl:right-220 2xl:mr-48 "></div>
      </div>
      <div className="absolute ">
        <div className="relative -bottom-80 left-150 ml-6  rounded-full h-20 w-20 bg-tacitbase-tertiary2 bg-opacity-50 2xl:-bottom-96 2xl:left-220  2xl:ml-40 "></div>
        <div className="relative -top-80 left-100   rounded-full h-48 w-48 bg-tacitbase-primary1 bg-opacity-20   mt-4 2xl:-top-96 2xl:left-128"></div>
        <div className="absolute">
          <div className="relative -top-20 right-60  rounded-full h-10 w-10 bg-tacitbase-secondary5 bg-opacity-30  mt-4"></div>
        </div>
      </div>
    </>
  );
};
export default TopBubble;
