import React from 'react';


interface GoogleScholarprops{
    size?:number
}


const GoogleScholar = (props:GoogleScholarprops) => (
    <svg width={props.size?props.size:"26"} height={props.size?props.size:"26"} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 0L0 8.34783L4.34412 12H10V0Z" fill="#4086F4"/>
    <path d="M10 0L20 8.34783L15.6533 12H10V0Z" fill="#326AC4"/>
    <circle cx="10" cy="14" r="6" fill="#A1C4FF"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M15.659 12H4.3418C5.16547 9.66962 7.38795 8 10.0004 8C12.6128 8 14.8353 9.66962 15.659 12Z" fill="#77A9FA"/>
    </svg>
    
);

export default GoogleScholar;
