export function set_client_id(state: any, action: any) {
  return {
    ...state,
    clientId: action.payload,
  };
}

export function topic_subscription(state: any, action: any) {
  return {
    ...state,
    topicSubscription: action.payload,
  };
}

export function reset_ws(state: any) {
  return {
    ...state,
    ws: null,
  };
}

export function change_filledFeedbackForm_state(state: any) {
  return {
    ...state,
    filledFeedbackForm: true,
  };
}
