import React from "react";
const FavoriteFilledIcon = (props: any) => {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Favorite Filled</title>
      <g
        id="Icons-/-16x16-/-Favorite-Filled"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <polygon
          id="Combined-Shape"
          fill={`${props.fill ? props.fill : "#161E25"}`}
          points="8 11.5 2.70993273 16.2811529 4.72024568 9.39057647 -0.559508647 5.35942353 6 5.35942353 8 0 10 5.35942353 16.5595086 5.35942353 11.2797543 9.39057647 13.2900673 16.2811529"
        ></polygon>
      </g>
    </svg>
  );
};
export default FavoriteFilledIcon;
