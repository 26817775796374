import React, { useState } from "react";
import ListNameInput from "../listNameInput/ListNameInput";
import { ADD_LIST } from "../../../constant";
import AddIcon from "../../../assets/Add";
const CreateList = () => {
  const [listInput, showListInput] = useState(false);
  const showListHandle = () => showListInput(!listInput);
  if (listInput)
    return <ListNameInput click={(e: boolean) => showListInput(e)} />;
  return (
    <React.Fragment>
      <div className="flex items-start">
        <button
          data-testid="testBtn"
          className="flex flex-no-shrink focus:outline-none hover:bg-opacity-60 hover:bg-black mr-2 bg-black bg-opacity-50 text-sm rounded text-white w-70 cursor-pointer font-Inter"
          onClick={showListHandle}
        >
          <div className="p-2.5 text-xs font-light tracking-wider flex">
            <AddIcon fill="#ffff" />
            <span className="text-xs tracking-wider font-light ml-1">
              {ADD_LIST}
            </span>
          </div>
        </button>
      </div>
    </React.Fragment>
  );
};
export default CreateList;
