import React from "react";
import CloseIcon from "../../../assets/Close";
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";

type PopupTitleProps = {
  PopupTitle: string;
  close?: (e: boolean) => void;
  font?: string;
  back?: () => void;
};

const PopupTitle = (props: PopupTitleProps) => {
  return (
    <React.Fragment>
      <div className="flex ">
        {props.back ? (
          <button
            data-testid="backTestBtn"
            className="flex cursor-pointer justify-center items-center focus:outline-none mx-2"
            onClick={props.back}
          >
            <i className="fa fa-angle-left fa-lg" aria-hidden="true"></i>
          </button>
        ) : null}

        <div
          className={`flex justify-center tracking-normal font-normal  items-center ${
            props.font ? props.font : `text-sm text-gray-500`
          } w-full`}
        >
          {capitalizeFirstLetter(props.PopupTitle)}
        </div>

        <button
          className="flex cursor-pointer justify-center items-center focus:outline-none mx-2"
          data-testid="PopupTitleTestBtn"
          onClick={() => {
            if (props.close) props.close(false);
          }}
          tabIndex={0}
        >
          <CloseIcon />
        </button>
      </div>
      <div className="flex mt-2">
        <hr
          className="w-full"
          style={{ borderColor: "#0F0A0D", opacity: "0.3" }}
        ></hr>
      </div>{" "}
    </React.Fragment>
  );
};
export default PopupTitle;
