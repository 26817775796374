import React, { useEffect, useRef, useState } from "react";
import PopupTitle from "../../common/popupTitle/PopupTitle";
import { COLOR_DATA_LABEL, colorDictionary } from "../../../constant";
import {
  currentApplicant,
  GetOpening,
  Label,
  updatedColor,
} from "../../../type/type";
import ColorSelection from "../../createBoardPopup/colorSelection/ColorSelection";

// Redux stuff
import { connect } from "react-redux";
import {
  saveBoardLabel,
  updateLabelState,
  saveCandidateLabel,
} from "../../../redux/actions/opening/action";
import { Dispatch } from "redux";

// Api's
import { addLabelToCDBAndReview, editLabel } from "../../../lib/api";
import AddLabelInCDB from "./AddLabelInCDB";
import { AddLabelToCDBAndReviewReq } from "../../../lib/apiReq";
export type LabelPopupProps = {
  currentOpening: GetOpening;
  width?: string;
  currentApplicant: currentApplicant;
  saveCandidateLabel(label: any): void;
  click: (e: boolean) => void;
  updateApplicantLabel(updatedLabel: any): void;
  refer: any;
  clientId: any;
  searchLabelChar: string;
  selectedLabel: (label: any) => void;
  clearSearchField: () => void;
};
const LabelPopup = (props: LabelPopupProps) => {
  const labelInputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (labelInputRef.current && labelInputRef.current.value) {
      labelInputRef.current.focus();
    }
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);
  const handleClick = (e: any) => {
    if (props.refer.current && !props.refer.current.contains(e.target)) {
      props.click(false);
      return;
    }
  };
  useEffect(() => {
    let bgColor: string = "";
    COLOR_DATA_LABEL.map((color: updatedColor) => {
      if (color.isSet) {
        bgColor = color.colorCode;
      }
    });
    setSelectedColorCode(bgColor);
  }, []);
  const [labelInput, showLabelInput] = useState<boolean>(false);
  const [selectedColorCode, setSelectedColorCode] = useState("#F58231");
  const [updateFlag, setUpdateFlag] = useState(false);

  const [updateLabelObj, setUpdateLabelObj] = useState({
    color: "",
    id: "",
    name: "",
  });

  const createLabelOfSearch = () => {
    showLabelInput(!labelInput);
  };

  const setSelectedColor = (color: string) => {
    setSelectedColorCode(colorDictionary[color]);
  };
  const updateLabel = (label: Label) => {
    setUpdateLabelObj(label);
    setSelectedColorCode(label.color);
    showLabelInput(true);
    setUpdateFlag(true);
  };
  const saveLabel = () => {
    const req = {
      id: updateLabelObj.id,
      color: selectedColorCode || updateLabelObj.color,
      name: updateLabelObj.name,
      opening_id: props.currentOpening.id,
      socket_client_id: props.clientId,
    };
    editLabel(req).then((applicant_label: any) => {
      props.updateApplicantLabel(applicant_label);
      showLabelInput(false);
      // setLabelName("");
      setUpdateFlag(false);
    });
  };

  const createLabel = () => {
    if (props.searchLabelChar.trim().length > 0) {
      let label: AddLabelToCDBAndReviewReq = {
        entity: "candidates",
        name: props.searchLabelChar,
        color: selectedColorCode,
        socket_client_id: props.clientId,
      };
      addLabelToCDBAndReview(label).then((CDBlabel) => {
        props.saveCandidateLabel(CDBlabel);
        props.selectedLabel(CDBlabel.id);
        props.clearSearchField();
      });
    }
  };

  return (
    <React.Fragment>
      <div
        ref={props.refer}
        className={`flex flex-col mb-1 z-10
          bg-white text-gray-800 tracking-normal text-sm border border-gray-300  font-normal rounded ${
            props.width ? props.width : `w-72`
          }`}
      >
        <div className="my-3">
          {labelInput ? (
            <PopupTitle
              PopupTitle="Label"
              close={props.click}
              back={() => {
                showLabelInput(false);
              }}
            />
          ) : (
            <PopupTitle PopupTitle="Label" close={props.click} />
          )}
        </div>
        {labelInput ? (
          <>
            <div className="mx-3">
              <div className="color-preview-panel">
                <div className="flex flex-row  flex-wrap h-90 w-full  text-white ">
                  {Object.keys(colorDictionary).map((color: string, idx) => (
                    <div key={idx}>
                      <ColorSelection
                        colorName={colorDictionary[color]}
                        isSet={selectedColorCode === colorDictionary[color]}
                        click={() => setSelectedColor(color)}
                        showMore={false}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="m-3  ">
              <button
                className="submit-btn text-sm"
                data-testid="saveLabelTestBtn"
                onClick={updateFlag ? saveLabel : createLabel}
              >
                <span className="text-xs">
                  {" "}
                  {updateFlag ? "Save" : "Create"}
                </span>
              </button>
            </div>
          </>
        ) : (
          <AddLabelInCDB
            update={(label: Label) => updateLabel(label)}
            click={() => createLabelOfSearch()}
            flag={false}
            close={props.click}
            searchLabelChar={props.searchLabelChar}
            selectedLabel={props.selectedLabel}
          />
        )}
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
  currentApplicant: state.opening.currentApplicant,
  clientId: state.opening.clientId,
  candidateLabels: state.opening.candidateLabels,
});
const mapPropsToState = (dispatch: Dispatch) => ({
  saveCandidateLabel: (label: any) => dispatch(saveCandidateLabel(label)),
  saveOpeningLabel: (label: any, openingId: string) =>
    dispatch(saveBoardLabel(label, openingId)),
  updateApplicantLabel: (updatedLabel: any) =>
    dispatch(updateLabelState(updatedLabel)),
});
export default connect(mapStateToProps, mapPropsToState)(LabelPopup);
