import React, { useState } from "react"
import { connect } from "react-redux"
import { CandidateDetailReview, CandidatesInReview, InitLabel, Label } from "../../type/type"
import { LabeledIconButton } from "../../ant/Button"
import { addLabelToCDBAndReview, attachLabelToReview, deleteLabelFromReview, editLabelOfCDB } from "../../lib/api"
import { REVIEWS, SOCKET_CLIENT_ID } from "../../constant"
import { addLabelToCandidateInReview, removeLabelFromReview, updateReviewLabel } from "../../redux/actions/opening/action"
import { Dispatch } from "redux"
import BulkLabel from "../common/AddLabelPopover"
import { CheckOutlined, LoadingOutlined } from "@ant-design/icons"
import { determineTextColor } from "../../utils/CalculateBrightnessOfColor"

const AddLabelToReview = (props: {
    applicationId: string,
    currentCandidateInReview: CandidateDetailReview,
    saveReviewLabel(rID: string, label: any): void,
    updateReviewLabel(updatedLabel: any): void,
    reviewLabels: InitLabel,
    candidatesInReview: CandidatesInReview,
    removeLabelFromReview: (rId: string, label: string[]) => void
}) => {
    const [labels, setLabels] = useState<any>([])
    const [loader, showLoader] = useState(false)
    const [loadedLabel, setloadedLabel] = useState("")

    const handleCreateLabel = (label: { value: string, color: string }) => {
        const labelreq = {
            name: label.value,
            color: label.color,
            entity: REVIEWS,
            socket_client_id: sessionStorage.getItem(SOCKET_CLIENT_ID),

        }
        addLabelToCDBAndReview(labelreq).then((lres) => {
            attachLabelToReview({
                review_id: props.currentCandidateInReview.id,
                label_id: lres.id,
                socket_client_id: sessionStorage.getItem(SOCKET_CLIENT_ID),
            }).then((res) => {

                props.saveReviewLabel(props.currentCandidateInReview.id, {
                    id: res.labels.id,
                    name: lres.name,
                    color: lres.color,
                })
            })
        })
        setLabels([...labels, label])
    }
    const handleEditLabel = (label: { id: string, value: string, color: string }) => {
        const req = {
            id: label.id,
            color: label.color,
            name: label.value,
            entity: "review",
            // socket_client_id: props.clientId,
        }
        editLabelOfCDB(req).then((applicant_label: any) => {
            props.updateReviewLabel(applicant_label)
        })
        setLabels([...labels, label])
    }

    const determineIcon = (label: Label) => {
        const isLabelExist = props.currentCandidateInReview.labels.map((label: Label) => label.id).includes(label.id)
        const isLoading = label.id === loadedLabel
        if (isLoading && loader) {
            // Show loading icon for the current label being processed
            return <LoadingOutlined style={{ color: determineTextColor(label.color) }} />
        }

        if (isLabelExist) {
            // If the label count equals the total number of selected rows, return a checkmark icon
            return <CheckOutlined style={{ color: determineTextColor(label.color) }} />
        } else {
            // Otherwise, return a mixed symbol
            return (
                null
            )
        }
    }

    const addLabelToReview = (labelData: Label) => {
        setloadedLabel(labelData.id)
        let found: boolean = false
        if (props.reviewLabels[labelData.id]) {
             found = props.currentCandidateInReview.labels.some((label: Label) => label.id === labelData.id);
            if (found) {
                showLoader(true)

                deleteLabelFromReview({
                    review_ids: [props.currentCandidateInReview.id],
                    label_ids: [labelData.id],
                    // socket_client_id: props.clientId,
                }).then((res) => {
                    showLoader(false)
                    props.removeLabelFromReview(props.currentCandidateInReview.id, res.label_ids)
                }).catch(() => {
                    showLoader(false)

                })
            } else if (!found) {
                showLoader(true)
console.log("true");

                attachLabelToReview({
                    review_id: props.currentCandidateInReview.id,
                    label_id: labelData.id,
                    socket_client_id: "",
                }).then((res) => {
                    showLoader(false)
                    console.log("sdasas");
                    
                    props.saveReviewLabel(props.currentCandidateInReview.id, {
                        id: res.labels.id,
                        name: res.labels.name,
                        color: res.labels.color,
                    })
                }).catch(() => {
                    showLoader(false)

                })
            }
        }
    }

    return (
        <div>
            <BulkLabel determineIcon={determineIcon} onAddLabel={addLabelToReview} selectedLabels={props.currentCandidateInReview.labels.map((label: Label) => label.id)} element={<LabeledIconButton label="Add label" onClick={() => { }} />} onEditLabel={handleEditLabel} onCreateLabel={handleCreateLabel} defaultTitle="Create Label" initialLabels={Object.values(props.reviewLabels)} />
        </div>
    )
}
const mapStateToProps = (state: any) => ({
    applicationId: state.opening.applicationId,
    currentCandidateInReview: state.opening.currentCandidateInReview,
    reviewLabels: state.opening.reviewLabels,
    candidatesInReview: state.opening.candidatesInReview,

})
const mapPropsToState = (dispatch: Dispatch) => ({
    saveReviewLabel: (rId: string, label: any) =>
        dispatch(addLabelToCandidateInReview(rId, label)),
    updateReviewLabel: (label: any) => dispatch(updateReviewLabel(label)),
    removeLabelFromReview: (rId: string, label: string[]) =>
        dispatch(removeLabelFromReview(rId, label)),

})
export default connect(mapStateToProps, mapPropsToState)(AddLabelToReview)
