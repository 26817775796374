import React from "react";
import InvitedSignupIndex from "../../components/signUp/InvitedSignupIndex";
import TB_ORANGE_LOGO from "../../images/TB full orange.png"


const InvitedSignUpPageLayout: React.FC = () => {
  return (
    <>
      <div className="flex w-full flex-col  h-full bg-cover  bg-bgImgType1 ">
        <div className="  justify-center items-center">
          <div className="bg-transparent pt-6 pl-6 ">
            <img src={TB_ORANGE_LOGO} alt="" width="200px" style={{ backgroundColor: 'transparent' }} />
          </div>
        <div className="flex  pt-16   xl:space-x-4  w-full h-full overflow-y-scroll ">
          <div className=" flex  justify-center h-full items-start w-full">

            <div
              className="bg-white     xl:p-8 p-4 rounded xl:m-10 m-4   "
              style={{
                width: 463,
                boxShadow: "2px 4px 10px 0px #00000040",
                border: "1px solid #D9D9D9",
              }}
            >
              <InvitedSignupIndex />
            </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvitedSignUpPageLayout;
