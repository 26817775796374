import React, {useRef, useState } from "react"
import {  Scope} from "../../../type/type.d"
import { Overlay } from "../../hook/Overlay";
import ShowMember from "./ShowMember";
import { Popover } from "antd/lib"
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter"
import { MEMBERS } from "../../../constant"
import { IconButton, LabeledIconButton } from "../../../ant/Button"
import { CloseOutlined, TeamOutlined } from "@ant-design/icons"
import { Typography } from "antd"
const { Text } = Typography


const AddBoardMember = () => {
  let refer = useRef(null);
  const [open, setOpen] = useState(false);


  return (
    <div className="z-10">
      <Popover onOpenChange={(open) => setOpen(open)}
        open={open} title={<div className="flex w-full bg-white items-center">
          <Text className="w-full flex justify-center">
            <Text>{capitalizeFirstLetter(MEMBERS)}</Text>
          </Text>
          <LabeledIconButton
            type="text"
            label=""
            onClick={() => setOpen(false)}
            icon={<CloseOutlined />}
          />
        </div>} trigger={["click"]}
        content={<Overlay onClose={() => setOpen(false)} scope={Scope.Popover} reference={refer}>
          <ShowMember click={() => setOpen(false)} refer={refer} /></Overlay>
        }>

        <div  >
        {/* <div
          className="nav-btn items-center"
            onClick={() => setOpen(true)}
          aria-hidden
          data-testid="openMemberPopup" style={{height:30}}
        >
          {" "}
          {props.openingMembers[props.currentOpening.id] && Object.keys(props.members).length > 0 ? (
            <>
              {
                Object.values(
                  props.openingMembers[props.currentOpening.id]
                ).filter((member: openingMemberType) => props.members && props.members[member.id].registrations[0].data.activated).length > 5 ? (
                <AddIcon fill="#fff" size="12px" />
              ) : (
                <MembersSmallIcon fill="#fff" />
              )}
              <span className="text-xs font-extralight">
                {Object.values(
                  props.openingMembers[props.currentOpening.id]
                ).filter((member: openingMemberType) => props.members[member.id].registrations[0].data.activated).length > 5
                  ? Object.values(
                    props.openingMembers[props.currentOpening.id]
                  ).filter((member: openingMemberType) => props.members[member.id].registrations[0].data.activated).length - 5
                  : null}
              </span>{" "}
            </>
          ) : null}
        </div> */}
          <IconButton
          tooltipTitle="Members"
            className="nav-btn"
            onClick={() => setOpen(true)}
           icon={<TeamOutlined />}
            />
        </div>
      </Popover>
    </div>
  );
};

export default (AddBoardMember);
