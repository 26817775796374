import React, { useEffect, useState } from "react"
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter"
import { DragDropContext, Droppable } from "react-beautiful-dnd"
import FormBuilderPanal from "../formBuilder/FormBuildPanal"
import FormBuilderActionButton from "../formBuilder/FormBuilderActionBuilder"
import { advanceFormDesignComponent, REQUIRED_FIELD_OF_DATABASE, UNSAVED_WARNING } from "../../constant"
import { FormBuilder } from "../../type/type"
import { ExtendedTableColumn } from "../formBuilder/FormBuilder"
import { getCDBTable, updateFormById } from "../../lib/api"
import { connect } from "react-redux"
import { Divider, Space, Tag, Typography } from "antd/lib"
import Title from "antd/es/typography/Title"
import { LabeledIconButton } from "../../ant/Button"
import { CheckOutlined, ExclamationCircleOutlined, LoadingOutlined } from "@ant-design/icons"
const { Text } = Typography
const emptyFormBuilder: FormBuilder = {
  id: "",
  title: "",
  form_data: { elements: [] },
  form_url: "",
  created_at: "",
  updated_at: "",
  status: "",
  creater_id: "",
  company_logo: "",
}
const initialExtendedTableColumn: ExtendedTableColumn = {
  name: "",
  type: "",
  secret: false,
  custom: false,
  view: "",
  isRequired: false,
  order: 0,
  label: "",
  style: "",
  size: "",
  subheading: "",
  height: 0,
  link: ""
}
type BoardFormProps = {
  currentTrackerForm: FormBuilder
}
const BoardForm = (props: BoardFormProps) => {
  const [selectedComponent, setCurrentComponent] =
    useState<ExtendedTableColumn>(initialExtendedTableColumn)
  const [showPreview, setShowPreview] = useState<boolean>(false)
  const [formField, addFormField] = useState<ExtendedTableColumn[]>(props.currentTrackerForm.form_data.elements)
  const [updatedFormStatus, setupdatedFormReq] =
    useState<FormBuilder>(emptyFormBuilder)
  const [formCDBdata, setRequireFormField] = useState<ExtendedTableColumn[]>(
    []
  )
  const [isUnsavedChanges, setUnsavedChangesStatus] = useState<boolean>(
    false
  )
  const [savedChangesVisible, setSavedChangesVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    addFormField(props.currentTrackerForm.form_data.elements)
  }, [props.currentTrackerForm])
  const prepareFormData = () => {
    const formDataReq: any = {}
    const hasFormTitleChanged = (updatedFormStatus.title !== emptyFormBuilder.title)
    if (hasFormTitleChanged)
      formDataReq["title"] = updatedFormStatus.title

    const hasFormFieldsChanged = JSON.stringify(updatedFormStatus.form_data) !== JSON.stringify(emptyFormBuilder.form_data)
    if (hasFormFieldsChanged) {
      formDataReq["form_data"] = { elements: updatedFormStatus.form_data.elements }
    }

    return formDataReq
  }
  const saveForm = () => {
    let formData = new FormData()
    formData.append("form_data", JSON.stringify(prepareFormData()))

    updateForm(formData)
  }
  const updateForm = (updateFormReq: any) => {
    setUnsavedChangesStatus(false)
    setLoading(true)
    updateFormById(props.currentTrackerForm.id, updateFormReq)
      .then((form: any) => {
        setLoading(false) // Stop loading
        setSavedChangesVisible(true)
        setTimeout(() => {
          setSavedChangesVisible(false)
        }, 2000)
        addFormField(form.form_data.elements)
        setupdatedFormReq(emptyFormBuilder)
        setUnsavedChangesStatus(false)
        setCurrentComponent(initialExtendedTableColumn)

      }).catch(() => {
        setLoading(false),
          setSavedChangesVisible(false)
        setUnsavedChangesStatus(false)
      })

  }
  const handleClick = (field: ExtendedTableColumn) => {
    addFormField([...formField, field])
    setCurrentComponent(field)
    setupdatedFormReq((prevFormStatus) => ({
      ...prevFormStatus,
      form_data: {
        elements: [...formField, field],
      },
    }))
    setUnsavedChangesStatus(true)
  }
  const makeFieldRequired = (e: any) => {
    const updatedArray: any = [...formField]
    updatedArray[selectedComponent.order - 1] = {
      ...updatedArray[selectedComponent.order - 1],
      [e.target.name]: e.target.checked,
    }
    addFormField(updatedArray)
    setupdatedFormReq((prevFormStatus) => ({
      ...prevFormStatus,
      form_data: {
        ...prevFormStatus.form_data,
        elements: updatedArray, // Use the modified array 'a' directly
      },
    }))
    setUnsavedChangesStatus(true)

  }
  const DiscardChanges = () => {
    addFormField(props.currentTrackerForm.form_data.elements)
    setCurrentComponent(initialExtendedTableColumn)
    setLoading(false),
      setSavedChangesVisible(false)
    setUnsavedChangesStatus(false)
  }
  useEffect(() => {
    getCDBTable().then((res) => {
      const { customFalse, resWithLabels } = res.columns.reduce(
        (result: { customFalse: any[]; resWithLabels: any[] }, item, index) => {
          result.resWithLabels.push({
            ...item,
            label: item.view,
            isRequired: false,
          })
          if (REQUIRED_FIELD_OF_DATABASE.includes(item.name)) {
            result.customFalse.push({
              ...item,
              label: item.view,
              order: index + 1,
              isRequired: true,
            })
          }
          return result
        },
        { customFalse: [], resWithLabels: [] }
      );

      customFalse.push({
        label: "submit",
        name: "button",
        order: customFalse.length,
        secret: false,
        type: "button",
      })
      setRequireFormField([...formCDBdata, ...resWithLabels])
    })
  }, [])
  const handleSelectComponent = (formElement: any) => {
    setCurrentComponent(formElement)
  }
  function handleOnDragEnd(result: any) {
    let currentComponent = formField[result.source.index]
    if (!result.destination) return
    const items = Array.from(formField)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)
    items[result.draggableId].order = parseInt(result.draggableId) + 1
    items.forEach((item, index) => {
      item.order = index + 1
    })
    addFormField(items)
    currentComponent.order = result.destination.index + 1
    setCurrentComponent(currentComponent)
    setupdatedFormReq((prevFormStatus) => ({
      ...prevFormStatus,
      form_data: {
        ...prevFormStatus.form_data,
        elements: items, // Use the modified array 'a' directly
      },
    }))
    setUnsavedChangesStatus(true)

  }

  const handleClickRemove = (index: number) => {
    addFormField((a: ExtendedTableColumn[]) => {
      a.splice(index, 1)
      a.forEach((item: ExtendedTableColumn, index) => {
        item.order = index + 1
      })
      setupdatedFormReq((prevFormStatus) => ({
        ...prevFormStatus,
        form_data: {
          ...prevFormStatus.form_data,
          elements: a, // Use the modified array 'a' directly
        },
      }))
      return [...a]
    })

    setCurrentComponent(initialExtendedTableColumn)

    setUnsavedChangesStatus(true)
  }
  const handleChangeLabel = (e: any) => {
    const updatedArray: ExtendedTableColumn[] = [...formField]
    updatedArray[selectedComponent.order - 1] = {
      ...updatedArray[selectedComponent.order - 1],
      ...e
    }
    setCurrentComponent(updatedArray[selectedComponent.order - 1])
    addFormField(updatedArray)
    setupdatedFormReq((prevFormStatus) => ({
      ...prevFormStatus,
      form_data: {
        ...prevFormStatus.form_data,
        elements: updatedArray, // Use the modified array 'a' directly
      },
    }))
    setUnsavedChangesStatus(true)

  }

  return (
    <>
          <div className="">

          <div className="h-12 bg-white flex flex-1 justify-end items-center px-4 mx-4 rounded-t">
          {
          props.currentTrackerForm.id ? <> {isUnsavedChanges && <Tag icon={<ExclamationCircleOutlined />} color="warning">
          {capitalizeFirstLetter(UNSAVED_WARNING)}
        </Tag>}

          {loading ? (
            <Tag icon={<LoadingOutlined />} color="processing">
              {capitalizeFirstLetter("Saving")}
            </Tag>
          ) : (
            savedChangesVisible && (
              <Tag icon={<CheckOutlined />} color="success">
                {capitalizeFirstLetter("saved")}
              </Tag>
              )
          )}</> : <></>}
       
        <Space>
          {isUnsavedChanges &&
            <LabeledIconButton label={capitalizeFirstLetter("Discard")} 
            className="h-7"
             onClick={DiscardChanges} />
          }
        {props.currentTrackerForm.id && isUnsavedChanges &&
          <LabeledIconButton label={capitalizeFirstLetter("Save and publish")} 
          className="h-7"
           onClick={saveForm} />
          }
        </Space>
      </div>
      <div className="px-4" style={{
        height: "calc(-166px + 100vh)"
      }} >
        <div className=" h-full w-full bg-white rounded-b border  " >
          <div className="flex h-full" >
            <div className="w-72 animate-popover_fadeIn commentContent flex-col items-start justify-center flex  overflow-y-scroll flex-shrink-0">
              <Title level={5} className=" pt-2 px-4">
                {"Form fields"}
              </Title>
              <Divider style={{ margin: 0, width: "100%" }}></Divider>
              <div className="w-full  px-4 pr-2 mb-2 bg-white  rounded h-full overflow-y-scroll   select-none">
                <Space direction="vertical" className="my-2">
                  <Text strong className=" ">
                    {capitalizeFirstLetter("Basic elements")}
                  </Text>
                </Space>{" "}
                {/* <div className=" grid-cols-2 grid gap-2 p-2 "> */}
                <div>
                  <Space direction="vertical" className="w-full">
                    {advanceFormDesignComponent.map(
                      (field: any) =>
                        field && (
                          <>
                            <Tag
                              onClick={() =>
                                handleClick({
                                  ...field,
                                  order: formField.length + 1,
                                })
                              }
                              className="w-full p-2 bg-white "
                            >
                              <Text>{field.name}</Text>
                            </Tag>
                          </>
                        )
                    )}
                  </Space>
                </div>
                <Space direction="vertical" className="my-2">
                  <Text strong className="">
                  {capitalizeFirstLetter("Talent pool elements")}
                  </Text>
                </Space>
                <div>
                  <Space direction="vertical" className="w-full">
                    {formCDBdata.map(
                      (field: ExtendedTableColumn) =>
                        field && (
                          <Tag
                            key={field.name}
                            className={`${formField.some(
                              (item) => item.name === field.name
                            ) &&
                              " pointer-events-none"
                              } w-full p-2 `}
                            style={{
                              backgroundColor: formField.some((item) => item.name === field.name) ? '#F0EFFE' : 'initial',
                            }}
                            onClick={() =>
                              handleClick({
                                ...field,
                                order: formField.length + 1,
                              })
                            }
                            onKeyDown={() => { }}
                            tabIndex={0}
                            role="button"
                          >
                            <Text type={formField.some((item) => item.name === field.name) ? "secondary" : "secondary"}>{field.view}</Text>
                          </Tag>
                        )
                    )}
                  </Space>
                </div>
              </div>
            </div>
          <Divider type="vertical" className="h-full" style={{ margin: 0 }} />
            <div className="w-full p-4 overflow-y-hidden h-full justify-center items-center bg-gray-100 rounded flex">
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="1">
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{ width: "600px" }}
                      className="shadow-xl py-4 rounded-lg bg-white overflow-y-scroll h-full   border  "
                  >

                    <FormBuilderPanal
                      handleKeyPressed={() => { }}
                      handleChangeLabel={handleChangeLabel}
                      formField={Array.from(
                        formField
                      )}
                      selectedComponent={selectedComponent}
                      handleSelectComponent={(e: any) =>
                        handleSelectComponent(e)
                      }
                      preview={showPreview}
                      showPreview={() => setShowPreview(!showPreview)}
                      handleClickRemove={(index: number) =>
                        handleClickRemove(index)
                      }
                    />
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>{" "}
          </div>
        <Divider type="vertical" style={{ margin: 0, height: "100%" }}></Divider>

        <div className="w-72 overflow-x-scroll overflow-y-scroll  commentContent    rounded-r  flex-shrink-0 ">
          <div className="h-full overflow-hidden">

              <FormBuilderActionButton
              currentFormDetails={props.currentTrackerForm}
                selectedComponent={selectedComponent}
                handleChangeRequest={() => { }}
                handleChangeLabel={handleChangeLabel}
                clearSelectedElement={() =>
                  setCurrentComponent(initialExtendedTableColumn)
                }
                makeFieldRequired={makeFieldRequired}
              />
          </div>
            </div>
          </div>

        </div>
      </div>
      </div>
    </>

  )
}
const mapStateToProps = (state: any) => ({
    openingMembers: state.opening.currentOpening,
    loginUser: state.opening.loginUser,
    currentTrackerForm: state.opening.currentTrackerForm,
    topic: state.opening.topicSubscription,
    applicationId: state.opening.applicationId,
    cal_id: state.opening.calendar.currentCalenderId,
    orgStatus: state.opening.orgStatus,
    notificationCenter: state.opening.notificationCenter,

})

export default connect(mapStateToProps)(BoardForm)
