import React from 'react'
import { connect } from 'react-redux'
import { loginUser as LoginUserType } from './type/type';
import { tokenVerify } from './service/tokenVerify';
import { useHistory } from 'react-router-dom';

type OnboardingVerificationProps = {
  loginUser: LoginUserType;
};

const OnboardingVerification = (WrappedComponent: React.ComponentType<any>) => {
  const handleOnboarding = (props:OnboardingVerificationProps) => {
    const history = useHistory();
    if (tokenVerify()) {
      if (props.loginUser.user.verified && props.loginUser.user?.registrations?.length > 0) {
        history.replace("/o");
      }else{
         history.replace("/onboarding");
      }
    }
    return <WrappedComponent {...props} />;
  };

  return handleOnboarding;
};

const mapStateToProps = (state: any) => ({
  loginUser: state.opening.loginUser, 
});

export default (component: React.ComponentType<any>) =>
  connect(mapStateToProps)(OnboardingVerification(component));
