import React from 'react';
import { Button } from 'antd';
import QuickAddCandidate from '../../pages/pool/QuickAddCandidate';

const ActionButton: React.FC = () => {

  return (
    <Button.Group style={{ display: 'flex' }}>
      <QuickAddCandidate />
      </Button.Group>
  );
};

export default ActionButton;
