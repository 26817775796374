import { Space, Typography } from "antd";
import React from "react";
import CardWatcher from "./CardWatcher";
import AddMembers from "./AddMembers";
import CardLables from "./CardLables";
import AddAttachment from "./AddAttachment";
import ArchiveCard from "./ArchiveCard";
import CardEvent from "./CardEvent";
import CardEmailAndComment from "../../cardEmailCommentAction/CardEmailAndComment";
import CopyApplicant from "./CopyApplicant";
import MoveApplicant from "./MoveApplicant";
const { Text } = Typography;

const SideSectionComponent = () => {
  return (
    <div className="w-full">
      <div className="flex justify-start items-center border-b mt-0.5 h-11">
      <Text className="font-semibold px-4">Actions</Text>
      </div>
      <Space direction="vertical" className="w-full mt-2 px-4  space-y-1">
        <CardWatcher />
        <AddMembers />
        <CardLables />
        <AddAttachment /> 
        <MoveApplicant />
        <CopyApplicant />
        <ArchiveCard />
      <div className="flex justify-start items-center border-b mt-0.5 h-11">
      <Text className="font-semibold">Communications</Text>
      </div>
          <CardEmailAndComment/>
        <CardEvent />
      </Space>
    </div>
  );
};

export default SideSectionComponent;
