import React from "react"
import { activity, CandidateDetailReview, ReviewComments } from "../../type/type"
import { connect } from "react-redux"
import { ACTIVITY_TAG } from "../../constant"
import ShowCardActivity from "../card/activity/ShowCardActivity"

const DetailReviewActivity = (props: { reviewComments: ReviewComments, currentCandidateInReview: CandidateDetailReview }) => {
    const sortedActivities: any = props.reviewComments[props.currentCandidateInReview.id]
        ? Object.values(props.reviewComments[props.currentCandidateInReview.id]).sort(
            (a: any, b: any) => a.ACTIVITY_TAG && b.ACTIVITY_TAG &&
                new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        )
        : []

    return (
        <>
            <div
                style={{ height: "calc(100vh - 200px)" }}
                className="py-2 pb-2 pr-2 h-full overflow-y-scroll"
            >
                {sortedActivities.map((activityReview: activity) =>
                    activityReview.tag == ACTIVITY_TAG && <ShowCardActivity key={activityReview.id} activity={activityReview} />

                )}
            </div >
        </>
    )
}
const mapStateToProps = (state: any) => ({
    candidatesInReview: state.opening.candidatesInReview,
    members: state.opening.members,
    loginUser: state.opening.loginUser,
    currentCandidateInReview: state.opening.currentCandidateInReview,
    reviewComments: state.opening.reviewComments,
    reviewLabels: state.opening.reviewLabels,
    notificationCenter: state.opening.notificationCenter,

})
export default connect(mapStateToProps)(DetailReviewActivity)
