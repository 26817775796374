import React, { useEffect, useState } from "react";
import PopupTitle from "../../common/popupTitle/PopupTitle";
import LabelInput from "../../card/cardLabel/labelInput/LabelInput";
import { COLOR_DATA_LABEL, SOCKET_CLIENT_ID } from "../../../constant";
import { updatedColor } from "../../../type/type";
import ColorSelection from "../../createBoardPopup/colorSelection/ColorSelection";
import TacitbaseInput from "../../../ant/Input";
// import { toast } from "react-toastify";

// Redux stuff
import { connect } from "react-redux";
import {
  saveCardLabel,
  saveBoardLabel,
  updateLabelState,
} from "../../../redux/actions/opening/action";
import { Dispatch } from "redux";

// Api's
import {
  createOpeningLabel,
  createApplicantLabel,
  editLabel,
} from "../../../lib/api";

import { LabelPopupProps } from "../../../type/cardActionButton";
import Emoji from "../../Emoji";
import { Button } from "antd";
// import ShowError from "../../../service/ShowError";

const LabelPopup = (props: LabelPopupProps) => {

  let islimitexceed = false;
  const [loading,setLoading] = useState(false)
  const [isdisable ,setIsdisable] = useState(true);
  const [labelInput, showLabelInput] = useState<boolean>(false);
  const [defaultColors, setColors] = useState(COLOR_DATA_LABEL);
  const [selectedColorCode, setSelectedColorCode] =
    useState("green text-white");
  const [labelName, setLabelName] = useState<string>("");
  const [updateFlag, setUpdateFlag] = useState(false);

  const [updateLabelObj, setUpdateLabelObj] = useState({
    color: "",
    id: "",
    name: "",
  });

  const createLabelOfSearch = (e: any) => {
    if(e.length >0 && e.length<50){
      setIsdisable(false);
    }
    showLabelInput(!labelInput);
    setLabelName(e);
  };
  useEffect(()=>{
    
    if (labelName.length<=0 || labelName.length>=50){
      setIsdisable(true);
  }else{
    setIsdisable(false);
  }
  },[labelName])
  const validateLabelName = (e: any) => {
    setLabelName(e.target.value);
    // console.log(e.target.value);
  };
 


  // function to add emoji with label name
  const onEmojiSelect = (emoji: string): void => {
    // console.log("Selected Emoji:", emoji);
    setLabelName((prevLabelName) => prevLabelName + emoji);
  };
  
  const setSelectedColor = (index: number) => {
    let updatedColors: any = [];
    COLOR_DATA_LABEL.map((color: updatedColor, i) => {
      if (i === index) {
        color.isSet = true;
        setSelectedColorCode(color.colorCode);
        updatedColors.push(color);
      } else {
        color.isSet = false;
        updatedColors.push(color);
      }
    });
    setColors(updatedColors);
  };
  useEffect(() => {
    let bgColor: string = ""
    COLOR_DATA_LABEL.map((color: updatedColor) => {
      if (color.isSet) {
        bgColor = color.colorCode
      }
    })
    setSelectedColorCode(bgColor)
  }, []);
  const updateLabel = (label: any) => {
    setIsdisable(false);
    let updatedColors: any = [];
    COLOR_DATA_LABEL.map((color: updatedColor) => {
      if (color.colorCode == label.color) {
        color.isSet = true;
        setSelectedColorCode(color.colorCode);
        updatedColors.push(color);
      } else {
        color.isSet = false;
        updatedColors.push(color);
      }
    });
    setColors(updatedColors);
    setUpdateLabelObj(label);
    setLabelName(label.name);
    setSelectedColorCode(label.color);
    showLabelInput(true);
    setUpdateFlag(true);
  };
  const saveLabel = () => {

    const req = {
      id: updateLabelObj.id,
      color: selectedColorCode || updateLabelObj.color,
      name: labelName || updateLabelObj.name,
      opening_id: props.currentOpening.id,
      socket_client_id: sessionStorage.getItem(SOCKET_CLIENT_ID),
      applicant_id: props.currentApplicant.id,
    };
    setLoading(true);
    editLabel(req).then((applicant_label: any) => {
      
      props.updateApplicantLabel(applicant_label);
      showLabelInput(false);
      setLabelName("");
      setUpdateFlag(false);
      setLoading(false);
    }).catch(() => { setLoading(false)});
  };
  const createLabel = () => {
    if (labelName.length<= 0 || labelName.length >= 50) {
      islimitexceed = true;
      showLabelInput(true);
    }
 // if label length is greater than 50
     else{
      const label = {
        name: labelName,
        color: selectedColorCode,
        opening_id: props.currentOpening.id,
        applicant_id: props.currentApplicant.id,
        socket_client_id:sessionStorage.getItem(SOCKET_CLIENT_ID),
      };
       setLoading(true);
      createOpeningLabel(label)
        .then((label: any) => {
          const req = {
            name: labelName,
            color: selectedColorCode,
            applicant_id: props.currentApplicant.id,
            label_id: label.id,
            opening_id: props.currentOpening.id,
            socket_client_id:sessionStorage.getItem(SOCKET_CLIENT_ID),
          };
          createApplicantLabel(req)
            .then((applicant_label: any) => {
              props.saveApplicantLabel(applicant_label);
              showLabelInput(false);
              setLabelName("");
              setLoading(false);
            })
          
        }).catch(() => { setLoading(false)});
      }
  };

  return (
    <React.Fragment>
      <div
        ref={props.refer}
        className={`flex flex-col mt-1 z-10  bg-white text-gray-800 tracking-normal text-sm border border-gray-300  font-normal rounded ${
          props.width ? props.width : `w-72`
        }`}
      >
        <div className="my-3">
          {labelInput ? (
            <PopupTitle
              PopupTitle="Label"
              close={props.click}
              back={() => {
                showLabelInput(false);
              }}
            />
          ) : (
            <PopupTitle PopupTitle="Label" close={props.click} />
          )}
        </div>
        {labelInput ? (
          <>
            <div className="my-2 mx-3">
              <TacitbaseInput
                type="text"
                placeholder="Enter label name"
                value={labelName}
                onChange={validateLabelName}
                suffix={<Emoji onEmojiSelect={onEmojiSelect}/> }
                validator={(value) => {
                  if (value.length >= 50 || islimitexceed) {
                      return 'Maximum 50 characters allowed';
                  }
              }}
              />
            </div>
            <div className="mx-3">
              <div className="color-preview-panel">
                <div className="flex flex-row  flex-wrap h-90 w-full  text-white ">
                  {defaultColors.map((label: updatedColor, idx) => (
                    <div key={idx}>
                      <ColorSelection
                        colorName={`${label.colorCode}`}
                        isSet={label.isSet}
                        click={() => setSelectedColor(idx)}
                        showMore={label.showMore}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="m-3  ">
              <Button
              type="primary"
                className="submit-btn text-sm"
                data-testid="saveLabelTestBtn"
                onClick={updateFlag ? saveLabel : createLabel}
                disabled={isdisable}
                loading={loading}
              >
                <span className="text-xs">
                  {" "}
                  {updateFlag ? "Save" : "Create"}
                </span>
              </Button>
            </div>
          </>
        ) : (
          <LabelInput
            update={(label: any) => updateLabel(label)}
            click={(e: any) => createLabelOfSearch(e)}
            flag={false}
            close={props.click}
          />
        )}
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
  currentApplicant: state.opening.currentApplicant,
  clientId: state.opening.clientId,
});
const mapPropsToState = (dispatch: Dispatch) => ({
  saveApplicantLabel: (label: any) => dispatch(saveCardLabel(label)),
  saveOpeningLabel: (label: any, openingId: string) =>
    dispatch(saveBoardLabel(label, openingId)),
  updateApplicantLabel: (updatedLabel: any) =>
    dispatch(updateLabelState(updatedLabel)),
});
export default connect(mapStateToProps, mapPropsToState)(LabelPopup);
