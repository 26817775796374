import React from "react";
type UserIconProps = {
  fill?: string;
};
const UserIcon = (props: UserIconProps) => {
  return (
    <svg
      width="30px"
      height="30px"
      viewBox="0 0 36 36"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Members</title>
      <g
        id="Icons-/-36x36-/-Members"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M14.5,9 C17.5375661,9 20,11.4624339 20,14.5 C20,16.0641675 19.3470505,17.4758304 18.2988512,18.4772892 C21.9685048,19.5391904 23.869003,22.3803987 24,27 L5,27 C5.16573881,22.4013269 7.07144112,19.56497 10.7171069,18.4909293 C9.65952002,17.4899963 9,16.0720177 9,14.5 C9,11.4624339 11.4624339,9 14.5,9 Z M16.4710302,19.6352977 L16.3972465,19.664001 C15.8058525,19.8813461 15.1667828,20 14.5,20 C13.811561,20 13.1526645,19.8735135 12.545282,19.6425122 C9.24095951,20.1474129 7.24398169,22.0103794 6.55411708,25.2303719 L6.5,25.5 L22.5,25.5 C21.8956731,22.1006612 19.8860165,20.1457604 16.4710302,19.6352977 Z M14.5,10.5 C12.290861,10.5 10.5,12.290861 10.5,14.5 C10.5,16.709139 12.290861,18.5 14.5,18.5 C16.709139,18.5 18.5,16.709139 18.5,14.5 C18.5,12.290861 16.709139,10.5 14.5,10.5 Z"
          id="Combined-Shape"
          fill={`${props.fill ? props.fill : "#161E25"}`}
        ></path>
      </g>
    </svg>
  );
};
export default UserIcon;
