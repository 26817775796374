import { useState } from "react";
import dayjs, { Dayjs } from "dayjs";

// Initialize dayjs with custom format
dayjs.extend(require("dayjs/plugin/customParseFormat"));
const tomorrow = dayjs().add(1, "day").format("YYYY-MM-DD");

export const useDateRangeSelection = () => {
  const [selectedDates, setSelectedDates] = useState<string>();
  const [selectedEndDates, setSelectedEndDates] = useState<string>(tomorrow);

  const handleDateChange = (dates: any[] | null) => {
    if (dates && dates.length > 0 && dates[0] && dates[1]) {
      const startDate = dates[0].format("YYYY-MM-DD");
      const endDate = dates[1].format("YYYY-MM-DD");
      setSelectedDates(startDate);
      setSelectedEndDates(endDate);
    } else {
      setSelectedDates("");
      setSelectedEndDates(tomorrow);
    }
  };

  return {
    selectedDates,
    selectedEndDates,
    tomorrow,
    handleDateChange,
    setSelectedDates,
    setSelectedEndDates,
  };
};

export const disabledDate = (current: Dayjs): boolean => {
  return current.isAfter(dayjs(), "day");
};
