import React, { useRef, useState } from "react"
import { connect } from "react-redux";
import { Scope } from "../../type/type.d";
import { Overlay } from "../hook/Overlay";
import AddMemberToDbPopup from "./AddMemberToDbPopup";
import {Popover,Typography } from "antd"
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter"
import { IconButton, LabeledIconButton } from "../../ant/Button"
import { CloseOutlined, PlusOutlined } from "@ant-design/icons"
import { ADD_MEMBER_TITLE } from "../../constant"
const { Text } = Typography
const AddBoardMember = () => {
  const [open, setOpen] = useState(false);
  let refer = useRef(null);


  return (
    <>
      <Popover destroyTooltipOnHide title={<div className="flex w-full bg-white items-center">
        <Text className="w-full flex justify-center">
          <Text>{capitalizeFirstLetter(ADD_MEMBER_TITLE)}</Text>
        </Text>
        <LabeledIconButton
          type="text"
          label=""
          onClick={() => setOpen(false)}
          icon={<CloseOutlined />}
        />
      </div>} open={open} onOpenChange={(open) => setOpen(open)} trigger={["click"]}
        content={<Overlay onClose={() => setOpen(!open)} scope={Scope.Popover} reference={refer}>
          <AddMemberToDbPopup click={() => setOpen(!open)} refer={refer} />
        </Overlay >}>
        <div className="" >
          <IconButton
            icon={<PlusOutlined />}
            onClick={() => setOpen(!open)}
            data-testid="openMemberPopup"
            tooltipTitle="Add member"  
          />
        </div>
      </Popover >

    </>
  );
};
const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
});
export default connect(mapStateToProps)(AddBoardMember);
