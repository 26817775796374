import React from "react";
import TickIcon from "../../../assets/Tick";
type ColorSelectionProps = {
  colorCode: string;
  isSet: boolean;
  click?: any;
  showMore: boolean;
};
const IsBackgroundSet = ({ isSet }: any) => {
  if (!isSet) return null;
  return <TickIcon fill="#ffff" />;
};
const EventColorSelectionPopup = (props: ColorSelectionProps) => {
  return (
    <React.Fragment>
      <div
        className={`h-7 w-7 mx-0.5 mb-1.5
         flex rounded justify-center items-center cursor-pointer `}
        style={{
          backgroundColor: props.colorCode,
        }}
        onClick={() => props.click()}
        onKeyDown={() => {}}
        role="button"
        tabIndex={0}
        data-testid="click-btn"
      >
        <IsBackgroundSet isSet={props.isSet} />
        {props.showMore ? (
          <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
        ) : null}
      </div>
    </React.Fragment>
  );
};
export default EventColorSelectionPopup;
