import React from "react";
import { connect } from "react-redux";

import { OWNER } from "../../../../constant";
import { Role } from "../../../../lib/apiRes";
import { openingMemberType } from "../../../../type/type";
import { capitalizeFirstLetter } from "../../../../utils/capitalizeFirstLetter";
import DropdownWithDesc from "../../../common/customDropdowm/DropdownWithDesc";
import PopupTitle from "../../../common/popupTitle/PopupTitle";
type EditRoleProps = {
  selectedMember: openingMemberType;
  click: () => void;
  remove: () => void;
  refer: any;
  editRole: () => void;
  role: string;
  isSecretVisible: boolean;
  setSecreteVisible: () => void;
  orgRoles: Role[];
  selectedRole: (role: any) => void;
};
const EditRole = (props: EditRoleProps) => {

  return (
    <>
      <div
        data-popper-arrow
        className="animate-popover_fadeIn  flex flex-col ml-3 z-20 w-44 bg-white text-gray-800 tracking-normal text-sm font-normal border border-tacitbase-tertiary1 border-opacity-30 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1  ease-linear transition-all duration-150" ref={props.refer}
      >
        <div className="mt-2 mx-1">
          <PopupTitle PopupTitle="edit role" close={props.click} />
        </div>
        <div className="flex-col flex space-y-2 mx-1 mt-2 px-2">
          <div className=" flex text-xs text-black space-x-1 items-center font-normal">
            <span>{capitalizeFirstLetter("role")}</span>
            <i className="fa fa-info-circle" aria-hidden="true"></i>
          </div>
          <div>
            <DropdownWithDesc
              selectedItem={(item: string) => props.selectedRole(item)}
              options={
                props.orgRoles
                  ? props.orgRoles.filter(
                      (role: Role) => role.name !== capitalizeFirstLetter(OWNER)
                    )
                  : []
              }
              selectedRole={props.role}
              width={"w-full"}
              refer={props.refer}
            />
          </div>
          <div className=" flex items-center space-x-1 px-2 bg-tacitbase-primary2 bg-opacity-30 text-xs  text-black rounded-default py-1 font-light">
            <input
              type="checkbox"
              data-testid="checkboxTestBtn"
              className="flex h-4 w-4 rounded cursor-pointer"
              onChange={props.setSecreteVisible}
              checked={props.isSecretVisible}
            />
            <span className="font-medium text-xs">
              {" "}
              {capitalizeFirstLetter("secrets visible")}
            </span>
            <i className="fa fa-info-circle" aria-hidden="true"></i>
          </div>
        </div>
        <div className="justify-start mt-1 flex px-2">
          <button
            onClick={props.editRole}
            className="submit-btn text-xs"
            data-testid="updateRole"
          >
            {capitalizeFirstLetter("update")}
          </button>
        </div>
        <div className="flex mt-1">
          <hr
            className="w-full"
            style={{ borderColor: "#0F0A0D", opacity: "0.3" }}
          ></hr>
        </div>
        <div className="justify-start items-start flex  my-1 px-2 w-full ">
          <button
            onClick={props.remove}
            data-testid="remove"
            className="text-xs font-normal text-left rounded-default p-1 w-full  hover:bg-gray-300 "
          >
            {capitalizeFirstLetter("remove")}
          </button>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  orgRoles: state.opening.orgRoles,
});
export default connect(mapStateToProps)(EditRole);
