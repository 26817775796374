import React from "react"
import { ColorSelectionProps } from "../../../type/createBoardPopup";
import { determineTextColor } from "../../../utils/CalculateBrightnessOfColor"
import { CheckOutlined } from "@ant-design/icons"

const IsBackgroundSet = ({ isSet, colorname }: { isSet: boolean, colorname: string }) => {
  if (!isSet) return null;
  return <CheckOutlined style={{ color: determineTextColor(colorname), fontSize: "14px" }} />
};
const ColorSelection = ({
  colorName,
  isSet,
  click,
  showMore,
  size = "9"
}: ColorSelectionProps) => {
  return (
    <React.Fragment>
      <div
        className={`relative h-${size} w-${size} mx-0.5 mb-1.5 flex-wrap
        flex rounded justify-center items-center cursor-pointer bg-cover transition duration-300 transform hover:scale-125 bg-color-${colorName}  `}
        style={{ overflow: 'hidden', color: determineTextColor(colorName), backgroundColor: colorName }}
        onClick={() => click()}
        onKeyDown={() => {}}
        role="button"
        tabIndex={0}
        data-testid="click-btn"
      >
        <IsBackgroundSet isSet={isSet} colorname={colorName} />
        {showMore ? (
          <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
        ) : null}
      </div>
    </React.Fragment>
  );
};
export default ColorSelection;
