import  { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { communicationIntegration, getOrgintegrations } from '../lib/api';
import { throwSuccess } from '../service/throwError';
import { Dispatch } from 'redux';
import { Integration } from '../lib/apiRes';
import { addChatIntegration } from '../redux/actions/opening/action';
import { connect } from 'react-redux';

  type CommunicationIntegrationCodeHandlerProps = {
    addIntegrations: (integration: Integration[]) => void;
  }
const CommunicationIntegrationCodeHandler = (props:CommunicationIntegrationCodeHandlerProps) => {
    const history: any = useHistory();
    const orgId = localStorage.getItem('orgId');
    let paramsObj: Record<string, string> = {};
  // getting orgId and slackCode in first render
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.forEach((value, key) => {
      paramsObj[key] = value;
    });
  }, []);

  useEffect(() => {
    const { code, state } = paramsObj;
    // redirecting to organization page if connected to slack or failed to connect slack
    if (code && state) {
      history.push({
        pathname: `/o/${state}/settings/general`,
        method: "post",
      });
      communicationIntegration("slack", code)
        .then(() => {
          throwSuccess("Your Slack workspace integrated successfully.");
        })
        .then(() => {
          getOrgintegrations().then((integration) => {
            props.addIntegrations(integration);
          });
        })
      return; 
    }
  
    // if code or state not present, getting orgId from localStorage and redirect to the organization setting
    history.push({
      pathname: `/o/${orgId}/settings/general`,
      method: "post",
    });
  }, [paramsObj]);  
  
  return null;
};

const mapPropsToState = (dispatch: Dispatch) => ({
  addIntegrations: (integration: Integration[]) =>
    dispatch(addChatIntegration(integration)),
});

export default connect(null,mapPropsToState) (CommunicationIntegrationCodeHandler);