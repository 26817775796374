import { Steps } from 'antd'
import React from 'react'

type StepsBarProps = {
    current: number;
}
// progress bar that show on which step we currently have pn top of the modal
const StepsBar = (props:StepsBarProps) => {
  return (
    <Steps
    size="small"
    responsive = {true}
    current={props.current}
    items={[
      {
        title: 'Create a job post',
      },
      {
        title: 'Publish',
      },
      {
        title: 'Share',
      },
    ]}
    style={{ margin: '0 auto' }} 
  />
  )
}

export default StepsBar
