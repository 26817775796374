import React from 'react';
import { Button, Dropdown, Menu } from 'antd';
import {EllipsisOutlined } from '@ant-design/icons';
import CreateBoardPreview from '../../createBoardPopup/createBoardPreview/CreateBoardPreview';
import CreateCompanyPreview from '../../Company/CreateCompanyPreview';
import { connect } from 'react-redux';
import { loginUser, orgMember } from '../../../type/type';
import { OWNER } from '../../../constant';
import { IconButton } from '../../../ant/Button';
import ArchiveJobPostsIndex from '../../archiveJobPosts/ArchiveJobPostsIndex';

type ActionButtonProps = {
  members: orgMember,
  loginUser: loginUser,

};

const ActionButton: React.FC<ActionButtonProps> = ({ loginUser,members }) => {
  let accessForWorkspace = members[loginUser.user.id]?.registrations[0].roles[0] == OWNER
  const renderMenu = () => (
    <Menu
      onClick={(e) => {
        e.domEvent.stopPropagation();
      }}
    >
      <div
        className="flex flex-col items-start "
        role="button"
        tabIndex={0}
        onClick={(e) => e.stopPropagation()}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            e.stopPropagation();
          }
        }}
      >
        <ArchiveJobPostsIndex/>   
        <div>
       {accessForWorkspace &&(<CreateCompanyPreview />)} 
        </div>
      </div>
    </Menu>
  );



  return (
    <Button.Group style={{ display: 'flex' }}>
              <CreateBoardPreview />
              <Dropdown overlay={renderMenu()}  overlayStyle={{ zIndex: 100 ,padding:0,margin:0}} trigger={['click']} >
        <IconButton
          // type="primary"
          icon={<EllipsisOutlined />}
          style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 
          }}
        />
      </Dropdown>
      </Button.Group>
  );
};


const mapStateToProps = (state: any) => ({
    applicationId: state.opening.applicationId,
    loginUser: state.opening.loginUser,
    members: state.opening.members,
  
  })

export default connect(mapStateToProps)(ActionButton);
