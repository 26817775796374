import React, { useState } from "react";
import { Divider, Popover } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import { IconButton } from "../../../ant/Button"; // Assuming your custom Button
import SortingPopup from "./SortingPopup";

type SortingProps = {
  c: (value: any) => void;
};

const Sorting = (props: SortingProps) => {
  const [open, setOpen] = useState(false);

  // Function to handle visibility toggle
  const handlePopoverVisibleChange = (visible: boolean) => {
    setOpen(visible);
  };

  const title = (
    <div className="h-full w-full">
      <div className="flex justify-between items-center pl-3">
        <div>Sort by</div>
      </div>
      <Divider style={{ margin: "8px 0" }} />
    </div>
  );

  // Render the Sorting component with Ant Design Popover
  return (
    <div className="flex items-center">
      {/* Icon Button to open Popover */}
      <Popover
        content={
          <SortingPopup
            click={(e: boolean) => setOpen(e)} // Close popover when necessary
            getValue={props.c}        />
        }
        title={title}
        trigger="click" // Trigger on button click
        visible={open} // Control visibility of the popover
        onVisibleChange={handlePopoverVisibleChange} // Handle visibility change
        placement="bottomRight"
      >
        <IconButton
          icon={<FilterOutlined />}
          tooltipTitle="Apply sort filter"
        />
      </Popover>
    </div>
  );
};

export default Sorting;
