import React from "react";
import { ShowCardPreviewProps } from "../../../type/boardPanal";
import CardPreview from "../cardPreview/CardPreview";

const ShowCardPreview = ({
  applicants,
  cardreferenceElement,
}: ShowCardPreviewProps) => {
  if (!applicants) return null;
  return (
    <>
      {applicants.map((applicant: any, index: number) => (
        <div key={applicant.id}>
          <CardPreview
            refCard={cardreferenceElement}
            index={index}
            applicant={applicant}
          />
        </div>
      ))}
    </>
  );
};
export default ShowCardPreview;
