import React, { useState } from "react"
import { Form, Input, Modal } from "antd"
import AddManager from "./AddManager";
import { connect } from "react-redux";
import { orgMember } from "../../type/type";
import getOrgMembers from "../../utils/getOrgMember";

export type CompanyCreationProps = {
  onSave: (values: any) => void;
  open: boolean;
  onCancel: () => void;
  members: orgMember;
};

const CompanyCreation = (props: CompanyCreationProps) => {
  const [selectedManager, setSelectedManagers] = useState<any[]>();

  const [form] = Form.useForm();

  const handleManagerChange = (selectedManagers: any) => {
    setSelectedManagers(selectedManagers);
    form.setFieldsValue({
      members: selectedManagers, // Update the 'members' field with selected managers
    });
  };


  return (
    // <Overlay scope={Scope.Dialog} onClose={() => props.closeModal(false)}>
    <>
      <Modal
        okText="Create"
        title="Add Workspace"
        open={props.open}
        maskClosable={false}
        width={450}
        onOk={() => {
          form.validateFields().then((values) => {
            form.resetFields();
            Modal.destroyAll()
            props.onSave({ ...values, members: selectedManager });
            props.onCancel();
          });
        }}
        onCancel={() => {
          form.resetFields();
          props.onCancel();
        }}
      >
        <Form
          layout="vertical"
          form={form}
          name="control-hooks"
          autoComplete="off"
        >
          <Form.Item
            name="name"
            style={{ width: "100%" }}
            label="Workspace name"
            rules={[
              {
                required: true,
                message: "Please enter workspace title",
              },
            ]}
          >
            <Input showCount maxLength={50} />
          </Form.Item>

          <Form.Item
            name="members" // Set a name for the selected managers field
            label="Assign manager to workspace" // Label for the managers field
            help={"You will be assigned as the manager by default."}
          >
            <AddManager
              members={getOrgMembers()}
              onManagerChange={handleManagerChange}
            />
          </Form.Item>
          {/* <Form.Item name="description" label="Description" className="mb-12">
            <TextArea showCount maxLength={500} style={{ resize: "none" }} />
          </Form.Item> */}
        </Form>
      </Modal>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  members: state.opening.members,
});
export default connect(mapStateToProps)(CompanyCreation);
