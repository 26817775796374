import { notificationObj, notitifcationCenterObj } from "./initialState";

export function init_notifications_initial_state(state: any) {
  return {
    ...state,
    notification: notificationObj,
  };
}

export function init_dashboard_notifications(state: any, action: any) {
  var dashboardNotifications: any = {};

  action.data.map((notification: any) => {
    dashboardNotifications[notification.entity_id] = notification;
  });
  return {
    ...state,
    notification: {
      ...state.notification,
      dashboardNotificationsDot: {
        ...state.notification.dashboardNotificationsDot,
        ...dashboardNotifications,
      },
    },
  };
}

export function remove_notification_dot_of_entity(state: any, action: any) {
  const serviceNotifications =
    state.notification[`${action.payload.service}NotificationsDot`];

  if (!serviceNotifications) {
    return state;
  }

  return {
    ...state,
    notification: {
      ...state.notification,
      [`${action.payload.service}NotificationsDot`]: Object.keys(
        state.notification[`${action.payload.service}NotificationsDot`]
      )
        .filter((key) => key !== action.payload.data)
        .reduce((obj: any, key: string) => {
          obj[key] =
            state.notification[`${action.payload.service}NotificationsDot`][
              key
            ];
          return obj;
        }, {}),
    },
  };
}

export function init_all_notifications(state: any, action: any) {
  return {
    ...state,
    notification: {
      ...state.notification,
      allNotifications: action.data,
    },
  };
}

export function init_unread_notifications(state: any, action: any) {
  return {
    ...state,
    notification: {
      ...state.notification,
      unreadNotifications: action.data,
    },
  };
}

export function set_notification_option(state: any, action: any) {
  return {
    ...state,
    notificationOptions: action.data,
  };
}

export function init_entity_unread_notifications(state: any, action: any) {
  return {
    ...state,
    notification: {
      ...state.notification,
      entityUnreadNotification: action.data,
    },
  };
}

export function init_entity_unread_notifications_board(
  state: any,
  action: any
) {
  return {
    ...state,
    notification: {
      ...state.notification,
      entityUnreadNotificationBoard: action.data,
    },
  };
}

export function init_current_card_watcher(state: any, action: any) {
  return {
    ...state,
    notification: {
      ...state.notification,
      currentCardWatcher: action.data,
    },
  };
}

export function init_current_board_watcher(state: any, action: any) {
  return {
    ...state,
    notification: {
      ...state.notification,
      currentBoardWatcher: action.data,
    },
  };
}

export function remove_service_notification_dot(state: any, action: any) {
  return {
    ...state,
    notification: {
      ...state.notification,
      dashboardNotificationsDot: {
        ...state.notification.dashboardNotificationsDot,
        [action.data]: {
          ...state.notification.dashboardNotificationsDot[action.data],
          count: 0,
        },
      },
    },
  };
}

export function init_notifications_dot(state: any, action: any) {
  var notifications: any = {};

  action.payload.data.map((notification: any) => {
    notifications[notification.entity_id] = notification;
  });

  return {
    ...state,
    notification: {
      ...state.notification,
      [`${action.payload.service}NotificationsDot`]: notifications,
    },
  };
}

export function change_service_notifications_count(state: any, action: any) {
  if (state.notification.dashboardNotificationsDot[action.payload.service]) {
    let result = action.payload.isAdding
      ? state.notification.dashboardNotificationsDot[action.payload.service]
          ?.count + action.payload.count
      : state.notification.dashboardNotificationsDot[action.payload.service]
          ?.count - action.payload.count;

    return {
      ...state,
      notification: {
        ...state.notification,
        dashboardNotificationsDot: {
          ...state.notification.dashboardNotificationsDot,
          [action.payload.service]: {
            ...state.notification.dashboardNotificationsDot[
              action.payload.service
            ],
            count: result > 0 && result,
          },
        },
      },
    };
  } else {
    return { ...state };
  }
}

export function change_service_dot_of_entities(state: any, action: any) {
  const serviceNotifications = {
    ...state.notification[`${action.payload.service}NotificationsDot`],
  };

  if (
    Object.prototype.hasOwnProperty.call(
      serviceNotifications,
      action.payload.source.entity_id
    )
  ) {
    // Entity already exists, increase count
    serviceNotifications[action.payload.source.entity_id].count++;
  } else {
    // Entity does not exist, add it
    serviceNotifications[action.payload.source.entity_id] =
      action.payload.source;
  }

  return {
    ...state,
    notification: {
      ...state.notification,
      [`${action.payload.service}NotificationsDot`]: serviceNotifications,
    },
  };
}

export function change_board_entities_dot(state: any, action: any) {
  const serviceNotifications = {
    ...state.notification[`${action.payload.service}NotificationsDot`],
  };

  if (
    Object.prototype.hasOwnProperty.call(
      serviceNotifications,
      action.payload.topic_id
    )
  ) {
    // Entity already exists, increase count
    serviceNotifications[action.payload.topic_id].count++;
  } else {
    // Entity does not exist, add it
    serviceNotifications[action.payload.topic_id] = action.payload.source;
  }

  return {
    ...state,
    notification: {
      ...state.notification,
      [`${action.payload.service}NotificationsDot`]: serviceNotifications,
    },
  };
}

//  Adding ws update to redux for notifiction center

export function init_notification_center_unread(state: any, action: any) {  
  const { data, pagination } = action;
  // Combine existing notifications with new data if it's a "Show More" scenario
  const updatedNotifications =
    pagination.page > 1
      ? state.notificationCenter.unread.concat(data)
      : data;

  return {
    ...state,
    notificationCenter: {
      ...state.notificationCenter,
      unread: updatedNotifications,
      pagination: pagination,
    },
  };
}

export function add_notification_from_ws(state: any, action: any) {
  let newUnread = state.notificationCenter.unread.slice();

  // Use unshift to add the new data to the beginning of the copied array
  newUnread.unshift(action.data);
  return {
    ...state,
    notificationCenter: {
      ...state.notificationCenter,
      unread: newUnread,
    },
  };
}

// Adding lastVisitedTime of user to show dot on updates icon so that user will understand the change
export function show_dot_or_not_on_updates(state: any, action: any) {
  return {
    ...state,
    notificationCenter: {
      ...state.notificationCenter,
      showDotOnUpdates: action.data,
    },
  };
}

//  Adding dots of unread notification
export const unread_notification_dot_remover = (state: any, action: any) => {
  let updatedState = { ...state };

  let notificationItem = action.data;
  // return action.payload.reduce((updatedState: any, notificationItem: any) => {

  switch (notificationItem.service) {
    case "ats":
      if (notificationItem.model_name === "board") {
        const isReferenced = Object.values(
          updatedState.notificationCenter.unreadDotsofServices.ats.cards
        ).some(
          (cardNotification: any) =>
            cardNotification.data.deltas.data.board.id ==
            notificationItem.remove_id
        );

        if (!isReferenced) {
          // If not referenced, directly remove the boardId from the state
          const newBoards =
            updatedState.notificationCenter.unreadDotsofServices.ats.boards;

          delete newBoards[notificationItem.remove_id];

          updatedState = {
            ...updatedState,
            notificationCenter: {
              ...updatedState.notificationCenter,
              unreadDotsofServices: {
                ...updatedState.notificationCenter.unreadDotsofServices,
                [notificationItem.service]: {
                  ...updatedState.notificationCenter.unreadDotsofServices.ats,
                  boards: newBoards,
                },
              },
            },
          };
        }
      } else if (notificationItem.model_name === "card") {
        const cardId = notificationItem.remove_id;
        // const boardId = notificationItem.data.deltas.data?.board?.id
        const newCards =
          updatedState.notificationCenter.unreadDotsofServices.ats.cards;
        delete newCards[cardId];

        updatedState = {
          ...updatedState,
          notificationCenter: {
            ...updatedState.notificationCenter,
            unreadDotsofServices: {
              ...updatedState.notificationCenter.unreadDotsofServices,
              [notificationItem.service]: {
                ...updatedState.notificationCenter.unreadDotsofServices.ats,
                cards: newCards,
              },
            },
          },
        };
      }
      break;

      case "review": {
        const reviewId = notificationItem.remove_id;
        // const boardId = notificationItem.data.deltas.data?.board?.id
        const newReviews =
          updatedState.notificationCenter.unreadDotsofServices.review?.reviews;
        delete newReviews[reviewId];

        updatedState = {
          ...updatedState,
          notificationCenter: {
            ...updatedState.notificationCenter,
            unreadDotsofServices: {
              ...updatedState.notificationCenter.unreadDotsofServices,
              [notificationItem.service]: {
                ...updatedState.notificationCenter.unreadDotsofServices.ats,
                reviews: newReviews,
              },
            },
          },
        };
      }
      break;
      case "inbox": {
        const mailId = notificationItem.remove_id;
        // const boardId = notificationItem.data.deltas.data?.board?.id
        const newMail =
          updatedState.notificationCenter.unreadDotsofServices.inbox?.mail;
        delete newMail[mailId];

        updatedState = {
          ...updatedState,
          notificationCenter: {
            ...updatedState.notificationCenter,
            unreadDotsofServices: {
              ...updatedState.notificationCenter.unreadDotsofServices,
              [notificationItem.service]: {
                ...updatedState.notificationCenter.unreadDotsofServices.inbox,
                mail: newMail,
              },
            },
          },
        };
      }
  }

  return updatedState;
};


export const unread_notification_dot_adder = (state: any, action: any) => {
if(action?.payload?.items){
  return action?.payload?.items?.reduce((updatedState: any, notificationItem: any) => {

    switch (notificationItem.service) {
      case "ats":
        if (notificationItem.model_name == "board") {
          const boardId = notificationItem.data.deltas.data?.board
            ? notificationItem.data.deltas.data?.board.id
            : notificationItem.data.deltas.data?.board_destination?.id;

          updatedState = {
            ...updatedState,
            notificationCenter: {
              ...updatedState.notificationCenter,
              unreadDotsofServices: {
                ...updatedState.notificationCenter.unreadDotsofServices,
                [notificationItem.service]: {
                  ...updatedState.notificationCenter.unreadDotsofServices.ats,
                  boards: {
                    ...updatedState.notificationCenter.unreadDotsofServices.ats
                      .boards,
                    [boardId]: notificationItem,
                  },
                },
              },
            },
          };
        } else if (notificationItem.model_name === "card"||notificationItem.model_name === "comment") {
          const cardId = notificationItem.data.deltas.data?.card?.id;
          const boardId = notificationItem.data.deltas.data?.board?.id

          updatedState = {
            ...updatedState,
            notificationCenter: {
              ...updatedState.notificationCenter,
              unreadDotsofServices: {
                ...updatedState.notificationCenter.unreadDotsofServices,
                [notificationItem.service]: {
                  ...updatedState.notificationCenter.unreadDotsofServices.ats,

                  cards: {
                    ...updatedState.notificationCenter.unreadDotsofServices.ats
                      .cards,
                    [cardId]: notificationItem,
                  },
                  boards: {
                    ...updatedState.notificationCenter.unreadDotsofServices.ats
                      .boards,
                    [boardId]: notificationItem,
                  },
                },
              },
            },
          };
        }
        break;

      case "review":
        updatedState = {
          ...updatedState,
          notificationCenter: {
            ...updatedState.notificationCenter,
            unreadDotsofServices: {
              ...updatedState.notificationCenter.unreadDotsofServices,
              [notificationItem.service]: {
              
                reviews: {
                  ...updatedState.notificationCenter.unreadDotsofServices.review.reviews,
                  [notificationItem.data.deltas.data.review.id]:notificationItem
                },
              },
            },
          },
        };
        break;
        case "inbox":
          updatedState = {
            ...updatedState,
            notificationCenter: {
              ...updatedState.notificationCenter,
              unreadDotsofServices: {
                ...updatedState.notificationCenter.unreadDotsofServices,
                [notificationItem.service]: {
                
                  mail: {
                    ...updatedState.notificationCenter.unreadDotsofServices.inbox.mail,
                    [notificationItem.data.deltas.data.mail.id]:notificationItem
                  },
                },
              },
            },
          };
          
    }

    return updatedState;
  }, state);
}
else return state
};

export const init_notifications_dot_initial_state = (state: any) => { 
  return {
    ...state,
    notificationCenter: {
      ...state.notificationCenter,
      unreadDotsofServices: notitifcationCenterObj.unreadDotsofServices,
  }
}
}

export const set_user_notification_time = (state: any,action:any) => {
  return {
   ...state,
    userNotificationTimezone:action.payload,
  };
}

