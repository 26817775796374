import React from 'react';
interface Figmaprops{
    size?:number
}
const Figma = (props:Figmaprops) => {
    return (
        <div>
         <svg width={props.size?props.size:"26"} height={props.size?props.size:"26"} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.3125 0H4.6875C2.09867 0 0 2.09867 0 4.6875V15.3125C0 17.9013 2.09867 20 4.6875 20H15.3125C17.9013 20 20 17.9013 20 15.3125V4.6875C20 2.09867 17.9013 0 15.3125 0Z" fill="#242938"/>
<mask id="mask0_7012_13205"  maskUnits="userSpaceOnUse" x="4" y="2" width="12" height="16">
<path d="M4.76562 2.1875H15.1844V17.8125H4.76562V2.1875Z" fill="white"/>
</mask>
<g mask="url(#mask0_7012_13205)">
<path d="M7.37082 17.8099C8.80832 17.8099 9.97496 16.6433 9.97496 15.2058V12.6016H7.37082C5.93332 12.6016 4.7666 13.7683 4.7666 15.2058C4.7666 16.6433 5.93332 17.8099 7.37082 17.8099Z" fill="#0ACF83"/>
<path d="M4.7666 10.0026C4.7666 8.56508 5.93332 7.39844 7.37082 7.39844H9.97496V12.6067H7.37082C5.93332 12.6067 4.7666 11.4401 4.7666 10.0026Z" fill="#A259FF"/>
<path d="M4.7666 4.79164C4.7666 3.35414 5.93332 2.1875 7.37082 2.1875H9.97496V7.39586H7.37082C5.93332 7.39586 4.7666 6.22914 4.7666 4.79164Z" fill="#F24E1E"/>
<path d="M9.97461 2.1875H12.5788C14.0163 2.1875 15.183 3.35414 15.183 4.79164C15.183 6.22914 14.0163 7.39586 12.5788 7.39586H9.97461V2.1875Z" fill="#FF7262"/>
<path d="M15.183 10.0026C15.183 11.4401 14.0163 12.6067 12.5788 12.6067C11.1413 12.6067 9.97461 11.4401 9.97461 10.0026C9.97461 8.56508 11.1413 7.39844 12.5788 7.39844C14.0163 7.39844 15.183 8.56508 15.183 10.0026Z" fill="#1ABCFE"/>
</g>
</svg>

        </div>
    );
};

export default Figma;