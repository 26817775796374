import React from "react"
type FilePreviewProps = {
  link: string;
};
const FilePreview = (props: FilePreviewProps) => {
  return (
    <>
      <embed
        key={props.link}
        src={props.link}
        className="w-full h-full object-scale-down bg-black"
        id="pdf-url" data-testid="filePreview"
      />
    </>
  );
};
export default FilePreview;
