import React from "react";
const DeleteIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 40 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.2857 35.7143C14.2857 36.5 13.6429 37.1429 12.8571 37.1429C12.0714 37.1429 11.4286 36.5 11.4286 35.7143V17.1429C11.4286 16.3571 12.0714 15.7143 12.8571 15.7143C13.6429 15.7143 14.2857 16.3571 14.2857 17.1429V35.7143ZM21.4286 35.7143C21.4286 36.5 20.7857 37.1429 20 37.1429C19.2143 37.1429 18.5714 36.5 18.5714 35.7143V17.1429C18.5714 16.3571 19.2143 15.7143 20 15.7143C20.7857 15.7143 21.4286 16.3571 21.4286 17.1429V35.7143ZM28.5714 35.7143C28.5714 36.5 27.9286 37.1429 27.1429 37.1429C26.3571 37.1429 25.7143 36.5 25.7143 35.7143V17.1429C25.7143 16.3571 26.3571 15.7143 27.1429 15.7143C27.9286 15.7143 28.5714 16.3571 28.5714 17.1429V35.7143ZM28.3482 2.22679L31.625 7.14286H37.8571C39.0446 7.14286 40 8.10268 40 9.28571C40 10.4732 39.0446 11.4286 37.8571 11.4286H37.1429V38.5714C37.1429 42.5179 33.9464 45.7143 30 45.7143H10C6.05536 45.7143 2.85714 42.5179 2.85714 38.5714V11.4286H2.14286C0.959821 11.4286 0 10.4732 0 9.28571C0 8.10268 0.959821 7.14286 2.14286 7.14286H8.37679L11.6518 2.22679C12.5804 0.835446 14.1429 0 15.8125 0H24.1875C25.8571 0 27.4196 0.835536 28.3482 2.22679ZM13.5268 7.14286H26.4732L24.7768 4.60357C24.6429 4.40536 24.4196 4.28571 24.1875 4.28571H15.8125C15.5804 4.28571 15.2768 4.40536 15.2232 4.60357L13.5268 7.14286ZM7.14286 38.5714C7.14286 40.1518 8.42232 41.4286 10 41.4286H30C31.5804 41.4286 32.8571 40.1518 32.8571 38.5714V11.4286H7.14286V38.5714Z"
        fill="black"
      />
    </svg>
  );
};
export default DeleteIcon;
