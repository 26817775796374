import { openingMemberType, orgMember } from "../../../type/type";

type searchResultOfMemberType = {
  serviceMembers: openingMemberType[];
  members: orgMember;
  searchChar: string;
};

export const searchResultOfMember = (
  props: searchResultOfMemberType
): openingMemberType[] => {
  let accessForCDB: openingMemberType[] = props.serviceMembers
    .filter((member: openingMemberType) => {
      const memberDetails = props.members[member.id];
      return (
        memberDetails.first_name
          .toUpperCase()
          .includes(props.searchChar.toUpperCase()) ||
        memberDetails.last_name
          .toUpperCase()
          .includes(props.searchChar.toUpperCase()) ||
        memberDetails.email
          .toUpperCase()
          .includes(props.searchChar.toUpperCase()) ||
        memberDetails.username
          .toUpperCase()
          .includes(props.searchChar.toUpperCase())
      );
    });

  return accessForCDB;
};
