import React from "react";

const ArchiveIcon = () => {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Archive</title>
      <g
        id="Icons-/-16x16-/-Archive"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M15,0 C15.5522847,-1.01453063e-16 16,0.44771525 16,1 L16,4 C16,4.55228475 15.5522847,5 15,5 L15,14 C15,15.1045695 14.1045695,16 13,16 L3,16 C1.8954305,16 1,15.1045695 1,14 L1,5 C0.44771525,5 6.76353751e-17,4.55228475 0,4 L0,1 C-6.76353751e-17,0.44771525 0.44771525,1.01453063e-16 1,0 L15,0 Z M14,5 L2,5 L2,14 C2,14.5128358 2.38604019,14.9355072 2.88337887,14.9932723 L3,15 L13,15 C13.5128358,15 13.9355072,14.6139598 13.9932723,14.1166211 L14,14 L14,5 Z M11.5,12 C11.7761424,12 12,12.2238576 12,12.5 C12,12.7761424 11.7761424,13 11.5,13 L4.5,13 C4.22385763,13 4,12.7761424 4,12.5 C4,12.2238576 4.22385763,12 4.5,12 L11.5,12 Z M14.5,1 L1.5,1 C1.22385763,1 1,1.22385763 1,1.5 L1,1.5 L1,3.5 C1,3.77614237 1.22385763,4 1.5,4 L1.5,4 L14.5,4 C14.7761424,4 15,3.77614237 15,3.5 L15,3.5 L15,1.5 C15,1.22385763 14.7761424,1 14.5,1 L14.5,1 Z"
          id="Combined-Shape"
          fill="#161E25"
        ></path>
      </g>
    </svg>
  );
};
export default ArchiveIcon;
