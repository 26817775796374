import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
  copyApplicant,
  copyAttachments,
  getListByListIDs,
  getOpenings,
} from "../../../lib/api";
import {
  copyApplicantAction,
  copyAttachmentsAction,
  listOfBoards,
} from "../../../redux/actions/opening/action";
import PopupTitle from "../../common/popupTitle/PopupTitle";
import CheckList from "./CheckList";
import useCheckedItem from "./useCheckedItem";
import SelectOptions from "./SelectOptions";
import SelectPosition from "./SelectPosition";
// import SelectDestinationList from "./SelectDestinationList";
import { CopyCard } from "../../../type/copyCard";
import { COPY_CARD, SELECT_DESTINATION, SOCKET_CLIENT_ID } from "../../../constant";
import { Attachment, JobBoard, Opening } from "../../../type/type"
import { throwSuccess } from "../../../service/throwError";
const CopyCardPopup = (props: CopyCard) => {
  const applicantType: any[] = [];
  const keepType: string[] = [];
  const { data } = useCheckedItem({
    labels: props.applicantlabels[props.currentApplicant.id] || [],
    members: props.members[props.currentApplicant.id] || [],
    attachments: props.attachments,
  });
  let indexOfcurrentApplicant: number;
  indexOfcurrentApplicant = props.boardList[props.currentApplicant.list_id]
    .applicants
    ? props.boardList[props.currentApplicant.list_id].applicants
        .map((item: any) => item.id)
        .indexOf(props.currentApplicant.id)
    : 0;
  var currentList: any[] = Object.values(props.boardList);
  const [lists, setOpeningLists] = useState(currentList);
  const [openings, setOpenings] = useState<Opening[]>([]);
  const [openingID, setOpeningID] = useState(props.currentOpening.id);
  let deafultCopyCardName = `copy of ${props.currentApplicant.name}`;
  const [applicantName, setApplicantName] = useState(deafultCopyCardName);
  const [applicants, setApplicants] = useState(applicantType);
  const [selectedKeep, setSelectedKeep] = useState(keepType);
  const [companyOptions, setCompanyOptions] = useState<Record<string, JobBoard>>({})
  const [selectedCompany, setSelectedCompany] = useState(props.currentOpening.company_id);
  const [selectedListID, setSelectedListID] = useState(
    props.currentApplicant.list_id
  );
  const [selectedApplicant, setSelectedApplicant] = useState(
    indexOfcurrentApplicant.toString()
  );

  useEffect(() => {
    getOpenings()
      .then((openings: any) => {
        openings = openings ? openings : [];
        // setOpenings(openings);
        const companyOptionsObject: Record<string, JobBoard> = {}
        openings.forEach((company: JobBoard) => {
          companyOptionsObject[company.company_id] = company
        })

        setCompanyOptions(companyOptionsObject)
      })
     
  }, []);

  useEffect(() => {
    var index = lists.map((item: any) => item.id).indexOf(selectedListID);
    var applicants: any = lists[index].applicants
      ? lists[index].applicants
      : [];
    setApplicants(applicants);
    setSelectedApplicant("0");
  }, [selectedListID]);
  const getBoardLists = (e: any) => {
    setOpeningID(e.target.value);
  };

  useEffect(() => {
    if (selectedCompany) {
      // Access the selected company data directly from the object
      const selectedCompanyData = companyOptions[selectedCompany]

      if (selectedCompanyData) {
        // Extract the boards associated with the selected company
        const boards = selectedCompanyData.boards || []

        setOpenings(boards)
      }
    }
  }, [selectedCompany, companyOptions]);
  useEffect(() => {
    getListByListIDs(openingID)
      .then((lists_with_applicants: any) => {
        setOpeningLists(lists_with_applicants);
        if (!lists_with_applicants) {
          setApplicants([]);
        } else {
          setOpeningLists(lists_with_applicants);
          if (props.currentOpening.id != openingID) {
            setSelectedListID(lists_with_applicants[0].id);
            setApplicants(lists_with_applicants[0].applicants);
          }
        }
      })
   
  }, [openingID]);

  const copy = () => {
    let index: number = parseInt(selectedApplicant);
    const copyReq = {
      src_applicant: {
        id: props.currentApplicant.id,
        opening_id: props.currentOpening.id,
        list_id: props.currentApplicant.list_id,
        name: "",
      },
      dst_applicant: {
        id:
          index === 0
            ? "00000000-0000-0000-0000-000000000000"
            : applicants[index - 1].id,
        opening_id: openingID,
        list_id: selectedListID,
      },
      keep: selectedKeep,
      socket_client_id: sessionStorage.getItem(SOCKET_CLIENT_ID),
      position: selectedApplicant,
    };
    applicantName !== ""
      ? (copyReq.src_applicant["name"] = applicantName)
      : copyReq;
    copyApplicant(copyReq)
      .then((res: any) => {
        if (selectedKeep.includes("attachments")) {
          let attachments: Attachment[] = [];
          props.attachments.map((at: Attachment) => {
            attachments.push({
              ...at,
              recordID: res.applicant.id,
              opening_id: openingID,
            });
          });
          let req = {
            attachments: attachments,
          };
          copyAttachments(req).then((resA) => {
            props.copyAttachmentsAction(res.applicant.id, selectedListID, resA);
          });
        }
        if (res.destination == "copy_to_same_opening") {
          props.copyApplicantAction(res, index);
        } else if (res.destination == "copy_to_another_opening") {
          throwSuccess(COPY_CARD)
        }
        props.click(false);
      })
     
  };

  return (
    <React.Fragment>
      <div className="flex flex-col h-auto mt-1 z-10 w-72 bg-white text-gray-800 tracking-normal border border-gray-300 text-sm font-normal rounded "  ref={props.refer}
      >
        <div className="my-3">
          <PopupTitle PopupTitle={props.name} close={props.click} />
        </div>
        <div className="mx-3 mb-2">
          <span className="text-xs font-extrabold">Title</span>
          <textarea
            defaultValue={applicantName}
            onChange={(e: any) => setApplicantName(e.target.value)}
            maxLength={512}
            data-testid="CopyCardPopupInputTestBtn"
            className="w-full mb-2 h-20 p-2   rounded inputCSS resize-none"
          />
          <CheckList
            checkLists={data}
            selectCheck={(e: any) => setSelectedKeep(e)}
          />
          <span>{SELECT_DESTINATION}</span>
          <div className="bg-gray-200 mb-2 rounded hover:bg-gray-200 p-2 bg-opacity-60 w-full">
            <div className="text-xs">Company</div>
            <select
              className="appearance-none cursor-pointer outline-none w-full bg-gray-200 bg-opacity-20"
              onBlur={getBoardLists}
              onChange={(e: any) => setSelectedCompany(e.target.value)}
              data-testid="selectBoard"
            >
              {Object.keys(companyOptions).map((companyId) => (
                <option key={companyId} value={companyId}
                  selected={companyId === props.currentOpening.company_id}
                >
                  {companyOptions[companyId].company_name}
                </option>
              ))}
            </select>
          </div>
          <div className="bg-gray-200 mb-2 rounded hover:bg-gray-200 p-2 bg-opacity-60 w-full">
            <div className="text-xs">Board</div>
            <select
              className="appearance-none cursor-pointer outline-none w-full bg-gray-200 bg-opacity-20"
              onBlur={getBoardLists}
              onChange={getBoardLists}
              data-testid="selectBoard"
            >
              {openings.map((opening: any, index: number) => (
                <option
                  key={index}
                  className="w-full"
                  value={opening.id}
                  selected={opening.id === props.currentOpening.id}
                >
                  {opening.name}
                </option>
              ))}
            </select>
          </div>
          <div className="flex w-full mb-2">
            <div className="mr-4 rounded p-2 bg-gray-200 w-full bg-opacity-60 hover:bg-gray-200">
              {lists ? (
                <SelectOptions
                  label="List"
                  value={lists}
                  event={(e: any) => setSelectedListID(e)}
                  comparedId={props.currentApplicant.list_id}
                />
              ) : (
                <>
                  <div className="text-xs">list</div>
                  <div className="w-full">No list</div>
                </>
              )}
            </div>
            <div className="p-2 rounded bg-gray-200 bg-opacity-60 hover:bg-gray-200">
              {applicants.length > 0 ? (
                <SelectPosition
                  label="Position"
                  value={applicants ? applicants : []}
                  event={(e: any) => setSelectedApplicant(e)}
                  comparedId={props.currentApplicant.id}
                  move={false}
                  extraIndexCheck={null}
                  sourceId={null}
                />
              ) : (
                <>
                  <div className="text-xs capitalize">position</div>
                  <div className="w-full">{lists ? 1 : "N/A"}</div>
                </>
              )}
            </div>
          </div>
        </div>
        <div>
          <button
            // className={`submit-btn text-sm`}
            className={`submit-btn text-sm mb-2 mx-3 ${
              props.blockUserFromCopycard ? "cursor-not-allowed" : ""
            }`}
            onClick={copy}
            data-testid="copyTestBtn"
            disabled={
              lists ? (props.blockUserFromCopycard ? true : false) : true
            }
          >
            <span className="text-xs">Copy</span>
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  openings: state.opening.openings,
  currentOpening: state.opening.currentOpening,
  boardList: state.opening.boardList,
  currentApplicant: state.opening.currentApplicant,
  keep: state.opening.keepCopyApplicant,
  tasks: state.opening.tasks,
  applicantlabels: state.opening.applicantLabels,
  members: state.opening.applicantMembers,
  blockUserFromCopycard: state.opening.billingUser.blockFromCardCreation,
  // comments: state.opening.comments,
  clientId: state.opening.clientId,
  attachments:
    state.opening.attachment[state.opening.currentApplicant.id] || [],
});

const mapPropsToState = (dispatch: Dispatch) => ({
  copyApplicantAction: (applicant: any, index: number) =>
    dispatch(copyApplicantAction(applicant, index)),
  listOfBoards: (boardData: any) => dispatch(listOfBoards(boardData)),
  copyAttachmentsAction: (
    record_id: string,
    list_id: string,
    data: Attachment[]
  ) => dispatch(copyAttachmentsAction(record_id, list_id, data)),
});
export default connect(mapStateToProps, mapPropsToState)(CopyCardPopup);
