import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { SOCKET_CLIENT_ID } from "../../../constant";
import { editApplicantDescription } from "../../../lib/api";
import { editApplicantDesc } from "../../../redux/actions/opening/action";
import { CardDescriptionProps } from "../../../type/card";
import CardDescriptionInput from "./cardDescriptionInput/CardDescriptionInput";
const CardDescription = (props: CardDescriptionProps) => {

  const editDesc = (value: any) => {
    const desc = {
      id: props.currentApplicant.id,
      description: value,
      socket_client_id: sessionStorage.getItem(SOCKET_CLIENT_ID),
    };
    if (value?.length > 0) {
      editApplicantDescription(desc).then((applicant: any) => {
        props.editDesc(applicant);
      });
    }
  };
  return (
    <div>
        <div>
          <CardDescriptionInput
            description={props.currentApplicant.description}
            create={editDesc}
          />
        </div>
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  currentApplicant: state.opening.currentApplicant,
  clientId: state.opening.clientId,
  currentOpening: state.opening.currentOpening,
});
const mapPropsToState = (dispatch: Dispatch) => ({
  editDesc: (desc: string) => dispatch(editApplicantDesc(desc)),
});
export default connect(mapStateToProps, mapPropsToState)(CardDescription);
