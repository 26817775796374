import React from "react";

type SearchOperatorProps = {
  setQueryBy: (operator: string) => void;
  queryBy: string[];
};
const SearchOperator = (props: SearchOperatorProps) => {
  const operators = ["name", "labels", "description"] || [];
  return (
    <>
      <div className="flex flex-row w-full p-4  border-b space-x-2 items-center ">
        <div className="text-xs leading-4 tracking-normal">Search for:</div>
        <div className="flex space-x-2">
          {operators.length > 0 &&
            operators.map((operator: string, index: number) => (
              <button
                className={`${
                  props.queryBy.includes(operator)
                    ? "border border-tacitbase-secondary3 "
                    : "border border-tacitbase-tertiary1 border-opacity-30"
                } text-xs leading-4 tracking-normal font-medium  p-1 rounded capitalize`}
                key={index}
                data-testid="queryByTestBtn"
                onClick={() => props.setQueryBy(operator)}
              >
                {operator}
              </button>
            ))}
        </div>
      </div>
    </>
  );
};
export default SearchOperator;
