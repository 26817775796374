import React, { useState } from "react";
import { usePopper } from "react-popper";
import { connect } from "react-redux";
import Read from "../../../Read";
import { ListActionButtonProps } from "../../../type/boardPanal";
import { Scope } from "../../../type/type.d";
import { Overlay } from "../../hook/Overlay";
import ListPopOver from "../../listPopup/listPopover/ListPopOver";
import Portal from "../../portal/Portal";
import { Button } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";

const ListActionButton = (props: ListActionButtonProps) => {
  const [modal, showModal] = useState(false);
  const [referenceElement, setReferenceElement] = useState<
    HTMLButtonElement | HTMLDivElement | null |any
  >(null);
  let [popperElement, setPoperElement] = useState<HTMLDivElement | null>();
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "top-start",
    modifiers: [
      {
        name: "flip",
        options: {
          fallbackPlacements: ["top", "left"],
        },
      },
    ],
  });
  return (
    <Read access={props.currentOpening.access}>
      <div
        className={ `mr-1 ${
          modal
            ? "bg-opacity-25  rounded"
            : "hover:bg-gray-300 hover:bg-opacity-50 rounded"
        }`}
      >
        <Button
          className={` ${
            modal ? " bg-gray-300 bg-opacity-50 rounded" : ""
          }    list-action-button hover:bg-gray-300 hover:bg-opacity-50 `}
          ref={setReferenceElement}
          data-testid="testBtn"
          onClick={() => showModal(!modal)}
          icon={<EllipsisOutlined size={24} style={{color:'white'}}/>}
          size="small"
          type="text"
        />
      </div>
      {modal ? (
        <Overlay onClose={() => showModal(!modal)} scope={Scope.Popover}>
          <Portal>
            <div
              ref={setPoperElement}
              style={styles.popper}
              {...attributes.popper}
            >
              <ListPopOver
                listID={props.listID}
                close={(e: boolean) => {
                  showModal(e);
                }}
              />
            </div>
          </Portal>
        </Overlay>
      ) : null}
    </Read>
  );
};
const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
});

export default connect(mapStateToProps)(ListActionButton);
