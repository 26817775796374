import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
type Props = {
  children: any;
};
const Portal = (props: Props) => {
  const [elevation] = useState<number>(0);
  const [container] = useState(() => {
    const el = document.createElement("div");
    el.classList.add("portal-root");
    el.classList.add("animate-popover_fadeIn");
    el.classList.add(`data-elevation-${elevation.toString()}`);
    return el;
  });
  useEffect(() => {
    document.getElementById("root")?.appendChild(container);
    return () => {
      document.getElementById("root")?.removeChild(container);
    };
  }, []);
  return createPortal(props.children, container);
};
export default Portal;
