import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { loginUser, orgMember, Plan } from "../../../type/type";
import TacitBaseModal from "../../../ant/TacitbaseModal";
import { Typography } from "antd";
import {  GiftOutlined } from "@ant-design/icons";
import { OWNER } from "../../../constant";
import { newOrganisation } from "../../../lib/apiRes";
import { getBillingActivePlans } from "../../../lib/api";
import PricingTable from "./PricingTable";

const { Text } = Typography;

type GetplanModelProps = {
  isModalVisible: boolean;
  onCancel: () => void;
  loginUser: loginUser;
  members: orgMember;
  currentOrg: newOrganisation;
};

const GetplanModel = ({
  isModalVisible,
  onCancel,
  loginUser,
  members,
}: GetplanModelProps) => {
  const [plans, setPlans] = useState<Plan[]>([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    getBillingActivePlans("tacitbase").then((res: any) => {
      setPlans(res.plans);
      setLoading(false);
    });
  }, []);
  return (
    <TacitBaseModal
      className="w-auto animate-fade-in"
      maskClosable={true}
      onCancel={onCancel}
      title={
        <div className="flex flex-col items-center">
          <div className="flex gap-4">
            <GiftOutlined
              style={{ fontSize: "24px", color: "#0073e6" }}
              className="transition-transform transform hover:scale-110 mb-2"
            />
            <Text className="text-xl font-semibold mb-1">
            Flexible Plans for Your Hiring Needs
            </Text>
          </div>
          <Text type="secondary">
          Upgrade Your Plan for Enhanced Features and Priority Support
          </Text>
        </div>
      }
      visibility={isModalVisible}
      content={
        <>
          <PricingTable loading={loading} plans={plans} />
          {!loading &&
            (members[loginUser.user.id].registrations[0].roles[0] !== OWNER ? (
              <div className="flex justify-center mt-6">
                <Text style={{ color: "#009D79" }}>
                  {"Please contact your admin."}
                </Text>
              </div>
            ) : null)}
        </>
      }
      width={"auto"}
      footer={null}
      centered
    />
  );
};

const mapStateToProps = (state: any) => ({
  currentOrg: state.opening.currentOrg,
  loginUser: state.opening.loginUser,
  members: state.opening.members,
});

export default connect(mapStateToProps)(GetplanModel);
