import { JDMember } from "../../constant"
import { Jd, Member, openingMemberType } from "../../type/type"

export function init_JD_member(state: any, action: any) {
    var JDMembers: any[] = []
    action.data.length > 0?
    action.data.map((member: Member) => {
        JDMembers.push({ id: member.id, access: member.access })
    }): (JDMembers = []);
    return {
        ...state,
        servicesMembership: {
            ...state.servicesMembership,
            [JDMember]: JDMembers,
        },
    }
}
export function add_member_in_jd(state: any, action: any) {
    var JDMembers: openingMemberType[] = []
    action.data.map((member: Member) => {
        JDMembers.push({
            id: member.id,
            access: member.access,
        })
    })
    return {
        ...state,
        servicesMembership: {
            ...state.servicesMembership,
            [JDMember]: state.servicesMembership[JDMember]
                ? [...state.servicesMembership[JDMember], ...JDMembers]
                : JDMembers,
        },
    }
}
export function update_JD_member_role(state: any, action: any) {
    return {
        ...state,
        servicesMembership: {
            ...state.servicesMembership,
            [JDMember]: state.servicesMembership.JDMember
                ? state.servicesMembership.JDMember.map((m: any) =>
                    m.id == action.memberId
                        ? {
                            ...m,
                            access: action.access,
                        }
                        : m
                )
                : [],
        },
    }
}

export function remove_JD_member(state: any, action: any) {  

    return {
        ...state,
        servicesMembership: {
            ...state.servicesMembership,
            [JDMember]: state.servicesMembership.JDMember
                ? state.servicesMembership.JDMember.filter(
                    (m: any) => m.id != action.memberID
                )
                : [],
        },
    }
}



export function init_current_jd(state: any, action: any) {
    return {
        ...state,
        currentJD: action.JD
    }
}

export function update_current_jd(state: any, action: { JD: Jd }) {
    return {
        ...state,
        currentTrackerJd: action.JD
    }
}

export function add_JD_reviewer(state: any, action: any) {
    return {
        ...state,
        currentJDReviewer: {
            reviewers: action.reviewer
        }
    }
}