import React, { useState, useCallback } from "react";
import {
  Badge,
  Button,
  Collapse,
  Divider,
  Popover,
  Tooltip,
  Typography,
  Menu
} from "antd";
import { FilterOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { PoolFilters } from "../../constant";
import { relevancyFilter } from "../../type/type";
import { setResumeRelevancyFilterNameAndCount } from "../../redux/actions/opening/action";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";

const { Panel } = Collapse;
const { Text } = Typography;

interface PoolTableFilterProps {
  addResumeRelevancyCount: (data: relevancyFilter) => void;
  relevancyFilter: relevancyFilter;
}

const customPanelStyle = {
  backgroundColor: "#fff",
  borderRadius: 4,
  border: 0,
  overflow: "hidden",
};
const customMenuItemStyle = {
  paddingTop: 0, 
  marginTop: 0, 
  marginBottom: 0,
  paddingBottom: 0,
  color:"black",
};
const customMenuStyle = {
  backgroundColor: "white",
  border: "1px solid white",
  padding: 0, 
  margin: 0,
};

function PoolTableFilter({ addResumeRelevancyCount, relevancyFilter }: PoolTableFilterProps) {
  const [visible, setVisible] = useState(false);

  const handleFilterClick = useCallback((label: string) => {
    const data = {
      name: label.toLowerCase(),
      count: 1,
    };
    addResumeRelevancyCount(data);
  }, [addResumeRelevancyCount]);

  const clearFilter = useCallback(() => {
    addResumeRelevancyCount({ name: "created_at", count: 0 });
  }, [addResumeRelevancyCount]);
  const handleMenuClick = (key: string) => {
    handleFilterClick(key);
  };

  const title = (
    <div className="h-full w-full">
      <div className="flex justify-between items-center pl-3">
        <div>Filter</div>
        <Button type="link" onClick={clearFilter} style={{ color: "#4B48A5" }}>
          Clear All
        </Button>
      </div>
      <Divider style={{ margin: "8px 0" }} />
    </div>
  );

  const content = (
    <div
      className="flex"
      style={{ width: 300, minHeight: 50, overflowY: "auto", margin: 0 }}
    >
      <Collapse
        accordion
        bordered={false}
        className="w-full"
        expandIconPosition="left"
        defaultActiveKey={1}
      >
        <Panel
          header={<Text>Relevancy</Text>}
          key="1"
          style={customPanelStyle}
        >
          <Menu
            onClick={({ key }) => handleMenuClick(key)}
            selectedKeys={[capitalizeFirstLetter(relevancyFilter?.name) ||""]}
            style={customMenuStyle}
          >
            {PoolFilters.map((filterItem) => (
              <Menu.Item key={filterItem.label}  style={customMenuItemStyle}>
              <div className="flex justify-between items-center">
              {filterItem.label}
              </div>
              </Menu.Item>
            ))}
          </Menu>
        </Panel>
      </Collapse>
    </div>
  );

  return (
    <div>
      <Popover
        title={title}
        content={content}
        trigger="click"
        visible={visible}
        onVisibleChange={setVisible}
      >
        <Tooltip title="Apply filter">
          <Button
            type="default"
            icon={<FilterOutlined />}
            onClick={() => setVisible(true)}
            size="small"
            style={{ minWidth: 28, height: 28 }}
          >
            {relevancyFilter?.count !== 0 && (
              <Badge
                className="site-badge-count-109"
                count={relevancyFilter?.count}
                style={{ backgroundColor: "#4B48A5" }}
              />
            )}
          </Button>
        </Tooltip>
      </Popover>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  relevancyFilter: state.opening.relevancyFilter,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  addResumeRelevancyCount: (data: relevancyFilter) =>
    dispatch(setResumeRelevancyFilterNameAndCount(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PoolTableFilter);
