import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import CloseIcon from "../../../assets/Close";
import { CreateCandidateCard, getOpeningLists } from "../../../lib/api"
import { Opening, OrganizedBoard, companyBoards, orgMember } from "../../../type/type"
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter"
import DropDownWithIcon from "../../common/DropDownWithIcon";
import { NO_LIST_FOUND_IN_BOARD } from "../../../constant"
import { Button } from "antd"
import { AxiosError } from "axios"
import { throwSuccess } from "../../../service/throwError";
type SendToBoardProps = {
  members: orgMember;
  applicationId: string;
  selectedItems: any;
  openings: OrganizedBoard;
  close: () => void;
  companyBoard: companyBoards[]

};
const SendToBoard = (props: SendToBoardProps) => {

  let element = document.getElementById("errorMsg")
  const [listLength, setListLength] = useState<number>(0);
  const [loading, setLoading] = useState(false);

  const [selectedOpening, setSelectedOpening] = useState<string>("");
  const [companyOptions, setCompanyOptions] = useState<Record<string, any>>({})
  const [openings, setOpenings] = useState<Opening[]>([])
  const selectOpening = (openingId: string) => {
    setSelectedOpening(openingId)
  }
  const selectCompany = (openingId: string) => {
    setOpenings(companyOptions[openingId].boards.filter((o: Opening) => !o.access.comment_only
    ))
    setSelectedOpening(companyOptions[openingId].boards[0].id)

  };
  useEffect(() => {

    const companyOptionsObject: Record<string, any> = {}
    Object.values(props.companyBoard).forEach((company: companyBoards) => {
      companyOptionsObject[company.company_id] = {
        ...company,
        "id": company.company_id,
        boards: company.boards.map((boardId: any) => props.openings.pageSection.openings[boardId])
      }
    })

    setCompanyOptions(companyOptionsObject)
    if (props.companyBoard.length > 0) {
      setOpenings(companyOptionsObject[props.companyBoard[0].company_id].boards.filter((o: Opening) => !o.access.comment_only
      ))
      setSelectedOpening(companyOptionsObject[props.companyBoard[0].company_id].boards[0].id)
    } else {
      if (element) element.innerHTML = NO_LIST_FOUND_IN_BOARD
    }
  }, []);
  useEffect(() => {
    if (selectedOpening) {
      getOpeningLists(selectedOpening).then((res) => {
        if (res) {
          setListLength(res.length)
          if (element) element.innerHTML = ""
        } else { setListLength(0); if (element) element.innerHTML = NO_LIST_FOUND_IN_BOARD }
      })
    }
  }, [selectedOpening]);
  const createReviews = () => {
    if (selectedOpening.trim().length > 0) {
      setLoading(true);

      const req = {
        candidates: props.selectedItems,
        opening_id: selectedOpening,
      };
      CreateCandidateCard(req).then((res) => {
        if (res) {
          setLoading(false);
          props.close();
          throwSuccess(`Candidates have been forwarded to the board ${props.openings.pageSection.openings[selectedOpening].name}.`)
        }
      }).catch((reason: AxiosError) => {
        if (reason)
          setLoading(false);
      });
    }
  };
  return (
    <>
      <div>
        {" "}
        <div className="justify-between flex">
          <div className="flex space-x-2">
            <div>
              <div className="text-sm mb-1 font-medium">
                {capitalizeFirstLetter("select workspace")}
              </div>
              <DropDownWithIcon
                showKey={"company_name"}
                data={Object.values(companyOptions)}
                selectedOption={(e: string) => selectCompany(e)}
                defaultValue="select workspace"
              />
            </div>
            <div>
              <div className="text-sm mb-1 font-medium">
              {capitalizeFirstLetter("select board")}
            </div>
            <DropDownWithIcon
              showKey={"name"}
                data={openings}
                selectedOption={(e: string) => selectOpening(e)}
              defaultValue="select board"
              />
            </div>
          </div>

          <div className="flex justify-center items-start h-8 pt-1  w-8 hover:bg-gray-200 rounded-default">
            <button tabIndex={0} className="p-1" onClick={props.close}>
              <CloseIcon />
            </button>
          </div>
        </div>
      </div>
      <div className=" max-h-full h-full overflow-y-scroll ">
        <div className="flex flex-wrap mt-1 ">
          {selectedOpening && listLength ? (
            <>
              <div className="text-sm">
                {capitalizeFirstLetter(
                  `candidates card will be created in the first list of board`
                )}
                <span className="font-semibold mx-1">
                  {props.openings.pageSection.openings[selectedOpening].name}
                </span>
                <div>{capitalizeFirstLetter("If a candidates profile contains labels, description, or attachments, they will be automatically included within the card.")}
                </div>
              </div>
            </>
          ) : null}
          <div id="errorMsg" className="text-xs"></div>
        </div>
      </div>
      <div className="justify-end flex items-end">
        {" "}
        <Button type="primary" loading={loading} disabled={listLength > 0 ? false : true} onClick={createReviews}>
          {capitalizeFirstLetter("send to tracker")}
        </Button>

      </div>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  members: state.opening.members,
  applicationId: state.opening.applicationId,
  openings: state.opening.openings,
  companyBoard: state.opening.companyBoards,
});

export default connect(mapStateToProps)(SendToBoard);
