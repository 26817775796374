import { AxiosError } from "axios";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import CloseIcon from "../../../assets/Close";
import { ADD_REVIEWER, CDBMember, MAX_CHAR_LIMIT_WARNING } from "../../../constant"
import { createReview } from "../../../lib/api";
import { loginUser, Member, orgMember, Scope } from "../../../type/type.d";
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter"
import Image from "../../common/image/Image";
import SearchedResultPopup from "../../subNavbar/addBoardMember/SearchedResultPopup";
import { Overlay } from "../../hook/Overlay";
import ReactTextareaAutosize from "react-textarea-autosize"
import { createReviewReq } from "../../../lib/apiReq"
import { Button } from "antd"
import { throwSuccess } from "../../../service/throwError";
type CreateReviewProps = {
  members: orgMember;
  applicationId: string;
  selectedItems: string[];
  close: () => void;
  candidateDBMembers: any;
  loginUser: loginUser;
  clientId: string;

};
const CreateReview = (props: CreateReviewProps) => {
  const [searchedMember, getSearchedMember] = useState("");
  const [checked, setChecked] = useState<any[]>([]);
  const refer=useRef(null);
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  let oMember = Object.values(props.members).filter(
    (member: Member) =>
      member.registrations[0].data.activated &&
      member.id != props.loginUser.user.id &&
      (member.first_name.toUpperCase().includes(searchedMember.toUpperCase()) ||
        member.last_name.toUpperCase().includes(searchedMember.toUpperCase()) ||
        member.email.toUpperCase().includes(searchedMember.toUpperCase()) ||
        member.username.toUpperCase().includes(searchedMember.toUpperCase()))
  );
  // const addReviewer = (memberId: string) => {
  //   if (!reviewer.includes(memberId)) {
  //     setReviewer([...reviewer, memberId]);
  //   }
  // };
  const validateCommentLength = (e: any) => {
    setComment(e.target.value)
  };
  const removeMemberFromSelectedList = (index: number) => {
    setChecked((a: string[]) => {
      a.splice(index, 1);
      return [...a];
    });
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = "";
      inputRef.current.focus();
      getSearchedMember("");
    }
  }, [checked]);
  const createReviews = () => {
    if (checked.length > 0 && props.selectedItems.length > 0 && comment.trim().length < 125) {
      setLoading(true)
      let req: createReviewReq
      req = {
        candidates: props.selectedItems,
        reviewers: checked,
        socket_client_id: "",
      }
      if (comment.trim()) {
        req.comments = [{
          body: comment.trim()
        }]
      }
      createReview(req)
        .then((res) => {
          if (res) {
            setLoading(false)
            throwSuccess("Candidates sent for review.")
            setChecked([])
            setComment("")
          }
        })
        .catch((reason: AxiosError) => {
          if (reason)
            setLoading(false);
        });
    }
  };
  const handleInputChange = (member: Member) => {
    var updatedList = [...checked];
    if (!checked.includes(member.id)) {
      updatedList = [...checked, member.id];
    }
    setChecked(updatedList);
  };
  const searchKeyword = (e: any) => {
    getSearchedMember(e.target.value);
  };
  return (
    <>
      <div>
        <div className="justify-between flex">
          <div className="m-1">
            <div className="text-sm mb-1 font-medium">
              {capitalizeFirstLetter(ADD_REVIEWER)}
            </div>
            <div className="w-72 relative">
              <div
                className={`rounded inputCSSOnlyForFocus ${
                  inputRef.current
                    ? "border border-tacitbase-secondary3"
                    : "border border-tacitbase-tertiary1 border-opacity-25"
                  }  flex flex-wrap items-center w-full px-2 py-1.5`}
              >

                  <input
                    type="text"
                    className={`${
                      checked.length == 0 ? "w-full" : ""
                      } placeholder-italic outline-none text-sm  `}
                    placeholder={"search email or name"}
                    onChange={(e: any) => searchKeyword(e)}
                    ref={inputRef}
                  onBlur={() => inputRef.current?.blur()}
                  data-testid="searchMember"
                />
              </div>
              {searchedMember.trim() ? (
                <Overlay onClose={() => getSearchedMember("")
              } scope={Scope.Popover} reference={refer} >
                <SearchedResultPopup
                refer={refer}
                  click={(member: Member) => handleInputChange(member)}
                  selectedMember={checked}
                  searchResult={oMember}
                  searchInObject={{}}
                  searchInObjectKey={CDBMember}
                /></Overlay>
              ) : null}
            </div>
          </div>

          <div className="flex justify-center items-start h-8 pt-1  w-8 hover:bg-gray-200 rounded-default">
            <button tabIndex={0} className="p-1" onClick={props.close}>
              <CloseIcon />
            </button>
          </div>
        </div>
      </div>


      <div className="max-h-full h-full overflow-y-scroll mx-1">
        <div className="text-sm">{capitalizeFirstLetter("comment(optional)")} </div>
        <ReactTextareaAutosize
          className={`border-tacitbase-tertiary1 border-opacity-20 border  h-8 w-full py-2 px-2 mb-2 rounded focus:outline-none resize-none text-sm block overflow-hidden `}
          onChange={validateCommentLength}
          tabIndex={0}
          value={comment}
          data-testid="bulkCandidateComment"
          placeholder="write a comment to review"
        ></ReactTextareaAutosize>{comment.trim().length > 125 && <div className="text-red-500 py-2 text-sm">
          {capitalizeFirstLetter(MAX_CHAR_LIMIT_WARNING)}
        </div>}

        {checked.length > 0 && (
          <div className="flex flex-wrap mt-1 ">
            {checked.map(
              (reviewer, index: number) =>
                reviewer && (
                  <div key={index} className="p-1.5 rounded  w-auto relative  justify-between bg-gray-300 bg-opacity-25 hover:bg-gray-300 hover:bg-opacity-40 mb-2 mr-1 flex flex-row items-center space-x-2">
                    <button
                      onClick={() => removeMemberFromSelectedList(index)}
                      className="-top-1 -right-1 absolute border border-tacitbase-tertiary1 border-opacity-30 p-0.5 bg-white rounded-full "
                    >
                      <CloseIcon size="9px" />
                    </button>
                    <div className="flex flex-col">
                      {props.members[reviewer].image_url ? (
                        <Image src={props.members[reviewer].image_url} />
                      ) : null}
                    </div>
                    <div className="flex flex-col">
                      <div className="leading-snug font-bold text-sm text-gray-800 tracking-normal line-clamp-1">
                        {props.members[reviewer].username}
                      </div>
                      <div className="text-xs leading-snug tracking-wide text-gray-800">
                        {props.members[reviewer].email}
                      </div>
                    </div>
                  </div>
                )
            )}
          </div>
        )}
        </div>

      <div className="justify-end flex items-end">

        <Button type="primary" onClick={createReviews} loading={loading} >
          {capitalizeFirstLetter("send to review")}
        </Button>
      </div>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  members: state.opening.members,
  applicationId: state.opening.applicationId,
  candidateDBMembers: state.opening.candidateDBMembers,
  loginUser: state.opening.loginUser,
  clientId: state.opening.clientId,
});

export default connect(mapStateToProps)(CreateReview);
