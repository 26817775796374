import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router";
import { Dispatch } from "redux";
import { logoutUserAc } from "./redux/actions/opening/action";
import { tokenVerify } from "./service/tokenVerify"
import { SOCKET_CLIENT_ID } from "./constant";
import {  setAnyTokenInSessionStorage } from "./lib/api";

const ProtectedRoute = ({
  component: Component,
  logoutUserAc,
  loginUser,
  ...restOfProps
}: any) => {
  let isAuthenticated: boolean;
  let nevigation: string;

  if (tokenVerify()) {
    if(!sessionStorage.getItem(SOCKET_CLIENT_ID)){
      setAnyTokenInSessionStorage(SOCKET_CLIENT_ID,loginUser.user.id+"_"+new Date().getTime()).then(()=>{return true})
    }
    isAuthenticated = true;
   } else {
    localStorage.removeItem("login_token");
    sessionStorage.removeItem("current_tab_application_token");
    logoutUserAc();
    isAuthenticated = false;
    nevigation = "/login";
  }
  return (
    <>

      <Route
        {...restOfProps}
        render={(props) =>
          isAuthenticated ?(
              <Component
                key={props.match.params[restOfProps["identifire"]]}
                {...props}
              />
            )
           : (
            <Redirect to={`${nevigation}`} />
          )
        }
      />
    </>
  );
};
const mapPropsTostate = (dispatch: Dispatch) => ({
  logoutUserAc: () => dispatch(logoutUserAc()),
});
const mapStateToProps = (state: any) => ({
  loginUser: state.opening.loginUser,
  applicationId: state.opening.applicationId,
});
export default connect(mapStateToProps, mapPropsTostate)(ProtectedRoute);
