import React from "react";
import { LinkedinFilled } from "@ant-design/icons";
import { Typography } from "antd";
const { Text } = Typography;
const LinkedinSource = () => {
  return (
    <div className="mt-8">
      <div className="pr-4 flex gap-2">
        <LinkedinFilled
          style={{
            fontSize: "22px",
            color: "#0A66C2",
          }}
        />
        <Text strong>Source candidate from linkedin</Text>
      </div>
      <div className="mb-4 mt-2">
        <Text type="secondary" style={{ fontSize: "14px" }}>
          You will get an email id that you can use it to receive applications
          from linkedin.
        </Text>
      </div>
    </div>
  );
};

export default LinkedinSource;
