import React from "react";
export const columns = [
  {
    title: "Name",
    dataIndex: "Name",
  },
  {
    title: "Job posts",
    dataIndex: "Boards",
    render: (text: string) => <div style={{ textAlign: "right" }}>{text}</div>,
  },
  {
    title: "Sourced",
    dataIndex: "Sourced",
    sorter: (a: any, b: any) => a.Sourced - b.Sourced,
    render: (text: string) => <div style={{ textAlign: "right" }}>{text}</div>,
  },
  {
    title: "Offered",
    dataIndex: "Offered",
    sorter: (a: any, b: any) => a.Offered - b.Offered,
    render: (text: string) => <div style={{ textAlign: "right" }}>{text}</div>,
  },
  {
    title: "Joined",
    dataIndex: "Joined",
    sorter: (a: any, b: any) => a.Joined - b.Joined,
    render: (text: string) => <div style={{ textAlign: "right" }}>{text}</div>,
  },
  {
    title: "Joined rate",
    dataIndex: "Joined rate",
    sorter: (a: any, b: any) => {
      const joinedRateA =
        typeof a["Joined rate"] === "string" ? a["Joined rate"] : "";
      const joinedRateB =
        typeof b["Joined rate"] === "string" ? b["Joined rate"] : "";
      return joinedRateA.localeCompare(joinedRateB);
    },
    render: (text: string) => <div style={{ textAlign: "right" }}>{text}</div>,
  },
  {
    title: "Time to hire",
    dataIndex: "Time to hire",
    sorter: (a: any, b: any) =>
      a["Time to hire"].localeCompare(b["Time to hire"]),
    render: (text: string) => <div style={{ textAlign: "right" }}>{text}</div>,
  },
];
