import React, {  useState } from "react";
import SetupOrgName from "../../components/signUp/SetupOrgName";
import OrgNamePreview from "../../images/OrgNamePreview.png";
import Image from "../../components/common/image/Image";

import { Grid } from "antd"
import { connect } from "react-redux";
const { useBreakpoint } = Grid;
const OrgNamePage = () => {
  const [username, setUsername] = useState("");
  const screens = useBreakpoint();

  return (

      <div className="flex  pt-16    w-full h-full xl:overflow-y-scroll space-x-4 2xl:space-x-0 ">
        <div className="w-1/12"></div>
        {screens.xl && (
          <div className="w-8/12  2xl:w-7/12  flex  justify-end items-center ">
         <div className="relative top-0 ">  <Image src={OrgNamePreview} size="full" />
        

            <div
              className="flex w-full items-center justify-start space-x-2 absolute "
              style={{ top: "10%", left: "61.8%" }}
            >
              <div className="w-1/4" style={{ width: "10%" }}>
                <div className="truncate font-semibold text-white">
                  {username ? username : "Super Nice"}
                </div>
              </div>{" "}
            </div>
            <div
              className="flex w-full items-center justify-start space-x-2 absolute "
              style={{ top: "53.5%", left: "51%" }}
            >
              <div className="w-1/4" style={{ width: "22%" }}>
                <div className="truncate font-semibold">
                  {username ? username : "Super Nice"}
                </div>
              </div>{" "}
            </div>
            </div> 
          </div>
        )}
        <div className="xl:w-5/12 2xl:3/12 flex xl:justify-end justify-center h-full items-start w-full">
          <div
            className="bg-white     xl:p-8 p-4 rounded xl:mt-4 mt-2   "
            style={{
              width: 463,
              boxShadow: "2px 4px 10px 0px #00000040",
              border: "1px solid #D9D9D9",
            }}
          >
            <SetupOrgName UsernameForPreview={(value) => setUsername(value)} />
          </div>
        </div>
      <div className="w-1/12"></div>
    </div>
  );
};


const mapStateToProps = (state: any) => ({
  loginUser: state.opening.loginUser,
});
export default connect(mapStateToProps)(OrgNamePage);

