import React from "react";
import { Attachment } from "../../../type/type";

type useCheckedItemType = {
  labels: string[];
  attachments: Attachment[];
  members: string[];
};

const useCheckedItem = ({
  labels,
  attachments,
  members,
}: useCheckedItemType) => {
  let keepType: {
    key: string;
    count: number;
  }[] = [];
  const [checkedItems, setKeeping] = React.useState(keepType);

  React.useEffect(() => {
    let keep: {
      key: string;
      count: number;
    }[] = [];
    if (labels.length > 0) {
      keep.push({
        key: "labels",
        count: labels.length,
      });
    }
    if (attachments.length > 0) {
      keep.push({
        key: "attachments",
        count: attachments.length,
      });
    }
    if (members.length > 0) {
      keep.push({
        key: "members",
        count: members.length,
      });
    }

    // if (tasks[applicantID]) {
    //   keep.push({
    //     key: "tasks",
    //     count: tasks[applicantID].length,
    //   });
    // }
    // if (members[applicantID]) {
    //   keep.push({
    //     key: "members",
    //     count: members[applicantID].length,
    //   });
    // }
    setKeeping(keep);
  }, []);
  return { data: checkedItems };
};

export default useCheckedItem;
