import moment from "moment";
import React, {  useEffect, useState } from "react";
import { usePopper } from "react-popper";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import CloseIcon from "../../../../assets/Close";
import UserIcon from "../../../../assets/User";
import { getApplicant, getAttachmentByEventID } from "../../../../lib/api";
import { deleteCalEvent, deleteCalEventAttachments } from "../../../../lib/api";
import { EventAttachment } from "../../../../lib/apiRes";
import zoom from "../../../../images/zoom.png";
import meet from "../../../../images/meet.png";
import {
  deleteBoardEvent,
  deleteCardEvent,
} from "../../../../redux/actions/opening/action";
import { orgMember } from "../../../../type/type";
import AttachmentPreview from "../../../cardActions/addAttachment/AttachmentPreview";
import Image from "../../../common/image/Image";
import Portal from "../../../portal/Portal";
import MembersSmallIcon from "../../../../assets/MembersSmall";
import DescriptionIcon from "../../../../assets/Description";
import AttachmentIcon from "../../../../assets/Attachment";
import { getIcon } from "./GuestStatus";
import { SOCKET_CLIENT_ID } from "../../../../constant";
export type attendeeType = { email: "" };

type eventPopupProps = {
  currentEvent?: any;
  clientId: string;
  click: (e: boolean) => void;
  edit: (e: boolean) => void;
  deleteEventWithIdFromBoard: (id: string) => void;
  deleteEventWithIdFromCard: (id: string) => void;
  members: orgMember;
};
const EventPopupCal = (props: eventPopupProps) => {
  const [currentEvent, setCurrentEvent] = useState<any>();
  const [cardName, setCardName] = useState<string>("");
  const [guest, setGuest] = useState<[]>();
  const [deleteEventState, setDeleteEvent] = useState(false);
  const [attachment, setAttachment] = useState<EventAttachment[]>([]);

  useEffect(() => {
    getAttachmentByEventID(
      props.currentEvent.event.extendedProps.customEvent.id
    ).then((res) => {
      setAttachment(res.attachments);
    });

    setCurrentEvent(props.currentEvent.event.extendedProps.customEvent);
  }, [props.currentEvent.event.extendedProps.customEvent]);

  useEffect(() => {
    if (currentEvent) {
      let orgMember = Object.values(props.members);
      const arr =
        currentEvent.guest?.length > 0
          ? currentEvent.guest.map((obj: attendeeType) => {
              const email = obj.email;
              let id = "";
              const isPresent = orgMember.some(
                (someObj) => ((id = someObj.id), someObj.email === email)
              );
              if (isPresent) {
                return id;
              } else {
                return email;
              }
            })
          : [];

      setGuest(arr);

      getApplicant(currentEvent.cardID).then((res) => {
        setCardName(res.name);
      });
    }
  }, [currentEvent]);
  const deleteEvent = () => {
    let clientId=sessionStorage.getItem(SOCKET_CLIENT_ID)
    deleteCalEvent(currentEvent.id, clientId?clientId:"").then((res) => {
      if (res) {
        deleteCalEventAttachments(currentEvent.id).then(() => {
         
        });
        props.deleteEventWithIdFromBoard(currentEvent.id);
        props.deleteEventWithIdFromCard(currentEvent.id);
        props.click(false);
      }
    });
  };
  const editEvent = () => {
    props.click(false);
    props.edit(true);
  };

  const [preview, showPreview] = useState({
    show: false,
    presignedLink: "",
    fileName: "",
  });

  const showPreviewImg = (e: any, attachment: any) => {
    e.preventDefault();
    e.stopPropagation();
    showPreview({
      show: !preview.show,
      presignedLink: attachment.presignedLink,
      fileName: attachment.name.split("/").pop(),
    });
  };
  const closePreview = () => {
    showPreview({
      show: !preview.show,
      presignedLink: "",
      fileName: "",
    });
  };

  let [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>();
  let [popperElement, setPoperElement] = useState<HTMLDivElement | null>();
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start",
  });

  return (
    <>
      <div className="w-80 rounded  bg-white p-2 shadow-4xl flex flex-col ">
        <div className="flex items-center justify-between p-1 pb-2 space-x-2 w-full border-b border-tacitbase-tertiary1 border-opacity-25">
          <div className="font-semibold text-sm tracking-wider  w-11/12">
            {currentEvent ? (
              <div className="flex space-x-2 w-full">
                <div className="flex w-1/12 pt-1 ">
                  <div
                    className="h-3 w-3  rounded"
                    style={{ backgroundColor: currentEvent.backgroundColor }}
                  ></div>
                </div>
                <div className="flex flex-col space-y-1 w-11/12">
                  {" "}
                  <div className=" text-base tracking-wider  font-medium truncate">
                    {currentEvent.title}
                  </div>
                  <div className="uppercase text-gray-500 text-xs font-medium tracking-wider max-w-full">
                    {moment(new Date(currentEvent.date)).format(
                      "DD MMM YYYY  h:mm a - "
                    ) +
                      moment(
                        new Date(currentEvent.date).getTime() +
                          currentEvent.duration * 60 * 1000
                      ).format("h:mm a")}
                  </div>
                </div>
              </div>
            ) : null}
          </div>{" "}
          <button
            className="flex cursor-pointer justify-center items-start focus:outline-none w-1/12 mx-2"
            data-testid="PopupTitleTestBtn"
            onClick={() => {
              props.click(false);
            }}
            tabIndex={0}
          >
            <CloseIcon />
          </button>
        </div>

        <div className="flex  p-1 py-2 w-full h-max max-h-80 overflow-y-scroll  ">
          {" "}
          {currentEvent ? (
            <div className="flex flex-col w-full space-y-2">
              <div className="flex font-medium text-sm tracking-wider capitalize w-full space-x-2">
                <div className="w-1/12">
                  <i className="fa fa-credit-card fa-md "></i>
                </div>
                <div className="w-11/12 truncate">{cardName}</div>
              </div>

              {currentEvent.zoom_join_url ? (
                <div className="flex space-x-2">
                  <div className="w-1/12 -ml-0.5">
                    {" "}
                    <Image size="5" src={zoom} />
                  </div>
                  <div className="flex flex-col space-y-1 w-11/12">
                    <div className="text-sm tracking-wider  font-medium">
                      {" "}
                      Join Using zoom
                    </div>
                    <div className=" text-blue-400 text-xs font-medium tracking-wider max-w-full underline break-all">
                      <a href={currentEvent.zoom_join_url}>
                        {" "}
                        {currentEvent.zoom_join_url}
                      </a>
                    </div>
                  </div>{" "}
                </div>
              ) : currentEvent.meet_join_url ? (
                <div className="flex space-x-2">
                  <div className="w-1/12 -ml-0.5">
                    {" "}
                    <Image size="5" src={meet} />
                  </div>
                  <div className="flex flex-col space-y-1 w-11/12 -ml-0.5 ">
                    <div className="text-sm tracking-wider  font-medium">
                      {" "}
                      Join Using meet
                    </div>
                    <div className=" text-blue-400 text-xs font-medium tracking-wider max-w-full underline break-all">
                      <a href={currentEvent.meet_join_url}>
                        {" "}
                        {currentEvent.meet_join_url}
                      </a>
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="flex w-full space-x-2">
                <div className="w-1/12">
                  <MembersSmallIcon size="18" />
                </div>
                <div className="flex flex-col w-11/12 space-y-1 ">
                  <div className=" text-sm tracking-wider  font-medium w-full">
                    Guests({guest?.length})
                  </div>

                  <div className="flex-col space-y-2 w-full ">
                    {guest?.map((guest: string, i: number) => (
                      <div className="flex space-x-1 w-full" key={i}>
                        <span>
                          {props.members[guest] ? (
                            <div className="relative flex w-full">
                              <Image 
                                size="6"
                                src={props.members[guest].image_url}
                              />
                              {getIcon(
                                currentEvent.guest?.length > 0 &&
                                  currentEvent.guest[i]
                              )}
                              <div className="pl-4 text-gray-500 text-xs font-medium tracking-wider truncate">
                                {props.members[guest].email}
                              </div>
                            </div>
                          ) : (
                            <div className="relative flex w-full h-full iten=ms-center justify-center">
                              <div className="rounded h-6 w-6 border border-tacitbase-tertiary1 border-opacity-30   text-center">
                                <UserIcon />
                              </div>

                              {getIcon(
                                currentEvent.guest?.length > 0 &&
                                  currentEvent.guest[i]
                              )}
                              <div className="text-gray-500 text-xs font-medium tracking-wider truncate pl-4">
                                {currentEvent.guest[i].email}
                              </div>
                            </div>
                          )}
                        </span>
                        {/* <div className="text-gray-500 text-xs font-medium tracking-wider max-w-full">
                        {props.members[guest].email}
                      </div> */}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {currentEvent.description ? (
                <div className="flex space-x-2 w-full">
                  <div className="w-1/12">
                    <DescriptionIcon />
                  </div>
                  <div className="flex flex-col  space-y-1 w-11/12">
                    {" "}
                    <div className="text-sm tracking-wider  font-medium">
                      Description
                    </div>
                    <div className="text-gray-500 text-xs font-medium tracking-wider max-w-full break-all">
                      {currentEvent.description}
                    </div>
                  </div>
                </div>
              ) : null}
              {attachment ? (
                <div className="flex space-x-3 w-full">
                  <div className="w-1/12">
                    <AttachmentIcon />
                  </div>
                  <div className="flex flex-col w-11/12 space-y-1">
                    <div className="text-sm tracking-wider  font-medium">
                      Attachments
                    </div>
                    {attachment.map((attachment, i: number) => (
                      <div
                        className="truncate input-box  h-max w-full   flex items-center justify-between space-x-1 "
                        key={i}
                        onClick={(e: any) => showPreviewImg(e, attachment)}
                        onKeyDown={(e: any) => showPreviewImg(e, attachment)}
                        role="button"
                        tabIndex={0}
                      >
                        {" "}
                        <button className="w-max ">{attachment.name} </button>
                        <div> </div>
                      </div>
                    ))}
                    <div></div>
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
        <div
          className="flex w-full items-center justify-end  flex-wrap h-full"
          ref={setReferenceElement}
        >
          <button
            className="delete-btn"
            data-testid="add-card"
            onClick={() => setDeleteEvent(!deleteEventState)}
          >
            <span className="text-xs">{`
                 Delete
              `}</span>{" "}
          </button>
          <button
            className="submit-btn text-sm"
            data-testid="add-card"
            onClick={() => editEvent()}
          >
            <span className="text-xs">{`
                 Edit
              `}</span>
          </button>
        </div>
        {preview.show ? (
          <Portal>
            <AttachmentPreview
              link={preview.presignedLink}
              closePreview={closePreview}
              fileName={preview.fileName}
            />
          </Portal>
        ) : null}
      </div>{" "}
      {deleteEventState ? (
        <Portal>
          <div
            ref={setPoperElement}
            style={styles.popper}
            {...attributes.popper}
          >
            <div
              className="flex flex-col h-auto mt-1 z-20 w-72 bg-white text-gray-800 tracking-normal border border-gray-300  text-sm font-normal rounded shadow-4xl"
              // ref={props.refer}
            >
              <div className="flex mt-2">
                <div
                  className={`flex justify-center tracking-normal font-normal  items-center 
            text-sm text-gray-500
           w-full`}
                >
                  Delete event?{" "}
                </div>

                <button
                  className="flex cursor-pointer justify-center items-center focus:outline-none mx-2"
                  data-testid="testbtn1"
                  onClick={() => setDeleteEvent(!deleteEventState)}
                >
                  <CloseIcon />
                </button>
              </div>
              <div className="flex">
                <hr
                  className=" w-full m-2"
                  style={{ borderColor: "#0F0A0D", opacity: "0.3" }}
                ></hr>
              </div>{" "}
              <div className="m-2">
                <div
                  className="text-sm flex w-full items-end "
                  onClick={(e: any) => e.stopPropagation()}
                  onKeyDown={(e: any) => e.stopPropagation()}
                  data-testid="testbtn2"
                  tabIndex={0}
                  role="button"
                >
                  Deleting an event is permanent. There is no undo.
                </div>
                <button
                  className="delete-btn text-xs"
                  data-testid="add-card"
                  onClick={() => deleteEvent()}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </Portal>
      ) : null}
    </>
  );
};
const mapStateToProps = (state: any) => ({
  members: state.opening.members,
  clientId: state.opening.clientId,
});
const mapPropsToState = (dispatch: Dispatch) => ({
  deleteEventWithIdFromBoard: (event_id: string) =>
    dispatch(deleteBoardEvent(event_id)),
  deleteEventWithIdFromCard: (event_id: string) =>
    dispatch(deleteCardEvent(event_id)),
});
export default connect(mapStateToProps, mapPropsToState)(EventPopupCal);
