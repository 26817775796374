import React from "react";

import { connect } from "react-redux";
import { loginUser, orgMember } from "../../../type/type";
import ExpandCollapsDescription from "../../common/ExpandCollapsDesc/ExpandCollapsDescription"
type ActivityProps = {
  activity: any;
  loginUser: loginUser;
  members: orgMember;
};
const ActivityMsg = (props: ActivityProps) => {

  var applicantActivity: any = {};
  applicantActivity = props.activity;
  if (!applicantActivity) return null;
  if (
    applicantActivity.action == "added" &&
    applicantActivity.sub_trackable == "card"
  ) {
    return (
      <>
        {applicantActivity.action} this {applicantActivity.sub_trackable} to{" "}
        {applicantActivity.trackable} {applicantActivity.trackable_name}
      </>
    );
  } else if (
    applicantActivity.action == "added" &&
    applicantActivity.sub_trackable == "label"
  ) {
    return (
      <>
        {applicantActivity.action} {applicantActivity.sub_trackable}{" "}
        {applicantActivity.sub_trackable_name} to this{" "}
        {applicantActivity.trackable}
      </>
    );
  } else if (
    applicantActivity.action == "added" &&
    applicantActivity.sub_trackable == "attachment"
  ) {
    return (
      <>
        {applicantActivity.action} {applicantActivity.sub_trackable}{" "}
        {applicantActivity.sub_trackable_name} to this{" "}
        {applicantActivity.trackable}
      </>
    );
  } else if (
    applicantActivity.action == "added" &&
    applicantActivity.sub_trackable == "member"
  ) {
    if (applicantActivity.sub_trackable_id === applicantActivity.owner_id)
      return <>joined this {applicantActivity.trackable}</>;
    else
      return (
        <>
          {applicantActivity.action}{" "}
          <span className="font-semibold">{props.members[applicantActivity.sub_trackable_id] && props.members[applicantActivity.sub_trackable_id].first_name}{" "}
            {props.members[applicantActivity.sub_trackable_id] && props.members[applicantActivity.sub_trackable_id].last_name}</span> to this{" "}
          {applicantActivity.trackable}
        </>
      );
  }
  else if (
    applicantActivity.action == "deleted" &&
    applicantActivity.sub_trackable == "attachment"
  ) {
    return (
      <>
        {applicantActivity.action} {applicantActivity.sub_trackable}{" "}
        {applicantActivity.sub_trackable_name}
        {/* <span>to</span>
        <span>this</span>
        <span>{applicantActivity.trackable}</span> */}
      </>
    );
  } else if (
    applicantActivity.action == "removed" &&
    applicantActivity.sub_trackable == "member"
  ) {
    if (applicantActivity.sub_trackable_id === applicantActivity.owner_id)
      return <>left this {applicantActivity.trackable}</>;
    else {
      return (
        <>
          {applicantActivity.action}{" "}
          <span className="font-semibold">{props.members[applicantActivity.sub_trackable_id] && props.members[applicantActivity.sub_trackable_id].first_name}{" "}
            {props.members[applicantActivity.sub_trackable_id] && props.members[applicantActivity.sub_trackable_id].last_name}</span> from{" "}
          this {applicantActivity.trackable}
        </>
      );
    }
  } else if (
    applicantActivity.action == "updated" &&
    applicantActivity.sub_trackable == "attachment"
  ) {
    return (
      <>
        {applicantActivity.action} the {applicantActivity.sub_trackable}
        {applicantActivity.old_val} to the
        {applicantActivity.sub_trackable_name}
      </>
    );
  } else if (applicantActivity.action == "moved") {
    return (
      <>
        {applicantActivity.action} this {applicantActivity.sub_trackable} from{" "}
        {applicantActivity.old_val} to {applicantActivity.new_val}
      </>
    );
  } else if (applicantActivity.action == "archived") {
    return (
      <>
        {applicantActivity.action} this {applicantActivity.sub_trackable}
      </>
    );
  } else if (
    applicantActivity.action == "updated" &&
    applicantActivity.sub_trackable == "description"
  ) {
    return (
      <>
        {applicantActivity.action} {applicantActivity.sub_trackable} to
        <ExpandCollapsDescription data={applicantActivity.new_val} />
      </>
    );
  } else if (
    applicantActivity.action == "credited" &&
    applicantActivity.sub_trackable == "reward"
  ) {
    return (
      <>
        {applicantActivity.action}{" "}
        <span className="uppercase">
          {" "}
          {applicantActivity.sub_trackable_name}
        </span>{" "}
        {applicantActivity.sub_trackable} points to this{" "}
        {applicantActivity.trackable}
      </>
    );
  } else if (
    applicantActivity.action == "debited" &&
    applicantActivity.sub_trackable == "reward"
  ) {
    return (
      <>
        {applicantActivity.action} {applicantActivity.sub_trackable} points for
        this {applicantActivity.trackable}
      </>
    );
  } else if (applicantActivity.action == "unarchived") {
    return (
      <>
        {applicantActivity.action} this {applicantActivity.sub_trackable}
      </>
    );
  } else if (
    applicantActivity.action == "added" &&
    applicantActivity.sub_trackable == "candidate"
  ) {
    return (
      <>
        {applicantActivity.action} the {applicantActivity.trackable}
      </>
    );
  } else if (
    applicantActivity.action == "updated" &&
    applicantActivity.sub_trackable == "candidate"
  ) {
    return (
      <>
        {applicantActivity.action} the {applicantActivity.trackable} profile
      </>
    );
  } else if (
    applicantActivity.action == "created" &&
    applicantActivity.sub_trackable == "review"
  ) {
    return (
      <>
        {applicantActivity.action} the {applicantActivity.sub_trackable}
      </>
    );
  } else if (
    applicantActivity.action == "added" &&
    applicantActivity.sub_trackable == "board"
  ) {
    return (
      <>
        {applicantActivity.action} {applicantActivity.trackable_name} to the{" "}
        {applicantActivity.sub_trackable_name}
      </>
    );
  } else if (
    applicantActivity.action == "closed" &&
    applicantActivity.sub_trackable == "review"
  ) {
    return (
      <>
        {applicantActivity.action} the {applicantActivity.sub_trackable}
      </>
    );
  }

  return <></>;
};

const mapStateToProps = (state: any) => ({
  loginUser: state.opening.loginUser,
  members: state.opening.members,
});

export default connect(mapStateToProps)(ActivityMsg);
