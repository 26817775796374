import React from "react";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import { Button } from "antd";
import { useHistory } from "react-router-dom";

const ContactOwner = () => {
    const history=useHistory()
    const UserNavigationToOrgSwitcher = async() => {
        history.push("/o");
      };
    
  return (
    <div>
      <div className=" flex flex-col space-y-6">
        <div className="w-full flex items-center justify-center font-semibold md:text-lg text-base">
          {capitalizeFirstLetter("Huston, we have a problem")}
        </div>
        <div className="flex flex-col space-y-4 lg:text-sm text-xs">
          <div>
            <div className="tracking-wide">
              Our records indicate that there is an issue, looks like your
              account administrator is yet to add payment details.
            </div>
            <div></div>
          </div>
        </div>
        <div>
          To continue enjoying Tacitbase seamlessly, please take a moment to
          update your billing information or contact your account administrator.
        </div>
        <div>Are you an administrator?  <a
              href="https://support.tacitbase.com/"
              style={{ color: "#009D79" }}
            >
             Contact us.
            </a> </div>
            <div className="flex justify-end w-full">
          <Button
                htmlType="submit"
                className="w-max" 
            onClick={() => UserNavigationToOrgSwitcher()}
            type="primary"
              >
                Go back
              </Button>
            </div>
      </div>
    </div>
  );
};

export default ContactOwner;
