import React, {  useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
  editRoleOfBoardMember,
  removeOpeningMembership,
} from "../../../../lib/api";
import {
  editRoleOfMember,
  removeMemberFromOpening,
} from "../../../../redux/actions/opening/action";
import {
  Member,
  Opening,
  openingMemberType,
  PermissionAccess,
} from "../../../../type/type.d";
import { capitalizeFirstLetter } from "../../../../utils/capitalizeFirstLetter";
import EllipsButtonPopup from "../../../common/image/EllipsButtonPopup";
import EditRole from "./EditRole";
import { SOCKET_CLIENT_ID } from "../../../../constant";
type BoardMemberActionProps = {
  selectedMember: openingMemberType;
  refer: any;
  applicationId: string;
  currentOpening: Opening;
  editRoleOfMember: (
    memberId: string,
    access: PermissionAccess,
    r_id: string
  ) => void;
  removeMember(memberID: string, applicantID: string): void;
};
const BoardMemberAction = (props: BoardMemberActionProps) => {
  const [open, setOpen] = useState(false);
  // const refer=useRef(null)


  const [selectedRole, selectRole] = useState<string>(
    props.selectedMember
      ? props.selectedMember.access.admin
        ? capitalizeFirstLetter("admin")
        : props.selectedMember.access.comment_only
        ? capitalizeFirstLetter("commentor")
        : capitalizeFirstLetter("member")
      : ""
  );

  const [isSecretVisible, setSecretVisible] = useState<boolean>(
    props.selectedMember.access.is_secrete_visible
  );

  const editRole = () => {
    const req = {
      id: props.currentOpening.id,
      name: "openings",
      parent_resource_id: "",
      member: [
        {
          id: props.selectedMember.id,
          role: selectedRole,
          is_secrete_visible: isSecretVisible,
        },
      ],
      socket_client_id: sessionStorage.getItem(SOCKET_CLIENT_ID),

    };

    editRoleOfBoardMember(req)
      .then((res: Member) => {
        props.editRoleOfMember(res.id, res.access, req.id);
        setOpen(!open);
      })
     
  };
  const removeMember = () => {
    const req = {
      id: props.selectedMember.id,
      opening_id: props.currentOpening.id,
      application_id: props.applicationId,
      socket_client_id: sessionStorage.getItem(SOCKET_CLIENT_ID),

    };
    removeOpeningMembership(req)
      .then((success: any) => {
        if (success)
          props.removeMember(props.selectedMember.id, props.currentOpening.id);
      })
    
  };
  const openPopup = () => {
    setOpen(!open);
  };
  return (
    <>
      <EllipsButtonPopup setOpen={openPopup} openPopup={open} refer={props.refer}>
        <EditRole
          selectedMember={props.selectedMember}
          click={() => setOpen(!open)}
          refer={props.refer}
          role={selectedRole}
          selectedRole={(role: any) => selectRole(role)}
          editRole={editRole}
          setSecreteVisible={() => setSecretVisible(!isSecretVisible)}
          isSecretVisible={isSecretVisible}
          remove={removeMember}
        />
      </EllipsButtonPopup>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  openingMembers: state.opening.openingMembers,
  loginUser: state.opening.loginUser,
  currentOpening: state.opening.currentOpening,
  applicationId: state.opening.applicationId,
});
const mapPropsToState = (dispatch: Dispatch) => ({
  editRoleOfMember: (
    memberId: string,
    access: PermissionAccess,
    r_id: string
  ) => dispatch(editRoleOfMember(memberId, access, r_id)),
  removeMember: (memberID: string, openingId: string) =>
    dispatch(removeMemberFromOpening(memberID, openingId)),
});
export default connect(mapStateToProps, mapPropsToState)(BoardMemberAction);
