import { ACTIVITY_TAG } from "../../constant";
import { initOpeningActivity } from "./initialState"

export function init_activity(state: any, action: any) {
  return {
    ...state,
    activity: [...action.data],
  };
}
export function init_opening_activity(state: any, action: any) {
  return {
    ...state,
    openingActivity: {
      [action.openingID]: state.openingActivity[action.openingID]
        ?action.currentPage==1? action.data: [...state.openingActivity[action.openingID], ...action.data]
        : action.data,
    },
  };
}
export function clear_board_activity(state: any) {
  return {
    ...state,
    openingActivity: initOpeningActivity
  }
}
export function add_activity(state: any, action: any) {
  let id =
    action.data.trackable === "list"
      ? action.data.sub_trackable_id
      : action.data.trackable_id;
  return {
    ...state,
    comments: {
      ...state.comments,
      [id]: {
        ...state.comments[id],
        [action.data.id]: {
          ...action.data,
          tag: action.tag,
        },
      },
    },
    openingActivity: {
      ...state.openingActivity,
      [action.data.topic_id]: state.openingActivity[action.data.topic_id]
        ? [...state.openingActivity[action.data.topic_id], action.data]
        : [action.data],
    },
  };
}
export function update_activity(state: any, action: any) {
  return {
    ...state,
    comments: {
      ...state.comments,
      [action.data.trackable_id]: {
        ...state.comments[action.data.trackable_id],
        [action.data.id]: {
          ...action.data,
          tag: ACTIVITY_TAG,
        },
      },
    },
    openingActivity: {
      ...state.openingActivity,
      [action.data.topic_id]: state.openingActivity[action.data.topic_id]
        ? [...state.openingActivity[action.data.topic_id], action.data]
        : [action.data],
    },
  };
}
