import React, { useEffect, useRef, useState } from "react";
import {
  Responsive as ResponsiveGridLayout,
  WidthProvider,
} from "react-grid-layout";
import "../../styles/component/AnalyticsContent.css";
import {
  getGraphDataByChartType,
  getGraphLayout,
  updateLayoutsPosition,
} from "../../lib/api";
import GraphComponent from "./GraphComponent";
import LoadingIndicator from "./LodingIndicator";
import { Empty } from "antd";
import { LayoutData } from "../../lib/apiRes";
export const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const ResponsiveReactGridLayout = WidthProvider(ResponsiveGridLayout);

interface AnalyticsBodyProps {
  start_time?: string;
  end_time?:string;
  entity_type: string;
  entity_names: string[];
  size?:string;
}

const AnalyticsContent: React.FC<AnalyticsBodyProps> = ({
  entity_type,
  entity_names,
  start_time,
  end_time,
  size
}) => {
  const isMounted = useRef(true); // useRef to track mounted state

  const [layouts, setLayouts] = useState<LayoutData[]>([]);
  const [dataLoading, setDataLoading] = useState<{ [key: number]: boolean }>(
    {}
  );

  const [chartData, setChartData] = useState<{ [key: number]: any }>({});

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  const [dataerror, setdataError] = useState<{ [key: string]: boolean }>({});


  useEffect(() => {
    const fetchData = async () => {
      try {
        const layoutData = await getGraphLayout(); // Fetch layout information
        setLayouts(layoutData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching layout data:", error);
        setError("Failed to fetch data. Please try again later.");
        setLoading(false);
      }
    };

    fetchData();

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const fetchDataForGraphs = async () => {
      layouts?.forEach((graph: any) => {
        if (graph.graph_type === "table") {
          fetchDataForGraph(
            graph.graph_type,
            graph.template_id,
            entity_type,
            entity_names,
            userTimeZone,
            start_time,
            end_time,
            1,
            7
          );
        } else {
          fetchDataForGraph(
            graph.graph_type,
            graph.template_id,
            entity_type,
            entity_names,
            userTimeZone,
            start_time,
            end_time,
            
          );
        }
      });
    };

    fetchDataForGraphs();
  }, [layouts, entity_names, start_time]);

  const fetchDataForGraph = async (
    graph_type: string,
    template_id: string,
    entity_type: string,
    entity_names: string[],
    time_zone:string,
    start_time?: string,
    end_time?:string,
    page?: number,
    page_size?: number,
    
  ) => {
    try {
      setDataLoading((prevState) => ({
        ...prevState,
        [template_id]: true,
      }));

      let entity_names_string = "";
      entity_names?.map(
        (entity) =>
          (entity_names_string =
            entity_names_string + `&entity_names=${entity}`)
      );
      // let ep = `type=${graph_type}&template_id=${template_id}&entity_type=${entity_type}${p}&start_time=${start_time}`;

      // if (page && page_size) {
      //   ep += `&page=${page}&page_size=${page_size}`;
      // }

      

      const res = await getGraphDataByChartType(graph_type, {
        graph_type,
        template_id,
        entity_type,
        entity_names_string,
        start_time,
        end_time,
        time_zone,
        page,
        page_size,
        
      });

      if (isMounted.current) {
        setChartData((prevData) => ({
          ...prevData,
          [template_id]: res,
        }));
        setDataLoading((prevState) => ({
          ...prevState,
          [template_id]: false,
        }));
        // Clear error state if data fetch succeeds
        setdataError((prevErrors) => ({
          ...prevErrors,
          [template_id]: false,
        }));
      }
    } catch (error) {
      console.error(`Error fetching data for graph ${template_id}:`, error);

      setdataError((prevErrors) => ({
        ...prevErrors,
        [template_id]: true,
      }));

      setDataLoading((prevState) => ({
        ...prevState,
        [template_id]: false,
      }));
    }
  };

  const handleDragStart = (
    layout: any,
    oldItem: any,
    newItem: any,
    placeholder: any,
    e: any,
    element: any
  ) => {
    element.style.zIndex = "1000";
    element.style.cursor = "grabbing";
  };

  const handleDragStop = (
    layout: any,
    oldItem: any,
    newItem: any,
    placeholder: any,
    e: any,
    element: any
  ) => {
    element.style.zIndex = "auto";
    element.style.cursor = "grab";
  };

  const handleLayoutChange = async (newLayouts: any[]) => {
    try {
      await updateLayoutsPosition({ layouts: newLayouts });
      // console.log("Layouts sent to backend:", newLayouts);
    } catch (error) {
      console.error("Error sending layouts to backend:", error);
    }
  };

  const renderGraph = (Layout: any) => {
    const { template_id, name, tooltip_message, chart_type } = Layout;

    return (
      <div
        key={template_id}
        className=" border bg-white hover:border-purple-400 cursor-grabbing rounded p-4 h-full"
      >
        <GraphComponent
          data={chartData[template_id]}
          loading={dataLoading[template_id]}
          dataerror={dataerror[template_id]}
          chart_type={chart_type}
          templateId={template_id}
          name={name}
          tooltipMessage={tooltip_message}
          entity_type={entity_type}
          entity_names={entity_names}
          start_time={start_time}
        />
      </div>
    );
  };

  return (
    <div className="max-w-screen overflow-x-hidden overflow-y-scroll h-screen bg-white pt-0 mt-0 border-t">
      {loading ? (
        <div className="flex justify-center items-center h-full ">
          <LoadingIndicator loader_message="Loading templates..." />
        </div>
      ) : error ? (
        <div className="flex justify-center items-center h-full ">
          <Empty
            // image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Unable to fetch data , please try again..."
          />
        </div>
      ) : 
        layouts?.length>0?(
        <ResponsiveReactGridLayout
          className="layout"
          style={{height:size?size:'2644px'}}
          layouts={{ lg: layouts?.map((graph) => graph.Layout) }}
          cols={{ lg: 4 }}
          onLayoutChange={handleLayoutChange}
          width={1200}
          compactType="vertical"
          breakpoints={{ lg: 1200 }}
          containerPadding={[16, 16]}
          margin={[16, 16]}
          onDragStart={handleDragStart}
          onDragStop={handleDragStop}
          rowHeight={203}
        >
          {layouts.map((Layout) => renderGraph(Layout))}
        </ResponsiveReactGridLayout>
        
      ) : (
        <div className="flex justify-center items-center h-full">
          <Empty description="No layouts available" />
        </div>
      )
      }
    </div>
  );
};

export default AnalyticsContent;
