import { Button, Divider, Form, Input, InputNumber, Radio, Select, Space, Tooltip, Typography } from "antd"
import Title from "antd/es/typography/Title";
import React, { useEffect, useState } from "react"
import FormItem from "../../ant/FormItem";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import { Jd, Opening } from "../../type/type"
import { connect } from "react-redux";
import { compensation_error, experience_error, experience_level_from, experience_level_to, MAX_EXPERIENCE_ERROR, number_of_open_positions, salary_range_from, salary_range_to } from "../../constant"
import { filterLocations, Location } from "../../utils/location"
import { PlusOutlined } from "@ant-design/icons"
import { getDepartment } from "../../lib/api"
import { newOrganisation } from "../../lib/apiRes"
import { FormInstance } from "antd/lib"
const { Text } = Typography;
const JDInformation = (props: {
  JDFormData: Jd;
  currentOpening: Opening;
  onChangeField: (job: { [key: string]: any }) => void;
  orgdata: newOrganisation,
  form: FormInstance
}) => {
  const [searchText, setSearchText] = useState('')
  const [inputValue, setInputValue] = useState("")

  const [allDepartments, setDepartment] = useState(["Engineering", "Marketing", "HR"]);
  const validateExperience = (_: any, value: number) => {
    if (value > 100) {
      return Promise.reject(new Error(MAX_EXPERIENCE_ERROR))
    }
    if (value > props.JDFormData.experience_level_to) {
      return Promise.reject(new Error(experience_error))
    }
    return Promise.resolve()
  }
  const validateExperienceTo = (_: any, value: number) => {
    if (value > 100) {
      return Promise.reject(new Error(MAX_EXPERIENCE_ERROR))
    }
    return Promise.resolve()
  };

  useEffect(() => {
    getDepartment(props.orgdata.career_page_url_suffix).then((res) => {
      const uniqueDepartments = Array.from(new Set([...allDepartments, ...res.department]));
      setDepartment(uniqueDepartments)
    })
  }, [])
  // Initialize default values as a list of location strings

  const jobTypes = [
    { label: "Full-time", value: "Full-time" },
    { label: "Part-time", value: "Part-time" },
    { label: "Internship", value: "Internship" },
    { label: "Contract", value: "Contract" },
  ]

  // available workplace types
  const workplaceTypes = [
    { label: "On site", value: "On-site" },
    { label: "Remote", value: "Remote" },
    { label: "Hybrid", value: "Hybrid" },
  ]
  const handleDeselect = (value: string, options: any) => {
    console.log(props.JDFormData.locations, value, options);

    // Logic to handle when an item is deselected (removed)
    const updatedValues = props.JDFormData.locations.filter((item: any) => {
      const locationString = `${item.city}, ${item.state}, ${item.country}`
      return locationString !== value
    })

    props.onChangeField({ locations: updatedValues })
  };

  const handleOnChange = (value: any, options: any) => {

    let jdLocations = props.JDFormData.locations ? props.JDFormData.locations : []
    props.onChangeField({ locations: [...jdLocations, options.locationObj] })

    setSearchText("")

  }
  const validateNumberOfOpening = (_: any, value: number) => {
    if (value < 0) {
      return Promise.reject("Must be greater than zero")
    }
    if (value > 100) {
      return Promise.reject("Must be less than 100")
    }
    return Promise.resolve()
  }

  const validateSalary = (_: any, value: number, context: FormInstance) => {
    const { getFieldValue } = context
    const minSalary = getFieldValue(salary_range_from)
    const maxSalary = getFieldValue(salary_range_to)

    if (minSalary && maxSalary) {
      if (minSalary > maxSalary) {
        return Promise.reject(compensation_error)
      }
    }

    return Promise.resolve();
  };
  const addItem = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    e.preventDefault()
    if (inputValue.length > 0 &&!allDepartments.includes(inputValue)) {
      // setItems([...items, inputValue])
      setDepartment([...allDepartments, inputValue])
      setInputValue("")
    }
  };
  const handleExperienceChange = () => {
    // Trigger validation for both experience fields
    props.form.validateFields(["experience_level_from"])
  }
  const handleSalaryChange = () => {
    // Trigger validation for both salary fields
    props.form.validateFields(["salary_range_from", "salary_range_to"])
  };
  return (
    <>
      <div className="overflow-y-scroll  h-full flex flex-col pr-2">
        <Title level={5} className="pt-2 sticky top-0">
          {"Job post information"}
        </Title>
        <Divider style={{ margin: 0 }} className="pb-3" />
        <Text strong>{"Job post details"}</Text>
        <Text className="mb-3">
          {
            "Provide a brief overview of the job post, workplace type and location of job within the company"
          }
        </Text>
        {/* <Form layout="vertical" > */}
        <div className="flex 2xl:w-4/5 xl:w-full xs:w-full flex-col gap-4 mb-4 sm:flex-col md:flex-col lg:flex-row lg:gap-8">
          <div className="w-full">
            <FormItem
              name="department"
              label={capitalizeFirstLetter("department")}
            >
              <Select

                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider style={{ margin: "8px 0" }} />
                    <Space style={{ padding: "0 8px 4px" }}>
                      <Input
                        placeholder="Please enter department"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}

                        onKeyDown={(e) => e.stopPropagation()}
                      />
                      <Button
                        type="text"
                        icon={<PlusOutlined />}
                        onClick={addItem}
                      >
                        {capitalizeFirstLetter("add department")}
                      </Button>
                    </Space>
                  </>
                )}

                options={allDepartments.map((item: string) => ({
                  label: item,
                  value: item,
                }))}

              />
            </FormItem>
          </div>
          <div className="w-full  ">
            <FormItem
              name="company_name"
              label={capitalizeFirstLetter("company name")}
            >
              <Input
              showCount
              maxLength={50}
                  onKeyDown={(e) => e.stopPropagation()}
                  />
            </FormItem>
          </div>
          {/* </div> */}
        </div>
        <div className="flex 2xl:w-4/5 xl:w-full xs:w-full flex-col gap-4 mb-4 sm:flex-col md:flex-col lg:flex-row lg:gap-8">
          <div
            className="w-full flex items-center rounded "
            style={{ backgroundColor: "#F0EFFE", padding: "14px" }}
          >
            <FormItem
              required
              name="job_type"
              label={capitalizeFirstLetter("job type")}
              rules={[
                {
                  required: true,
                  message: capitalizeFirstLetter("Job type required"),
                },
              ]}
            >
              <Radio.Group options={jobTypes} className="mb-4" />
            </FormItem>
          </div>

          <div
            className="w-full  flex items-center rounded"
            style={{ backgroundColor: "#F0EFFE", padding: "14px" }}
          >
            <FormItem
              required
              name="workplace_type"
              label={capitalizeFirstLetter("workplace type")}
              rules={[
                {
                  required: true,
                  message: capitalizeFirstLetter("Workplace type required"),
                },
              ]}
            >
              <Radio.Group options={workplaceTypes} className="mb-4" />
            </FormItem>
          </div>
        </div>
        <div className="flex 2xl:w-4/5 xl:w-full xs:w-full flex-col gap-4 mb-4 sm:flex-col md:flex-col lg:flex-row lg:gap-8">
          <div className="w-full">
            <FormItem
              className="w-full"
              name= {number_of_open_positions}
              label={capitalizeFirstLetter("Number of openings")}
              rules={[{ validator: validateNumberOfOpening }]}
            >
              <InputNumber
                style={{ width: "100%" }}
                min={0}
                onKeyPress={(e) => {
                  if (e.key === "-") {
                    e.preventDefault()
                  }
                }}
              />
            </FormItem>
          </div>
          <div className="w-full  ">
            <FormItem
              name="locations"
              label={capitalizeFirstLetter("locations")}
              rules={[
                {
                  required: true,
                  message: capitalizeFirstLetter("Location required"),
                },
              ]}
            >
              <Select
                mode="multiple"
                maxTagCount={"responsive"}
                onChange={() => { }}
                // defaultValue={JDLocations}
                onSearch={(value) => setSearchText(value)}
                showSearch
                // value={JDLocations}
                defaultActiveFirstOption={false}
                
                suffixIcon={null}
                filterOption={false}
                onSelect={handleOnChange}
                notFoundContent={null}
                maxCount={4}

                optionFilterProp="children"
                onDeselect={handleDeselect}
                maxTagPlaceholder={(omittedValues) => (
                  <Tooltip
                    overlayStyle={{ pointerEvents: 'none' }}
                    title={omittedValues.map(({ label }) => label).join(', ')}
                  >
                    <div className="flex justify-center items-center space-x-1">
                      <PlusOutlined style={{ fontSize: "10px" }}
                      ></PlusOutlined>
                      <span>
                        {omittedValues.length}
                      </span>
                    </div>
                  </Tooltip>
                )}
              >
                {filterLocations(searchText).map((location: Location) => (
                  <Select.Option
                    key={location.city}
                    value={location.location}
                    locationObj={location}
                  >
                    {location.location}
                  </Select.Option>
                ))}
              </Select>

            </FormItem>
          </div>
        </div>
        <div className="mb-3 mt-4">
          <div>
            <Text strong>{"Additional details"}</Text>
          </div>
          <Text>
            {
              "Provide a additional details of the job post, preferred years of relevent experience and compensation"
            }
          </Text>{" "}
        </div>
        <div className="flex 2xl:w-4/5 xl:w-full xs:w-full flex-col gap-4 mb-4 sm:flex-col md:flex-col lg:flex-row lg:gap-8">
          <div className="w-full">

            <label className="font-medium pb-2">
              {capitalizeFirstLetter("Work experience")}
            </label>
            <Space.Compact className="w-full">
              <Form.Item
                name={experience_level_from}
                className="w-full mt-2"
              rules={[{ validator: validateExperience }]}
            >
                <InputNumber
                  min={0}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
                  addonAfter="to"
                  // value={props.data.experiance_from}
                  className="w-full"
                  onKeyPress={(e) => {
                    if (e.key === "-") {
                      e.preventDefault()
                    }
                  }} 
                  onChange={handleExperienceChange}

              />
              </Form.Item>

              <Form.Item
                name={experience_level_to}
                className="w-full mt-2"
              rules={[{ validator: validateExperienceTo }]}
            >
                <InputNumber
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  min={0}
                  parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
                  addonAfter="Years"
                style={{ width: "100%" }}
                  className="w-full"
                  onKeyPress={(e) => {
                    if (e.key === "-") {
                      e.preventDefault()
                    }
                  }}
                  onChange={handleExperienceChange}

              />
              </Form.Item>
            </Space.Compact>
          </div>
          <div className="w-full mb-2">
            <label className="font-medium">
              {capitalizeFirstLetter("Compensation")}
            </label>
            <Space.Compact >

              <Form.Item
                name={salary_range_from}
                className="w-full mt-2"
                label=""
                rules={[
                  {
                    validator: (_, value) => validateSalary(_, value, props.form),
                  },
                ]}              >
                <InputNumber
                  addonBefore={
                    // <FormItem className="w-24"
                    //   name="currency_type" >
                    <Select defaultValue="USD" onSelect={(value) => props.onChangeField({ "salary_type": value })}>
                      <Select.Option value="INR">INR</Select.Option>
                      <Select.Option value="USD">USD</Select.Option>
                    </Select>
                    // </FormItem>
                  }

                  addonAfter="to"
                  // value={props.data.minimum_salary}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
                  className="w-full"

                  min={0}
                  onKeyPress={(e) => {
                    if (e.key === "-") {
                      e.preventDefault()
                    }
                  }}
                  onChange={handleSalaryChange}

              />
              </Form.Item>
              <Form.Item
                name={salary_range_to}
                className="w-full  mt-2"
              >
                <InputNumber

                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  addonAfter={<Select defaultValue="Annual" onSelect={(value) => props.onChangeField({ "salary_type": value })} className="w-24">
                    <Select.Option value="Annual">Annual</Select.Option>
                    <Select.Option value="Monthly">Monthly</Select.Option>
                  </Select>}
                  min={0}
                  parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
                  // value={props.data.maximum_salary}
                  className="w-full"
                  onKeyPress={(e) => {
                    if (e.key === "-") {
                      e.preventDefault()
                    }
                  }}
                  onChange={handleSalaryChange}

              />
              </Form.Item>

            </Space.Compact>
          </div>
        </div>


      </div>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  applicationId: state.opening.applicationId,
  currentOpening: state.opening.currentOpening,
  orgdata: state.opening.currentOrg,

});

export default connect(mapStateToProps)(JDInformation);
