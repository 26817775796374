import React from 'react';


interface StackOverflowprops{
    size?:number
}


const StackOverflow = (props:StackOverflowprops) => (
    <svg width={props.size?props.size:"26"} height={props.size?props.size:"26"} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.0005 12.1719H15.3339V17.5032H3.33301V12.1719H4.66635V16.1703H14.0004L14.0005 12.1719Z" fill="#BBBBBB"/>
<path d="M6.11977 11.7738L12.6687 13.1494L12.9439 11.8408L6.39542 10.4643L6.11977 11.7738ZM6.98633 8.639L13.053 11.4634L13.6184 10.2506L7.55133 7.42613L6.98633 8.639ZM8.66505 5.66408L13.8081 9.9455L14.6647 8.9171L9.52153 4.63626L8.66505 5.66408ZM11.9852 2.5L10.9109 3.29811L14.905 8.66595L15.9788 7.86784L11.9852 2.5ZM6 14.8354H12.6673V13.5025H6V14.8354Z" fill="#F58025"/>
</svg>
    
);

export default StackOverflow;
