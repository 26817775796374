import React from 'react';
interface Dribbbleprops{
    size?:number
}

const Dribbble = (props:Dribbbleprops) => (
  <svg width={props.size?props.size:"30"} height={props.size?props.size:"30"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
   <path d="M20 17.2222C20 17.9589 19.7073 18.6655 19.1864 19.1864C18.6655 19.7073 17.9589 20 17.2222 20H2.77778C2.04107 20 1.33453 19.7073 0.813592 19.1864C0.292658 18.6655 0 17.9589 0 17.2222V2.77778C0 2.04107 0.292658 1.33453 0.813592 0.813592C1.33453 0.292658 2.04107 0 2.77778 0H17.2222C17.9589 0 18.6655 0.292658 19.1864 0.813592C19.7073 1.33453 20 2.04107 20 2.77778V17.2222Z" fill="#FF4081"/>
<path d="M15.0338 11.2593C14.9396 11.2302 14.8407 11.2201 14.7426 11.2293C14.6445 11.2386 14.5492 11.2672 14.4622 11.3134C14.3751 11.3596 14.2981 11.4225 14.2354 11.4985C14.1728 11.5746 14.1258 11.6623 14.0971 11.7565C13.6393 13.2332 12.8104 14.7671 12.4343 14.851C12.1527 14.851 11.7477 14.6121 11.3466 14.051C12.2766 12.0543 12.7638 9.54764 12.7638 7.87431C12.7638 3.20042 11.5838 2.21875 10.5927 2.21875C8.50545 2.21875 8.48156 7.72542 8.48156 7.78097C8.48156 8.42875 8.50489 9.02986 8.54878 9.58986C8.23394 9.47356 7.90108 9.41357 7.56545 9.41264C5.421 9.41264 4.44434 11.5288 4.44434 13.4943C4.44434 15.3465 5.48378 17.2188 7.80322 17.2188C8.89878 17.2188 9.82211 16.5015 10.5671 15.4293C11.2271 16.1154 11.9182 16.3715 12.4338 16.3715C14.0577 16.3715 15.1327 13.4643 15.5204 12.2126C15.5804 12.0217 15.563 11.8148 15.472 11.6365C15.381 11.4582 15.2236 11.3228 15.0338 11.2593ZM7.80434 15.6988C6.12045 15.6988 5.93767 14.1571 5.93767 13.4938C5.93767 13.3893 5.95656 10.9321 7.56656 10.9321C8.20211 10.9321 8.68989 11.4238 8.68989 11.4238C8.726 11.4626 8.766 11.4965 8.80767 11.526C9.016 12.5838 9.30989 13.4293 9.64989 14.0993C9.09211 15.0649 8.446 15.6988 7.80434 15.6988ZM10.5004 12.1776C10.1899 11.1054 9.97489 9.66597 9.97489 7.78097C9.97489 6.04431 10.3449 4.40986 10.6521 3.85153C10.8877 4.20931 11.2716 5.32375 11.2716 7.87431C11.2716 9.22764 10.9721 10.7999 10.5004 12.1776Z" fill="white"/>
</svg>
);

export default Dribbble;