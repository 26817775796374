import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import AddIcon from "../../../../assets/Add";
import TickIcon from "../../../../assets/Tick";
import { GetOpening, KeyWithArray } from "../../../../type/type"
import Suggested from "./SuggestedGuest";
import TacitbaseAvtar from "../../../../ant/Avtar"
import { generateTooltipForMember } from "../../../../utils/capitalizeFirstLetter"
type cardMemberProps = {
  currentApplicant: any;
  currentOpening: GetOpening;
  arr: (e: any) => void;
  members: any;
  applicantMembers: KeyWithArray;
  applicationId: string;
  guest: string[];
  attendees: any;
  cardID?: string;
  deletedAttendees?: string[];
  type: string;
  setMeetingInput: (e: any) => void;
  setValidationState?: (value: any) => void;
  validations: any;
};
const QuickAdd = (props: cardMemberProps) => {
  const [showPopup, setShowPopup] = useState(false);
  const popupRef = useRef<HTMLDivElement | null>(null);
  const searchRef = useRef<HTMLInputElement | null>(null);
  const [validEmail, setValidEmail] = useState(true);
  const [searchedMember, getSearchedMember] = useState("");
  let orgMembers: string[] = [];
  // if user tries to search for a member
  searchedMember
    ? ((orgMembers = Object.keys(props.members).filter(
        (member: string) => props.members[member].registrations[0].data.activated
      )),
      (orgMembers = orgMembers.filter((member: string) => {
        return (
          props.members[member].first_name
            .toUpperCase()
            .includes(searchedMember.toUpperCase()) ||
          props.members[member].last_name
            .toUpperCase()
            .includes(searchedMember.toUpperCase()) ||
          props.members[member].email
            .toUpperCase()
            .includes(searchedMember.toUpperCase()) ||
          props.members[member].username
            .toUpperCase()
            .includes(searchedMember.toUpperCase())
        );
      })))
    : (orgMembers = []);
  useEffect(() => {
    var emailReg =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;
    searchedMember
      ? orgMembers
        ? orgMembers.length == 0
          ? 
            emailReg.test(searchedMember)
              ? setValidEmail(true)
            : setValidEmail(false)
          : null
        : null
      : null;
  }, [searchedMember]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);
  const handleClick = (e: any) => {
    if (popupRef.current && !popupRef.current.contains(e.target)) {
      setShowPopup(false);
      return;
    }
  };
  //  adding all suggested members as guest to meeting
  const addAll = () => {
    {
      props.applicantMembers[props.currentApplicant.id].length > 0
        ? props.applicantMembers[props.currentApplicant.id].map(
            (member: string) =>
              props.members[member] && props.members[member].registrations[0].data.activated
                ? props.attendees.some(
                    (obj: any) => obj.email === props.members[member].email
                  )
                  ? null
                  : props.setMeetingInput((obj: any) => ({
                      ...obj,
                      attendees: [
                        ...obj.attendees,
                        { email: props.members[member].email },
                      ],
                    }))
                : null
          )
        : null;

      setShowPopup(false),
        getSearchedMember(""),
        searchRef.current ? (searchRef.current.value = "") : null;
    }
  };
  // adding a specific member as guest to meeting
  const addThisGuest = (member: any) => {
    setShowPopup(false);
    searchRef.current ? (searchRef.current.value = "") : null;
    getSearchedMember("");
    const deletedAttendees = props.deletedAttendees?.filter(
      (obj: any) => obj.email != props.members[member.id].email
    );
    props.setMeetingInput((obj: any) => ({
      ...obj,
      deleted_attendees: deletedAttendees,
    }));

    const emailExists = props.attendees.some(
      (obj: any) => obj.email === props.members[member.id].email
    );
    if (!emailExists) {
      // Add new email object to emailObjects
      props.setMeetingInput((obj: any) => ({
        ...obj,
        attendees: [
          ...obj.attendees,
          { email: props.members[member.id].email },
        ],
      }));
    }
  };
  const addThisNonMemberGuest = (member: string) => {
    setShowPopup(false);
    searchRef.current ? (searchRef.current.value = "") : null;
    getSearchedMember("");
    const deletedAttendees = props.deletedAttendees?.filter(
      (obj: any) => obj.email != member
    );
    props.setMeetingInput((obj: any) => ({
      ...obj,
      deleted_attendees: deletedAttendees,
    }));
    const emailExists = props.attendees.some(
      (obj: any) => obj.email === member
    );
    if (!emailExists) {
      // Add new email object to emailObjects
      props.setMeetingInput((obj: any) => ({
        ...obj,
        attendees: [...obj.attendees, { email: member }],
      }));
    }
  };

  useEffect(() => {
    props.type == "create"
      ? props.currentApplicant.candidate_id
        ? addThisNonMemberGuest(props.currentApplicant.source.email)
        : null
      : null;
  }, []);

  return (
    <>
      <div className="flex flex-col space-y-2 w-7/12">
        <div className="input-label">Quick Add Guests *</div>{" "}
        <div className="relative">
          <input
            type="text"
            className={ "input-box"
            }
            placeholder="Search..."
            id="showInput"
            ref={searchRef}
            data-testid="guestInput"
            onChange={(e: any) => e && getSearchedMember(e.target.value)}
            onClick={() => setShowPopup(!showPopup)}
          />{" "}
          {showPopup ? (
            <div
              className="popupDiv absolute  p-2 bg-white w-full top-9 rounded shadow-2xl z-10 "
              ref={popupRef}
            >
              {searchedMember ? (
                <div className="flex flex-col space-y-1">
                  {orgMembers ? (
                    orgMembers.length == 0 ? (
                      <div className="p-1 flex items-center justify-between bg-gray-300 bg-opacity-25 hover:bg-gray-300 hover:bg-opacity-40 rounded ">
                        {validEmail ? (
                          <div
data-testid="addThisNonMemberGuestBtn"
                            className="flex flex-row items-center space-x-2 justify-between w-full cursor-pointer"
                            onClick={() =>
                              addThisNonMemberGuest(searchedMember)
                            }
                            aria-hidden
                          >
                            <div className="flex space-x-2 items-center">
                              {" "}
                              <div className="flex flex-col">
                                <TacitbaseAvtar
                                  // toolTip={generateTooltipForMember(props.members[member])}
                                  // src={props.members[member].image_url}
                                  size="default"
                                />
                              </div>
                              <div className="flex flex-col">
                                <div className="leading-snug font-bold text-sm text-gray-800 tracking-normal line-clamp-1">
                                  {searchedMember}
                                </div>
                              </div>{" "}
                            </div>

                            {!props.guest.includes(searchedMember) ? (
                              <AddIcon size="13px" />
                            ) : (
                              <TickIcon size="13px" />
                            )}
                          </div>
                        ) : (
                          <div className="text-red-600">Enter valid email</div>
                        )}
                      </div>
                    ) : (<div className=" min-h-0 max-h-40 md:max-h-44 lg:max-h-52  xl:max-h-70 2xl:max-h-80 overflow-y-scroll flex flex-col space-y-1">
                     { orgMembers.map((member: string, i: number) =>
                        props.members[member].registrations[0].data.activated ? (
                          <div
                            key={i}
                            className="p-1.5 flex items-center justify-between bg-gray-300 bg-opacity-25 hover:bg-gray-300 hover:bg-opacity-40 rounded cursor-pointer "
                            onClick={() => addThisGuest(props.members[member])}
                            aria-hidden
                          >
                            <div className="flex flex-row items-center space-x-2 justify-between w-full">
                              <div className="flex space-x-2 items-center">
                                {" "}
                                <div className="flex flex-col">
                                  {props.members[member].image_url ? (
                                   <TacitbaseAvtar
                                     toolTip={generateTooltipForMember(props.members[member])}
                                     src={props.members[member].image_url}
                                     size="default"
                                   />
                                  ) : null}
                                </div>
                                <div className="flex flex-col">
                                  <div className="leading-snug font-bold text-sm text-gray-800 tracking-normal line-clamp-1">
                                    {props.members[member].username}
                                  </div>
                                  <div className="text-xs leading-snug tracking-wide text-gray-800">
                                    {props.members[member].email}
                                  </div>
                                </div>{" "}
                              </div>

                              {!props.guest.includes(
                                props.members[member].id
                              ) ? (
                                <AddIcon size="13px" />
                              ) : (
                                <TickIcon size="13px" />
                              )}
                            </div>
                          </div>
                        ) : null
                      )}</div>
                    )
                              
                  ) : null}
                </div>
              ) : (
                <Suggested
                  orgMembers={props.members}
                  applicantMembers={props.applicantMembers}
                  currentApplicant={
                    props.type == "create"
                      ? props.currentApplicant.id
                      : props.cardID
                  }
                  guest={props.guest}
                  addThisGuest={(m) => addThisGuest(m)}
                  addAll={() => addAll()}
                />
              )}
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,

  currentApplicant: state.opening.currentApplicant,
  members: state.opening.members,
  applicantMembers: state.opening.applicantMembers,
  applicationId: state.opening.applicationId,
});

export default connect(mapStateToProps)(QuickAdd);
