import React from "react"
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter"
import { Space } from "antd"
import { BACK_TO_LOGIN, RESET_PWD_LINK_ADDITIONAL_HEADING, RESET_PWD_LINK_HEADING } from "../../constant"
import {Typography} from 'antd'
const {Paragraph,Link} = Typography


const ExpireLinkMsg = () => {
    return (
        <>

            <div className="w-full flex items-center justify-center font-semibold md:text-lg text-base">
                {capitalizeFirstLetter("Password reset")}
            </div>
            <div className="text-sm my-4 font-light w-full tracking-wide  ">
                <Space direction="vertical">
                    <Paragraph>{RESET_PWD_LINK_HEADING}</Paragraph>
                    <Paragraph>
                        To regain access to your account, get a new link by trying{" "}
                        <Link href="/password/change">
                            {capitalizeFirstLetter("Forget password")}
                        </Link>{" "}
                        again.
                    </Paragraph>

                    <Paragraph>
                        {RESET_PWD_LINK_ADDITIONAL_HEADING}
                    </Paragraph>
                    <Link href={`/login`}>{BACK_TO_LOGIN} </Link>
                </Space>
            </div>{" "}

        </>
    )
}
export default ExpireLinkMsg
