import React from "react";
import { ChangeBoardBackgroundProps } from "../../../type/sidePanalSlider";
import PopupTitle from "../../common/popupTitle/PopupTitle";
import { COLORS, PHOTOS } from "../../../constant"
const ChangeBoardBackground = (props: ChangeBoardBackgroundProps) => {
  const baordBackgroundSections = [
    {
      url:
        "https://images.unsplash.com/photo-1591178761188-885caa0b4fc3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1058&q=80",
      caption: PHOTOS,
      component: "ImgPalette"
    },
    {
      url:
        "https://images.unsplash.com/photo-1525909002-1b05e0c869d8?crop=entropy\u0026cs=tinysrgb\u0026fit=max\u0026fm=jpg\u0026ixid=M3w0NTg4NTh8MHwxfHNlYXJjaHwyfHxjb2xvcnxlbnwwfHx8fDE2ODY2NDM4OTJ8MA\u0026ixlib=rb-4.0.3\u0026q=80\u0026w=200",
      caption: COLORS,
      component: "ColorPalette"

    },
  ];


  return (
    <React.Fragment>
      <div className="">
        <PopupTitle
          PopupTitle="Change background"
          font="text-base font-medium text-black"
          back={props.prev}
          close={props.close}
        />
      </div>
      <div className="flex cursor-pointer p-1">
        {baordBackgroundSections.map((section) => (
          <>
            <div
              className="w-1/2 hover:opacity-70  p-1"
              role="button"
              tabIndex={0}
              data-testid="testDiv"
              onKeyDown={() => { }}
              onClick={() => props.click(section.component)}
            >
              <div
                className={`h-24 mb-1 rounded w-full bg-cover bg-center bg-no-repeat`}
                style={{ backgroundImage: `url(${section.url})` }}
              >
              </div >
              <div className="text-sm justify-center items-center flex ">
                {section.caption}
              </div>
            </div>

          </>
        ))}
      </div>
    </React.Fragment>
  );
};
export default ChangeBoardBackground;
