import React from "react";
import { connect } from "react-redux";
import { loginUser, Opening } from "../../type/type";
import TacitabseLogoWithName from "../../images/Tacitbase cdr logo.png";
import TacitbaseLogoWithoutName from "../../images/tacitbase_logo_300px.png";
import TBLogoWhiteWithLabel from "../../images/TBLogoWhiteWithLabel.png"
import Tacitbase_WT_7 from "../../images/Tacitbase_WT_7.png"
const UserProfileMenu = (props: {
  loginUser: loginUser;
  currentOpening: Opening;
  isCollaps: boolean;
}) => {
  return (
    <div
      style={  props.currentOpening.color
        ?{
          borderBottom: "1px solid rgba(255, 255, 255, 0.25)" ,
        }:
        {
      
        borderBottomWidth: "1px",
        borderBottomStyle: "solid",
        borderRightStyle:'solid',
        borderRightWidth:'1px'
      }}
    >
      <div
        style={{
          padding: "0px 10px 0px 16px",
          paddingLeft: "24px",
          paddingRight: "0px",
          height: "48px",
        }}
        key="userProfile"
        className="w-full flex items-center "
      >
        {props.isCollaps && !props.currentOpening.color && (
            <img
              src={TacitbaseLogoWithoutName}
              alt=""
              width={40}
              className="object-fill"
            />
        )}
        {props.isCollaps && props.currentOpening.color && (
            <img
              src={Tacitbase_WT_7}
              alt=""
              width={28}
              className="object-fill"
            />
        )}
        {!props.isCollaps && !props.currentOpening.color && (
          <span>
              <img
                src={TacitabseLogoWithName}
                alt=""
                width="130px"
                className="object-cover"
              />
          </span>
        )}

        {!props.isCollaps && props.currentOpening.color && (
          <span>
              <img
                src={TBLogoWhiteWithLabel}
                alt=""
                width="130px"
                className="object-cover"
              />
          </span>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  loginUser: state.opening.loginUser,
  currentOpening: state.opening.currentOpening,
});

export default connect(mapStateToProps)(UserProfileMenu);
