import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  // Collapse,
  // ConfigProvider,
  Divider,
  Flex,
  Input,
  List,
  Space,
  Tabs,
  Tag,
  Tooltip,
  Typography,
} from "antd";
// import parse from "html-react-parser";
import { Candidate, InitLabel,  Jd,   ResumeAnalysis,  TableColumn } from "../../../../../type/type";
import { capitalizeFirstLetter, formatLink } from "../../../../../utils/capitalizeFirstLetter";
import {
  CheckOutlined,
  CopyOutlined,
  ExportOutlined,
  LockOutlined,
  MailOutlined,
  PhoneOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { determineTextColor } from "../../../../../utils/CalculateBrightnessOfColor";
import CandidateInPoolAttachments from "./CandidateInPoolAttachments";
import { CurrentCandidateInPool } from "../../../../../redux/reducers/initialState";
import { editCandidate } from "../../../../../lib/api";
import { throwSuccess } from "../../../../../service/throwError";
import { validateInput } from "../../../../../utils/Validations";
import Link from "antd/es/typography/Link";
import { BETA_PRODUCT, customFormats, customModules,  PAID_RELEVANCY_FEATURES,  predefinedFields, regexPatterns } from "../../../../../constant";
import { SocialLinks } from "../../../../CDB/CandidateInCDBDetails";
import Editor from "../../../../../ant/Editor/Editor";
import CandidateDetailAiScore from "../../../../resumeRanking/CandidateDetailAiScore";
import { InitCurrentCandidateInAtsPool } from "../../../../../redux/actions/opening/action";
import { Dispatch } from "redux";
import GPT from "../../../../../assets/GPT";
import { newOrganisation } from "../../../../../lib/apiRes";
import { useOrgMetadata } from "../../../../../pages/setting/billingSetting/OrgMetadata";
const { Title, Text } = Typography;

type Props = {
  currentCandidateInPool: CurrentCandidateInPool;
  candidateLabels: InitLabel;
  sources:{[key:string]:{name:string,profile:string}},
  candidateDBTableHeader: TableColumn[];
  currentTrackerJd:Jd;
  InitCurrentCandidateInDB: (candidate: Candidate) => void;
  currentOrg:newOrganisation;
};

const CandidateInPoolDetails = (props: Props) => {
  const [candidate, setCandidate] = useState<any>(
    props.currentCandidateInPool.candidate
  );
  const [editingKey, setEditingKey] = useState<string | null>(null);
  const [candidateAiData, setCandidateAiData] = useState<ResumeAnalysis|null>()
  const orgMetadata = useOrgMetadata(props.currentOrg)
  const [initialCandidate, setInitialCandidate] = useState<Candidate | null>(
    null
  );
  const [saveStatus, setSaveStatus] = useState<string>(""); // Save status state
  const [errors, setErrors] = useState<{ [key: string]: string }>({}); // Error state
  const [modifiedValues, setModifiedValues] = useState<{ [key: string]: any }>(
    {}
  );

  const [editorContent, setEditorContent] = useState<string>("");
  useEffect(()=>{
setEditorContent(candidate.description)
  },[candidate.description])

  // Update candidate data and initial candidate data when props change

  useEffect(() => {
    setCandidate(props.currentCandidateInPool.candidate);
    setInitialCandidate(props.currentCandidateInPool.candidate);
    if(props.currentCandidateInPool.candidate.resume_analysis){
      const candidateData = props.currentCandidateInPool.candidate.resume_analysis.filter((candidate:ResumeAnalysis)=>candidate.jd_id === props.currentTrackerJd.id)
       setCandidateAiData(candidateData[0])
     }else{
      setCandidateAiData(null);
     }
  }, [props.currentCandidateInPool]);

  // Handle the click to edit a field
  const handleEditClick = (key: string) => {
    setEditingKey(key);
  };


  // Validate input fields
  const validateInputValue = (
    key: string,
    value: string,
    view: string
  ): string => {
    const pattern = regexPatterns[key];

    if (pattern && !pattern.test(value)) {
      if (key == "email") return `The  ${key.replace(/_/g, " ")} is invalid.`;
      else return `The  ${view.replace(/_/g, " ")} URL is invalid.`;
    }
    return "";
  };

  // Handle input changes for both predefined and custom fields
  const handleInputChange = (
    key: string,
    event: React.ChangeEvent<HTMLInputElement>,
    isCustom: boolean,
    view: string,
    type:string
  ) => {
if(isCustom)  { 
      setCandidate((prevCandidate: any) => ({
        ...prevCandidate,
        customs: {
          ...prevCandidate?.customs,
          [view]:
          type == "number"
            ? parseInt(event.target.value)
            : event.target.value,
        },
      }));
      setModifiedValues((prevCandidate: any) => ({
        ...prevCandidate,
        customs: {
          ...prevCandidate?.customs,
          [key]:
          type == "number"
            ? parseInt(event.target.value)
            : event.target.value,
        },
      }));
    }
else{   setCandidate((prevCandidate: any) => ({
        ...prevCandidate,
        [key]:
          key == "notice_period_in_days"
            ? parseInt(event.target.value)
            : event.target.value,
      }));
    }
    const value = event.target.value;

    if (value.trim().length > 0) {

      const errorMessage = validateInputValue(key, value, view);
      // Track modified values
      if (errorMessage) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [key]: errorMessage,
        }));
      } else {
        setModifiedValues((prevModifiedValues) => ({
          ...prevModifiedValues,
          [key]: type == "number" ? parseInt(value) : value,
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          [key]: "",
        }));
      }
    } else {
      setModifiedValues((prevModifiedValues) => ({
        ...prevModifiedValues,
        [key]: type == "number" ? parseInt(value) : value,
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        [key]: "",
      }));
    }
  };

  const handleInputBlur = () => {

    handleEditClick(""); // Close editing mode
    const hasErrors = Object.values(errors).some((error) => error !== "");

    if (initialCandidate && !hasErrors) {
      // Check if there are any modified values
      if (Object.keys(modifiedValues).length === 0) {

        return; // No changes, exit early
      }
      // Construct the candidate request object
      let candidateReq = {
        ...modifiedValues,
        description: editorContent,
        id: props.currentCandidateInPool.candidate.id,
      };
      // Check for errors before making API call
      setSaveStatus("saving"); // Show saving status

      editCandidate(candidateReq)
        .then((res) => {
          setCandidate(res);
          props.InitCurrentCandidateInDB(res)
          setTimeout(() => {
            setSaveStatus("saved"); // Show saved status
            throwSuccess("Candidate updated successfully.");
          }, 1000);
          setTimeout(() => setSaveStatus(""), 2000); // Reset save status
          setModifiedValues({}); // Clear modified values

          setInitialCandidate(candidate); // Update initialCandidate to current candidate after successful save
        })
        .catch(() => {
          setSaveStatus(""); // Reset save status
          setCandidate(initialCandidate); // Reset candidate to initial state
        });
    } else {
      // Reset value and error state of fields with errors

      Object.keys(errors).forEach((key) => {

        if (errors[key]) {
          setCandidate((prevCandidate: any) => ({
            ...prevCandidate,
            [key]: "", // Reset value of the field
          }));
          setErrors((prevErrors) => ({
            ...prevErrors,
            [key]: "", // Reset error of the field
          }));
        }
      });
    }
  };


const CandidatesCustomFields :TableColumn[]=props.candidateDBTableHeader.filter((table:TableColumn)=>table.custom)
  // Define the structure for predefined fields

  const CandidatesdetailedFields=[ { name: "skills", type: "paragraph", view: "Skills",custom:false  },
    { name: "location", type: "paragraph", view: "Location" },{ name: "highest_education", type: "paragraph", view: "Highest education",custom:false  },
    { name: "latest_experience", type: "paragraph", view: "Latest experience",custom:false  },
    {
      name: "notice_period_in_days",
      type: "number",
      view: "Notice period (Days)",
      custom:false 
    },]
  

  // Handle Enter key press to save changes
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" || event.code==="Enter") {
      handleInputBlur();
    }
  };
  const social_links=predefinedFields.map(link=>({
    ...link,
    // icon:SOCIAL_LINKS_ICON[link.name],
    iconLink:candidate[link.name]
  }))
  const handleKeyDownEditedComment = (
    e: React.KeyboardEvent<HTMLDivElement>
) => {
    if (e.keyCode === 13 && e.metaKey) {
        // Save the comment when Command + Enter is pressed
                 let candidateReq = {
          ...modifiedValues,
          description:editorContent,
          id: props.currentCandidateInPool.candidate.id,
        };
        // Check for errors before making API call
        setSaveStatus("saving"); // Show saving status
  
        editCandidate(candidateReq)
          .then((res) => {
            // props.InitCurrentCandidateInDB(res);
            setCandidate(res);
            setTimeout(() => {
              setSaveStatus("saved"); // Show saved status
              throwSuccess("Candidate updated successfully.");
            }, 1000);
            setTimeout(() => setSaveStatus(""), 2000); // Reset save status
            setModifiedValues({}); // Clear modified values
  
            setInitialCandidate(candidate); // Update initialCandidate to current candidate after successful save
          })
          .catch(() => {
            setSaveStatus(""); // Reset save status
            setCandidate(initialCandidate); // Reset candidate to initial state
          });
      }
}
  


  // Render editable field based on the current editing key
  const renderEditableField = (field: any) => {
    return (
      <>
        {editingKey === field.name ? (
          <div>
            <Input
              value={candidate[field.name]}
              type={field.type}
              onChange={(event) =>
                handleInputChange(field.name, event,false, field.view,field.type)
              }
              onBlur={handleInputBlur}
              onKeyDown={handleKeyDown}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
            />
            {errors[field.name] && (
              <Text type="danger" style={{ fontSize: "12px" }}>
                {errors[field.name]}
              </Text>
            )}
          </div>
        ) : (
          <div
            onKeyDown={() => {}}
            role="button"
            tabIndex={0}
            onClick={() => handleEditClick(field.name)}
            style={{}}
            className="truncate relative"
          >
            <div
              style={{
                boxSizing: "border-box",
                margin: 0,
                padding: "5px 12px",
                fontSize: "14px",
                lineHeight: "1.5714285714285714",
                fontFamily: "Inter",
                display: "inline-block",
                width: "100%",
                minWidth: 0,
                borderRadius: "4px",
                height: 32,
                transition: "all 0.2s",
              }}
            >
              {" "}
              {candidate[field.name] || "Type here"}
            </div>

          </div>
        )}
      </>
    );
  };

  const renderEditableCustomField = (field: any) => {    
    return (
      <>
        {editingKey === field.view ? (
          <div>
            <Input
              value={candidate.customs[field.view]&&candidate.customs[field.view]}
              type={field.type}
              onChange={(event) =>
                handleInputChange(field.name, event, true, field.view,field.type)
              }
              onBlur={handleInputBlur}
              onKeyDown={handleKeyDown}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
            />
            {errors[field.name] && (
              <Text type="danger" style={{ fontSize: "12px" }}>
                {errors[field.name]}
              </Text>
            )}
          </div>
        ) : (
          <div
            onKeyDown={() => {}}
            role="button"
            tabIndex={0}
            onClick={() => handleEditClick(field.view)}
            style={{}}
            className="truncate relative"
          >
            <div
              style={{
                boxSizing: "border-box",
                margin: 0,
                padding: "5px 12px",
                fontSize: "14px",
                lineHeight: "1.5714285714285714",
                fontFamily: "Inter",
                display: "inline-block",
                width: "100%",
                minWidth: 0,
                borderRadius: "4px",
                height: 32,
                transition: "all 0.2s",
              }}
            >
              {" "}
              {candidate.customs[field.view]&& candidate.customs[field.view] || "Type here"}
            </div>

            {field.type === "url" && candidate[field.name] && (
              <span
                tabIndex={0}
                role="button"
                onKeyDown={() => {}}
                className=" absolute right-0 top-1 bg-white pl-4"
              >
                <Link href={candidate[field.name]} target="_blank">
                  <ExportOutlined />
                </Link>
              </span>
            )}
          </div>
        )}
      </>
    );
  };


  const onEditorChange = (content: string) => {
    setEditorContent(content)
  };

  const items = [
    {
      key: "1",
      label: "Candidate Details",
      children: (
        <div style={{ height: "70%", overflow: "auto" }}>
          <List
            itemLayout="horizontal"
            dataSource={CandidatesdetailedFields}
            renderItem={(item) => (
              <List.Item style={{ padding: "8px 0px 0px 0px" }}>
                <List.Item.Meta
                  title={item.view && capitalizeFirstLetter(item.view)}
                  description={renderEditableField(item)}
                />
              </List.Item>
            )}
          />
        </div>
      ),
    },
    {
      key: "2",
      label: "Description",
      children: (
        <><div
            className="editor-description border rounded mb-2"
            style={{
              height: "calc(-200px + 67vh)",
            }}
          >
            <Editor
              initialValue={editorContent}
              defaultValue={editorContent}
              value={editorContent}
              onChange={(content: string) => onEditorChange(content)}
              modules={customModules}
              formats={customFormats}
              placeholder="Type your description here..."
              onBlur={handleInputBlur}
            onKeyDown={handleKeyDownEditedComment} />
          </div>
          {/* <div className="font-normal" style={{color:'grey',backgroundColor:'white'}}>Please press command + Enter to save or update the description.</div> */}
        <Text type="secondary" className="text-left" style={{ fontSize: "14px" }}>
            <sup style={{ color: "red" }}>*</sup>
                Please press command (ctrl) + Enter to save or update the description.
          </Text>
        </>
      ),
    },
    {
      key: "3",
      label: "Social profiles",
      children: (
        <List
          itemLayout="horizontal"
          dataSource={predefinedFields}
          style={{ overflowY: "scroll" }}
          renderItem={(item) => (
            <List.Item style={{ padding: "8px 0px 0px 0px" }}>
              <List.Item.Meta
                // title={item.view && capitalizeFirstLetter(item.view)}
                title={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {item.view && capitalizeFirstLetter(item.view)}
                    {item.type === "url" && candidate[item.name] && (
                      <span
                        tabIndex={0}
                        role="button"
                        onKeyDown={() => {}}
                        style={{ marginLeft: "8px", cursor: "pointer" }}
                      >
                        <Link
                          href={formatLink(candidate[item.name])}
                          target="_blank"
                        >
                          <ExportOutlined />
                        </Link>
                      </span>
                    )}
                  </div>
                }
                description={renderEditableField(item)}
              />
            </List.Item>
          )}
        />
      ),
    },
    {
      key: "4",
      label: "Custom fields",
      children: (
        <List
          itemLayout="horizontal"
          dataSource={CandidatesCustomFields}
          renderItem={(item) => (
            <List.Item style={{ padding: "8px 0px 0px 0px" }}>
              <List.Item.Meta
                title={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {item.view && capitalizeFirstLetter(item.view)}
                    {item.type === "url" && candidate[item.name] && (
                      <span
                        tabIndex={0}
                        role="button"
                        onKeyDown={() => {}}
                        style={{ marginLeft: "8px", cursor: "pointer" }}
                      >
                        <Link
                          href={formatLink(candidate[item.name])}
                          target="_blank"
                        >
                          <ExportOutlined />
                        </Link>
                      </span>
                    )}
                  </div>
                }
                description={renderEditableCustomField(item)}
              />
            </List.Item>
          )}
        />
      ),
    },
    ...(props.currentTrackerJd?.resume_ranking_settings !== null
      ? [
          {
            key: "5",
            label: (
              <div className="flex items-center gap-2">
                {orgMetadata?.ai_feature !== "true" ? (
                  <div>
                    <Tooltip title={PAID_RELEVANCY_FEATURES}>
                      <LockOutlined />
                    </Tooltip>
                  </div>
                ) : (
                  <GPT fill="black" />
                )}
                <div>Resume relevancy</div>
                <Tooltip title = {BETA_PRODUCT}>
                  <Tag bordered={false} color="gold">
                    Beta
                  </Tag>
                </Tooltip>
              </div>
            ),
            children: (
              <div style={{ height: "70%", overflow: "auto" }}>
                  <CandidateDetailAiScore candidateAiData={candidateAiData} />
              </div>
            ),
          },
        ]
      : []),
  ];

  
  return (
    <>
      {saveStatus && (
        <div
          style={{
            position: "fixed",
            top: 56,
            left: 200,
            width: "100%",
            padding: "10px",

            color: "black",
            textAlign: "left",
            zIndex: 1000,
          }}
        >
          {saveStatus === "saving" ? (
            <span className=" w-full font-bold" style={{ color: "#009D79"}}>
              <SyncOutlined
                spin={true}
                style={{ color: "#009D79", marginRight: 4 ,}}
              />{" "}
              Saving changes...
            </span>
          ) : (
            <span style={{ color: "#009D79" }} className="font-bold ">
              <CheckOutlined
                size={100}
                style={{ color: "#009D79", marginRight: 4 }}
                type="success"
              />
              Changes saved
            </span>
          )}
        </div>
      )}
      <div className="h-full mb-4 w-full" >
        <div className="border-b flex flex-row justify-between w-full pl-2" >
        <Title
          level={4}
          className="bg-white z-10 flex items-center mb-0 px-4 pt-2 text-xl h-10 "
          style={{width:'calc(100% - 194px)'}}
        >
          <div className="w-full flex space-x-2">
            <div className=" w-max truncate" style={{ maxWidth: "50%" }}>
              {editingKey === "first_name" ? (
                <>
                  <Input
                    value={candidate?.first_name}
                    onChange={(event) =>
                      handleInputChange("first_name", event, false, "","string")
                    }
                    onBlur={handleInputBlur}
                    onKeyDown={handleKeyDown}
                    style={{ width: "max-content", maxWidth: "100%" }}
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus
                  />

                  <Text type="danger" style={{ fontSize: "12px" }}>
                    {validateInput(candidate?.first_name, "first name")}
                  </Text>
                </>
              ) : (
                <span
                  role="button"
                  tabIndex={0}
                  onKeyDown={() => {}}
                  className="pt-1 cursor-pointer truncate w-full"
                  onClick={() => handleEditClick("first_name")}
                >
                  {candidate?.first_name && capitalizeFirstLetter(candidate?.first_name)}
                </span>
              )}
            </div>
            <div className=" w-max truncate" style={{ maxWidth: "50%" }}>
              {editingKey === "last_name" ? (
                <Input
                  value={candidate?.last_name}
                  onChange={(event) =>
                    handleInputChange("last_name", event, false, "","")
                  }
                  onBlur={handleInputBlur}
                  onKeyDown={handleKeyDown}
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus
                />
              ) : (
                <span
                  role="button"
                  tabIndex={0}
                  onKeyDown={() => {}}
                  className="pt-1 cursor-pointer truncate"
                  onClick={() => handleEditClick("last_name")}
                >
                  {candidate?.last_name}
                </span>
              )}
            </div>
          </div>
        </Title>
        <div className="flex flex-row items-center mr-2">
          <SocialLinks links={social_links} />


          </div>
        </div>

        <div
          className="h-full pl-6 pb-2 pt-2 overflow-y-scroll "
          style={{
            height: "calc(100vh - 236px )",
            // margin: 2,
          }}
        >
          <div className="h-auto  max-h-1/2 flex flex-col items-start overflow-y-scroll ">
          <Space direction="vertical" className="w-full">
                <Flex style={{ width: "100%" }} className="justify-between">
                  <div
                    style={{ width: "49%" }}
                    className="space-x-1 flex items-center w-full "
                  >
                    <div>
                      <MailOutlined />
                    </div>

                    {editingKey === "email" ? (
                      <div className="w-full">
                        <Input
                          className="ml-1"
                          value={candidate?.email}
                          onChange={(event) =>
                            handleInputChange("email", event, false, "email","")
                          }
                          onBlur={handleInputBlur}
                          onKeyDown={handleKeyDown}
                          // eslint-disable-next-line jsx-a11y/no-autofocus
                          autoFocus
                        />
                        {errors["email"] && (
                          <Text type="danger" style={{ fontSize: "12px" }}>
                            {errors["email"]}
                          </Text>
                        )}
                      </div>
                    ) : (
                      <div className="relative" style={{ width: "90%" }}>
                        <Text
                          style={{
                            boxSizing: "border-box",
                            margin: 0,
                            padding: "5px 12px",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            fontSize: "14px",
                            lineHeight: "1.5714285714285714",
                            fontFamily: "Inter",
                            display: "inline-block",
                            width: "99%",
                            minWidth: 0,
                            borderRadius: "4px",
                            height: 26,
                            transition: "all 0.2s",
                          }}
                          copyable={{
                            text: "",
                            icon: (
                              <span className="absolute z-10 -right-2 bottom-1.5">
                                <CopyOutlined />
                              </span>
                            ),
                            tooltips: false,
                          }}
                          role="button"
                          tabIndex={0}
                          onClick={() => handleEditClick("email")}
                        >
                          {candidate?.email || "Type here"}
                        </Text>
                      </div>
                    )}
                  </div>
                  <div
                    style={{ width: "49%" ,marginLeft:'7%'}}
                    className="flex items-center"
                  >
                    <div>
                      <PhoneOutlined />
                    </div>
                    {editingKey === "phone" ? (
                      <div>
                        <Input
                          className="ml-1"
                          value={candidate?.phone}
                          onChange={(event) =>
                            handleInputChange("phone", event, false, "","")
                          }
                          onBlur={handleInputBlur}
                          onKeyDown={handleKeyDown}
                          // eslint-disable-next-line jsx-a11y/no-autofocus
                          autoFocus
                        />                       
                      </div>
                    ) : (
                      <div className="truncate">
                        <Text
                          style={{
                            boxSizing: "border-box",
                            margin: 0,
                            padding: "4px 16px",
                            fontSize: "14px",
                            lineHeight: "1.5714285714285714",
                            fontFamily: "Inter",
                            display: "inline-block",
                            width: "100%",
                            minWidth: 0,
                            borderRadius: "4px",
                            height: 30,
                            transition: "all 0.2s",
                          }}
                          ellipsis={{ tooltip: candidate?.phone }}
                          role="button"
                          tabIndex={0}
                          onClick={() => handleEditClick("phone")}
                        >
                          {candidate?.phone || "Type here"}
                        </Text>
                      </div>
                    )}
                  </div>
                </Flex>
          
              </Space>
             {props?.currentCandidateInPool?.labels.length > 0 && (<>
            <Space direction="vertical" className="my-2">
            <Text  strong>Labels </Text>
            
              <Flex gap="4px 0" wrap="wrap" className=" h-full">
                {props?.currentCandidateInPool?.labels?.length > 0&& props.candidateLabels &&
                 props?.currentCandidateInPool?.labels?.map((label: any) => {
                    const candidateLabel = props.candidateLabels?.[label];
                    return (
                      <Tag
                        color={candidateLabel?.color}
                        className="flex items-center h-7 max-w-full"
                        key={label.id}
                      >
                        <Text
                          ellipsis={{
                            tooltip: candidateLabel?.name,
                          }}
                          style={{
                            color: candidateLabel?.color
                              ? determineTextColor(candidateLabel.color)
                              : undefined,
                            maxWidth: '100%',
                          }}
                        >
                          {candidateLabel?.name}
                        </Text>
                      </Tag>
                    );
                  })}
              </Flex>
            </Space>
            <Divider className="my-2" /></>)}  
                        <div className="w-full h-full" >

              <CandidateInPoolAttachments />
              </div>
              </div>
            <div className="overflow-y-scroll w-full"  style={{maxHeight:'calc(50%-20px)',paddingRight:'7px'}}>
             <Tabs defaultActiveKey="1" items={items} tabPosition="top" style={{width:"100%"}}  tabBarStyle={{
        top: 0,zIndex: 1, background:'white',marginBottom:'4px'}}/>
             </div>

            
          </div>
       
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  currentCandidateInPool: state.opening.currentCandidateInPool,
  applicationId: state.opening.applicationId,
  candidateLabels: state.opening.candidateLabels,
  members: state.opening.members,
  sources: state.opening.candidateSources,
  candidateDBTableHeader: state.opening.candidateDBTableHeader,
  currentTrackerJd: state.opening.currentTrackerJd,
  currentOrg:state.opening.currentOrg,
});

const mapPropsToState = (dispatch: Dispatch) => ({
  InitCurrentCandidateInDB: (candidate: Candidate) =>
  dispatch(InitCurrentCandidateInAtsPool(candidate)), 
});

export default connect(mapStateToProps,mapPropsToState)(CandidateInPoolDetails);
