import React from "react";
import { Form, InputNumber, Select, Space, Typography } from "antd";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import { jdCreationObject } from "./Types";
import { compensation_error, experience_error, MAX_EXPERIENCE_ERROR } from "../../constant";
import { FormInstance } from "antd/lib";
const { Text } = Typography;
const { Option } = Select;

type JobPostAdditionalInfoProps = {
  data: jdCreationObject;
  formInstance: FormInstance;
};
const JobPostAdditionalInfo = (props: JobPostAdditionalInfoProps) => {

  // validating the minimum experience
  const validateExperience = (_: any, value: number) => {
    if (value > 100) {
      return Promise.reject(new Error(MAX_EXPERIENCE_ERROR))
    }
    if (value > props.formInstance.getFieldValue("experience_to")) {
      return Promise.reject(new Error(experience_error))
    }
    return Promise.resolve()
  }

  //validating the maximum experience
  const validateExperienceTo = (_: any, value: number) => {
    if (value > 100) {
      return Promise.reject(new Error(MAX_EXPERIENCE_ERROR))
    }
    return Promise.resolve()
  };

  //validating the minimum salary
  const validateSalary = (_: any, value: number, context: FormInstance) => {
    const { getFieldValue } = context
    const minSalary = getFieldValue("minimum_salary")
    const maxSalary = getFieldValue("maximum_salary")

    if (minSalary && maxSalary) {
      if (minSalary > maxSalary) {
        return Promise.reject(compensation_error)
      }
    }

    return Promise.resolve();
  };
  const handleSalaryChange = () => {
    // Trigger validation for both salary fields
    props.formInstance.validateFields(["minimum_salary", "maximum_salary"])
  };
 
  const handleExperienceChange = () => {
    // Trigger validation for both experience fields
    props.formInstance.validateFields(["experience_from"])
  }

  return (
    <div className="w-full mt-8 h-full">
      <div className="flex">
        <Text strong>Additional details&nbsp;</Text>{" "}
        <Text type="secondary" style={{ fontSize: "14px" }}>
          (Optional)
        </Text>
      </div>
      <div>
        <div className="mb-4">
          <Text type="secondary" style={{ fontSize: "14px" }}>
            Provide a additional detail of the job post, specify preferred years
            of relevant experience.
          </Text>
        </div>
        <div className="flex flex-col gap-4 mt-4 sm:flex-col md:flex-col lg:flex-row lg:gap-8">
          <div className="w-full">
            <label className="font-medium">
              {capitalizeFirstLetter("work experience")}
            </label>
            <Space.Compact className="w-full mt-2">
              <Form.Item
                name="experience_from"
                className="w-full"
                rules={[{ validator: validateExperience }]}
              >
                <InputNumber
                  onChange={handleExperienceChange}
                  min={0}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
                  addonAfter="To"
                  className="w-full input-num"
                  onKeyPress={(e) => {
                    if (e.key === "-") {
                      e.preventDefault();
                    }
                  }}
                />
              </Form.Item>
              <Form.Item
                name="experience_to"
                className="w-full"
                rules={[{ validator: validateExperienceTo }]}
              >
                <InputNumber
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  min={0}
                  parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
                  addonAfter="Years"
                  style={{ width: "100%" }}
                  onChange={handleExperienceChange}
                  className="w-full"
                  onKeyPress={(e) => {
                    if (e.key === "-") {
                      e.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </Space.Compact>
          </div>
          <div className="w-full">
            <label className="font-medium">
              {capitalizeFirstLetter("Compensation")}
            </label>
            <Space.Compact className="w-full mt-2">
            <Form.Item name="salary_currency_type" className="w-auto">
                <Select  defaultValue="USD" className="salary-currency-item">
                  <Option value="INR">INR</Option>
                  <Option  value="USD">USD</Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="minimum_salary"
                className="w-full"
                rules={[{  validator: (_, value) => validateSalary(_, value, props.formInstance) }]}
              >
                <InputNumber
                  addonAfter="To"
                  onChange={handleSalaryChange}
                  value={props.data.minimum_salary}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
                  className="w-full input-num"
                 
                  min={0}
                  onKeyPress={(e) => {
                    if (e.key === "-") {
                      e.preventDefault();
                    }
                  }}
                />
              </Form.Item>
              <Form.Item
                name="maximum_salary"
                className="w-full"
              >
                <InputNumber
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  min={0}
                  parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
                  value={props.data.maximum_salary}
                  className="w-full input-num"
                  onKeyPress={(e) => {
                    if (e.key === "-") {
                      e.preventDefault();
                    }
                  }}
                  onChange={handleSalaryChange}
                />
              </Form.Item>
              <Form.Item name="salary_type" className="w-1/3">
                <Select defaultValue="Annual" className="w-full">
                  <Option value="Annual">Annual</Option>
                  <Option value="Monthly">Monthly</Option>
                </Select>
              </Form.Item>
            </Space.Compact>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobPostAdditionalInfo;
