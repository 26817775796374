import React, { useEffect, useState } from "react";
import JobPostCreation from "../../JdManagement/JobPostCreation";
import { Button, Typography } from "antd";
import { connect } from "react-redux";
import { UpgradeButton } from "../../../ant/Button";
import UpgradePlanModal from "../../../pages/setting/billingSetting/UpgradePlanModal";
import { TotalBoards } from "../../../type/type";
const { Text } = Typography;

export type CreateBoardPreviewProps = {
  totalBoards:TotalBoards;
}
const CreateBoardPreview = (props: CreateBoardPreviewProps) => {
  const [showModal, setShowModal] = useState(false);
  const [totalBoard, setTotalBoard] = useState(props?.totalBoards?.total);
  const [availableBoard, setAvailableBoard] = useState(props?.totalBoards?.active);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    setTotalBoard(props?.totalBoards?.total);
    setAvailableBoard(props?.totalBoards?.active);
  }, [props.totalBoards]);

  const onCancle = () => {
    setShowModal(false)
  }

  const handleBillingModalCancel = () => {
    setIsModalVisible(false);
  };
  const handleBillingModal = () => {
    if (totalBoard >= availableBoard) {
      setIsModalVisible(true);
    } else {
      setShowModal(true);
    }
  }
  return (
    <>
      {availableBoard >= totalBoard ? (
        <UpgradeButton label={<><Text >
          Create job post</Text>{" "}
        </>}
          onClick={handleBillingModal}
        >
          <Text type="secondary" className="text-xs" ></Text>
        </UpgradeButton>
      ) : (
        <Button className="h-7 flex items-center justify-center" iconPosition="start"
          onClick={() => setShowModal(true)}
        >
          <Text>
            Create job post</Text>
        </Button>)}

      {showModal &&
        <JobPostCreation open={showModal} onCancel={onCancle} />
      }
      <UpgradePlanModal isModalVisible={isModalVisible} handleCancel={handleBillingModalCancel} />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  totalBoards:state.opening.totalBoards
});
export default connect(mapStateToProps)(CreateBoardPreview);
