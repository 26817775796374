import React from "react"
import { connect } from "react-redux"
import { CandidateReview, CandidateShortReview } from "../../type/type"

import { changeStateOfCloseReview } from "../../redux/actions/opening/action"
import { Dispatch } from "redux"
import ClosedReviewPopover from "./ClosedReviewPopover"
import { CloseReview } from "../../lib/api"
import { LabeledIconButton } from "../../ant/Button"

const ClosedReview = (props: {
    currentCandidateInReview: CandidateReview,
    changeStateOfCloseReview(reviewId: string): void,
    setLoading: (event: boolean) => void,
}) => {

    const deleteResource = () => {
        props.setLoading(true)
        CloseReview({ reviews: [props.currentCandidateInReview.id] }).then((res: CandidateShortReview[]) => {
            if (res) props.changeStateOfCloseReview(res[0].id)
            props.setLoading(false)
        })
    }

    return (
        <div className="w-full">
            <ClosedReviewPopover deleteResource={deleteResource} selectedReview={props.currentCandidateInReview} popupElement={<LabeledIconButton label="Close review" onClick={() => { }} />}
            />
        </div>
    )
}
const mapStateToProps = (state: any) => ({
    applicationId: state.opening.applicationId,
    reviewLabels: state.opening.reviewLabels,
    candidatesInReview: state.opening.candidatesInReview,
    currentCandidateInReview: state.opening.currentCandidateInReview,

})
const mapPropsToState = (dispatch: Dispatch) => ({
    changeStateOfCloseReview: (reviewId: string) => {
        dispatch(changeStateOfCloseReview(reviewId))
    },

})
export default connect(mapStateToProps, mapPropsToState)(ClosedReview)
