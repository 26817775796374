import React from "react";
import { ListPopupMenuItemProps } from "../../../type/listPopup";
const ListPopupMenuItem = ({
  menuItemName,
  popup,
  click,
  move,
}: ListPopupMenuItemProps) => {
  return (
    <React.Fragment>
      <div
        className="hover:bg-gray-100 py-1.5 px-3 mb-0.5  align-middle w-full truncate"
        onClick={move ? move : () => click(popup)}
        key={popup}
        onKeyDown={move ? move : () => click(popup)}
        role="button"
        tabIndex={0}
      >
        {menuItemName}
      </div>
    </React.Fragment>
  );
};
export default ListPopupMenuItem;
