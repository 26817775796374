import React from "react";
import { Tabs } from "antd";
import Comments from "../comment/CardHistory";
import CommentInput from "../comment/commentInput/CommentInput";
import ShowComments from "../comment/commentInput/ShowComments";
// import CardMailPreview from "../comment/CardMailPreview";
import { getActivity, getApplicantComments, getRecordMailPreview, getUserThreadOfMessage } from "../../../lib/api";
import { connect } from "react-redux";
import {  getSelectedMailThread, initComments } from "../../../redux/actions/opening/action";
import { Dispatch } from "redux";
import { currentApplicant, RecordThreads } from "../../../type/type";
import { ACTIVITY_TAG, COMMENT_TAG, EMAIL_TAG } from "../../../constant";
import CardDetailMail from "../comment/CardDetailMail";
import { useParams } from "react-router-dom";

type ActivityTabProps = {
  currentApplicant: currentApplicant;
  initComments(comments: any, tag: string, applicant_id: string): void;
  getSelectedMailThread: (currentMailId: string,threadMsg: RecordThreads) => void;
}
const ActivityTab = (props: ActivityTabProps) => {
  const params: { card_id: string } = useParams();

  const handleTabChange = (key:string)=>{
    if (props.currentApplicant.id) {
      if(key === "comments"){
        getApplicantComments(props.currentApplicant.id)
        .then((res) => {
          // Call initComments after successfully fetching commentssetLoading
          props.initComments(res, COMMENT_TAG, props.currentApplicant.id);
        })
        .catch((error) => {
          // Handle any errors here
          console.error("Failed to fetch applicant comments:", error);
        });
      }
      if(key === "email"){
        getRecordMailPreview(props.currentApplicant.id).then((res) => {
          props.initComments(res.items, EMAIL_TAG, props.currentApplicant.id);
        });
        getUserThreadOfMessage(props.currentApplicant.id).then((res) => {
          props.getSelectedMailThread(props.currentApplicant.id, res);
        });
      }
      if(key === "activity"){
        if (props.currentApplicant.id) {
          getActivity("cards", params.card_id).then((res) => {
            if (res) {
              props.initComments(res, ACTIVITY_TAG, props.currentApplicant.id);
            }
          });
        }
      }
    }
  }
  const items = [
    {
      key: "comments",
      label: <div>Comments</div>,
      children: (
        <div
          className="flex flex-col"
          style={{
            height: "calc(100vh - 225px )",
          }}
        >
          <div className="overflow-y-auto h-full">
            <ShowComments />
          </div>
          <div className="mt-auto">
            <CommentInput />
          </div>
        </div>
      ),
    },
    {
      key: "email",
      label: "Email",
      children: (
        <div
          style={{
            height: "calc(100vh - 225px )",
          }}
        >
          <CardDetailMail />
        </div>
      ),
    },
    {
      key: "activity",
      label: "Activity",
      children: (
        <div
          className="overflow-y-scroll"
          style={{
            height: "calc(100vh - 225px )",
          }}
        >
          {" "}
          <Comments type="activity" />{" "}
        </div>
      ),
    },
  ];
  return (
    <Tabs className="card-activity-tab" onChange={handleTabChange} defaultActiveKey="comments" items={items} />
  );
};

const mapStateToProps = (state: any) => ({
  currentApplicant: state.opening.currentApplicant,
  comments: state.opening.comments,
  members: state.opening.members,
});
const mapPropsToState = (dispatch: Dispatch) => ({
  initComments: (comments: any, tag: string, applicant_id: string) =>
    dispatch(initComments(comments, tag, applicant_id)),
  getSelectedMailThread: (currentMailId: string, threadMsg: RecordThreads) =>
    dispatch(getSelectedMailThread(currentMailId, threadMsg)),
});
export default connect(mapStateToProps, mapPropsToState)(ActivityTab);
