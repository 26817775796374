import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import moment from "moment";
import { activity, GetOpening, openingActivity } from "../../../../type/type.d";
import { initOpeningActivity } from "../../../../redux/actions/opening/action";
import BoardActivityMsg from "./BoardActivityMsg";
import { getActivityByPagination } from "../../../../lib/api";
import { DATE_TIME_FORMATE, MODEL_BOARD } from "../../../../constant";
import {  capitalizeFirstLetter, generateTooltipForMember } from "../../../../utils/capitalizeFirstLetter";
import { Avatar, Button, List,  Tooltip } from "antd";

type ActivityProps = {
  currentOpening: GetOpening;
  initOpeningActivity(activity: any, openingId: string,currentPage:number): void;
  openingActivity: openingActivity;
  member: any;
};

const Activity = (props: ActivityProps) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize] = useState<string>("10");
  const [loadMore, setLoadMore] = useState<boolean>(false);

  var arr = props.openingActivity[props.currentOpening.id]
    ? Object.values(props.openingActivity[props.currentOpening.id]).sort(
        (a: activity, b: activity) => (a.created_at < b.created_at ? 1 : -1)
      )
    : [];


  useEffect(() => {
    props.currentOpening.id&&
    getActivityByPagination(
      MODEL_BOARD,
      props.currentOpening.id,
      currentPage.toString(),
      pageSize
    ).then((activities) => {
      activities.items = activities.items ? activities.items : [];
      if (activities.page_count > currentPage) {
        setLoadMore(true);
      } else setLoadMore(false);
      props.initOpeningActivity(activities.items, props.currentOpening.id,currentPage);
    });
    return () => {};
  }, [currentPage,props.currentOpening]);

  if (!props.openingActivity) return null;

  return (
    <React.Fragment >
      <div className="flex flex-col w-6/12 justify-between ">
      <List className=""
        itemLayout="horizontal"
        dataSource={arr}
        renderItem={(opening: activity, index) => (
          <List.Item key={index}>
            <List.Item.Meta
              avatar={
                <Tooltip title={generateTooltipForMember(props.member[opening.owner_id])}>
                  <Avatar 
                    src={props.member[opening.owner_id].image_url} 
                    style={{ backgroundColor: !props.member[opening.owner_id].image_url ? '#87d068' : undefined }}
                  >
                    {!props.member[opening.owner_id].image_url && 
                      `${props.member[opening.owner_id].first_name[0]}${props.member[opening.owner_id].last_name[0]}`}
                  </Avatar>
                </Tooltip>
              }
              title={
                <span className="flex font-normal">
                  <span className="font-semibold mr-1">{` ${capitalizeFirstLetter( props.member[opening.owner_id].first_name)} ${props.member[opening.owner_id].last_name}`}</span>
                 <span className=" space-x-1 "> <BoardActivityMsg activity={opening} /></span>
                </span>
              }
              description={
                <span className="text-xs">
                  {moment(opening.created_at).format(DATE_TIME_FORMATE)}
                </span>
              }
            />
          </List.Item>
        )}
      />
      {loadMore && (
        <div className="pb-1 px-1 w-full text-center">
         
          <Button             onClick={() => setCurrentPage((prev) => prev + 1)}

        type="primary"
        style={{
          backgroundColor: "#F0EFFE",
          color: "#4B48A5",
          height: "30px",
          width: "98px",
        }}
        className="hover:border-purple-400"
      >
        Show more
      </Button>
        </div>
      )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
  openingActivity: state.opening.openingActivity,
  member: state.opening.members,
});

const mapPropsToState = (dispatch: Dispatch) => ({
  initOpeningActivity: (activity: any, openingId: string,currentPage:number) =>
    dispatch(initOpeningActivity(activity, openingId,currentPage)),
});

export default connect(mapStateToProps, mapPropsToState)(Activity);
