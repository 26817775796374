import React, { useEffect, useRef, useState } from "react";
import { loginUser, Mail, orgMember } from "../../type/type.d";
import { connect } from "react-redux";
import MailThread from "./MailThread";
import MailReplyBox from "../mail/MailReplyBox";

type MailProps = {
  mail: Mail;
  callApi?: (e: any) => void;
  service: string;
  members: orgMember;
  entity: string;
  selectMailId: string;
  clientId: string;
  record_id: string | any;
  isCardThread?: boolean;
  source: { id: string; name: string; email: string };
  loginUser: loginUser;
};
const DetailMail = (props: MailProps) => {
  const [reply, setReply] = useState("");
  const refer = useRef(null);

  const discardMail = () => {
    setReply("");
  };

  const clickOnSetReply = (id: string) => {
    props.mail.initiator.trim().length > 0 && setReply(id);
  };
  
    const replyBoxRef = useRef<HTMLDivElement | null>(null); // Create a ref for the reply box

    const scrollToReplyBox = () => {
      if (replyBoxRef.current) {
        replyBoxRef.current.scrollIntoView({ behavior: "smooth" }); // Smooth scrolling to the reply box
      }
    };
  
    useEffect(() => {
      if (reply) {
        scrollToReplyBox(); // Scroll to the reply box when it's opened
      }
    }, [reply]);

  return (
    <div>
      <MailThread
        isCardThread={props.isCardThread ? true : false}
        mail={props.mail}
        setMailId={(id: string) => clickOnSetReply(id)}
      />
      <div>
        {reply.trim().length > 0 && reply === props.mail.id && (
          <div className="px-3 pl-12 m-1 my-2 " id={reply} ref={replyBoxRef} >
            <div className="animate-popover_fadeIn  border border-tacitbase-tertiary1 border-opacity-20 rounded  max-h-full">
              <MailReplyBox
                refer={refer}
                entity={props.entity}
                toAddress={[props.source.email]}
                service={props.service}
                close={() => discardMail()}
                record_id={[props.record_id]}
                reduxActionCall={() => {}}
                getJobDescription={() => {}}
                getDisableState={() => {}}
                Subject={props.mail.subject}
                source={props.source}
              />
            </div>
          </div>

        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
  members: state.opening.members,
  openingMembers: state.opening.openingMembers,
  currentApplicant: state.opening.currentApplicant,
  applicantMembers: state.opening.applicantMembers,
  applicationId: state.opening.applicationId,
  clientId: state.opening.clientId,
  orgRoles: state.opening.orgRoles,
  loginUser: state.opening.loginUser,
});

export default connect(mapStateToProps)(DetailMail);
