import React from 'react';

const MarkAsRead = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_1599_7472)">
<path d="M19.2857 2.16785H14.1786C13.0826 2.16785 12.0112 2.48258 11.0893 3.07633L10 3.77499L8.91072 3.07633C7.98976 2.48269 6.91713 2.16726 5.82143 2.16785H0.714286C0.319196 2.16785 0 2.48704 0 2.88213V15.5607C0 15.9558 0.319196 16.275 0.714286 16.275H5.82143C6.91741 16.275 7.98884 16.5897 8.91072 17.1835L9.90179 17.8219C9.9308 17.8397 9.96429 17.8509 9.99777 17.8509C10.0312 17.8509 10.0647 17.842 10.0938 17.8219L11.0848 17.1835C12.0089 16.5897 13.0826 16.275 14.1786 16.275H19.2857C19.6808 16.275 20 15.9558 20 15.5607V2.88213C20 2.48704 19.6808 2.16785 19.2857 2.16785ZM5.82143 14.6678H1.60714V3.77499H5.82143C6.61161 3.77499 7.37946 4.00044 8.04241 4.42678L9.1317 5.12544L9.28571 5.22588V15.5384C8.22321 14.967 7.03571 14.6678 5.82143 14.6678ZM18.3929 14.6678H14.1786C12.9643 14.6678 11.7768 14.967 10.7143 15.5384V5.22588L10.8683 5.12544L11.9576 4.42678C12.6205 4.00044 13.3884 3.77499 14.1786 3.77499H18.3929V14.6678ZM7.4308 6.63213H3.28348C3.19643 6.63213 3.125 6.70803 3.125 6.79954V7.80401C3.125 7.89553 3.19643 7.97142 3.28348 7.97142H7.42857C7.51562 7.97142 7.58705 7.89553 7.58705 7.80401V6.79954C7.58929 6.70803 7.51786 6.63213 7.4308 6.63213ZM12.4107 6.79954V7.80401C12.4107 7.89553 12.4821 7.97142 12.5692 7.97142H16.7143C16.8013 7.97142 16.8728 7.89553 16.8728 7.80401V6.79954C16.8728 6.70803 16.8013 6.63213 16.7143 6.63213H12.5692C12.4821 6.63213 12.4107 6.70803 12.4107 6.79954ZM7.4308 9.75713H3.28348C3.19643 9.75713 3.125 9.83303 3.125 9.92454V10.929C3.125 11.0205 3.19643 11.0964 3.28348 11.0964H7.42857C7.51562 11.0964 7.58705 11.0205 7.58705 10.929V9.92454C7.58929 9.83303 7.51786 9.75713 7.4308 9.75713ZM16.7165 9.75713H12.5692C12.4821 9.75713 12.4107 9.83303 12.4107 9.92454V10.929C12.4107 11.0205 12.4821 11.0964 12.5692 11.0964H16.7143C16.8013 11.0964 16.8728 11.0205 16.8728 10.929V9.92454C16.875 9.83303 16.8036 9.75713 16.7165 9.75713Z" fill="#1890FF"/>
</g>
<defs>
<clipPath id="clip0_1599_7472">
<rect width="20" height="20" fill="white"/>
</clipPath>
</defs>
</svg>
    );
};

export default MarkAsRead;

