import React, { useEffect, useState } from "react";
import {
  AddChhanelIntegrationForBoard,
  deleteSlackChannelIntegrationForBoard,
  GetChatActionsForBoard,
  GetChatIntegrationForBoard,
  GetSlackWorkspaceChannels,
  GetSlackWorkspaceRefreshChannels,
  UpdateChatIntegrationForBoard,
} from "../../lib/api";
import { Divider, Select, Spin, Checkbox, Typography, Tag, Alert } from "antd";
import {
  ExclamationCircleOutlined,
  LoadingOutlined,
  LockOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { IconButton, LabeledIconButton } from "../../ant/Button";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import { connect } from "react-redux";
import { Opening } from "../../type/type";
import { throwFrontEndError, throwSuccess } from "../../service/throwError";

interface ActionData {
  name: string;
  entity_name: string;
  display_name: string;
  entity_display_name: string;
  description: string;
}

interface SlackIntegratedContentProps {
  currentOpening: Opening;
  integrations: any;
  slackIntegrationMessage:string;
}
const { Text } = Typography;

function SlackIntegratedContent(props: SlackIntegratedContentProps) {
  const [channels, setChannels] = useState<any[]>([]);
  const [groupedActions, setGroupedActions] = useState<{
    [key: string]: ActionData[];
  }>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedChannelId, setSelectedChannelID] = useState<string | null>(null);
  const [checkboxStates, setCheckboxStates] = useState<{
    [key: string]: { [key: string]: boolean };
  }>({});
  const [channelState, setchannelState] = useState<boolean>(false);
  const [selectedChannelName, setSelectedChannelName] = useState<string>(""); // State to store the members
  const [initialCheckboxStates, setInitialCheckboxStates] = useState<{
    [key: string]: { [key: string]: boolean };
  }>({});
  const [initialChannel, setInitialChannel] = useState<string | null>(null);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 100,
    page_count: 0,
    total_count: 0,
  });
  const [optionLoading, setOptionLoading] = useState({
    search: false,
    pagination: false,
  });
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [refreshing, setRefreshing] = useState(false); // New state for refresh loading

  useEffect(() => {
    fetchChannels(1, searchQuery, false);
  }, []);


  const fetchChannels = (
    page: number,
    searchBy?: string | null,
    isScrollable?: boolean
  ) => {
    const params = {
      page,
      per_page: pagination.per_page,
      search: searchBy || null,
    };
    let ep = "";
    if (searchBy) {
      ep = `page=${page}&per_page=${params.per_page}&search=${searchBy}`;
    } else {
      ep = `page=${page}&per_page=${params.per_page}`;
    }

    GetSlackWorkspaceChannels(ep)
      .then((response) => {
        setPagination({
          page: response.page,
          per_page: response.per_page,
          page_count: response.page_count,
          total_count: response.total_count,
        });

        setOptionLoading({ pagination: false, search: false });

        if (searchBy && !isScrollable) {
          setChannels([...response.items]);
        } else {
          setChannels((prevChannels) => [...prevChannels, ...response.items]);
        }
      })

      .catch(() => {
        return true;
      });
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const target = e.target;
    if (
      target &&
      target instanceof HTMLDivElement &&
      target.scrollTop + target.clientHeight === target.scrollHeight
    ) {
      let page = pagination.page + 1;
      if (page <= pagination.page_count) {
        setOptionLoading({ pagination: true, search: false });
        setTimeout(() => {
          if (searchQuery) fetchChannels(page, searchQuery, true);
          else fetchChannels(page, null, true);
        }, 2000);
      }
    }
  };

  const handleSearch = (searchBy: string) => {
    setSearchQuery(searchBy);
    setChannels([]);
    setOptionLoading({ pagination: false, search: true });
    setTimeout(() => {
      fetchChannels(pagination.page, searchBy, false);
    }, 1000);
  };

  useEffect(() => {
    const fetchActions = async () => {
      try {
        const actionsData: any = await GetChatActionsForBoard();
        const grouped = actionsData.reduce(
          (acc: { [key: string]: ActionData[] }, action: any) => {
            if (!acc[action.entity_name]) {
              acc[action.entity_name] = [];
            }
            acc[action.entity_name].push(action);
            return acc;
          },
          {}
        );
        setGroupedActions(grouped);

      } catch (error) {
        console.error("Failed to fetch actions:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchActions();
  }, []);


  useEffect(()=>{
// Initialize checkbox states
const initialCheckboxStates = Object.keys(groupedActions).reduce(
  (acc: any, entity) => {
    acc[entity] = groupedActions[entity].reduce(
      (actionAcc: any, action: any) => {
        actionAcc[action.name] = true;
        return actionAcc;
      },
      {}
    );
    return acc;
  },
  {}
);
setCheckboxStates(initialCheckboxStates);
  },[selectedChannelId,groupedActions])

  useEffect(() => {
    const fetchIntegration = async () => {
      try {
        const integrationData: any = await GetChatIntegrationForBoard(
          props.currentOpening.id
        );
        if (Object.keys(integrationData).length > 0) {
          // Channel is connected, update the selected channel and checkbox states
          setSelectedChannelID(integrationData.channel_id);
          setSelectedChannelName(integrationData.channel_name)
          setInitialChannel(integrationData.channel_id); // Set initial channel state

          const newCheckboxStates = Object.keys(
            integrationData.subscriptions
          ).reduce((acc: any, entity: string) => {
            acc[entity] = integrationData.subscriptions[entity].reduce(
              (actionAcc: any, action: any) => {
                actionAcc[action.action] = action.enabled;
                return actionAcc;
              },
              {}
            );
            return acc;
          }, {});
          setCheckboxStates(newCheckboxStates);
          setInitialCheckboxStates(newCheckboxStates); // Set initial checkbox state
        } else {
          // No channel connected, reset the selected channel and checkbox states
          setSelectedChannelID(null);
          setCheckboxStates({});
          setInitialCheckboxStates({})
        }
      } catch (error) {
        setchannelState(true);
      } finally {
        setLoading(false);
        setInitialLoadComplete(true); // Mark the initial load as complete
      }
    };
    fetchIntegration();
  }, [props.currentOpening.id]);

  const entityDisplayNames = Object.keys(groupedActions).reduce(
    (acc: any, entityName: string) => {
      acc[entityName] =
        groupedActions[entityName][0]?.entity_display_name ||
        capitalizeFirstLetter(entityName);
      return acc;
    },
    {}
  );

  const handleChannelChange = async (value: string, option: any) => {
setSelectedChannelName(option?.props?.children?.props.children[1])
    setSelectedChannelID(value);
  };

  const hasChanges = () => {
    if (!initialLoadComplete) return false; // Do not show warning during the initial load

    if (selectedChannelId !== initialChannel) return true;

    for (let entity in checkboxStates) {
      if (!initialCheckboxStates[entity]) return true;

      for (let action in checkboxStates[entity]) {
        if (
          checkboxStates[entity][action] !==
          initialCheckboxStates[entity][action]
        ) {
          return true;
        }
      }
    }

    return false;
  };

  const handleUpdate = async () => {
    if (!selectedChannelId) return;

    // Prepare the subscriptions data
    const subscriptions = Object.keys(groupedActions).reduce(
      (acc: any, entity: string) => {
        acc[entity] = groupedActions[entity].map((action) => ({
          action: action.name,
          enabled: checkboxStates[entity]?.[action.name] || false,
        }));
        return acc;
      },
      {}
    );

    // Call the API to update the channel settings
    try {
      await UpdateChatIntegrationForBoard(props.currentOpening.id, {
        channel_id: selectedChannelId,
        channel_name: channels.find((c) => c.channel_id === selectedChannelId)
          ?.name,
        subscriptions: subscriptions,
        enabled: true,
      });
      throwSuccess("Channel settings updated successfully.");
      // Update initial states to match the current state after a successful update
      setInitialChannel(selectedChannelId);
      setInitialCheckboxStates(checkboxStates);
    } catch (error) {
      console.error("Failed to update channel settings:", error);
    }
  };

  const handleSave = async () => {
    if (!selectedChannelId) return;

    // Prepare the subscriptions data
    const subscriptions = Object.keys(groupedActions).reduce(
      (acc: any, entity: string) => {
        acc[entity] = groupedActions[entity].map((action) => ({
          action: action.name,
          enabled: checkboxStates[entity]?.[action.name] || false,
        }));
        return acc;
      },
      {}
    );

    // Call the API to update the channel settings
    try {
      const apiPayload = {
        opening_id: props.currentOpening.id,
        channel_id: selectedChannelId,
        channel_name: selectedChannelName.trim(),
        app_name: props.integrations[0].app_name,
        team_name: props.integrations[0].team_name,
        team_id: props.integrations[0].team_id,
        integration_id: props.integrations[0].id,
        subscriptions: subscriptions,
        enabled: true,
      };

      await AddChhanelIntegrationForBoard(props.currentOpening.id, apiPayload);
      throwSuccess("Channel settings saved successfully.");
      // Update initial states to match the current state after a successful save
      setInitialChannel(selectedChannelId);
      setInitialCheckboxStates(checkboxStates);
      setchannelState(false);
    } catch (error) {
      console.error("Failed to saved channel settings:", error);
    }
  };

  const handleCheckboxChange = (
    entity: string,
    actionName: string,
    checked: boolean
  ) => {
    const newCheckboxStates = {
      ...checkboxStates,
      [entity]: {
        ...checkboxStates[entity],
        [actionName]: checked,
      },
    };
    setCheckboxStates(newCheckboxStates);
  };

  const handleSelectAllChange = (entity: string, checked: boolean) => {
    const newCheckboxStates = {
      ...checkboxStates,
      [entity]: Object.fromEntries(
        Object.keys(checkboxStates[entity] || {}).map((actionName) => [
          actionName,
          checked,
        ])
      ),
    };
    setCheckboxStates(newCheckboxStates);

  };

  const handleRemoveChannel = async () => {
    if (!selectedChannelId) return;

    try {
      await deleteSlackChannelIntegrationForBoard(props.currentOpening.id);

      // Reset the selected channel and checkbox states
      setSelectedChannelID(null);
      setCheckboxStates({});
      setInitialCheckboxStates({})
      setchannelState(true);
      throwSuccess("Slack channel removed successfully.");
    } catch (error) {
      console.error("Failed to remove Slack channel:", error);
    }
  };
  

  const fetchRefreshChannels = async () => {
    setRefreshing(true); // Set refreshing to true before the API call
    try {
      const res:any = await GetSlackWorkspaceRefreshChannels();
      setChannels(res)
    } catch {
      throwFrontEndError("Failed to sync new slack channels.");
    } finally {
      setRefreshing(false); // Set refreshing to false after the API call
    }
  };

  return (
    <>
      <div
        className="flex items-center px-4 border-b justify-between"
        style={{ height: 44 }}
      >
        <Text>Slack notification</Text>
        <div className="flex flex-row space-x-2">
          {initialLoadComplete && hasChanges() && selectedChannelId && (
            <Tag
              icon={<ExclamationCircleOutlined />}
              color="warning"
              className="flex items-center"
            >
              You have made changes, please save or update.
            </Tag>
          )}
          {channelState ? (
            <LabeledIconButton
              label="Save"
              onClick={handleSave}
              className="h-7"
            />
          ) : (
            <LabeledIconButton
              label="Update"
              onClick={handleUpdate}
              className="h-7"
            />
          )}
        </div>
      </div>
      <div
        className="h-full w-full overflow-y-scroll"
        style={{ height: "calc(100vh - 176px)" }}
      >
        <div className="flex flex-col h-full p-4 w-full 2xl:w-1/2 xl:w-3/4">
          <div className="flex flex-col text-color-black  font-medium">
            Send job post notifications to Slack channel
          </div>
          <div className="flex flex-col text-color-black font-light">
            Manage job post notifications you want to receive for selected Slack
            channels.
          </div>
          <div className="py-4 items-center flex">
            {loading ? (
              <div className="justify-center flex w-full items-center">
              <Spin />
              </div>
            ) : (
              <div className="flex flex-col w-full">
                              <div className="flex flex-row">

                <Select
                  showSearch
                  onSearch={handleSearch}
                  notFoundContent={
                    optionLoading.search ? (
                      <div className="flex justify-center py-4">
                        <Spin size="small" />
                      </div>
                    ) : (
                      <div>
                      <span>
                      No matching results.
                      </span><br/>Looking for a private channel? First, invite the Tacitbase app to your private channel, or if it&apos;s already added, try refreshing.
                      </div>
                    )
                  }
                  onPopupScroll={(e) => handleScroll(e)}
                  placeholder="Select a channel"
                  style={{ width: "100%" }}
                  optionFilterProp="children"
                  virtual={false}
                  filterOption={false}
                  onChange={handleChannelChange}
                  value={selectedChannelId}                  
                  className="h-7" 
                 

                  dropdownRender={(menu) => (
                    <div>
                      {menu}
                      {optionLoading.pagination && (
                        <div className="flex justify-center items-center py-4 ">
                          <Spin
                            size="small"
                            className="flex justify-center items-center "
                          />
                        </div>
                      )}
                    </div>
                  )}
                >
                  {channels &&
                    channels.map(
                      (channel: any) => (
                        (
                          <Select.Option
                            key={channel.id}
                            value={channel.channel_id}
                            disabled={channel.attached} private={channel.is_private} 
                          >
                            {channel.is_private ? (
                              <>
                                <LockOutlined />{" "+channel.name}
                              </>
                            ) : (
                              <># {channel.name}</>
                            )}
                          </Select.Option>
                        )
                      )
                    )}
                </Select>
                <div className="ml-1">
                  <IconButton
                    tooltipTitle="Sync the channels"
                    icon={refreshing ? <LoadingOutlined /> : <ReloadOutlined />}
                    onClick={fetchRefreshChannels} // Fetch latest channels on button click
                    disabled={loading}
                    style={{ marginRight: "4px" }}
                  />
                </div>
                </div>

                <Text type="secondary" className="text-left mt-1" >
                {/* <sup style={{ color: "red" }}>*</sup> */}
                To add a private channel, you need to invite the Tacitbase app into your channel.
                 </Text>
              </div>
            )}
          </div>
          {props.slackIntegrationMessage.length > 0 && (
                <Alert type="warning" message={props.slackIntegrationMessage} showIcon />
              )}
          {selectedChannelId && (
            <div className="flex justify-end py-2">
              <LabeledIconButton
                label="Remove slack channel"
                onClick={handleRemoveChannel}
                className="h-7"
              />
            </div>
          )}
          {/* <Divider className="my-4" /> */}
          <div className="py-4 ">
            {selectedChannelId &&
              Object.keys(groupedActions).map((entityDisplayName) => (
                <>
                  <Divider className="my-4" />
                  <div key={entityDisplayName} className="my-2">
                    <div className="flex items-center w-full my-1">
                      <Checkbox
                        checked={
                          checkboxStates[entityDisplayName] &&
                          Object.values(
                            checkboxStates[entityDisplayName]
                          ).every(Boolean)
                        }
                        onChange={(e) =>
                          handleSelectAllChange(
                            entityDisplayName,
                            e.target.checked
                          )
                        }
                      >
                         <div
                        className="text-color-black  text-sm font-medium"
                        // style={{ fontSize: "15px" }}
                      >
                        {capitalizeFirstLetter(
                        entityDisplayNames[entityDisplayName]
                      )}</div></Checkbox>
                    </div>
                    <div className="flex flex-col items-center my-2 ">
                      {groupedActions[entityDisplayName].map((action) => (
                        <>
                          <div
                            className="flex items-center w-full my-1 -mr-12"
                            key={action.display_name}
                          >
                            {/* <div className="text-color-black text-sm font-medium">
                              {capitalizeFirstLetter(action.display_name)}
                            </div> */}
                            <Checkbox
                              key={action.name}
                              checked={
                                checkboxStates[entityDisplayName]?.[
                                  action.name
                                ] || false
                              }
                              onChange={(e) =>
                                handleCheckboxChange(
                                  entityDisplayName,
                                  action.name,
                                  e.target.checked
                                )
                              }
                           >      
                           <div className="text-color-black text-sm font-light ">
                                                 {capitalizeFirstLetter(action.display_name)}</div>   
                                                 </Checkbox> 
                          </div>
                          {/* <div className="text-color-black mb-4 text-sm font-normal">
                            {capitalizeFirstLetter(action.description)}
                          </div> */}
                        </>
                      ))}
                    </div>
                  </div>
                </>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
  integrations: state.opening?.chatsIntegration,
});
export default connect(mapStateToProps)(SlackIntegratedContent);
