import React from "react";
import { connect } from "react-redux";
import CloseIcon from "../../../../assets/Close"
import { getIcon } from "../../boardScheduler/fullCalender/GuestStatus";
import TacitbaseAvtar from "../../../../ant/Avtar"
import { generateTooltipForMember } from "../../../../utils/capitalizeFirstLetter"
type guestProps = {
  guest: string[];
  arr: (e: any) => void;
  members: any;
  type: string;
  attendees: any;
  setMeetingInput: (e: any) => void;
};
const Guests = (props: guestProps) => {
  const removeGuest = (memberId: string, member: string) => {
    props.arr((prev: string[]) => prev.filter((guest) => guest !== memberId));
    const filteredArray = props.attendees.filter(
      (obj: any) => obj.email != member
    );
    props.setMeetingInput((obj: any) => ({
      ...obj,
      attendees: filteredArray,
    }));
  };

  const removeGuestOnEdit = (memberId: string, member: string) => {
    props.arr((prev: string[]) => prev.filter((guest) => guest !== memberId));
    const deletedAttendees = props.attendees.filter(
      (obj: any) => obj.email == member
    );
    props.setMeetingInput((obj: any) => ({
      ...obj,
      deleted_attendees: deletedAttendees,
    }));

    const filteredArray = props.attendees.filter(
      (obj: any) => obj.email != member
    );
    props.setMeetingInput((obj: any) => ({
      ...obj,
      attendees: filteredArray,
    }));
  };
  
  return (
    <>
      {props.guest ? (
        props.guest.length > 0 ? (
          <div className="flex flex-col w-full space-y-2">
            <div className="flex justify-between items-start  space-y-2">
              <div className="input-label">Guests</div>{" "}
              <div className="flex space-x-2 items-start "></div>
            </div>{" "}
            <div className="flex flex-col space-y-1">
              {props.guest?.map((member, i) => (
                <div className="flex flex-col " key={i}>
                  <div className="flex items-center justify-between bg-white w-full px-1.5 py-1  text-sm rounded border border-tacitbase-tertiary1 border-opacity-30 ">
                    <div className="w-4/12">
                      <div
                        className={`flex flex-row items-center ${
                          props.members[member] ? "space-x-2" : "space-x-1"
                        } `}
                      >
                        <div className="flex flex-col relative ">
                          {props.members[member] && props.members[member]?.image_url ? (
                            <>
                              <TacitbaseAvtar
                                toolTip={generateTooltipForMember(props.members[member])}
                                src={props.members[member].image_url}
                                size="default"
                              />
                              {getIcon(
                                props.attendees.find(
                                  (attendee: any) =>
                                    attendee.email ==
                                    props.members[member].email
                                )
                              )}
                            </>
                          ) : (
                            <>
                                <TacitbaseAvtar
                                  toolTip={generateTooltipForMember(props.members[member])}
                                  size="default"
                                />
                                {getIcon(
                                props.attendees.find(
                                  (attendee: any) => attendee.email == member
                                )
                              )}
                            </>
                          )}
                        </div>
                        <div className="flex flex-col -space-y-1 justify-center">
                          <div className="leading-snug font-semibold text-xs text-gray-800 tracking-normal line-clamp-1">
                            {props.members[member]
                              ? props.members[member].username
                              : member}
                          </div>
                          <div className="text-xs leading-snug tracking-wide text-gray-800">
                            {props.members[member]
                              ? props.members[member].email
                              : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    {props.type == "create" ? (
                      <div
                        className="w-max flex justify-end cursor-pointer"
                        aria-hidden
                        onClick={() =>
                          removeGuest(
                            member,
                            props.members[member]
                              ? props.members[member].email
                              : member
                          )
                        }
                      >
                        {" "}
                        <CloseIcon />
                      </div>
                    ) : (
                      <div
                        className="w-max flex justify-end cursor-pointer"
                        aria-hidden
                        onClick={() =>
                          removeGuestOnEdit(
                            member,
                            props.members[member]
                              ? props.members[member].email
                              : member
                          )
                        }
                      >
                        {" "}
                        <CloseIcon />
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : null
      ) : null}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  members: state.opening.members,
});

export default connect(mapStateToProps)(Guests);
