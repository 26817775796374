import React from "react";
import PopupTitle from "../../common/popupTitle/PopupTitle";
import BoardLabelInput from "../boardLabelInput/BoardLabelInput";
import { BoardLabelProps } from "../../../type/sidePanalSlider";

// Api's

const BoardLabel = (props: BoardLabelProps) => {
  return (
    <React.Fragment>
      <div className="">
        <PopupTitle
          PopupTitle="Label"
          font="text-base font-medium text-black"
          close={props.close}
          back={props.prev}
        />
        <BoardLabelInput flag={true} close={() => props.close} />
      </div>
    </React.Fragment>
  );
};
export default BoardLabel;
