// ResetPassword.js

import React, { useState, useEffect } from 'react'
import { Form, Input, Button } from 'antd'
import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter'
import { BACK_TO_LOGIN, RESET_PASSWORD_HEADING } from '../../constant'
import Link from 'antd/es/typography/Link'
import { forgetPassword } from '../../lib/api'
import CheckEmail from './CheckResetPwdMail'
import { useHistory, useLocation } from 'react-router-dom';
import { tokenVerify } from '../../service/tokenVerify'
import TB_ORANGE_LOGO from "../../images/TB full orange.png"


const ResetPassword = () => {
  // const history = useHistory()
  const location: any = useLocation();
  const history = useHistory();

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [emailValue, setEmailValue] = useState('')
  useEffect(()=>{
    // If the login token is valid and the user is already logged in, and they attempt to access /password/change, the user will be automatically redirected to /o.
        if (tokenVerify()) {
            history.push(`/o`);
        }
      },[])

  useEffect(() => {
    // Check if the success property is present in the location state
    if (location.state && location.state.success !== undefined) {
      setSuccess(location.state.success)
    }
  }, [location.state])

  const onFinish = (values: any) => {
    setLoading(true);
    const forgotPwdObject = {
            sendForgotPasswordEmail: true,
      loginId: values.email.toLowerCase(),
    };

    forgetPassword(forgotPwdObject)
      .then((res) => {
        if (res) {
          setSuccess(true)
          setEmailValue(values.email)
        }
            })
      .catch(() => {
      })
      .finally(() => {
        setLoading(false)
      })
  };

    return (
      <div className="flex flex-col w-full  h-full bg-cover bg-bgImgType1 ">
        <div className='justify-center items-center'>
        <div className="bg-transparent pt-6 pl-6 "> 
       <img src={TB_ORANGE_LOGO} alt="" width="200px" style={{backgroundColor:'transparent'}} />
       </div>
        <div className="flex pt-16 xl:space-x-4 w-full h-full overflow-y-scroll ">
          <div className="flex justify-center h-full items-start w-full">
            <div
              className="bg-white p-8 rounded xl:m-10"
              style={{
                width: 463,
                boxShadow: '2px 4px 10px 0px #00000040',
                border: '1px solid #D9D9D9',
              }}
            >
              <div className="flex flex-col md:space-y-4 space-y-2 relative w-full ">
                {!success ? (
                  <>
                    <div className="w-full flex items-center justify-center font-semibold md:text-lg text-base">
                      {capitalizeFirstLetter('reset password')}
                    </div>
                    <div className="text-gray-600">{RESET_PASSWORD_HEADING}</div>
                    <Form
                      name="resetPassword"
                      onFinish={onFinish}
                      initialValues={{ email: emailValue }}
                      layout="vertical"
                    >
                      <Form.Item
                        name="email"
                        label="Email"
                        rules={[
                          { required: true, message: 'Please input your email!' },
                          { type: 'email', message: 'Invalid email format' },
                        ]}
                      >
                        <Input type="email" />
                      </Form.Item>

                      <Button
                        loading={loading}
                        htmlType="submit"
                        className="w-full my-3"
                        type='primary'
                      >
                        Send instructions
                      </Button>
                      <Link href={`/login`}>{BACK_TO_LOGIN}</Link>
                    </Form>
                  </>
                ) : (
                  <CheckEmail email={emailValue} success={success} />
                )}
                        </div>
            </div>
          </div>
        </div>
        </div>
      </div>
  )
}

export default ResetPassword
