import React, { useState,useEffect } from "react";
import { columns } from "./TeamPerformanceTableColumn";
import GenericTable from "../../ant/TableAnt";
import { getGraphDataByChartType } from "../../lib/api";
import { userTimeZone } from "./AnalyticsContent";
interface TeamPerformanceTableComponentprops {
  data?: any;
  onPaginationChange?: any;
  entityType: string;
  entityNames: string[];
  start_time?: string;
  template_id: string;
}

const TeamPerformanceTableComponent: React.FC<
  TeamPerformanceTableComponentprops
> = ({
  data,
  onPaginationChange,
  entityNames,
  entityType,
  start_time,
  template_id,
}) => {
  const [tableParams, setTableParams] = useState<any>({
    pagination: {
      current: 1,
      pageSize: 7,
      position: ["bottomLeft"],
      size: "default",
      showSizeChanger: false,
      total: data?.totalCount,
      responsive: false,
      style: {
        marginTop: "0px",
        borderTop: "1px solid rgba(5, 5, 5, 0.06)",
        paddingTop: "8px",
        position: "sticky",
      },
    },
  });

  const [tableData, setTableData] = useState<any[]>(data?.options);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const tableBody: any = document.querySelector(".ant-table-container");
    if (tableBody && !loading) {
    // const elmnt = document.getElementById("teamReportParent");
    // if(elmnt)
    tableBody.style.height = 311+"px"
    // height=elmnt?.offsetHeight
    tableBody.style.minHeight=311+"px"
    }
  }, [loading]);
  const fetchTableData = async (page: number, pageSize: number) => {
    try {
      setLoading(true);
      let entity_names_string = "";
      entityNames?.map(
        (entity: any) =>
          (entity_names_string =
            entity_names_string + `&entity_names=${entity}`)
      );
      const res = await getGraphDataByChartType("table", {
        graph_type: "table",
        template_id: template_id,
        entity_type: entityType,
        entity_names_string: entity_names_string,
        time_zone:userTimeZone,
        start_time: start_time,
        page: page,
        page_size: pageSize,
      });
      setTableData(res?.[0].options || []);
      setLoading(false);
      onPaginationChange({ ...tableParams.pagination, current: page });
    } catch (error) {
      console.error("Error fetching table data:", error);
    }
  };

  const handleTableChange = async (
    pagination: any,
    filters: any,
    sorter: any
  ) => {
    const { current, pageSize } = pagination;
    // Check if sorting is applied
    if (!sorter.field) {
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          ...pagination,
        },
      });
      setLoading(true);
      await fetchTableData(current, pageSize);
      setLoading(false);
    } else {
      // If sorting is applied, just update the pagination state
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          ...pagination,
        },
      });
    }
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        cursor: "grab",
        minHeight: "100%",
      }}
    >
      <div style={{ flex: 1 }}>
        {/* {loading ? (
          <div className="flex justify-center items-center h-full ">
            <LoadingIndicator loader_message="Loading data..." />
          </div>
        ) : ( */}
          <GenericTable
            columns={columns}
            data={tableData}
            bordered={false}
            loading={loading}
            size="small"
            showHeader={true}
            onChange={handleTableChange}
            pagination={tableParams.pagination}
            scroll={{ y: 300 }}
            style={{ minHeight: 300,cursor:'pointer' }}
            id="teamReportParent"
          />
        {/* )} */}
      </div>
    </div>
  );
};

export default TeamPerformanceTableComponent;
