import React from "react";
import CloseIcon from "../../../../assets/Close";

type Props = {
  PopupTitle: String;
  close?: (e: boolean) => void;
  font?: string;
  back?: () => void;
};

const MeetingPopupTitleComponent = (props: Props) => {
  return (
    <React.Fragment>
      <div className="flex">
        {props.back ? (
          <button
            data-testid="backTestBtn"
            className="flex cursor-pointer justify-center items-center focus:outline-none mx-2"
            onClick={props.back}
          >
            <i className="fa fa-angle-left fa-lg" aria-hidden="true"></i>
          </button>
        ) : null}

        <div
          className={`resize-none  overflow-hidden mr-1 focus:bg-white mb-1 bg-gray-100 bg-opacity-50 font-semibold text-xl px-1 rounded w-full`}
        >
          {props.PopupTitle}
        </div>

        <button
          className="flex cursor-pointer justify-center items-center focus:outline-none mx-2"
          data-testid="PopupTitleTestBtn"
          onClick={() => {
            if (props.close) props.close(false);
          }}
          tabIndex={0}
        >
          <CloseIcon />
        </button>
      </div>
      <div className="flex">
        <hr
          className="  w-full m-2"
          style={{ borderColor: "#0F0A0D", opacity: "0.3" }}
        ></hr>
      </div>{" "}
    </React.Fragment>
  );
};
export default MeetingPopupTitleComponent;
