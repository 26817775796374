import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import { createApplicantLabel, deleteLabel } from "../../../../lib/api";
import {
  addBoardLabelInCard,
  removeLabelFromCard,
} from "../../../../redux/actions/opening/action";
import {
  InitLabel,
  currentApplicant,
  GetOpening,
  Label,
  KeyWithArray,
} from "../../../../type/type";
import ShowLabelPreview from "./ShowLabelPreview";
import { SOCKET_CLIENT_ID } from "../../../../constant";
export type LabelPreviewProps = {
  currentApplicant: currentApplicant;
  currentOpening: GetOpening;
  labels: InitLabel;
  update: (label: any) => void;
  flag?: boolean;
  saveApplicantLabel(label: any, id: string, listID: string): void;
  removeLabel(labelID: string, applicantID: string, list_id: string): void;
  applicantLabels: KeyWithArray;
  clientId: string;
  searchedLabel: string;
};
const LabelPreview = (props: LabelPreviewProps) => {
  let labels = Object.values(props.labels);
  labels.sort((a: Label, b: Label) => a.name.localeCompare(b.name))

  labels = labels.filter((label: Label) => {
    return label.name.toUpperCase().includes(props.searchedLabel.toUpperCase());
  });

  const addCardLabel = (labelData: any) => {
    let found: boolean = false;
    if (props.applicantLabels[props.currentApplicant.id]) {
      found = props.applicantLabels[props.currentApplicant.id].includes(
        labelData.id
      );
      if (found) {
        const req = {
          opening_id: props.currentOpening.id,
          applicant_id: props.currentApplicant.id,
          label_id: labelData.id,
          socket_client_id:sessionStorage.getItem(SOCKET_CLIENT_ID),
        };
        deleteLabel(req).then((res: any) => {
          if (res)
            props.removeLabel(
              labelData.id,
              props.currentApplicant.id,
              props.currentApplicant.list_id
            );
        });
      }
    }
    if (!found) {
      const req = {
        name: labelData.id,
        color: labelData.color,
        applicant_id: props.currentApplicant.id,
        label_id: labelData.id,
        opening_id: props.currentOpening.id,
        socket_client_id: sessionStorage.getItem(SOCKET_CLIENT_ID),
      };
      createApplicantLabel(req)
        .then((applicant_label: any) => {
          const newLabel = {
            id: applicant_label.label_id,
            name: labelData.name,
            color: labelData.color,
          };
          props.saveApplicantLabel(
            newLabel,
            props.currentApplicant.id,
            props.currentApplicant.list_id
          );
        })
      
    }
  };
  return (
    <div className=" label-panal">
      {labels
        ? labels.map(
            (label: any) =>
              label && (
              <ShowLabelPreview
                key={label.id}
                  clickOnLabel={(label) => addCardLabel(label)}
                  label={label}
                  updateLabel={(label) => props.update(label)}
                  searchInArray={
                    props.applicantLabels[props.currentApplicant.id]
                  }
                  searchInObject={props.labels}
                  flag={props.flag}
                />
              )
          )
        : null}
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  labels: state.opening.labels,
  currentApplicant: state.opening.currentApplicant,
  currentOpening: state.opening.currentOpening,
  applicantLabels: state.opening.applicantLabels,
  clientId: state.opening.clientId,
});

const mapPropsToState = (dispatch: Dispatch) => ({
  saveApplicantLabel: (label: any, id: string, listID: string) =>
    dispatch(addBoardLabelInCard(label, id, listID)),
  removeLabel: (labelID: string, applicantID: string, list_id: string) =>
    dispatch(removeLabelFromCard(labelID, applicantID, list_id)),
});
export default connect(mapStateToProps, mapPropsToState)(LabelPreview);
