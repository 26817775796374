import React from "react";
import { Input } from "antd";

type SearchBoxProps = {
  onSearchChange: (value: string) => void;
};

const SearchBox: React.FC<SearchBoxProps> = ({ onSearchChange }) => {
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSearchChange(e.target.value);
  };

  return (
    <div className="w-60 flex items-center">
      <Input.Search
      className="global-search"
      placeholder="Search..."
      onChange={handleSearchChange}
      allowClear
    />
    </div>
  );
};

export default SearchBox;
