import { useSelector } from "react-redux";
import { orgMember, Member } from "../type/type";

// Custom Hook to get filtered members
const getOrgMembers = (): Member[] => {
  // Ensure orgMembers is of type orgMember, which is assumed to be a dictionary of Member objects
  const orgMembers:orgMember = useSelector((state: any) => state.opening.members  );
  const loginUser = useSelector((state: any) => state.opening.loginUser );

  // Filter the members and return an array of Member objects
  const filteredMembers: Member[] = Object.values(orgMembers).filter(
    (member) =>
      member.id !== loginUser.user.id &&
      member.registrations[0].data.activated &&
      member.registrations[0].roles.some((role) =>
        ["Member", "Owner"].includes(role)
      )
  );

  return filteredMembers;  // Return the filtered members as an array of type Member[]
};

export default getOrgMembers;
