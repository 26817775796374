import React, { useEffect, useState } from "react";
// import moment from "moment";

import { meeting } from "../../../../type/cardEventScheduling";
import Input from "../../../billing/inputBox/Input";
import TimezoneSelect from "react-timezone-select";

import CommonDropDown from "./Dropdown";
import moment from "moment";
import { getUsersSlots } from "../../../../lib/api";
import { orgMember } from "../../../../type/type";
import { MatchingSlots } from "../../../../lib/apiRes";
import Slots from "./Slots";
type meetingProps = {
  setMeetingInput: (e: any) => void;
  value: meeting;
  data?: {};
  setSelectedTimezone: (tz: any) => void;
  selectedTz: any;
  attendees: any;
  members:orgMember;
  setValidationState?: (value: any) => void;
  validations: any;
};
const Details = (props: meetingProps) => {
  const duration = ["30 min", "45 min", "60 min", "90 min", "custom..."];
  const [matchingSlots,setMatchingSlots]=useState<MatchingSlots[]>([])
const [selectedSlot,setSelectedSlot]=useState<MatchingSlots>();
const [msgInSlotOnBlank,setMsgInSlotOnBlank]=useState<{loader:boolean,msg:string}>({loader:false,msg:""});
const [findUserSlots,setFindUserSlots]=useState(false)
useEffect(() => {
  if(findUserSlots){
  props.attendees?.length == 0
    ? setMsgInSlotOnBlank({loader:false,msg:"Add guests to view slots"})
    : null;
  const slotUserArray = props.attendees.map((emailObj: any) => {
    const { email } = emailObj;
    let orgMember = Object.values(props.members);
    const member = orgMember.find((member) => member.email === email);
    return {
      user_id: member ? member.id : "",
      user_email: email,
    };
  });

  let req = { user: slotUserArray };
  slotUserArray?.length > 0 &&(setMsgInSlotOnBlank({loader:true,msg:`Fetching your latest availability`}),
    getUsersSlots(req).then((res) => {
      res?.matching_slots?.length == 0
        ? setMsgInSlotOnBlank({loader:false,msg:"No overlapping slots found"})
        : null;

      setMatchingSlots(res.matching_slots);
    }).catch(()=>{
      setMsgInSlotOnBlank({loader:false,msg:"Unable to fetch your latest availability"})
    })
  );
  }
}, [findUserSlots]);
useEffect(() => {
  selectedSlot != undefined &&
    (props.setMeetingInput((prev: any) => ({
      ...prev,
      meeting: {
        ...prev.meeting,

        date: moment(selectedSlot?.startDate).format("YYYY-MM-DDTHH:mm:ss"),
      },
    })),
    props.setValidationState &&
      props.setValidationState((prev: any) => ({
        ...prev,
        dateIsBlank: 1,
      })));
}, [selectedSlot]);
 
  return (
    <div className="flex flex-col space-y-3">
      <div className="flex w-full space-x-4">
        <div className="flex flex-col space-y-2 w-7/12">
          {" "}
          <div className="input-label">Meeting Name *</div>
          <div>
            <Input
              border={props.validations.nameIsBlank == 2 ? "red" : ""}
              setInputValue={(e) => (
                props.setMeetingInput((prev: any) => ({
                  ...prev,
                  meeting: {
                    ...prev.meeting,
                    name: e.target.value,
                  },
                })),
                e.target.value.length > 0
                  ? props.setValidationState
                    ? props.setValidationState((prev: any) => ({
                        ...prev,
                        nameIsBlank: 1,
                      }))
                    : null
                  : props.setValidationState
                  ? props.setValidationState((prev: any) => ({
                      ...prev,
                      nameIsBlank: 2,
                    }))
                  : null
              )}
              defaultValue={props.value.name}
            />{" "}
          </div>
        </div>
        <div className="flex flex-col space-y-2 w-5/12 ">
          <div className="input-label">Timezone</div>

          <TimezoneSelect
            className="cursor-pointer"
            value={props.selectedTz}
            onChange={(e) => props.setSelectedTimezone(e.value)}
          />
        </div>
      </div>
      <div className="flex space-x-4 w-full">
        <div className="flex w-7/12 space-x-4">
          {" "}
          <div className="flex flex-col space-y-2 w-8/12  ">
            <div className="input-label">Date *</div>
            <div>
              <Input
                border={props.validations.dateIsBlank == 2 ? "red" : ""}
                type="datetime-local"
                setInputValue={(e) => (
                  props.setMeetingInput((prev: any) => ({
                    ...prev,
                    meeting: {
                      ...prev.meeting,

                      date: 
                         moment(e.target.value).format("YYYY-MM-DDTHH:mm:ss")
                      
                    },
                  })),
                  e.target.value
                    ? props.setValidationState
                      ? props.setValidationState((prev: any) => ({
                          ...prev,
                          dateIsBlank: 1,
                        }))
                      : null
                    : props.setValidationState
                    ? props.setValidationState((prev: any) => ({
                        ...prev,
                        dateIsBlank: 2,
                      }))
                    : null
                )}
                defaultValue={props.value.date}
              />{" "}
            </div>
          </div>
          <div className="flex flex-col space-y-2 w-4/12  ">
            <div className="input-label">Duration</div>
            <CommonDropDown
              name="duration"
              option={duration}
              setValue={(value) =>
                props.setMeetingInput((prev: any) => ({
                  ...prev,
                  meeting: {
                    ...prev.meeting,

                    duration: value,
                  },
                }))
              }
              selectedValue={props.value.duration}
            />
          </div>
        </div>

<Slots findUserSlots={(value)=>setFindUserSlots(value)} matchingSlots={matchingSlots} setSelectedSlot={setSelectedSlot} selectedSlot={selectedSlot} selectedTz={props.selectedTz} msgInSlotOnBlank={msgInSlotOnBlank}/>
      </div>
    </div>
  );
};
export default Details;
