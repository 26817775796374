import React from "react"
import { connect } from "react-redux"
import { ColorPaletteProps } from "../../../../type/sidePanalSlider"
import PopupTitle from "../../../common/popupTitle/PopupTitle"
import { setOpeningBackground } from "../../../../lib/api"
import BoardBgColorSelectionPreview from "../../BoardBgImageSelection"
import { Dispatch } from "redux"
import { setBoardBackgroundColor } from "../../../../redux/actions/opening/action"
import { PHOTOS_UNSPLASH, SOCKET_CLIENT_ID } from "../../../../constant"

const ImagePalette = (props: ColorPaletteProps) => {
    const setBordBackgroundColor = (selectedColor: any) => {
        const req = {
            id: props.currentOpening.id,
            color: selectedColor,
            tenant_id: props.applicationId,
            socket_client_id: sessionStorage.getItem(SOCKET_CLIENT_ID),
        }
        setOpeningBackground(req).then((opening: any) => {
            props.updateBoardBackground(opening)
        })
    }
    return (
        <React.Fragment>
            <div className="">
                <PopupTitle
                    PopupTitle={PHOTOS_UNSPLASH}
                    font="text-base font-medium text-black"
                    back={props.prev}
                    close={props.close}
                />
            </div>
            <BoardBgColorSelectionPreview
                click={(color: any) => setBordBackgroundColor(color)}
            />
        </React.Fragment>
    )
}
const mapStateToProps = (state: any) => ({
    currentOpening: state.opening.currentOpening,
    socketClientID: state.opening.clientId,
    applicationId: state.opening.applicationId,
})
const mapPropsToState = (dispatch: Dispatch) => ({
    updateBoardBackground: (opening: any) =>
        dispatch(setBoardBackgroundColor(opening)),
})
export default connect(mapStateToProps, mapPropsToState)(ImagePalette)
