import React, { useState, useEffect } from "react"
import { Select, Space, Typography } from "antd"
import { CloseOutlined } from '@ant-design/icons';
import TacitbaseAvtar from "../../ant/Avtar"
import { Member } from "../../type/type"
const { Text } = Typography
type AddManagertype = {
    members: Member[],
    onManagerChange: (value: any) => void
}

const AddManager = (props: AddManagertype) => {
    const [options, setOptions] = useState<{
        label: JSX.Element,
        value: string
    }[]>([])
    const { members } = props
    const options1: {
        label: JSX.Element,
        value: string
    }[] = members.map((member: Member) => ({
        label: (
            <>
                <div key={member.id}>
                    <Space>
                        {member.image_url ? <TacitbaseAvtar size={"default"} src={member.image_url} />
                            : <TacitbaseAvtar size={"default"} content={member.first_name[0] + member.last_name[0]} />
                        }
                        <div className="flex flex-col">
                            <Text strong>{member.username}</Text>
                            <div className="text-xs">{member.email}</div>
                        </div>
                    </Space>
                </div>
            </>
        ),
        value: member.id.toString()
    }))

    useEffect(() => {
        setOptions(options1)
    }, [])

    const handleSearch = (inputValue: any) => {
        const filtered = props.members.filter(
            (member: any) =>
                member.username.toLowerCase().includes(inputValue.toLowerCase()) ||
                member.first_name.toLowerCase().includes(inputValue.toLowerCase()) ||
                member.last_name.toLowerCase().includes(inputValue.toLowerCase()) ||
                member.email.toLowerCase().includes(inputValue.toLowerCase())
        )

        const updatedOptions: any = filtered.map((member: any) => ({
            label: (
                <div key={member.id}>
                    <Space>
                        {member.image_url ? <TacitbaseAvtar size={"default"} src={member.image_url} />
                            : <TacitbaseAvtar size={"default"} content={member.first_name[0] + member.last_name[0]} />
                        }
                        <div>
                            <div>{member.username}</div>
                            <div>{member.email}</div>
                        </div>
                    </Space>
                </div>
            ),
            value: member.id.toString()
        }))

        setOptions(() => updatedOptions)
    }

    const handleChange = (value: any) => {
        props.onManagerChange(value)
    }
    return (
        <Space style={{ width: "100%" }} direction="vertical">
            <Select
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder="Search member"
                onChange={handleChange}
                onSearch={handleSearch}
                options={options}
                showSearch
                onBlur={() => setOptions(() => options1)}
                filterOption={false}
                tagRender={(props) => {
                    const { value, closable, onClose } = props
                    const member: Member | undefined = members.find((m: Member) => m.id.toString() === value)
                    if (member) {
                        return (
                            <div className={`ant-select-selection-item ${closable ? "" : ""}`}>
                                <div className="ant-select-selection-item-content">
                                    {member.username}

                                </div>
                                {closable && (
                                    <span onClick={(e: any) => onClose(e)} tabIndex={0} role="button" onKeyDown={() => { }}>
                                        <CloseOutlined />
                                    </span>
                                )}
                            </div>
                        )
                    } else return <></>
                }}
            />
        </Space>
    )
}
export default AddManager
