
import { Tag, Typography } from "antd";
import React  from "react";
import { ResumeAnalysis } from "../../type/type";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
const { Text } = Typography;

type CandidateDetailAiScoreProps = {
  candidateAiData: ResumeAnalysis | null | undefined;
}
const CandidateDetailAiScore = (props:CandidateDetailAiScoreProps) => {
  if(!props.candidateAiData)return null;
  const isIrrelevant = props.candidateAiData?.remark.toLowerCase() === 'irrelevant';
    const style = {
      color: isIrrelevant ? '#F5222D' : '#009D79',
      backgroundColor: isIrrelevant ? '#FFF1F0' : '#E3FFF9',
      border: `1px solid ${isIrrelevant ? '#FFA39E' : '#37C8A6'}`
    };
  return (
    <div>
      <div className="mt-2">
        <Text className=" text-lg font-semibold">Resume Analysis</Text>
      </div>
      <div className="flex mt-4 flex-col">
        <div className="flex items-center gap-4">
          <div>
           <Tag style={style}>{capitalizeFirstLetter(props.candidateAiData.remark)}</Tag>
          </div>
        </div>
        <div className=" mt-4">
          <div>
            <Text className="font-semibold">{`Why ${props.candidateAiData.remark} ?`}</Text>
          </div>
          <div>
              <Text type="secondary">
               {props.candidateAiData.summary}
              </Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CandidateDetailAiScore;
