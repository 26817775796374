import React from "react";
import { Checkbox, Typography } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { jdCreationObject } from "./Types";
const { Text } = Typography;
type HideJobPostProps = {
  handleHideStatus: (value: boolean) => void;
  data: jdCreationObject;
};
const HideJobPost = (props: HideJobPostProps) => {
  const handleHideFromCareerPageChange = (e: CheckboxChangeEvent) => {
    const value = e.target.checked;
    props.handleHideStatus(value);
  };
  return (
    <>
      <div className="mt-4">
        <Text style={{ fontSize: "14px" }}>
          Internal/confidential job posting?
        </Text>
      </div>
      <div className="flex gap-2 mt-1">
        <Checkbox
          checked={props.data.hide}
          onChange={handleHideFromCareerPageChange}
        />
        <div>Hide this job from career page.</div>
      </div>
    </>
  );
};

export default HideJobPost;
