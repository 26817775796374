import React from "react";
import Portal from "../../portal/Portal";
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";
type ConfirmPromptProps = {
  cancel?: () => void;
  confirm: () => void;
  statement: string;
  buttonName?: string;
};
const ConfirmPrompt = (props: ConfirmPromptProps) => {
  return (
    <>
      <Portal>
        <>
          <div className="justify-center bg-black bg-opacity-50 items-center flex fixed inset-0  outline-none focus:outline-none">
            <div className="flex items-center rounded relative w-96 h-full justify-center overflow-y-scroll z-20">
              <div className={`bg-gray-100 z-30 rounded p-2`}>
                <div className="text-sm mx-1">
                  {capitalizeFirstLetter(props.statement)}
                </div>
                <div className="flex justify-end mt-2">
                  {props.cancel && <button
                    onClick={props.cancel}
                    className="bg-red-700 text-xs text-white active:ring-red-900  active:ring-2   active:bg-gray-10  rounded tracking-wider m-1 py-1 px-2 focus:outline-none outline-none "
                  >
                    {" "}
                    {capitalizeFirstLetter("cancel")}
                  </button>}
                  {props.confirm && <button
                    onClick={props.confirm}
                    className="submit-btn text-xs"
                  >
                    {capitalizeFirstLetter(
                      `${props.buttonName ? props.buttonName : "confirm"}`
                    )}
                  </button>}

                </div>
              </div>
            </div>
          </div>
        </>
      </Portal>
    </>
  );
};

export default ConfirmPrompt;
