import Locations from "./location.json"
export interface Location {
    city: string,
    state: string,
    country: string,
    location: string,
}
/*
 * Filters the list of locations based on a search text and returns up to 5 matching results.
*/

/* This function performs the following steps:
* 1. Checks if the search text is empty. If it is, returns an empty array to indicate no initial options.
* 2. Converts the search text to lowercase for case-insensitive comparison.
* 3. Filters the `Locations` array to include only those locations where the city name contains the search text.
* 4. Limits the results to the first 5 matching locations using `slice(0, 5)`.
* 5. Returns the filtered array of locations.
*/
export const filterLocations = (searchText: string): Location[] => {
    if (!searchText) {
        return [] // No initial options
    }

    const filtered = Locations.filter((location: Location) =>
        location.city.toLowerCase().includes(searchText.toLowerCase())
    ).slice(0, 5) // Get the first 5 matching results

    return filtered
}
