import React from "react";
const CloseIcon = (props: any) => {
  return (
    <svg
      width={props.size ? props.size : "16px"}
      height={props.size ? props.size : "16px"}
      viewBox="0 0 12 12"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Close</title>
      <g
        id="Icons-/-12x12-/-No"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M9.93198052,1.38015152 L10.7805087,2.22867966 L6.8946039,6.11315152 L10.6474134,9.86543289 L9.79888527,10.713961 L6.0466039,6.96115152 L2.29522728,10.713961 L1.44669914,9.86543289 L5.1986039,6.11315152 L1.3136039,2.22867966 L2.16213203,1.38015152 L6.0466039,5.26515152 L9.93198052,1.38015152 Z"
          id="Combined-Shape"
          fill={`${props.fill ? props.fill : "#000000"}`}
        ></path>
      </g>
    </svg>
  );
};
export default CloseIcon;
