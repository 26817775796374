import React, { useState } from "react";
import { usePopper } from "react-popper";
import { Scope } from "../../../type/type.d";
import { Overlay } from "../../hook/Overlay";
// import Portal from "../../portal/Portal";
type EllipsButtonPopupType = {
  children: any;
  setOpen: () => void;
  openPopup: boolean;
  refer:any
};
const EllipsButtonPopup = (props: EllipsButtonPopupType) => {
  let [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>();
  let [popperElement, setPoperElement] = useState<HTMLDivElement | null>();
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "right",
    modifiers: [
      {
        name: "arrow",
        options: {
          padding: 5,
        },
      },
    ],
  });

  return (
    <>
      <div
        ref={setReferenceElement}
        role="button"
        tabIndex={0}
        data-testid="ellipsButton"
        className="flex h-full focus:outline-none"
        onKeyDown={() => {}}
        onClick={props.setOpen}
      >
        <div className="h-full">
          <i
            className={`fa fa-ellipsis-v  ${
              props.openPopup ? "bg-gray-300 rounded-default" : null
            } flex justify-center p-1`}
            aria-hidden="true"
          ></i>
        </div>
      </div>
      {props.openPopup && (
        <Overlay scope={Scope.ChildPopover} onClose={props.setOpen} reference={props.refer}>
          {/* <Portal> */}
            <div
              ref={setPoperElement}
              className="arrow"
              data-testid="children"
              style={styles.popper}
              {...attributes.popper}
            >
              {props.children}
            </div>
          {/* </Portal> */}
        </Overlay>
      )}
    </>
  );
};
export default EllipsButtonPopup;
