import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { MEMBER_ONLY, PUBLIC, SOCKET_CLIENT_ID } from "../../../constant";
import { changeBoardVisibility } from "../../../lib/api";
import { updateBoardVisibility } from "../../../redux/actions/opening/action";
import {
  Opening,
  OpeningMembers,
  orgMember,
  Scope,
} from "../../../type/type.d";
import ConfirmPrompt from "../../common/confirmPrompt/ConfirmPrompt";
import { Overlay } from "../../hook/Overlay";
type ShowConfirmationPopupProps = {
  currentOpening: Opening;
  close: () => void;
  updateBoardVisibility: (board: Opening) => void;
  openingMembers: OpeningMembers;
  members: orgMember;
  clientId: string
};

const ShowConfirmationPopup = (props: ShowConfirmationPopupProps) => {
  let ids = props.openingMembers[props.currentOpening.id].map((i) => i.id);
  let clientId=sessionStorage.getItem(SOCKET_CLIENT_ID)
  let req = {
    id: props.currentOpening.id,
    name: "openings",
    parent_resource_: "",
    members: ids,
    member_only: props.currentOpening.member_only ? false : true,
    socket_client_id: clientId?clientId:"",
  };
  const confirm = () => {
    changeBoardVisibility(req)
      .then((board: any) => {
        props.updateBoardVisibility(board);
        props.close();
      })
     
  };
  return (
    <>
      <Overlay onClose={props.close} scope={Scope.ConfirmationPopup}>
        <ConfirmPrompt
          statement={`are you sure? you are about to change ${
            props.currentOpening.member_only ? MEMBER_ONLY : PUBLIC
          } board to ${
            props.currentOpening.member_only ? PUBLIC : MEMBER_ONLY
          }`}
          cancel={props.close}
          confirm={confirm}
        />
      </Overlay>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
  openingMembers: state.opening.openingMembers,
  members: state.opening.members,
  clientId: state.opening.clientId,
});
const mapPropsToState = (dispatch: Dispatch) => ({
  updateBoardVisibility: (board: Opening) =>
    dispatch(updateBoardVisibility(board)),
});
export default connect(mapStateToProps, mapPropsToState)(ShowConfirmationPopup);
