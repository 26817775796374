import { OutgoingInvitations } from "../../type/type";

export function init_invitaions(state: any, action: any) {
  return {
    ...state,
    outgoingInvitations: action.payload,
  };
}
export function init_incoming_invitaions(state: any, action: any) {
  return {
    ...state,
    incomingInvitations: action.payload,
  };
}

export function add_invitaions(state: any, action: any) {
  return {
    ...state,
    outgoingInvitations: state.outgoingInvitations
      ? [...state.outgoingInvitations, action.payload]
      : [action.payload],
  };
}
export function withdraw_invitaions(state: any, action: any) {
 
  return {
    ...state,
    outgoingInvitations: state.outgoingInvitations
    ? state.outgoingInvitations.filter((invitation:OutgoingInvitations) => invitation.id !== action.payload.id)
    : [],
  };
}
export function add_incoming_invitaions(state: any, action: any) {
  return {
    ...state,
    incomingInvitations: state.incomingInvitations
      ? [...state.incomingInvitations, action.payload]
      : [action.payload],
  };
}