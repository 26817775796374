import React from "react";
import * as constant from "../../../../constant";
import { archieveUnArchiveApplicant } from "../../../../lib/api";
import {
  sendBackArchivedItem,
  updateApplicantArchiveStatus,
} from "../../../../redux/actions/opening/action";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { Applicant } from "../../../../type/type";
import { SecondaryButton } from "../../../../ant/Button";
import { throwSuccess } from "../../../../service/throwError";
import { ArrowUpOutlined, DeleteOutlined } from "@ant-design/icons";

export type ArchiveCardProps = {
  currentApplicant: Applicant;
  archiveUnArchive(applicantID: string): void;
  sendBackArchivedApplicant(archivedApplicant: any): void;
};
const ArchiveCard = (props: ArchiveCardProps) => {
  const isArchive = (list: string) => {
    const req = {
      id: props.currentApplicant.id,
      archive: true,
      socket_client_id: sessionStorage.getItem(constant.SOCKET_CLIENT_ID),
    };
    if (list === "Archive")
      archieveUnArchiveApplicant(req).then((applicant: any) => {
        props.archiveUnArchive(applicant);
        throwSuccess("Archive Successfully");
      });
  };
  const sendBackToBoard = (archivedItem: any) => {
    const req = {
      id: archivedItem.id,
      archive: false,
      socket_client_id: sessionStorage.getItem(constant.SOCKET_CLIENT_ID),
    };

    archieveUnArchiveApplicant(req).then((res: any) => {
      props.sendBackArchivedApplicant(res);
      throwSuccess("Send to Tracker Successfully");
    });
  };
  return (
    <>
      {props.currentApplicant ? (
        props.currentApplicant.archive ? (
          <>
            <SecondaryButton
              className="flex w-full truncate justify-start"
              onClick={() => {
                sendBackToBoard(props.currentApplicant);
              }}
              icon={<ArrowUpOutlined />}
            >
              Send to tracker
            </SecondaryButton>
          </>
        ) : (
          <SecondaryButton
            className="flex w-full truncate justify-start"
            onClick={() => isArchive("Archive")}
            icon={<DeleteOutlined />}
          >
            Archive
          </SecondaryButton>
        )
      ) : null}
    </>
  );
};
const mapStateToProps = (state: any) => ({
  currentApplicant: state.opening.currentApplicant,
});

const mapPropsToState = (dispatch: Dispatch) => ({
  archiveUnArchive: (applicantID: string) =>
    dispatch(updateApplicantArchiveStatus(applicantID)),
  sendBackArchivedApplicant: (archivedApplicant: any) =>
    dispatch(sendBackArchivedItem(archivedApplicant)),
});
export default connect(mapStateToProps, mapPropsToState)(ArchiveCard);
