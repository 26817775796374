import { billingMember } from "../../constant";
import { Member, openingMemberType, Product } from "../../type/type";

export function init_recent_invoice(state: any, action: any) {
  return {
    ...state,
    billingUser: {
      ...state.billingUser,
      recentInvoice: action.data,
    },
  };
}

export function init_payment_methods(state: any, action: any) {
  return {
    ...state,
    billingUser: {
      ...state.billingUser,
      paymentMethods: action.data,
    },
  };
}

export function update_default_method(state: any, action: any) {
  return {
    ...state,
    billingUser: {
      ...state.billingUser,
      paymentMethods: {
        ...state.billingUser.paymentMethods,
        default_payment_method_id: action.data,
      },
    },
  };
}

export function add_payment_method(state: any) {
  return {
    ...state,
    billingUser: {
      ...state.billingUser,
      // paymentMethods:{...state.billingUser.paymentMethods,default_payment_method_id:action.data}
    },
  };
}

export function add_block_status(state: any, action: any) {
  return {
    ...state,
    billingUser: {
      ...state.billingUser,
      blockFromCardCreation: action.data,
    },
  };
}

export function init_billing_opa_member(state: any, action: any) {
  var billingMembers: any[] = [];
  action.data.map((member: Member) => {
    billingMembers.push({ id: member.id, access: member.access });
  });
  return {
    ...state,
    servicesMembership: {
      ...state.servicesMembership,
      [billingMember]: billingMembers,
    },
  };
}

export function add_current_plans (state: any, action: any){
  let currentPlans:Product[] = [];
  action.data.forEach((product: Product) => {
    currentPlans.push(product);
  });
  return{
    ...state,
    currentPlans:currentPlans
  }
}

export function add_member_in_billing(state: any, action: any) {
  var billingMembers: openingMemberType[] = [];
  action.data.map((member: Member) => {
    billingMembers.push({
      id: member.id,
      access: member.access,
    });
  });
  return {
    ...state,
    servicesMembership: {
      ...state.servicesMembership,
      [billingMember]: state.servicesMembership[billingMember]
        ? [...state.servicesMembership[billingMember], ...billingMembers]
        : billingMembers,
    },
  };
}


export function update_billing_member_role(state: any, action: any) {
  return {
    ...state,
    servicesMembership: {
      ...state.servicesMembership,
      billingMember: state.servicesMembership.billingMember ? state.servicesMembership.billingMember.map((m: any) => m.id == action.memberId ? {
        ...m,
        access: action.access
      } : m) : []
    }
  }
}

export function remove_billing_member(state: any, action: any) {
  return {
    ...state,
    servicesMembership: {
      ...state.servicesMembership,
      billingMember: state.servicesMembership.billingMember ? state.servicesMembership.billingMember.filter((m: any) => m.id != action.memberId) : []
    }
  }
}



// Adding Billing customer id
export function add_customer_status(state: any, action: any) {
  
  return {
    ...state,
    billingCustomer: {
      ...state.billingCustomer,
      status: action.data,
    },
  };
}

export function store_customer_selection_of_plan(state: any, action: any) {
  return {
    ...state,
    billingCustomer: {
      ...state.billingCustomer,
      selectedPlanObj: action.data,
    },
  };
}
// update status
export function update_customer_status(state: any, action: any) {

  return {
    ...state,
    billingCustomer: {
      ...state.billingCustomer,
      status: {
        ...state.billingCustomer.status,
       
          setup: {
            ...state.billingCustomer.status?.setup,
            [action.data.field]:action.data.value,
          
        },
      },
    },
  };
}