import React, {  useRef, useState } from "react";
import { usePopper } from "react-popper";
import ArrowDownIcon from "../../../assets/ArrowDown";
import { customRoles } from "../../../constant";
import { Scope } from "../../../type/type.d";
import { Overlay } from "../../hook/Overlay";
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";
type DropdownWithDescProp = {
  selectedRole: string;
  selectedItem: (item: string) => void;
  options: customRoles[];
  width?: string;
  refer?: any;
};
const DropdownWithDesc = (props: DropdownWithDescProp) => {
  const [isRoleOpen, openRolePopup] = useState(false);
  const refer=useRef(null);
  const [referenceElement, setReferenceElement] = useState<
    HTMLButtonElement | HTMLDivElement | null
  >(null);
  let [popperElement, setPoperElement] = useState<HTMLDivElement | null>();
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start",
    modifiers: [
      {
        name: "flip",
        options: {
          fallbackPlacements: ["top", "top-start"],
        },
      },
    ],
  });
  const openPopup = (e: any) => {
    e.preventDefault();
    openRolePopup(!isRoleOpen);
  };
  const handleDropdown = (column: string) => {
    openRolePopup(!isRoleOpen);
    props.selectedItem(column);
  };
  return (
    <>
      <button
        ref={setReferenceElement}
        onClick={openPopup}
        className={`cursor-pointer flex p-1 justify-between text-xs items-center capitalize outline-none inputCSS rounded  ${
          props.width ? props.width : "w-32"
        }  bg-opacity-20`}
        data-testid="selectBoard"
      >
        <div>{capitalizeFirstLetter(props.selectedRole)}</div>
        <div className="">
          <ArrowDownIcon />{" "}
        </div>
      </button>
      {isRoleOpen ? (
        <Overlay
          onClose={() => openRolePopup(!isRoleOpen)}
          scope={Scope.ChildPopover}
          reference={refer}
        >
          <div
            ref={setPoperElement}
            style={styles.popper}
            {...attributes.popper}
            className="z-20 border p-1 border-tacitbase-tertiary1 border-opacity-25 w-60 mt-1 overflow-y-scroll max-h-80 origin-top-right rounded-default bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          >
            <div ref={refer}>
            {props.options.map((column: any, index: number) => (
              <div
                className="mb-1 px-1 py-1 hover:bg-gray-100 rounded-default"
                key={index}
                onClick={() => handleDropdown(column.name)}
                role="button"
                data-testid={column.name}
                tabIndex={-1}
                onKeyDown={() => {}}
              >
                <div className="text-xs font-medium">{column.name}</div>
                <div className="text-xs font-light "> {column.description}</div>
              </div>
            ))}
            </div>
          
          </div>
        </Overlay>
      ) : null}
    </>
  );
};
export default DropdownWithDesc;
