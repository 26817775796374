import React from 'react'
import ReactQuill, { ReactQuillProps, UnprivilegedEditor } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { DeltaStatic, Sources } from 'quill';

interface EditorProps extends ReactQuillProps {
    initialValue?: string,
    onChange(content: string, delta: any, source: string, editor: any): void,
    modules?: Record<string, any>,
    formats?: string[],
    // value: string

}

const Editor: React.FC<EditorProps> = ({ initialValue = '', onChange, modules, formats, ...rest }) => {
    const handleChange = (content: string, delta: DeltaStatic, source: Sources, editor: UnprivilegedEditor) => {
        if (onChange) {
            onChange(content, delta, source, editor)
        }
    }

    return (
        <ReactQuill
            theme="snow"
            value={initialValue}
            onChange={handleChange}
            {...rest}
            className=""
            modules={modules}
            formats={formats}
        />

    )
}

export default Editor
