import React, { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { SELECT_DESTINATION, SOCKET_CLIENT_ID } from "../../../constant";
import { getListByListIDs, getOpenings, moveApplicant } from "../../../lib/api";
import { move } from "../../../redux/actions/opening/action";
import { currentApplicant, JobBoard, Opening, OrganizedBoard } from "../../../type/type"
import SelectPosition from "../copyCard/SelectPosition";
import PopupTitle from "../../common/popupTitle/PopupTitle";
import { useHistory } from "react-router";
type MoveCardPopupProps = {
  click: (e: boolean) => void;
  openings: OrganizedBoard;
  currentApplicant: currentApplicant;
  currentOpening: Opening;
  move(
    applicant: any,
    currentListId: any,
    selectedApplicantPosition: any
  ): void;
  refer: any;

  boardList: any;
  clientId: any;
  applicationId: string;
  name: string;
};
const MoveCardPopup = (props: MoveCardPopupProps) => {
  const history = useHistory();
  const applicantType: any[] = [];
  let result: any[] = [];
  result = Object.values(props.boardList);
  let indexOfcurrentApplicant: number;
  indexOfcurrentApplicant = props.boardList[props.currentApplicant.list_id]
    .applicants
    ? props.boardList[props.currentApplicant.list_id].applicants
        .map((item: any) => item.id)
        .indexOf(props.currentApplicant.id)
    : 0;
  const [lists, setLists] = useState(result);
  const [openings, setOpenings] = useState<Opening[]>([])
  const [companyOptions, setCompanyOptions] = useState<Record<string, JobBoard>>({})
  const [selectedCompany, setSelectedCompany] = useState(props.currentOpening.company_id)
  const [selectedApplicant, setSelectedApplicant] = useState(
    indexOfcurrentApplicant.toString()
  );
  const [listID, setListID] = useState(props.currentApplicant.list_id);
  const [openingID, setOpeningID] = useState(props.currentOpening.id);
  const [applicants, setApplicants] = useState(applicantType);

  useEffect(() => {
    getOpenings()
      .then((openings: any) => {
        openings = openings ? openings : [];
        const companyOptionsObject: Record<string, JobBoard> = {}
        openings.forEach((company: JobBoard) => {
          companyOptionsObject[company.company_id] = company
        })
        setCompanyOptions(companyOptionsObject)
      })
     
  }, []);

  useEffect(() => {
    var index = lists.map((item: any) => item.id).indexOf(listID);
    var applicants: any = lists[index].applicants
      ? lists[index].applicants
      : [];
    setApplicants(applicants);
    setSelectedApplicant("0");
  }, [listID]);
  useEffect(() => {

    if (selectedCompany) {
      // Access the selected company data directly from the object
      const selectedCompanyData = companyOptions[selectedCompany]

      if (selectedCompanyData) {
        // Extract the boards associated with the selected company
        const boards = selectedCompanyData.boards || []

        setOpenings(boards)
      }
    }
  }, [selectedCompany, companyOptions]);
  useEffect(() => {
    getListByListIDs(openingID)
      .then((lists_with_applicants: any) => {
        setLists(lists_with_applicants);
        if (!lists_with_applicants) {
          setApplicants([]);
        } else {
          setLists(lists_with_applicants);
          if (props.currentOpening.id != openingID) {
            setListID(lists_with_applicants[0].id);
            setApplicants(lists_with_applicants[0].applicants);
          }
        }
      })
     
  }, [openingID]);

  useEffect(() => {
    var index = lists.map((item: any) => item.id).indexOf(listID);
    setApplicants(lists[index].applicants);
  }, []);
  const getBoardLists = (e: any) => {
    setOpeningID(e.target.value);
  };
  const move = () => {
    if (lists) {
      var previous_Applicant_id;
      previous_Applicant_id = "00000000-0000-0000-0000-000000000000";
      if (openingID == props.currentOpening.id) {
        if (parseInt(selectedApplicant) > parseInt("0")) {
          previous_Applicant_id =
            applicants[parseInt(selectedApplicant) - 1].id;
        }
      }
      if (openingID !== props.currentOpening.id) {
        if (parseInt(selectedApplicant) > parseInt("0")) {
          previous_Applicant_id =
            applicants[parseInt(selectedApplicant) - 1].id;
        }
      }
      const req = {
        src_applicant: {
          id: props.currentApplicant.id,
          opening_id: props.currentOpening.id,
          list_id: props.currentApplicant.list_id,
        },
        dst_applicant: {
          id: previous_Applicant_id,
          opening_id: openingID,
          list_id: listID,
          position: parseInt(selectedApplicant)
        },
        socket_client_id:sessionStorage.getItem(SOCKET_CLIENT_ID),
        position: selectedApplicant,
      };
      moveApplicant(req).then((res: any) => {
        props.move(res, props.currentApplicant.list_id, selectedApplicant);
        if (props.currentOpening.id !== openingID) {
          history.push({
            pathname: `/o/${props.applicationId}/b/${props.currentOpening.id}/tracker`,
          });
        }
        props.click(false);
      });
    }
  };
  return (
    <React.Fragment>
      <div
        className="flex flex-col h-auto mt-1 z-10 w-72 bg-white text-gray-800 tracking-normal border border-gray-300  text-sm font-normal rounded"
        ref={props.refer}
      >
        <div className="my-3">
          <PopupTitle PopupTitle={props.name} close={props.click} />
        </div>
        <div className="m-2">
          <span>{SELECT_DESTINATION}</span>
          <div className="bg-gray-200 mb-2 rounded hover:bg-gray-200 p-2 bg-opacity-60 w-full">
            <div className="text-xs">Company</div>
            <select
              className="appearance-none cursor-pointer outline-none w-full bg-gray-200 bg-opacity-20"
              onBlur={(e: any) => setSelectedCompany(e.target.value)}
              onChange={(e: any) => setSelectedCompany(e.target.value)}
              data-testid="selectBoard"
            >
              {Object.keys(companyOptions).map((companyId) => (
                <option key={companyId} value={companyId}
                  selected={companyId === props.currentOpening.company_id}
                >
                  {companyOptions[companyId].company_name}
                </option>
              ))}
            </select>
          </div>
          <div className="bg-gray-200 mb-2 rounded hover:bg-gray-200 p-2 bg-opacity-60 w-full">
            <div className="text-xs">Board</div>
            <select
              data-testid="selectTestBtn"
              className="appearance-none cursor-pointer outline-none w-full bg-gray-200 bg-opacity-20"
              onBlur={getBoardLists}
              onChange={getBoardLists}
            >
              {openings.map((opening: any, index: number) => (
                <option
                  key={index}
                  className="w-full"
                  value={opening.id}
                  defaultValue={opening.id}
                  selected={opening.id === props.currentOpening.id}
                >
                  {opening.name}
                </option>
              ))}
            </select>
          </div>

          <div className="mb-2 flex w-full">
            <div className="mr-4 rounded p-2 bg-gray-200 w-full bg-opacity-60 hover:bg-gray-200">
              <div className="text-xs">List</div>
              {lists ? (
                <select
                  className="appearance-none cursor-pointer outline-none w-full bg-gray-200 bg-opacity-20"
                  onBlur={(e) => setListID(e.target.value)}
                  onChange={(e) => setListID(e.target.value)}
                  data-testid="listTestBtn"
                >
                  {lists.map((list: any, index: number) => (
                    <option
                      key={index}
                      className="w-full"
                      value={list.id}
                      selected={props.currentApplicant.list_id === list.id}
                    >
                      {list.name}
                    </option>
                  ))}
                </select>
              ) : (
                <div className="w-full">No lists</div>
              )}
            </div>
            <div className="p-2 rounded bg-gray-200 bg-opacity-60 hover:bg-gray-200">
              {applicants.length > 0 ? (
                <SelectPosition
                  label="Position"
                  value={applicants}
                  event={(e: any) => setSelectedApplicant(e)}
                  comparedId={props.currentApplicant.id}
                  move={true}
                  extraIndexCheck={props.currentApplicant.list_id}
                  sourceId={listID}
                />
              ) : (
                <>
                  <div className="text-xs">Position</div>
                  <div className="w-full">{lists ? 1 : "N/A"}</div>
                </>
              )}
            </div>
          </div>

          <button
            className="submit-btn text-sm"
            onClick={move}
            disabled={lists ? false : true}
            data-testid="move-card"
          >
            <span className="text-xs">Move</span>
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (state: any) => ({
  openings: state.opening.openings,
  currentOpening: state.opening.currentOpening,
  currentApplicant: state.opening.currentApplicant,
  boardList: state.opening.boardList,
  clientId: state.opening.clientId,
  applicationId: state.opening.applicationId,
});

const mapPropsToState = (dispatch: Dispatch) => ({
  move: (applicant: any, currentListId: any, selectedApplicantPosition: any) =>
    dispatch(move(applicant, currentListId, selectedApplicantPosition)),
});
export default connect(mapStateToProps, mapPropsToState)(MoveCardPopup);
