import { useEffect, useState } from "react";

export const useOrgMetadata = (currentOrg: any) => {
    const [orgMetadata, setOrgMetadata] = useState<any>();
  
    useEffect(() => {
      if (currentOrg) {
        setOrgMetadata(currentOrg?.customer?.metadata);
      }
    }, [currentOrg]);
  
    return orgMetadata;
  };
  
