export function waitForSocketConnection(
  socket: WebSocket,
  callback: Function
) {
  setTimeout(function () {
    if (socket.readyState === 1) {
      if (callback != null) {
        callback();
      }
    } else {
      waitForSocketConnection(socket, callback);
    }
  }, 1000);
}
