import React from "react";
const SetImgIcon = () => {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Set Image</title>
      <g
        id="Icons-/-16x16-/-Set-Image"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M14,1 C15.1045695,1 16,1.8954305 16,3 L16,13 C16,14.1045695 15.1045695,15 14,15 L2,15 C0.8954305,15 1.3527075e-16,14.1045695 0,13 L0,3 C-1.3527075e-16,1.8954305 0.8954305,1 2,1 L14,1 Z M5,8 L1,10.4 L1,13 C1,13.5128358 1.38604019,13.9355072 1.88337887,13.9932723 L2,14 L14,14 C14.5128358,14 14.9355072,13.6139598 14.9932723,13.1166211 L15,13 L15,11.4 L11,9 L9.516,9.742 L12,11.25 L11.5,12 L5,8 Z M14,2 L2,2 C1.48716416,2 1.06449284,2.38604019 1.00672773,2.88337887 L1,3 L1,9.4 L5,7 L8.613,9.194 L11,8 L15,10.4 L15,3 C15,2.44771525 14.5522847,2 14,2 Z M9,4 C9.82842712,4 10.5,4.67157288 10.5,5.5 C10.5,6.32842712 9.82842712,7 9,7 C8.17157288,7 7.5,6.32842712 7.5,5.5 C7.5,4.67157288 8.17157288,4 9,4 Z"
          id="Combined-Shape"
          fill="#161E25"
        ></path>
      </g>
    </svg>
  );
};
export default SetImgIcon;
