import React from "react";
import { Popconfirm, Typography } from "antd";
import { ExportOutlined } from "@ant-design/icons";
import { exportCsv } from "../../../lib/api";
import { IconButton } from "../../../ant/Button";
import throwError from "../../../service/throwError";
const { Text } = Typography;
type MoreActionProps = {
  selectedCandidates?: string[];
  JdId?: string;
  isSelected: boolean;
  isCDB?: boolean;
};
const MoreAction = (props: MoreActionProps) => {

  const handleExportCsv = () => {
     // If user try to export cdb candidates, take only the first 100 candidates
    const candidatesToExport = props.isCDB ? props.selectedCandidates?.slice(0, 100) : props.selectedCandidates;
    return exportCsv(props.isSelected, candidatesToExport, props.JdId).catch(error => {
      throwError(error);
    });
  };
  const content = (
    <div className="w-52">
      <Text>We will send you a download link after the export is completed</Text>
    </div>
  );
  return (
    <Popconfirm
      title="Export CSV"
      description={content}
      okText="Export CSV"
      cancelText="Cancel"
      onConfirm={handleExportCsv}
    >
      <IconButton tooltipTitle="Export CSV" icon={<ExportOutlined />} />
    </Popconfirm>
  );
};

export default MoreAction;
