export function get_card_rewards(state: any, action: any) {
  return {
    ...state,
    currentApplicantRewards: {
      ...state.currentApplicantRewards,
      [action.cardID]: action.data,
    },
  };
}

export function update_card_reward(state: any, action: any) {
  return {
    ...state,
    currentApplicantRewards: {
      ...state.currentApplicantRewards,
      [action.cardID]: state.currentApplicantRewards[action.cardID].map(
        (reward: any) =>
          reward.name === action.data ? { ...reward, active: false } : reward
      ),
    },
  };
}

export function all_tp_rewards(state: any, action: any) {
  return {
    ...state,
    allTpRewards: {
      ...state.allTpRewards,
      [action.orgId]: action.data,
    },
  };
}

export function credit_or_debit_reward(state: any, action: any) {
  return {
    ...state,
    allTpRewards: {
      ...state.allTpRewards,
      [action.orgId]: state.allTpRewards[action.orgId]
        ? [...state.allTpRewards[action.orgId], action.data]
        : [action.data],
    },
  };
}
