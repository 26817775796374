import React from "react";
const CalenderIcon = (props: any) => {
  return (
    <svg
      width={`${props.size ? props.size : "16px"}`}
      height={`${props.size ? props.size : "16px"}`}
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Icons / 16x16 / Calendar</title>
      <g
        id="Icons-/-16x16-/-Calendar"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M12.5,0 C12.7761424,-5.07265313e-17 13,0.223857625 13,0.5 L13,1 L14,1 C15.1045695,1 16,1.8954305 16,3 L16,14 C16,15.1045695 15.1045695,16 14,16 L2,16 C0.8954305,16 1.3527075e-16,15.1045695 0,14 L0,3 C-1.3527075e-16,1.8954305 0.8954305,1 2,1 L3,1 L3,0.5 C3,0.223857625 3.22385763,5.07265313e-17 3.5,0 C3.77614237,-5.07265313e-17 4,0.223857625 4,0.5 L4,1 L12,1 L12,0.5 C12,0.223857625 12.2238576,5.07265313e-17 12.5,0 Z M3,2 L2,2 C1.48716416,2 1.06449284,2.38604019 1.00672773,2.88337887 L1,3 L1,14 C1,14.5128358 1.38604019,14.9355072 1.88337887,14.9932723 L2,15 L14,15 C14.5128358,15 14.9355072,14.6139598 14.9932723,14.1166211 L15,14 L15,3 C15,2.44771525 14.5522847,2 14,2 L13,2 L13,3.5 C13,3.77614237 12.7761424,4 12.5,4 C12.2238576,4 12,3.77614237 12,3.5 L12,2 L4,2 L4,3.5 C4,3.77614237 3.77614237,4 3.5,4 C3.22385763,4 3,3.77614237 3,3.5 L3,2 Z M9,6 C10.1045695,6 11,6.8954305 11,8 L11,10 C11,11.1045695 10.1045695,12 9,12 L7,12 C5.8954305,12 5,11.1045695 5,10 L5,8 C5,6.8954305 5.8954305,6 7,6 L9,6 Z M9,7 L7,7 C6.48716416,7 6.06449284,7.38604019 6.00672773,7.88337887 L6,8 L6,10 C6,10.5128358 6.38604019,10.9355072 6.88337887,10.9932723 L7,11 L9,11 C9.51283584,11 9.93550716,10.6139598 9.99327227,10.1166211 L10,10 L10,8 C10,7.44771525 9.55228475,7 9,7 Z"
          id="hoverable-svg"
          // fill="#161E25"
        ></path>
      </g>
    </svg>
  );
};
export default CalenderIcon;
