import { Applicant, KeyWithArray, List } from "../../type/type";

export function init_list(state: any, action: any) {
  var lists: { [key: string]: List } = {};
  var applicantLabel: KeyWithArray = {};
  var applicantMembers: KeyWithArray = {};
  action.payload.map((list: any) => {
    if (!list.applicants) {
      list.applicants = [];
    }
    list.applicants.sort(function (prevCard: Applicant, nextCard: Applicant) {
      if (!prevCard.labels) prevCard.labels = [];
      if (!prevCard.members) prevCard.members = [];
      return prevCard.rank.localeCompare(nextCard.rank);
    });
    // Create an object of member and label for each applicant in the list
    list.applicants.forEach((applicant: Applicant) => {
      if (applicant.labels && applicant.labels.length > 0)
        applicantLabel[applicant.id] = applicant.labels;
      if (applicant.members && applicant.members.length > 0)
        applicantMembers[applicant.id] = applicant.members;
    });
    lists[list.id] = list;
  });
  return {
    ...state,
    boardList: lists,
    applicantLabels: applicantLabel,
    applicantMembers: applicantMembers,
  };
}

export function add_list(state: any, action: any) {
  action.data.applicants = [];
  return {
    ...state,
    boardList: {
      ...state.boardList,
      [action.data.id]: action.data,
    },
  };
}
export function drag_list(state: any, action: any) {
  const { draggableId, destination } = action.data;
  const keys = Object.keys(state.boardList);
  const i = keys.indexOf(draggableId);
  if (i > -1) {
    keys.splice(i, 1);
  }
  keys.splice(destination.index, 0, draggableId);
  let newlist: any = {};
  keys.forEach((key: string) => {
    newlist[key] = state.boardList[key];
  });
  return { ...state, boardList: newlist };
}

export function edit_list_name(state: any, action: any) {
  return {
    ...state,
    boardList: {
      ...state.boardList,
      [action.data.id]: {
        ...state.boardList[action.data.id],
        name: action.data.name,
      },
    },
  };
}

export function move_list(state: any, action: any) {
  const { data, destination } = action;
  const keys = Object.keys(state.boardList);
  const i = keys.indexOf(data.src_list.id);
  if (i > -1) {
    keys.splice(i, 1);
  }
  if (data.destination === "move_to_same_opening") {
    keys.splice(destination.index, 0, data.src_list.id);
  }
  let newlist: any = {};
  keys.forEach((key: string) => {
    newlist[key] = state.boardList[key];
  });
  return { ...state, boardList: newlist };
}

export function move_list_ws(state: any, action: any) {
  const { data, destination } = action;
  let newlist: any = {};

  const keys = Object.keys(state.boardList);
  keys.splice(destination.index, 0, data.src_list.id);
  keys.forEach((key: string) => {
    if (key === data.src_list.id) {
      newlist[key] = data.src_list;
    } else {
      newlist[key] = state.boardList[key];
    }
  });
  return { ...state, boardList: newlist };
}

export function archive_list(state: any, action: any) {
  if (!action.data.applicants) {
    action.data.applicants = [];
  }
  const keys = Object.keys(state.boardList);
  const i = keys.indexOf(action.data.id);
  if (i > -1) {
    keys.splice(i, 1);
  }
  let newlist: any = {};
  keys.forEach((key: string) => {
    newlist[key] = state.boardList[key];
  });

  return {
    ...state,
    boardList: newlist,
    archiveList: {
      ...state.archiveList,
      [action.data.opening_id]: state.archiveList[action.data.opening_id]
        ? [...state.archiveList[action.data.opening_id], action.data]
        : [action.data],
    },
    archivedApplicants: {
      ...state.archivedApplicants,
      [action.data.opening_id]: state.archivedApplicants[action.data.opening_id]
        ? [
            ...state.archivedApplicants[action.data.opening_id],
            ...action.data.applicants,
          ]
        : action.data.applicants,
    },
  };
}

export function clear_data(state: any) {
  return {
    ...state,
    boardList: {},
  };
}
export function init_archived_list(state: any, action: any) {
  return {
    ...state,
    archiveList: {
      ...state.archiveList,
      [action.openingID]: action.data ? action.data : [],
    },
  };
}

export function send_back_archived_list(state: any, action: any) {
  if (!action.data.applicants) {
    action.data.applicants = [];
  }
  return {
    ...state,
    archiveList: {
      ...state.archiveList,
      [action.data.opening_id]: state.archiveList[action.data.opening_id]
        ? state.archiveList[action.data.opening_id].filter(
            (list: any) => list.id !== action.data.id
          )
        : [],
    },
    archivedApplicants: {
      ...state.archiveApplicants,
      [action.data.opening_id]: state.archivedApplicants[action.data.opening_id]
        ? state.archivedApplicants[action.data.opening_id].filter(
            (applicant: any) => applicant.list_id !== action.data.id
          )
        : [],
    },
    boardList: {
      ...state.boardList,
      [action.data.id]: action.data,
    },
  };
}

//function for updating the analytics label object from list

export function edit_analytics_list_label_name(state: any, action: any) {
  return {
    ...state,
    boardList: {
      ...state.boardList,
      [action.data.list_id]: {
        ...state.boardList[action.data.list_id],
        label:action.data
      },
    },
  };
}
