import React from "react";
import { Modal } from "antd";
import { CANCEL_WARNING_HEADING } from "../constant";
import { ExclamationCircleOutlined } from "@ant-design/icons";

interface TacitbaseCardProps {
  isPopupVisible: boolean;
  onOkay: () => void;
  onCancel: () => void;
}

const CancelDiscard: React.FC<TacitbaseCardProps> = ({
  isPopupVisible,
  onOkay,
  onCancel,
}) => {
  return (
    <div>
      <Modal
        maskClosable={false}
        closable={false}
        visible={isPopupVisible}
        onOk={onOkay}
        onCancel={onCancel}
        okText="Yes, discard"
        cancelText="No, keep editing"
        centered
        style={{
          height: "160px",
        }}
      >
        <div
          style={{
            height: "60px",
          }}
          className="flex items-center gap-4"
        >
          <ExclamationCircleOutlined style={{ color: '#faad14', fontSize:"25px" }}  />
          <p>{CANCEL_WARNING_HEADING}</p>
        </div>
      </Modal>
    </div>
  );
};

export default CancelDiscard;
