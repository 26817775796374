import React from "react"
// import TacitbaseAvtar from "../../../ant/Avtar"
import { Avatar, Divider, Skeleton } from "antd"
import Title from "antd/es/typography/Title"
const BoardLoader = ({ active, text }: { active: boolean, text: string }) => {
    return (
        <>
            <div className="flex justify-center items-center h-full   rounded  bg-white ">
                <div className="w-80 flex  justify-center items-center flex-col ">
                    <div className="bg-gray-200 rounded border w-full">
                        <div className=" rounded-t flex-col flex justify-between h-20 p-2">
                            {/* <Skeleton.Avatar active size={64} shape="circle" className="ml-3 mt-3" /> */}
                            <Skeleton.Input active={active} size="small" />
                            <div className="flex justify-end items-end space-y-2">
                                <Skeleton.Input active={active} size="small" />
                            </div>
                        </div>

                        <div className="px-3 bg-white py-2 flex items-center justify-between">
                            <div className="flex items-center">
                                <Avatar.Group>
                                    <Skeleton.Avatar active={active} size="small" />
                                </Avatar.Group>
                                <Divider type="vertical" style={{ borderColor: "gray" }} />
                                <Avatar.Group>
                                    <Skeleton.Avatar active={active} size="small" />
                                    <Skeleton.Avatar active={active} size="small" />
                                    <Skeleton.Avatar active={active} size="small" />
                                    <Skeleton.Avatar active={active} size="small" />
                                    <Skeleton.Avatar active={active} size="small" />
                                </Avatar.Group>
                            </div>
                            <Skeleton.Avatar active={active} shape="square" size="small" />
                        </div>
                    </div>
                    <Title level={5} type="secondary">{text}</Title>
                </div>
            </div>
        </>
    )
}
export default BoardLoader
