import { Attachment,  Label, Member } from "../../type/type";
import { currentCDBactivity } from "./initialState";

export function init_current_candidate_in_ats_pool(state: any, action: any) {
  
  return {
    ...state,
    currentCandidateInPool: {
      ...state.currentCandidateInPool,
      candidate: action.candidate,
    },
  };
}

export function init_current_candidate_labels_in_ats_pool(
  state: any,
  action: any
) {var label: string[] = [];
  action.labels.map((member: Member) => {
    label.push(member.id);
  });
  return {
    ...state,
    currentCandidateInPool: {
      ...state.currentCandidateInPool,
      labels: label,
    },
    currentCDBactivity: currentCDBactivity,
    currentCDBAtsActivity: currentCDBactivity,
  };
}

export function init_candidates_ats_pool(state: any, action: any) {
  let candidates: any = {};
  action.data.map((candidate: any) => {
    if (candidate)
    candidates[candidate.id] = candidate;
  });
  return {
    ...state,
    candidatePool:
      Object.keys(state.candidatePool).length < 3
        ? { ...state.candidatePool, [action.currentpage]: candidates, currentpage: action.currentpage }
        : { [action.currentpage]: candidates, currentpage: action.currentpage },
        reviewComments: {},
    reviewAttachments: {},
  };
}

export function add_label_to_pool_candidate_in_ats(state: any, action: any) {  
  return {
    ...state,
    currentCandidateInPool: {
      ...state.currentCandidateInPool,
      labels: [...state.currentCandidateInPool.labels, action.data.id],
    },
    candidateLabels: {
      ...state.candidateLabels,
      [action.data.id]: action.data,
    },
  };
}

export function remove_label_from_pool_candidate_in_ats(
  state: any,
  action: any
) {
  return {
    ...state,
    currentCandidateInPool: {
      ...state.currentCandidateInPool,
      labels: state.currentCandidateInPool.labels
        ? state.currentCandidateInPool.labels.filter(
            (label: string) => !action.labels.includes(label)
          )
        : [],
    },
  };
}

export function add_pool_candidate_attachment(
  state: any,
  action: {
    data: Attachment[];
  }
) {
  return {
    ...state,
    currentCandidateInPool: {
      ...state.currentCandidateInPool,
      attachments: action.data,
    },
  };
}

export function add_new_pool_candidate_attachment(
  state: any,
  action: {
    data: Attachment[];
  }
) {
  return {
    ...state,
    currentCandidateInPool: {
      ...state.currentCandidateInPool,
      attachments: [
        ...state.currentCandidateInPool.attachments,
        ...action.data,
      ],
    },
  };
}

export function add_attachment_upload_status(state: any, action: any) {
  return {
    ...state,
    currentCandidateInPool: {
      ...state.currentCandidateInPool,
      newAttachmentUploader: action.data,
    },
  };
}

export function remove_label_from_pool_candidate(state: any, action: any) {
   // Create a copy of the candidateDB to avoid direct mutation
   const updatedCandidateDB = { ...state.candidatePool };

   // Iterate over each candidate ID
   action.data.forEach((candidateId: string) => {
     // Iterate through each page in the candidateDB
     Object.keys(updatedCandidateDB).forEach(pageKey => {
       const candidatesOnPage = updatedCandidateDB[pageKey];
       // Check if the candidate exists on the current page
       if (candidatesOnPage[candidateId]) {        
         // Filter out the labels specified in action.data.labels
         const updatedLabels = candidatesOnPage[candidateId].labels.filter((labelId: Label) =>           
           !action.labels.includes(labelId.id)
         );
         // Update the candidate's labels
         updatedCandidateDB[pageKey][candidateId] = {
           ...candidatesOnPage[candidateId],
           labels: updatedLabels
         };
       }
     });
   });
 
   return {
     ...state,
     candidatePool: updatedCandidateDB,
   };
  // let candidatePool: any = {};
  // action.data.map((poolLabels: any) => {
  //   candidatePool[poolLabels] = {
  //     ...state.candidatePool[poolLabels],
  //     labels: state.candidatePool[poolLabels].labels
  //       ? state.candidatePool[poolLabels].labels.filter(
  //           (label: string) => !action.labels.includes(label)
  //         )
  //       : state.candidatePool[poolLabels].labels,
  //   };
  // });

  // return {
  //   ...state,
  //   candidatePool: {
  //     ...state.candidatePool,
  //     ...candidatePool,
  //   },
  // };
}



export function add_labels_on_multiple_ats_pool_candidates(state: any, action: any) {
  // Create a copy of the candidateDB to avoid direct mutation
  const updatedCandidatePool = { ...state.candidatePool };

  // Iterate over each item in action.data
  action.data.forEach((item: { id: string, labels: any[] }) => {
    let { id, labels } = item;
    // Iterate through each page in the candidateDB
    Object.keys(updatedCandidatePool).forEach(pageKey => {
      const candidatesOnPage = updatedCandidatePool[pageKey];

      // Check if the candidate exists on the current page
      if (candidatesOnPage[id]) {      
        updatedCandidatePool[pageKey][id] = {
          ...candidatesOnPage[id],
          labels: [...new Set([...candidatesOnPage[id].labels, ...labels])]
        };
      }
    });
  });

  return {
    ...state,
    candidatePool: updatedCandidatePool,
  };
  // let candidateInAtsPool: any = {};
  // let candidateLabels: string[] = [];
  // action.data.map((reviewLabels: any) => {
  //   (candidateLabels = reviewLabels.labels.map((l: Label) => l.id)),
  //     (candidateInAtsPool[reviewLabels.id] = {
  //       ...state.candidatePool[reviewLabels.id],
  //     labels: state.candidatePool[reviewLabels.id].labels ? [
  //         ...state.candidatePool[reviewLabels.id].labels,
  //         ...candidateLabels,
  //     ] : [],
  //     });
  // });
  // return {
  //   ...state,
  //   candidatePool: {
  //     ...state.candidatePool,
  //    ...candidateInAtsPool
  //   },
  // };
}


export function add_Candidate_In_Pool(state: any, action: any) {
    // let newCandidates :any = {};
    // if (Array.isArray(action.data)) {
    //   action.data.forEach((candidate: any) => {
    //     newCandidates[candidate.id] = candidate;
    //   });
    // } else {
    //   newCandidates = { [action.data.id]: action.data };
    // }

    // return {
    //   ...state,
    //   candidatePool: { ...newCandidates, ...state.candidatePool },
    // };
    let key: string[] = Object.keys(state.candidatePool);
    let objKey = key.length > 0 ? key[0] : 1;
    return {
      ...state,
      candidatePool: {
        ...state.candidatePool,
        [objKey]: {
          [action.data.id]: action.data,
          ...state.candidatePool[objKey],
        },
      },
    };
}

export const set_resume_relevancy_name_and_count = (state: any, action: any) => {
  return {
    ...state,
  relevancyFilter: {
    name: action.payload?.name,
    count: action.payload?.count,
  }
}
};