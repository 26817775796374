export const buildFormData = (data: { key: string; value: any }[], files?: FileList): FormData => {
    const formData = new FormData();

    data.forEach(item => {
      formData.append(item.key, item.value);
    });
      if (files) {
      Array.from(files).forEach(file => {
        formData.append('file', file);
      });
    }
  
    return formData;
  };
  