import React, { useRef, useState } from "react";
import { usePopper } from "react-popper";
import { connect } from "react-redux";
import data from "@emoji-mart/data";
import { SmileTwoTone } from "@ant-design/icons";
import { Overlay } from "./hook/Overlay";
import { Scope } from "../type/type.d";
import Picker from "@emoji-mart/react";
import Portal from "./portal/Portal";

type EmojiProps = {
  onEmojiSelect: (emoji: string) => void;
  usePortal?: boolean; 
};

const Emoji = (props: EmojiProps) => {
  const refer = useRef(null);
  const [open, setOpen] = useState(false);
  const [firstClick , setFirstClick] = useState(false);
  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>();
  const [popperElement, setPoperElement] = useState<HTMLDivElement | null>();
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom",
    modifiers: [
      {
        name: "flip",
        options: {
          fallbackPlacements: ["top", "right"],
        },
      },
    ],
  });

  const handleEmojiPicker = ()=>{
    setOpen(!open)
  }
  const handleEmojiSelect = (emoji: string): void => {
    props.onEmojiSelect(emoji);
    setOpen(!open)
  };
  const handleOutsideClick = () => {
    // setIsEmoji(false);
    // console.log('handleOutsideClick')
    if(open && firstClick){
        setOpen(false);
        setFirstClick(false);
    }else{
        setFirstClick(true);
    }
}

  const renderOverlay = () => (
    <Overlay
    onClose={() => setOpen(false)}
      scope={Scope.ChildPopover}
      reference={refer}
    >
      <div
        ref={setPoperElement}
        style={styles.popper}
        {...attributes.popper}
      >
       { open && <div ref={refer} className="mt-4"   data-testid="picker"
>
         <Picker
             // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            data={data}
            onEmojiSelect={(emoji: any) => handleEmojiSelect(emoji.native)}
            onClickOutside={handleOutsideClick}
          />
        </div>}
      </div>
    </Overlay>
  );

  return (
    <>
      <button
        ref={setReferenceElement}
        data-testid="testBtn"
        onClick={handleEmojiPicker}
      >
        <SmileTwoTone />
      </button>

      {open? props.usePortal ?  <Portal>{renderOverlay()}</Portal> : renderOverlay():null}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  currentApplicant: state.opening.currentApplicant,
});

export default connect(mapStateToProps)(Emoji);