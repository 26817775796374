import React, { useEffect, useRef } from "react";
// import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter"
import CustomFormField from "../candidateDB/formBuilder/CustomFormField";

import DeleteIcon from "../../assets/DeleteIcon"
import { Draggable } from "react-beautiful-dnd"
import DragHandle from "../../assets/DragHandle"
import { REQUIRED_FIELD_OF_DATABASE } from "../../constant"
import { ExtendedTableColumn } from "./FormBuilder"
import { Form } from "antd"
type FormBuilderPanalType = {
    formField: ExtendedTableColumn[],
    selectedComponent: ExtendedTableColumn,
    handleSelectComponent: (column: any) => void,
    showPreview: () => void,
    handleClickRemove: (index: number) => void,
    preview: boolean,
    handleChangeLabel: (label: string) => void,
    handleKeyPressed: (e: any) => void,
}
const FormBuilderPanal = (props: FormBuilderPanalType) => {
    const ref = useRef<HTMLDivElement>(null)
    useEffect(() => {
        if (ref.current) {
            ref.current.scrollIntoView({ behavior: "auto" })
        }
    }, [props.formField])
    return (
        <>
            {props.formField.map((column: ExtendedTableColumn, index: number) => {
                return (
                    <Draggable draggableId={index.toString()} index={index} key={index}>
                        {(provided) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                onClick={(e: any) => {
                                    e.preventDefault(),
                                        props.handleSelectComponent({
                                            ...column,
                                            order: index + 1,
                                        })
                                }}
                                tabIndex={0}
                                onKeyDown={() => { }}
                                role="button"
                            // className={`${props.selectedComponent.order == index + 1 &&
                            //     !props.preview &&
                            //     "border border-dashed border-black  rounded"
                            //     } px-20 py-2 mx-3 mb-1 relative`}
                            >
                                <div
                                    style={props.selectedComponent.order == index + 1 &&
                                        !props.preview ? { backgroundColor: "#F0EFFE" } : { backgroundColor: "white" }}

                                    className={`${props.selectedComponent.order == index + 1 &&
                                        !props.preview &&
                                        "border border-dashed border-black  rounded"
                                        } mx-10 px-8 py-2  mb-1 relative`} ref={
                                        props.selectedComponent.order == props.formField.length
                                            ? ref
                                            : null
                                    }
                                >
                                    {props.selectedComponent.order == index + 1 && !props.preview && (
                                        <div
                                            title="Drag"
                                            className="absolute top-1/3  left-1"

                                        >
                                            <DragHandle />
                                        </div>
                                    )}
                                    <Form layout="vertical">
                                    <CustomFormField
                                        showPreview={props.preview}
                                        column={column}
                                        defaultValue={""}
                                        setValue={() => { }}
                                        handleChangeLabel={props.handleChangeLabel}
                                        handlekeyPress={(e: any) => props.handleKeyPressed(e)}
                                        onClick={() => { }}
                                        />
                                    </Form>

                                    {!REQUIRED_FIELD_OF_DATABASE.includes(props.selectedComponent.name) && props.selectedComponent.order === index + 1 &&
                                        !props.preview && (
                                            <div
                                                title="Remove"
                                            className="absolute top-1/3 -right-2 bg-white"
                                            onClick={(e: any) => {
                                                e.stopPropagation(),
                                                    e.preventDefault(),
                                                    props.handleClickRemove(index)
                                                }}
                                            tabIndex={0}
                                            data-testid="remove"
                                                onKeyDown={() => { }}
                                                role="button"
                                            >
                                                <DeleteIcon />
                                            </div>
                                        )}
                                </div>
                            </div>
                        )}
                    </Draggable>
                )
            })}
        </>
    )
}
export default FormBuilderPanal
