import React from "react";
type DropDownWithIconProps = {
  data: any[];
  selectedOption(e: string | number): void;
  defaultValue?: string | number;
  width?: string;
  showKey?: string;
  disabled?: boolean;
};
const DropDownWithIcon = (props: DropDownWithIconProps) => {
  return (
    <>
      <select
        className={`cursor-pointer flex-shrink-0 text-sm  outline-none inputCSS rounded mr-2 py-1 pr-2 ${
          props.width ? "w-" + props.width : "w-48"
        }  bg-opacity-20`}
        data-testid="selectBoard"
        disabled={props.disabled}
        onChange={(data) => data && props.selectedOption(data.target.value)}
      >
        {props.data.length > 0 ? (
          props.data.map((column: any, index: number) => (
            <option
              key={index}
              value={props.showKey ? column.id : column}
              selected={
                props.defaultValue ==
                (props.showKey ? column[props.showKey] : column)
              }
            >
              {props.showKey ? column[props.showKey] : column}
            </option>
          ))
        ) : (
          <option>none</option>
        )}
      </select>
    </>
  );
};

export default DropDownWithIcon;
