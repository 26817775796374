import React, { ReactNode } from "react";
import TickIcon from "../../../../assets/Tick";
import CloseIcon from "../../../../assets/Close";
// Function to determine the appropriate icon based on conditions
export const getIcon = (member: any): ReactNode | null => {
  if (member?.is_available == "ACCEPTED") {
    return (
      <div
        data-testid="AcceptIcon"
        className="absolute rounded top-4 h-3.5 w-3.5 flex items-center justify-center left-4 bg-tacitbase-secondary5  border border-tacitbase-secondary5 shadow-sm"
      >
        <TickIcon size="8" fill="#ffff" />
      </div>
    );
  } else if (member?.is_available == "TENTATIVE") {
    return (
      <div
        data-testid="TentativeIcon"
        className="absolute rounded top-4 h-3.5 w-3.5 flex items-center justify-center left-4 bg-tacitbase-secondary4 text-xs border border-tacitbase-secondary4 border-opacity-25 text-white"
        style={{ fontSize: "8px" }}
      >
        ?
      </div>
    );
  } else if (member?.is_available == "DECLINED") {
    return (
      <div
        data-testid="DeclinedIcon"
        className="absolute rounded top-4 h-3.5 w-3.5 flex items-center justify-center left-4 bg-tacitbase-primary2 border border-tacitbase-primary2  border-opacity-80  text-white"
      >
        <CloseIcon size="8" fill="#ffff" />
      </div>
    );
  }

  return null;
};
