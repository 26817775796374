import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
// import { Dispatch } from "redux";
import Read from "../../Read";
import { servicesMembership } from "../../redux/reducers/initialState";
import { loginUser, openingMemberType, orgMember } from "../../type/type.d";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import MemberAccessPreview from "../common/MemberAccessPreview";
import MemberlistOfService from "../common/MemberlistOfService";
import MembersOfServicesPopup from "../common/MembersOfServicesPopup";
import CDBMemberActions from "./CDBMemberActions";
import { searchResultOfMember } from "./hook/filterMember";
import { getServiceAccessOfLoginUser } from "./hook/getLoginUserServiceAccess";
import { SEARCH_IN_CDB_MEMBER } from "../../constant"

export type ShowCDBMemberProps = {
  click: (e: boolean) => void;
  members: orgMember;
  refer: any;
  applicationId: string;
  clientId: string;
  loginUser: loginUser;
  servicesMembership: servicesMembership;
};
const ShowCDBMember = (props: ShowCDBMemberProps) => {
  let accessForCDB = getServiceAccessOfLoginUser({
    serviceMembers: props.servicesMembership.CDBMember,
    loginUserId: props.loginUser.user.id,
  });
  let refer = useRef(null);
  const [searchedMember, getSearchedMember] = useState("");
  const [member, setMember] = useState<openingMemberType[]>(
    props.servicesMembership.CDBMember
  );
  useEffect(() => {
    let orgMembers: openingMemberType[];
    if (searchedMember.trim()) {
      orgMembers = searchResultOfMember({
        serviceMembers: props.servicesMembership.CDBMember,
        members: props.members,
        searchChar: searchedMember,
      });
      setMember(orgMembers);
    } else {
      setMember(props.servicesMembership.CDBMember);
    }
    return () => {};
  }, [searchedMember]);

  useEffect(() => {
    setMember(props.servicesMembership.CDBMember);
  }, [props.servicesMembership.CDBMember]);

  const searchKeyword = (e: any) => {
    getSearchedMember(e.target.value);
  };

  if (!props.members) return null;
  return (
    <React.Fragment>
      <MembersOfServicesPopup
        closePopup={(e: any) => props.click(e)}
        searchKeyword={(e: string) => searchKeyword(e)}
      >
        {member.length > 0 ? (
          member.map(
            (member: openingMemberType) =>
              member && props.members[member.id].registrations[0].data.activated && (
                <MemberlistOfService key={member.id}>
                  <MemberAccessPreview member={member} />{" "}
                  <Read access={accessForCDB} type={true}>
                    {member.id != props.loginUser.user.id && (
                      <CDBMemberActions selectedMember={member} refer={refer} />
                    )}
                  </Read>
                </MemberlistOfService>
              )
          )
        ) : (
          <div className="text-xs text-gray-700 bg-gray-100 ml-2 rounded-default mb-2 px-2 py-2">
              {capitalizeFirstLetter(SEARCH_IN_CDB_MEMBER)}
          </div>
        )}
      </MembersOfServicesPopup>
    </React.Fragment>
  );
};
const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
  members: state.opening.members,
  loginUser: state.opening.loginUser,
  openingMembers: state.opening.openingMembers,
  currentApplicant: state.opening.currentApplicant,
  servicesMembership: state.opening.servicesMembership,
  applicationId: state.opening.applicationId,
  clientId: state.opening.clientId,
});

export default connect(mapStateToProps)(ShowCDBMember);
