export function init_search_query(state: any, action: any) {
    return {
        ...state,
        searchQuery: {
            ...state.searchQuery,
            query: action.query,
        },
        currentCandidateInEditMode: {
            ...state.currentCandidateInEditMode,
            candidateId: ""
        }
    }
}
export function init_search_Filter(state: any, action: any) {
    return {
        ...state,
        searchQuery: {
            ...state.searchQuery,
            filters: [...action.filterObj]
        }
    }
}
