// src/components/ColorPalette.tsx
import React from "react";
import { Row, Col, ConfigProvider } from "antd";
import { COLOR_DATA_LABEL, SOCKET_CLIENT_ID } from "../../../../../constant";
import { setBoardBackgroundColor } from "../../../../../redux/actions/opening/action";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { GetOpening } from "../../../../../type/type";
import { setOpeningBackground } from "../../../../../lib/api";

// Define the properties expected by the ColorPalette component
interface ColorPaletteProps {
  currentOpening: GetOpening;
  updateBoardBackground(selectedColor: string): void;
  socketClientID: string;
  applicationId: string;
}

const ColorPalette: React.FC<ColorPaletteProps> = ({
  currentOpening,
  applicationId,
  updateBoardBackground,
}) => {
  // Function to set the board background color
  const setBoardBackgroundColor = (selectedColor: string) => {
    const req = {
      id: currentOpening.id,
      color: selectedColor,
      tenant_id: applicationId,
      socket_client_id: sessionStorage.getItem(SOCKET_CLIENT_ID),
    };
    // Update the background color and trigger the API call
    setOpeningBackground(req).then((opening: any) => {
      updateBoardBackground(opening);
    });
  };

  return (
    <div className="h-full w-full" style={{ height: "calc(-300px + 100vh)" }}>
      <div className="flex-1 overflow-y-auto pl-4 pr-2" style={{ height: "calc(-300px + 100vh)" }}>
        <Row gutter={[16, 16]}>
          {COLOR_DATA_LABEL.map((color, index) => (
            <Col span={4} key={index}>
              <ConfigProvider
                theme={{
                  token: {
                    paddingLG: 16,
                  },
                }}
              >
                {/* Render each color option */}
                <div
                  onKeyDown={() => {}} // No-op for accessibility
                  role="button"
                  tabIndex={0}
                  className={`relative group p-1 rounded hover:opacity-90 ${
                    currentOpening.color === color.colorCode ? "border border-tacitbase-secondary3" : ""
                  }`}
                  onClick={() => setBoardBackgroundColor(color.colorCode)}
                >
                  <div
                    className={`bg-color-${color.colorCode}`}
                    style={{
                      width: "100%",
                      height: "100px",
                      borderRadius: "4px",
                    }}
                  />
                </div>
              </ConfigProvider>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

// Map state from Redux store to component props
const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
  socketClientID: state.opening.clientId,
  applicationId: state.opening.applicationId,
});

// Map dispatch actions to component props
const mapPropsToState = (dispatch: Dispatch) => ({
  updateBoardBackground: (opening: any) => dispatch(setBoardBackgroundColor(opening)),
});

// Connect the component to the Redux store
export default connect(mapStateToProps, mapPropsToState)(ColorPalette);
