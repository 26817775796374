import React from "react";
import { Button } from "antd";
import MarkAsRead from "../../assets/MarkAsRead";
import { markAllAsRead } from "../../lib/api";
import { Dispatch } from "redux";
import { NotificatioRTU } from "../../lib/apiRes";
import { initialUpdatesPagination } from "../../redux/reducers/initialState";
import { notificationsPagination } from "../../type/type";
import { connect } from "react-redux";
import { initNotificationCenterUnread, dot_initial_state } from "../../redux/actions/opening/action";

interface MarkAllAsReadButtonProps {
  initUpdates: (notifications: NotificatioRTU[], pagination: notificationsPagination) => void;
  initUnreadDots: () => void;
}

const MarkAllAsReadButton: React.FC<MarkAllAsReadButtonProps> = ({
  initUpdates,
  initUnreadDots,
}) => {
  const handleMarkAllAsRead = () => {
    markAllAsRead().then(() => {
      initUpdates([], initialUpdatesPagination);
      initUnreadDots();
    });
  };

  return (
    <Button
      block
      type="link"
      className="flex underline"
      icon={<MarkAsRead />}
      onClick={handleMarkAllAsRead}
    >
      Mark all as read
    </Button>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  initUpdates: (notifications: NotificatioRTU[], pagination: notificationsPagination) =>
    dispatch(initNotificationCenterUnread(notifications, pagination)),
  initUnreadDots: () => dispatch(dot_initial_state()),
});

export default connect(null, mapDispatchToProps)(MarkAllAsReadButton);
