import React from "react";
type SearchIconProps = {
  fill?: string;
};
const SearchIcon = (props: SearchIconProps) => {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Search</title>
      <g
        id="Icons-/-16x16-/-Search"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M6.5,0 C10.0898509,0 13,2.91014913 13,6.5 C13,10.0898509 10.0898509,13 6.5,13 C2.91014913,13 0,10.0898509 0,6.5 C0,2.91014913 2.91014913,0 6.5,0 Z M6.5,1.08333333 C3.50845761,1.08333333 1.08333333,3.50845761 1.08333333,6.5 C1.08333333,9.49154239 3.50845761,11.9166667 6.5,11.9166667 C9.49154239,11.9166667 11.9166667,9.49154239 11.9166667,6.5 C11.9166667,3.50845761 9.49154239,1.08333333 6.5,1.08333333 Z"
          id="Combined-Shape"
          fill={`${props.fill ? props.fill : "#161E25"}`}
        ></path>
        <rect
          id="Rectangle"
          fill={`${props.fill ? props.fill : "#161E25"}`}
          transform="translate(13.328427, 13.353553) rotate(-45.000000) translate(-13.328427, -13.353553) "
          x="12.8284271"
          y="9.85355339"
          width="1"
          height="7"
          rx="0.5"
        ></rect>
      </g>
    </svg>
  );
};
export default SearchIcon;
