import React from "react";
const LabelsIcon = (props: any) => {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Labels</title>
      <g
        id="Icons-/-16x16-/-Labels"
        stroke="none"
        strokeWidth="1"
        fill={`${props.fill ? props.fill : "#161E25"}`}
        fillRule="evenodd"
      >
        <path
          d="M14,2 C15.1045695,2 16,2.8954305 16,4 L16,12 C16,13.1045695 15.1045695,14 14,14 L2,14 C0.8954305,14 1.3527075e-16,13.1045695 0,12 L0,4 C-1.3527075e-16,2.8954305 0.8954305,2 2,2 L14,2 Z M14,3 L2,3 C1.44771525,3 1,3.44771525 1,4 L1,4 L1,12 C1,12.5522847 1.44771525,13 2,13 L2,13 L14,13 C14.5522847,13 15,12.5522847 15,12 L15,12 L15,4 C15,3.44771525 14.5522847,3 14,3 L14,3 Z M11,7 C11.5522847,7 12,7.44771525 12,8 C12,8.55228475 11.5522847,9 11,9 L5,9 C4.44771525,9 4,8.55228475 4,8 C4,7.44771525 4.44771525,7 5,7 L11,7 Z"
          id="Combined-Shape"
          fill={`${props.fill ? props.fill : "#161E25"}`}
        ></path>
      </g>
    </svg>
  );
};
export default LabelsIcon;
