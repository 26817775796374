import React from 'react';


interface PersonalBlogprops{
    size?:number
}
const PersonalBlog = (props:PersonalBlogprops) => (
    <svg width={props.size?props.size:"26"} height={props.size?props.size:"26"} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
   <path d="M7.50027 0.00125008V1.87637C8.89592 1.8736 10.2783 2.14711 11.5678 2.68113C12.8572 3.21514 14.0282 3.9991 15.0132 4.98781C16.0013 5.97335 16.7848 7.14444 17.3188 8.43377C17.8527 9.7231 18.1266 11.1053 18.1247 12.5008H19.9998C19.9998 5.5966 14.4032 0 7.49902 0L7.50027 0.00125008Z" fill="#666666"/>
<path d="M7.50047 3.75V5.62512C9.33683 5.62512 11.0632 6.34016 12.362 7.63899C13.6609 8.93782 14.3759 10.6642 14.3759 12.5005H16.251C16.251 10.1798 15.3291 7.95402 13.688 6.31298C12.047 4.67193 9.82126 3.75 7.50047 3.75ZM9.37559 7.50023L8.12551 8.75031L3.75023 10.0004L0 18.1259L0.495031 18.6209L5.04282 14.0731C4.97157 13.8073 4.99002 13.5254 5.09532 13.2712C5.20061 13.0169 5.38686 12.8045 5.62518 12.6669C5.8635 12.5293 6.14057 12.4742 6.41341 12.5101C6.68625 12.546 6.93962 12.671 7.13421 12.8656C7.3288 13.0601 7.45374 13.3135 7.48966 13.5864C7.52557 13.8592 7.47045 14.1363 7.33284 14.3746C7.19523 14.6129 6.98283 14.7992 6.72858 14.9044C6.47433 15.0097 6.19243 15.0282 5.92662 14.957L1.37884 19.5047L1.87387 19.9998L9.99938 16.2495L11.2495 11.8743L12.4995 10.6242L9.37434 7.49898L9.37559 7.50023Z" fill="#666666"/>
</svg>
    
);

export default PersonalBlog;
