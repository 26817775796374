import React from "react";
import {  Alert, Button,  Form, Input } from "antd"
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import { registration, setTokenInLocalStorage } from "../../lib/api";
import { loginUserAc } from "../../redux/actions/opening/action";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import throwError from "../../service/throwError"

type SignUpFormProps = {
  loginUserAc(token: string, data: any): void;
} & RouteComponentProps;

class InvitedSignupIndex extends React.Component<SignUpFormProps  & RouteComponentProps> {
  state = {
    validMobile: true,
    loading: false,
    errorMessage: "", // Add a state to store the error message
    invitedEmailAddress:"",
    isEmailParamsExist:false

  };

    validatePassword = (_: any, value: any) => {
    if (!value) {
      const errorMessage = "Please enter a password!"
      return Promise.reject(new Error(errorMessage))
    }
    // Use regular expressions to check for specific conditions
    const hasUppercase = /[A-Z]/.test(value)
    const hasLowercase = /[a-z]/.test(value)
    const hasSpecialCharacter = /[!@#$%^&*(),.?":{}|<>]/.test(value)

    // Check for the required conditions
    if (
      value &&
      value.length >= 12 &&
      hasUppercase &&
      hasLowercase &&
      hasSpecialCharacter
    ) {
      return Promise.resolve()
    }

    // Create an array to store error messages
    const errorMessages = []

    // Check each condition and add an error message if not met
    if (value.length < 12) {
      errorMessages.push("At least 12 characters")
    }
    if (!hasUppercase) {
      errorMessages.push("1 uppercase")
    }
    if (!hasLowercase) {
      errorMessages.push("1 lowercase")
    }
    if (!hasSpecialCharacter) {
      errorMessages.push("1 special character")
    }

    // Combine error messages and return the rejection with the message
    const errorMessage = `Password must have: ${errorMessages.join(", ")}`
    return Promise.reject(new Error(errorMessage))
  }
  onFinish = (values: any) => {
    this.setState({ loading: true });
    const urlParams = new URLSearchParams(window.location.search);
    const invitationSecret:any= urlParams.get('invitation_secret');
    registration({
      user: values,
    },invitationSecret).then((res) => {
      setTokenInLocalStorage("login_token", res.token).then(async(flag: any) => {
        if (flag) {
          this.props.loginUserAc(res.token, res.user);

          if (!res.user.verified) {
            return this.props.history.push("/onboarding");
          }
          return this.props.history.push("/o");
        }
        this.setState({ loading: false });
      });
    }).catch((error) => {
      if (error.response && error.response.status === 409) {
        this.setState({
          errorMessage: "An account already exist with this email address",
        })
      } else
        throwError(error)

      this.setState({ loading: false });
    });
  };
  componentDidMount(): void {
    let emailValue: any = {};
    emailValue = this.props.match.params;
    this.setState({invitedEmailAddress:emailValue.email})
    this.setState({isEmailParamsExist:true})
    
  }

  render() {
 
    

    return (
        <>
        {this.state.isEmailParamsExist&&(
      <div className="flex flex-col md:space-y-4 space-y-2 relative w-full bg-bg">
        <div className="w-full flex flex-col space-y-2 items-center justify-center ">
          <div className="font-semibold md:text-lg text-base">
            {" "}
            {capitalizeFirstLetter("Create your account")}
          </div>
          {this.state.errorMessage && (
            <Alert message={this.state.errorMessage} type="error" />
          )}
        
        </div>
        <hr />

        <Form
          name="normal_login"
          className="login-form text-xs w-full"
          initialValues={{ remember: true,email:this.state.invitedEmailAddress }}
          onFinish={this.onFinish}
          layout="vertical"
        >
          <div className="flex w-full xl:space-x-6 space-x-4">
            <Form.Item
              className="w-1/2 font-medium"
              name="firstName"
              label="First Name"
              rules={[
                { required: true, message: "Please input your First Name!" },
              ]}
            >
              <Input placeholder="abc" />
            </Form.Item>
            <Form.Item
              className="w-1/2 font-medium"
              name="lastName"
              label="Last Name"
              rules={[
                { required: true, message: "Please input your Last Name!" },
              ]}
            >
              <Input placeholder="xyz" />
            </Form.Item>
          </div>
          <Form.Item
            name="email"
            label="Work Email"
            rules={[
              { required: true, message: "Please input your Work Email!" },
              { type: 'email', message: 'Invalid email format' },
            ]}
            className=" font-medium"
          >
            <Input value={this.state.invitedEmailAddress} disabled={true}  defaultValue={this.state.invitedEmailAddress}/>
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            className=" font-medium"
            rules={[{ required: true, validator: this.validatePassword }]}

          >
            <Input.Password />
          </Form.Item>
          <Form.Item>
            <Button
              loading={this.state.loading} // Use the loading prop
              htmlType="submit"
              type={"primary"}
              className="w-full"
            >
              Sign up
            </Button>
          </Form.Item>
        </Form>
        <div className=" ">
          <div className="  ">
            By signing up, you agree to our&nbsp;
            <a
           
              href="https://www.tacitbase.com/terms-of-use"
              style={{ color: "#009D79" }}
            >
              terms of use{" "}
            </a>
            &nbsp;and&nbsp;
            <a
              href="https://www.tacitbase.com/privacy"
              style={{ color: "#009D79" }}
            >
              {" "}
              privacy policy.
            </a>
            &nbsp;Need help? Visit&nbsp;
            <a
              href="https://support.tacitbase.com/"
              style={{ color: "#009D79" }}
            >
              support.
            </a>
          </div>
        </div>
      
      </div>)}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loginUserAc: (token: string, data: any) => dispatch(loginUserAc(token, data)),
});

export default connect(null, mapDispatchToProps)(withRouter(InvitedSignupIndex));
