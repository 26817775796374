import React from "react";
import { connect } from "react-redux";
import { SelectPositionProps } from "../../../type/copyCard";
import { Select, Typography } from "antd";
const {Text} = Typography;
const SelectPosition = (props: SelectPositionProps): any => {
  return (
    <>
      <Text strong>Position</Text>
      <Select
        className="w-full"
        onChange={props.event}
      >
        {props.value.map((option: any, index: number) => (
          <Select.Option
            key={index}
            className="w-full"
            value={index+1}
            selected={option.id === props.comparedId}
          >
            {index + 1}
          </Select.Option>
        ))}
        {props.move ? (
          props.sourceId === props.extraIndexCheck ? null : (
            <option className="w-full" value={props.value.length}>
              {props.value.length + 1}
            </option>
          )
        ) : (
          <option className="w-full" value={props.value.length}>
            {props.value.length + 1}
          </option>
        )}
      </Select>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
  lists: state.opening.lists,
  currentApplicant: state.opening.currentApplicant,
  keep: state.opening.keepCopyApplicant,
  tasks: state.opening.tasks,
  labels: state.opening.labels,
  members: state.opening.members,
  // comments: state.opening.comments,
});
export default connect(mapStateToProps)(SelectPosition);
