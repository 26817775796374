import { Popover, Typography } from "antd";
import React, {  useState } from "react";
import { LabeledIconButton, SecondaryButton } from "../../../../ant/Button";
import { CloseOutlined, CopyOutlined } from "@ant-design/icons";
import { Overlay } from "../../../hook/Overlay";
import { Scope } from "../../../../type/type.d";
import CopyCard from "./CopyCard";
const { Text } = Typography;
const CopyApplicant = () => {
  const [open, setOpen] = useState(false);
//   let refer = useRef(null);
  return (
    <>
      <Popover
        destroyTooltipOnHide
        title={
          <div className="flex w-full bg-white items-center">
            <Text className="w-full flex justify-center">
              <Text>Copy</Text>
            </Text>
            <LabeledIconButton
              type="text"
              label=""
              onClick={() => setOpen(false)}
              icon={<CloseOutlined />}
            />
          </div>
        }
        open={open}
        onOpenChange={(open) => setOpen(open)}
        trigger={["click"]}
        content={
          <Overlay
            onClose={() => setOpen(!open)}
            scope={Scope.Popover}
            // reference={refer}
          >
            <CopyCard click={() => setOpen(!open)} />
          </Overlay>
        }
      >
        <div className="sticky bottom-0 ">
          <SecondaryButton
            className="w-full flex truncate justify-start"
            onClick={() => setOpen(!open)}
            icon={<CopyOutlined />}
          >
            Copy
          </SecondaryButton>
        </div>
      </Popover>
    </>
  );
};

export default CopyApplicant;
