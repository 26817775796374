import React, { useEffect, useRef, useState } from "react";

import CommonDropDown from "../../calender/cardScheduler/cardPopupContentComponents/Dropdown";
import { connect } from "react-redux";
import {
  updateBoardCalendar,
  // updateBoardCalendar,
} from "../../../lib/api";
import { calendar, loginUser, Opening, updatedColor } from "../../../type/type";

import Button1 from "../../common/button/Button1";
import { COLOR_DATA_EVENT } from "../../../constant";
import BoardConferenceSettings from "./BoardConferenceSettings";
import { Calendar } from "../../../lib/apiRes";
import { UpdateCurrentCal } from "../../../redux/actions/opening/action";
import { Dispatch } from "redux";
import EventColorSelectionPopup from "./EventColorSelection";
import { throwSuccess } from "../../../service/throwError";
type color = {
  name: string | undefined;
  code: string;
};

// Api's
type settingsPageProps = {
  calenderId: string;
  loginUser: loginUser;
  opening: Opening;
  applicationId: string;
  currentCalendar: Calendar;
  UpdateCurrentCalendar(data: Calendar): void;
};

const SettingsPage = (props: settingsPageProps) => {
  const [hasChanges, setHasChanges] = useState(false);
  const [eventBoardSettings, setEventBoardSettings] = useState<calendar>({
    name: "",
    description: "",
    owner_id: props.loginUser.user.id,
    owner_name: "",
    owner_email: "",
    default_timezone: "GMT +05:30 Asia/kolkata",
    default_event_duration: 0,
    default_event_color: "",
    past_event_color: false,
    board_id: props.opening.id,
    member_only: true,
    org_id: props.applicationId,
  });
  const [initialState,setInitialState]=useState<calendar>()
  const zones = ["30 min", "60 min", "90 min"];
  const [selectedColor, setSelectedColor] = useState<color>({
    name: "",
    code: "",
  });



  const updateCalendar = () => {
    updateBoardCalendar(eventBoardSettings, props.calenderId).then((res) => {
      props.UpdateCurrentCalendar(res.calendar);
      if (res) {
        throwSuccess("Calendar settings updated successfully.")
      }
      setInitialState(res.calendar)
      COLOR_DATA_EVENT.filter((e) => {
        e.colorCode == res.calendar.default_event_color
        ? setSelectedColor({ name: e.name, code: e.colorCode })
        : null;

      });
    });
  };

  const [defaultColors, setColors] = useState(COLOR_DATA_EVENT);

  const [showPopup, setShowPopup] = useState(false);
  const popupRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    setEventBoardSettings(props.currentCalendar);
    setInitialState(props.currentCalendar)
    COLOR_DATA_EVENT.filter((e) => {
      e.colorCode == props.currentCalendar.default_event_color
      ? (setSelectedColor({ name: e.name, code: e.colorCode }),
      (e.isSet = true))
    : (e.isSet = false);


    });
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  // closes dropdown on outsideclick
  const handleClick = (e: any) => {
    if (popupRef.current && !popupRef.current.contains(e.target)) {
      setShowPopup(false);
      return;
    }
  };
  const setSelectedColorFunc = (index: number) => {
    let updatedColors: any = [];
    setTimeout(() => {
      setShowPopup(false);
    }, 500);
    COLOR_DATA_EVENT.map((color: updatedColor, i) => {
      if (i === index) {
        color.isSet = true;

        setSelectedColor({ name: color.name, code: color.colorCode });


        setEventBoardSettings((prev: any) => ({
          ...prev,
          default_event_color: color.colorCode,
        }));
        updatedColors.push(color);
      } else {
        color.isSet = false;
        updatedColors.push(color);
      }
    });
    setColors(updatedColors);
  };
  useEffect(()=>{
    let currentState ={...eventBoardSettings}
    setHasChanges(JSON.stringify(initialState) !== JSON.stringify(currentState));
  },[eventBoardSettings])

  return (
    <React.Fragment>
      {/* <div>Event settings</div> */}
      <div className="flex flex-col text-sm space-y-2 mx-3 my-2">
        <div>
          <div className=" ">
            <div className="tracking-normal  items-center text-sm font-medium text-black w-full">
              Event settings
            </div>
          </div>
          <div className="">
            <div className="flex">
              <div className="flex flex-col  space-y-2 ">
                <div className="flex flex-col space-y-1">
                  <div className="tracking-normal  items-center text-xm font-normal  w-full">
                    Default duration
                  </div>
                  <div>
                    <CommonDropDown
                      name="zone"
                      option={zones}
                      setValue={(value) =>
                        setEventBoardSettings((prev: any) => ({
                          ...prev,

                          default_event_duration: parseInt(value.split(" ")[0]),
                        }))
                      }
                      selectedValue={eventBoardSettings.default_event_duration.toString()}
                    />
                  </div>
                </div>
                <div className="flex flex-col space-y-1">
                  <div className="tracking-normal  items-center text-xm font-normal  w-full">
                    Default color
                  </div>
                  <div>
                    <div className="relative ">
                      {" "}
                      <button
                        className=" input-box cursor-default flex items-center justify-between  "
                        onClick={() => setShowPopup(!showPopup)}
                      >
                        <div className="overflow-hidden text-sm">
                          {eventBoardSettings ? (
                            <div className="flex items-center space-x-2">
                              {" "}
                              <div
                                className={`h-3 w-3 rounded  `}
                                style={{
                                  backgroundColor: selectedColor.code,
                                }}

                              ></div>
                              <div>{selectedColor.name}</div>

</div>
                          ) : (
                            "Select"
                          )}
                        </div>
                        <div className="text-xl mb-3"> &#8964;</div>
                      </button>
                      {showPopup ? (
                        <div
                          className="z-10 absolute  p-2 bg-white w-full top-9 rounded shadow-2xl "
                          ref={popupRef}
                        >
                          <div className=" flex   flex-wrap">
                            {" "}
                            {defaultColors.map((label: updatedColor, idx) => (
                              <div key={idx}>
                                <EventColorSelectionPopup
                                  colorCode={`${label.colorCode}`}
                                  isSet={label.isSet}
                                  click={() => setSelectedColorFunc(idx)}
                                  showMore={label.showMore}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
               
                <div className="flex items-start justify-center space-x-2">
                  <div>
                    <input
                      type="checkbox"
                      data-testid="CheckListTestInputBtn"
                      className="form-checkbox"
                      value="members"
                      checked={eventBoardSettings.past_event_color}
                      onClick={() =>
                        setEventBoardSettings((prev: any) => ({
                          ...prev,

                          past_event_color:
                            !eventBoardSettings.past_event_color,
                        }))
                      }
                    />
                  </div>
                  <div className="tracking-normal  items-center text-xm font-normal  w-full">
                    Make past event color background gray
                  </div>
                </div>
                <Button1 buttonName="update" click={() => updateCalendar()}   testId="UpdateButton"    isDisabled={hasChanges?false:true}/>
              </div>
            </div>
          </div>
        </div>
        <BoardConferenceSettings />
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  loginUser: state.opening.loginUser,
  opening: state.opening.currentOpening,
  applicationId: state.opening.applicationId,
  calenderId: state.opening.calendar.currentCalenderId,
  currentCalendar: state.opening.calendar.currentCalendar,
});

const mapPropsToState = (dispatch: Dispatch) => ({
  UpdateCurrentCalendar: (data: Calendar) => dispatch(UpdateCurrentCal(data)),
});
export default connect(mapStateToProps, mapPropsToState)(SettingsPage);
