import React from "react";

const ArrowDownIcon = (props: any) => {
  return (
    <svg
      width="10px"
      height="10px"
      viewBox="0 0 12 12"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Arrow Down</title>
      <g
        id="Icons-/-12x12-/-Arrow-Down"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M4.2,2 L4.2,6.8 L9,6.8 L9,8 L3,8 L3,2 L4.2,2 Z"
          id="Combined-Shape"
          fill={`${props.fill ? props.fill : "#6A7077"} `}
          transform="translate(6.000000, 5.000000) rotate(-45.000000) translate(-6.000000, -5.000000) "
        ></path>
      </g>
    </svg>
  );
};
export default ArrowDownIcon;
