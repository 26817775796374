// A redux action that will set redux state for current board calendar id
export function set_calender_id(state: any, action: any) {
  return {
    ...state,
    calendar: { ...state.calendar, currentCalenderId: action.data },
  };
}

// A redux action that will set redux state for current card calendar events
export function set_card_events(state: any, action: any) {
  var events: any = {};
  action.data?.map((event: any) => {
    events[event.id] = event;
  });
  return {
    ...state,
    calendar: { ...state.calendar, currentCardEvents: events },
  };
}

//  A redux action that will add new event to redux state for current card calendar events
export function add_new_event_to_card(state: any, action: any) {
  return {
    ...state,
    calendar:
      action.data.card_id === state.currentApplicant.id
        ? {
            ...state.calendar,
            currentCardEvents: {
              ...state.calendar.currentCardEvents,
              [action.data.id]: action.data,
            },
          }
        : state.calendar,
  };
}

// A redux action that will delete event by event id from board calendar
export function delete_card_event(state: any, action: any) {
  var events: any = {};
  Object.values(state.calendar.currentCardEvents).map((event: any) => {
    event.id !== action.data ? (events[event.id] = event) : null;
  });
  return {
    ...state,
    calendar:
      
        { ...state.calendar, currentCardEvents: events }
  };
}

// A redux action that will set redux state for current board calendar events
export function set_board_events(state: any, action: any) {
  var events: any = {};
  action.data?.map((event: any) => {
    events[event.id] = event;
  });
  return {
    ...state,
    calendar: { ...state.calendar, currentBoardEvents: events },
  };
}
export function add_event_on_board_events(state: any, action: any) {
  return {
    ...state,
    calendar: {
      ...state.calendar,
      currentBoardEvents: {
        ...state.calendar.currentBoardEvents,
        [action.data.id]: action.data,
      },
    },
  };
}

// A redux action that will delete event by event id from board calendar
export function delete_board_event(state: any, action: any) {
  var events: any = {};
  Object.values(state.calendar.currentBoardEvents).map((event: any) => {
    event.id !== action.data ? (events[event.id] = event) : null;
  });
  return {
    ...state,
    calendar: { ...state.calendar, currentBoardEvents: events },
  };
}
// A redux action that will edit event by event id from board calendar
export function edit_event_from_board(state: any, action: any) {
  return {
    ...state,
    calendar: {
      ...state.calendar,
      currentBoardEvents: {
        ...state.calendar.currentBoardEvents,
        [action.data.eventID]: action.data.value.event,
      },
    },
  };
}

// A redux action that will edit event by event id from card
export function edit_event_from_card(state: any, action: any) {
  return {
    ...state,
    calendar:
    action.data.value.event.card_id === state.currentApplicant.id
        ? {
            ...state.calendar,
            currentCardEvents: {
              ...state.calendar.currentCardEvents,
              [action.data.eventID]: action.data.value.event,
            },
          }
        : state.calendar,
  };
}

// A redux action that will add current calendar
export function add_current_calendar(state: any, action: any) {
  return {
    ...state,
    calendar: {
      ...state.calendar,
      currentCalendar: action.data,
    },
  };
}

// A redux action that will update current calendar
export function update_current_calendar(state: any, action: any) {
  return {
    ...state,
    calendar: {
      ...state.calendar,
      currentCalendar: action.data,
    },
  };
}

// A redux action that will update current calendar
export function set_currentUser_ZoomMeetAcc(state: any, action: any) {
  return {
    ...state,
    calendar: {
      ...state.calendar,
      currentUserZoomMeet: action.data,
    },
  };
}
export function remove_zoom_access(state: any) {
  return {
    ...state,
    calendar: {
      ...state.calendar,
      currentUserZoomMeet: {
        ...state.calendar.currentUserZoomMeet,
        zoom_email: "",
      },
    },
  };
}

export function remove_meet_access(state: any) {
  return {
    ...state,
    calendar: {
      ...state.calendar,
      currentUserZoomMeet: {
        ...state.calendar.currentUserZoomMeet,
        google_email: "",
      },
    },
  };
}
export function add_zoom_access(state: any, action: any) {
  return {
    ...state,
    calendar: {
      ...state.calendar,
      currentUserZoomMeet: {
        ...state.calendar.currentUserZoomMeet,
        zoom_email: action.data,
      },
    },
  };
}
export function add_meet_access(state: any, action: any) {
  return {
    ...state,
    calendar: {
      ...state.calendar,
      currentUserZoomMeet: {
        ...state.calendar.currentUserZoomMeet,
        google_email: action.data,
      },
    },
  };
}

// A redux action that will update current calendar
export function set_current_calendar_conference_settings(
  state: any,
  action: any
) {
  return {
    ...state,
    calendar: {
      ...state.calendar,
      currentCalendarConferenceSettings: action.data,
    },
  };
}
// A redux action that will update rrules
export function edit_rrule(state: any, action: any) {
  return {
    ...state,
    calendar: {
      ...state.calendar,
      rrules: { ...state.calendar.rrules, [action.data.id]: action.data },
    },
  };
}

// A redux action that will add rrules from API res
export function init_rrules(state: any, action: any) {
  var rrules: any = {};

  action.data.map((rrule: any) => {
    rrules[rrule.id] = rrule;
  });
  return {
    ...state,
    calendar: {
      ...state.calendar,
      rrules: rrules,
    },
  };
}

// A redux action that will add rrule to existing rrules
export function add_rrule(state: any, action: any) {
  var rrules: any = { ...state.calendar.rrules, [action.data.id]: action.data };
  return {
    ...state,
    calendar: {
      ...state.calendar,
      rrules: rrules,
    },
  };
}

// A redux action that will delete rrule
export function remove_rrule(state: any, action: any) {
  return {
    ...state,
    calendar: {
      ...state.calendar,
      rrules: Object.keys(state.calendar.rrules)
        .filter((key) => state.calendar.rrules[key].id !== action.data)
        .reduce((obj: any, key: string) => {
          obj[key] = state.calendar.rrules[key];
          return obj;
        }, {}),
    },
  };
}
