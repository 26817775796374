export const seperateMailAddress = (text: string) => {
    const inputString: string = text
    const pattern: RegExp = /"?(.*?)"?\s*<(.+)>/

    const matchResult: RegExpMatchArray | null = inputString.match(pattern)
    const name: string = matchResult ? matchResult[1] : ""

    const email: string = matchResult ? `<${matchResult[2]}>` : ""
    // const emailCheck: string = matchResult ? matchResult[2] : "";
    return {name:name,email:email}
}