import { useState } from 'react';
import { sendMail } from '../../../lib/api';
import { sendMailReq } from "../../../lib/apiReq";
import { Candidate, loginUser, orgMember, SendMail } from '../../../type/type';
import { newOrganisation } from '../../../lib/apiRes';
import throwError, { throwFrontEndError, throwInfo, throwSuccess } from '../../../service/throwError';

export interface ComposeMailType {
    close(): void;
    service: string;
    members: orgMember;
    entity: string;
    record_id: Candidate[];
    toAddress: string[];
    loginUser: loginUser;
    clientId: string;
    refer: any;
    reduxActionCall: (res: SendMail) => void;
    getDisableState: (value: boolean) => void;
    getJobDescription: (description: string) => void;
    currentOrg: newOrganisation;
  }

  
export const useMailSend = (props: ComposeMailType) => {
  const [isEditable, setIsEditable] = useState(false);

  const send = () => {
    return new Promise<boolean>((resolve) => {
        throwInfo("Sending mail");
      setTimeout(() => resolve(true), 3000); // Simulate mail sending
    });
  };

  const sentMailAnyway = async (
    selectedDynamicKeys: string[],
    ccMembers: string[],
    bccMembers: string[],
    subject: string,
    mailBody: string,
    mailTextBody: string,
    uploadedAttachments: any[],
    source?:any,
  ) => {
    if (subject.trim().length > 0) {
      setIsEditable(true);
      const callApi = await send();
      if (callApi) {
        const editorElement: any = document.querySelector("trix-editor");
        if (editorElement) editorElement.setAttribute("disabled", true);

        const ccMembersMail = ccMembers.map((id: string) => props.members[id].email);
        const bccMembersMail = bccMembers.map((id: string) => props.members[id].email);

        const attachments = uploadedAttachments.map((attachment: any) => ({
          objectKey: attachment.objectKey,
          name: attachment.name,
        }));

        const records = props.record_id.map((candidate:any) => {
          const values = selectedDynamicKeys.reduce((acc: any, key) => {
            if (Object.prototype.hasOwnProperty.call(candidate, key)) {
              acc[key] = candidate[key];
            }
            else if (Object.prototype.hasOwnProperty.call(candidate.candidates || {}, key)) {
              acc[key] = candidate.candidates[key];
            }
            return acc;
          }, {});

          return {
            id: candidate.id,
            address: [candidate.email],
            values: values,
          };
        });
        const sourceRecords=[{
          id: source?.id,
          address: [source?.email],
        }]

        const reqObb: sendMailReq = {
          service: props.service,
          entity: props.entity,
          input: {
            keys: selectedDynamicKeys,
            records: source?sourceRecords:records,
          },
          message: {
            cc: [...ccMembersMail],
            bcc: [...bccMembersMail],
            to: props.toAddress,
            subject: subject,
            body: mailBody,
            text_body: mailTextBody,
            attachments: attachments,
          },
        };

        sendMail(reqObb)
          .then((res) => {
            props.close();
            setIsEditable(false);
            props.reduxActionCall(res);
            throwSuccess("Mail sent");
          })
          .catch((reason) => {
            setIsEditable(false);
            if (editorElement) editorElement.removeAttribute("disabled");
            if (
              reason.response?.status == 500 ||
              reason.response?.status == 400
            ) {
              throwFrontEndError(`${reason.response?.data.error.message}`);
            } else throwError(reason);
          });
      }
    }
  };

  return { sentMailAnyway, isEditable };
};

