import React, { ReactNode } from "react";
type MemberlistOfServiceType = {
  children: ReactNode;
};
const MemberlistOfService = (props: MemberlistOfServiceType) => {
  return (
    <React.Fragment>
      <div className="flex rounded-l-default border-l-4 border-tacitbase-secondary3  hover:bg-gray-200 hover:bg-opacity-40 mb-2 ml-2 mr-0.5">
        <div
          className="flex py-1.5 px-1 rounded-r-default justify-between border-t border-b border-r border-tacitbase-secondary3 items-center hover:bg-gray-300 hover:bg-opacity-40 w-full"
          data-testid="add-member"
        >
          {props.children}
        </div>
      </div>
    </React.Fragment>
  );
};
export default MemberlistOfService;
