import React from "react";
import { COLOR_DATA_LABEL } from "../../constant";
import { BoardBgColorSelectionPreviewProps } from "../../type/sidePanalSlider";
import Palette from "./palette/Palette"

const BoardBgColorSelectionPreview = (
  props: BoardBgColorSelectionPreviewProps
) => {
  return (
    <>
      <div className="flex cursor-pointer p-1 flex-wrap">
        {COLOR_DATA_LABEL.map((color: any) => color && (
          <Palette key={color.colorCode} isImage={false} backgroundItem={color.colorCode} click={props.click} />))}
      </div>
    </>
  );
};

export default BoardBgColorSelectionPreview;
