import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Divider, Dropdown, Menu, Skeleton, Space, Tag, Typography } from "antd";
import { EllipsisOutlined, FilePdfOutlined } from "@ant-design/icons";

import { Dispatch } from "redux";
import { Attachment } from "../../type/type.d";
import { getAttachments } from "../../lib/api";
import { ATTACHMENTS, CANDIDATES } from "../../constant";
import { PDFRenderer } from "../card/PDFViewer";
import { initCandidateAttachments } from "../../redux/actions/opening/action";
import DownloadFile from "./AttachmentAction/DownloadAttachment";
import DeleteCandidateAttachment from "./AttachmentAction/DeleteAttachment";
import EditCandidateAttachment from "./AttachmentAction/EditAttachment";
import OpenFileInNewTab from "./AttachmentAction/OpenFileInNewTab";
import { useParams } from "react-router-dom";

const { Text } = Typography;

type candidateInCDBAttachmentsProps = {
  addAttachmentToCurrentCandidateInDb(attachment: Attachment[]): void;
  currentCandidateInEditMode: any;
};

const CandidateInCDBAttachments = (props: candidateInCDBAttachmentsProps) => {
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const [selectedCandidateAttachment, setSelectedCandidateAttachment] =
    useState<Attachment | null>(null);
    const params: { board_id: string; candidate_id: string; tabname: string } =
    useParams();

  useEffect(() => {
    if (params.candidate_id) {
      getAttachments(
        CANDIDATES,
        params.candidate_id
      ).then((res) => {
        props.addAttachmentToCurrentCandidateInDb(res);
      });
    }
  }, [params.candidate_id]);

  useEffect(() => {
    const attachmentsObject =
      props.currentCandidateInEditMode?.attachments || {};
    const attachmentsArray = Object.keys(attachmentsObject).map(
      (key) => attachmentsObject[key]
    );
    setAttachments(attachmentsArray);
  }, [props.currentCandidateInEditMode?.attachments]);

  const handleSelectedAttachment = (attachment: Attachment) => {
      setSelectedCandidateAttachment(attachment);
  };

  const onCloseViewer = () => {
    setSelectedCandidateAttachment(null);
  };

  const renderMenu = (attachment: Attachment) => (
    <Menu
      onClick={(e) => {
        e.domEvent.stopPropagation();
        e.domEvent.preventDefault(); // Additional prevention of default behavior
      }}
    >
      <div
        className="flex flex-col items-start space-y-2 p-2"
        role="button"
        tabIndex={0}
        onClick={(e) => e.stopPropagation()}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            e.stopPropagation();
          }
        }}
      >
        {" "}
        <div>
          <DownloadFile attachment={attachment} />
        </div>
        <div>
          {""} <EditCandidateAttachment attachment={attachment} />
        </div>
        <div>
          <DeleteCandidateAttachment attachment={attachment} />
        </div>
        <div>
          <OpenFileInNewTab attachment={attachment} />
        </div>
      </div>
    </Menu>
  );
  return (
    
      <>
        {attachments.length > 0 && (
          <Space direction="vertical" className="w-full">
            <Text strong>{ATTACHMENTS}</Text>
            <div className="flex flex-wrap gap-4 pt-2 w-full">
              {attachments.map((attachment: Attachment) => (
                <Tag
                  className="cursor-pointer flex items-center border border-black border-opacity-5 p-2 h-10"
                  style={{ width: "47%" }}
                  bordered={false}
                  key={attachment.id}
                  onClick={() => handleSelectedAttachment(attachment)}
                >
                  <FilePdfOutlined style={{ fontSize: "22px" }} />
                  <Text
                    ellipsis={{ tooltip: attachment.name }}
                    style={{ width: "100%" }}
                  >
                    {attachment.name}
                  </Text>
                  {/* <DownloadFile
                                      attachment={attachment}
                                    /> */}
                  <Dropdown
                    overlay={renderMenu(attachment)}
                    trigger={["click"]}
                  >
                    <Button
                      type="text"
                      tabIndex={-1}
                      icon={<EllipsisOutlined />}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                    />
                  </Dropdown>
                </Tag>
              ))}
            </div>
          </Space>
        )}

        {selectedCandidateAttachment && (
          <PDFRenderer
            title={selectedCandidateAttachment.name}
            attachmentOfwhom={`${props.currentCandidateInEditMode?.candidateId?.first_name} ${props.currentCandidateInEditMode?.candidateId?.last_name}`}
            onCloseViewer={onCloseViewer}
            url={selectedCandidateAttachment.converted_presigned_link}
          />
        )}

        {props.currentCandidateInEditMode?.newAttachmentUploader?.fileList
          ?.length > 0 && (
        
            <div className="flex flex-wrap gap-4 pt-2 w-full">
              {[
                ...Array(
                  props.currentCandidateInEditMode?.newAttachmentUploader
                    ?.fileList?.length
                ),
              ].map((_, index) => (
                <Tag
                  key={index}
                  style={{ width: "47%" }}
                  className="cursor-pointer w-full flex items-center border border-black border-opacity-5 p-2 h-10"
                  bordered={false}
                >
                  <Skeleton.Avatar active shape="square" size="small" />
                  <Skeleton.Input
                    active
                    size="default"
                    style={{ width: "100%",marginLeft:'4px' }}
                  />
                </Tag>
              ))}
            </div>
        )}
                 
{attachments.length>0 &&              
  <Divider className="mt-4 mb-1" />
}
      </>
    
  );
};

const mapStateToProps = (state: any) => ({
  currentCandidateInEditMode: state.opening.currentCandidateInEditMode,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  addAttachmentToCurrentCandidateInDb: (attachments: Attachment[]) =>
    dispatch(initCandidateAttachments(attachments)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CandidateInCDBAttachments);
