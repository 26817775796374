import React, { ReactNode } from "react";
import { Spin } from "antd";

interface LoadingIndicatorProps {
  loader_message: string|ReactNode;
}
const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({
  loader_message,
}) => {
  return (
    <div className="flex justify-center items-center h-full flex-col">
      <div style={{ marginBottom: "10px" }}>
        <Spin size="large" tip="Loading Data..." />
      </div>
      <div>
        <p className="text-center" style={{ color: "#7152f2" }}>
          {loader_message}
        </p>
      </div>
    </div>
  );
};

export default LoadingIndicator;
