import React from "react";
const Tacitmail = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <circle cx="14" cy="14" r="14" fill="white" />
      <path
        d="M23.8825 5.76471H4.11776C3.68093 5.76471 3.262 5.93824 2.95312 6.24712C2.64423 6.55601 2.4707 6.97494 2.4707 7.41177V20.5882C2.4707 21.0251 2.64423 21.444 2.95312 21.7529C3.262 22.0618 3.68093 22.2353 4.11776 22.2353H23.8825C24.3193 22.2353 24.7382 22.0618 25.0471 21.7529C25.356 21.444 25.5295 21.0251 25.5295 20.5882V7.41177C25.5295 6.97494 25.356 6.55601 25.0471 6.24712C24.7382 5.93824 24.3193 5.76471 23.8825 5.76471ZM22.0707 7.41177L14.0001 12.9953L5.92953 7.41177H22.0707ZM4.11776 20.5882V8.16118L13.5307 14.6753C13.6686 14.7709 13.8323 14.8222 14.0001 14.8222C14.1679 14.8222 14.3317 14.7709 14.4695 14.6753L23.8825 8.16118V20.5882H4.11776Z"
        fill="#F26046"
      />
      <circle
        cx="14"
        cy="14.0082"
        r="3.53941"
        fill="white"
        stroke="#F26046"
        strokeWidth="0.3"
      />
      <rect
        x="11.4058"
        y="11.7023"
        width="5.53412"
        height="5.27926"
        fill="url(#pattern0)"
      />
      <defs>
        <pattern
          id="pattern0"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image0_306_21"
            transform="scale(0.00416667 0.00440529)"
          />
        </pattern>
      </defs>
    </svg>
  );
};
export default Tacitmail;
