import React, { useEffect, useState } from "react"
import { BoardBgColorSelectionPreviewProps } from "../../type/sidePanalSlider"
import { getPhotosByUnsplash, triggerUnsplashDownloadLink } from "../../lib/api"
import { ImageData } from "../../type/type"
import Palette from "./palette/Palette"
import { AxiosError } from "axios"


const BoardBgImageSelectionPreview = (
    props: BoardBgColorSelectionPreviewProps
) => {

    const [images, SetImages] = useState<ImageData[]>([])
    const [searchedLabel, getSearchedLabel] = useState("")
    const searchKeyword = (searchEvent: React.ChangeEvent<HTMLInputElement>) => {
        getSearchedLabel(`${searchEvent.target.value.trim()}`)
    }
    useEffect(() => {
        getPhotosByUnsplash(searchedLabel).then((res) => {
            SetImages(res)
        })
    }, [searchedLabel])
    const setImage = (imgeUrl: string, downloadLocation?: string) => {
        props.click(imgeUrl)

        // trigger a unsplash download event
        triggerUnsplashDownloadLink(
            downloadLocation
        ).catch((err: AxiosError) => err)
    }

    return (
        <>
            <div className="flex cursor-pointer mx-1 flex-wrap">
                <input
                    type="text"
                    className="p-2 h-10 mx-1 w-full text-sm rounded inputCSS"
                    onChange={(searchEvent: React.ChangeEvent<HTMLInputElement>) => searchKeyword(searchEvent)}
                    data-testid="label-search-input"
                    ref={(input) => input && input.focus()}
                    placeholder="Photos"
                />
                {images.map((image: ImageData) => (
                    <>
                        <Palette isImage={true} downloadLink={image.links.download_location} backgroundItem={image.urls.full} backgroundImagePreview={image.urls.small} link={`${image.user.links.html + "?utm_source=tacitbase&utm_medium=referral&utm_campaign=api-credit"}`} click={setImage} name={image.user.name} />
                    </>
                ))}
            </div>
        </>
    )
}

export default BoardBgImageSelectionPreview
