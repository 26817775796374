import React, { useRef, useState } from "react";
import { MatchingSlots } from "../../../../lib/apiRes";
import { Overlay } from "../../../hook/Overlay";
import { Scope } from "../../../../type/type.d";
import { usePopper } from "react-popper";
import * as momentTz from 'moment-timezone';
type slotProps = {
  matchingSlots: MatchingSlots[];
  setSelectedSlot: (value: MatchingSlots) => void;
  selectedSlot: MatchingSlots | undefined;
  selectedTz: string;
  msgInSlotOnBlank: {loader:boolean,msg:string};
  findUserSlots:(value:boolean)=>void
};
const Slots = (props: slotProps) => {
  const [showPopup, setShowPopup] = useState(false);
  let [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>();
  let [popperElement, setPoperElement] = useState<HTMLDivElement | null>();
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 2.25],
        },
      },
      {
        name: "flip",
        options: {
          fallbackPlacements: ["top", "right"],
        },
      },
    ],
  });
  const refer = useRef(null);
  const popupRef = useRef(null);
  const formatDate = (dateString: string): string => {
    return momentTz
      .tz(dateString, props.selectedTz)
      .format("dddd DD MMMM, h:mm A");
  };
  return (
    <>
      {" "}
      <div className="w-5/12">
        <div className="flex flex-col space-y-2   ">
          <div className="input-label">Suggested Slots</div>
          <div
            ref={setReferenceElement}
            onClick={() =>(setShowPopup(!showPopup))}
            role="button"
            tabIndex={0}
            onKeyDown={() => {}}
          >
            <button
              className=" input-box cursor-pointer flex items-center justify-between z-10 "
              onClick={() => (setShowPopup(!showPopup),props.findUserSlots(true))}
              data-testid="suggestSlotDataTestBtn"
            >
              <div
                className="overflow-hidden text-sm"
                data-testId="selectedSlot"
              >
                {props.selectedSlot
                  ? formatDate(props.selectedSlot.startDate)
                  : "Select"}
              </div>
              <div className="text-xl mb-3"> &#8964;</div>
            </button>
          </div>
        </div>
        {showPopup ? (
          <Overlay
            onClose={() => (setShowPopup(false),props.findUserSlots(false))}
            scope={Scope.Dialog}
            reference={popupRef}
          >
            <div
              ref={setPoperElement}
              style={styles.popper}
              {...attributes.popper}
              className=""
            >
              <div
                className="w-full text-xs border bg-white shadow-sm   rounded"
                ref={refer}
              >
                <div
                  className=" popupDiv   p-2 bg-white w-full top-9 rounded shadow-2xl z-20 "
                  ref={popupRef}
                >
                  <div className=" flex flex-col space-y-1 min-h-min max-h-48 overflow-y-scroll w-full">
                    {" "}
                    {props.matchingSlots?.length > 0 &&
                      props.matchingSlots.map(
                        (slot: MatchingSlots, index: number) => (
                          <div
                            className=" p-1.5 flex items-center justify-between bg-gray-300 bg-opacity-25 hover:bg-gray-300 hover:bg-opacity-40 rounded  text-sm cursor-pointer"
                            key={index}
                            data-testId={formatDate(slot.startDate)}
                            aria-hidden
                            onClick={() => (
                              props.setSelectedSlot(slot), setShowPopup(false)
                            )}
                          >
                            {formatDate(slot.startDate)}
                          </div>
                        )
                      )}
                    {props.matchingSlots?.length == 0 && (
                      <div className=" p-1.5 flex items-center justify-between bg-gray-300 bg-opacity-25 hover:bg-gray-300 hover:bg-opacity-40 rounded  text-xs cursor-pointer">
                        {props.msgInSlotOnBlank.loader ? (
                         <div className="flex space-x-2">
                         {" "}
                         <div className="flex justify-end items-end">
                           <div
                             style={{
                               borderTopColor: "rgba(15, 10, 13,0.7)",
                             }}
                             className="w-4 h-4 border border-gray-300   border-solid rounded-full animate-spin"
                           ></div>
                         </div>
                         <div> {props.msgInSlotOnBlank.msg}</div>
                       </div>
                        ) : (
                          <>{props.msgInSlotOnBlank.msg}</>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Overlay>
        ) : null}
      </div>
    </>
  );
};

export default Slots;
