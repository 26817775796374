import React, { useState, useEffect } from "react";
import { Row, Col, Input, ConfigProvider } from "antd";
import { AxiosError } from "axios";
import { GetOpening, ImageData } from "../../../../../type/type";
import {
  getPhotosByUnsplash,
  setOpeningBackground,
  triggerUnsplashDownloadLink,
} from "../../../../../lib/api";
import { setBoardBackgroundColor } from "../../../../../redux/actions/opening/action";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { SOCKET_CLIENT_ID } from "../../../../../constant";

interface UnsplashImagesProps {
  prev: () => void;
  close: (e: boolean) => void;
  currentOpening: GetOpening;
  updateBoardBackground(selectedColor: string): void;
  socketClientID: string;
  applicationId: string;
  isActive: boolean; // New prop to indicate if the tab is active
}

const UnsplashImages: React.FC<UnsplashImagesProps> = ({
  currentOpening,
  applicationId,
  updateBoardBackground,
  isActive,
}) => {
  const [images, setImages] = useState<ImageData[]>([]);
  const [searchedLabel, setSearchedLabel] = useState("");
  const [selectedImageUrl, setSelectedImageUrl] = useState(currentOpening.color || "");

  const searchKeyword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchedLabel(event.target.value.trim());
  };

  useEffect(() => {
    if (isActive) {
      getPhotosByUnsplash(searchedLabel).then((res) => setImages(res));
    }
  }, [searchedLabel, isActive]);

  useEffect(() => {
    if (!isActive) {
      setSearchedLabel(""); // Clear search input when tab becomes inactive
    }
  }, [isActive]);

  const setImage = (imageUrl: string, downloadLocation?: string) => {
    setSelectedImageUrl(imageUrl);

    const req = {
      id: currentOpening.id,
      color: imageUrl,
      tenant_id: applicationId,
      socket_client_id: sessionStorage.getItem(SOCKET_CLIENT_ID),
    };
    setOpeningBackground(req).then((opening: any) => {
      updateBoardBackground(opening);
    });

    if (downloadLocation) {
      triggerUnsplashDownloadLink(downloadLocation).catch((err: AxiosError) =>
        console.error(err)
      );
    }
  };

  return (
    <div
      className="h-full w-full relative"
      style={{ height: "calc(-216px + 100vh)" }}
    >
      <div className="flex flex-col h-full">
        <div className="sticky top-0 border-b flex space-x-2 px-4">
          <Input
            placeholder="Search photos"
            value={searchedLabel}
            onChange={searchKeyword}
            className="w-1/2"
            style={{ marginBottom: 16 }}
          />
        </div>
        <div
          className="flex-1 overflow-y-auto py-4 pl-4 pr-2"
          style={{ height: "calc(-300px + 100vh)" }}
        >
          <Row gutter={[16, 16]}>
            {images.map((image, index) => (
              <Col  span={4} key={index}>
                <ConfigProvider
                  theme={{
                    token: {
                      paddingLG: 16,
                    },
                  }}
                >
                  <div className={`relative group p-1 rounded  ${
                    selectedImageUrl === image?.urls?.full ? "border border-tacitbase-secondary3" : ""
                  }`}>
                    <div
                      onKeyDown={() => {}}
                      role="button"
                      tabIndex={0}
                      onClick={() =>
                        setImage(
                          image?.urls?.full,
                          image?.links?.download_location
                        )
                      }
                      className="bg-cover bg-center hover:opacity-90 bg-no-repeat w-full  rounded relative"
                      style={{
                        backgroundImage: `url(${image?.urls?.small})`,
                        height: 100,
                      }}
                    >
                      <a
                        href={image?.user?.links?.html}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="absolute bottom-0 rounded-b left-0 w-full bg-gray-900 bg-opacity-50 text-white text-center p-1 text-xs opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                      >
                        {image?.user?.name}
                      </a>
                    </div>
                  </div>
                </ConfigProvider>
              </Col>
            ))}
          </Row>
        </div>
      </div>
      <div className="sticky bg-white text-xs text-center pt-2">
        By using images from{" "}
        <a
          href="https://unsplash.com"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500"
        >
          Unsplash
        </a>
        , you agree to their{" "}
        <a
          href="https://unsplash.com/license"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500"
        >
          license
        </a>{" "}
        and{" "}
        <a
          href="https://unsplash.com/terms"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500"
        >
          terms of service
        </a>
        .
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
  socketClientID: state.opening.clientId,
  applicationId: state.opening.applicationId,
});
const mapPropsToState = (dispatch: Dispatch) => ({
  updateBoardBackground: (opening: any) =>
    dispatch(setBoardBackgroundColor(opening)),
});
export default connect(mapStateToProps, mapPropsToState)(UnsplashImages);
