import React, { useEffect, useState } from "react";
import CloseIcon from "../../../assets/Close";
import Image from "../../common/image/Image";
import meet from "../../../images/meet.png";
import zoom from "../../../images/zoom.png";
import CTools from "../../calender/cardScheduler/cardPopupContentComponents/ConferenceTools";
import {
  addingMeetToBoardCalendar,
  addingZoomToBoardCalendar,
  getBoardCalenderSettingsOfCTools,
  removeMeetFromBoardUsingCalID,
  removeZoomFromBoardUsingCalID,
} from "../../../lib/api";
import { connect } from "react-redux";
import { loginUser } from "../../../type/type";
import { Dispatch } from "redux";
import { calendar_conference_settingsRes } from "../../../lib/apiRes";
import { setCurrentCalConferenceSettings } from "../../../redux/actions/opening/action";
import { throwSuccess } from "../../../service/throwError";
type conferenceSettingsProps = {
  calenderId: string;
  loginUser: loginUser;
  setCurrentCalender(data: calendar_conference_settingsRes): void;
  currentCalendarConferenceSettings: calendar_conference_settingsRes;
};
const ConferenceSettings = (props: conferenceSettingsProps) => {
  const [selectedTool, setSelectedTool] = useState<string>("");
  const [boardTools, setBoardTools] = useState<any>();

  useEffect(() => {
    selectedTool == "meet"&& boardTools.meet_email==""
      ? addingMeetToBoardCalendar(props.calenderId).then((res) => {
        if (res) {
          throwSuccess("Meet added successfully." )

          getBoardCalenderSettingsOfCTools(props.calenderId).then((res) => {
            props.setCurrentCalender(res.calendar_conference_settings);
            setSelectedTool("");
          });
        }
        })
      : selectedTool == "zoom" && boardTools.zoom_email==""
      ? addingZoomToBoardCalendar(props.calenderId).then((res) => {
          if (res) {
            throwSuccess("Zoom added successfully.")
            getBoardCalenderSettingsOfCTools(props.calenderId).then((res) => {
              props.setCurrentCalender(res.calendar_conference_settings);
              setSelectedTool("");
            });
          }
        })
        : setSelectedTool("");
  }, [selectedTool]);
  useEffect(() => {
    getBoardCalenderSettingsOfCTools(props.calenderId).then((res) => {
      props.setCurrentCalender(res.calendar_conference_settings);
    });
  }, []);
  useEffect(() => {
    setBoardTools(props.currentCalendarConferenceSettings);
  }, [props.currentCalendarConferenceSettings]);
  const removeZoom_Meet = (id: string) => {
    id == "zoom"
      ? removeZoomFromBoardUsingCalID(props.calenderId).then((res) => {
          if (res) {
            throwSuccess("Zoom removed successfully.")
  
            getBoardCalenderSettingsOfCTools(props.calenderId).then((res) => {
              if (res)
                props.setCurrentCalender(res.calendar_conference_settings);
            });
            setSelectedTool("");
          }
        })
      : id == "meet"
      ? removeMeetFromBoardUsingCalID(props.calenderId).then((res) => {
          if (res) {
            throwSuccess("Meet removed successfully." )
            getBoardCalenderSettingsOfCTools(props.calenderId).then((res) => {
              if (res)
                props.setCurrentCalender(res.calendar_conference_settings);
            });
            setSelectedTool("");
          }
        })
      : null;
  };
  return (
    <>
      <div>
        <div className="">
          <div className="tracking-normal  items-center text-sm font-medium text-black w-full">
            Conference integration
          </div>
        </div>
        <div className="">
          <div className="flex flex-col space-y-1">
            <div className="tracking-normal  items-center text-sm font-normal text-gray-600 w-full">
            Connect your video conference tool (e.g., Zoom, Meet) for upcoming events to be scheduled on this job tracker.
            </div>{" "}
            <div className="py-2">
              <CTools
                selectedTool={(value) => setSelectedTool(value)}
                deafaultValue={selectedTool}
              />
            </div>
            {boardTools ? (
              <div>
                <div className="flex flex-col space-y-1">
                  {boardTools.meet_email ? (
                    <div className="flex items-center justify-start space-x-2">
                      <div className="">
                        <Image src={meet} />
                      </div>
                      <div className="bg-white border border-tacitbase-tertiary1 border-opacity-25 px-2 py-1 text-xs rounded w-full flex items-center justify-between ">
                        {" "}
                        {boardTools.meet_email}
                        <div>
                          {" "}
                          <button
                            id="meet" data-testid="removeToolBtnMeet" 
                            onClick={() => removeZoom_Meet("meet")}
                          >
                            <CloseIcon size="9" />
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {boardTools.zoom_email ? (
                    <div className="flex items-center justify-start space-x-2">
                      <div className="">
                        <Image src={zoom} />
                      </div>
                      <div className="bg-white border border-tacitbase-tertiary1 border-opacity-25 px-2 py-1 text-xs rounded w-full flex items-center justify-between ">
                        {" "}
                        {boardTools.zoom_email}
                        <div>
                          {" "}
                          <button data-testid="removeToolBtnZoom" onClick={() => removeZoom_Meet("zoom")}>
                            <CloseIcon size="9" />
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  loginUser: state.opening.loginUser,
  calenderId: state.opening.calendar.currentCalenderId,
  currentCalendarConferenceSettings:
    state.opening.calendar.currentCalendarConferenceSettings,
});

const mapPropsTostate = (dispatch: Dispatch) => ({
  setCurrentCalender: (data: calendar_conference_settingsRes) =>
    dispatch(setCurrentCalConferenceSettings(data)),
});
export default connect(mapStateToProps, mapPropsTostate)(ConferenceSettings);
