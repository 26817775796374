import React from "react";
import { Alert, Button, Divider, Form, Input, Typography } from "antd"
import { registration, setTokenInLocalStorage } from "../../lib/api";
import { loginUserAc } from "../../redux/actions/opening/action";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch } from "redux";
import Link from "antd/es/typography/Link"
const { Text } = Typography

type SignUpFormProps = {
  loginUserAc(token: string, data: any): void;
} & RouteComponentProps;

class SignupForm extends React.Component<SignUpFormProps> {
  formRef = React.createRef<any>();
  state = {
    validMobile: true,
    loading: false,
    errorMessage: "", // Add a state to store the error message
  };
  validatePassword = (_: any, value: any) => {
    if (!value) {
      const errorMessage = "Please enter a password!"
      return Promise.reject(new Error(errorMessage))
    }
    // Use regular expressions to check for specific conditions
    const hasUppercase = /[A-Z]/.test(value)
    const hasLowercase = /[a-z]/.test(value)
    const hasSpecialCharacter = /[!@#$%^&*(),.?":{}|<>]/.test(value)

    // Check for the required conditions
    if (
      value &&
      value.length >= 12 &&
      hasUppercase &&
      hasLowercase &&
      hasSpecialCharacter
    ) {
      return Promise.resolve()
    }

    // Create an array to store error messages
    const errorMessages = []

    // Check each condition and add an error message if not met
    if (value.length < 12) {
      errorMessages.push("At least 12 characters")
    }
    if (!hasUppercase) {
      errorMessages.push("1 uppercase")
    }
    if (!hasLowercase) {
      errorMessages.push("1 lowercase")
    }
    if (!hasSpecialCharacter) {
      errorMessages.push("1 special character")
    }

    // Combine error messages and return the rejection with the message
    const errorMessage = `Password must have: ${errorMessages.join(", ")}`
    return Promise.reject(new Error(errorMessage))
  }
  onFinish = (values: any) => {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.setState({ loading: true });
    let userValues = {
      ...values,
      timezone: userTimezone,
    };
    registration({
      user: userValues,
    }).then((res) => {
      setTokenInLocalStorage("login_token", res.token).then((flag: any) => {
        if (flag) {
          this.props.loginUserAc(res.token, res.user);

          if (!res.user.verified) {
            this.props.history.push("/onboarding");
          }
        }
        this.setState({ loading: false });
      });
    }).catch((error) => {
      if (error.response && error.response.status === 409) {
        this.formRef.current.setFields([
          {
            name: "email",
            errors: ["An account already exist with this email address"],
          },
        ]);
        this.setState({ loading: false }); 
      } else if (error.response && error.response.status === 422){
        this.formRef.current.setFields([
          {
            name: "email",
            errors: [error.response?.data?.error?.message],
          },
        ]);
        this.setState({ loading: false });
      }
    }).finally(()=>{
      this.setState({ loading: false }); 
    });
  };

  render() {
    return (
      <div className="flex flex-col md:space-y-4 space-y-2 relative w-full bg-bg">
        <div className="w-full flex flex-col space-y-2 items-center justify-center ">
          <div className="font-semibold md:text-lg text-base">
            {" "}
            {"Sign up free"}
          </div>
          {this.state.errorMessage && (
            <Alert message={this.state.errorMessage} type="error" />
          )}
         
        </div>
        <hr />

        <Form
          name="normal_login"
          className="login-form text-xs w-full"
          initialValues={{ remember: true }}
          onFinish={this.onFinish}
          layout="vertical"
          ref = {this.formRef}
        >
          <div className="flex w-full xl:space-x-6 space-x-4">
            <Form.Item
              className="w-1/2 font-medium"
              name="firstName"
              label="First name"
              rules={[
                { required: true, message: "Please input your First Name!" },
              ]}
            >
              <Input placeholder="John" />
            </Form.Item>
            <Form.Item
              className="w-1/2 font-medium"
              name="lastName"
              label="Last name"
              rules={[
                { required: true, message: "Please input your Last Name!" },
              ]}
            >
              <Input placeholder="Doe" />
            </Form.Item>
          </div>
          <Form.Item
            name="email"
            label="Work email"
            rules={[
              { required: true, message: "Please input your Work Email!" },
              { type: 'email', message: 'Invalid email format' },
            ]}
            className=" font-medium"
          >
            <Input placeholder="johndoe@yourcompany.com" />
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            className=" font-medium"
            rules={[{ required: true, validator: this.validatePassword }]}

          >
            <Input.Password />
          </Form.Item>
          <Form.Item>
            <Button
              loading={this.state.loading} // Use the loading prop
              htmlType="submit"
              type={"primary"}
              className="w-full"
            >
              Sign up
            </Button>
          </Form.Item>
        </Form>
        <div className=" ">
          <div className="  ">
            By signing up, you agree to our&nbsp;
            <a
           
              href="https://www.tacitbase.com/terms-of-use"
              style={{ color: "#009D79" }}
            >
              terms of use{" "}
            </a>
            &nbsp;and&nbsp;
            <a
              href="https://www.tacitbase.com/privacy"
              style={{ color: "#009D79" }}
            >
              {" "}
              privacy policy.
            </a>
            &nbsp;Need help? Visit&nbsp;
            <a
              href="https://support.tacitbase.com/"
              style={{ color: "#009D79" }}
            >
              support.
            </a>
          </div>
        </div>
        <Divider />
        <div className="text-center mt-3">
          <Text>Already have an account?

          </Text> <Link href="/login">Sign in</Link>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loginUserAc: (token: string, data: any) => dispatch(loginUserAc(token, data)),
});

export default connect(null, mapDispatchToProps)(withRouter(SignupForm));
