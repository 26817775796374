export function validation(subject: string): boolean {
    return subject.length === 0;
  }
  
  export function setHeight(): void {
    const composeMailBox = document.getElementById("composeMailbox");
  const mailheader = document.getElementById("mailheader") ? document.getElementById("mailheader") : null;
  const mailSendButton = document.getElementById("mailSendButton");
    const composeMailEditor = document.getElementById("composeMailEditor");
    const composeMailAddress = document.getElementById("composeMailAddress");
    const trix: any = document.getElementsByClassName("composeMail");
  
    if (
      composeMailEditor &&
      composeMailAddress &&
      mailSendButton &&
      composeMailBox 
    ) {
      const middleBox =
        composeMailBox.offsetHeight -
        (mailheader ? mailheader.offsetHeight : 0) - 
        mailSendButton.offsetHeight;
  
      if (trix.length > 0) {
        const element = trix[0];
        element.style.height = middleBox - composeMailAddress.offsetHeight - 62 + "px";
      }
      composeMailEditor.style.maxHeight = middleBox + "px";
    }
  }
  