import React from "react";
import AddIcon from "../../../../assets/Add";
import TickIcon from "../../../../assets/Tick";
import { ApplicantMembers, Member, orgMember } from "../../../../type/type";
import TacitbaseAvtar from "../../../../ant/Avtar"
import { generateTooltipForMember } from "../../../../utils/capitalizeFirstLetter"
type suggestedProps = {
  orgMembers: orgMember;
  applicantMembers: ApplicantMembers;
  currentApplicant: string;
  addThisGuest: (member: Member) => void;
  addAll: () => void;

  guest: string[];
};
const Suggested = (props: suggestedProps) => {
  return (
    <div className="flex flex-col   max-w-full w-full   space-y-1">
      <div className="flex items-center space-x-2 justify-between ">
        <div>Suggested Guests</div>{" "}
        <button
          className="font-base text-tacitbase-secondary3 text-opacity-90 underline cursor-pointer"
          onClick={() => props.addAll()}
        >
          {" "}
          Add Suggested
        </button>
      </div>
      <div className=" min-h-0 max-h-40 md:max-h-44 lg:max-h-52  xl:max-h-70 2xl:max-h-80 overflow-y-scroll flex flex-col space-y-1">
      {props.applicantMembers[props.currentApplicant]
        ? props.applicantMembers[props.currentApplicant].map(
            (member: string, i: number) =>
              props.orgMembers[member] && props.orgMembers[member].registrations[0].data.activated ? (
                <div
                  key={i}
                  className="p-1.5 flex items-center justify-between bg-gray-300 bg-opacity-25 hover:bg-gray-300 hover:bg-opacity-40 rounded cursor-pointer"
                  onClick={() => props.addThisGuest(props.orgMembers[member])}
                  aria-hidden
                >
                  <div className="flex flex-row items-center space-x-2 justify-between w-full">
                    <div className="flex space-x-2 items-center">
                      {" "}
                      <div className="flex flex-col">
                      {props.orgMembers[member].image_url ? (
                        <TacitbaseAvtar
                          toolTip={generateTooltipForMember(props.orgMembers[member])}
                          src={props.orgMembers[member].image_url}
                          size="default"
                        />
                      ) : null}
                      </div>
                      <div className="flex flex-col">
                        <div className="leading-snug font-bold text-sm text-gray-800 tracking-normal line-clamp-1">
                          {props.orgMembers[member].username}
                        </div>
                        <div className="text-xs leading-snug tracking-wide text-gray-800">
                          {props.orgMembers[member].email}
                        </div>
                      </div>
                    </div>
                    <div>
                      {!props.guest.includes(props.orgMembers[member].id) ? (
                        <AddIcon size="13px" />
                      ) : (
                        <TickIcon size="13px" />
                      )}
                    </div>
                  </div>
                </div>
              ) : null
          )
        : null}
        </div>
    </div>
  );
};

export default Suggested;
