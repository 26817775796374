import { AxiosError } from "axios";
import React, {  useRef } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { useState } from "react";
import {
  addAttachments,
  attachmentLoader,
} from "../../../redux/actions/opening/action";
// import { uploadFile } from "../../../lib/api";
import { AddAttachmentProps } from "../../../type/cardActionButton";
import PopupTitle from "../../common/popupTitle/PopupTitle";
import { toast } from "react-toastify";
import { addAttachment } from "../../../lib/api";
import { MAX_ATTACHMENT_SIZE, SOCKET_CLIENT_ID, UPLOAD_FROM_COMPUTER } from "../../../constant"
import ShowError from "../../../service/ShowError";
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";

const AddAttachment = (props: AddAttachmentProps) => {

  let fileInput = useRef<HTMLInputElement>(null);
  const [file_Exceed, isExceed] = useState(false);
  const onFileChange = (file: any) => {
    if (file.target.files[0].size > 10000000) {
      isExceed(true);
    } else {
      isExceed(false);
      const formData = new FormData();
      formData.append("model_name", "applicants");
      formData.append("record_id", props.currentApplicant.id);
      formData.append("opening_id", props.currentOpening.id);
      let clientId=sessionStorage.getItem(SOCKET_CLIENT_ID)
      formData.append("socket_client_id",clientId?clientId:"");
      formData.append("list_id", props.currentApplicant.list_id);

      for (let i = 0; i < file.target.files.length; i++) {
        formData.append(`file`, file.target.files[i]);
      }
      props.attachmentLoader();
      toast.warn(<ShowError msg="Uploading" />, {
        position: toast.POSITION.TOP_RIGHT,
        toastId: "customId",
        autoClose: false,
      });
      addAttachment(formData, props.currentApplicant.id)
        .then((res: any) => {
          props.addAttachements(res, props.currentApplicant.list_id);
          props.attachmentLoader();
          props.click(false);
          toast.update("customId", {
            render: () => (
              <div className="text-sm">
                {capitalizeFirstLetter("uploading finish")}
              </div>
            ),
            type: toast.TYPE.INFO,
            autoClose: 1000,
          });
        })
        .catch((reason: AxiosError) => {
          toast.error(reason.response?.data.error.type, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  return (
    <React.Fragment>
      <div
        className="flex flex-col h-auto mt-1 z-10 w-72 bg-white text-gray-800 tracking-normal  text-sm font-normal border border-gray-300  rounded"
        ref={props.refer}
      >
        <div className="my-3">
          <PopupTitle PopupTitle={props.name} close={props.click} />
        </div>
        {file_Exceed && (
          <div className="bg-red-50 p-4 mx-2">{MAX_ATTACHMENT_SIZE}
          </div>
        )}
        <div className="m-3">
          <input
            type="file"
            ref={fileInput}
            data-testid="input-attachment"
            style={{ display: "none" }}
            onChange={(file: any) => onFileChange(file)}
            multiple
          />
          <button
            className="items-center bg-gray-200 w-full rounded cursor-pointer focus:outline-none border-none py-2"
            onClick={() => fileInput.current?.click()}
            data-testid="add-attachment"
          >
            {UPLOAD_FROM_COMPUTER}
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (state: any) => ({
  currentApplicant: state.opening.currentApplicant,
  clientId: state.opening.clientId,
  currentOpening: state.opening.currentOpening,
});
const mapPropsTostate = (dispatch: Dispatch) => ({
  addAttachements: (attachment: any, listID: string) =>
    dispatch(addAttachments(attachment, listID)),
  attachmentLoader: () => dispatch(attachmentLoader()),
});
export default connect(mapStateToProps, mapPropsTostate)(AddAttachment);
