import React from "react";
import { Button, Typography } from "antd";
import TacitBaseModal from "../../../ant/TacitbaseModal";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

const { Text } = Typography;

type DomainInfoModalProps = {
  isModalVisible: boolean;
  handleCancel: () => void;
  applicationId:string;
};

const DomainModal: React.FC<DomainInfoModalProps> = (
  props: DomainInfoModalProps
) => {
  const history = useHistory()
  const handleSetUpDomain = ()=>{
    history.push({
      pathname: `/o/${props.applicationId}/settings/organization`,
    });
  }
  return (
    <>
      <TacitBaseModal
        closable={false}
        maskClosable={true}
        onCancel={props.handleCancel}
        visibility={props.isModalVisible}
        title = "Email Configuration Required"
        content={
          <>
            <div className="flex">
                <Text>
                You haven’t set up your custom domain yet. Please configure your custom domain to send emails.
                </Text>
            </div>
          </>
        }
        visible={true}
        footer={
          <>
          <Button  size="small" onClick={props.handleCancel}>Cancel</Button>
          <Button type="primary" onClick={handleSetUpDomain} size="small">Setup now</Button>
          </>
        }
        centered
        width={480}
      />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  applicationId: state.opening.applicationId,
});

export default connect(mapStateToProps) (DomainModal);
