import React from "react";
const MembersSmallIcon = (props: { fill?: string; size?: string }) => {
  return (
    <svg
      width={`${props.size ? props.size : "16px"}`}
      height={`${props.size ? props.size : "16px"}`}
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Members</title>
      <g
        id="Icons-/-16x16-/-Change-Members"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M10.3333333,3.33333333 C12.1452149,3.33333333 13.6140351,4.80215354 13.6140351,6.61403509 C13.6140351,7.54715154 13.2244694,8.38928005 12.5991051,8.98665348 C14.7883144,9.61999272 15.9218643,11.3147252 16,14.0701754 L13.968921,14.0698219 C13.9450377,13.7599808 13.9094137,13.4620022 13.8620492,13.1758864 L15.1052632,13.1754386 C14.798482,11.4497948 13.8845627,10.348185 12.3635051,9.8706092 C11.9972109,9.47764028 11.5703885,9.14716027 11.082486,8.87841883 C12.0331416,8.5659782 12.7192982,7.67013688 12.7192982,6.61403509 C12.7192982,5.35704226 11.7472735,4.32707799 10.5138805,4.23479881 C10.4239159,3.91869436 10.295577,3.61784222 10.1344092,3.33830517 C10.2006005,3.33531775 10.2667352,3.33333333 10.3333333,3.33333333 Z M6.33333333,2 C8.35837742,2 10,3.64162258 10,5.66666667 C10,6.70929899 9.56482221,7.65029009 8.86619425,8.31791235 C11.3128832,9.02694429 12.5793542,10.9209302 12.6666667,14 L0,14 C0.110483785,10.9344609 1.38075941,9.04361303 3.81082688,8.32745643 C3.10641003,7.66013376 2.66666667,6.714754 2.66666667,5.66666667 C2.66666667,3.64162258 4.30828925,2 6.33333333,2 Z M7.64681608,9.09011815 L7.63200071,9.0967078 C7.22831926,9.24962937 6.79060135,9.33333333 6.33333333,9.33333333 C5.87415526,9.33333333 5.43469095,9.24892863 5.02960907,9.09478787 C2.84973069,9.42845583 1.52356509,10.6482735 1.05063348,12.7539062 L1,13 L11.6666667,13 C11.2637504,10.7335958 9.92380017,9.43030185 7.64681608,9.09011815 Z M6.33333333,3 C4.860574,3 3.66666667,4.19390733 3.66666667,5.66666667 C3.66666667,7.139426 4.860574,8.33333333 6.33333333,8.33333333 C7.80609267,8.33333333 9,7.139426 9,5.66666667 C9,4.19390733 7.80609267,3 6.33333333,3 Z"
          id="Combined-Shape"
          fill={`${props.fill ? props.fill : "#161E25"}`}
        ></path>
      </g>
    </svg>
  );
};
export default MembersSmallIcon;
