import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
  COMMENT_TAG,
  customFormats,
  customModules,
  SOCKET_CLIENT_ID,
} from "../../../../constant";
import { saveComment } from "../../../../redux/actions/opening/action";
import { currentApplicant, loginUser } from "../../../../type/type";
import Editor from "../../../../ant/Editor/Editor";
import { SendOutlined } from "@ant-design/icons";
import { createComment } from "../../../../lib/api";
import { UnprivilegedEditor } from "react-quill";
import { Divider } from "antd";
import { LabeledIconButton } from "../../../../ant/Button";
export type CommentInputProps = {
  currentApplicant: currentApplicant;
  saveComment(comment: any, listID: string): void;
  loginUser: loginUser;
  clientId: string;
};

const CommentInput = (props: CommentInputProps) => {
  const ref = useRef<HTMLTextAreaElement>(null);
  const [newComment, setnewComment] = useState<string>("");
  const [commentValidationMessage, setCommentValidationMessage] =
    useState<string>("");
  const [isFocused, setIsFocused] = useState(false);

  const handleKeyDownComment = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.keyCode === 13 && e.metaKey) {
      // Save the comment when Command + Enter is pressed
      handleCommentSave();
    }
  };
  const handleBlur = () => {
    setIsFocused(false);
  };
  const handleFocus = () => {
    setIsFocused(true);
  };

  // Validate comment length and update state
  const handleEditorChange = (
    content: string,
    delta: any,
    source: string,
    editor: UnprivilegedEditor
  ) => {
    if (editor.getText().length > 0) {
      setnewComment(content);

      if (content.trim().length > 5000) {
        // If it exceeds, set the validation message
        setCommentValidationMessage("Your comment is too long");
      } else {
        // If it's within the limit, clear the validation message
        setCommentValidationMessage("");
      }
    }
  };

  // Save comment when Enter is pressed or Send button is clicked
  const handleCommentSave = () => {
    if (!newComment) return;
    if (commentValidationMessage.length > 0) return;
    const postComment = {
      body: newComment.trim(),
      member_id: props.loginUser.user.id,
      applicant_id: props.currentApplicant.id,
      opening_id: props.currentApplicant.opening_id,
      socket_client_id: sessionStorage.getItem(SOCKET_CLIENT_ID),
      list_id: props.currentApplicant.list_id,
    };
    createComment(postComment).then((savedComment: any) => {
      props.saveComment(savedComment, COMMENT_TAG);
      setnewComment("");
    });
  };

  useEffect(() => {
    ref.current?.focus();
  }, []);

  return (
    <div className="px-4">
      <div className="w-full comment-editor sticky bottom-0  bg-white">
        <Divider className="mb-3  mt-2 bg-white" />
        <div
          className={` rounded ${
            isFocused ? "quill-editor-focused" : "border"
          }`}
          style={{
            boxShadow:
              "var(--ds-shadow-raised,0 1px 1px #091e4240,0 0 1px #091e424f)",
          }}
        >
          <Editor
            onFocus={handleFocus}
            modules={customModules}
            onKeyDown={handleKeyDownComment}
            formats={customFormats}
            defaultValue={newComment}
            value={newComment}
            initialValue={newComment}
            onChange={handleEditorChange}
            onBlur={handleBlur}
          />
          <div className="flex w-full py-2 pl-4 pr-2">
            <div className="justify-start flex w-full text-red-600">
              {commentValidationMessage}
            </div>
            <div className="flex justify-end">
              <LabeledIconButton
                size="small"
                label=""
                type="primary"
                icon={<SendOutlined />}
                onClick={handleCommentSave}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  currentApplicant: state.opening.currentApplicant,
  loginUser: state.opening.loginUser,
  clientId: state.opening.clientId,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  saveComment: (comment: any, listID: string) =>
    dispatch(saveComment(comment, listID)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CommentInput);
