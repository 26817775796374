import React from "react";
const TickIcon = (props: any) => {
  return (
    <svg
      width={props.size ? props.size : "16px"}
      height={props.size ? props.size : "16px"}
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Tick</title>
      <g
        id="Icons-/-16x16-/-Tick"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M1.75,4 C2.16421356,4 2.5,4.33578644 2.5,4.75 L2.5,9 L14.25,9 C14.6296958,9 14.943491,9.28215388 14.9931534,9.64822944 L15,9.75 C15,10.1642136 14.6642136,10.5 14.25,10.5 L14.25,10.5 L1.75,10.5 C1.33578644,10.5 1,10.1642136 1,9.75 C1,9.66220473 1.0150854,9.57793285 1.04280554,9.499635 C1.01521461,9.42278736 1,9.33817046 1,9.25 L1,4.75 C1,4.33578644 1.33578644,4 1.75,4 Z"
          id="Combined-Shape"
          fill={`${props.fill ? props.fill : "#161E25"}`}
          transform="translate(8.000000, 7.250000) rotate(-45.000000) translate(-8.000000, -7.250000) "
        ></path>
      </g>
    </svg>
  );
};
export default TickIcon;
