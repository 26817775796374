import React from "react";
import {  Typography, Button, Result } from "antd";
import { useHistory } from "react-router-dom";

const {  Text } = Typography;

const EmailVerifiedPage: React.FC = () => {
  const history = useHistory();

  const handleLogin = () => {
    history.push("/login");
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Result
        status="success"
        title="Email Verified!!"
        subTitle="Your email has been successfully verified."
        extra={[
          <>
            <Text style={{ display: "block", marginTop: "10px" }}>
              Please log in to continue.
            </Text>
            <Button
              type="primary"
              onClick={handleLogin}
              style={{ marginTop: "20px", width: "100%" }}
            >
              Go to Login
            </Button>
          </>,
        ]}
      />
    </div>
  );
};

export default EmailVerifiedPage;
