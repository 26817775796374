import React, { useEffect, useState } from "react";
import CToolsDropdown from "./ConferenceDropdown";
import zoom from "../../../../images/zoom.png";
import meet from "../../../../images/meet.png";
import { getZoomMeetUser } from "../../../../lib/api";
import { loginUser } from "../../../../type/type";
import { connect } from "react-redux";

type cToolsProps = {
  loginUser: loginUser;
  selectedTool: (tool: string) => void;
  deafaultValue: string;
};
const CTools = (props: cToolsProps) => {
  const [zoomMeetUser, setZoomMeetUser] = useState({
    zoom_email: "",
    google_email: "",
  });
  const [toolMenuState, setToolMenuState] = useState(0);

  useEffect(() => {
    props.loginUser.user.id?
    getZoomMeetUser(props.loginUser.user.id).then((res) => {
      setZoomMeetUser(res.user_conference_settings);
    }): null ;
  }, []);

  const [selectedRole, selectRole] = useState<string>(
   ""
  );
  const [toolMenu, setToolMenu] = useState({
    zoom: {
      name: "Connect Zoom Video Meeting",
      id: "zoom",
      image: zoom,
      value: false,
      active: false,
    },
    meet: {
      name: "Add Google meet Video Conferencing",
      id: "meet",
      image: meet,
      value: false,
      active: false,
    },
  });
  useEffect(() => {
    zoomMeetUser.google_email && zoomMeetUser.zoom_email
      ? (setToolMenu({
          zoom: {
            ...toolMenu.zoom,
            name: zoomMeetUser.zoom_email,
            value: true,
          },
          meet: {
            ...toolMenu.meet,
            name: zoomMeetUser.google_email,
            value: true,
          },
        }),
        setToolMenuState(1))
      : null;
    zoomMeetUser.zoom_email && !zoomMeetUser.google_email
      ? (setToolMenuState(1),
        setToolMenu({
          ...toolMenu,
          zoom: {
            ...toolMenu.zoom,
            name: zoomMeetUser.zoom_email,
            value: true,
          },
        }))
      : !zoomMeetUser.zoom_email && zoomMeetUser.google_email
      ? (setToolMenuState(1),
        setToolMenu({
          ...toolMenu,
          meet: {
            ...toolMenu.meet,
            name: zoomMeetUser.google_email,
            value: true,
          },
        }))
      : !zoomMeetUser.zoom_email && !zoomMeetUser.google_email
      ? setToolMenuState(0)
      : null;
  }, [zoomMeetUser]);

  useEffect(() => {
    props.selectedTool(selectedRole)
  }, [selectedRole])
  useEffect(() => {
    selectRole(props.deafaultValue ? props.deafaultValue : "")
  }, [props.deafaultValue])
  return (
    <div className="flex flex-col  w-full">
      <div className="tracking-normal pb-2   items-center text-sm font-medium text-black w-full">
        Add video conference tool
      </div>{" "}
      <CToolsDropdown
        selectedItem={(item: string) => selectRole(item)}
        options={toolMenu}
        selectedRole={selectedRole}
        width={"w-full"}
        toolMenuState={toolMenuState}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  loginUser: state.opening.loginUser,
});
export default connect(mapStateToProps)(CTools);
