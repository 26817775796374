import React, { useEffect, useState } from "react";
import { Button, Checkbox, Form, Popover, Typography } from "antd";
import { CheckOutlined, DownOutlined, SyncOutlined } from "@ant-design/icons";
import { COLOR_DATA_EVENT } from "../../../../../constant";
import {
  Opening,
  calendar,
  loginUser,
  updatedColor,
} from "../../../../../type/type";
import { Dispatch } from "redux";
import { Calendar } from "../../../../../lib/apiRes";
import { UpdateCurrentCal } from "../../../../../redux/actions/opening/action";
import { connect } from "react-redux";
import EventColorSelectionPopup from "../../../../sidePanal/calendarSettings/EventColorSelection";
import EventDuration from "./EventDuration";
import { updateBoardCalendar } from "../../../../../lib/api";
import { throwSuccess } from "../../../../../service/throwError";
import BoardConferenceSettings from "../../../../sidePanal/calendarSettings/BoardConferenceSettings";

const { Text } = Typography;

type Color = {
  name: string | undefined;
  code: string;
};

type SettingsPageProps = {
  calenderId: string;
  loginUser: loginUser;
  opening: Opening;
  applicationId: string;
  currentCalendar: Calendar;
  UpdateCurrentCalendar(data: Calendar): void;
};

const EventSettings = ({
  calenderId,

  currentCalendar,
  UpdateCurrentCalendar,
}: SettingsPageProps) => {
  const [selectedColor, setSelectedColor] = useState<Color>({
    name: "",
    code: "",
  });
  const [initialState, setInitialState] = useState<calendar>(currentCalendar);
  const [saveStatus, setSaveStatus] = useState<"Saving" | "Changes saved" | "">(
    ""
  );
console.log(selectedColor)
  const [defaultColors, setDefaultColors] = useState(COLOR_DATA_EVENT);

  const [eventBoardSettings, setEventBoardSettings] =
    useState<calendar>(currentCalendar);

  const zones = ["30 min", "60 min", "90 min"];

  useEffect(() => {
    const currentState = { ...eventBoardSettings };
    if (JSON.stringify(initialState) !== JSON.stringify(currentState)) {
      setSaveStatus("Saving");
      updateCalendar();
    }
  }, [eventBoardSettings]);
  const updateCalendar = () => {
    updateBoardCalendar(eventBoardSettings, calenderId).then((res) => {
      UpdateCurrentCalendar(res.calendar);
      if (res)
        setTimeout(() => {
          setInitialState(res.calendar);
          setSaveStatus("Changes saved"); // Show saved status
          throwSuccess("Calendar settings updated successfully.");
        }, 1000);
      setTimeout(() => setSaveStatus(""), 2000); // Reset save status      setInitialState(res.calendar);
      COLOR_DATA_EVENT.forEach((e) => {
        if (e.colorCode === res.calendar.default_event_color)
          setSelectedColor({ name: e.name, code: e.colorCode });
      });
    });
  };

  const setSelectedColorFunc = (index: number) => {
    const updatedColors: any = [];
    COLOR_DATA_EVENT.forEach((color: updatedColor, i) => {
      if (i === index) {
        color.isSet = true;
        setSelectedColor({ name: color.name, code: color.colorCode });
        setEventBoardSettings((prev: any) => ({
          ...prev,
          default_event_color: color.colorCode,
        }));
        updatedColors.push(color);
      } else {
        color.isSet = false;
        updatedColors.push(color);
      }
    });
    setDefaultColors(updatedColors);
  };

  const popoverContent = (
    <div style={{ display: "flex", flexWrap: "wrap", maxWidth: "250px" }}>
      {defaultColors.map((label, idx) => (
        <div key={idx} >
          <EventColorSelectionPopup
            colorCode={`${label.colorCode}`}
            isSet={label.isSet}
            click={() => setSelectedColorFunc(idx)}
            showMore={label.showMore}
          />
        </div>
      ))}
    </div>
  );
  // Render save status indicator beside the title "Event settings"
  const renderSaveStatus = () => {
    if (saveStatus === "Saving") {
      return (
        <span className="w-full font-bold" style={{ color: "#009D79" }}>
          <SyncOutlined
            spin={true}
            style={{ color: "#009D79", marginRight: 4 }}
          />{" "}
          Saving changes...
        </span>
      );
    } else {
      return (
        <>
          {saveStatus && (
            <span style={{ color: "#009D79" }} className="font-bold ">
              <CheckOutlined
                size={100}
                style={{ color: "#009D79", marginRight: 4 }}
                type="success"
              />
              {saveStatus}
            </span>
          )}
        </>
      );
    }
  };
  return (
    <>
      <div
        className="flex items-center space-x-2 px-4 border-b"
        style={{ height: 44 }}
      >
        <div>
          <Text>Event settings</Text>
        </div>
        <div> {renderSaveStatus()}</div>
      </div>

      <div className="h-full w-full" style={{ height: "calc(100vh - 250px)" }}>
        <div className="flex flex-col h-full">
          <div
            className="flex-1 overflow-y-auto p-4"
            style={{ height: "calc(100vh - 385px)" }}
          >
            <div className="border-inherit rounded  w-2/4">
              <Form layout="vertical">
                <div className="flex-1 overflow-y-auto">
                  <div className="border-inherit rounded ">
                    <div className="flex items-center space-x-2 ">
                      <Form.Item label="Default duration" style={{width:'242px'}}>
                        <EventDuration
                          name="zone"
                          option={zones}
                          setValue={(value) =>
                            setEventBoardSettings((prev: any) => ({
                              ...prev,
                              default_event_duration: parseInt(
                                value.split(" ")[0]
                              ),
                            }))
                          }
                          selectedValue={eventBoardSettings.default_event_duration.toString()}
                        />
                      </Form.Item>

                      <Form.Item label="Default color">
                        <Popover
                          placement="bottom"
                          content={popoverContent}
                          title="Select a color"
                          trigger="click"
                        >
                          <Button
                            className="w-40 flex justify-between  items-center"
                            style={{ width: "242px" }}
                          >
                            <div className="flex space-x-2 w-full items-center">
                              <div
                                className="w-4 h-4 rounded"
                                style={{
                                  backgroundColor:
                                    eventBoardSettings.default_event_color,
                                }}
                              ></div>
                              <div>
                                {
                                  defaultColors.find(
                                    (color) =>
                                      color.colorCode ===
                                      eventBoardSettings.default_event_color
                                  )?.name
                                }
                              </div>
                            </div>
                            <div>
                              <DownOutlined
                                style={{ color: "rgba(0,0,0,0.3)" }}
                              />
                            </div>
                          </Button>
                        </Popover>
                      </Form.Item>
                    </div>
                  </div>
                </div>

                <Form.Item name="setBackgroundColor" valuePropName="checked">
                  {eventBoardSettings.past_event_color}
                  <Checkbox
                    onClick={() =>
                      setEventBoardSettings((prev: any) => ({
                        ...prev,
                        past_event_color: !eventBoardSettings.past_event_color,
                      }))
                    }
                    defaultChecked={eventBoardSettings.past_event_color}
                  >
                    Set past event background color to gray
                  </Checkbox>
                </Form.Item>

                <BoardConferenceSettings />
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  loginUser: state.opening.loginUser,
  opening: state.opening.currentOpening,
  applicationId: state.opening.applicationId,
  calenderId: state.opening.calendar.currentCalenderId,
  currentCalendar: state.opening.calendar.currentCalendar,
});

const mapPropsToState = (dispatch: Dispatch) => ({
  UpdateCurrentCalendar: (data: Calendar) => dispatch(UpdateCurrentCal(data)),
});

export default connect(mapStateToProps, mapPropsToState)(EventSettings);
