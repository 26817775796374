import React from "react";
const CommentIcon = () => {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Comments</title>
      <g
        id="Icons-/-16x16-/-Comments"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M12,1 C14.209139,1 16,2.790861 16,5 L16,9 C16,11.209139 14.209139,13 12,13 L9,13 C9,14.1045695 8.1045695,15 7,15 L2,15 L2,13 C0.8954305,13 1.3527075e-16,12.1045695 0,11 L0,5 C-2.705415e-16,2.790861 1.790861,1 4,1 L12,1 Z M12,2 L4,2 C2.34314575,2 1,3.34314575 1,5 L1,5 L1,11 C1,11.5522847 1.44771525,12 2,12 L2,12 L3,12 L3,14 L7,14 C7.55228475,14 8,13.5522847 8,13 L8,13 L8,12 L12,12 C13.6568542,12 15,10.6568542 15,9 L15,9 L15,5 C15,3.34314575 13.6568542,2 12,2 L12,2 Z"
          id="Combined-Shape"
          fill="#161E25"
        ></path>
      </g>
    </svg>
  );
};
export default CommentIcon;
