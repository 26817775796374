import { Tabs } from "antd";
// import { TabsProps } from 'antd/lib';
import React, { useState } from "react";
import CandidateInPoolActivity from "./CandidateInPoolActivity";
import TabPane from "antd/es/tabs/TabPane";
import CandidateInAtsPoolTracker from "./CandidateInAtsPoolTracker";
import CandidateInAtsPoolReview from "./CandidateInAtsPoolReview";
import CandidateInAtsPoolMail from "./CandidateInAtsPoolMail";

const CandidateInPoolTabs = () => {
  const [activeTab, setActiveTab] = useState<string>("Activity"); // State to track active tab
  // Define tab titles and contents
  const tabData = [
    {
      key: "Activity",
      title: "Activity",
      content: <CandidateInPoolActivity  activeTab={activeTab}/>,
    },
    {
      key: "Tracker",
      title: <span>Tracker</span>,
      content: <CandidateInAtsPoolTracker activeTab={activeTab}/>,
    },
    {
      key: "Review",
      title: <span>Review</span>,
      content: <CandidateInAtsPoolReview activeTab={activeTab}/>,
    },
    {
      key: "Mail",
      title: <span>Mail</span>,
      content: <CandidateInAtsPoolMail activeTab={activeTab}/>,
    },

    // Add more tab data as needed
  ];

  const onChange = (key: string) => {
    setActiveTab(key);
  };
  return (
    <div className="flex flex-col h-full justify-between items-baseline w-full comment-editor">
      <Tabs
        size="small"
        defaultActiveKey={activeTab}
        className="w-full "
        onChange={onChange} 
        tabBarStyle={{ marginBottom: -8 ,padding:"6.5px 16px" }}
      >
        {tabData.map((tab) => (
          <TabPane tab={tab.title} key={tab.key} className="py-3 ">
            {tab.content}
          </TabPane>
        ))}
      </Tabs> 
    </div>
  );
};

export default CandidateInPoolTabs;
