import React from "react";
import { connect } from "react-redux";
import { initLabels } from "../../../redux/actions/opening/action";
import { Dispatch } from "redux";
import { collapseLabel } from "../../../redux/actions/opening/action";
import { determineTextColor } from "../../../utils/CalculateBrightnessOfColor"
type LabelProps = {
  name: string;
  color: string;
  flag: boolean;
  click(e: any): void;
  collapsingLabel: (flag: boolean) => void;
  showIcon?: JSX.Element;
  remove?: JSX.Element;
  labelId?: string;
};
const Label = (props: LabelProps) => {
  const isExpand = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    props.collapsingLabel(!props.flag);
  };
  return (
    <React.Fragment>
      <span
        onClick={isExpand}
        onKeyDown={isExpand}
        role="button"
        data-testid={props.name}
        tabIndex={-1}
        style={{ backgroundColor: props.color }}
        className={`rounded   mr-1 mb-1  px-2 text-xs  ${
          props.flag ? "h-5 py-0.5  w-auto" : "h-1.5 w-10"
        } font-normal rounded hover:bg-${props.color} bg-color-${
          props.color
        }bg-opacity-80 text-shadow truncate max-w-full flex justify-center items-center space-x-1 `}
      >
        {props.showIcon ? props.showIcon : null}
        <span style={{ color: determineTextColor(props.color) }}>{props.flag ? props.name : null}</span>
        {props.remove ? (
          <button onClick={() => props.click(props.labelId)}>
            {props.remove}
          </button>
        ) : null}
      </span>
    </React.Fragment>
  );
};

const mapPropsToState = (dispatch: Dispatch) => ({
  initLabels: (labels: any, id: string) => dispatch(initLabels(labels, id)),
  collapsingLabel: (flag: boolean) => dispatch(collapseLabel(flag)),
});
export default connect(null, mapPropsToState)(Label);
