import React, { useRef } from "react";
import { connect } from "react-redux";
import { NAV_TEXTBOX_PLACEHOLDER } from "../../constant";
import { SearchOutlined } from '@ant-design/icons';
import SearchPanal from "./SeachPanal";
// import SearchIcon from "../../assets/SearchIcon";
// import ArrowDownIcon from "../../assets/ArrowDown";
import { Dispatch } from "redux";
import { showSearch } from "../../redux/actions/opening/action";
import { Overlay } from "../hook/Overlay";
import { Scope } from "../../type/type.d";
import {  LabeledIconButton } from "../../ant/Button";
import TacitBaseModal from "../../ant/TacitbaseModal";
type NavSearchProps = {
  setSearchFlag(): void;
  search: boolean;
  color?:string
};
const NavSearch = (props: NavSearchProps) => {
  const refer = useRef(null);
  const showSearch = () => {
    props.setSearchFlag();
  };
  return (
    <>
      <div className="flex flex-row w-full justify-between items-center focus:outline-none">
        <>
          <div
           className="flex flex-row w-full justify-between items-center">
            <LabeledIconButton
              type="text"
              label={NAV_TEXTBOX_PLACEHOLDER}
              icon={<SearchOutlined  style={{
                color: props.color ? props.color : "black",
              }}/>}
              onClick={showSearch}
              style={{
                color: props.color ? props.color : "black",
              }}            ></LabeledIconButton>
          </div>
        </>

        {props.search && (
          <TacitBaseModal styles={{content:{padding:0}}}
            centered
            keyboard={false}
            maskClosable={false}
            visibility={props.search}
            width={"70%"}
            closeIcon={null}
            content={
              <>
                <Overlay
                  onClose={() => props.setSearchFlag()}
                  scope={Scope.Dialog}
                >
                  <SearchPanal close={showSearch} refer={refer} />
                </Overlay>
              </>
            }
            // className="review-modal-content"
            footer={<div className="m-0"></div>}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  openings: state.opening.openings,
  search: state.opening.showSearch,
});
const mapPropsToState = (dispatch: Dispatch) => ({
  setSearchFlag: () => dispatch(showSearch()),
});

export default connect(mapStateToProps, mapPropsToState)(NavSearch);
