import React, {  useState } from "react";
import { ConfigProvider,  Form, Input, Typography } from "antd"
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import Editor from "../../ant/Editor/Editor";
import { LabeledIconButton, UpgradeButton } from "../../ant/Button";
import GPT from "../../assets/GPT";
import Chatbox from "./Chatbox";
import EditorSkeleton from "./EditorSkeleton";
import { jdCreationObject } from "./Types";
import { FormInstance } from "antd/lib";
import { jdInfo } from "../../constant";
import { connect } from "react-redux";
import UpgradePlanModal from "../../pages/setting/billingSetting/UpgradePlanModal";
import { newOrganisation } from "../../lib/apiRes";
import { useOrgMetadata } from "../../pages/setting/billingSetting/OrgMetadata";

const {Text} = Typography;

// props for job post input
type JobPostInputProps = {
  data: jdCreationObject;
  handleSetData: (value: jdCreationObject) => void;
  getJobDescription: (description: string) => void;
  cancel: () => void;
  getCurrentStep: (value: number) => void;
  getTitle: (value: string) => void;
  formInstance: FormInstance;
  getDisableState: (value: boolean) => void;
  currentOrg:newOrganisation;
};

const JobPostInput = (props: JobPostInputProps) => {
  const [jobTitle, setJobTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [chatBox, setShowChatBox] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const orgMetadata = useOrgMetadata(props.currentOrg)

  // list of items that we showing in trix editor
  const customModules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, false] }],
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      // ['clean']
    ],
  };
  const customFormats = [
    "header",
    "bold",
    "italic",
    "underline",
    "list",
    "bullet",
  ];

  // after click on create a with AI button function will call and open chatBox to give additional info to the ai api
  const handleChatBox = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    props.formInstance
      .validateFields(["board_title"])
      .then(() => {
        setShowChatBox(!chatBox);
      })
      .catch((error) => {
        console.log("Input field is error: " + error);
      });
  };

  // while api processing set skeleton
  const handleLoading = (value: boolean) => {
    setLoading(value);
  };

  // handle chatBox hiding and showing
  const handleChatBoxFromParent = (value: boolean) => {
    setShowChatBox(value);
  };

  // after click on save and next button this function call and go to second screen
  const handleEditorChange = (content: string) => {

    props.handleSetData({ ...props.data, job_description: content });
    props.getJobDescription(content);
  };

  const handleBillingModal = ()=>{
    setIsModalVisible(true);
  }
  const handleBillingModalCancel = () => {
    setIsModalVisible(false);
};
  return (
    <>
      <div
       className={`flex flex-col sm:flex-row h-full gap-8 w-full overflow-auto md:px-0 px-4 ${chatBox ? "md:pl-8" : "md:px-8"}`}
      >
        <div className={`flex mt-4 flex-col flex-1`}>
          <Form.Item
            name="board_title"
            label={
              <span className="font-medium">
                {capitalizeFirstLetter("title")}
              </span>
            }
            rules={[
              {
                required: true,
                message: capitalizeFirstLetter("title required"),
              },
              {
                validator: (_, value) => {
                  const specialCharsRegex = /[!@#$%^&*(),.?":{}|<>]/;
                  if (specialCharsRegex.test(value)) {
                    return Promise.reject(
                      "title should not contain special characters."
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              className={`${
                chatBox
                  ? "w-full"
                  : "sm:w-full md:w-full xl:w-1/2 lg:w-1/2 2xl:w-1/2"
              }`}
              showCount
              onChange={(e) => setJobTitle(e.target.value)}
              maxLength={50}
              value={
                props.data.board_title.length > 0 ? props.data.board_title : ""
              }
            />
          </Form.Item>

          <Form.Item
            name="job_description"
            rules={[
              {
                validator: (_, value) => {
                  if (value.length < 100 && value.length >1) {
                    return Promise.reject(
                      "Description should be at least 100 characters long."
                    );
                  }
                  if (value.length < 0 || value.length <= 1) {
                    return Promise.reject(
                      "please provide a job description."
                    );
                  }
                  return Promise.resolve();
                },
              }
            ]}
          >
            <div className=" flex justify-between items-center py-2">
                <label className="font-medium">
                  <sup className="text-red-500">*</sup>{capitalizeFirstLetter("description")}
                </label>
                {!chatBox && (
                  <ConfigProvider
                    theme={{
                      components: {
                        Button: {
                          colorPrimary: "#009D79",
                          colorPrimaryHover: "#009D79",
                          colorPrimaryActive: "#009D79"
                        },
                      },
                    }}
                  >
                    {
                     
                     orgMetadata?.ai_feature  === 'true' ?(
                      <LabeledIconButton
                      label="Generate with AI"
                        icon={<GPT />}
                      style={{ backgroundColor: "#009D79", color: "#fff" }}
                        size="middle"
                        onClick={handleChatBox}
                      />
                     ):(
                       <UpgradeButton label="Generate with AI" 
                       style={{ backgroundColor: "#009D79", color: "#fff" }}
                       onClick={handleBillingModal}
                       />
                     )
                   }

                    
                  </ConfigProvider>
                )}
              </div>
            {loading ? (
              <EditorSkeleton />
            ) : (
                <div className="editor-description border rounded mb-2" style={{
                  height: "calc(-200px + 67vh)"
                }}>
                <Editor
                  initialValue="<p>Job Description</p>"
                  value={props.data.job_description}
                  onChange={handleEditorChange}
                  modules={customModules}
                  formats={customFormats}
                  placeholder="Type your job description here..."
                />
              </div>
            )}
            <Text type="secondary" className="text-left" style={{ fontSize: "14px" }}>
                <sup style={{ color: "red" }}>*</sup>
                {jdInfo.aiWarning}
              </Text>
          </Form.Item>
          
          {/* </div> */}
        </div>
        {chatBox && (
          <Chatbox
            handleChatBoxFromParent={handleChatBoxFromParent}
            getJobDescription={props.getJobDescription}
            title={jobTitle}
            handleLoading={handleLoading}
            getDisableState={props.getDisableState}
          />
        )}
      </div>
      <UpgradePlanModal isModalVisible = {isModalVisible} handleCancel={handleBillingModalCancel}/>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  currentOrg:state.opening.currentOrg,
});
export default connect(mapStateToProps)(JobPostInput);
