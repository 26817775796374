import React from "react";
import { connect } from "react-redux";
import { openingMemberType, orgMember } from "../../type/type";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import TacitbaseAvtar from "../../ant/Avtar"
import { Avatar } from "antd"
type MemberAccessPreviewType = {
  orgMembers: orgMember;
  member: openingMemberType;
};
const MemberAccessPreview = (props: MemberAccessPreviewType) => {
  const role = props.orgMembers[props.member.id]?.registrations[0]?.roles[0];
  const isGuestOrAgency = role === "Guest" || role === "Agency";

  return (
    <div className="flex space-x-1.5 w-full">
      {props.orgMembers[props.member.id].image_url ? (
        <Avatar.Group>
          <TacitbaseAvtar size="default" src={props.orgMembers[props.member.id].image_url} />
        </Avatar.Group>
      ) : <TacitbaseAvtar size="default" content={props.orgMembers[props.member.id].first_name[0] + props.orgMembers[props.member.id].last_name[0]} />
      }
      <div className="flex flex-col justify-center">
        <div className="flex flex-wrap space-x-1">
          <div
            key={props.orgMembers[props.member.id].id}
            className="text-xs font-normal leading-none bg-tacitbase-secondary3 bg-opacity-20 px-2 py-0.5 rounded-default text-tacitbase-secondary3 w-max"
          >
            {props.member.access.admin
              ? capitalizeFirstLetter("admin")
              : props.member.access.comment_only
              ? capitalizeFirstLetter("commentor")
              : capitalizeFirstLetter("member")}
          </div>
          {props.member.access.is_secrete_visible ? (
            <><div className="text-xs font-normal leading-none bg-tacitbase-primary2 bg-opacity-20 px-2 py-0.5 rounded-default text-tacitbase-primary2 w-max">
              {capitalizeFirstLetter("secrets visible")}{" "}
            </div>
            </>
          ) : null}
          {isGuestOrAgency && 
                  <div
                key={props.orgMembers[props.member.id].id}
                className="text-xs font-normal leading-none bg-opacity-20 px-2 py-0.5 rounded-default w-max"
                style={{
                  backgroundColor: role === 'Guest' 
                    ? '#F2FFFC' : '#e6f4ff',
                  color: role === 'Guest' 
                    ? '#009D79' : '#4B48A5' 
                }}
              >
                {props.orgMembers[props.member.id].registrations[0].roles[0]}
              </div>}
        </div>
        <div className="leading-snug font-semibold text-xs text-gray-800 tracking-normal line-clamp-1">
          @{props.orgMembers[props.member.id].username}
        </div>
        <div className="text-xs leading-snug  text-gray-800 w-48 truncate">
          {props.orgMembers[props.member.id].email}
        </div>
      </div>{" "}
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  orgMembers: state.opening.members,
});
export default connect(mapStateToProps)(MemberAccessPreview);
