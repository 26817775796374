import { JWTtokenExchange, getOrgMember, setTokenInSessionStorage } from "../lib/api"
import { newOrganisation } from "../lib/apiRes";
import { initMembership, tokenExchange, updateCareerPageDetails } from "../redux/actions/opening/action"
import { loginUser } from "../type/type";
import { con } from "../index";

export async function orgConfigurationMiddleware(
  orgData: newOrganisation,
  login_user: loginUser,
): Promise<boolean> {
  
  if (!orgData.id || !login_user.user.id) {
    return false;
  }

  try {
    // Fetching members and initializing
    const members = await getOrgMember(orgData.id);
    con?.store?.dispatch(initMembership(members));

    // Token exchange
    const token = await JWTtokenExchange(orgData.id, login_user.user.id);
     con?.store?.dispatch(tokenExchange(
      token,
      orgData.id,
      orgData.application?.name,
      orgData.application?.data?.description,
      orgData.application?.data?.logo,
      orgData.application?.roles
    ));

    if(orgData.application?.data){
      con?.store?.dispatch(updateCareerPageDetails(orgData.application?.data))
    }
    
    // Store token in session storage
    await setTokenInSessionStorage(token);
    
    return true;
  } catch (error) {
    console.error("Org configuration failed:", error);
    return false;
  }
}
