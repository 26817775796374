import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

dayjs.extend(duration);

export const renderCreatedAt = (created_at: string): string => {
    // Parse timestamp in local time zone
    const createdAtDay = dayjs(created_at);
    // Get current time in local time zone
    const currentTimeDay = dayjs();
    const timeDiff = dayjs.duration(currentTimeDay.diff(createdAtDay));
    const isToday = createdAtDay.isSame(currentTimeDay, 'day');
    const isSameYear = createdAtDay.isSame(currentTimeDay, 'year');

    if (isToday) {
        if (timeDiff.asMinutes() < 1) {
            return "Just now";
        } else if (timeDiff.asMinutes() < 60) {
            return `${Math.floor(timeDiff.asMinutes())} minutes ago`;
        } else {
            return `${Math.floor(timeDiff.asHours())} hours ago`;
        }
    } else if (isSameYear) {
        return createdAtDay.format('D MMM [at] h:mm a');
    } else {
        return createdAtDay.format('D MMM YYYY [at] h:mm a');
    }
};
