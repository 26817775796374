import React from "react";
import { CopyListProps } from "../../../../type/boardPanal";
import Button from "../../../common/button/Button";
import PopupTitle from "../../../common/popupTitle/PopupTitle";

const CopyList = (props: CopyListProps) => {
  return (
    <React.Fragment>
      <div className="my-2">
        <PopupTitle
          PopupTitle="Copy List"
          back={props.prev}
          close={props.close}
        />
      </div>
      <div className="mt-2 mx-3">
        <span>Title</span>
        <textarea
          name=""
          className="w-full mb-2 h-20 max-h-20 resize-none"
          ref={(txtarea) => txtarea?.focus()}
        ></textarea>
        <div className="mb-2">
          <Button buttonName="Create list" />
        </div>
      </div>
    </React.Fragment>
  );
};
export default CopyList;
