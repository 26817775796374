import { capitalizeFirstLetter } from "./capitalizeFirstLetter"


export const validateInput = (inputValue: string, type: string) => {
  const emailReg = /^[a-zA-Z0-9]+(?:[-._+][a-zA-Z0-9]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?(?:\.[a-zA-Z]{2,})+$/
    switch (type) {
      case 'url':
        if (!inputValue) {
          return 'URL is required.';
        }
        
        if (!/^(ftp|http|https):\/\/[^ "]+$/.test(inputValue)) {
          return 'Please enter a valid URL.';
        }
        break;
      case 'date':
        if (!inputValue) {
          return 'Date is required.';
        }
        break;
    //   case 'paragraph':
    //     if (inputValue.length > 1000) {
    //       return 'Paragraph must be less than or equal to 1000 characters.';
    //     }
    //     break;
      case 'email':
        if (!inputValue) {
          return 'Email is required.';
        }
        if (!(emailReg.test(inputValue))) {
          return 'Please enter a valid email address.';
        }
        break;
      case 'number':
            if (isNaN(Number(inputValue))) {
            return 'Please enter a valid number.';
        }
        break;
      case 'first name':
      case 'last name':
        if (!inputValue) {
          return `${capitalizeFirstLetter(type)} is required.`
        }
          if (!/^[a-zA-Z]+$/.test(inputValue)) {
            // If the input contains spaces or special characters
            if (/^\s*$/.test(inputValue)) {
              // If the input contains only spaces
              return  "Name field required"
            } else 
            if(inputValue.match(/[^\w.\s-]/)){
              // If the input contains special characters expect - and space(/s)
              return "Special characters are not allowed";
            }
             if (inputValue.match(/\d/)) {
              return "Numbers are not allowed";
            }
          } 
      break;
case "color":
      if (/^#[0-9A-F]{6}$/i.test(inputValue)) {
        return true;
      } else {
        return false;
      }      
case "domain":
        if (!inputValue) {
          return "Domain name is required."
        }
        if (
          !/^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/.test(
            inputValue
          )
        ) {
          if (/\s/.test(inputValue)) {
            // If the input contains spaces
            return "Spaces are not allowed"
          } else if (inputValue.match(/[^\w.-]/)) {
            // If the input contains special characters
            return "Special characters are not allowed"
          }
          return "Please enter a valid domain."
        }
        break;
      default:
        break;
    }
  
    return '';
  };
  