import React from "react";
import {  Menu } from "antd";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { ASCENDING, customMenuItemStyle, customMenuStyle, DATE, DESCENDING } from "../../../constant";
import { sortingBoard } from "../../../redux/actions/opening/action";

type SortingPopupProps = {
  click: (e: boolean) => void;
  getValue: (value: any) => void;
  sortingBoard: (selectionType: any) => void;
  sortingType: any;
  applicationId: string;
};

const SortingPopup = (props: SortingPopupProps) => {
  const menuOptions = [
    { type: "A-Z", name: ASCENDING },
    { type: "Z-A", name: DESCENDING },
    { type: "Date Added", name: DATE },
  ];

  const handleMenuClick = (menuName: string) => {
    props.getValue(menuName);
    props.sortingBoard(menuName);
  };

  // Get the active sorting type for the current applicationId
  const activeSortingType = props.sortingType[props.applicationId]?.["boards"];

  return (
      <div className="pr-1 pl-1 ">
        <Menu 
          style={customMenuStyle} 
          selectedKeys={[activeSortingType]} // Set selected menu item based on activeSortingType
        >
          {menuOptions.map((menu) => {

            return (
              <Menu.Item
                key={menu.name}
                onClick={() => handleMenuClick(menu.name)}
                style={{
                  ...customMenuItemStyle,
                  marginBottom: "8px",
                }}
              >
                <span>{menu.type}</span>
              </Menu.Item>
            );
          })}
        </Menu>
      </div>
  );
};

const mapStateToProps = (state: any) => ({
  sortingType: state.opening.sortingType,
  applicationId: state.opening.applicationId,
});

const mapPropsToState = (dispatch: Dispatch) => ({
  sortingBoard: (selectionType: string) =>
    dispatch(sortingBoard(selectionType)),
});

export default connect(mapStateToProps, mapPropsToState)(SortingPopup);