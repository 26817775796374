import React, { useState } from "react"
import { PREVIEW } from "../../../constant"
import { Overlay } from "../../hook/Overlay"
import { Candidate, Scope } from "../../../type/type.d"
import MailTagPreview from "./MailTagPreview"
type DynamicMailTagType = {
    handleInsertValue(e: string): void,
    item: string,
    selectedDynamicKeys: string[],
    records: Candidate[]
}
const DynamicMailTag = (props: DynamicMailTagType) => {
    const [selectedTagForPreview, showPreview] = useState<string>("")
    const showPreviewOftag = (e: any, item: string) => {
        e.stopPropagation()
        showPreview(item)
    }
    return (
        <>
            <div onClick={() => props.handleInsertValue(props.item)}
                onKeyDown={() => { }}
                role="button"
                tabIndex={0}
                className={`${props.selectedDynamicKeys.includes(props.item) ? "border-tacitbase-secondary3" : "border-tacitbase-tertiary1 border-opacity-30"} flex space-x-1 border items-center justify-center text-xs leading-4 tracking-normal font-medium  p-1 rounded capitalize`}
            >
                <button
                    data-testid={props.item}
                >
                    {props.item.charAt(0).toUpperCase() + props.item.slice(1).replace("_", " ")}
                </button>
                {props.selectedDynamicKeys.includes(props.item) &&
                    <div className="tooltip">
                        <span className={`tooltiptext`}>
                            {PREVIEW}
                        </span>
                        <button
                        >
                            <i
                                onClick={(e: any) => showPreviewOftag(e, props.item)}
                                className="fas fa-eye  cursor-pointer  text-gray-600"
                                aria-hidden
                                data-testid="eyeIcon"
                            ></i>
                        </button>
                    </div>
                }
            </div >
            {selectedTagForPreview.trim().length > 0 && <Overlay scope={Scope.Modal} onClose={() => showPreview("")}><MailTagPreview recipients={props.records} previewTag={selectedTagForPreview} onClose={() => showPreview("")} /></Overlay>
            }
        </>
    )
}
export default DynamicMailTag
