import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import {
  COMMENT_TAG,
  customFormats,
  customModules,
  DATE_TIME_FORMATE,
  SOCKET_CLIENT_ID,
} from "../../../../constant";
import {
  currentApplicant,
  InitComments,
  loginUser,
  orgMember,
} from "../../../../type/type.d";
import TacitbaseAvtar from "../../../../ant/Avtar";
import { Empty, Space, Spin, Tag, Typography } from "antd";
import Editor from "../../../../ant/Editor/Editor";
import { LabeledIconButton } from "../../../../ant/Button";
import { SendOutlined } from "@ant-design/icons";
import parse from "html-react-parser";
import SlackIcon from "../../../../assets/SlackIcon";
import { editComment, getApplicantComments } from "../../../../lib/api";
import { Dispatch } from "redux";
import {
  initComments,
  updateComment,
} from "../../../../redux/actions/opening/action";
const { Text } = Typography;
// import parse from "html-react-parser";
type ShowCommentProps = {
  comments: InitComments;
  members: orgMember;
  loginUser: loginUser;
  currentApplicant: currentApplicant;
  updateComment(comment: any): void;
  initComments(comments: any, tag: string, applicant_id: string): void;
};

const ShowComments = (props: ShowCommentProps) => {
  const [editingCommentId, setEditingCommentId] = useState<string>("");
  const [editedCommentBody, setEditedCommentBody] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (props.currentApplicant.id) {
      setLoading(true);
      getApplicantComments(props.currentApplicant.id)
        .then((res) => {
          // Call initComments after successfully fetching commentssetLoading
          setLoading(false);
          props.initComments(res, COMMENT_TAG, props.currentApplicant.id);
        })
        .catch((error) => {
          setLoading(false);
          // Handle any errors here
          console.error("Failed to fetch applicant comments:", error);
        });
    }
  }, [props.currentApplicant.id]); // Add the currentApplicant.id as a dependency

  const handleEdit = (commentId: string, body: string) => {
    setEditingCommentId(commentId);
    setEditedCommentBody(body);
  };
  const updateComment = () => {
    const postComment = {
      body: editedCommentBody,
      id: editingCommentId,
      socket_client_id: sessionStorage.getItem(SOCKET_CLIENT_ID),
      opening_id: props.currentApplicant.opening_id,
      member_id: props.loginUser.user.id,
    };

    if (editedCommentBody.trim().length > 0) {
      editComment(postComment, props.currentApplicant.id).then(
        (comment: any) => {
          if (comment) {
            props.updateComment(comment);

            setEditingCommentId("");
            setEditedCommentBody("");
          }
        }
      );
    }
  };
  const handleEditedComment = (content: string) => {
    setEditedCommentBody(content);
  };
  const handleKeyDownEditedComment = (
    e: React.KeyboardEvent<HTMLDivElement>
  ) => {
    if (e.keyCode === 13 && e.metaKey) {
      // Save the comment when Command + Enter is pressed
      updateComment();
    }
  };

  const messagesEndRef = useRef<null | HTMLDivElement>(null)

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: "auto" })
        }
    }
    useEffect(() => {
        scrollToBottom()
    }, [props.comments])

  const renderComments = () => {
    const comments =
      props.comments[props.currentApplicant.id] &&
      Object.values(props.comments[props.currentApplicant.id]);

    // Sort comments by date in descending order
    const sortedComments = comments
      ? comments.sort(
          (a, b) =>
            new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
        )
      : [];

    if (sortedComments.length === 0) {
      return (
        <Empty
          description="No comments yet"
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
      );
    }
    if (sortedComments) {
      return sortedComments.map((comment: any) => {
        const isEdited =
          new Date(comment.created_at) < new Date(comment.updated_at);
        return (
          comment.tag == COMMENT_TAG &&
          props.members[comment.member_id] && (
            <div key={comment.id}>
              {
                <div
                  key={comment.id}
                  className={` ${
                    comment.member_id === props.loginUser.user.id
                      ? "flex  flex-col justify-end items-end"
                      : "flex flex-col justify-start items-start"
                  } mb-2`}
                >
                  <div
                    className={`flex flex-col space-y-1 ${
                      comment.member_id === props.loginUser.user.id
                        ? "justify-end"
                        : "justify-start "
                    } `}
                    style={{ minWidth: "auto", maxWidth: "66%" }}
                  >
                    <div
                      className={`flex  ${
                        comment.member_id === props.loginUser.user.id
                          ? "justify-end"
                          : "justify-start"
                      } items-center space-x-2`}
                    >
                      {comment.member_id === props.loginUser.user.id ? (
                        <>
                          <Text className="font-normal text-xs ">
                            {moment(comment.created_at).format(
                              DATE_TIME_FORMATE
                            )}
                          </Text>
                          <Text strong className="font-normal">
                            {"You"}
                          </Text>
                          <TacitbaseAvtar
                            size="default"
                            src={props.members[comment.member_id].image_url}
                          />
                        </>
                      ) : (
                        <>
                          <TacitbaseAvtar
                            size="default"
                            src={props.members[comment.member_id].image_url}
                          />
                          <Text strong className="font-normal">
                            {props.members[comment.member_id].first_name}{" "}
                            {props.members[comment.member_id].last_name}
                          </Text>
                          <Text className="font-normal text-xs">
                            {moment(comment.created_at).format(
                              DATE_TIME_FORMATE
                            )}
                          </Text>
                        </>
                      )}{" "}
                    </div>
                    {editingCommentId === comment.id ? (
                      <div
                        style={{
                          boxShadow:
                            "var(--ds-shadow-raised,0 1px 1px #091e4240,0 0 1px #091e424f)",
                        }}
                        className="comment-editor border rounded "
                      >
                        <Editor
                          modules={customModules}
                          onKeyDown={handleKeyDownEditedComment}
                          formats={customFormats}
                          defaultValue={editedCommentBody}
                          value={editedCommentBody}
                          initialValue={editedCommentBody}
                          onChange={handleEditedComment}
                        />
                        <div className="flex justify-end pb-2 px-3">
                          {/* <EmojiPicker onEmojiSelect={(emoji) => handleEmojiSelect(emoji)} /> */}
                          <LabeledIconButton
                            size="small"
                            label=""
                            type="primary"
                            icon={<SendOutlined />}
                            onClick={updateComment}
                          />
                        </div>
                      </div>
                    ) : (
                      <div
                        className={`space-y-1 ${
                          comment.member_id === props.loginUser.user.id
                            ? "mr-8"
                            : "ml-9"
                        } `}
                      >
                        <div
                          className=" rounded px-2 py-1 "
                          style={{
                            background: `${
                              comment.member_id === props.loginUser.user.id
                                ? "#F6F4FF66"
                                : "#EAFFF666"
                            }`,
                            boxShadow:
                              "var(--ds-shadow-raised,0 1px 1px #091e4240,0 0 1px #091e424f)",
                          }}
                        >
                          {parse(comment.body)}
                        </div>
                        <div className="flex  justify-between items-start">
                          {comment.member_id === props.loginUser.user.id && (
                            <Tag
                              bordered={false}
                              onClick={() =>
                                handleEdit(comment.id, comment.body)
                              }
                              className="cursor-pointer"
                            >
                              {"edit"}
                            </Tag>
                          )}

                          <div className="flex justify-end w-full items-center space-x-1">
                            {comment.source == "slack" && (
                              <Space className="flex justify-end items-center">
                                <SlackIcon />
                                <Text className="text-xs" type={"secondary"}>
                                  {"Sent from Slack"}
                                </Text>
                              </Space>
                            )}
                            <Space>
                              {isEdited && (
                                <Text
                                  type="secondary"
                                  style={{ fontSize: "12px" }}
                                >
                                  {"(edited)"}
                                </Text>
                              )}
                            </Space>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              }
            </div>
          )
        );
      });
    }
  };

  return (
    <>
      {loading ? (
        <div className="flex min-h-full justify-center items-center">
          <Spin />
        </div>
      ) : (
        <div className="px-4 pt-4 h-full overflow-y-auto">
          {renderComments()}
          <div ref={messagesEndRef}></div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  loginUser: state.opening.loginUser,
  members: state.opening.members,
  comments: state.opening.comments,
  currentApplicant: state.opening.currentApplicant,
});
const mapPropsToState = (dispatch: Dispatch) => ({
  updateComment: (comment: any) => dispatch(updateComment(comment)),
  initComments: (comments: any, tag: string, applicant_id: string) =>
    dispatch(initComments(comments, tag, applicant_id)),
});
export default connect(mapStateToProps, mapPropsToState)(ShowComments);
