import React from "react";
import Filter from "../../components/subNavbar/filter/Filter";
import { connect } from "react-redux";
import { Jd } from "../../type/type";


interface BoardPanalProps{
    activeTab:string,
    currentTrackerJd: Jd,
}

const BoardPanal = (props: BoardPanalProps) => {
  return (
    <div >
      {props.activeTab === "tracker" && (
        <div className="ml-2">
     <Filter/>
</div>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
    currentTrackerJd: state.opening.currentTrackerJd,
  });

export default connect(mapStateToProps) (BoardPanal);
