import { useEffect } from "react";

const OutsideClick = (refer: any, callback: any) => {  
  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  // closes dropdown on outsideclick
  const handleClick = (e: any) => {    
    if (refer.current && !refer.current.contains(e.target)) {
      callback(refer);
      return;
    }
  };
};
export default OutsideClick;
