import React from "react"
import { ExportOutlined } from '@ant-design/icons'
import { Attachment } from "../../../type/type"
import { Button } from "antd"

const OpenFileInNewTab = ({
    attachment

}: {
    attachment: Attachment
}) => {
    const openInNewTab = (event: any) => {
        event.stopPropagation() // Stop the click event from propagating
        event.preventDefault() // Stop the click event from propagating

        try {
            const newTab = window.open(attachment.presignedLink, '_blank')
            if (newTab) {
                newTab.focus()
            }
        } catch (error) {
            console.error("Error opening the file in a new tab", error)
        }
    }

    return (
        <>
            <Button type="text" size="small" icon={<ExportOutlined   />} onClick={openInNewTab} >Open</Button>
        </>
    )
}

export default OpenFileInNewTab
