import React from "react"
import { DownloadOutlined } from '@ant-design/icons'
import { Attachment } from "../../../type/type"
import { Button, Tooltip } from "antd"

const DownloadFile = ({
    attachment,


}: {
        attachment: Attachment,
        
}) => {
    const downloadFile = async (event: any) => {
        try {
            event.stopPropagation() // Stop the click event from propagating
            event.preventDefault() // Stop the click event from propagating

            const response = await fetch(attachment.presignedLink)
            const blob = await response.blob()

            const url = window.URL.createObjectURL(blob)
            const a = document.createElement("a")
            a.href = url
            a.download = attachment.name + "." + attachment.objectKey.split(".")[
                attachment.objectKey.split(".").length - 1
            ]
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
        } catch (error) {
            console.error("Error downloading the file", error)
        }

    }


    return (
        <>
        <Tooltip title="Download">
            <Button type="text" size="small" icon={<DownloadOutlined 
 />} onClick={downloadFile} >Download</Button>
 </Tooltip>
        </>
    )
}

export default DownloadFile
