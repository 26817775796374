import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { archivedAll } from "../../../lib/api";
import { archivedAllListApplicants } from "../../../redux/actions/opening/action";
import { GetOpening } from "../../../type/type";
import PopupTitle from "../../common/popupTitle/PopupTitle";
import { SOCKET_CLIENT_ID } from "../../../constant";
export type ArchiveAllCardProps = {
  prev: () => void;
  close: (e: boolean) => void;
  listId?: string;
  archivedApplicants(lists: any, openingID: string): void;
  clientId: string;
  currentOpening: GetOpening;
};
const ArchiveAllCard = (props: ArchiveAllCardProps) => {
  const archiveAllListCards = () => {
    const req = {
      id: props.listId,
      archive: true,
      socketClientID: sessionStorage.getItem(SOCKET_CLIENT_ID),
      socket_client_id: sessionStorage.getItem(SOCKET_CLIENT_ID),
      opening_id: props.currentOpening.id,
    };
    archivedAll(req)
      .then((list: any) => {
        props.close(false);
        props.archivedApplicants(list.id, list.opening_id);
      })
      .catch((error: Error) => {
        console.log(error);
      });
  };
  return (
    <>
      <div className="my-2">
        <PopupTitle
          PopupTitle="Archive all cards in this list?"
          back={props.prev}
          close={props.close}
        />
      </div>
      <div className="m-2">
        This will remove all the cards in this list from the board. To view
        archived cards and bring them back to the board, click “Menu ”{">"}{" "}
        “Archived Items.”
      </div>
      <div className="flex">
        <div className="m-2">
          <button
            className="bg-red-700 text-white text-sm active:ring-red-900  active:ring-2   active:bg-gray-10  rounded tracking-wider m-1 py-1 px-2 focus:outline-none outline-none "
            onClick={archiveAllListCards}
            type="button"
            data-testid="testBtn"
          >
            <span className="text-xs capitalize">Archive all </span>
          </button>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  clientId: state.opening.clientId,
  currentOpening: state.opening.currentOpening,
});
const mapPropsToState = (dispatch: Dispatch) => ({
  archivedApplicants: (listsID: any, openingID: string) =>
    dispatch(archivedAllListApplicants(listsID, openingID)),
});
export default connect(mapStateToProps, mapPropsToState)(ArchiveAllCard);
