import React, { useState } from "react";
import { LabeledIconButton } from "../../ant/Button";
import ArchiveJobPostsModal from "./ArchiveJobPostsModal";
const ArchiveJobPostsIndex = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <LabeledIconButton className="w-full flex justify-start"
        onClick={() => setShowModal(true)}
        label="View archive"
        type="text"
      />
      <ArchiveJobPostsModal
        onSave={() => {}}
        open={showModal}
        onCancel={() => setShowModal(false)}
      />
    </>
  );
};

export default ArchiveJobPostsIndex;
