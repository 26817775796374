import React, { useState } from "react";
import PopupTitle from "../../common/popupTitle/PopupTitle";
import { Button, Input, Tag } from "antd";
import {  colorDictionary1 } from "../../../constant";
import { CheckOutlined } from "@ant-design/icons";
import { determineTextColor } from "../../../utils/CalculateBrightnessOfColor";
interface AnalyticsCreateLabelpanelProps{
    onButtonState(arg0: boolean): unknown;
    prev: (() => void) | undefined;
    close?: (e: boolean) => void;
    onCreateLabel: (label: { value: string, color: string }) =>  void,    

}
function AnalyticsCreateLabelpanel(props:AnalyticsCreateLabelpanelProps){

    const [labelValue, setLabelValue] = useState("")
    const [selectedColor, setSelectedColor] = useState<string | null>("red")
    const [labelName, setLabelName] = useState("")
    const [labelColor, setLabelColor] = useState("")
    const [isCreatingLabel, setIsCreatingLabel] = useState(false)

console.log(labelColor,isCreatingLabel,"rendered")



    const handleLabelChange = (value: any) => {
        setLabelValue(value)

        // const results = initialLabels.filter((label: { name: string; }) =>
        //     label.name.toLowerCase().includes(value.toLowerCase())
        // ) 
        setLabelName(value)
        // setSearchResults(results)
    }
    const handleColorSelect = (color: string) => {
        setSelectedColor(color)
        setLabelColor(color)
    }

    const handleCreateLabel = () => {
        if (!labelName || !selectedColor) {
            return
        }
        props.onCreateLabel({ value: labelName, color: selectedColor })
        setLabelName("")
        setLabelColor("")
        // setVisible(false)
        setIsCreatingLabel(false)
        props.onButtonState(false); 
        console.log(labelName,selectedColor,"label name and colour")
    }

    const renderColorButton = (colorName: string, colorValue: string) => {
        const isSelected = selectedColor === colorName
        return (
            <>
                {/* <Flex gap="12px 12px" wrap="wrap"> */}
                <Tag
                    bordered
                    style={{ backgroundColor: colorValue, borderColor: colorValue }}
                    className="h-8 w-8 flex  justify-center items-center mb-2"
                    onClick={() => handleColorSelect(colorName)}
                    icon={isSelected && <CheckOutlined style={{ color: determineTextColor(colorName), border: `1px solid ${colorName}  50%` }} />}

                />
                {/* </Flex> */}
            </>
        )
    }

    return(
        <><div className="my-2">
            <PopupTitle
                PopupTitle="Update analytics labels"
                back={props.prev}
                close={props.close} />
        </div><div className="m-2">
                <span>Analytics labels</span>
            
            <div className=" mt-2 mb-6 rounded  w-full">
        <Input
                            placeholder="Enter Label name"
                            value={labelValue}
                            onChange={(e) => handleLabelChange(e.target.value)}
                        />
        </div>
        <div className="h-full overflow-y-hidden mb-6">
                    <div className="flex flex-wrap items-center justify-center">
                        {Object.entries(colorDictionary1).map(([colorName, colorValue]) =>
                            renderColorButton(colorName, colorValue)
                        )}
                    </div>
                </div>
        <div className=" w-full">
                        <Button
                            disabled={labelName ? false : true}
                            type="default"
                            onClick={handleCreateLabel}
                            style={{width:'100%',color:'#7152f2',}}
                        > Create</Button>
                    </div>
        </div>

            </>
    );

}
export default AnalyticsCreateLabelpanel;

