import React from "react";
import { connect } from "react-redux";
import { SelectOptionsProps } from "../../../type/copyCard";
import { Select, Typography } from "antd";
const {Text} = Typography;
const SelectOptions = (props: SelectOptionsProps): any => {
  return (
    <>
      <Text>{props.label}</Text>
      <Select
        className="w-full"
        onChange={(value) => props.event(value)}
      >
        {props.value.map((option: any, index: number) => (
          <Select.Option
            key={index}
            className="w-full"
            value={option.id}
            selected={option.id === props.comparedId}
          >
            {option.name}
          </Select.Option>
        ))}
      </Select>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
  lists: state.opening.lists,
  currentApplicant: state.opening.currentApplicant,
  keep: state.opening.keepCopyApplicant,
  tasks: state.opening.tasks,
  labels: state.opening.labels,
  members: state.opening.members,
});
export default connect(mapStateToProps)(SelectOptions);
