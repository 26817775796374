import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { redirectToCareerPage, redirectToNewForm, redirectToNewJD } from '../../../lib/api'
interface RouteParams {
  company_name: string,
  form_id: string,
  [key: string]: string // Allow additional string keys if needed
}

// Now you can create an object with the types inferred
export const RedirectTonewJD = () => {

  const { org_id, jd_id } = useParams<RouteParams>() // useParams with RouteParams type
  const params: any = { org_id, jd_id }

  useEffect(() => {
    redirectToNewJD(params.jd_id).then((res) => {
      window.location.href = res

    })

  }, [])
  return <></>

}
export const RedirectToNewForm = () => {
  const { org_id, form_id } = useParams<RouteParams>() // useParams with RouteParams type
  const params: any = { org_id, form_id }
  useEffect(() => {
    redirectToNewForm(params.form_id).then((res) => {
      window.location.href = res

    })

  }, [])
  return <></>

}

export const RedirectToNewCareerpage = () => {
  const { org_id } = useParams<RouteParams>() // useParams with RouteParams type
  const params: any = { org_id }
  useEffect(() => {
    redirectToCareerPage(params.org_id).then((res) => {
      window.location.href = res

    })

  }, [])
  return <></>

}

