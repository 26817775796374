import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Dispatch } from "redux";
import { getUserWatcherOfModel, setUserWatcherOfModel } from "../../../../lib/api"
import { WatcherSubscription } from "../../../../lib/apiRes";
import { initCurrentCardWatcher } from "../../../../redux/actions/opening/action";
import { SecondaryButton } from "../../../../ant/Button";
import { CheckOutlined, EyeOutlined } from "@ant-design/icons";



const WatcherCardSettings = () => {
  const params: { card_id: string } = useParams()
  const [watchStatus, setWatchStatus] = useState(false)
useEffect(()=>{
  getUserWatcherOfModel(params.card_id,"card").then((res)=>{
    // console.log(res);
    
    res.watcher.watch? setWatchStatus(true): setWatchStatus(false);
    
  })
},[params.card_id])

  const addWatcherToThisEntity = () => {
    setUserWatcherOfModel(params.card_id,"card",true).then(()=>{
      setWatchStatus(true) 
    })
  };
  const removeWatcherFromThisEntity = () => {
    setUserWatcherOfModel(params.card_id,"card",false).then(()=>{
      setWatchStatus(false) 
    })
  };

  

  return (
    <React.Fragment>
      <SecondaryButton
        onClick={
          watchStatus ? removeWatcherFromThisEntity : addWatcherToThisEntity
        }
        icon={<EyeOutlined />}
        className="w-full truncate flex justify-start"
        id="Members"
      >
     {watchStatus ? "Watching" : "Watch"} 

        {watchStatus ? (
          <div className="flex items-center">
            <CheckOutlined color="#F0EFFE"/> 
          </div>
        ) : null}
      </SecondaryButton>
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  loginUser: state.opening.loginUser,
  opening: state.opening.currentOpening,
  applicationId: state.opening.applicationId,
  currentApplicant: state.opening.currentApplicant,
  notificationReduxState: state.opening.notification,
});

const mapPropsToState = (dispatch: Dispatch) => ({
  initCurrentCardWatcher: (data: WatcherSubscription) =>
    dispatch(initCurrentCardWatcher(data)),
});
export default connect(mapStateToProps, mapPropsToState)(WatcherCardSettings);
