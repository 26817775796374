import React, { useState, useEffect } from "react";
import { updateOpening } from "../../../lib/api";
import AutosizeInput from "react-input-autosize";

//redux stuff
import { connect } from "react-redux";
import { updateOpeningName } from "../../../redux/actions/opening/action";
import { Dispatch } from "redux";
import { useRef } from "react";
import OutsideClick from "../../OutsideClick";
import { BoardInputProps } from "../../../type/subNavbar";
import { SOCKET_CLIENT_ID, TACITBASE } from "../../../constant";
import { Opening } from "../../../type/type";
import { updatedOpeningReq } from "../../../lib/apiReq";

const BoardInput = (props: BoardInputProps) => {
  const [boardInput, showBoardInput] = useState(false);
  const [board, updateBoard] = useState("");
  const txtRef = useRef<HTMLInputElement | null>(null);
  OutsideClick(txtRef, () => {
    showBoardInput(false);
  });

  useEffect(() => {
    updateBoard(props.boardName);
  }, [props.boardName]);
  const updatedOpeningName = (e: any) => {
    let clientId=sessionStorage.getItem(SOCKET_CLIENT_ID)
    const updatedOpening: updatedOpeningReq = {
      id: props.currentBoard.id,
      name: board,
      socket_client_id:clientId?clientId:"",
      tenant_id: props.applicationId,
    };
    if (e.keyCode === 13 || e.button == 0) {
      e.preventDefault();
      if (e.target.value.trim().length !== 0 && board !== props.boardName) {
        updateOpening(updatedOpening)
          .then((opening: Opening) => {
            document.title = `${opening.name} | ${TACITBASE} `;
            props.editOpeningName(opening);
            showBoardInput(!boardInput);
          })
        
      } else {
        updateBoard(props.currentBoard.name);
        showBoardInput(!boardInput);
      }
    }
  };
  return (
    <React.Fragment>
      {boardInput ? (
        <div ref={txtRef}>
          <AutosizeInput
            ref={(txtRef: any) => txtRef && txtRef.focus()}
            value={board}
            maxLength={40}
            inputStyle={{
              fontSize: 16,
              fontWeight: 900,
              color: "black",
              paddingLeft: 4,
              paddingRight: 4,
              height: 31,
              borderRadius: 3,
              marginRight: 4,
            }}
            data-testid="AutosizeInput"
            onFocus={(f: any) => {
              f.target.select();
            }}
            onChange={(e) => updateBoard(e.target.value)}
            onKeyDown={(e: any) => updatedOpeningName(e)}
          />
        </div>
      ) : (
        <div
            className={`isEditable rounded flex w-auto space-y-2 trucate min-w-min  items-center justify-center text-white focus:outline-none  py-1  mr-1 font-semibold text-base tracking-wider line-clamp-1
`}
          onClick={() => showBoardInput(!boardInput)}
          role="button"
          tabIndex={0}
          ref={txtRef}
          onKeyDown={() => showBoardInput(!boardInput)}
        >
          {props.boardName}
        </div>
      )}
    </React.Fragment>
  );
};
const mapStateToProps = (state: any) => ({
  currentBoard: state.opening.currentOpening,
  socketClientID: state.opening.clientId,
  applicationId: state.opening.applicationId,
});
const mapPropsToState = (dispatch: Dispatch) => ({
  editOpeningName: (board: Opening) => dispatch(updateOpeningName(board)),
});

export default connect(mapStateToProps, mapPropsToState)(BoardInput);
