import { Checkbox, Typography } from "antd";
import React, {  useEffect, useState } from "react";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { Integrations, jdCreationObject } from "./Types";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import { PUBLISH, UNPUBLISH } from "../../constant";
import { connect } from "react-redux";
import UpgradePlanModal from "../../pages/setting/billingSetting/UpgradePlanModal";
import { newOrganisation } from "../../lib/apiRes";
import { LockOutlined } from "@ant-design/icons";
import { useOrgMetadata } from "../../pages/setting/billingSetting/OrgMetadata";

const { Text } = Typography;
type IntegrationProps = {
  data: jdCreationObject;
  integration: Integrations[];
  handleSetIntegrations: (value: Integrations[]) => void;
  currentOrg: newOrganisation;
};
const CurrentIntegrations = (props: IntegrationProps) => {
  const orgMetadata = useOrgMetadata(props.currentOrg);
  const [handleSelectAllIntermediate, setHandleSelectAllIntermediate] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const handleCheckboxChange = (e: CheckboxChangeEvent, key: string) => {
    const newIntegrations = props.integration.map((integration) =>
      integration.integration_id === key
        ? { ...integration, status: e.target.checked ? PUBLISH : UNPUBLISH }
        : integration
    );
    props.handleSetIntegrations(newIntegrations);
    // Check if any integration is not selected, and set selectAll to false if so
    const allSelected = newIntegrations.every(integration => integration.status === PUBLISH);
    setSelectAll(allSelected);
  };

  const handleSelectAllCheck = (e: CheckboxChangeEvent) => {
    setSelectAll(e.target.checked);
    const newIntegrations = props.integration.map((integration) =>
      ({ ...integration, status: e.target.checked ? PUBLISH : UNPUBLISH }));
    props.handleSetIntegrations(newIntegrations);
  }
  const handleBillingModal = () => {
    setIsModalVisible(true);
  }
  const handleBillingModalCancel = () => {
    setIsModalVisible(false);
  };
  useEffect(() => {
    const selectedCount = props.integration.filter(integration => integration.status === PUBLISH).length;
    const isIntermediate = selectedCount > 0 && selectedCount < props.integration.length;
    setHandleSelectAllIntermediate(isIntermediate);
  }, [props.integration]);
  return (
    <div className="mt-4">
      {props.integration.length > 0 && (
        orgMetadata?.job_posting === 'true' ? (
          <div className="pr-4 flex gap-3">
            <Checkbox checked = {selectAll} indeterminate = {handleSelectAllIntermediate} onChange={handleSelectAllCheck} />
            <Text strong>Public job boards</Text>
          </div>
        ) : (
          <div className="pr-4 flex gap-3">
            <Checkbox onClick={handleBillingModal} checked={false} />
            <Text strong>             
              Public job boards</Text>
          </div>
        )
      )}

      {props.integration.length > 0 && (
        <div className="mb-4">
          <Text type="secondary" style={{ fontSize: "14px" }}>
            {orgMetadata?.job_posting === 'false' &&          
            <LockOutlined />
          }
          You can publish your job post on the below platforms for FREE.
          </Text>
        </div>
      )}
      <div className="flex flex-wrap gap-3 rounded">
        {props.integration
          ?.map((integration) => (
            <div
              className="flex rounded items-center gap-1 p-2"
              key={integration.integration_id}
              style={{
                border: "1px solid #D9D9D9",
                padding: "8px",
                height: "56px",
                minWidth: "172px",
                maxWidth: "auto"
              }}
            >
              {
                orgMetadata?.job_posting === 'true' ? (
                  <Checkbox
                    checked={integration.status === PUBLISH}
                    onChange={(e: CheckboxChangeEvent) =>
                      handleCheckboxChange(e, integration.integration_id)
                    }
                  />
                ) : (
                  <Checkbox
                    disabled
                    checked={false}
                  />
                )
              }

              <img
                src={integration.platform_image}
                alt={integration.platform_name}
                style={{
                  width: "30px",
                  height: "30px",
                  marginLeft: "8px",
                  marginRight: "8px",
                }}
              />
              <div>{capitalizeFirstLetter(integration.platform_name)}</div>
            </div>
          ))}
      </div>
     
      <UpgradePlanModal isModalVisible={isModalVisible} handleCancel={handleBillingModalCancel} />
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  currentOrg: state.opening.currentOrg,
});
export default connect(mapStateToProps)(CurrentIntegrations);

