import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import {
  CandidateDetailReview,
  loginUser,
  orgMember,
  ReviewComment,
} from "../../../type/type";
import ReactTextareaAutosize from "react-textarea-autosize"
import CommentInputActionButton from "../../card/CommentInputActionButton";
import { AddCommentOnReviewReq } from "../../../lib/apiReq";
import { createReviewComment } from "../../../lib/api";
import { Dispatch } from "redux";
import { addCommentReview } from "../../../redux/actions/opening/action";
import CloseIcon from "../../../assets/Close";
import { COMMENT_TAG, MAX_CHAR_LIMIT_WARNING } from "../../../constant";
import { capitalizeFirstLetter, generateTooltipForMember } from "../../../utils/capitalizeFirstLetter"
import { Avatar } from "antd"
import TacitbaseAvtar from "../../../ant/Avtar"
// import { createReviewComment } from "../../../lib/api";
type CreateReviewProps = {
  members: orgMember;
  applicationId: string;
  selectedItems: any;
  loginUser: loginUser;
  clientId: string;
  currentCandidateInReview: CandidateDetailReview;
  addRComment: (comment: ReviewComment, tag: string) => void;
  close: () => void;
};
const CreateReview = (props: CreateReviewProps) => {
  const ref = useRef<HTMLTextAreaElement>(null);
  const [comment, setComment] = useState("");
  const [commentInput, showCommentInput] = useState({
    Input: false,
    style: "",
  });

  const click = (value: boolean) => {
    if (value) setComment("");
  };
  const validateCommentLength = (e: any) => {
    setComment(e.target.value);
  };
  const saveComment = (e: any) => {
    if (comment.length < 250 && (e.keyCode == 13 && e.metaKey) || e.button == 0) {
      let commentReq: AddCommentOnReviewReq = {
        body: comment.trim(),
        review_id: props.currentCandidateInReview.id,
        socket_client_id: props.clientId,
      };
      createReviewComment(commentReq).then((res) => {
        props.addRComment(res, COMMENT_TAG);
        setComment("");
      });
    }
  };

  const txtref = useRef(null);

  return (
    <>
      <div>
        <div className="flex justify-end w-full items-end my-1 ">
          <button
            className="p-1 hover:bg-gray-200 rounded-default"
            onClick={props.close}
          >
            <CloseIcon />
          </button>
        </div>
        <div className="w-full flex justify-between ">
          <div className="mr-1.5">
            <Avatar.Group maxCount={4} size={"default"}>{props.loginUser.user.imageUrl ?
              <TacitbaseAvtar size="default" toolTip={generateTooltipForMember(props.members[props.loginUser.user.id])} src={props.loginUser.user.imageUrl} />
              : <TacitbaseAvtar size="default" toolTip={generateTooltipForMember(props.members[props.loginUser.user.imageUrl])} content={props.loginUser.user.firstName + props.loginUser.user.lastName} />
            }</Avatar.Group>
          </div>
          <div
            ref={txtref}
            className={`${
              commentInput.style
                ? commentInput.style
                : "border-tacitbase-tertiary1 border-opacity-20 "
            } border h-auto rounded bg-white w-full`}
          >
            <ReactTextareaAutosize
              className={` ${
                commentInput.style ? "cursor-text" : "cursor-pointer"
              } h-8 w-full py-2 px-2 rounded focus:outline-none resize-none cursor-pointer text-sm  `}
              value={comment}
              ref={ref}
              data-testid="commentInput"
              onKeyDown={(e: any) => saveComment(e)}
              placeholder="Write a comment..."
              style={{ wordBreak: "break-word" }}
              onChange={validateCommentLength}
              onClick={
                commentInput.Input
                  ? (e: any) => {
                      e.preventDefault;
                    }
                  : () => {
                      showCommentInput({
                        Input: true,
                        style: "border-tacitbase-secondary3",
                      });
                    }
              }
              tabIndex={0}
            ></ReactTextareaAutosize>
            {commentInput.Input ? (
              <CommentInputActionButton
                clickedClose={(e: boolean) => click(e)}
                click={saveComment}
                commentValue={comment}
                comments={comment}
                isExceed={comment.length > 250 ? true : false}
                CommentInput={(e) =>
                  showCommentInput({
                    Input: e.Input,
                    style: e.style,
                  })
                }
              />
            ) : null}
            {comment.length > 250 && (
              <div className="text-red-500 px-2 pb-2 text-sm">
                {capitalizeFirstLetter(MAX_CHAR_LIMIT_WARNING)}{" "}
              </div>
            )}
          </div>
        </div>{" "}
      </div>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  members: state.opening.members,
  loginUser: state.opening.loginUser,
  applicationId: state.opening.applicationId,
  currentCandidateInReview: state.opening.currentCandidateInReview,
  clientId: state.opening.clientId,
});

const mapPropsToState = (dispatch: Dispatch) => ({
  addRComment: (comment: ReviewComment, tag: string) =>
    dispatch(addCommentReview(comment, tag)),
});
export default connect(mapStateToProps, mapPropsToState)(CreateReview);
