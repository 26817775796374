import React from 'react';

const Facebook = () => {
    return (
        <div>
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_6189_16501)">
<path d="M23.6944 0.980957H2.30736C1.57481 0.980957 0.980957 1.57481 0.980957 2.30736V23.6944C0.980957 24.4269 1.57481 25.0208 2.30736 25.0208H23.6944C24.4269 25.0208 25.0208 24.4269 25.0208 23.6944V2.30736C25.0208 1.57481 24.4269 0.980957 23.6944 0.980957Z" fill="#3D5A98"/>
<path d="M17.5663 25.0187V15.7095H20.6904L21.1575 12.0817H17.5663V9.76603C17.5663 8.71587 17.8588 7.99884 19.3639 7.99884H21.2855V4.74884C20.355 4.65186 19.4199 4.60575 18.4844 4.61071C15.7179 4.61071 13.8125 6.29665 13.8125 9.40649V12.0817H10.6885V15.7095H13.8125V25.0187H17.5663Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_6189_16501">
<rect width="26" height="26" fill="white"/>
</clipPath>
</defs>
</svg> 
        </div>
    );
};

export default Facebook;
