import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { Dispatch } from "redux";
import { LABELS, MEMBERS } from "../../../constant";
import { filter, removeFilters } from "../../../redux/actions/opening/action";
import {
  InitLabel,
  Label,
  ObjectType,
  Opening,
  OpeningMembers,
  openingMemberType,
  orgMember,
} from "../../../type/type"
import { Avatar, Divider, Popover, Typography,Checkbox, Tag } from "antd"
import TacitbaseAvtar from "../../../ant/Avtar"
import { capitalizeFirstLetter, generateTooltipForMember } from "../../../utils/capitalizeFirstLetter"
import { CloseOutlined } from "@ant-design/icons";
import { determineTextColor } from "../../../utils/CalculateBrightnessOfColor";
const {Text} = Typography;
export type FilterProps = {
  click: (e: boolean) => void;
  orgMembers: orgMember;
  applicationId: string;
  openingMembers: OpeningMembers;
  currentOpening: Opening;
  labels: InitLabel;
  filter: (id: any) => void;
  filterObj: ObjectType;
  removeFilters: () => void;
  roles: string[];
};

const FilterPopup = (props: FilterProps) => {
  const filterRef = useRef<HTMLDivElement>(null);

  const history = useHistory();
  let boardLabels = Object.values(props.labels);
  const handleInputChange = (event: any) => {
    let newFilterKey = {
      checked: event.target.checked,
      key: event.target.name,
      value: event.target.value,
    };
    props.filter(newFilterKey);
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);
  const handleClick = (e: any) => {
    if (filterRef.current && !filterRef.current.contains(e.target)) {
      props.click(false);
      return;
    }
  };
  /*
   * convert obj={member:['1','2']|label:['3','4']} to string "members:1,2-labels:3,4"
   * assign string value to filter key
   * if object is null then delete that key from object and redirect to original path
   */

  useEffect(() => {
    let filterDefault: any = props.filterObj || {};
    if (filterDefault) {
      for (const key of Object.keys(filterDefault)) {
        let newQueryParam = "";
        if (filterDefault[key].length <= 0) {
          delete filterDefault[key];
        }
        newQueryParam = Object.entries(filterDefault)
          .map((e) => e.join(":"))
          .join("|");

        if (newQueryParam) {
          history.push({
            pathname: `/o/${props.applicationId}/b/${props.currentOpening.id}/tracker`,
            search: `filter=${newQueryParam}`,
          });
        } else {
          props.removeFilters();
          history.push({
            pathname: `/o/${props.applicationId}/b/${props.currentOpening.id}/tracker`,
          });
        }
      }
    }
  }, [props.filterObj]);

  /*
   * add checked value to the corresponding item
   */
  const applyCheck = (key: string, matchID: string) => {
    let filterDefault: any = props.filterObj;
    let checked = false;
    if (filterDefault) {
      if (filterDefault[key]) {
        filterDefault[key].map((id: string) => {
          if (id == matchID) {
            checked = true;
          }
        });
      }
    }
    return checked;
  };


  const renderLabels = (labels: Label[]) => {
    return (
        <div className="h-auto overflow-hidden">
            <div className="space-y-1.5">
                {labels.map((label) => { 
                    return (
                      <div className="label-btn flex space-x-2 px-2  w-full items-center justify-center cursor-pointer" key={label.id}>
                           <Checkbox
                          name="labels"
                          value={label.id}
                          onChange={handleInputChange}
                          checked={applyCheck("labels", label.id)}
                          />
                            <Tag
                                key={label.id}
                                color={label.color}
                                className="w-full flex items-center h-8 justify-between"
                            >
                                <div className="flex justify-center items-center">
                                    <Text
                                        ellipsis
                                        style={{ color: determineTextColor(label.color), maxWidth: 200, minWidth: 100 }}
                                    >
                                        {label.name}
                                    </Text>
                                </div>
                            </Tag>
                        </div>
                    )
                })}
            </div>
        </div>
    )
};
  return (
    <React.Fragment>
      <Popover title = {
        <div>
          <div className="flex px-1 justify-between">
            <Text>Filter</Text>
            <CloseOutlined onClick={()=>props.click} />
          </div>
          <Divider type="horizontal" className="mx-0 my-1"/>
        </div>
      } 
      placement="topRight"
      className="filter-popover"
      trigger="click"
       onVisibleChange={props.click}
      visible = {true}
      content = {
      <div
        ref={filterRef}
        data-testid="filterPanal"
        className="flex flex-col h-auto mt-1 z-10 w-80 bg-white text-gray-800 tracking-normal text-sm font-normal rounded-default max-h-96 overflow-y-auto"
      >
        <div className="overflow-y-scroll ">
          <div className="my-1">
          <Text>{ capitalizeFirstLetter(MEMBERS)}</Text>
          </div>
          {props.openingMembers[props.currentOpening.id]?.map(
            (member: openingMemberType, index: number) =>
              props.orgMembers[member.id] && (
                <div
                  className="flex items-center space-x-2 px-2 "
                  key={props.orgMembers[member.id].id}
                >
                  <Checkbox
                    name="members"
                    value={props.orgMembers[member.id].id}
                    onChange={handleInputChange}
                    checked={applyCheck(
                      "members",
                      props.orgMembers[member.id].id
                    )}
                  />
                  <div
                    key={index}
                    className="p-1.5 flex w-full mb-1.5 rounded justify-between items-center bg-gray-300 bg-opacity-25 hover:bg-gray-300 hover:bg-opacity-40  ml-2 mr-1"
                  >
                    <div className="flex flex-row items-center space-x-2">
                      <div className="flex flex-col">
                      
                          <Avatar.Group>{props.orgMembers[member.id].image_url ?
                            <TacitbaseAvtar size="default" toolTip={generateTooltipForMember(props.orgMembers[member.id])} src={props.orgMembers[member.id].image_url} />
                            : <TacitbaseAvtar size="default" toolTip={generateTooltipForMember(props.orgMembers[member.id])} content={props.orgMembers[member.id].first_name[0] + props.orgMembers[member.id].last_name[0]} />
                          }</Avatar.Group>
                      
                      </div>
                      <div className="flex flex-col">
                        <div className="leading-snug font-bold text-sm text-gray-800 tracking-normal line-clamp-1 truncate w-40">
                          {props.orgMembers[member.id].username}
                        </div>
                        <div className="text-xs leading-snug tracking-wide text-gray-800 truncate w-40">
                          {props.orgMembers[member.id].email}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
          )}

          {boardLabels.length > 0 && (
            <div className="mt-2">
              <div className="my-1">
              <Text>{ capitalizeFirstLetter(LABELS)}</Text>
              </div>
                <div>
                  {renderLabels (boardLabels)}
                </div>
            </div>
          )}
        </div>
      </div>
      }
      />
    </React.Fragment>
  );
};
const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
  orgMembers: state.opening.members,
  openingMembers: state.opening.openingMembers,
  currentApplicant: state.opening.currentApplicant,
  applicantMembers: state.opening.applicantMembers,
  applicationId: state.opening.applicationId,
  clientId: state.opening.clientId,
  labels: state.opening.labels,
  roles: state.opening.roles,

  filterObj:
    state.opening.boardFilterKeys[state.opening.currentOpening.id] || {},
});
const mapPropsToState = (dispatch: Dispatch) => ({
  filter: (id: any) => dispatch(filter(id)),
  removeFilters: () => dispatch(removeFilters()),
});

export default connect(mapStateToProps, mapPropsToState)(FilterPopup);
