import React, { useState } from "react"
import { Form, Input, Tooltip, Typography } from "antd"
import { FormBuilder } from "../../type/type"
import FormItem from "../../ant/FormItem"
import Paragraph from "antd/es/typography/Paragraph"
import { LabeledIconButton } from "../../ant/Button"
import { CopyOutlined, ExportOutlined } from "@ant-design/icons"
const { Text } = Typography
type FormSettingsProps = {
    currentFormDetails: FormBuilder,
    handleChangeRequest: (value: any) => void
};

const FormSettings = (props: FormSettingsProps) => {
    const [form] = Form.useForm();
    form.setFieldsValue(props.currentFormDetails)
    const [tooltipVisible, setTooltipVisible] = useState(false)
    const handleCopy = () => {
        const textToCopy = props.currentFormDetails.form_url ? props.currentFormDetails.form_url : "Not available"
        navigator.clipboard.writeText(textToCopy).then(() => {
            setTooltipVisible(true)
            setTimeout(() => setTooltipVisible(false), 1000)
        })
    };

    const handleFormChange = (changedValues: any) => {
        props.handleChangeRequest(changedValues)
    };
    function openRoute() {
        const route = props.currentFormDetails.form_url
        window.open(route, '_blank')
    }


    return (
        <Form
            form={form}
            layout="vertical"
            initialValues={{
                title: props.currentFormDetails.title,
                form_url: props.currentFormDetails.form_url,
            }}
            onValuesChange={(changedValues) => handleFormChange(changedValues)}
        >
            <FormItem 
                name="title"
                label="Title"
            >
                <Input disabled />
            </FormItem>

            {props.currentFormDetails.status === "active" && (
                <>
                    <Text className="font-medium">Form Link</Text>
                    <div className="w-full flex space-x-2 justify-start pt-2 items-center">
                        <Paragraph copyable className="border rounded  px-2" style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            width: "80%",
                            marginBottom: 0,
                            color: "#a5a5a5", // Color of the ellipsis dots

                        }}><Text type="secondary" className="text-xs">{props.currentFormDetails.form_url ? props.currentFormDetails.form_url : "Not available"}</Text></Paragraph>
                        <div className="flex justify-center items-center space-x-2">
                            <LabeledIconButton htmlType="button" size="small" onClick={openRoute} label={""} icon={<ExportOutlined />} />
                            <Tooltip title="Copied to clipboard!" visible={tooltipVisible}>
                                <LabeledIconButton htmlType="button" size="small" onClick={handleCopy} label={""} icon={<CopyOutlined />} />
                            </Tooltip>
                        </div>
                    </div>

                </>
            )}


        </Form>
    )
}

export default FormSettings
