

import React from "react"
import Login from "../../components/signIn/Login"
import TB_ORANGE_LOGO from "../../images/TB full orange.png"


const LoginPage: React.FC = () => {
  return (
    <>
     
                
            
      <div className="flex flex-col w-full h-full bg-cover  bg-bgImgType1 ">
<div className="  justify-center items-center">
      <div className="bg-transparent pt-6 pl-6 "> 
       <img src={TB_ORANGE_LOGO} alt="" width="200px"  style={{backgroundColor:'transparent'}} />
       </div>

        <div className="flex  pt-10   xl:space-x-4  w-full h-full overflow-y-scroll ">
          <div className=" flex  justify-center h-full items-start w-full">
            <div
              className="bg-white p-8 rounded xl:m-10   "
              style={{
                width: 463,
                boxShadow: "2px 4px 10px 0px #00000040",
                border: "1px solid #D9D9D9",
              }}
            >
              <Login />
              </div>

            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default LoginPage
