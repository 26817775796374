// PleaseVerifyEmail.tsx
import React, { useEffect, useState } from "react"
import { checkEmailVerify, resendEmailVerification } from "../../../lib/api"
import { AxiosError } from "axios"
import { connect } from "react-redux"
import { loginUser } from "../../../type/type"
import { useHistory, useParams } from "react-router-dom"
import { Dispatch } from "redux"
import { emailVerify } from "../../../redux/actions/opening/action"
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter"
import { Typography } from "antd"
import Paragraph from "antd/es/typography/Paragraph"
import Link from "antd/es/typography/Link"

const { Text } = Typography

type PleaseVerifyEmailProps = {
  loginUser: loginUser,
  emailVerify(): void
}

const PleaseVerifyEmail: React.FC<PleaseVerifyEmailProps> = ({ loginUser, emailVerify }) => {
  const { verificationId } = useParams<{ verificationId: string }>()
  const history = useHistory()
  const [emailResent, setEmailResent] = useState(false)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    if (loginUser) {
      if (loginUser.user.email === "") {
        history.push("/login")
      }
    }
    if (loginUser.user.verified && loginUser.user.registrations && loginUser.user.registrations.length !== 0) {
         history.push("/o")
    } else {

      verificationId
        ? checkEmailVerify(verificationId).then((res) => {
          if (res) {
            if (loginUser) {
              emailVerify()
            }
            history.push("/onboarding")
          }
        })
        : null
    }
  }, [])
  const resendEmail = () => {
    setLoading(true)
    resendEmailVerification(loginUser.user.email)
      .then((res: any) => {
        if (res) {
          setLoading(false)
          setEmailResent(true) // Set emailResent to true after successful resend
          setTimeout(() => {
            setEmailResent(false) // Reset emailResent to false after 5000 milliseconds
          }, 5000)
        }
      })
      .catch((reason: AxiosError) => {
        console.log(reason)
      })
  }
  return (
    <div>
      <div className=" text-gray-600 ">
        <div className="w-full flex items-center justify-center font-semibold md:text-lg text-base">
          {capitalizeFirstLetter("Account verification")}
        </div>
        <div className="text-gray-600 my-6">
          <div className="text-gray-600">
            <Paragraph>
              Hey{" "}
              <strong>{loginUser.user.firstName}</strong>,
            </Paragraph>         
          </div>
          <Paragraph className="text-gray-600" >
          For your security and a smooth Tacitbase experience, verify your email by clicking the link sent to&nbsp; 
            <Link href={`mailto:${loginUser.user.email}`}>
                    {loginUser.user.email}
            </Link>
          </Paragraph>        
          <Paragraph className="text-gray-600">
            Didnt receive the email?&nbsp;
            <Link onClick={resendEmail}>
              {capitalizeFirstLetter("resend link")}
            </Link>
                  {loading && (
                    <div className={` flex justify-center items-center `}>
                      <div
                        style={{ borderTopColor: "#4B48A5" }}
                        className="w-4 h-4 border-2 border-gray-300 border-solid rounded-full animate-spin"
                      ></div>
                    </div>
            )}
          </Paragraph>
          <Paragraph className="text-gray-600" >
          See you soon,<br/>
Team Tacitbase
          </Paragraph>

          {/* </Paragraph>{" "} */}

          {emailResent && (
            <div className=" ">
              <div className="flex text-md items-center font-light tracking-wide space-x-4 bg-green-50 p-2 justify-between  rounded">
                <Text>
                  Verification email sent to{" "}
                  <span style={{ color: "#4B48A5" }}>
                    {loginUser.user.email}
                  </span>
                </Text>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  loginUser: state.opening.loginUser,
  applicationId: state.opening.applicationId,
  applicationName: state.opening.applicationName,
})
const mapPropsTostate = (dispatch: Dispatch) => ({
  emailVerify: () => {
    dispatch(emailVerify())
  },
})

export default connect(mapStateToProps, mapPropsTostate)(PleaseVerifyEmail)
