import React from "react";
import { Attachment, Mail, Member, orgMember } from "../../type/type"
import Tacitmail from "../../assets/Tacitmail";
import {  TACITMAIL } from "../../constant";
import { connect } from "react-redux";
// import AttachmentPreviewOfMail from "./AttachmentPreview";
// import ShowMailDetailsPopup from "./ShowMailDetailsPopup";
import { seperateMailAddress } from "../../utils/seperateMailAddress";
import TacitbaseAvtar from "../../ant/Avtar"
// import MailAttachmentView from "../mail/MailAttachmentView";
import { IconButton } from "../../ant/Button";
import { DownOutlined, RollbackOutlined } from "@ant-design/icons";
import { Button, Popover, Typography } from "antd";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import ShowMailDetailsPopup from "./ShowMailDetailsPopup";
import AttachmentPreviewOfMail from "./AttachmentPreview";
import { renderCreatedAt } from "../../utils/renderCreatedAt";
import parse from "html-react-parser";

const { Text } = Typography;

type MailThreadType = {
  setMailId(id: string): void;
  mail: Mail;
  isCardThread?: boolean;
  members: orgMember;
};
const MailThread = (props: MailThreadType) => {
  let address = seperateMailAddress(props.mail.from);
  let emailExists = Object.values(props.members).find(
    (member: Member) => member.id == props.mail.user_id
  );
  return (
    <div className="flex py-2 px-3 bg-white space-x-3 justify-start items-start">
      <div className="flex pt-2">
      {emailExists ? (
        <TacitbaseAvtar
          src={emailExists.image_url}
          key={emailExists.id}
          size="default"
        />
      ) : address.name == TACITMAIL ? (
        <Tacitmail />
          ) : (
          <TacitbaseAvtar
            size="default"
            content={address.name[0]}
          />
        )}
      </div>

      <div className="flex-col flex w-full trix-content">
        <div className="w-full text-xs flex justify-between space-x-2">
          <div
            className={`leading-none flex-wrap overflow-hidden items-center flex `}
            style={{maxWidth:'80%'}}
          >
            <Text strong >{capitalizeFirstLetter(props.mail.from)}</Text>
            {/* {props.isCardThread ? null : (
                          <Text type="secondary" className="text-sm" style={{fontSize:'13px'}}>{address.email}</Text>

            )} */}
          </div>
          <div className="text-xs flex-shrink-0 justify-start items-center flex space-x-2">
            <div className="flex text-xs leading-none">
            <Text type="secondary" className="text-sm" style={{fontSize:'13px'}}>
              {renderCreatedAt(props.mail.created_at)}
              </Text>
            </div>
            {/* <button onClick={() => props.setMailId(props.mail.id)}>
              <i className="fa fa-reply-all px-1 " aria-hidden="true"></i>
            </button> */}
            <IconButton icon={<RollbackOutlined />}  onClick={() => props.setMailId(props.mail.id)} className="m-0 p-0" tooltipTitle="Reply"/>
            {/* <button onClick={() => props.setMailId(props.mail.id)}>
              <i className="fa fa-share px-1" aria-hidden="true"></i>
            </button> */}
          </div>
        </div>
        <div className="text-xs  space-x-1 mb-1 flex justify-start items-center leading-snug">
          {/* <span style={{ fontSize: "11px" }}> */}
          <Text type="secondary" className="text-sm" style={{fontSize:'13px'}}>
            To: {props.mail.to.map((address: string, index: number) => address &&
            <span key={index} className="pr-1">{address}
              {index !== props.mail.to.length - 1 && <span>&#44;</span>}
            </span>
          )}
          </Text>
          {/* </span> */}
          <Popover
              content={<ShowMailDetailsPopup mail={props.mail}/>}
              placement="bottomLeft" 
              // title="Mail Details"
              trigger="click" // Ensure that the popover opens on click
            >
            <Button
              icon={
                <DownOutlined style={{ color: "#8c8c8c", fontSize: "12px" }} />
              }
                type="text"
                className="m-0 p-0"
              size="small"
              style={{ height: "16px", width: "16px" }}
              />
            </Popover>
        </div>
        <div className="text-xs mt-1 pb-2">
          <Text className="text-xs">
          {parse(props.mail.body)}
          </Text>
          </div>
        <div className="flex flex-wrap">
          {props.mail.attachments.length > 0 &&
            props.mail.attachments.map((attachment: Attachment) => (
              <>
                {" "}
                <div className="text-xs mb-1 mr-1 flex  space-x-2 w-40  ">
                  <AttachmentPreviewOfMail attachment={attachment} />
                 {/* <MailAttachmentView attachment={attachment} /> */}
                </div>
              </>
            ))}
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
  members: state.opening.members,
  openingMembers: state.opening.openingMembers,
  currentApplicant: state.opening.currentApplicant,
  applicantMembers: state.opening.applicantMembers,
  applicationId: state.opening.applicationId,
  clientId: state.opening.clientId,
  orgRoles: state.opening.orgRoles,
});

export default connect(mapStateToProps)(MailThread);
