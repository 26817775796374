import React, { Component } from "react";
import CloseIcon from "../../../assets/Close";
import FilePreview from "../../common/FilePreview/FilePreview";

type AttachmentPreviewProps = {
  link: any;
  closePreview: () => void;
  fileName: string;
};
class AttachmentPreview extends Component<AttachmentPreviewProps> {
  render() {
    return (
      <React.Fragment>
        <div className="justify-center bg-black bg-opacity-50 items-center flex z-10 fixed inset-0  outline-none focus:outline-none">
          <div className="flex items-start rounded relative w-full h-full justify-center overflow-y-scroll ">
            <div className="w-192 bg-gray-100 rounded mt-12">
              <div className="flex justify-between p-2">
                <div className="flex">{this.props.fileName}</div>
                <span
                  onClick={this.props.closePreview}
                  aria-hidden
                  className="cursor-pointer mx-1 flex justify-center items-center"
                  data-testid="testBtn"
                >
                  <CloseIcon />
                </span>
              </div>
              <div className="w-192 h-212">
                <FilePreview link={this.props.link} />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AttachmentPreview;
