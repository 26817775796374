// import { Member } from "../type/type"

export const capitalizeFirstLetter = (text: string): string =>
  text.charAt(0).toUpperCase() + text.slice(1);

export function generateTooltipForMember(member: {
  first_name: string,
  last_name: string
}) {
  if (member && member.first_name && member.last_name) {
    return `${member.first_name} ${member.last_name}`
  } else {
    return '' // Return an empty string if member information is missing
  }
}


export const formatLink = (link: string) => {
  return link.startsWith('http://') || link.startsWith('https://') ? link : `https://${link}`;
};


//Function for truncating the word after specific limit
export function truncateText(text: string, maxLength: number) {
  if (text.length <= maxLength) {
    return text;
  }
  return text.substring(0, maxLength) + '...';
}