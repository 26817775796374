import { Dispatch, SetStateAction } from "react";

// Function to open the modal
export const handleBillingModal = (setIsModalVisible: Dispatch<SetStateAction<boolean>>) => {
  setIsModalVisible(true);
};

// Function to close the modal
export const handleBillingModalCancel = (setIsModalVisible: Dispatch<SetStateAction<boolean>>) => {
  setIsModalVisible(false);
};
