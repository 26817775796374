import React, { useEffect, useState } from 'react'
import { SecondaryButton } from '../../../ant/Button'
import { useParams } from 'react-router-dom'
import { archiveBoard } from '../../../lib/api';
import {  Popconfirm, Typography } from 'antd';
import { connect } from 'react-redux';
import {   getActiveBoard, updateOpeningStatus } from '../../../redux/actions/opening/action';
import { Dispatch } from 'redux';
import { Opening, TotalBoards } from '../../../type/type';
import { throwSuccess } from '../../../service/throwError';
import { JOB_POST_ARCHIVED_SUCCESS, JOB_POST_UNARCHIVED_SUCCESS } from '../../../constant';

const { Text } = Typography;

interface Params {
  board_id: string;
}

type ArchiveBoardProps = {
  currentOpening: any;
  currentJD:any;
  updateOpening: (opening: Opening) => void;
  getActiveBoard(data: TotalBoards):void;
  totalBoards:TotalBoards;
}

const ArchiveBoard = (props: ArchiveBoardProps) => {
  const { board_id } = useParams<Params>();
  // const [loading, setLoading] = useState(false);
  const [boardStatus, setBoardStatus] = useState(false);
  useEffect(() => {
    if (props.currentOpening.archive) {
      setBoardStatus(true);
    } else {
      setBoardStatus(false);
    }
  
  }, [props.currentOpening,props.currentJD]);

  
  const handleArchiveBoard = () => {
    if (board_id) {
      return new Promise<void>((resolve, reject) => {
        archiveBoard(board_id, !boardStatus,props.currentJD.id).then(() => {
          throwSuccess(boardStatus?JOB_POST_UNARCHIVED_SUCCESS :JOB_POST_ARCHIVED_SUCCESS)
          let updatedOpening = {...props.currentOpening, archive:!boardStatus}
            props.updateOpening(updatedOpening)
            const activeJobs = props.totalBoards.active;
            const data = {
              active:boardStatus?activeJobs+1:activeJobs-1,
              total:props.totalBoards.total
            }
            props.getActiveBoard(data) // Update the active board count in the store.
            // props.removeOpening(props.currentOpening) // Remove the archived board from the store.
            resolve()
        }).catch((err) => {
          console.log("error while archiving board", err)
          reject(err)
        })
      })
    }
  }
  return (<>
   <div className="flex items-center px-4 border-b" style={{ height: 44 }}>
        <Text>{boardStatus ? 'Unarchive job post' : 'Archive job post'}</Text>
      </div>
    <div className="flex gap-4 flex-col px-4 w-5/6 py-4">
      <Text>
      {boardStatus ? (
      'Unarchiving will restore this job post to the active job list.'
      ) : (
    <>
     <Text className='font-semibold'>After archiving this job post:</Text> 
      <br />
      • The job post will be unpublished from all job posting sites.
      <br />
      • Guests and agencies will no longer have access to this job post.
      <br />
      • The job post will no longer be visible in real-time analytics.
      <br />
      • Scheduled interviews will proceed as planned, but interviewers won’t be able to send feedback.
      <br />
      • Emails to this job board will be frozen, though candidate emails will still be visible in the talent pool.
      <br />
      • Reviews associated with this job post will remain active as they have a separate scope.
    </>
  )}
</Text>
      <Popconfirm
        description={
          <div>
            <p>
              {boardStatus 
                ? 'Are you sure you want to unarchive this job post?' 
                : 'Are you sure you want to archive this job post?'}
            </p>
          </div>
        }
        onConfirm={handleArchiveBoard}
        okText = {boardStatus ? 'Unarchive' : 'Archive'}
        title={boardStatus ? 'Confirm unarchive' : 'Confirm archive'}
      >
        <SecondaryButton
          className='w-36'
          onClick={()=>{}}
        >
          {boardStatus ? 'Unarchive job post' : 'Archive job post'}
        </SecondaryButton>
      </Popconfirm>
    </div>
    </>
  )
}

const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
  currentJD:state.opening.currentTrackerJd,
  totalBoards:state.opening.totalBoards
});

const mapPropsToState = (dispatch: Dispatch) => ({
  updateOpening: (board: Opening) => dispatch(updateOpeningStatus(board)),
  getActiveBoard:(data: TotalBoards)=> dispatch(getActiveBoard(data)),
})
export default connect(mapStateToProps,mapPropsToState)(ArchiveBoard)
