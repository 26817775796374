import React from "react";

const UnsupportedChart = () => {
  return (
    <div
      className="flex justify-center items-center h-full"
      style={{
        fontSize: "24px",
        //   color: "#7152f2"
      }}
    >
      Unsupported Chart Type
    </div>
  );
};

export default UnsupportedChart;
