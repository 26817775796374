import React, { useEffect, useState } from "react";
// import PopupTitle from "../PopupTitle";
import LabelInput from "../../card/cardLabel/labelInput/LabelInput";
import TacitbaseInput from "../../../ant/Input";
import { COLOR_DATA_LABEL,SOCKET_CLIENT_ID } from "../../../constant";

import {
  currentApplicant,
  GetOpening,
  Label,
  updatedColor,
} from "../../../type/type.d";
import ColorSelection from "../../createBoardPopup/colorSelection/ColorSelection";

// Redux stuff
import { connect } from "react-redux";
import {
  saveBoardLabel,
  updateLabelState,
} from "../../../redux/actions/opening/action";
import { Dispatch } from "redux";

// Api's
import { createOpeningLabel, editLabel } from "../../../lib/api";
import { labelReq } from "../../../lib/apiReq";
import Emoji from "../../Emoji";
import { Button } from "antd";
// import { toast } from "react-toastify";
// import ShowError from "../../../service/ShowError";
// import Portal from "../../portal/Portal";
// import Portal from "../../portal/Portal";

export type BoardLabelPopupProps = {
  currentOpening: GetOpening;
  currentApplicant: currentApplicant;
  saveOpeningLabel(label: any, openingId: string): void;
  click?: () => void;
  prev?: () => void;
  flag: boolean;
  close: () => void;
  clientId: any;
  updateApplicantLabel(updatedLabel: any): void;
};
const BoardLabelInput = (props: BoardLabelPopupProps) => {
  let islimitexceed = false;

  const [loading,setLoading] = useState(false);
  const [labelInput, showLabelInput] = useState(false);
  const [defaultColors, setColors] = useState(COLOR_DATA_LABEL);
  const [selectedColorCode, setSelectedColorCode] = useState("");
  const [labelName, setLabelName] = useState("");
  // const [empty, isEmpty] = useState(true);
  const [updateFlag, setUpdateFlag] = useState(false);
  const [isdisable ,setIsdisable] = useState(true);
  const [updateLabelObj, setUpdateLabelObj] = useState({
    color: "",
    id: "",
    name: "",
  });
  useEffect(() => {
    let bgColor: string = "";
    COLOR_DATA_LABEL.map((color: updatedColor) => {
      if (color.isSet) {
        bgColor = color.colorCode;
      }
    });
    setSelectedColorCode(bgColor);
  }, []);
  useEffect(()=>{
    
    if (labelName.length<=0 || labelName.length>=50){
      setIsdisable(true);
  }else{
    setIsdisable(false);
  }
  },[labelName])
  const validateLabelName = (e: any) => {
    setLabelName(e.target.value);
    // e.target.value === "" ? isEmpty(true) : isEmpty(false);
  };
 
 

  // function to add a emoji on emoji
  const onEmojiSelect = (emoji: string): void => {
    // Do something with the emoji data, such as passing it to another function or component
    // console.log("Selected Emoji:", emoji);
    setLabelName((prevLabelName) => prevLabelName + emoji);
  };
  const createLabelOfSearch = (e: any) => {
    if(e.length >0 && e.length<50){
      setIsdisable(false);
    }
    showLabelInput(!labelInput);
    setLabelName(e);
  };
  const setSelectedColor = (index: number) => {
    let updatedColors: any = [];
    COLOR_DATA_LABEL.map((color: updatedColor, i) => {
      if (i === index) {
        color.isSet = true;
        setSelectedColorCode(color.colorCode);
        updatedColors.push(color);
      } else {
        color.isSet = false;
        updatedColors.push(color);
      }
    });
    setColors(updatedColors);
  };
  const saveLabel = () => {
    const req = {
      id: updateLabelObj.id,
      color: selectedColorCode || updateLabelObj.color,
      name: labelName || updateLabelObj.name,
      opening_id: props.currentOpening.id,
      socket_client_id: sessionStorage.getItem(SOCKET_CLIENT_ID),
    };
    setLoading(true);
    editLabel(req).then((applicant_label: any) => {
      props.updateApplicantLabel(applicant_label);
      showLabelInput(false);
      setLabelName("");
      setLoading(false);
      setUpdateFlag(false);
    }).catch(() => { setLoading(false)});
  };
  const updateLabel = (label: any) => {
    setIsdisable(false);
    let updatedColors: any = [];
    COLOR_DATA_LABEL.map((color: updatedColor) => {
      if (color.colorCode == label.color) {
        color.isSet = true;
        setSelectedColorCode(color.colorCode);
        updatedColors.push(color);
      } else {
        color.isSet = false;
        updatedColors.push(color);
      }
    });
    setUpdateLabelObj(label);
    showLabelInput(true);
    props.click;
    setUpdateFlag(true);
    setColors(updatedColors);
    setLabelName(label.name);
    setSelectedColorCode(label.color);
  };
  const createLabel = () => {
    showLabelInput(!labelInput);
    const label: labelReq = {
      name: labelName,
      color: selectedColorCode,
      opening_id: props.currentOpening.id,
      socket_client_id: sessionStorage.getItem(SOCKET_CLIENT_ID),
    };
    if (labelName.length<= 0 || labelName.length >= 50) {
      islimitexceed = true;
      showLabelInput(true);
    } else {
      setLoading(true);
      createOpeningLabel(label).then((label: Label) => {
        setLabelName("");
        setLoading(false);
        props.saveOpeningLabel(label, props.currentOpening.id);
      }).catch(() => { setLoading(false)});
    }
  };
  return (
    <React.Fragment>
      {labelInput ? (
        <>
          <div className="my-2 mx-3">
            <TacitbaseInput
              type="text "
              placeholder="Enter label name"
              value={labelName}
              onChange={validateLabelName}
              suffix={<Emoji onEmojiSelect={onEmojiSelect} usePortal={true} />}
              validator={(value) => {
                if (value.length >= 50 || islimitexceed) {
                    return 'Maximum 50 characters allowed';
                }
            }}
  
            />
          </div>
          <div className="mx-3">
            <div className="color-preview-panel">
              <div className="flex flex-row ml-2 flex-wrap  h-90 text-white ">
                {defaultColors.map((label: updatedColor, idx) => (
                  <div className="mr-1" key={idx}>
                    <ColorSelection
                      colorName={`${label.colorCode}`}
                      isSet={label.isSet}
                      click={() => setSelectedColor(idx)}
                      showMore={label.showMore}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="mx-3 ">
            <Button
            type="primary"
              data-testid="createLabelTestBtn"
              className="submit-btn text-sm"
              onClick={updateFlag ? saveLabel : createLabel}
              disabled={isdisable}
              loading={loading}
            >
              <span className="text-xs">{updateFlag ? "Save" : "Create"}</span>
            </Button>
          </div>
        </>
      ) : (
        <LabelInput
          update={(label: any) => updateLabel(label)}
          click={(e) => createLabelOfSearch(e)}
          flag={true}
          close={props.close}
          usePortal={true}
        />
      )}
    </React.Fragment>
  );
};
const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
  currentApplicant: state.opening.currentApplicant,
  clientId: state.opening.clientId,
});
const mapPropsToState = (dispatch: Dispatch) => ({
  saveOpeningLabel: (label: any, openingId: string) =>
    dispatch(saveBoardLabel(label, openingId)),
  updateApplicantLabel: (updatedLabel: any) =>
    dispatch(updateLabelState(updatedLabel)),
});
export default connect(mapStateToProps, mapPropsToState)(BoardLabelInput);
