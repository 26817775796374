import { openingMemberType, PermissionAccess } from "../../../type/type";
type getLoginUserCDBAccessType = {
    serviceMembers: openingMemberType[],
    loginUserId:string
}
export const getServiceAccessOfLoginUser = ({ serviceMembers, loginUserId }: getLoginUserCDBAccessType): PermissionAccess => {
    let accessForCDB: PermissionAccess = {
      admin: false,
      comment_only: false,
      is_secrete_visible: false,
    };  
    for (const member of serviceMembers) {
      if (member.id === loginUserId) {
        accessForCDB = member.access;
        break;
      }
    }  
    return accessForCDB;
  };
  