import React from "react"
import { connect } from "react-redux"
import BoardInput from "./boardInput/BoardInput"
// import StarredBoard from "./starredBoard/StarredBoard";
import BoardMember from "./memberProfile/BoardMember"
import AddBoardMember from "./addBoardMember/AddBoardMember"
import { companyBoards, notification, Opening, OrganizedBoard } from "../../type/type.d"
import ShowBoardMembers from "./addBoardMember/ShowBoardMembers"
import ShowBoardStatus from "./boardStatus/ShowBoardStatus"
// import Read from "../../Read"
import { Divider, Popconfirm, Space, Tag, Typography } from "antd"
const { Text } = Typography
import { Dispatch } from "redux"
import { initCurrentBoard, updateOpeningStatus } from "../../redux/actions/opening/action"
import NavigationJobPosts from "./NavigationJobPosts"
import { useHistory } from "react-router-dom"
import NavSearch from "../navSearch/NavSearch"
import Navbar from "../navbar/Navbar"
import { colors, DIVIDER_HEIGHT } from "../../constant"
import { archiveBoard } from "../../lib/api"
import Read from "../../Read"

type SubNavbarProps = {
  currentOpening: Opening,
  notificationReduxState: notification,
  companyBoard: companyBoards[],
  click: () => void,
  sliderState: boolean,
  showPopup?: () => void,
  roles: string[],
  showCal: () => void,
  toggleValue: boolean,
  openings: OrganizedBoard,
  saveCurrentBoard(board: Opening): void,
  applicationId: string,
  updateOpening: (opening: Opening) => void;
  currentJD:any;
}
const SubNavbar: React.FC<SubNavbarProps> = (props: SubNavbarProps) => {
  const history: any = useHistory();

  // const [watchStatus, setWatchStatus] = useState(false)
  // const params: { board_id: string, org_id: string } = useParams()

  // useEffect(() => {
  //   if (props.notificationReduxState.currentBoardWatcher) {
  //     if (
  //       props.notificationReduxState.currentBoardWatcher.entity_id ==
  //       params.board_id
  //     ) {
  //       setWatchStatus(true)
  //     } else {
  //       setWatchStatus(false)
  //     }
  //   }
  // }, [props.notificationReduxState.currentBoardWatcher])
  const handleArchiveBoard = () => {
    if (props.currentOpening.id) {
      return new Promise<void>((resolve, reject) => {
        archiveBoard(props.currentOpening.id, !props.currentOpening.archive,props.currentJD.id).then(() => {
          let updatedOpening = { ...props.currentOpening, archive: !props.currentOpening.archive }
          props.updateOpening(updatedOpening)
          resolve()
        }).catch((err) => {
          console.log("error while archiving board", err)
          reject(err)
        })
      })
    }
  }


  const backToAts = () => {
    history.push({
      pathname: `/o/${props.applicationId}/ats`,
    });
  };

  return (
    <React.Fragment>
      <div className="board-main-content h-12 items-center flex justify-between">
        <div className="flex space-x-5 items-center justify-start">
          <Space>
            <div className="flex items-center">

              <Space>

                <Space>
                  <Text
                    strong
                    className="text-white cursor-pointer tracking-wider  text-base font-semibold"
                    ellipsis
                    onClick={backToAts}
                  >
                    {props.currentOpening.company_name}
                  </Text>
                </Space>

                {/* <Divider type="vertical" style={{ height: "1em", borderColor: "white" }} */}
                {/* /> */}

                <div style={{ color: 'white' }} className="flex" >{"/"}</div>

                <BoardInput boardName={props.currentOpening.name} />
              </Space>
              <Read>
                <NavigationJobPosts />
              </Read>
            </div>

          </Space>

          <ShowBoardStatus />
          {props.currentOpening.archive &&
            <Tag color="gold"><Text type="warning">The job post archived.</Text>
              <Popconfirm
                title="Are you sure you want to unarchive this job post?"
                onConfirm={handleArchiveBoard}
                okText="Yes"
                cancelText="No"
              >
                <Text type="warning" underline style={{ marginLeft: 8, cursor: "pointer" }}>
                  Unarchive
                </Text>
              </Popconfirm>            </Tag>
          }

        </div>
        <div className="flex flex-row items-center justify-end space-x-2">
          <BoardMember />
          <ShowBoardMembers />
          <AddBoardMember />
          <Divider
            type="vertical"
            style={{
              height: DIVIDER_HEIGHT,
              borderColor: `${colors.divider}`,
            }}
          />
          <NavSearch color="white" />
          <Divider
            type="vertical"
            style={{
              height: DIVIDER_HEIGHT,
              borderColor: `${colors.divider}`,
            }}
          />
          <Navbar />

        </div>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
  roles: state.opening.roles,
  notificationReduxState: state.opening.notification,
  companyBoard: state.opening.companyBoards,
  openings: state.opening.openings, // Assuming openings is your Redux state containing boards
  applicationId: state.opening.applicationId,
  currentJD:state.opening.currentTrackerJd,

})
const mapPropsTostate = (dispatch: Dispatch) => ({
  saveCurrentBoard: (board: Opening) => dispatch(initCurrentBoard(board)),
  updateOpening: (board: Opening) => dispatch(updateOpeningStatus(board)),
})
export default connect(mapStateToProps, mapPropsTostate)(SubNavbar)
