import { Applicant } from "../../type/type";

export function save_card_label(state: any, action: any) {
  return {
    ...state,
    labels: {
      ...state.labels,
      [action.data.label_id]: {
        ...action.data,
        id: action.data.label_id,
      },
    },
    applicantLabels: {
      ...state.applicantLabels,
      [action.data.applicant_id]: state.applicantLabels[
        action.data.applicant_id
      ]
        ? state.applicantLabels[action.data.applicant_id].includes(
            action.data.label_id
          )
          ? state.applicantLabels[action.data.applicant_id]
          : [
              ...state.applicantLabels[action.data.applicant_id],
              action.data.label_id,
            ]
        : [action.data.label_id],
    },
    boardList: {
      ...state.boardList,
      [action.data.list_id]: {
        ...state.boardList[action.data.list_id],
        applicants: state.boardList[action.data.list_id].applicants.map(
          (applicant: Applicant) =>
            applicant.id === action.data.applicant_id
              ? {
                  ...applicant,
                  labels: applicant.labels
                    ? applicant.labels.includes(action.data.label_id)
                      ? applicant.labels
                      : [...applicant.labels, action.data.id]
                    : [action.data.id],
                }
              : { ...applicant }
        ),
      },
    },
  };
}
export function save_board_label_in_card(state: any, action: any) {
  return {
    ...state,
    boardList: {
      ...state.boardList,
      [action.listID]: {
        ...state.boardList[action.listID],
        applicants: state.boardList[action.listID].applicants.map(
          (applicant: any) =>
            applicant.id === action.applicantID
              ? {
                  ...applicant,
                  labels: applicant.labels
                    ? [...applicant.labels, action.data.id]
                    : [action.data.id],
                }
              : { ...applicant }
        ),
      },
    },
    applicantLabels: {
      ...state.applicantLabels,
      [action.applicantID]: state.applicantLabels[action.applicantID]
        ? [...state.applicantLabels[action.applicantID], action.data.id]
        : [action.data.id],
    },
  };
}

export function save_board_label(state: any, action: any) {
  return {
    ...state,
    labels: {
      ...state.labels,
      [action.data.id]: action.data,
    },
  };
}

export function init_labels(state: any, action: any) {
  return {
    ...state,
    applicantLabels: {
      ...state.applicantLabels,
      [action.id]: action.data,
    },
  };
}

export function update_label(state: any, action: any) {
  return {
    ...state,
    labels: {
      ...state.labels,
      [action.data.id]: {
        ...state.labels[action.data.id],
        name: action.data.name,
        color: action.data.color,
      },
    },
  };
}

export function collapse_label(state: any, action: any) {
  return {
    ...state,
    collapseLabel: action.data,
  };
}
export function init_board_labels(state: any, action: any) {
  var openingLabels = state.labels;
  action.data
    ? action.data.map((olabel: any) => {
        openingLabels[olabel.id] = olabel;
      })
    : state.labels;
  return {
    ...state,
    labels: openingLabels,
  };
}

export function remove_label_from_card(state: any, action: any) {
  return {
    ...state,
    applicantLabels: {
      ...state.applicantLabels,
      [action.applicantID]: state.applicantLabels[action.applicantID].filter(
        (label: string) => label !== action.labelID
      ),
    },
    boardList: {
      ...state.boardList,
      [action.listID]: {
        ...state.boardList[action.listID],
        applicants: state.boardList[action.listID].applicants.map(
          (applicant: any) =>
            applicant.id === action.applicantID
              ? {
                  ...applicant,
                  labels: applicant.labels.filter(
                    (label: string) => label !== action.labelID
                  ),
                }
              : { ...applicant }
        ),
      },
    },
  };
}
