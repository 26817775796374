import React, {  useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { updateApplicantAttachment } from "../../../lib/api";
import { updateAttachment } from "../../../redux/actions/opening/action";
import { EditOutlined } from "@ant-design/icons";
import { SOCKET_CLIENT_ID } from "../../../constant";
import { Button, Form, Input, Popover } from "antd";
import { useForm } from "antd/es/form/Form";
import { throwSuccess } from "../../../service/throwError";

type EditAttachmentType = {
  attachment: any;
  updateAttachmentName(attachment: any): void;
};

const EditAttachment = (props: EditAttachmentType) => {
  const [form] = useForm();
  const [open, setOpen] = useState(false);

  const handleEditName = () => {
    form.validateFields().then((values) => {
      let name = values.attachmentName.trim();
      const attachmentReq = {
        id: props.attachment.id,
        name: name,
        socket_client_id: sessionStorage.getItem(SOCKET_CLIENT_ID),
      };
      if (name.length > 0) {
        updateApplicantAttachment(attachmentReq).then((res) => {
          props.updateAttachmentName(res);
          setOpen(false);
          throwSuccess("Attachment name updated successfully");
        });
      } else {
        form.setFields([
          {
            name: "attachmentName",
            errors: ["Attachment name is required"],
          },
        ]);
      }
    });
  };
  const handlePopoverChange = (visible: boolean) => {
    setOpen(visible);
  };
  return (
    <>
      <Popover
        placement="bottom" 
        trigger={"click"}
        open={open}
        onOpenChange={handlePopoverChange}
        content={
          <div>
            <Form form={form} layout="vertical" className="mt-1">
              <Form.Item
                label="Attach file name"
                name="attachmentName"
                initialValue={props.attachment.name}
                shouldUpdate={false} 
              >
                <Input
                  className="w-full rounded"
                  placeholder="Enter label name"
                  
                />
              </Form.Item>
            </Form>
            <Button
              type="primary"
              className="mt-1 w-full"
              onClick={handleEditName}
            >
              Update
            </Button>
          </div>
        }
      >
        <Button
          size="small"
          type="text"
          icon={<EditOutlined />}
          onClick={() => setOpen(!open)}
        >
          Edit
        </Button>
      </Popover>
    </>
  );
};

const mapPropsToState = (dispatch: Dispatch) => ({
  updateAttachmentName: (user: any) => dispatch(updateAttachment(user)),
});

export default connect(null, mapPropsToState)(EditAttachment);
