import React from "react";

import { Attachment } from "../../type/type";
import { downloadAttachmentOfMail } from "../../lib/api"
import { DownloadOutlined, FilePdfFilled } from "@ant-design/icons";
import { Button, Space, Tag, Tooltip, Typography } from "antd";
const { Text } = Typography;

const AttachmentPreviewOfMail = ({
  attachment,
}: {
  attachment: Attachment;
}) => {
  
  const downloadFile = (event:any) => {
    event.stopPropagation() // Stop the click event from propagating
    event.preventDefault() // Stop the click event from propagating

    downloadAttachmentOfMail(attachment.objectKey).then((res: any) => {
      const fileUrl = res.link;
    const link = document.createElement("a");
      // Set 'target' to avoid opening in a new tab
      link.target = "_self";
    link.href = fileUrl;
    link.download = attachment.name;
   
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    })
  };
  return (
    <>
      <Space direction="vertical" className="w-full">
        <Tag
          className="cursor-pointer flex items-center border border-black border-opacity-10 p-2 h-6 bg-white"
          style={{ width: "100%" }}
          bordered={false}
          key={attachment.id}
        >
          <FilePdfFilled style={{ fontSize: "13px", color: "#FF0000" }} />
          <Text
            ellipsis={{ tooltip: attachment.name }}
            style={{ width: "100%", fontSize: "13px" }}
          >
            {attachment.name}
          </Text>
          {/* <DownloadFile attachment={props.attachment} size="13" /> */}
          <Tooltip title="Download">
            <Button type="text" size="small" icon={<DownloadOutlined  className="m-0 p-0"   style={{ fontSize:'13px'}}
 />} onClick={downloadFile} style={{width:'13px',height:'13px',marginLeft:'2px'}}/>
 </Tooltip>
        </Tag>
      </Space>
    </>
  );
};

export default AttachmentPreviewOfMail;
