
import React from 'react';

const Twitter = () => {
    return (
        <div>
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_6189_16507)">
<path d="M15.4202 11.0094L24.8911 0H22.6466L14.4233 9.55906L7.85505 0H0.279297L10.2117 14.4552L0.279297 26H2.52383L11.2082 15.9053L18.1445 26H25.7203L15.4196 11.0094H15.4202ZM12.3461 14.5823L11.3397 13.143L3.33247 1.68959H6.77991L13.2415 10.933L14.2478 12.3723L22.6476 24.3872H19.2006L12.3461 14.583V14.5823Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_6189_16507">
<rect width="26" height="26" fill="white"/>
</clipPath>
</defs>
</svg>
        </div>
    );
};

export default Twitter;

