// CheckEmail.js

import React from 'react'
import { Typography } from 'antd'
import Link from 'antd/es/typography/Link'
import { BACK_TO_LOGIN } from '../../constant'
import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter'
import { useHistory } from 'react-router-dom'

const { Paragraph, Text } = Typography
export const CHECK_EMAIL_HEADING = 'Check your email'
export const RESEND_EMAIL = 'Resend email'

export const EMAIL_SENT_MESSAGE = (email: string) => (
    <span>
        We have sent an email to <Text strong>{email}</Text>.
    </span>
)

const CheckEmail = ({ email }: any) => {
    const history = useHistory()

    const handleResendClick = () => {
        // Reset the success state before navigating to ResetPassword
        history.push(`/password/change`, { email, success: false })
    }

    return (
        <div className="flex flex-col md:space-y-4 space-y-2 relative w-full">
            <div className="w-full flex items-center justify-center font-semibold md:text-lg text-base">
                {capitalizeFirstLetter(CHECK_EMAIL_HEADING)}
            </div>
            <div className="text-gray-600">
                <Paragraph>{EMAIL_SENT_MESSAGE(email)}</Paragraph>
                <Paragraph>{"To continue, please check your inbox and follow the instruction provided."}</Paragraph>
                <Paragraph>
                    Did not receive the email? Check your spam folder.
                </Paragraph>
                <div className='flex justify-between'>
                    <Link href={`/login`}>{BACK_TO_LOGIN}</Link>
                    <Link onClick={handleResendClick}>{RESEND_EMAIL}</Link>
                </div>
            </div>
        </div>
    )
}

export default CheckEmail
