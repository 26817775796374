import React, { useEffect, useState } from "react";
import meet from "../../../../images/meet.png";
import Image from "../../../common/image/Image";
import zoom from "../../../../images/zoom.png";
import { getBoardCalenderSettingsOfCTools } from "../../../../lib/api";
import { connect } from "react-redux";
import CloseIcon from "../../../../assets/Close";
type conferenceOnCardProps = {
  setSelectedTool: (tool: string) => void;
  selected: string;
  calenderId: string;
  urlObj?: { tool: string; url: string };
};
const ConferenceOnCard = (props: conferenceOnCardProps) => {
  const [zoomMeetUser, setZoomMeetUser] = useState({
    zoom_email: "",
    meet_email: "",
  });

  useEffect(() => {props.calenderId?
    getBoardCalenderSettingsOfCTools(props.calenderId).then((res) => {
      setZoomMeetUser(res.calendar_conference_settings);
    }):null;
  }, []);

  return (
    <div>
      <div>
        <div className="flex flex-col space-y-1">
          {zoomMeetUser.meet_email ? (
            <button
              className="flex items-center justify-start space-x-2"
              onClick={() =>
                props.selected == "meet" ? null : props.setSelectedTool("meet")
              }
            >
              <div className="w-1/12">
                <Image src={meet} />
              </div>
              <div
                className={`bg-white ${
                  props.selected == "meet"
                    ? "border-l-4 border-tacitbase-secondary3"
                    : "border-tacitbase-tertiary1 border-opacity-25"
                } border  px-2 py-1 text-xs rounded w-11/12 flex  items-center justify-between `}
              >
                <div className="flex flex-col items-start justify-start">
                  {" "}
                  <div> {zoomMeetUser.meet_email} </div>{" "}
                  {props.urlObj?.tool == "meet" ? (
                    <div className=" text-blue-400 text-xs font-medium tracking-wider max-w-full underline cursor-pointer">
                      <a href={props.urlObj?.url}> {props.urlObj?.url}</a>
                    </div>
                  ) : null}
                </div>
                {props.selected == "meet" ? (
                  <div>
                    <button onClick={() => props.setSelectedTool("")}>
                      <CloseIcon size="9" />
                    </button>
                  </div>
                ) : null}
              </div>
            </button>
          ) : null}
          {zoomMeetUser.zoom_email ? (
            <button
              className="flex items-center justify-start space-x-2"
              onClick={() =>
                props.selected == "zoom" ? null : props.setSelectedTool("zoom")
              }
            >
              <div className="w-1/12">
                <Image src={zoom} />
              </div>
              <div
                className={`bg-white ${
                  props.selected == "zoom"
                    ? "border-l-4 border-tacitbase-secondary3"
                    : "border-tacitbase-tertiary1 border-opacity-25"
                } border  px-2 py-1 text-xs rounded w-11/12 flex items-center justify-between`}
              >
                <div className="flex flex-col items-start justify-start w-full">
                  {" "}
                  <div> {zoomMeetUser.zoom_email}</div>
                  {props.urlObj?.tool == "zoom" ? (
                    <a
                      href={props.urlObj.url}
                      className="truncate text-blue-400 text-xs font-medium tracking-wider max-w-full underline cursor-pointer"
                    >
                      {" "}
                      {props.urlObj.url}
                    </a>
                  ) : null}
                </div>
                {props.selected == "zoom" ? (
                  <div>
                    <button onClick={() => props.setSelectedTool("")}>
                      <CloseIcon size="9" />
                    </button>
                  </div>
                ) : null}
              </div>
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  loginUser: state.opening.loginUser,
  calenderId: state.opening.calendar.currentCalenderId,
});
export default connect(mapStateToProps)(ConferenceOnCard);
