import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { SELECT_DESTINATION, SOCKET_CLIENT_ID } from "../../../../constant";
import { getOpeningLists, getOpenings, moveList } from "../../../../lib/api";
import { moveListAction } from "../../../../redux/actions/opening/action";
import { MoveListProps } from "../../../../type/boardPanal";
import SelectPosition from "../../../cardActions/copyCard/SelectPosition";
import PopupTitle from "../../../common/popupTitle/PopupTitle";
import { JobBoard, Opening } from "../../../../type/type"
const MoveList = (props: MoveListProps) => {
  let listArr: any[] = [];
  const [list, setLists] = useState(listArr);
  const [openingID, setOpeningID] = useState(props.currentOpening.id);
  const [selectedPosition, setSelectedPosition] = useState("0");
  const [openings, setOpenings] = useState<Opening[]>([])
  const [companyOptions, setCompanyOptions] = useState<Record<string, JobBoard>>({})
  const [selectedCompany, setSelectedCompany] = useState(props.currentOpening.company_id);
  useEffect(() => {
    getOpeningLists(openingID)
      .then((lists: any) => {
        lists = lists ? lists : [];
        setLists(lists);
        let p = lists.findIndex((l: any) => l.id === props.listId).toString();
        let index = p !== "-1" ? p : "0";
        setSelectedPosition(index);
      })
     
  }, [openingID]);
  useEffect(() => {
    getOpenings()
      .then((openings: any) => {
        openings = openings ? openings : [];
        const companyOptionsObject: Record<string, JobBoard> = {}
        openings.forEach((company: JobBoard) => {
          companyOptionsObject[company.company_id] = company
        })

        setCompanyOptions(companyOptionsObject)
      })
     
      return () => {};
  }, []);
  useEffect(() => {
    if (selectedCompany) {
      // Access the selected company data directly from the object
      const selectedCompanyData = companyOptions[selectedCompany]

      if (selectedCompanyData) {
        // Extract the boards associated with the selected company
        const boards = selectedCompanyData.boards || []

        setOpenings(boards)
      }
    }
  }, [selectedCompany, companyOptions]);
  const move = () => {
    let previousListID = "00000000-0000-0000-0000-000000000000";
    if (openingID == props.currentOpening.id) {
      if (parseInt(selectedPosition) > parseInt("0")) {
        previousListID = list[parseInt(selectedPosition)].id;
      }
    }
    if (openingID !== props.currentOpening.id) {
      if (parseInt(selectedPosition) > parseInt("0")) {
        previousListID = list[parseInt(selectedPosition) - 1].id;
      }
    }
    const req = {
      src_list: {
        id: props.listId,
        opening_id: props.currentOpening.id,
        socket_client_id: sessionStorage.getItem(SOCKET_CLIENT_ID),
      },
      dst_list: {
        id: previousListID,
        opening_id: openingID,
      },
      socket_client_id: sessionStorage.getItem(SOCKET_CLIENT_ID),
      position: selectedPosition,
    };
    moveList(req).then((res: any) => {
      props.moveList(res, selectedPosition);
      props.close(false);
    });
  };
  return (
    <React.Fragment>
      <div className="my-2">
        <PopupTitle
          PopupTitle="Move List"
          back={props.prev}
          close={props.close}
        />
      </div>
      <div className="m-2">
        <span>{SELECT_DESTINATION}</span>
        <div className="bg-gray-200 mb-2 rounded hover:bg-gray-200 p-2 bg-opacity-60 w-full">
          <div className="text-xs">Company</div>
          <select
            className="appearance-none cursor-pointer outline-none w-full bg-gray-200 bg-opacity-20"
            onBlur={(e: any) => setSelectedCompany(e.target.value)}
            onChange={(e: any) => setSelectedCompany(e.target.value)}
            data-testid="selectBoard"
          >
            {Object.keys(companyOptions).map((companyId) => (
              <option key={companyId} value={companyId}
                selected={companyId === props.currentOpening.company_id}
              >
                {companyOptions[companyId].company_name}
              </option>
            ))}
          </select>
        </div>
        <div className="bg-gray-200 mb-2 rounded hover:bg-gray-200 p-2 bg-opacity-60 w-full">
          <div className="text-xs">Board</div>
          <select
            className="appearance-none cursor-pointer outline-none w-full bg-gray-200 bg-opacity-20"
            onBlur={(e: any) => setOpeningID(e.target.value)}
            onChange={(e: any) => setOpeningID(e.target.value)}
            data-testid="btn"
          >
            {openings.map((opening: any, index: number) => (
              <option
                key={index}
                className="w-full"
                value={opening.id}
                defaultValue={props.currentOpening.id}
                selected={opening.id === props.currentOpening.id}
              >
                {opening.id === props.currentOpening.id
                  ? opening.name + "(current)"
                  : opening.name}
              </option>
            ))}
          </select>
        </div>
        <div className="p-2 rounded bg-gray-200 bg-opacity-60 hover:bg-gray-200">
          <SelectPosition
            label="Position"
            value={list ? list : []}
            event={(e: any) => setSelectedPosition(e)}
            comparedId={props.listId}
            move={true}
            sourceId={openingID}
            extraIndexCheck={props.currentOpening.id}
          />
        </div>
        <button
          className="submit-btn text-sm active:ring-blue-800 focus:outline-none  active:ring-2   active:bg-gray-10 "
          onClick={move}
          type="submit"
          data-testid="moveTestBtn"
        >
          <span className="text-xs capitalize">Move</span>
        </button>
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (state: any) => ({
  openings: state.opening.openings,
  currentOpening: state.opening.currentOpening,
  clientId: state.opening.clientId,
  currentOpeningLists: state.opening.boardList,
});
const mapPropsToState = (dispatch: Dispatch) => ({
  moveList: (listID: string, selectedPosition: string) =>
    dispatch(moveListAction(listID, selectedPosition)),
});
export default connect(mapStateToProps, mapPropsToState)(MoveList);
