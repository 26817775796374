import React from "react";
import { capitalizeFirstLetter } from "../../../../utils/capitalizeFirstLetter";

type NameWithPositionProps = {
  card_name: string;
  position_name: string;
  position_color: string;
};
const NameWithPosition = (props: NameWithPositionProps) => {
  return (
    <div className="flex space-x-2 items-center ">
      <div className="tracking-normal text-md font-medium uppercase">
        {props.card_name ? props.card_name : ""}
      </div>
      <div className="tracking-normal text-sm ">Job Position</div>
      <div
        className={`text-xs rounded flex items-center justify-center  px-1 py-0.5 font-medium tracking-wider line-clamp-1 max-w-xl   text-tacitbase-secondary3   bg-opacity-20`}
      >
        {props.position_name ? capitalizeFirstLetter(props.position_name) : ""}
      </div>
    </div>
  );
};
export default NameWithPosition;
