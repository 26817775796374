import React from "react";
import { connect } from "react-redux";
import CloseIcon from "../../assets/Close";
import { GetOpening } from "../../type/type";
export type CommentInputActionButtonProps = {
  click: (e: any) => void;
  commentValue: string;
  CommentInput: (e: any) => void;
  currentOpening: GetOpening;
  comments: any;
  isExceed: boolean;
  clickedClose: (e: boolean) => void;
};
const CommentInputActionButton = (props: CommentInputActionButtonProps) => {
  const clickOnClose = () => {
    props.CommentInput({
      Input: false,
      style: "",
    });
    props.clickedClose(true);
  };
  return (
    <React.Fragment>
      <div className=" flex flex-row items-center justify-between">
        <div className="flex items-center  m-2 space-x-2 ">
          {props.commentValue ? (
            <button
              className="submit-btn text-sm"
              data-testid="btn"
              disabled={props.isExceed}
              onClick={(e: any) => props.click(e)}
              onKeyDown={(e: any) => props.click(e)}
            >
              <span className="text-xs">Save</span>
            </button>
          ) : (
            <button
              data-testid="btn1"
              className="submit-btn text-sm"
              disabled={true}
            >
              <span className="text-xs">Save</span>
            </button>
          )}
          <span
            className="cursor-pointer"
            aria-hidden="true"
            data-testid="closeIcon"
            onClick={clickOnClose}
          >
            <CloseIcon />
          </span>
        </div>

        {/* <div className=" m-2 cursor-pointer">
          <i className="fa fa-paperclip mr-4 " aria-hidden="true"></i>
          <i className="fa fa-smile-o m-2" aria-hidden="true"></i>
        </div> */}
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
});
export default connect(mapStateToProps)(CommentInputActionButton);
