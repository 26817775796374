import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { moveListAplicants } from "../../../lib/api";
import { moveAllApplicants } from "../../../redux/actions/opening/action";
import { MoveAllCardsProps } from "../../../type/boardPanal";
import ListPopupMenuItem from "../../listPopup/listPopoverMenuItem/ListPopupMenuItem";
import PopupTitle from "../../common/popupTitle/PopupTitle";
import { SOCKET_CLIENT_ID } from "../../../constant";

const MoveAllCards = (props: MoveAllCardsProps) => {
  const moveApplicants = (list: any) => {
    const req = {
      src_list: {
        id: props.listId,
      },
      dst_list: {
        id: list.id,
      },
      socket_client_id:sessionStorage.getItem(SOCKET_CLIENT_ID),
      opening_id: props.currentOpening.id,
    };
    moveListAplicants(req)
      .then((res: any) => {
        props.close(false);
        props.moveApplicants(res);
      })
      
  };
  return (
    <React.Fragment>
      <div className="my-2">
        <PopupTitle
          PopupTitle="Move List"
          back={props.prev}
          close={props.close}
        />
      </div>
      <div className="mt-1 cursor-pointer">
        {Object.keys(props.lists).map((list: any, i: number) =>
          props.listId != list ? (
            <ListPopupMenuItem
              key={i}
              move={() => moveApplicants(props.lists[list])}
              menuItemName={props.lists[list].name}
              click={props.close}
            />
          ) : null
        )}
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (state: any) => ({
  lists: state.opening.boardList,
  currentOpening: state.opening.currentOpening,
  clientId: state.opening.clientId,
});
const mapPropsToState = (dispatch: Dispatch) => ({
  moveApplicants: (payload: any) => dispatch(moveAllApplicants(payload)),
});
export default connect(mapStateToProps, mapPropsToState)(MoveAllCards);
