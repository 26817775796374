export const createFormData = (file: any, modelName: string) =>
{
    var formData = new FormData();
    formData.append("Content-Type", file.type);
    formData.append("model_name", modelName);
    // formData.append("record_id", recordId);
    return formData
}

export const onUploadProgress = (progressEvent: any) => {
    const progress = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );
    return progress
  };