import React from "react"
import { connect } from "react-redux"
import { CandidateDetailReview, CandidatesInReview, InitLabel, Member, orgMember } from "../../type/type"
import { LabeledIconButton } from "../../ant/Button"
import { addMemberOnReview, removeMemberOnReview } from "../../redux/actions/opening/action"
import { Dispatch } from "redux"
import MemberAddPopover from "../common/MemberPopupAnt"
import { AssignMemberToReviewReq } from "../../lib/apiReq"
import { assignMemberToReview, removeMemberFromCurrentReview } from "../../lib/api"
import { CheckOutlined } from "@ant-design/icons"
import getOrgMembers from "../../utils/getOrgMember"

const AddMemberToReview = (props: {
    applicationId: string,
    currentCandidateInReview: CandidateDetailReview,
    reviewLabels: InitLabel,
    candidatesInReview: CandidatesInReview,
    orgMembers: orgMember,
    addMemberOnReview(members: any, applicantID: string): void,
    removeMemberOnReview(memberID: any, applicantID: string): void

}) => {
    const determineIcon = (member: Member) => {
        const isMemberExist = props.currentCandidateInReview.members.map((member: string) => member).includes(member.id)
        if (isMemberExist) {
            // If the label count equals the total number of selected rows, return a checkmark icon
            return <CheckOutlined />
        } else {
            // Otherwise, return a mixed symbol
            return (
                null
            )
        }
    }


    const addMemberOnReview = (member: Member) => {
        let found: boolean = false
        if (props.currentCandidateInReview.members.length > 0) {
            found = props.currentCandidateInReview.members.includes(member.id)
            if (found) {
                let req: AssignMemberToReviewReq = {
                    review_id: props.currentCandidateInReview.id,
                    members: [member.id],
                    socket_client_id: "",
                }
                removeMemberFromCurrentReview(req)
                    .then((review_members) => {
                        props.removeMemberOnReview(
                            review_members.members,
                            review_members.id
                        )
                    })

            }
        }
        if (!found) {
            let req: AssignMemberToReviewReq = {
                review_id: props.currentCandidateInReview.id,
                members: [member.id],
                socket_client_id: "",
            }
            assignMemberToReview(req)
                .then((CDBlabel) => {
                    props.addMemberOnReview(CDBlabel.members, CDBlabel.id)
                })

        }
    }
    return (
        <div>
            <MemberAddPopover determineIcon={determineIcon} onAddMember={addMemberOnReview} element={<LabeledIconButton label="Add member" onClick={() => { }} />} defaultTitle="addmember" initialMembers={getOrgMembers()} />
        </div>
    )
}
const mapStateToProps = (state: any) => ({
    applicationId: state.opening.applicationId,
    currentCandidateInReview: state.opening.currentCandidateInReview,
    reviewLabels: state.opening.reviewLabels,
    candidatesInReview: state.opening.candidatesInReview,
    orgMembers: state.opening.members
})
const mapPropsToState = (dispatch: Dispatch) => ({
    addMemberOnReview: (members: any, reviewId: string) =>
        dispatch(addMemberOnReview(members, reviewId)),
    removeMemberOnReview: (memberID: any, applicantID: string) =>
        dispatch(removeMemberOnReview(memberID, applicantID)),

})
export default connect(mapStateToProps, mapPropsToState)(AddMemberToReview)
