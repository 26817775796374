import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Dispatch } from "redux";
import CommentIcon from "../../assets/Comments";
import DescriptionIcon from "../../assets/Description";
import { updateSearchFlag } from "../../redux/actions/opening/action";
import { SearchDocument, SearchType } from "../../type/type";
import ArrowDownIcon from "../../assets/ArrowDown";
type groupDataType = {
  [key: string]: any[];
};
type SearchedData = {
  getApplicants: SearchType;
  getOpenings: SearchType;
  searchedData: any;
  queryBy: string[];
  updateSearchFlag(): void;
  applicationId: string;
};
const SearchAfter = (props: SearchedData) => {
  const history = useHistory();
  const [groupedDataBySearchQuery, setGroupedDataBySearchQuery] =
    useState<groupDataType>({});
  const showCard = (opening_id: string, applicant_id: string) => {
    // This function changes window path
    history.push({
      pathname: `/o/${props.applicationId}/b/${opening_id}/tracker/${applicant_id}`,
    });
  };
  const showBoard = (opening_id: string) => {
    props.updateSearchFlag();
    // This function changes window path
    history.push({
      pathname: `/o/${props.applicationId}/b/${opening_id}/tracker`,
    });
  };

  useEffect(() => {
    /* This function returns the object of searchResult 

    in such a way that searchResult has data accoring to the query by parameters

    e.g localGroupedDataByQuery={name:[],labels:[]}
    */
    const localGroupedDataByQuery: { [key: string]: any[] } = {};
    props.getApplicants.forEach((obj) => {
      obj.highlights.forEach((highlight) => {
        const field = highlight.field;
        if (!localGroupedDataByQuery[field]) {
          localGroupedDataByQuery[field] = [];
        }
        localGroupedDataByQuery[field].push(obj);
      });
    });
    setGroupedDataBySearchQuery(localGroupedDataByQuery);
  }, [props.getApplicants]);
  const collapseDiv = (key: string) => {
    // This function collapses and expands the corresponding clicked div
    let elementToBeCollapsed = document.getElementById(key);
    let elementArrowElement = document.getElementById(key + "arrow");
    if (elementToBeCollapsed && elementArrowElement) {
      if (elementToBeCollapsed.style.display == "none") {
        elementToBeCollapsed.style.display = "flex";
        elementArrowElement.setAttribute("class", "");
      } else {
        elementToBeCollapsed.style.display = "none";
        elementArrowElement.setAttribute("class", "transform rotate-90");
      }
    } else {
      return;
    }
  };
  return (
    <>
      <div className="flex flex-row w-full divide-x divide-tacitbase-tertiary1 divide-opacity-30">
        <div className=" flex flex-col items-start  w-1/2 h-200  overflow-y-scroll ">
          <div className="flex flex-col py-4 pl-4 pr-3 w-full space-y-3  ">
            <div className="flex flex-col space-y-2 w-full">
              {Object.keys(groupedDataBySearchQuery).length > 0 &&
                Object.keys(groupedDataBySearchQuery).map((key: string) => (
                  <div key={key} className="flex flex-col space-y-2  w-full ">
                    <div
                      data-testid={key + "parentdiv"}
                      className="hover:bg-gray-300 p-2 flex items-center justify-between  bg-gray-200 capitalize  text-black   text-md    tracking-wide font-semibold w-full text-xs leading-4 tracking-normal font-medium  rounded capitalize    "
                      onClick={() => collapseDiv(key)}
                      onKeyDown={() => collapseDiv(key)}
                      role="button"
                      tabIndex={0}
                    >
                      <div className="overflow-hidden text-sm">
                        {key} {"(" + groupedDataBySearchQuery[key].length + ")"}
                      </div>
                      <div className="" id={key + "arrow"}>
                        <ArrowDownIcon />
                        <div></div>
                      </div>
                    </div>
                    <div
                      className="flex flex-col space-y-2 "
                      id={key}
                      data-testid={key}
                    >
                      {groupedDataBySearchQuery[key].map(
                        (searchResult: SearchDocument, index) => (
                          <div
                            className="w-full flex space-x-2 justify-between"
                            key={index}
                          >
                            <div
                              className="w-1/2 shadow-md border border-tacitbase-tertiary1 border-opacity-30  p-2 flex flex-col rounded space-y-2"
                              onClick={() =>
                                showCard(
                                  searchResult.document.opening_id,
                                  searchResult.document.id
                                )
                              }
                              role="button"
                              tabIndex={0}
                              onKeyDown={() =>
                                showCard(
                                  searchResult.document.opening_id,
                                  searchResult.document.id
                                )
                              }
                              data-testid={searchResult.document.id}
                            >
                              <div className="flex  leading-4 truncate   break-words text-xs  tracking-normal font-medium   rounded capitalize ">
                                {searchResult.document.name}
                              </div>
                              <div className="flex flex-row  space-x-3 text-gray-600  text-xs">
                                {searchResult.document.comments ? (
                                  <div className="flex items-center">
                                    <CommentIcon />

                                    <span className="mx-1">
                                      {searchResult.document.comments.length}
                                    </span>
                                  </div>
                                ) : null}
                                <div className="flex items-center">
                                  {searchResult.document.description ? (
                                    <DescriptionIcon />
                                  ) : null}
                                </div>
                              </div>
                            </div>{" "}
                            <div className="w-1/2 ">
                              <div
                                className="shadow-md truncate bg-gray-100  hover:bg-gray-200  break-words  text-xs leading-4 tracking-normal font-medium  p-1 rounded capitalize cursor-pointer"
                                onClick={() =>
                                  showBoard(searchResult.document.opening_id)
                                }
                                role="button"
                                tabIndex={0}
                                data-testid="testBtn"
                                onKeyDown={() =>
                                  showBoard(searchResult.document.opening_id)
                                }
                              >
                                {" "}
                                {searchResult.document.opening_name}
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                ))}{" "}
              {Object.keys(groupedDataBySearchQuery).length == 0 && (
                <div className="flex flex-col space-y-2  w-full ">
                  <div
                    className="hover:bg-gray-300 p-2 flex items-center justify-between  bg-gray-200 capitalize  text-black   text-md    tracking-wide font-semibold w-full text-xs leading-4 tracking-normal font-medium  rounded capitalize    "
                    role="button"
                    tabIndex={0}
                  >
                    <div className="overflow-hidden text-sm">Cards</div>
                  </div>
                  <div className="flex flex-col space-y-2 ">
                    <div className="w-full flex space-x-2 justify-between">
                      <div className="w-1/2    p-1 flex flex-col rounded space-y-2">
                        <div className="flex  leading-4 truncate   break-words text-xs  tracking-normal font-medium   rounded capitalize ">
                          No results found
                        </div>
                      </div>{" "}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-col items-start  w-1/2 h-200 overflow-y-scroll">
          <div className="flex flex-col py-4 pl-4 pr-3 w-full space-y-2  ">
            <div className="hover:bg-gray-300 p-2 flex items-center justify-between  bg-gray-200 capitalize  text-black   text-md    tracking-wide font-semibold w-full text-xs leading-4 tracking-normal font-medium  rounded capitalize    ">
              <div className="overflow-hidden text-sm">Job posts</div>
            </div>
            <div className="flex flex-col space-y-2 ">
              <div className="w-1/2   flex flex-col rounded space-y-2">
                <div className="flex flex-row  items-center w-full">
                  <div className="flex flex-col space-y-2 w-full">
                    {props.getOpenings.length > 0 ? (
                      props.getOpenings.map((opening: any, i: number) => (
                        <div
                          className="shadow-md truncate bg-gray-100  hover:bg-gray-200  break-words  text-xs leading-4 tracking-normal font-medium  p-1 rounded capitalize cursor-pointer"
                          key={i}
                          onClick={() => showBoard(opening.document.id)}
                          role="button"
                          tabIndex={0}
                          data-testid={opening.document.id}
                          onKeyDown={() => showBoard(opening.document.id)}
                        >
                          {opening.document.name}
                        </div>
                      ))
                    ) : (
                      <div className=" truncate    text-xs leading-4 tracking-normal font-medium  p-1 rounded capitalize cursor-pointer">
                        No results found
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="w-1/2 "></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapPropsToState = (dispatch: Dispatch) => ({
  updateSearchFlag: () => dispatch(updateSearchFlag()),
});

const mapStateToProps = (state: any) => ({
  applicationId: state.opening.applicationId,
});

export default connect(mapStateToProps, mapPropsToState)(SearchAfter);
