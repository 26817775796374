import React, { useEffect } from "react";
import PoolTable from "./PoolTable";
import { getLabels } from "../../lib/api";
import { CANDIDATES } from "../../constant";
import { Dispatch } from "redux";
import { initCandidateLabel } from "../../redux/actions/opening/action";
import { connect } from "react-redux";
import { Jd, Label } from "../../type/type"

type poolProps = {
  currentTrackerJd: Jd,
  initCandidateLabels: (labels: Label[]) => void;


};

const Pool = (props:poolProps) => {  
  useEffect(()=>{
    getLabels(CANDIDATES).then((res) => {
      props.initCandidateLabels(res);
    })

  },[])  
  return (
    <div className="   h-full flex flex-col">
      <PoolTable />
    </div>
  );
};


const mapPropsToState = (dispatch: Dispatch) => ({
  initCandidateLabels: (labels: Label[]) =>
    dispatch(initCandidateLabel(labels)),
 

  
});
const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
  candidatePool: state.opening.candidatePool,
  currentTrackerJd: state.opening.currentTrackerJd,

  applicationId: state.opening.applicationId,
});
export default connect(mapStateToProps,mapPropsToState)(Pool);

