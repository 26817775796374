import React from "react";
import { PAGE_NOT_FOUND, PAGE_NOT_FOUND_TITLE } from "../../constant";
import ProductName from "../product/ProductName";
import BottomBubble from "./BottomBubble";
import TopBubble from "./TopBubble";
const PageNotFound = () => {
  document.title = `${PAGE_NOT_FOUND_TITLE}`;
  return (
    <>
      {" "}
      <div className="flex  flex-col justify-center items-center h-screen">
        <TopBubble />
        <div className="flex flex-col justify-center items-center space-x-4 ">
          {" "}
          <ProductName /> <h1 className="text-9xl tracking-widest h-36">404</h1>
          <h1 className="tracking-widest">O-o-oh!</h1>{" "}
          <h1 className="tracking-wider">{PAGE_NOT_FOUND}</h1>
        </div>{" "}
        <BottomBubble />
      </div>{" "}
    </>
  );
};
export default PageNotFound;
