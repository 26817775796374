import React from "react";
import Input from "../../../billing/inputBox/Input";
type locationProps = {
  setLocation: (location: any) => void;
  value?: string;
};
const AddLocation = (props: locationProps) => {
  return (
    <div className="flex flex-col space-y-2 w-7/12">
      <div className="input-label">Location</div>{" "}
      <div>
        <Input
          placeholder="Add Map Link"
          setInputValue={(e) =>
            props.setLocation((prev: any) => ({
              ...prev,
              location: e.target.value,
            }))
          }
          defaultValue={props.value}
        />{" "}
      </div>
    </div>
  );
};
export default AddLocation;
