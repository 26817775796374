import React from "react";
const DescriptionIcon = () => {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Description</title>
      <g
        id="Icons-/-16x16-/-Details"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M1.5,3 L14.5,3 C14.7761424,3 15,3.22385763 15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L1.5,4 C1.22385763,4 1,3.77614237 1,3.5 C1,3.22385763 1.22385763,3 1.5,3 Z M1.5,6 L14.5,6 C14.7761424,6 15,6.22385763 15,6.5 C15,6.77614237 14.7761424,7 14.5,7 L1.5,7 C1.22385763,7 1,6.77614237 1,6.5 C1,6.22385763 1.22385763,6 1.5,6 Z M1.5,9 L14.5,9 C14.7761424,9 15,9.22385763 15,9.5 C15,9.77614237 14.7761424,10 14.5,10 L1.5,10 C1.22385763,10 1,9.77614237 1,9.5 C1,9.22385763 1.22385763,9 1.5,9 Z M1.5,12 L11.7,12 C11.9761424,12 12.2,12.2238576 12.2,12.5 C12.2,12.7761424 11.9761424,13 11.7,13 L1.5,13 C1.22385763,13 1,12.7761424 1,12.5 C1,12.2238576 1.22385763,12 1.5,12 Z"
          id="Combined-Shape"
          fill="#161E25"
        ></path>
      </g>
    </svg>
  );
};
export default DescriptionIcon;
