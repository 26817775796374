import React, { useEffect, useState } from "react";
import { Avatar, Breadcrumb, Modal, Table, Typography } from "antd";
import { loginUser, Opening, orgMember } from "../../type/type";
import { archiveBoard, getOpenings } from "../../lib/api";
import { LabeledIconButton } from "../../ant/Button";
import {  throwSuccess } from "../../service/throwError";
import { JOB_POST_UNARCHIVED_SUCCESS, OWNER, TrackerRoute } from "../../constant";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { addOpening } from "../../redux/actions/opening/action";

const { Link } = Typography;

export type ArchiveJobPostsModalProps = {
onSave: (values: any) => void;
  members: orgMember;
  open: boolean;
  applicationId: string;
  onCancel: () => void;
  saveNewOpening: (opening: any) => void;
  loginUser:loginUser;
}
const ArchiveJobPostsModal = (props: ArchiveJobPostsModalProps) => {
  const [archiveBoards, setArchiveBoards] = useState<Opening[]>([]);
  const [loadingMap, setLoadingMap]  = useState<{ [key: string]: boolean }>({});
  const history = useHistory() 
  const handleUnarchive = (id: string) => {
    if (id) {
      setLoadingMap((prev) => ({ ...prev, [id]: true }));
      archiveBoard(id, false)
        .then((res: any) => {
            props.saveNewOpening(res.data.opening)
            throwSuccess(JOB_POST_UNARCHIVED_SUCCESS);
            setLoadingMap((prev) => ({ ...prev, [id]: false }));
            // Filter out the unarchived board from the archiveBoards array
            const newArchiveBoards = archiveBoards.filter(
              (JobPost) => JobPost.id !== id
            );
            setArchiveBoards(newArchiveBoards);
        })
        .catch((err) => {
          setLoadingMap((prev) => ({ ...prev, [id]: false }));
          console.error("Error while unarchiving job post:", err);
        });
    }
  };

  useEffect(() => {
    getOpenings(true)
      .then((openings) => {
        if( props.members[props.loginUser.user.id].registrations[0].roles[0] ===OWNER){
          const allBoards = openings.flatMap((company: any) =>
          company.boards
        .map((board: any) => ({
            ...board,
            company_name: company.company_name,
          }))
        );

        setArchiveBoards(allBoards);
          }else{
            const allBoards = openings.flatMap((company: any) =>
            company.boards.filter((board:any) => board.member_id === props.loginUser.user.id) 
          .map((board: any) => ({
              ...board,
              company_name: company.company_name,
            }))
          );
  
          setArchiveBoards(allBoards);
          }
      })
      .catch((err) => {
        console.error("Error fetching openings:", err);
      });
  }, []);


  const columns: any = [
    {
      title: "Name",
      width: 300,
      fixed: "left",
      render: (_: any, record: Opening) => (
        <div
          role="button"
          tabIndex={0}
          className="flex space-x-2"
          onKeyDown={() => {}}
        >
          <div className="w-8 h-7 border flex items-center justify-center rounded">
            {record.background_image ? (
              <Avatar
                shape="square"
                src={record.color}
                size="small"
                className="w-7 h-6"
              />
            ) : (
              <div
                className={`w-7 h-6 ${
                  !record.background_image && `bg-color-${record.color}`
                } rounded`}
              />
            )}
          </div>
          <Link
            onClick={() => {
              history.push(
                `/o/${props.applicationId}/b/${record.id}/${TrackerRoute}`
              );
            }}
          >
            {record.name}
          </Link>
        </div>
      ),
    },
    {
      title: "Archived from",

      width: 400,

      fixed: "left",
      render: (_: any, record: Opening) => (
        <div role="button" tabIndex={0} onKeyDown={() => {}}>
          <Breadcrumb
            items={[
              {
                title: record.company_name,
              },
              {
                title: record.name,
              },
            ]}
          />
        </div>
      ),
    },

    {
      title: "Action",
      width: 100,

      render: (_: any, record: Opening) => (
        <>
          <LabeledIconButton
            loading={loadingMap[record.id] || false}
            label="Unarchive"
            className="h-7 w-28 flex items-center justify-center"
            onClick={() => handleUnarchive(record.id)}
          />
        </>
      ),
    },
  ];

  return (
    <>
      <Modal
        title="Archive"
        open={props.open}
        maskClosable={true}
        width={900}
        closable={false}
        onCancel={props.onCancel}
        footer={false}
      >
        <Table
          columns={columns}
          dataSource={archiveBoards}
          scroll={{ x: "max-content", y: "calc(100vh - 280px)" }}
          loading={false}
          rowKey="id"
          pagination={false}
          rowClassName="member-table"
          size="small"
        />
      </Modal>
    </>
  );
};
const mapPropsToState = (dispatch: Dispatch) => ({
  saveNewOpening: (opening: any) => dispatch(addOpening(opening)),
});

const mapStateToProps = (state: any) => ({
  applicationId: state.opening.applicationId,
  loginUser: state.opening.loginUser,
  members: state.opening.members,
});
export default connect(mapStateToProps,mapPropsToState)(ArchiveJobPostsModal);
