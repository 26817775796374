import React, { useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { CANDIDATES } from "./../../../constant";
import { updateCandidateAttachment } from "./../../../lib/api";
import { updateAttachmentOfCandidate } from "./../../../redux/actions/opening/action";
import EditAttachmentPopup from "./../../card/attachement/EditAttachmentPopup";
import { EditOutlined } from "@ant-design/icons";
import { Button, Popover } from "antd";

type EditAttachmentType = {
  attachment: any;
  updateAttachmentName(attachment: any): void;
};

const EditAttachment = (props: EditAttachmentType) => {
  const [open, setOpen] = useState(false);
  const [attachmentName, setUpdatedAttachmentName] = useState("");

  const showEditAttachmentPopup = (e: any, flag: boolean) => {
    e.stopPropagation();
    e.preventDefault();
    setOpen(flag);
  };

  const updateAttachment = (e: any) => {
    e.stopPropagation();
    const attachmentReq = {
      id: props.attachment.id,
      name: attachmentName,
      entity: CANDIDATES,
    };
    if ((e.keyCode === 13 && !e.shiftKey) || e.button === 0) {
      e.preventDefault();
      let name = attachmentName.trim();
      if (name.length > 0) {
        updateCandidateAttachment(attachmentReq).then((res) => {
          setOpen(false);
          props.updateAttachmentName(res);
        });
      }
    }
  };

  return (
    <div className="flex flex-row items-center justify-end">
      <Popover
        title="Edit Attachment"
        content={
          <EditAttachmentPopup
            click={(e: any, flag: boolean) => showEditAttachmentPopup(e, flag)}
            attachment={props.attachment}
            updateAttachmentClick={updateAttachment}
            updatedAttchmentName={(e: string) => setUpdatedAttachmentName(e)}
          />
        }
        trigger="click"
        open={open}
        onOpenChange={(visible) => setOpen(visible)}
        placement="bottom"
      >
        <Button
          size="small"
          type="text"
          icon={<EditOutlined />}
          onClick={(e: any) => {
            e.stopPropagation();
            setOpen(!open);
          }}
        >
          Edit
        </Button>
      </Popover>
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateAttachmentName: (user: any) =>
    dispatch(updateAttachmentOfCandidate(user)),
});

export default connect(null, mapDispatchToProps)(EditAttachment);
