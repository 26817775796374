import React, { useState, useEffect, useRef } from "react";
import GetComponent from "../../common/getComponent/GetComponent";
import ListPopupMenu from "../listPopupMenu/ListPopupMenu";
export type ListPopOverProps = {
  close: (e: boolean) => void;
  listID: string;
};
const ListPopOver = (props: ListPopOverProps) => {
  const refer = useRef<HTMLDivElement>(null);
  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);
  const handleClick = (e: any) => {
    if (refer.current && !refer.current.contains(e.target)) {
      props.close(false);
      return;
    }
  };
  const s: any[] = [];
  const [stackComponent, showStackComponent] = useState(
    <ListPopupMenu
      click={(name: string) => next(name)}
      close={(e: any) => props.close(e)}
      listId={props.listID}
    />
  );
  const [stack] = useState(s);
  const next = (component: any) => {
    let currentComponent = (
      <GetComponent
        prev={prev}
        param={component}
        next={next}
        close={(e: any) => props.close(e)}
        listId={props.listID}
      />
    );
    stack.push(currentComponent);
    showStackComponent(currentComponent);
  };
  useEffect(() => {
    stack.push(stackComponent);
  }, []);

  const prev = () => {
    stack.pop();
    const newC: any = stack[stack.length - 1];
    showStackComponent(newC);
  };

  return (
    <div
      ref={refer}
      className="search-panel border border-gray-300 z-50 text-gray-800 tracking-normal  text-sm font-normal rounded"
    >
      <div> {stackComponent ? stackComponent : null}</div>
    </div>
  );
};
export default ListPopOver;
