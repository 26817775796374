import React from "react"
type PaletteType = {
    isImage: boolean,
    backgroundItem: string,
    click: (backgroundItem: string, downloadLocation?: string) => void,
    name?: string,
    backgroundImagePreview?: string,
    link?: string,
    downloadLink?: string
} 

const Palette = (props: PaletteType) => {
    const [showName, setShowName] = React.useState(false)
    const handleMouseEnter = () => {
        setShowName(true)
    }
    const handleMouseLeave = () => {
        setShowName(false)
    }
    return (
        <>
            <div
                className="w-1/2 hover:opacity-70 relative p-1"
                role="button"
                tabIndex={0}
                data-testid="testDiv"
                onKeyDown={() => { }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={() => { props.click(props.backgroundItem, props.downloadLink && props.downloadLink) }}
            >
                <div
                    className={`h-24  ${!props.isImage && `bg-color-${props.backgroundItem}`} rounded w-full bg-cover bg-center bg-no-repeat`}
                    style={props.isImage && props.backgroundImagePreview ? { backgroundImage: `url(${props.backgroundImagePreview})` } : {}}
                >
                    {showName && props.isImage && <div className="absolute px-1 truncate bottom-1 underline  bg-black bg-opacity-60 text-white text-xs"><a href={props.link}>{props.name}</a></div>}
                </div>
            </div>
        </>
    )
}

export default Palette
