import React, { useRef, useState } from "react";
import { usePopper } from "react-popper";
import ArrowDownIcon from "../../../../assets/ArrowDown";
import { Scope } from "../../../../type/type.d";
import { capitalizeFirstLetter } from "../../../../utils/capitalizeFirstLetter";
import Image from "../../../common/image/Image";

import { Overlay } from "../../../hook/Overlay";
type DropdownWithDescProp = {
  selectedRole: string;
  selectedItem: (item: string) => void;
  options: any;
  width?: string;
  toolMenuState: number;
};
const CToolsDropdown = (props: DropdownWithDescProp) => {
  const [isRoleOpen, openRolePopup] = useState(false);
let popupRef =useRef(null)
  const [referenceElement, setReferenceElement] = useState<
    HTMLButtonElement | HTMLDivElement | null
  >(null);
  let [popperElement, setPoperElement] = useState<HTMLDivElement | null>();
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start",
    modifiers: [
      {
        name: "flip",
        options: {
          fallbackPlacements: ["top", "top-start"],
        },
      },
    ],
  });
  const handleDropdown = (column: string) => {
    openRolePopup(!isRoleOpen);
    props.selectedItem(column);
  };
  return (
    <>
      {props.toolMenuState == 1 ? (
        <>
          <button
            ref={setReferenceElement}
            onClick={() => openRolePopup(!isRoleOpen)}
            className={`cursor-pointer flex justify-between text-xs items-center capitalize outline-none input-box rounded   relative`}
            data-testid="selectBoard"
          >
            <div>
              {capitalizeFirstLetter(
                props.selectedRole ? props.selectedRole : "Add conferencing"
              )}
            </div>
            <div className="">
              <ArrowDownIcon />{" "}
            </div>
          </button>
          {isRoleOpen ? (
            <Overlay
              onClose={() => openRolePopup(!isRoleOpen)}
              scope={Scope.ChildPopover} reference={popupRef}
            >
              <div
                ref={setPoperElement}
                style={styles.popper}
                {...attributes.popper}
                className="absolute  z-20 border p-1   mt-1 overflow-y-scroll max-h-80 origin-top-right rounded-default bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <div ref ={popupRef}>
                {Object.values(props.options).map(
                  (column: any, index: number) => (
                    <div key={index}>
                      {column.value ? (
                        <div
                          className="mb-1 px-1 py-1 hover:bg-gray-100 rounded-default"
                          onClick={() => handleDropdown(column.id)}
                          role="button"
                          tabIndex={-1}
                          onKeyDown={() => {}}
                        >
                          <div className="flex items-center justify-start space-x-2">
                            <div className="">
                              <Image src={column.image} />
                            </div>
                            <div className="bg-white border  px-2 py-1 text-xs rounded w-full ">
                              {" "}
                              {column.name}
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  )
                )}</div>
              </div>
            </Overlay>
          ) : null}
        </>
      ) : (
        <div className="input-label text-tacitbase-tertiary1 text-opacity-40">
          Please add conference tool to your calendar.
        </div>
      )}
    </>
  );
};
export default CToolsDropdown;
