import React from "react";
import { useEffect } from "react";
// import { useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { archieveUnArchiveList, getArchivedLists } from "../../../lib/api";
import {
  initArchiveLists,
  sendBackArchivedList,
} from "../../../redux/actions/opening/action";
import { currentApplicant, Opening } from "../../../type/type";
import { SOCKET_CLIENT_ID } from "../../../constant";
type ArchiveCardsProps = {
  click(): void;
  switcher: any;
  currentOpening: Opening;
  clientId: any;
  archiveList: any;
  initArchivedLists(list: any, openingID: string): void;
  sendBackArchivedList(list: any): void;
};
const ArchiveCards = (props: ArchiveCardsProps) => {
  useEffect(() => {
    getArchivedLists(props.currentOpening.id).then((list: any) => {
      props.initArchivedLists(list, props.currentOpening.id);
    });
  }, []);

  const sendBackToBoard = (archivedItem: any) => {
    const req = {
      id: archivedItem.id,
      archive: false,
      socket_client_id: sessionStorage.getItem(SOCKET_CLIENT_ID),
    };
    archieveUnArchiveList(req).then((res: any) => {
      props.sendBackArchivedList(res);
    });
  };
  return (
    <React.Fragment>
      {props.archiveList[props.currentOpening.id]
        ? props.archiveList[props.currentOpening.id].map(
            (card: currentApplicant, index: number) => (
              <>
                <div className="flex m-1.5 overflow-y-scroll" key={index}>
                  <span className="flex-1 truncate w-full  px-2 py-1 text-sm rounded outline-none ">
                    {card.name}
                  </span>
                  <button
                    data-testid="testsendBackToBoardBTn"
                    onClick={() => sendBackToBoard(card)}
                    className=" text-black  px-2 w-32 py-1 rounded text-sm font-light float-right bg-gray-300 bg-opacity-25 hover:bg-gray-300 hover:bg-opacity-40  focus:outline-none"
                  >
                    Send to Tracker{" "}
                  </button>
                </div>
                <div className="flex">
                  <hr className="h-0.5 bg-gray-300 w-full m-1"></hr>
                </div>{" "}
              </>
            )
          )
        : null}
    </React.Fragment>
  );
};
const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
  archiveList: state.opening.archiveList,
  clientId: state.opening.clientId,
});
const mapPropsToState = (dispatch: Dispatch) => ({
  initArchivedLists: (archiveList: any, openingID: string) =>
    dispatch(initArchiveLists(archiveList, openingID)),
  sendBackArchivedList: (archiveList: any) =>
    dispatch(sendBackArchivedList(archiveList)),
});
export default connect(mapStateToProps, mapPropsToState)(ArchiveCards);
