import React from "react";
import { connect } from "react-redux";
import { initLabels } from "../../../redux/actions/opening/action";
import { Dispatch } from "redux";
import { collapseLabel } from "../../../redux/actions/opening/action";
import { InitLabel, KeyWithArray } from "../../../type/type";
import Label from "./Label";
type CardPreviewLabelProps = {
  applicantID: string;
  initLabels(labels: any, id: string): void;
  labels: InitLabel;
  applicantLabels: KeyWithArray;
  flag: boolean;
  collapsingLabel: (flag: boolean) => void;
  boardList: any;
};
const CardPreviewLabel = (props: CardPreviewLabelProps) => {
  if (!props.applicantLabels[props.applicantID]) return null;
  return (
    <React.Fragment>
      <div className="flex flex-wrap text-white max-w-full">
        {props.applicantLabels[props.applicantID].map(
          (labelID: string) =>
            props.labels[labelID] && (
              <Label
                key={labelID}
                color={props.labels[labelID].color}
                name={props.labels[labelID].name}
                flag={props.flag}
                click={() => {}}
              />
            )
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  labels: state.opening.labels,
  applicantLabels: state.opening.applicantLabels,
  boardList: state.opening.boardList,
});
const mapPropsToState = (dispatch: Dispatch) => ({
  initLabels: (labels: any, id: string) => dispatch(initLabels(labels, id)),
  collapsingLabel: (flag: boolean) => dispatch(collapseLabel(flag)),
});
export default connect(mapStateToProps, mapPropsToState)(CardPreviewLabel);
