import React from "react";
import { connect } from "react-redux";
import { initApplicantMembers, initMembership } from "../../../redux/actions/opening/action"
import { Dispatch } from "redux";
import TacitbaseAvtar from "../../../ant/Avtar"
import { CardPreviewMemberProps } from "../../../type/boardPanal";
import { Avatar, Divider } from "antd";

const CardPreviewMember = (props: CardPreviewMemberProps) => {
  if (!props.applicantMembers[props.applicant.id]) return null

  const creatorId = props.applicant.creator
  const creator = props.members[creatorId]

  const otherMembers = props.applicantMembers[props.applicant.id].filter(
    (member: any) => props.members[member] && member !== creatorId
  );

  return (
    <div className="flex items-center mt-2">
      {creator && (
        creator.image_url && (
          <Avatar.Group size={"small"} maxCount={4}>
            <TacitbaseAvtar
              src={creator.image_url}
              key={creator.id}
              size="small"
              toolTip={`${creator.first_name} ${creator.last_name} (Creator)`}
            />
          </Avatar.Group>
        )
      )}
      {otherMembers.length > 0 && creator && <Divider type="vertical" style={{ borderColor: "gray" }} />}
      <Avatar.Group maxCount={4} size={"small"}>
        {otherMembers.map((member: any) => (
          props.members[member].image_url ? (
            <TacitbaseAvtar
              src={props.members[member].image_url}
              key={props.members[member].id}
              size="small"
              toolTip={`${props.members[member].first_name} ${props.members[member].last_name}`}
            />
          ) :
            <TacitbaseAvtar
              content={props.members[member].first_name[0] + props.members[member].last_name[0]}
              key={props.members[member].id}
              size="small"
              toolTip={`${props.members[member].first_name} ${props.members[member].last_name}`}
            />

        ))}</Avatar.Group>

    </div>
  );
};

const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
  members: state.opening.members,
  applicantMembers: state.opening.applicantMembers,
  applicationId: state.opening.applicationId,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  initMembers: (members: any) => dispatch(initMembership(members)),
  initApplicantMembers: (members: any, applicantId: string) =>
    dispatch(initApplicantMembers(members, applicantId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CardPreviewMember)
