import React, { useState } from "react";
import {  LabeledIconButton} from "../../ant/Button";
import CompanyCreation from "./CompanyCreation";
import { createCompany } from "../../lib/api"
import { addCompanies } from "../../redux/actions/opening/action"
import { Dispatch } from "redux"
import { company, loginUser } from "../../type/type"
import { connect } from "react-redux"
import { throwSuccess } from "../../service/throwError";
export type AddCompanyPopupProps = {
  initCompanies: (candidate: any) => void,
  loginUser:loginUser
}
const CreateCompanyPreview = (props: AddCompanyPopupProps
) => {
  const [showModal, setShowModal] = useState(false);
  const ok = (values: any) => {
    console.log(values);
    
    const req1 = {
      ...values,
      members: values.members?[...values.members,props.loginUser.user.id]:[props.loginUser.user.id]
    }
    createCompany(req1).then((res) => {
      props.initCompanies(res)
      setShowModal(false)
      throwSuccess("Workspace created successfully.")
    })

  }
  return (
    <>
      <LabeledIconButton
        onClick={() => setShowModal(true)}
        label="Create a workspace"
        type="text" />
        <CompanyCreation
          onSave={ok}
          open={showModal}
          onCancel={() => setShowModal(false)}
      />
    </>
  );
};


const mapPropsToState = (dispatch: Dispatch) => ({
  initCompanies: (companies: company[]) =>
    dispatch(addCompanies(companies)),
})
const mapStateToProps = (state: any) => ({
  members: state.opening.members,
  loginUser: state.opening.loginUser,
});
export default connect(
  mapStateToProps,
  mapPropsToState
)(CreateCompanyPreview)

