import React, { useEffect, useState } from "react"
import {
  Button,
  Typography,
  Divider,
  Tag,
  Form,
  Pagination,
  Skeleton,
  Tooltip,
} from "antd"
import TextArea from "antd/lib/input/TextArea"
import FormItem from "../../ant/FormItem"
import { generateJDUsingGpt } from "../../lib/api"
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter"
import parse from "html-react-parser"
import { LabeledIconButton } from "../../ant/Button"
import { LeftOutlined, RedoOutlined, RightOutlined } from "@ant-design/icons"
import TacitBaseModal from "../../ant/TacitbaseModal"

const { Text } = Typography

type AIModalProps = {
  visible: boolean,
  title: string,
  //   content: string;
  //   onRegenerate: () => void;
  //   onEdit: () => void;
  onOk: (aiContent: string) => void,
  onCancel: () => void
}
const tagsData = [
  "Make it inclusive",
  "Make it friendly",
  "Make it shorter",
  "Make it larger",
  "Make it empathetic",
  "Make it simpler",
]
const AIModal = ({
  visible,
  title,
  //   content,
  //   onRegenerate,
  //   onEdit,
  onOk,
  onCancel,
}: AIModalProps) => {
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [regenerationCount, setRegenerationCount] = useState(0)
  const [aidata, setAidata] = useState({
    Roleandresponsibilities: "",
    aiInterviewProcess: "",
    AdditionalInfo: "",
  })
  const [form] = Form.useForm()
  const [modalContents, setModalContents] = useState<string[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const [selectedTags, setSelectedTags] = useState<string[]>([])
  const [regenerateDisabled, setRegenerateDisabled] = useState(false)
  const [threadId, setThreadId] = useState<string>(
    "00000000-0000-0000-0000-000000000000"
  )
  useEffect(() => {
    if (!visible) {
      form.setFieldsValue({
        Roleandresponsibilities: "",
        aiInterviewProcess: "",
        AdditionalInfo: "",
      })
      setSelectedTags([])
      setRegenerationCount(0)
      setRegenerateDisabled(false)
      setModalContents([])
      setCurrentPage(0)
      setThreadId("00000000-0000-0000-0000-000000000000")
    }
  }, [visible])

  const handleTagChange = (tag: string, checked: boolean) => {
    const nextSelectedTags = checked
      ? [...selectedTags, tag]
      : selectedTags.filter((t) => t !== tag)
    setSelectedTags(nextSelectedTags)
  }
  const handleFieldChange = (value: { [key: string]: string }) => {
    console.log("value")
    form.setFieldsValue(value)
    setAidata({ ...aidata, ...value })
  }

  const handleEditResponse = () => {
    setRegenerationCount(0)
    setModalContents([])
    setCurrentPage(0)
    setThreadId("00000000-0000-0000-0000-000000000000")
  }
  const handleRegenerate = () => {
    form.validateFields().then(() => {
      setLoading(true)
      const JDReq: any = {
        ip_address: "192.158.1.38",
        service: "jd",
        purpose: "jd",
        threadId,
      }

      if (regenerationCount === 0) {
        JDReq.user_inputs = {
          job_title: title,
          roles_and_resp: aidata.Roleandresponsibilities,
          interview_process: aidata.aiInterviewProcess,
          additional_info: aidata.AdditionalInfo,
        }
      }

      generateJDUsingGpt(JDReq)
        .then((res: any) => {
          const newContent = res.response.message
          setLoading(false)
          setCurrentPage((prevCount) => prevCount + 1)
          setThreadId(res.response.thread_id) // Store the threadId for subsequent regenerations
          setModalContents([...modalContents, newContent])
          setRegenerationCount((prevCount) => prevCount + 1)
          if (regenerationCount === 2) {
            setRegenerateDisabled(true)
          }
        })
        .catch((error) => {
          console.log(error)
          setLoading(false)
        })
    })
  }
  const handlePageChange = (page: number) => {
    if (page <= regenerationCount) {
      setCurrentPage(page)
    }
  }
  const handleCancel = () => {
    form.resetFields()
    // setAidata({
    //   Roleandresponsibilities: "",
    //   aiInterviewProcess: "",
    //   AdditionalInfo: "",
    // });
    setSelectedTags([]) // Reset selected tags
    setRegenerationCount(0) // Reset regeneration count
    setRegenerateDisabled(false) // Reset regenerate button state
    setModalContents([]) // Clear modal contents
    setCurrentPage(0) // Reset current page
    setThreadId("00000000-0000-0000-0000-000000000000")
    onCancel()
  }

  const itemRender = (current: any, type: any) => {
    if (type === "prev") {
      return (
        <LabeledIconButton
          icon={<LeftOutlined />}
          onClick={() => { }}
          label=""
          size="small"
          className="mr-2"
        />
      )
    }
    if (type === "next") {
      return (
        <LabeledIconButton
          icon={<RightOutlined />}
          onClick={() => { }}
          label=""
          size="small"
        />
      )
    }

    if (type === "page") {
      return null
    }
    return null
  }
  
  const handleOk = () => {
    onOk(modalContents[currentPage - 1])
    form.resetFields() // Reset form fields
    setAidata({
      Roleandresponsibilities: "",
      aiInterviewProcess: "",
      AdditionalInfo: "",
    }) // Reset aidata state
    setSelectedTags([]) // Reset selected tags
    setRegenerationCount(0) // Reset regeneration count
    setRegenerateDisabled(false) // Reset regenerate button state
    setModalContents([]) // Clear modal contents
    setCurrentPage(0) // Reset current page
    setThreadId("00000000-0000-0000-0000-000000000000")
  }
  return (
    <TacitBaseModal
      closeIcon={false}
      visibility={visible}
      style={{
        width: "750px",
        // height: "712px",
        position: "fixed",
        top: 50,
        bottom: 0,
        left: 0,
        right: 0,
        // display: "flex",
        // alignItems: "center",
        // justifyContent: "center",
        margin: "20x",
      }}
      title={<div className="px-4">{title}</div>}
      open={visible}
      onOk={handleOk}
      width={786}
      maskClosable={false}
      onCancel={handleCancel}
      footer={
        <div className="flex justify-between items-center ">
          <div className="flex items-center">

            {!loading &&
              <>                {regenerationCount > 1 && (
                <>
                  <Pagination
                    current={currentPage}
                    total={regenerationCount}
                    onChange={handlePageChange}
                    pageSize={1}
                    size="small"
                    showTotal={(total, range) => `${range[0]} of ${total}`}
                    itemRender={(current: any, type: any) =>
                      itemRender(current, type)
                    }
                  />
                  <Divider type="vertical" />
                </>
              )}

                {regenerationCount > 0 && (
                  <Tooltip title="Regenerate">
                    <LabeledIconButton
                      label=""
                      disabled={regenerateDisabled || loading}
                      onClick={handleRegenerate}
                      icon={<RedoOutlined />}
                      size="small"
                    />
                  </Tooltip>
                )}
              </>}
          </div>

          <div className="flex space-x-2 items-center">
            {regenerationCount == 0
              ? [
                <Button key="cancel" disabled={loading}
                  onClick={handleCancel}>
                  {capitalizeFirstLetter("cancel")}
                </Button>,
                <Button
                  key="regenerate"
                  disabled={loading}
                  htmlType="submit"
                  type="primary"
                  onClick={handleRegenerate}
                >
                  Generate
                </Button>,
              ]
              : [
                <Button key="cancel" disabled={loading}
                  onClick={handleCancel}>
                  {capitalizeFirstLetter("cancel")}
                </Button>,
                <Button
                  key="edit"
                  // type="primary"
                  disabled={loading}
                  onClick={handleEditResponse}
                >
                  {capitalizeFirstLetter("Edit response")}

                </Button>,
                <Button
                  key="ok"
                  type="primary"
                  disabled={loading}
                  onClick={handleOk}
                >
                  {capitalizeFirstLetter("insert")}

                </Button>,
              ]}
          </div>
        </div>
      }
      content={
        <>
          <Divider type="horizontal" className="my-3" />

          <div
            style={{
              // margin: 0,
              // height: modalContentHeight,
              // paddingRight: "2px",
              // position: "relative",
              // borderRadius: '12px',
              overflow: "scroll",
              // width: "calc(100vw - 150px )",
              // maxWidth: "calc(100vw - 150px )",
              height: "calc(100vh - 250px )",
              maxHeight: "calc(100vh - 250px )",
            }}
          >            {loading ? (
              <Skeleton active />
            ) : modalContents.length > 0 && modalContents[currentPage - 1] ? (
              <>{parse(modalContents[currentPage - 1])}</>
            ) : (
                  <div className="w-full px-4 overflow-y-scroll">
                <div>
                  <section>
                    <Text className="font-medium" style={{ fontSize: "14px" }}>
                      Review your job description information
                    </Text>
                  </section>
                </div>
                    <Text type="secondary" className="font-medium" style={{ fontSize: "14px" }}>
                    The information you provide will only be used for this job
                    description and will not impact future work.
                    {/* <sup>*</sup>  */}
                    </Text>

                    <Divider type="horizontal" className="my-2" />
                    <Form
                      form={form}
                      layout="vertical"
                      onValuesChange={handleFieldChange}
                      initialValues={aidata}
                    >
                      <FormItem
                        label="Role and responsibility"
                        name={"Roleandresponsibilities"}
                        rules={[
                          {
                            required: true,
                            message: capitalizeFirstLetter(
                              "Role and responsibility is required"
                            ),
                          },
                        ]}
                      >
                        <TextArea
                          showCount
                          //   onChange={(e) => getRoleandresponsibilities(e.target.value)}
                          maxLength={250}
                          autoSize={{ minRows: 4, maxRows: 8 }}
                          placeholder="Describe the key roles and responsibilities in 2-3 sentences for this position to attract the best candidates."
                        />
                      </FormItem>
                      <FormItem label="Interview process" name={"aiInterviewProcess"}>
                        <TextArea
                          showCount
                          maxLength={250}
                          placeholder="Outline the interview process for this position. Provide details
              on the stages."
                          // value={aidata.aiInterviewProcess}
                          autoSize={{ minRows: 4, maxRows: 8 }}
                        />
                      </FormItem>
                      <FormItem label="Additional information" name="AdditionalInfo">
                        <TextArea
                          showCount
                          maxLength={250}
                          placeholder="Start writing here..."
                          autoSize={{ minRows: 4, maxRows: 8 }}
                        />
                      </FormItem>

                      <section>
                        <div
                          className="font-medium  mt-4 mb-4"
                          style={{ fontSize: "14px" }}
                        >
                          Make it better
                        </div>

                    <div className="flex flex-wrap">
                      {tagsData.map<React.ReactNode>((tag) => (
                        <Tag.CheckableTag
                          key={tag}
                          className="bg-slate-100 rounded border-solid border-current"
                          checked={selectedTags.includes(tag)}
                          onChange={(checked) => handleTagChange(tag, checked)}
                        >
                          {tag}
                        </Tag.CheckableTag>
                      ))}
                    </div>
                  </section>
                </Form>
              </div>
            )}
          </div>

          <Divider style={{ margin: 0 }} />
        </>
      }
    />
  )
}

export default AIModal
