import { companyMember } from "../../constant"
import { Member, company, openingMemberType } from "../../type/type"

export function current_company(state: any, action: any) {
    action.company.members = action.company.members ? action.company.members : []
    return {
        ...state,
        currentEditableCompany: action.company,
    }
}
export function init_companies(state: any, action: { payload: company[] }) {
    var companyBoards: {
        [company_id: string]: {
            company_id: string,
            company_name: string,
            boards: string[]
        }
    } = {} // Initialize companyBoards as an empty object

    let workspace: { [key: string]: company } = {}

    action.payload.forEach((company: company) => {
        workspace[company.id] = company

        // Add or update the companyBoard in the companyBoards object
        companyBoards[company.id] = {
            company_id: company.id,
            company_name: company.name,
            boards: state.companyBoards ?
                state.companyBoards[company.id] ?
                    state.companyBoards[company.id].boards :
                    [] : []
        }
    });

    return {
        ...state,
        companyBoards: {
            ...state.companyBoards,
            ...companyBoards, // Assign the updated companyBoards object
        },
        companies: { ...workspace },
    }
}


export function add_companies(state: any, action: any) {
    let workspaceBoard = {
        company_id: action.payload.id,
        company_name: action.payload.name,
        boards: []
    };

    return {
        ...state,
        companyBoards: {
            ...state.companyBoards,
            [action.payload.id]: workspaceBoard, // Use company_id as the key
        },
        companies: {
            ...state.companies,
            [action.payload.id]: action.payload
        },
    }
}


export function add_member_on_company(state: any, action: any) {
    var companyMembers: openingMemberType[] = []
    action.data.map((member: Member) => {
        companyMembers.push({
            id: member.id,
            access: member.access,
        })
    })
    return {
        ...state,
        servicesMembership: {
            ...state.servicesMembership,
            [companyMember]: state.servicesMembership[companyMember]
                ? [...state.servicesMembership[companyMember], ...companyMembers]
                : companyMembers,
        },
    }
}
export function init_company_member(state: any, action: any) {
    var companyMembers: any[] = []
    action.data.map((member: Member) => {
        companyMembers.push({ id: member.id, access: member.access })
    })
    return {
        ...state,
        servicesMembership: {
            ...state.servicesMembership,
            [companyMember]: companyMembers,
        },
    }
}
export function remove_company_member(state: any, action: any) {
    return {
        ...state,
        servicesMembership: {
            ...state.servicesMembership,
            companyMember: state.servicesMembership.companyMember ? state.servicesMembership.companyMember.filter((m: any) => m.id != action.memberId) : []
        }
    }
}
export function add_assignee_to_company(state: any, action: any) {
    return {
        ...state,
        companies: {
            ...state.companies,
            [action.company_id]: {
                ...state.companies[action.company_id],
                members: state.companies[action.company_id] ? [...state.companies[action.company_id].members, ...action.members] : action.members

            }
        }
    }
}


export function remove_assignee_to_company(state: any, action: any) {
    return {
        ...state,
        companies: {
            ...state.companies,
            [action.company_id]: {
                ...state.companies[action.company_id],
                members: state.companies[action.company_id].members.filter(
                    (member: string) => member !== action.memberID
                ),
            }
        }

    }
}

export function edit_workspace_details(state: any, action: any) {
    return {
        ...state,
        currentEditableCompany: action.data,
        companies: {
            ...state.companies,
            [action.data.id]: action.data
        },
        companyBoards: {
            ...state.companyBoards,
            [action.data.id]: {
                ...state.companyBoards[action.data.id],
                company_name: action.data.name
            } // Use company_id as the key
        },
    }
}
