import React, { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';
import { Form, Input, Button, message } from 'antd';
import { getFormTemplate } from '../../../lib/api';

interface FormField {
  custom: boolean;
  isRequired: boolean;
  label: string;
  name: string;
  options: any; 
  order: number;
  secret: boolean;
  type: string;
  view: string;
}

export interface FormTemplate {
  id: string;
  title: string;
  schema: FormField[];
  creater_id: string;
  updated_at: string;
  created_at: string;
}

const JobDescriptionForm = () => {
  const [formData, setFormData] = useState<FormTemplate[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data:FormTemplate[] = await getFormTemplate();
        setFormData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

 

  const formTemplate = formData.length > 0 ? formData[0] : null;

  const handleSubmit = async () => {
    try {
      message.success('Form submitted successfully');
    } catch (error) {
      console.error('Error submitting form:', error);
      message.error('Failed to submit form. Please try again later.');
    }
  };

  const renderForm = () => {
    if (!formData || formData.length === 0) return null;

    return (
      <div className=" overflow-x-scroll space-x-2 h-full py-2 flex w-full ">
        {formTemplate && (
          <div>
            <h3>{formTemplate.title}</h3>
            <Form onFinish={handleSubmit}>
              {formTemplate.schema.map((field, index) => (
                <Form.Item
                  key={index}
                  label={field.label}
                  name={field.name}
                  rules={[{ required: field.isRequired, message: `Please input your ${field.label}` }]}
                >
                  <Input type={field.type} />
                </Form.Item>
              ))}
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
        )}
      </div>
    );
  };

  return <>{renderForm()}</>;
};

export default JobDescriptionForm;
