import React, { useEffect, useState } from "react";
import { Button, Form, Input } from "antd";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import { useHistory, useParams } from "react-router-dom";
import {
  checkForgetPasswordLinkExpiration,
  resetPassword,
} from "../../lib/api";
import ExpireLinkMsg from "./ExpireLinkMsg";
import { LOGIN_TOKEN } from "../../constant";
import TB_ORANGE_LOGO from "../../images/TB full orange.png"


const pstyle={marginBottom: '10px' }


const ResetPwd: React.FC = () => {
  const [isLinkExpire, setLinkExpireFlag] = useState(false);
  const [form] = Form.useForm();
  const params: { changePasswordId: string } = useParams();
  const history = useHistory();

  useEffect(() => {
    checkForgetPasswordLinkExpiration(params.changePasswordId)
      .then((res: boolean) => {
        setLinkExpireFlag(res);
      })
      .catch(() => {});
  }, []);

  // Custom validation for password
  const validatePassword = (_: any, value: any) => {
    if (!value) {
      const errorMessage = "Please enter a password!";
      return Promise.reject(new Error(errorMessage));
    }
    // Use regular expressions to check for specific conditions
    const hasUppercase = /[A-Z]/.test(value);
    const hasLowercase = /[a-z]/.test(value);
    const hasSpecialCharacter = /[!@#$%^&*(),.?":{}|<>]/.test(value);

    // Check for the required conditions
    if (
      value &&
      value.length >= 12 &&
      hasUppercase &&
      hasLowercase &&
      hasSpecialCharacter
    ) {
      return Promise.resolve();
    }

    // Create an array to store error messages
    const errorMessages = [];

    // Check each condition and add an error message if not met
    if (value.length < 12) {
      errorMessages.push("At least 12 characters");
    }
    if (!hasUppercase) {
      errorMessages.push("1 uppercase");
    }
    if (!hasLowercase) {
      errorMessages.push("1 lowercase");
    }
    if (!hasSpecialCharacter) {
      errorMessages.push("1 special character");
    }

    // Combine error messages and return the rejection with the message
    const errorMessage = `Password must have: ${errorMessages.join(", ")}`;
    return Promise.reject(new Error(errorMessage));
  };

  const handleSubmit = () => {
    const password = form.getFieldValue("password"); // Get the password value
    form
      .validateFields()
      .then(() => {
        const resetObject = {
          changePasswordId: params.changePasswordId,
          password: password,
        };
        resetPassword(resetObject).then((res: any) => {
          if (res) {
            // this.props.logoutUserAc();
            localStorage.removeItem(LOGIN_TOKEN);
            sessionStorage.removeItem("current_tab_application_token");

            setTimeout(() => {
              history.push("/login");
            }, 1500);
          }
        });
      })
      .catch((errorInfo) => {
        console.log("Form validation failed:", errorInfo);
      });
  };
  return (
    <div className="flex flex-col w-full r h-full bg-cover bg-bgImgType1 ">

      <div className="bg-transparent pt-6 pl-6 sticky top-0 "> 
       <img src={TB_ORANGE_LOGO} alt="" width="200px" style={{backgroundColor:'transparent'}} />
       </div>
      <div className="flex pt-16 xl:space-x-4 w-full h-full overflow-y-scroll ">
        <div className="flex justify-center h-full items-start w-full">
          <div
            className="bg-white p-8 rounded xl:m-10"
            style={{
              width: 463,
              boxShadow: "2px 4px 10px 0px #00000040",
              border: "1px solid #D9D9D9",
            }}
          >
            <div className="flex flex-col md:space-y-4 space-y-2 relative w-full ">
              {!isLinkExpire ? (
                <ExpireLinkMsg />
              ) : (
                <>
                  <div className="w-full flex items-center justify-center font-semibold md:text-lg text-base">
                    {capitalizeFirstLetter("Create new password")}
                  </div>
                  <div data-testid="contexDiv">
                    <pre>
                      Welcome!
                      <p style={pstyle}/>
                      Ready to reset your password?
                      <br />
                      Let&apos;s make it strong like your coffee &#9749; . Go
                      ahead and
                      <br />
                      create a new password to keep your account safe and
                      <br />
                      sound!
                    </pre>
                  </div>
                  <Form
                    form={form}
                    name="dependencies"
                    autoComplete="off"
                    style={{ maxWidth: 600 }}
                    layout="vertical"
                    // initialValues={{ remember: true }}
                    onFinish={handleSubmit}
                  >
                    {/* Password Field */}
                    <Form.Item
                      label="Password"
                      name="password"
                      data-testid="input1"
                      rules={[{ required: true, validator: validatePassword }]}
                    >
                      <Input.Password />
                    </Form.Item>

                    {/* Confirm Password Field */}
                    <Form.Item
                      label="Confirm Password"
                      name="confirm password"
                      data-testid="input2"
                      dependencies={["password"]}
                      rules={[
                        { required: true },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error("Password do not match")
                            );
                          },
                        }),
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                    <Button
                      htmlType="submit"
                      className="w-full my-3 mt-4"
                      type="primary"
                    >
                      {capitalizeFirstLetter("reset password")}
                    </Button>
                  </Form>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPwd;
