import React, { useState } from "react";
import { connect } from "react-redux";
import { CardDescriptionInputProps } from "../../../../type/card";
import { customFormats, customModules } from "../../../../constant";
import Editor from "../../../../ant/Editor/Editor";
import { UnprivilegedEditor } from "react-quill";
import { Typography } from "antd";
import { CheckOutlined, SyncOutlined } from "@ant-design/icons";
const {Text} = Typography;
const CardDescriptionInput = (props: CardDescriptionInputProps) => {
  const [newComment, setnewComment] = useState<string>(props.description);
  const [saveStatus, setSaveStatus] = useState<string>("");
  const [commentValidationMessage, setCommentValidationMessage] =
    useState<string>("");
  const handleEditorChange = (
    content: string,
    delta: any,
    source: string,
    editor: UnprivilegedEditor
  ) => {
    if (editor.getText().length > 0) {
      setnewComment(content);
      if (content.trim().length > 5000) {
        setCommentValidationMessage("Your description is too long");
      } else {
        setCommentValidationMessage("");
      }
    }
  };
  const handleKeyDownComment = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.keyCode === 13 && e.metaKey) {
      // Save the comment when Command + Enter is pressed
      handleCommentSave();
    }
  };
  // Save comment when Enter is pressed or Send button is clicked
  const handleCommentSave = () => {
    setSaveStatus("saving"); 
    if (newComment.trim().length > 0 && newComment.trim().length <= 5000) {
      props.create(newComment);
      setTimeout(() => {
        setSaveStatus("saved"); 
      }, 1000);
      setTimeout(() => setSaveStatus(""), 2000);
    } else {
      setSaveStatus("");
      setCommentValidationMessage("Your description is too long");
      return;
    }
  };

  return (
    <React.Fragment>
      {
      saveStatus && (
      <div
        style={{
          position: "fixed",
          top: 56,
          left: 240,
          width: "100%",
          padding: "10px",

          color: "black",
          textAlign: "left",
          zIndex: 1000,
        }}
      >
        {saveStatus === "saving" ? (
          <span className=" w-full font-bold" style={{ color: "#009D79"}}>
            <SyncOutlined
              spin={true}
              style={{ color: "#009D79", marginRight: 4 ,}}
            />{" "}
            Saving changes...
          </span>
        ) : (
          <span style={{ color: "#009D79" }} className="font-bold ">
            <CheckOutlined
              size={100}
              style={{ color: "#009D79", marginRight: 4 }}
              type="success"
            />
            Changes saved
          </span>
        )}
      </div>
    )}
        <Editor
          modules={customModules}
          onKeyDown={handleKeyDownComment}
          formats={customFormats}
          defaultValue={props.description}
          value={newComment}
          initialValue={props.description}
          onChange={handleEditorChange}
        />
        <div className="flex w-full mt-2 pr-2">
          <div className="justify-start flex w-full text-red-600">
            {commentValidationMessage}
          </div>
        </div>
        <div className="mt-2">
        <Text
            type="secondary"
            className="text-left"
            style={{ fontSize: "14px" }}
          >
            <sup style={{ color: "red" }}>*</sup>
            Please press command (ctrl) + Enter to save or update the
            description.
        </Text>
        </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  currentApplicant: state.opening.currentApplicant,
  clientId: state.opening.clientId,
  currentOpening: state.opening.currentOpening,
});

export default connect(mapStateToProps)(CardDescriptionInput);
