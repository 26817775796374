import React, { ReactNode, useEffect, useState } from "react"
import { Popover, Input, Typography, Divider, Space, Tag } from "antd"
import { CloseOutlined } from "@ant-design/icons"
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter"
import { LabeledIconButton } from "../../ant/Button"
import { Member, Registration, Scope } from "../../type/type.d" // Assuming Member type is similar to Label type
import { MEMBERS } from "../../constant"
import TacitbaseAvtar from "../../ant/Avtar"
import { hexToRGBA } from "../../utils/hexToRgb"
import SlackIcon from "../../assets/SlackIcon"
import { Overlay } from "../hook/Overlay"

const { Text } = Typography

interface Props {
    defaultTitle: string,
    initialMembers: Member[],
    element: ReactNode,
    selectedMembers?: string[],
    onAddMember: (member: Member) => void,
    determineIcon: (member: Member) => ReactNode // Function to determine icon based on label presence in items
}

const MemberAddPopover: React.FC<Props> = ({
    initialMembers = [],
    element,
    onAddMember,
    determineIcon
}) => {
    const [visible, setVisible] = useState(false)
    const [searchValue, setSearchValue] = useState("")
    const [searchResults, setSearchResults] = useState<Member[]>([])



    const handleAddMember = (member: Member) => {
        if (!member) {
            return
        }
        onAddMember(member)
    }




    const handleSearch = (value: any) => {
        const results = initialMembers.filter((member) =>
            member.first_name.toLowerCase().includes(value.toLowerCase()) ||
            member.last_name.toLowerCase().includes(value.toLowerCase()) ||
            member.email.toLowerCase().includes(value.toLowerCase()) ||
            member.username.toLowerCase().includes(value.toLowerCase())

        )
        setSearchValue(value)
        setSearchResults(results)
    }





    const hide = (event:any) => {
        event.stopPropagation()
        setVisible(false)
        setSearchValue("")
    }

    const contentDefault = searchValue ? (
        <>
            <div className="h-auto tacitbase-member-popover max-h-72 overflow-y-scroll py-1 overflow-hidden">
                <div className="space-y-1.5">
                    {searchResults.map((member: Member) => {
                        const icon = determineIcon(member)
                        return (
                            <>

                                <Tag
                                    key={member.id}
                                    className="w-full flex items-center py-0.5 justify-center h-auto cursor-pointer"

                                    onClick={(e) => {handleAddMember(member);
                                        e.stopPropagation();
                                    }}
                                ><div className="flex justify-center items-center">
                                        <Tag
                                            className="w-5 h-5 flex items-center justify-center outline-none border-0"
                                            style={{ backgroundColor: hexToRGBA('#000000', 0.1) }}
                                        >
                                            {icon}
                                        </Tag>

                                    </div>
                                    <Space className="w-full ">
                                        {member.image_url ?
                                            <TacitbaseAvtar size="default" src={member.image_url} />
                                            : <TacitbaseAvtar size="default" content={member.first_name[0] + member.last_name[0]} />
                                        }
                                        <div className="flex flex-col w-full justify-between">
                                            <span className="space-x-1 flex justify-start items-center">
                                                <Text strong>{member.first_name}</Text>
                                                <Text strong>{member.last_name}</Text>
                                                {member.registrations.some((registration: Registration) => registration.data.source === "slack") && (
                                                    <SlackIcon size="14" />
                                                )}
                                            </span>
                                            <Text ellipsis style={{ minWidth: 187, maxWidth: 187 }}
                                                className="text-xs  font-light">{member.email}</Text>
                                        </div>
                                    </Space>
                                </Tag>

                            </>)
                    })}
                </div>
            </div>        </>
    ) : (
        <>
            <div className="h-auto max-h-72 overflow-y-scroll py-1 overflow-hidden ">
                <div className="space-y-1.5">
                    {initialMembers.map((member: Member) => {
                        const icon = determineIcon(member)
                        return (
                            <>
                                <Tag
                                    key={member.id}
                                    className="w-full flex items-center py-0.5 justify-center h-auto cursor-pointer"

                                    onClick={(e) => {handleAddMember(member);
                                        e.stopPropagation();
                                    }}
                                ><div className="flex justify-center items-center">
                                        <Tag
                                            className="w-5 h-5 flex items-center justify-center outline-none border-0"
                                            style={{ backgroundColor: hexToRGBA('#000000', 0.1) }}
                                        >
                                            {icon}
                                        </Tag>

                                    </div>
                                    <Space className="w-full ">
                                        {member.image_url ?
                                            <TacitbaseAvtar size="default" src={member.image_url} />
                                            : <TacitbaseAvtar size="default" content={member.first_name[0] + member.last_name[0]} />
                                        }                                      
                                          <div className="flex flex-col w-full justify-between">
                                            <span className="space-x-1 flex justify-start items-center">
                                                <Text strong>{member.first_name}</Text>
                                                <Text strong>{member.last_name}</Text>
                                                {member.registrations.some((registration: Registration) => registration.data.source === "slack") && (
                                                    <SlackIcon size="14" />
                                                )}
                                            </span>
                                            <Text ellipsis style={{ minWidth: 187, maxWidth: 187 }}
                                                className="text-xs  font-light">{member.email}</Text>
                                        </div>
                                    </Space>
                                </Tag>

                            </>)
                    })}
                </div>
            </div>          </>
    )


    const titleContentDefault = (
        <div className="flex w-full bg-white items-center">
            <Text className="w-full flex justify-center">
                <Text>{capitalizeFirstLetter(MEMBERS)}</Text>
            </Text>
            <LabeledIconButton
                type="text"
                label=""
                onClick={hide}
                icon={<CloseOutlined />}
            />
        </div>
    )

    useEffect(() => {
        if (!visible) {
            setSearchValue("")
        }
    }, [visible])

    const content =
        <>
            <Divider className="my-2" />
            <div className="w-72 overflow-hidden">
                <Space direction="vertical" className="w-full">
                    <Input.Search
                        placeholder="Search member"
                        value={searchValue}
                        onChange={(e) => {handleSearch(e.target.value);
                            e.stopPropagation()
                        }}
                        onSearch={(value,e)=>{handleSearch(value);
                            e?.stopPropagation()
                        }}
                        onClick={(e)=> e.stopPropagation()}
                    />
                    <Text className="text-xs">{capitalizeFirstLetter("Organisation Members")}</Text>
                </Space>
                {contentDefault}
            </div>

            <div>
            </div>
        </>


    return (
        <div className="tacitbase-member-popover">
            <Popover
                title={titleContentDefault}
                content={<Overlay onClose={(e)=>hide(e)} scope={Scope.Popover}>{content}</Overlay>}
                trigger="click"
                visible={visible}
                onOpenChange={(isVisible) => setVisible(isVisible)}
                className="tacitbase-member-popover w-full"
            >
                {element}
            </Popover>
        </div>
    )
}

export default MemberAddPopover
