import React, { Dispatch, useEffect, useState } from "react";
import PopupTitle from "../../common/popupTitle/PopupTitle";
import { Checkbox, Input, Tag } from "antd";
import { connect } from "react-redux";
import { Action } from "redux";
import { updateAnalyticsListLabel } from "../../../redux/actions/opening/action";
import { updateAnalyticsListLabels } from "../../../lib/api";

interface UpdateAnalyticsLabelPopupProps {
  listId: any;
  lists: any;
  labels: any;
  currentOpening: any;
  click(e: any): void;
  prev: (() => void) | undefined;
  close?: (e: boolean) => void;
  onCreateLabel: (label: { value: string; color: string }) => void;
  updateAnalyticsListLabels: (labelData: any) => void;

  onCreateButtonClick: (clicked: boolean) => void; // Callback to send the clicked value
}

export const lightenColor = (color: string) => {
  // Remove '#' from the beginning of the color string
  const num = parseInt(color.slice(1), 16);
  // Calculate the new color with opacity of 0.1
  const newColor =
    "rgba(" +
    ((num >> 16) & 0xff) +
    "," +
    ((num >> 8) & 0xff) +
    "," +
    (num & 0xff) +
    ",0.1)";
  return newColor;
};

interface Label {
  id: string;
}

interface LabelsByListId {
  [listId: string]: Label;
}

var labelsByListId: LabelsByListId = {};

function UpdateAnalyticsLabelPopup(props: UpdateAnalyticsLabelPopupProps) {

  const [searchValue, setSearchValue] = useState("");
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [selectedLabel, setSelectedLabel] = useState<string | null>(null);
  const [labelsNotInListById, setLabelsNotInListById] = useState<Label[]>([]);

  const refreshLabelsNotInListById = () => {
    labelsByListId = {};
    for (const listId in props.lists) {
      const listLabel = props.lists[listId]?.label;
      if (listLabel) {
        labelsByListId[listId] = listLabel;
      }
    }
    const allLabels: Label[] = Object.values(props.labels);
    const newLabelsNotInListById: Label[] = [];

    for (const label of allLabels) {
      let labelFound = false;
      for (const listId in labelsByListId) {
        if (labelsByListId[listId].id === label.id) {
          labelFound = true;
          break;
        }
      }
      if (!labelFound) {
        newLabelsNotInListById.push(label);
      } else if (props.lists[props.listId]?.label?.id === label.id) {
        newLabelsNotInListById.push(label);
      }
    }
    setLabelsNotInListById(newLabelsNotInListById);
  };

  useEffect(() => {
    refreshLabelsNotInListById();
  }, [props.currentOpening]);


  const handleSearch = (value: any) => {
    setSearchValue(value);
    const labelsArray = Object.values<any>(props.labels);


    const results = labelsArray.filter((label: { name: string }) =>
      label.name.toLowerCase().includes(value.toLowerCase())
    );
    setSearchResults(results);
  };

  const handleLabelSelect = (
    labelName: string,
    labelColor: string,
    labelId: string
  ) => {
    //   for (const listId in props.lists) {
    //     if (listId === props.listId) continue; // Skip the current list

    //     const listLabelId = props.lists[listId]?.label?.id;
    //     if (listLabelId && listLabelId === labelId) {
    //         toast.error(`${labelName} label is already associated with ${props.lists[listId]?.name} list.`,
    //         // {position:toast.POSITION.BOTTOM_LEFT}
    //         );
    //         return;
    //     }
    // }

    // setSelectedLabel(labelName === selectedLabel ? null : labelName);

    const isSelected = props.lists[props?.listId]?.label?.id === labelId;
    const updatedLabelId = isSelected ? "" : labelId;
    const updatedLabelName = isSelected ? "" : labelName;
    const updatedLabelColor = isSelected ? "" : labelColor;

    updateAnalyticsListLabels(props.listId, updatedLabelId, {
      id: updatedLabelId,
      name: updatedLabelName,
      color: updatedLabelColor,
    })
      .then((response: any) => {
        props.updateAnalyticsListLabels(response);
        setSelectedLabel(labelName === selectedLabel ? null : response.name);
      })
      .catch((error: any) => {
        console.error("Error updating label:", error);
      });
  };

  return (
    <React.Fragment>
      <div className="my-2">
        <PopupTitle
          PopupTitle="Update analytics labels"
          back={props.prev}
          close={props.close}
        />
      </div>
      <div className="m-2 mb-3">
        {/* <span>search analytics labels</span> */}
        <div className="mb-2 rounded py-2  w-full">
          <Input
            placeholder="Search label"
            value={searchValue}
            onChange={(e) => handleSearch(e.target.value)}
            // onSearch={handleSearch}
          />
        </div>
        <div className="h-80  overflow-y-scroll">
          <div className="flex flex-col mb-2 rounded truncate">
            {searchValue === ""
              ? labelsNotInListById.map((label: any, index: number) => (
                  <div
                    key={index}
                    className="flex flex-col p-1 mb-1 rounded truncate hover:bg-gray-100  "
                  >
                    <Checkbox
                      checked={
                        props.lists[props?.listId]?.label?.id === label.id
                      }
                      onChange={() =>
                        handleLabelSelect(label.name, label.color, label.id)
                      }
                    >
                      <Tag
                        color={lightenColor(label.color)}
                        bordered
                        style={{
                          border: `1px solid ${label.color}`,
                          color: `${label.color}`,
                        }}
                      >
                        {label.name}
                      </Tag>
                    </Checkbox>
                  </div>
                ))
              : searchResults.map((label, index) => (
                  <div
                    key={index}
                    className="flex flex-col mb-1 p-1 rounded truncate hover:bg-gray-100"
                  >
                    <Checkbox
                      checked={
                        props.lists[props?.listId]?.label?.id === label.id
                      }
                      onChange={() =>
                        handleLabelSelect(label.name, label.color, label.id)
                      }
                    >
                      <Tag
                        color={lightenColor(label.color)}
                        bordered
                        style={{
                          border: `1px solid ${label.color}`,
                          color: `${label.color}`,
                        }}
                      >
                        {label.name}
                      </Tag>
                    </Checkbox>
                  </div>
                ))}
          </div>
        </div>
        {/* <div className="flex flex-col mb-2 rounded  truncate">
          <Checkbox
          // checked={checked}
          // disabled={disabled}
          // onChange={onChange}
          >
            <Tag bordered color="red">red</Tag>
          </Checkbox>
        </div> */}
        {/* <div className=" w-full mt-4">
          <Button
            type="default"
            onClick={handleCreateLabel}
            style={{ width: "100%", color: "#7152f2" }}
          >
            {" "}
            Create anlaytics label
          </Button>
        </div> */}
      </div>
    </React.Fragment>
  );
}

const mapPropsToState = (dispatch: Dispatch<Action>) => ({
  updateAnalyticsListLabels: (labelData: any) =>
    dispatch(updateAnalyticsListLabel(labelData)),
});
const mapStateToProps = (state: any) => ({
  labels: state.opening.analayticsLabels,
  lists: state.opening.boardList,
  currentOpening: state.opening.currentOpening,
});

export default connect(
  mapStateToProps,
  mapPropsToState
)(UpdateAnalyticsLabelPopup);
