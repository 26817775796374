import React, {useRef,   useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { editCDBRole, removeCandidateMember } from "../../lib/api";
import {
  deleteCDBMember,
  editCDBRoleOfMember,
} from "../../redux/actions/opening/action";
import { Member, openingMemberType, PermissionAccess } from "../../type/type.d";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import EditRole from "../subNavbar/memberProfile/memberActions/EditRole";
import EllipsButtonPopup from "../common/image/EllipsButtonPopup";

type CDBMemberActionProps = {
  selectedMember: openingMemberType;
  refer: any;
  editRoleOfMember: (memberId: string, access: PermissionAccess) => void;
  deleteMember: (memberId: string) => void;
};
const CDBMemberAction = (props: CDBMemberActionProps) => {
  const [open, setOpen] = useState(false);
 
  const refer=useRef(null)

  
  
  const [selectedRole, selectRole] = useState<string>(
    props.selectedMember
      ? props.selectedMember.access.admin
        ? capitalizeFirstLetter("admin")
        : props.selectedMember.access.comment_only
        ? capitalizeFirstLetter("commentor")
        : capitalizeFirstLetter("member")
      : ""
  );

  const [isSecretVisible, setSecretVisible] = useState<boolean>(
    props.selectedMember.access.is_secrete_visible
  );
  
  const removeMember = () => {
    removeCandidateMember({
      id: props.selectedMember.id,
    }).then((res) => {
      if (res) props.deleteMember(props.selectedMember.id);
    });
  };

  const editRole = () => {
    const req = {
      member: [
        {
          id: props.selectedMember.id,
          role: selectedRole,
          is_secrete_visible: isSecretVisible,
        },
      ],
    };
    editCDBRole(req)
      .then((res: Member) => {
        props.editRoleOfMember(res.id, res.access);
        setOpen(!open);
      })

  };
  const openPopup = () => {

    setOpen(!open);
  };
  return (
    <>
      <EllipsButtonPopup setOpen={openPopup} openPopup={open} refer={refer}>
        <EditRole
          selectedMember={props.selectedMember}
          click={() => setOpen(!open)}
          refer={refer}
          role={selectedRole}
          selectedRole={(role: any) => selectRole(role)}
          editRole={editRole}
          setSecreteVisible={() => setSecretVisible(!isSecretVisible)}
          isSecretVisible={isSecretVisible}
          remove={removeMember}

        />
      </EllipsButtonPopup>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  openingMembers: state.opening.openingMembers,
  loginUser: state.opening.loginUser,
  currentOpening: state.opening.currentOpening,
  topic: state.opening.topicSubscription,
  applicationId: state.opening.applicationId,
});
const mapPropsToState = (dispatch: Dispatch) => ({
  editRoleOfMember: (memberId: string, access: PermissionAccess) =>
    dispatch(editCDBRoleOfMember(memberId, access)),

  deleteMember: (memberId: string) => dispatch(deleteCDBMember(memberId)),
});
export default connect(mapStateToProps, mapPropsToState)(CDBMemberAction);
