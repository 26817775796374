import React, { useEffect, useState } from "react";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import {
  RecordThreads,
  SendMail,
  loginUser,
  notificationServiceEntityDotRemovePayloadType,
} from "../../type/type";
import {
  getUserThreadOfMessage,
  markMailAsRead,
  readNotification,
  sendMail,
} from "../../lib/api";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
  addComposeMailInInboxThread,
  changeServiceNotificationsCountValue,
  getSelectedMailThread,
  markAsReadMail,
  unread_dot_remover,
} from "../../redux/actions/opening/action";
// import usermailsThread from "../mock/mailThread.json"
import {
  currentMailThreads,
  userMails,
} from "../../redux/reducers/initialState";
import { sendMailReq } from "../../lib/apiReq";
import DetailMail from "../Tmail/DetailMail";
import { Divider, Spin, Typography } from "antd";
import { LabeledIconButton } from "../../ant/Button";
import { throwSuccess } from "../../service/throwError";
const { Text } = Typography;

type TmailThreadProps = {
  currentMailThread: currentMailThreads;
  selectedMailId: string;
  userMails: userMails|any;
  currentPage?: string;
  tab?: string;
  refKey?: string;
  markAsReadMail: (
    currentMailId: string,
    tab: string,
    currentPage: string
  ) => void;
  removeMailNotificationDotsOnCard(
    payload: notificationServiceEntityDotRemovePayloadType
  ): void;
  changeServiceNotificationsCount(payload: {}): void;
  loginUser: loginUser;
  notificationCenter: any;

  getSelectedMailThread: (
    currentMailId: string,
    threadMsg: RecordThreads
  ) => void;
  isCardThread?: boolean;
  addComposeMailInInboxThread(mailId: SendMail, selectedMailId: string): void;
};
const TmailThread = (props: TmailThreadProps) => {
  const [showMessages, setShowMessages] = useState(false);

  const handleShowMessages = () => {
    setShowMessages(!showMessages);
  };
  useEffect(() => {
    if (props.selectedMailId) {
      getUserThreadOfMessage(props.selectedMailId).then((res) => {
        props.getSelectedMailThread(props.selectedMailId, res);
      });

      markMailAsRead(props.selectedMailId).then((res) => {
        if (res) {
          if(props.userMails["inbox"][props?.userMails["inbox"]?.current_page][props?.selectedMailId]?.message.read===false) {
            props.markAsReadMail(
              props.selectedMailId,
              "inbox",
              props?.userMails["inbox"]?.current_page
            );
        }
      }
      });
      props.notificationCenter.unreadDotsofServices?.inbox?.mail?.[
        props.selectedMailId
      ] &&
        readNotification(props.selectedMailId, "mail").then(() => {
          props.removeMailNotificationDotsOnCard({
            service: "inbox",
            model_name: "mail",
            remove_id: props.selectedMailId,
          });
        });

      setShowMessages(false);
    }
  }, [props.selectedMailId]);

  const sendMailFromcard = (e: sendMailReq) => {
    sendMail(e).then((res) => {
      props.addComposeMailInInboxThread(res, props.selectedMailId);
      throwSuccess("Mail sent")
    });
  };
  if (!props.currentMailThread[props.selectedMailId])
    return (
      <div className="flex items-center h-full justify-center">
        <span className="flex flex-col space-y-2 items-center justify-center h-full">
          <Spin tip="" size="default" />
          <Text style={{ textAlign: "center" }}>Loading...</Text>
        </span>{" "}
      </div>
    );
  return (
    <>
      <div className="py-2 w-full border-b px-3 flex justify-between items-start">
        <div className="flex-wrap" style={{maxWidth:'80%'}}><Text strong style={{fontSize:'16px',width:'900px'}} >
          {props.currentMailThread[props.selectedMailId] &&
            capitalizeFirstLetter(
              props.currentMailThread[props.selectedMailId]?.messages[0].subject
            )}
            </Text>
        </div>
        <div className="text-xs border space-x-1 rounded px-2 py-1 ml-2 flex-wrap" >
          {capitalizeFirstLetter(
            `${props.currentMailThread[props.selectedMailId].entity}`
          )}
          {" "}|{" "}
          {capitalizeFirstLetter(
            `${props.currentMailThread[props.selectedMailId].source.name}`
          )}
        </div>
      </div>
      {props.currentMailThread[props.selectedMailId].messages.length > 0 && (
        <DetailMail
          isCardThread={props.isCardThread ? true : false}
          record_id={props.currentMailThread[props.selectedMailId].source.id}
          mail={props.currentMailThread[props.selectedMailId].messages[0]}
          selectMailId={props.selectedMailId}
          entity={props.currentMailThread[props.selectedMailId].entity}
          service={props.currentMailThread[props.selectedMailId].service}
          callApi={(e: any) => sendMailFromcard(e)}
          source={props.currentMailThread[props.selectedMailId].source}
        />
      )}
      {showMessages ||
      props.currentMailThread[props.selectedMailId].messages.length == 2 ? (
        <div className="flex-col w-full">
          <Divider className="m-0"/>
        </div>
      ) : (
        //
        <></>
      )}
      {showMessages &&
        props.currentMailThread[props.selectedMailId].messages
          .slice(
            1,
            props.currentMailThread[props.selectedMailId].messages.length - 1
          )
          .map((message, index) => (
            <>
              <DetailMail
                isCardThread={props.isCardThread ? true : false}
                record_id={
                  props.currentMailThread[props.selectedMailId].source.id
                }
                selectMailId={props.selectedMailId}
                entity={props.currentMailThread[props.selectedMailId].entity}
                key={index}
                mail={message}
                service={props.currentMailThread[props.selectedMailId].service}
                source={props.currentMailThread[props.selectedMailId].source}
                callApi={(e: any) => sendMailFromcard(e)}
              />
              <div className="flex-col w-full">
              <Divider className="m-0"/>
              </div>
            </>
          ))}
      {!showMessages &&
        props.currentMailThread[props.selectedMailId].messages.length > 2 && (
          <div className="flex items-center justify-center my-2">
            <div className="flex-col">
              <Divider className="my-1 w-3" />
              <Divider className="m-0 w-3" />
            </div>
            <LabeledIconButton 
              label={
                props.currentMailThread[props.selectedMailId].messages.length -
                2
              }
              onClick={handleShowMessages}
              className="h-8 w-4 m-0"
              style={{ borderRadius: "50%" }} 
              />
            <div className="flex-col w-full">
              <Divider className="my-1 w-3" />
              <Divider className="m-0 w-3" />
            </div>
          </div>
        )}
      {props.currentMailThread[props.selectedMailId].messages.length > 1 && (
        <>
          <DetailMail
            isCardThread={props.isCardThread ? true : false}
            source={props.currentMailThread[props.selectedMailId].source}
            record_id={props.currentMailThread[props.selectedMailId].source.id}
            selectMailId={props.selectedMailId}
            entity={props.currentMailThread[props.selectedMailId].entity}
            mail={
              props.currentMailThread[props.selectedMailId].messages[
                props.currentMailThread[props.selectedMailId].messages.length -
                  1
              ]
            }
            service={props.currentMailThread[props.selectedMailId].service}
            callApi={(e: any) => sendMailFromcard(e)}
          />
          {/* <button className="ml-5 px-2 py-1 text-sm rounded border border-tacitbase-tertiary1 border-opacity-30 hover:bg-gray-100">
              <i className="fa fa-reply px-1 " aria-hidden="true"></i>
              Reply
            </button> */}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  currentMailThread: state.opening.currentMailThread,
  userMails: state.opening.userMails,
  loginUser: state.opening.loginUser,
  notificationCenter: state.opening.notificationCenter,
});
const mapPropsToState = (dispatch: Dispatch) => ({
  getSelectedMailThread: (currentMailId: string, threadMsg: RecordThreads) =>
    dispatch(getSelectedMailThread(currentMailId, threadMsg)),
  markAsReadMail: (currentMailId: string, tab: string, currentPage: string) =>
    dispatch(markAsReadMail(currentMailId, tab, currentPage)),
  addComposeMailInInboxThread: (threadMsg: SendMail, selectedMailId: string) =>
    dispatch(addComposeMailInInboxThread(threadMsg, selectedMailId)),
  changeServiceNotificationsCount: (payload: {}) =>
    dispatch(changeServiceNotificationsCountValue(payload)),
  removeMailNotificationDotsOnCard: (
    payload: notificationServiceEntityDotRemovePayloadType
  ) => dispatch(unread_dot_remover(payload)),
});
export default connect(mapStateToProps, mapPropsToState)(TmailThread);
