import React from 'react'
import { Table } from 'antd'
import { TableProps } from 'antd/lib/table'

interface DataItem {
    key: React.Key,
    [key: string]: any
}



interface GenericTableProps {
    data: DataItem[],
    columns?: any,
}

const GenericTable: React.FC<GenericTableProps & TableProps<DataItem>> = ({ data, columns, ...props }) => {
    return <Table dataSource={data} columns={columns} {...props} />
};

export default GenericTable





