import React, { useState } from "react";
import { connect } from "react-redux";
import { AttachmentProps } from "../../../type/cardActionButton";
import AttachmentLoader from "./AttachmentLoader";
import DeleteAttachment from "./DeleteAttachment";
import EditAttachment from "./EditAttachment";
import AttachmentPreviewOfMail from "./DownloadAttchament"
import { Attachment } from "../../../type/type"
import { Button, Dropdown, Menu, Space, Tag, Typography } from "antd"
import OpenFileInNewTab from "./OpenFileInNewTab"
import { ATTACHMENTS } from "../../../constant";
import { EllipsisOutlined, FilePdfOutlined } from "@ant-design/icons";
import { PDFRenderer } from "../PDFViewer";
const {Text} = Typography;

const CardAttachment = (props: AttachmentProps) => {
  // Define the default attachment object
const initialAttachment: Attachment = {
  id: "",
  name: "",
  objectKey: "",
  modelName: "",
  recordID: "",
  presignedLink: "",
  created_at: "",
  updated_at: "",
  opening_id: "",
  converted_presigned_link:"",
  parent:"",
  converted_object_key:"",
};
  const [selectedCandidateAttachement, setSelectedAttachment] = useState<Attachment>(initialAttachment);
  const handleSelectedAttachment = (attachment: Attachment) => {
    setSelectedAttachment(attachment);
  };
  const onCloseViewer = () => {
    setSelectedAttachment(initialAttachment);
  };

  //if applicant dose not having an attachment then returning
  if (!props.attachment[props.currentApplicant.id]) {
    return null;
  }

  // attachment action menu
  const renderMenu = (attachment: Attachment) => (
    <Menu>
      <div
        className="flex flex-col items-start space-y-2 p-2"
        role="button"
        tabIndex={0}
        onClick={(e) => e.stopPropagation()}
        onKeyDown={(e) => {
          if (e.keyCode === 13 || e.metaKey) {
            e.stopPropagation();
          }
        }}
      >
        <div>
        <AttachmentPreviewOfMail attachment={attachment}  />
        </div>
        <div>
        <EditAttachment attachment={attachment} />
        </div>
        <div>
        <DeleteAttachment attachment={attachment} />
        </div>
        <div>
        <OpenFileInNewTab attachment={attachment} />
        </div>
      </div>
    </Menu>
  );
  return (
    <div>
      {props.attachment[props.currentApplicant.id]?.length > 0 && (
        <Space direction="vertical" className="w-full mb-2 px-4">
          <Text strong>{ATTACHMENTS}</Text>
          <div className="flex flex-wrap gap-4 pt-2 w-full">
            {props.attachment[props.currentApplicant.id].map(
              (attachment: any) => {
                return (
                  <Tag
                    className="cursor-pointer flex items-center border border-black border-opacity-5 p-2 h-10"
                    style={{ width: "45%" }}
                    bordered={false}
                    key={attachment.id}
                    onClick={() => handleSelectedAttachment(attachment)}
                  >
                    <FilePdfOutlined style={{ fontSize: "22px" }} />
                    <Text
                      ellipsis={{ tooltip: attachment.name }}
                      style={{ width: "100%" }}
                    >
                      {attachment.name}
                    </Text>
                    <Dropdown
                      overlay={renderMenu(attachment)}
                      trigger={["click"]}
                    >
                      <Button
                        type="text"
                        tabIndex={-1}
                        icon={<EllipsisOutlined />}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                        }}
                      />
                    </Dropdown>
                  </Tag>
                );
              }
            )}
          </div>
          {props.attachmentLoader && <AttachmentLoader />}
        </Space>
      )}
      
      {selectedCandidateAttachement?.id?.length > 0 && (
        <PDFRenderer
          title={selectedCandidateAttachement.name}
          attachmentOfwhom={props.currentApplicant.name}
          onCloseViewer={onCloseViewer}
          url={selectedCandidateAttachement.presignedLink}
        />
      )}
    </div>
  );
}  
const mapStateToProps = (state: any) => ({
  attachment: state.opening.attachment,
  currentApplicant: state.opening.currentApplicant,
  attachmentLoader: state.opening.attachmentLoader,
  clientId: state.opening.clientId,
});
export default connect(mapStateToProps)(CardAttachment);
