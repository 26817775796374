// refer links 
// https://www.freecodecamp.org/news/how-to-use-redux-persist-when-migrating-your-states-a5dee16b5ead
// https://github.com/rt2zz/redux-persist/blob/master/docs/migrations.md

import { AnyAction, applyMiddleware, combineReducers, createStore } from "redux"
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { persistStore, persistReducer, createMigrate } from "redux-persist"
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web


import todosReducer, { RootState } from "./reducers/root"
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2"
import { migrations } from "./reduxMigration"
import { Reducer } from "react"

const persistConfig = {
  key: "root",
  version: 1, // Starting with version 1
  storage,
  stateReconciler: autoMergeLevel2,
  migrate: createMigrate(migrations, { debug: true }),
};

var rootReducer: Reducer<RootState, AnyAction> = combineReducers({
  opening: todosReducer,
});
const logger = () => {
  return (next: any) => {
    return (action: any) => {
      const result = next(action);
      
      return result;
    };
  };
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export default function configureAppStore(preloadState?: any) {
  const middlewares = [thunk];
  const composeEnhancers =
    process.env.REACT_APP_ENV === "production"
      ? applyMiddleware(...middlewares)
      : composeWithDevTools(applyMiddleware(...middlewares, logger));
  const store = createStore(persistedReducer, preloadState, composeEnhancers);
  let persistor = persistStore(store);
  return {
    store: store,
    persistor,
  };
}
