import React from "react";
import { Divider } from "antd";
import { LabeledIconButton } from "../../../ant/Button";

type EditProfilePopupProps = {
  click: (e: any, flag: boolean) => void;
  attachment: any;
  deleteAttchement: (e: any) => void;
};

const DeleteAttachmentPopup = (props: EditProfilePopupProps) => {
  return (
    <div
      className="flex flex-col h-auto mt-1 z-10 w-56 rounded"
    >
      <div className="flex">
        <div
          className="flex tracking-normal font-medium  w-full"
        >
          Delete attachment
        </div>
      </div>
     <Divider className="my-2"/>
      <div className="">
        <div
          className="text-sm"
          onClick={(e: any) => e.stopPropagation()}
          onKeyDown={(e: any) => e.stopPropagation()}
          data-testid="testbtn2"
          tabIndex={0}
          role="button"
        >
          Are you sure you want to delete this
          attachment? This action is irreversible.
        </div>
       <div className="flex justify-end mt-4">
        <LabeledIconButton type="primary" label="Delete" onClick={props.deleteAttchement}/>
        </div>
      </div>
    </div>
  );
};

export default DeleteAttachmentPopup;
