import React, { useState } from "react";
import UpdateAnalyticsLabelPopup from "./UpdateAnalyticsLabelPopup";
import AnalyticsCreateLabelpanel from "./AnalyticsCretaeLabelPanel";

interface AnalyticsLabelPopoverProps {
  prev: (() => void) | undefined;
  close?: (e: boolean) => void;
  listId?:string
}

function AnalyticsLabelPopover(props: AnalyticsLabelPopoverProps) {
  const [showCreatePopup, setShowCreatePopup] = useState(false);

  const handleCreatePopup = () => {
    setShowCreatePopup(true);
  };
  const handleCreateButtonClick = (clicked: boolean) => {
    setShowCreatePopup(clicked);
  };

  const handleCreateLabel = () => {
    
  };

  function setButtonState(arg0: boolean): any {
    setShowCreatePopup(arg0);
  }

  return (
    <React.Fragment>
      {showCreatePopup == false ? (
        <UpdateAnalyticsLabelPopup
        
                  onCreateButtonClick={handleCreateButtonClick}
                  onCreateLabel={handleCreatePopup}
                  click={function () { } }
                  prev={props.prev}
                  close={props.close} listId={props.listId}         
        />
      ) : (
        showCreatePopup && (
          <div>
            <AnalyticsCreateLabelpanel
              onCreateLabel={handleCreateLabel}
              onButtonState={setButtonState}
              prev={()=>setButtonState(false)}
              close={props.close}
            />
          </div>
        )
      )}
    </React.Fragment>
  );
}

export default AnalyticsLabelPopover;
