import { JSXElementConstructor } from "react";
// import { ACTIVITY_TAG, COMMENT_TAG } from "../constant";
/************************************* Real time notification handler redux data of current id's object type ********************************/

export type reduxCurrentIDSType={currentBoard:string,currentCard:string,currentMail:string,currentReview:string,currentOrgId:string}



/******************************** constants file type ******************************************/
export type columnType = {
  name: string;
  type: string;
  public: boolean;
};

export type paginationDetails = {
  page: number;
  per_page: number;
  page_count: number;
  total_count: number;
  unread_count: number;
};

export type TabType = {
  [key: string]: {
    active: boolean;
    component: any;
    icon: JSX.Element;
  };
};
export type CandidateReviewTab = {
  [key: string]: {
    active: boolean;
  };
};
import {
  allNotifications,
  Calendar,
  calendar_conference_settingsRes,
  Event,
  getAllUserrrulesRes,
  Notification,
  siteMapping,
  Transaction,
  UserConnectedZoomMeet,
  WatcherSubscription,
  
} from "../lib/apiRes";
import { ACTIVITY_TAG, COMMENT_TAG } from "../constant";
import {  initDashboardNotification } from "../redux/reducers/initialState";
import { ExtendedTableColumn } from "../components/formBuilder/FormBuilder"
import { Location } from "../components/JdManagement/Types"
export type ShortcutEvent = KeyboardEvent;

export type ShortcutHandler<T = void> = (event: any) => T;
export interface RegisterShortcutHandlerOptions {
  scope?: Scope;
  clearScope?: boolean;
  key?: Keys;
}

export interface AddHandlerParams {
  callback: ShortcutHandler;
  scope: Scope;
  key?: Keys;
  clearScope?: boolean;
}

export interface Handler {
  callback: ShortcutHandler;
  key?: Keys;
}

export interface RegisterShortcutOptions {
  scope?: Scope;
  key: Keys;
  enabled?: boolean;
  clearScope?: boolean;
}
export enum Keys {
  Backspace = "Backspace",
  Tab = "Tab",
  Enter = "Enter",
  Shift = "Shift",
  Control = "Control",
  Alt = "Alt",
  CapsLock = "CapsLock",
  Escape = "Escape",
  LineFeed = "LineFeed",
  PageUp = "PageUp",
  PageDown = "PageDown",
  End = "End",
  Home = "Home",
  ArrowLeft = "ArrowLeft",
  ArrowUp = "ArrowUp",
  ArrowRight = "ArrowRight",
  ArrowDown = "ArrowDown",
  Left = "Left",
  Up = "Up",
  Right = "Right",
  Down = "Down",
  Insert = "Insert",
  Delete = "Delete",
  Meta = "Meta",
  LeftWindowKey = "Meta",
  RightWindowKey = "Meta",
}

export enum Scope {
  Global = 0,
  Default = 1,
  Header = 2,
  Board = 3,
  Sidebar = 10,
  Card = 20,
  BoardView = 30,
  PowerUpsDirectory = 30,
  Dialog = 100,
  DialogSection = 110,
  Overlay = 120,
  Modal = 125,
  Popover = 130,
  ChildPopover = 135,
  ConfirmationPopup = 150,
  Input = 151,
  /**
   * Universal shortcuts are usable anywhere, without taking away support for
   * any shortcuts with lower scopes. Use with caution to avoid key collision!
   */
  Universal = 1000,
}

export type ReducerParams = {
  state: any;
  action?: any;
};
export type openingMemberType = {
  id: string;
  access: PermissionAccess;
};
export type KeyWithArray = {
  [key: string]: string[];
};
export type PermissionAccess = {
  admin: boolean;
  comment_only: boolean;
  is_secrete_visible: boolean;
};
export type companyAnalyticsCounts = {
  "active_boards": number,
  "boards_on_hold": number,
  "job_openings": number,
  "filled_job_openings": number
}
export type CompanyCommon = {
  company_id: string,
  company_name: string,
  analytics: companyAnalyticsCounts
}

export type JobBoard = CompanyCommon & {
  boards: Opening[]
}

export type companyBoards = CompanyCommon & {
  boards: string[]
};


export type JobBoardAnalytics = {
  "sourced": number,
  "offered": number,
  "joined": number,
  "rejected": number
}
export interface Opening {
  id: string,
  name: string,
  color: string,
  description: string,
  image: string,
  member_id: string,
  members: string[],
  labels: any[],
  background_image: boolean,
  member_only: boolean,
  visibility: boolean,
  company_id: string,
  company_name: string,
  job_id: string,
  archive: boolean,
  starred: boolean,
  lists: any,
  created_at: string,
  updated_at: string,
  due_date: string,
  priority: string,
  tenant_id: string,
  socket_client_id: string,
  default_template: boolean,
  total_in_pipeline: number,
  access: PermissionAccess,
  analytics: JobBoardAnalytics
}
export type ImageUrls = {
  full: string,
  raw: string,
  regular: string,
  small: string,
  small_s3: string,
  thumb: string,
}
export type ImageData = {
  id:string,
  urls: ImageUrls,
  user: {
    name: string,
    links: {
      self: string,
      html: string,
      photos: string,
      likes: string,
      portfolio: string,
      following: string,
      followers: string
    },
  },
  links: {
    download: string,
    download_location: string,
    html: string,
    self: string
  },
  isSet:boolean,
}

export type calendar = {
  name: string;
  description: string;
  owner_id: string;
  owner_name: string;
  owner_email: string;
  default_timezone: string;
  default_event_duration: number;
  default_event_color: string;
  past_event_color: boolean;
  board_id: string;
  member_only: boolean;
  org_id: string;
};
export type cardMemberForEvent = {
  id: string;
  role: string;
  is_secrete_visible: boolean;
};
export type List = {
  id: string;
  name: string;
  position: number;
  archive: boolean;
  opening_id: string;
  created_at: string;
  updated_at: string;
  applicants: Applicant[];
  socket_client_id: string;
  rank: string;
  position: number;
  locked: boolean
};

export type Applicant = {
  id: string;
  name: string;
  description: string;
  archive: boolean;
  starred: boolean;
  member_only: boolean;
  created_at: string;
  updated_at: string;
  list_id: string;
  opening_id: string;
  socket_client_id: "";
  index: number;
  creator: string;
  comment_count: number;
  attachment_count: number;
  labels: string[];
  members: string[];
  position: number;
  rank: string;
  access: PermissionAccess;
  candidate_id: string;
  workspace_name:string;
  opening_name:string;
};

export type menuItem = {
  name: string;
  icon: any;
  active: boolean;
};

export type menuItem1 = {
  name: string;
  icon: any;
  active: boolean;
  popup?: JSXElementConstructor;
  points?: number;
};

export type Label = {
  id: string;
  name: string;
  color: string;
  created_at: string;
  updated_at: string;
  opening_id: string;
  socket_client_id: string;
  applicant_id: string;
};
export type InitLabel = {
  [key: string]: Label;
};
export type GetOpening = {
  id: string;
  name: string;
  description: string;
  color: string;
  image: string;
  archive: boolean;
  starred: boolean;
  listIDs: string[];
  member_id: string;
};

export type Attachment = {
  id: string;
  name: string;
  objectKey: string;
  modelName: string;
  recordID: string;
  presignedLink: string;
  created_at: string;
  updated_at: string;
  opening_id: string;
  converted_presigned_link:string;
  converted_object_key:string;
  parent:string;
};

/************** searchy type *************/
export interface SearchDocument {
  document: Document;
  highlights: Highlight[];
  text_match: number;
}

export interface Document {
  accessible_to_user_ids: string[];
  comments: string[];
  created_at: number;
  description: string;
  id: string;
  labels: string[];
  labels_id: string[];
  name: string;
  opening_id: string;
  opening_name: string;
  updated_at: number;
}
export type SearchType = Array<SearchDocument>;
export type Searchresult = Array<SearchType>;

export interface Highlight {
  field: string;
  matched_tokens: any[];
  snippet: string;
  indices?: number[];
  snippets?: string[];
}

/************************* */
export type currentApplicant = {
  id: string;
  name: string;
  description: string;
  archive: boolean;
  starred: boolean;
  created_at: string;
  updated_at: string;
  list_id: string;
  opening_id: string;
  member_only:boolean;
  socket_client_id: "";
  index: number;
  creator: string;
  comment_count: number;
  attachment_count: number;
  labels: string[];
  members: string[];
  position: number;
  rank: string;
  candidate_id: string;
  source: Candidate;
};

export type hasError = {
  error: boolean;
  message: string;
};

export type labels = {
  applicantID: string;
  labels: labelObject[];
};
export type labelObject = {
  name: string;
  color: string;
};

export type updatedColor = {
  colorCode: string;
  isSet: boolean;
  showMore: boolean;
  name?: string;
  brightness: string
};

export type updatedImage = {
  isSet: boolean;
  name?: string;
}

export type rule = {
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  duration: string;
  buffer: string;
  count: string;
  locatio: string;
  freq: string;
  interval: string;
  increments: string;
};

export type Action = {
  type: string;
  payload: any;
};

export type slackAction = {
  action: string;
  check: boolean;
}[];
export type entity = {
  [key: string]: slackAction;
};

export type wsAction = {
  type: "subscribe" | "unsubscribe",
  model_type: string,
  model_id: string,
  user_id: string,
  org_id: string,
};

export type wsActionHash = {
  [key: string]: wsAction;
};

export let currentWsRequest: wsAction = {
  type: "subscribe" | "unsubscribe",
  model_type: "",
  model_id: "",
  user_id: "",
  org_id: "",
};

export type wsConnection = {
  [key: string]: boolean;
};
// @ts-ignore
export let wsConn: WebSocket = {
  readyState: WebSocket.CLOSED,
};

export type organisationState = {
  prev: {
    id: string;
  };
  current: {
    id: string;
  };
};

export let orgStatus: organisationState = {
  prev: {
    id: "",
  },
  current: {
    id: "",
  },
};

// export type Member = {
//   id: string;
//   mobile_phone: string;
//   image_url: string;
//   active: boolean;
//   email: string;
//   verified: boolean;
//   username: string;
//   first_name: string;
//   last_name: string;
//   roles: {
//     id: string;
//     name: string;
//   }[];
//   access: PermissionAccess;
//   activated: boolean;
//   insert_instant: string;
// };

//integratedSlackMembers


export interface IntegratedMember {
  tacitbase_user_id: string,
  integrations: Integration[]
}

export interface Integration {
  integration_id: string,
  app_name: string,
  first_name: string,
  last_name: string,
  email: string,
  platform_image: string
}


// new member type 
export interface Member {
  id: string;
  username: string;
  active: boolean;
  data: Data1;
  email: string;
  first_name: string;
  image_url: string;
  insert_instant?: any;
  last_name: string;
  last_update_instant: number;
  middle_name: string;
  mobile_phone: string;
  registrations: Registration[];
  tenant_id: string;
  verified: boolean;
  name: string;
  socket_client_id: string;
  opening_id: string;
  applicant_id: string;
  application_id: string;
  role: string;
  is_secrete_visible: boolean;
  parent_resource_id: string;
  list_id: string;
  access: Access;
  member_only: boolean;
  is_manager: boolean;
}

export interface Data1 {
  verifiedPhone: boolean;
}

export interface Registration {
  application_id: string;
  data: Data2;
  id: string;
  insert_instant?: number;
  last_login_instant?: number;
  last_update_instant?: number;
  roles: string[];
  username: string;
  verified: boolean;
}

export interface Data2 {
  activated: boolean;
  invitation_secret?:string;
  agency_name?:string;
  services: string;
  source: string;
  type: boolean;
  user_type: string;
}

export interface Access {
  admin: boolean;
  comment_only: boolean;
  is_secrete_visible: boolean;
}

export type openingActivity = {
  [key: string]: activity[];
};

export type activity = {
  id: string;
  trackable: string;
  trackable_id: string;
  trackable_name: string;
  sub_trackable: string;
  sub_trackable_id: string;
  sub_trackable_name: string;
  action: string;
  owner: string;
  owner_image: string;
  owner_id: string;
  recipient: string;
  attachment_object_key: string;
  old_val: string;
  new_val: string;
  topic_id: string;
  created_at: string;
  updated_at: string;
  tag: ACTIVITY_TAG;
};

export type IsSelected = {
  selected?: boolean;
};
interface IObjectKeys {
  [key: string]: string | {};
}
export type SelectedCandidateType = Candidate & IsSelected;
export type comment = {
  id: string;
  body: string;
  member_id: string;
  applicant_id: string;
  created_at: Date;
  updated_at: Date;
  socket_client_id: string;
  opening_id: string;
  list_id: string;
  tag: COMMENT_TAG;
  source: string
};

export type OutgoingInvitations = {
  id: string;
  invitee: string;
  inviter: {
    passwordChangeRequired: boolean;
    verified: boolean;
    active: boolean;
    twoFactor: {};
    email: string;
    id: string;
  };
  status: string;
  application_id: string;
  application_name: string;
  role: string;
  created_at: string;
  updated_at: string;
  socket_client_id: string;
};

/********************************************************* root ***************************************************/

//board
export type OrganizedBoard = {
  pageSection: PageSection;
};
interface OState {
  [key: string]: Opening;
}

export type PageSection = {
  sectionName: string;
  openings: OState;
};
//slack option
export interface SlackLabel {
  action: string;
  check: boolean;
}
export interface SlackSetting {
  [key: string]: SlackLabel[];
}

export interface SlackOptions {
  id: string;
  userid: string;
  topic: string;
  subscriber: string;
  socket_client_id: string;
  setting: SlackSetting;
  created_at: string;
  updated_at: string;
}

// notification settings

export interface NotificationOptions {
  id: string;
  userid: string;
  topic: string;
  subscriber: string;
  socket_client_id: string;
  setting: notificationSetting;
  created_at: string;
  updated_at: string;
  email_frequency: emailFrequencyLabel[];sound_settings:soundSetting
}
export interface notificationSetting {
  [key: string]: notificationLabel[];
}
export interface notificationLabel {
  action: string;
  check: boolean;
}
export interface notificationSoundSetting {
  [key:string]:soundSetting
}
export interface soundSetting {
  enabled:boolean;selected_sound:string
}
export interface emailFrequency {
  [key: string]: emailFrequencyLabel[];
}
export interface emailFrequencyLabel {
  action: string;
  check: boolean;
}

//loginUser
export interface Data {
  type: boolean;
  description: string;
  key: string;
  logo: string;
}

export type Orgs = {
  applicationId: string;
  data: Data;
  id: string;
  insertInstant: number;
  lastLoginInstant: number;
  lastUpdateInstant: number;
  roles: string[];
  username: string;
  usernameStatus: string;
  verified: boolean;
};
export type User = {
  connectorId: string;
  id: string;
  lastLoginInstant: number;
  passwordChangeRequired: boolean;
  passwordLastUpdateInstant: number;
  uniqueUsername: string;
  username: string;
  usernameStatus: string;
  verified: boolean;
  data: { verifiedPhone: boolean; locked: boolean };
  active: boolean;
  email: string;
  firstName: string;
  imageUrl: string;
  insertInstant: number;
  lastName: string;
  lastUpdateInstant: number;
  mobilePhone: string;
  registrations: Orgs[];
  tenantId: string;
  twoFactor: {};
  timezone: string;
};

//orgMember
export type orgMember = {
  [key: string]: Member;
};

export type OrgFilterType = {
  [key: string]: ObjectType;
};

export type FilterKeysType = {
  [key: string]: [];
};

export type ObjectType = {
  [key: string]: FilterKeysType;
};

export type loginUser = {
  token: string;
  user: User;
  services: string[];
};

export type initPluginDataType = {
  mappings: siteMapping[];
};
//userBilling
export type billingUser = {};

// calendar
// calendar

export type initCalendarRrule = {
  [key: string]:  getAllUserrrulesRes ;
};
export type currentCalendar = {
  currentCalenderId: string;
  currentCardEvents: Event[];
  rrules:initCalendarRrule;
  currentBoardEvents: Event[];
  currentCalendar: Calendar;
  currentCalendarConferenceSettings: calendar_conference_settingsRes;
  currentUserZoomMeet: UserConnectedZoomMeet;
};
// notification
export type notificationServiceEntityDotChangePayloadType ={"data":Notification[],"service":string} 
export type notificationServiceEntityDotRemovePayloadType ={"service":string,"remove_id":string,"model_name":string} 

export type DashboardNotificationKey = 'ats' | 'billing' | 'email' | 'review' | 'cdb' ;
export type initDashboardNotificationDotType={
  [key: DashboardNotificationKey|string]:Notification
}


export type notification = {
  allNotifications: initOpeningNotifications[];
  entityUnreadNotification: initOpeningNotifications[];
  entityUnreadNotificationBoard:initOpeningNotifications[];
  unreadNotifications: initOpeningNotifications[];
  dashboardNotificationsDot: initDashboardNotification;
  cardNotificationsDot:initDashboardNotification;
  boardNotificationsDot:initDashboardNotification;
  reviewNotificationsDot:initDashboardNotification;
  mailNotificationsDot:initDashboardNotification;
  cardNotifications: initDashboardNotification;
  currentCardWatcher:WatcherSubscription;
  currentBoardWatcher:WatcherSubscription
};

//applicantMembers
export type ApplicantMembers = {
  [key: string]: string[];
};

//openingMembers
export type OpeningMembers = {
  [key: string]: openingMemberType[];
};
export type ArrayOfString = {
  [key: string]: string[];
};

//archivedApplicant
export type ArchivedApplicant = {};

// InitOpeningActivity
export type InitOpeningActivity = {
  [key: string]: activity[];
};

//attachment
export type CardAttachment = {
  [key: string]: attachment[];
};

export type InitComments = {
  [key: string]: comment[] | activity[];
};
export type InitLists = {
  [key: string]: List[];
};
// initOpeningNotifications
export type initOpeningNotifications = {
  [key: string]: allNotifications[];
};


export type TokenType = {
  aud: string;
  exp: number;
  iat: number;
  iss: string;
  sub: string;
  jti: string;
  authenticationType: string;
  email: string;
  email_verified: boolean;
  preferred_username: string;
  applicationId: string;
  roles: string[];
  auth_time: number;
  tid: string;
  type: string;
  userId: string;
  imageUrl: string;
  username: string;
  services: string[];
  applicationName: string;
};

// **********reward***********
export type InitCardRewards = {
  [key: string]: Transaction[];
};

export type InitAllTpRewards = {
  [key: string]: Transaction[];
};

/********************************** candidate DB  ******************************/
export type initCDbMembersCDB = {
  [key: string]: { [key: string]: Candidate };
};

export type ReviewAttachments = {
  [key: string]: Attachment[];
};

export type ReviewComments = {
  [key: string]: {
    [key: string]: ReviewComment;
  };
};

export interface CandidateSource {
  id: string;
  from: string;
  expiry: string;
  jd: string;
  created_at: string;
  updated_at: string;
  medium:string;
}
export interface Candidate extends IObjectKeys {
  id: string;
  first_name: string;
  last_name: string;
  description: string;
  email: string;
  phone: string;
  country_code: string,
  extension:string,
  source: CandidateSource;
  customs: any;
  attached_jd: string;
  created_at: string;
  updated_at: string;
  labels:any[];
  resume_analysis?:ResumeAnalysis[];
}

export interface CandidateAiData  {
  id:string;
  AiResumeScore: number;
  label:string;
}
export interface QuickCandidate {
  first: string;
  last: string;
  email: string;
  phone: string;
}
export interface Review {
  id: string;
  name: string;
  candidates: Candidate;
  reviewers: string[];
  labels: string[];
}
export interface Source {
  name: string;
  from: string;
  created_at: number;
  medium:string;
}
export type CandidateDetailReview = {
  id: string;
  name: string;
  candidates: {
    id: string;
    first_name: string;
    last_name: string;
    linked_in: string;
    twitter: string;
    source: Source;
    description: string,
    email: string,
    phone: string,
    customs: { [key: string]: string }
  };
  attachment_count: number;
  comment_count: number,
  description: string;
  labels: Label[];
  members: string[];
  created_by: string
};
type tagType = {
  tag: string;
};
export type commentTagType = ReviewComment & tagType;
export type CandidatesInReview = {
  reviewed: boolean;
  reviews: {
    [key:string]:{
    [key: string]: CandidateReview;
    }
  };
  currentPage:string
};
export interface CandidateReview {
  id: string;
  name: string;
  candidates: Candidate;
  comments: number;
  comment_count: number;
  attachment_count: number;
  description: boolean;
  labels: string[];
  members: string[];
  reviewed: boolean;
  created_by: string;
  created_at:string
}
export interface CandidateShortReview {
  id: string;
  name: string;
  candidates: Candidate;
  comments: number;
  comment_count: number;
  attachment_count: number;
  description: boolean;
  labels: Label[];
  members: string[];
  reviewed: boolean;
  created_by: string;
  created_at:string
}

export type TableColumn = {
  name: string;
  type: string;
  secret: boolean;
  custom: boolean;
  view: string;
  // values: string[]
};

export type CustomColumn = {
  id: string;
  name: string;
  type: string;
  size: int;
  unique: boolean;
  default: any;
  description: string;
  secret: boolean;
  entity: string;
  created_at: string;
  view: string;
  updated_at: string;
};

export type ReviewComment = {
  id: string;
  body: string;
  review_id: string;
  commentor: string;
  result: string;
  created_at: Date;
  updated_at: Date;
};

/************************************** tacitmail  ********************************************/
export type SendMail = {
  service: string;
  entity: string;
  mails: {
    failed: Mail[],
    success: Mail[]
  }
};
export type Mail = {
  id: string;
  subject: string;
  from: string;
  to: string[];
  body: string;
  object_key: string;
  attachments: Attachment[];
  user_id: string;
  status: string;
  reason: string;
  address_id: string;
  domain: string;
  tag: string;
  read: boolean;
  important: boolean;
  created_at: string;
  updated_at: string;
  cc: string[];
  bcc: string[];
  key: string;
  initiator:string
};
export type MailPreviewWithTag = {
  service: string,
  entity: string,
  message: Mail,
  msg_count: number
} & tagType
export type SendMailtag = SendMail & tagType;

export interface RecordThreads {
  service: string;
  entity: string;
  source: {
    id: string;
    name: string;
    email: string;
  };
  messages: Mail[];
}
export interface MailPreview {
  service: string;
  entity: string;
  source: {
    id: string;
    name: string;
    email: string;
  };
  message: Mail;
  msg_count: number;
  attachment_count: number;
}

export interface Domain {
  id: string;
  hostezone_id: string;
  name: string;
  status: string;
  name_servers: string[];
  private_zone: boolean;
  resource_record_count: number;
  location: string;
  created_at: string;
  updated_at: string;
  integrations: DomainIntegration[];
  required_integrations: string[];
  default_from: string;
  custom: boolean;
}
export interface DomainIntegration {
  id: string;
  aws_id: string;
  domain_id: string;
  name: string;
  status: string;
  created_at: string;
  updated_at: string;
}

// notification
export interface company extends IObjectKeys {
  id: string,
  name: string,
  industry: string,
  websites: SocialProfile[],
  location: string,
  email: string,
  phone: string,
  contry_code: number,
  contacts: Contact[],
  contact_persons: ContactPerson[],
  description: string,
  social_profiles: SocialProfile[],
  logo: string,
  no_of_employees: number,
  members: string[]
}


export interface Contact {
  type: string,
  name: string,
  value: string,
}

export interface ContactPerson {
  name: string,
  email: string,
  phone: string,
}

export interface SocialProfile {
  name: string,
  url: string
}









//form builder
export type FormData = {
  elements: ExtendedTableColumn[]
}


export type FormBuilder = {
  id: string,
  title: string,
  form_data: FormData,
  form_url: string,
  created_at: string,
  updated_at: string,
  status: string,
  creater_id: string,
  company_logo: string
}


export interface Jd extends IObjectKeys {
  id: string,
  title: string,
  description: string,
  company_name: string,
  locations: Location[],
  attachment_url: string,
  number_of_open_positions: number,
  skills_description: string,
  job_type: string,
  experience_level_from: number,
  experience_level_to: number,
  experience_level_in_string: string,
  salary_currency_type: string,
  salary_range_from: number,
  salary_range_to: number,
  status: string,
  created_at: string,
  updated_at: string,
  creater_id: string,
  form_id: string,
  form_name: string,
  form_url: string,
  jd_public_url: string,
  board_id: string,
  country: string,
  state: string,
  city: string,
  hide: boolean,
  country_code: string,
  department: string
}


export type searchDynamicQueryParameters = {
  filter_by: string,
  page: number,
  per_page: number,
  q: string,
  collection: string
}
export type customeFilterType = {
  column: string,
  operator: string,
  value: string,
  view: string,
  operatorName: string,
  id?: string
}

export type searchDynamicQuery = {
  query: string,
  filters: customeFilterType[]
}



// Stripe address element
export type addressElementType={name:string,phone:string,address:{
  city: string,
  country: string,
  line1: string,
  line2: string,
  postal_code: string,
  state: string,}
}

// UpdatesPagination
export interface notificationsPagination {
  page: number,
  per_page: number,
  page_count: number,
  total_count: number
}

export type ThirdPartyIntegrations = {
  integration_id: string;
  platform_name: string;
  platform_image:string;
  status: string;
  integration_name: string;
};
interface DefaultPrice {
  active: boolean;
  billing_scheme: string;
  created: number;
  currency: string;
  currency_options: any; 
  custom_unit_amount: any;
  deleted: boolean;
  id: string;
  livemode: boolean;
  lookup_key: string;
  metadata: any; 
  nickname: string;
  object: string;
  product: string | null;
  recurring: any;
  tax_behavior: string;
  tiers: any; 
  tiers_mode: string;
  transform_quantity: any; 
  type: string;
  unit_amount: number;
  unit_amount_decimal: string;
}

interface Feature {
  name: string;
}

interface Metadata {
  ai_feature: string;
  bulk_email: string;
  free_job_post: string;
  job_post: string;
  job_posting: string;
  type: string;
  plan?: string;
}

interface Product {
  active: boolean;
  created: number;
  default_price: DefaultPrice;
  deleted: boolean;
  description: string;
  features: Feature[];
  id: string;
  images: string[];
  livemode: boolean;
  metadata: Metadata;
  name: string;
  object: string;
  package_dimensions: any;
  shippable: boolean;
  statement_descriptor: string;
  tax_code: any;
  type: string;
  unit_label: string;
  updated: number;
  url: string;
}

export interface Plan {
  active: boolean;
  aggregate_usage: string;
  amount: number;
  amount_decimal: string;
  billing_scheme: string;
  created: number;
  currency: string;
  deleted: boolean;
  id: string;
  interval: string;
  interval_count: number;
  livemode: boolean;
  metadata: any; 
  nickname: string;
  object: string;
  product: Product;
  tiers: any;
  tiers_mode: string;
  transform_usage: any; 
  trial_period_days: number;
  usage_type: string;
}

export interface TotalBoards {
  active:number;
  total:number;
}
export interface checkoutSessionRequest {
    customer: string;
    line_items: {
      price: string;
      quantity: number;
      adjustable_quantity: {
        enabled: boolean;
        minimum: number;
        maximum: number;
      };
    }[];
    mode: string;
    ui_mode: string;
    success_url: string;
}

export type ResumeAnalysis = {
  id: string;
  jd_id: string;
  candidate_id: string;
  score: number;
  remark: string;
  summary: string;
  metadata: any;
  created_at: string;
  updated_at: string;
};
export type OrgInfo = {
  active: boolean;
  data: {
    company_size?: string;
    description?: string;
    facebook?: string;
    insta?: string;
    linkedin?: string;
    location?: string;
    mission_statement?: string;
    twitter?: string;
    type?: boolean;
    website?: string;
    youtube?: string;
    logo?: string;
  };
  id: string;
  name: string;
  state: string;
  tenantId: string;
};

export type relevancyFilter = {
  name: string;
  count: number;
};

export interface NotificationSettingsPayload {
  user_timezone: string;
  mention_notification: boolean;
  daily_notification: boolean;
  notification_time?: string;
}


export interface UserNotificationSettings {
  created_at: string;
  daily_notification: boolean;
  id: string;
  mention_notification: boolean;
  notification_time: string;
  org_id: string;
  updated_at: string;
  user_id: string;
  user_timezone: string;
}
