import { connect } from "react-redux";
import React from "react";
import { useHistory } from "react-router";
import { ObjectType, Opening } from "../../../type/type";
type FilterObjectProps = {
  applicationId: string;
  currentOpening: Opening;
  boardId: string;
  filterObj: ObjectType;
};
const FilterObject = (props: FilterObjectProps): any => {
  const history = useHistory();

  React.useEffect(() => {
    let filterDefault: any = props.filterObj[props.boardId] || {};
    if (filterDefault) {
      for (const key of Object.keys(filterDefault)) {
        let newQueryParam = "";
        if (filterDefault[key].length <= 0) {
          delete filterDefault[key];
        }
        newQueryParam = Object.entries(filterDefault)
          .map((e) => e.join(":"))
          .join("|");

        if (newQueryParam) {
          history.push({
            pathname: `/o/${props.applicationId}/b/${props.currentOpening.id}/tracker`,
            search: `filter=${newQueryParam}`,
          });
        } else {
          history.push({
            pathname: `/o/${props.applicationId}/b/${props.currentOpening.id}/tracker`,
          });
        }
      }
    }
  }, [props.filterObj[props.boardId]]);
  return <></>;
};
const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
  members: state.opening.members,
  openingMembers: state.opening.openingMembers,
  currentApplicant: state.opening.currentApplicant,
  applicantMembers: state.opening.applicantMembers,
  applicationId: state.opening.applicationId,
  clientId: state.opening.clientId,
  labels: state.opening.labels,
  filterObj: state.opening.boardFilterKeys || {},
});
export default connect(mapStateToProps)(FilterObject);
