import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { SLACK, SLACK_TOKEN_EXPIRE_WARNING } from '../../constant';
import SlackIntegratedContent from './SlackIntegratedContent';
import { Typography, Spin} from 'antd';
import { getOrgintegrations } from '../../lib/api';
import { addChatIntegration } from '../../redux/actions/opening/action';
import { Dispatch } from 'redux';
import SlackNotIntegratedContent from './SlackNotIntegratedContent';
import { Integration } from '../../lib/apiRes';

const { Text } = Typography;

interface SlackNotificationModalContentProps {
  integrations: Integration[];
  addIntegrations: (integration: any[]) => void;
}

const SlackNotificationModalContent: React.FC<SlackNotificationModalContentProps> = ({
  integrations,
  addIntegrations,
}) => {
  const [loading, setLoading] = useState(true);
  const [isSlackIntegrated, setIsSlackIntegrated] = useState(false);
  const [slackIntegrationMessage, setSlackIntegrationMessage] = useState("");

  

  useEffect(() => {
    const fetchIntegrations = async () => {
      try {
        const integration = await getOrgintegrations();
        addIntegrations(integration);
      } catch (error) {
        console.error("Failed to fetch integrations of organization", error);
      } finally {
        setLoading(false); // Set loading to false after the API calls are complete
      }
    };

    fetchIntegrations();
  }, [addIntegrations]);
  useEffect(() => {
    const slackIntegration = integrations?.find(
      (integration) => integration.app_name === SLACK
    );

    if (slackIntegration) {
      if (slackIntegration.is_valid_integration) {
        setIsSlackIntegrated(true); // Set Slack integration state to true
        setSlackIntegrationMessage("");
      } else {
        setIsSlackIntegrated(true); // Set Slack integration state to false
        setSlackIntegrationMessage(SLACK_TOKEN_EXPIRE_WARNING);
      }
    } else {
      setIsSlackIntegrated(false); // Set Slack integration state to false
      setSlackIntegrationMessage("");
    }
  }, [integrations]);

  return (
    <>

      {loading ? (
        <><div className="flex items-center px-4 border-b" style={{ height: 44 }}>
          <Text>Slack notification</Text>
        </div><div className='text-black p-4 overflow-y-scroll' style={{ height: "calc(100vh - 176px)" }}>
            <div className="flex items-center justify-center h-full">
              <Spin tip="Loading Slack integration status..." />
            </div>
          </div>
        </>
      ) : (
        isSlackIntegrated ? (
          <div><SlackIntegratedContent slackIntegrationMessage={slackIntegrationMessage}/></div>
        ) : (
          <><div className="flex items-center px-4 border-b" style={{ height: 44 }}>
            <Text>Slack notification</Text>
          </div><div className='text-black -mt-4 overflow-y-scroll py-4' style={{ height: "calc(100vh - 176px)" }}>
              <SlackNotIntegratedContent slackIntegrationMessage={slackIntegrationMessage}/>
             </div>
          </>
        )
      )}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  integrations: state.opening?.chatsIntegration ?? [],
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  addIntegrations: (integration: Integration[]) => dispatch(addChatIntegration(integration)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SlackNotificationModalContent);
