import React, { useState } from "react";
import {  Space, Upload } from "antd";
import type { UploadChangeParam, UploadFile } from "antd/es/upload/interface";
import { uploadAttachmentOfCandidate } from "../../../lib/api";
import { CANDIDATES } from "../../../constant";
import { connect } from "react-redux";
import {
  addAttachmentToCurrentCandidateInDb,
  addCDBAttachmentUploadStatus,
} from "../../../redux/actions/opening/action";
import { Dispatch } from "redux";
import { Attachment } from "../../../type/type";
import { throwSuccess } from "../../../service/throwError";
import { PaperClipOutlined } from "@ant-design/icons";
import { OrgInfo } from "../../../lib/apiRes";
import "../../../styles/component/style.css"
import { SecondaryButton } from "../../../ant/Button";

// Type definitions for component props
type UploadStatus = {
  uploading: boolean;
  fileList: UploadFile[];
  uploadResponse: any;
  uploadError: any;
};

type AddAttachmentProps = {
  currentCandidateInEditMode:any;
  addAttachmentToCurrentCandidateInDb(attachment:Attachment[]): void;
  addAttachmentUploadStatus: (status: UploadStatus) => void;
  org:OrgInfo,
  user:any,
};

// Main component to add attachments to a candidate in the pool
const AddAttachmentToCDBCandidate: React.FC<AddAttachmentProps> = (props) => {
  const [uploading, setUploading] = useState(false);

  // Handler for file changes
  const handleFileChange = async (info: UploadChangeParam<UploadFile<any>>) => {
    let updatedFileList = [...info.fileList];
    updatedFileList = updatedFileList.slice(-5); // Only keep the last 5 files


    setUploading(true);

    // Update the upload status in the Redux store
    props.addAttachmentUploadStatus({
      uploadResponse: "",
      uploadError: "",
      uploading: true,
      fileList: updatedFileList,
    });

    const formData = new FormData();

    // Append files to form data
    updatedFileList.forEach((file) => {
      if (file.originFileObj instanceof Blob) {
        formData.append("file", file.originFileObj);
      }
    });

    // Upload files to the server
    uploadAttachmentOfCandidate(formData, CANDIDATES, props.currentCandidateInEditMode.candidateId.id,props.org.id,
      props.user.user.id)
      .then((res) => {
        throwSuccess("Attachment uploaded successfully.");
        setTimeout(() => {
          props.addAttachmentUploadStatus({
            uploadResponse: "",
            uploadError: "",
            uploading: false,
            fileList: [],
          });
        }, 0);
        setTimeout(() => {
          props.addAttachmentToCurrentCandidateInDb(res);
        }, 100);
      })
      .catch(() => {
        // Handle upload error
      })
      .finally(() => {
        setUploading(false);
      });
  };

  // Prevent automatic upload
  const handleBeforeUpload = () => {
    return false;
  };

  // Adjust upload button width to full width
  const uploadButtons = document?.getElementsByClassName("ant-upload ant-upload-select");
  if (uploadButtons) {
    for (let i = 0; i < uploadButtons.length; i++) {
      const element = uploadButtons[i];
      if (element instanceof HTMLElement) {
        element.style.width = "100%";
      }
    }
  }

  return (
    
    <div className="w-full">
      <Space direction="vertical" style={{ width: "100%" }} size="large">
        <Upload
          className="w-full"
          showUploadList={false}
          beforeUpload={handleBeforeUpload}
          onChange={handleFileChange}
          fileList={[]}
        >
         <SecondaryButton className="w-full truncate left-align-button" disabled={uploading} icon={<PaperClipOutlined />} onClick={()=>{}} >
            Add attachment
          </SecondaryButton>
        </Upload>
      </Space>
    </div>
  );
};

// Map Redux state to component props
const mapStateToProps = (state: any) => ({
  currentCandidateInEditMode: state.opening.currentCandidateInEditMode,
  user:state.opening.loginUser,
  org:state.opening.currentOrg,

});

// Map Redux dispatch actions to component props
const mapDispatchToProps = (dispatch: Dispatch) => ({
  addAttachmentUploadStatus: (status: UploadStatus) => dispatch(addCDBAttachmentUploadStatus(status)),
  addAttachmentToCurrentCandidateInDb: (attachment: Attachment[]) =>
    dispatch(addAttachmentToCurrentCandidateInDb(attachment)),
  // attachmentLoader: () => dispatch(attachmentLoader()),
});

// Connect component to Redux store
export default connect(mapStateToProps, mapDispatchToProps)(AddAttachmentToCDBCandidate);
