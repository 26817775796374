import React from "react";
import { connect } from "react-redux";

//Redux
import { Dispatch } from "redux";
import { initComments } from "../../../redux/actions/opening/action";
import { ACTIVITY_TAG, EMAIL_TAG } from "../../../constant";
import {
  activity,
  comment,
  currentApplicant,
  InitComments,
  orgMember,
} from "../../../type/type";
import ShowActivity from "../activity/ShowCardActivity";

export type CommentProps = {
  currentApplicant: currentApplicant;
  initComments(comments: any, tag: string, applicant_id: string): void;
  comments: InitComments;
  members: orgMember;
  type: string;
};
const Comment = (props: CommentProps) => {
  let arr = props.comments[props.currentApplicant.id]
    ? Object.values(props.comments[props.currentApplicant.id]).sort(
        (prev: comment | activity | any, next: comment | activity | any) =>
          prev.tag == EMAIL_TAG && next.tag == EMAIL_TAG
            ? prev.message.created_at < next.message.created_at
              ? 1
              : -1
            : prev.tag == EMAIL_TAG
            ? prev.message.created_at < next.created_at
              ? 1
              : -1
            : next.tag == EMAIL_TAG
            ? prev.created_at < next.message.created_at
              ? 1
              : -1
            : prev.created_at < next.created_at
            ? 1
            : -1
      )
    : [];
  return (
    <React.Fragment>
      {props.comments[props.currentApplicant.id] &&
        arr.map((comment: any) => {
          switch (comment.tag) {
            case ACTIVITY_TAG:
              if (props.type === ACTIVITY_TAG)
                return (
                  <div className="ml-3 mt-2">
                    <ShowActivity activity={comment} />
                  </div>
                );
              break;
            default:
              break;
          }
        })}
    </React.Fragment>
  );
};
const mapStateToProps = (state: any) => ({
  currentApplicant: state.opening.currentApplicant,
  comments: state.opening.comments,
  members: state.opening.members,
});
const mapPropsToState = (dispatch: Dispatch) => ({
  initComments: (comments: any, tag: string, applicant_id: string) =>
    dispatch(initComments(comments, tag, applicant_id)),
});
export default connect(mapStateToProps, mapPropsToState)(Comment);
