import React from "react";

type inputProps = {
  type?: string;
  applicationId?: string;
  setInputValue?: (e: any) => void;
  defaultValue?: string;
  isDisabled?: boolean;
  placeholder?: string;
  border?: string;
};
const Input = (props: inputProps) => {  
  return (
    <>
      <input
        type={props.type ? props.type : "text"}
        name=""
        min={0}
        value={props.defaultValue}
        data-testid={props.type}
        onChange={(e) =>
          e && props.setInputValue ? props.setInputValue(e) : null
        }
        onWheel={(e: any) => e.target.blur()}
        className={` ${
          props.isDisabled
            ? "customer-form-value-preview"
            : props.border
            ? props.border == "red"
              ? "border  border-tacitbase-primary1  focus:outline-none rounded input-box"
              : "input-box"
            : "input-box"
        } `}
        disabled={props.isDisabled}
        placeholder={props.placeholder ? props.placeholder : ""}
      />
    </>
  );
};

export default Input;
