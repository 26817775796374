import React, { useEffect, useRef, useState } from "react";
import { EventAttachment } from "../../../../lib/apiRes";
import AttachmentInEvent from "./EventAttachment";
import {
  Attachment,
  CardAttachment,
  Scope,
  currentApplicant,
} from "../../../../type/type.d";
import { connect } from "react-redux";
import { Overlay } from "../../../hook/Overlay";
import { usePopper } from "react-popper";
import CloseIcon from "../../../../assets/Close";
import FavoriteFilledIcon from "../../../../assets/FavoriteFilled";
import { TrixEditor } from "react-trix";
import DescriptionIcon from "../../../../assets/Description";
type descProps = {
  setDesc: (e: any) => void;
  value?: string;
  setPrevAttachment: (arr: any) => void;
  prevAttachments?: EventAttachment[];
  removeAttachment?: (id: string) => void;
  file?: (file: any) => void;
  cardAttachments: CardAttachment;
  currentApplicant: currentApplicant;
};
const AddDescription = (props: descProps) => {
  let refer = useRef(null);
  const [fileName, setFileName] = useState<string[]>([]);
  const [loadings, setLoadings] = useState<boolean[]>([]);
  const [showPopup, setShowPopup] = useState(false);
  const [CardDesc, showCardDescInput] = useState(false);
  let [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>();
  let [popperElement, setPoperElement] = useState<HTMLDivElement | null>();
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 2.25],
        },
      },
      {
        name: "flip",
        options: {
          fallbackPlacements: ["top", "right"],
        },
      },
    ],
  });
  const fetchAttachment = async (
    remoteFileUrl: string,
    fileName: string,
    i: number
  ) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[i] = true;
      return newLoadings;
    });

    const response = await fetch(remoteFileUrl);
    const blob = await response.blob();
    setTimeout(
      () =>
        setLoadings((prevLoadings) => {
          const newLoadings = [...prevLoadings];
          newLoadings[i] = false;
          return newLoadings;
        }),
      2000
    );

    const newFile = new File([blob], fileName, { type: blob.type });
    props.file?.((obj: any) => [...obj, newFile]);
    setFileName((prev) => [...prev, fileName]);
    setShowPopup(false);
    // Now 'file' is a File object with the fetched content
  };
  const popupRef = useRef(null);

  const removeFile = (fileNameToRemove: string) => {
    const updatedArray = fileName.filter((item) => item !== fileNameToRemove);
    // Update the state with the new array
    setFileName(updatedArray);
    props.file?.(updatedArray);
  };

  const removeAttachment = (attachementId: string) => {
    props.removeAttachment?.(attachementId);
    props.setPrevAttachment((prev: any) =>
      prev.filter((attachment: Attachment) => attachementId != attachment.id)
    );
  };
  const cardAttachments = props.cardAttachments[props.currentApplicant.id]
    ? props.cardAttachments[props.currentApplicant.id]
    : [];
  const filteredAttachments = cardAttachments.filter(
    (attachment) => !fileName.includes(attachment.name)
  );
  const handleChange = (html: string) => {
      props.setDesc((prev: any) => ({
      ...prev,
      description: html,
    }));
  };
  var listner = (event: any) => {
    if (event.attachment.file) {
return true    }
  };
  const setEditorReady = (editor: any) => {
    editor.insertString("");
    document.addEventListener("trix-attachment-add", listner, false);
  };
  const showDescHandle = (e: any) => {
    /* check if value has a hyperlink then link open to next tab and does not open editior  */
    if (e.target.nodeName != "A") {
      e.stopPropagation();
      showCardDescInput(!CardDesc);
    }
  };
  useEffect(() => {
    props.value ? showCardDescInput(true) : showCardDescInput(false);
  }, [props.value]);
  // Removing attachment option from trix editor
  document.addEventListener("trix-initialize", function() {
    const fileTools = document.querySelector(".trix-button-group--file-tools");
    fileTools?.remove();
});
  return (
    <div className="flex flex-col space-y-2  w-full">
      <div className="input-label">Description</div>{" "}
      <div className="flex w-full  space-x-2  items-start">
        <div className="w-7/12">
          {!CardDesc && (
            <button
              data-testid="show-desc"
              onClick={showDescHandle}
              className="bg-gray-200 hover:bg-gray-200 w-full space-x-1      bg-opacity-60 py-1.5 px-3 text-xs font-medium rounded align-middle flex items-center  "
            >
              <DescriptionIcon />
              <div> Add Description</div>
            </button>
          )}
          <div className="">
            {" "}
            {props.value && (
              <TrixEditor
                onChange={handleChange}
                onEditorReady={setEditorReady}
                mergeTags={[]}
                value={props.value}
                placeholder="Add a more detailed description…"
                className=" w-full text-sm trix-content resize-none rounded bg-white p-2  placeholder-gray-600"
              />
            )}
            {CardDesc && !props.value && (
              <TrixEditor
                onChange={handleChange}
                onEditorReady={setEditorReady}
                mergeTags={[]}
                value={props.value}
                placeholder="Add a more detailed description…"
                className=" w-full text-sm trix-content resize-none rounded bg-white p-2  placeholder-gray-600"
              />
            )}
          </div>
        </div>
        <div className="flex flex-col items-start w-5/12 space-y-2  ">
          <div className="w-full flex space-x-1 h-max">
            {" "}
            <div className="w-1/2">
              <div
                ref={setReferenceElement}
                role="button"
                tabIndex={0}
                onKeyDown={() => {}}
              >
                <button
                  className="bg-gray-200 hover:bg-gray-200 w-full space-x-1      bg-opacity-60 py-1.5 px-3 text-xs font-medium rounded align-middle flex items-center "
                  onClick={() => (setShowPopup(!showPopup), setLoadings([]))}
                  data-testid="suggestSlotDataTestBtn"
                >
                  <div className="h-4">
                    <FavoriteFilledIcon />
                  </div>
                  <div>Suggested</div>
                </button>
              </div>
              {showPopup ? (
                <Overlay
                  onClose={() => setShowPopup(false)}
                  scope={Scope.Dialog}
                  reference={popupRef}
                >
                  <div
                    ref={setPoperElement}
                    style={styles.popper}
                    {...attributes.popper}
                    className=""
                  >
                    <div
                      className="w-full text-xs border bg-white shadow-sm z-10   rounded"
                      ref={refer}
                    >
                      <div
                        className=" popupDiv w-72  min-h-min max-h-32 overflow-y-scroll   p-2 bg-white  top-9 rounded shadow-2xl z-50 "
                        ref={popupRef}
                      >
                        <div className=" flex flex-col space-y-1 w-full">
                          {" "}
                          {filteredAttachments?.length > 0 ? (
                            filteredAttachments?.map((attachment, i) => (
                              <button
                                className="p-1.5  flex items-center justify-between truncate bg-gray-300 bg-opacity-25 hover:bg-gray-300 hover:bg-opacity-40 rounded text-sm cursor-pointer"
                                onClick={() =>
                                  fetchAttachment(
                                    attachment.presignedLink,
                                    attachment.name,
                                    i
                                  )
                                }
                                key={attachment.id}
                              >
                                {loadings[i] ? (
                                  <div className="flex space-x-2">
                                    <div className="flex justify-end items-end">
                                      <div
                                        style={{
                                          borderTopColor:
                                            "rgba(15, 10, 13,0.7)",
                                        }}
                                        className="w-4 h-4 border border-gray-300   border-solid rounded-full animate-spin"
                                      ></div>
                                    </div>{" "}
                                    <div className="">{attachment.name}</div>
                                  </div>
                                ) : (
                                  <div className="flex space-x-2">
                                    <div className="">{attachment.name}</div>
                                  </div>
                                )}
                              </button>
                            ))
                          ) : (
                            <div className="p-1.5  flex items-center justify-between truncate bg-gray-300 bg-opacity-25 hover:bg-gray-300 hover:bg-opacity-40 rounded text-sm cursor-pointer">
                              No attachments available.
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Overlay>
              ) : null}
            </div>
            <div className="w-1/2">
              <AttachmentInEvent
                refer={refer}
                setFileName={(value) => setFileName(value)}
                setPrevAttachment={(data) => props.setPrevAttachment(data)}
                prevAttachments={props.prevAttachments}
                file={(file) => (props.file ? props.file(file) : null)}
                removeAttachment={(attachmentId) =>
                  props.removeAttachment
                    ? props.removeAttachment(attachmentId)
                    : null
                }
              />
            </div>
          </div>
          <div className="w-full flex flex-col h-max space-y-1">
            {fileName.length > 0
              ? fileName.map((file: string, i: number) => (
                  <div
                    className=" input-box  h-max   flex items-center justify-between space-x-1 text-sm"
                    key={i}
                  >
                    {" "}
                    <div className=" truncate text-xs ">{file} </div>
                    <div>
                      {" "}
                      <button onClick={() => removeFile(file)}>
                        <CloseIcon size="9" />
                      </button>
                    </div>
                  </div>
                ))
              : null}
            {props.prevAttachments ? (
              props.prevAttachments.map((attachement, i) => (
                <div
                  className=" input-box  h-max   flex  items-center justify-between space-x-1 text-sm  "
                  key={i}
                >
                  {" "}
                  <div className=" truncate text-xs">{attachement.name} </div>
                  <div>
                    {" "}
                    <button onClick={() => removeAttachment(attachement.id)}>
                      <CloseIcon size="9" fill="red" />
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <></>
            )}
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  currentApplicant: state.opening.currentApplicant,

  cardAttachments: state.opening.attachment,
});

export default connect(mapStateToProps)(AddDescription);
