// import { activity, comment, MailPreview } from "../../type/type";

import { EMAIL_TAG } from "../../constant";
import { activity,comment, MailPreviewWithTag } from "../../type/type"


export function init_comments(state: any, action: any) {
  const emails: { [key: string]: MailPreviewWithTag | comment | activity }={};
  if(action.tag === EMAIL_TAG){
    action.data
    ? action.data.map((comment: MailPreviewWithTag | comment | activity) => {      
      if ('message' in comment) {
        comment.tag = action.tag;
        emails[comment.message.id] = comment;
      } else {
        comment.tag = action.tag;
        emails[comment.id] = comment;
      }
      })
    : emails;
  }
  const comments: { [key: string]: MailPreviewWithTag | comment | activity }={};
    action.data
    ? action.data.map((comment: MailPreviewWithTag | comment | activity) => {      
      if ('message' in comment) {
        comment.tag = action.tag;
        comments[comment.message.id] = comment;
      } else {
        comment.tag = action.tag;
        comments[comment.id] = comment;
      }
      })
    : comments;
  return {
    ...state,
    comments: {
      ...state.comments,
      [action.applicantId]: {
        ...state.comments[action.applicantId],
        ...comments,
        candidateEmails:emails?emails :{}
      },
    },
  };
}

export function update_comment(state: any, action: any) {
  return {
    ...state,
    comments: {
      ...state.comments,
      [action.data.applicant_id]: {
        ...state.comments[action.data.applicant_id],
        [action.data.id]: {
          ...state.comments[action.data.applicant_id][action.data.id],
          body: action.data.body,
        },
      },
    },
  };
}

export function save_comment(state: any, action: any) {
  return {
    ...state,
    comments: {
      ...state.comments,
      [action.data.applicant_id]: {
        ...state.comments[action.data.applicant_id],
        [action.data.id]: {
          ...action.data,
          tag: action.tag,
        },
      },
    },
    boardList: {
      ...state.boardList,
      [action.data.list_id]: {
        ...state.boardList[action.data.list_id],
        applicants: state.boardList[action.data.list_id].applicants.map(
          (applicant: any) =>
            applicant.id === action.data.applicant_id
              ? {
                  ...applicant,
                  comment_count: applicant.comment_count + 1,
                }
              : applicant
        ),
      },
    },
  };
}
