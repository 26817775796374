import React from "react"
import { connect } from "react-redux"
import { Dispatch } from "redux"
import { logoutUserAc } from "../../redux/actions/opening/action"
import { RouteComponentProps, withRouter } from "react-router"
import { Space } from "antd"

import {
    LogoutOutlined,
} from "@ant-design/icons"
import { LabeledIconButton } from "../../ant/Button"
import { logoutFromAllTabs } from "../../utils/logoutFromAllTabs"

type Props = {
    logoutUserAc(): void,
    wsConnectionObject: WebSocket,
} & RouteComponentProps

const Logout: React.FC<Props> = ({logoutUserAc, wsConnectionObject}) => {
    const logout = () => {
        logoutUserAc()
        logoutFromAllTabs()
        let ws = wsConnectionObject
        if (ws && ws.readyState !== WebSocket.CLOSED) {
          ws?.close()
        }
      
      }
    return (
        <>
            <Space>
                <LabeledIconButton label="Sign out" type="primary" icon={<LogoutOutlined   />} onClick={logout} />
            </Space>
        </>
    )
}

const mapStateToProps = (state: any) => ({
    wsConnectionObject: state.opening.wsConnection,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
    logoutUserAc: () => dispatch(logoutUserAc()),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Logout))
