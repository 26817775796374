import React from "react";
import {  useRef, useState } from "react";
import MultipleFile from "../../images/MultipleFile.png";
import {
  InfoCircleOutlined,
  DesktopOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Button, Empty, Progress} from "antd";
import { ResumeParserAttachment, createQuickCandidate } from "../../lib/api";
import {  addCandidateInPool } from "../../redux/actions/opening/action";
import { throwFrontEndError, throwSuccess } from "../../service/throwError";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import LoadingIndicator from "../../components/analyticsVersion1/LodingIndicator";
import { FormBuilder, TableColumn } from "../../type/type";
import ResumeConversiontablePool from "./ResumeConversiontablePool";
import { CANDIDATE_REQUIRED_FIELD, FILE_SIZE_LIMIT, File_SIZE_LIMIT_MB, PRIVATE_MODE, RESUME_MEDIUM, regexPatterns } from "../../constant";

interface ResumeConversionProps {
    addCandidateInPool: (candidate: any) => void;
  handleClose:()=>any;
  currentTrackerForm: FormBuilder;
  currentTrackerJd:any;
  candidateDBTableHeader:any
}

function ResumeConversionPool(props: ResumeConversionProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [dragging, setDragging] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [downloadVisible, setDownloadVisible] = useState(false); 
  const [apiResponse, setApiResponse] = useState<any>([]);
  const [editedtableResponse, setEditedtableResponse] = useState<any>(apiResponse);
  const [createCandidateButton, setCreateCandidateButton] = useState(false); 

//   type candidate={

//   }
const CandidatesCustomFields :TableColumn[]=props.candidateDBTableHeader.filter((table:TableColumn)=>table.custom)

  const handleDataChange = (newData: any[]) => {
    setEditedtableResponse(newData);
  };

  const handleCreateButton = (value:boolean) => {
    setCreateCandidateButton(value)
  };

  const AddCandidate = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true)
        // Initialize customs object
        const customs: Record<string, any> = {};

        // Iterate through the keys in editedtableResponse[0]
        Object.keys(editedtableResponse?.[0]).forEach((key) => {
          // Check if the key exists in CandidatesCustomFields
          const customField = CandidatesCustomFields.find(
            (field: TableColumn) => field.name === key
          );
    
          // If it exists, add it to the customs object
          if (customField) {
            customs[key] = editedtableResponse?.[0][key];
          }
        });
    
    // const candidate = editedtableResponse?.[0];
    const candidate1 = {...editedtableResponse?.[0],customs,
         source:{
          jd:props.currentTrackerJd.id,
          medium:RESUME_MEDIUM
      },
    }
    
  const emptyFields = CANDIDATE_REQUIRED_FIELD.filter(
    (field) => !candidate1?.[field] || candidate1?.[field].trim().length === 0
  );
  
  if (emptyFields.length > 0) {
    const errorMessage = `Please fill the required fields: ${emptyFields.join(", ")}.`;
    throwFrontEndError(errorMessage);
    setCreateCandidateButton(true);
    setLoading(false);
    return;
  }
 
  
  const urlFields = Object.keys(regexPatterns);
  for (const field of urlFields) {
    const value = candidate1?.[field];
    if (
      value &&
      value.trim().length > 0 &&
      !regexPatterns[field].test(value)
    ) {
      throwFrontEndError(
        `Please provide a valid URL for ${field.replace("_", " ")}.`
      );
      setCreateCandidateButton(true)
      setLoading(false)

      return;
    }
    else{
    setCreateCandidateButton(false)
    setLoading(true)
    }
  }

  try {
    const res = await createQuickCandidate(PRIVATE_MODE,candidate1);
    setLoading(false)
    props.addCandidateInPool(res);
    throwSuccess("Candidate added successfully");
    handleCancel();
    props.handleClose();
  } catch (error) {
    handleCancel();
    setLoading(false)
    props.handleClose();
  }
};
  

  // Function to handle drag leave event
  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    let validFileType = false;
    let validFiles = [];

    for (let i = 0; i < files.length; i++) {
      if (isFileTypeValid(files[i].type)) {
        validFileType = true;
        if (files[i].size <= FILE_SIZE_LIMIT) {
          validFiles.push(files[i]);
        } else {
            throwFrontEndError(`File size exceeds the limit of ${File_SIZE_LIMIT_MB} MB.`);
        }
      } else {
        throwFrontEndError(
          "File format is not supported, please provide correct file format."
        );
      }
    }
    setDragging(validFileType);

    if (validFiles.length > 0) {
      const formData = new FormData();
      validFiles.forEach((file) => {
        formData.append(`resumes`, file);
      });
      setSelectedFiles(validFiles);
      setDragging(validFileType);
      uploadFiles(formData);
    }

    setDragging(validFileType);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    let validFileType = false;
    for (let i = 0; i < e.dataTransfer.items.length; i++) {
      if (isFileTypeValid(e.dataTransfer.items[i].type)) {
        validFileType = true;
        break;
      }
    }
    setDragging(validFileType);
  };

  const isFileTypeValid = (fileType: string) => {
    return (
      fileType === "application/pdf" ||
      fileType === "application/msword" ||
      fileType ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      fileType === "text/plain"
    );
  };

  // Function to handle file change event

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files) {
      const files = Array.from(e.target.files);
      let validFileArray = [...selectedFiles];

      for (let i = 0; i < files.length; i++) {
        if (isFileTypeValid(files[i].type)) {
          if (files[i].size <= FILE_SIZE_LIMIT) {
            validFileArray.push(files[i]);
            setSelectedFiles(validFileArray);
          } else {
            throwFrontEndError(`File size exceeds the limit of ${File_SIZE_LIMIT_MB} MB.`);
          }
        } else {
            throwFrontEndError("File format is not supported.");
        }
      }

      if (validFileArray.length > 0) {
        const formData = new FormData();
        validFileArray.forEach((file) => {
          formData.append(`resumes`, file);
        });
        setSelectedFiles(validFileArray);
        try {
          uploadFiles(formData);
        } catch {
          setUploading(false);
          setDownloadVisible(false);
        }
      }
    }
  };
  

  const uploadFiles = async (formData: FormData | any) => {
    setUploading(true);
    try {
      const response = await ResumeParserAttachment(formData);
    //   throwSuccess("File uploaded successfully.");

      setTimeout(() => {
        setUploading(false);
        setApiResponse(response);
        setDownloadVisible(true);
      }, 500);

    } catch (error) {
      setUploading(false);
      setDownloadVisible(false);
    }
    
  };

  const handleCancel = () => {
    setSelectedFiles([]);
    setDownloadVisible(false);

  };


  return (
    <div className="h-full w-full">
      {selectedFiles?.length === 0 && !downloadVisible && !uploading && (
        <div className="">
          <div
            className="flex flex-col items-center w-full  border border-dashed border-tacitbase-tertiary1 border-opacity-20 pb-8"
            style={{ backgroundColor: "#F9F9F9" }}
          >
            <div
              className="flex w-full  rounded-md pt-4 pl-4 pr-4"
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
              id="drop-zone"
            >
              <div className="w-full h-full">
                <div
                  className="w-full h-full flex items-center justify-center"
                  tabIndex={0}
                  role="button"
                  onClick={() => fileInputRef.current?.click()}
                  onKeyDown={() => {}}
                >
                  <div className="flex flex-col justify-center items-center space-y-2">
                   
                    <img
                      src={MultipleFile}
                      alt="Your description"
                      className="h-32 object-cover items-center justify-center"
                    />
                    <div className="text-tacitbase-tertiary1 text-opacity-50 flex items-center justify-center flex-col">
                      {dragging
                        ? "Drop that file down low"
                        : "Drag and drop a file for single resume "}
                      <br />
                      <span>
                        {dragging ? (
                          "Drop that file down low"
                        ) : (
                          <InfoCircleOutlined />
                        )}
                        {dragging
                          ? "Drop that file down low"
                          : `Max file size ${File_SIZE_LIMIT_MB} MB`}
                      </span>
                    </div>
                    <div className="flex items-center justify-center text-tacitbase-tertiary1 text-opacity-25 space-x-2 ">
                      <div>
                        <hr className="w-16 border border-tacitbase-tertiary1 border-opacity-30" />
                      </div>
                      <div className="text-tacitbase-tertiary1 text-opacity-40">
                        OR
                      </div>
                      <div>
                        <hr className="w-16 border border-tacitbase-tertiary1 border-opacity-30" />
                      </div>
                    </div>
                    <div className="">
                      <input
                        type="file"
                        ref={fileInputRef}
                        id="FileInput"
                        style={{ display: "none" }}
                        onChange={(event) => handleFileChange(event)}
                        accept=".pdf,.docx,.doc,.txt"
                      />
                      <Button
                        icon={<DesktopOutlined />}
                        type="primary"
                        style={{ width: "315px", height: "40px" }}
                      >
                        Select files
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="mt-4 text-lg font-bold">The resume parser may contain errors; please double-check candidate details.</div> */}

          </div>
        </div>
      )}

      {uploading && (
        <div
          className="flex flex-col items-center justify-center py-24 space-y-3 border border-dashed border-tacitbase-tertiary1 border-opacity-20"
          style={{ backgroundColor: "#F9F9F9" }}
        >
          <div className=" mx-6 my-2">
            <LoadingIndicator
              loader_message={
                <div>
                  <div style={{color:'black'}}>
                    {selectedFiles[0].name.charAt(0).toUpperCase() + selectedFiles[0].name.slice(1)}

                    </div>
                  {/* <div>Stay patient..</div> */}
                  <div className="mt-2 mb-1"> We are uploading your resume.</div>
                </div>
              }
            />

          </div>
        </div>
      )}

      {downloadVisible &&
        !uploading &&
        selectedFiles.length > 0 &&
        apiResponse?.length > 0 &&
        apiResponse[0]?.metadata.this_document_is_resume == true && (
          <div className="flex flex-col w-full " >
            <ResumeConversiontablePool
                      data={apiResponse}
                      onDataChange={handleDataChange}
                    //   onValidationError={(val1) => { handleCreateButton(val1); } }
                      sendValidationStatus={(value) => handleCreateButton(value)} 
                      setValidationStatus={(val1) => { handleCreateButton(val1); } }            />
            <div className=" mt-4 space-x-2 flex flex-row justify-end">
              <div>
                <Button onClick={handleCancel}>Cancel</Button>
              </div>
              <div>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={AddCandidate}
                  disabled={createCandidateButton}
                  loading={loading}
                >
                  Add candidate
                </Button>
              </div>
            </div>
          </div>
        )}

      {downloadVisible &&
        !uploading &&
        selectedFiles.length > 0 &&
        (apiResponse?.length == 0 ||
          apiResponse[0]?.metadata.this_document_is_resume == false) && (
          <><div className="items-center justify-center flex flex-col px-4 py-12 border border-dashed border-tacitbase-tertiary1 border-opacity-20" style={{ backgroundColor: "#F9F9F9" }}>
                  <div
                      style={{ marginBottom: 10 }}
                      className="items-center justify-center "
                  >
                      <Empty
                          image={<ExclamationCircleOutlined
                              style={{
                                  fontSize: 80,
                                  color: "#FFC53D",
                                  marginBottom: "0",
                              }} />}
                          description={<div className="flex flex-col items-center" style={{ color: '#626161' }}>
                              <div>This doesn&apos;t seem to be a resume,</div>
                              <div>Please upload your correct resume again.</div>
                          </div>} />
                  </div>
              </div><div className=" mt-4 space-x-2 flex flex-row justify-end">
                      <div>
                      <Button type="primary" onClick={handleCancel}>Restart</Button>
                      </div>
                  </div></>
          
        )}

      {!downloadVisible && !uploading && selectedFiles.length > 0 && (
        <><div className="flex flex-col justify-center items-center h-full border border-dashed border-tacitbase-tertiary1 border-opacity-20 " style={{ backgroundColor: "#F9F9F9",color:'#626161',paddingTop:'59px',paddingBottom:'59px' }}>
                  <Progress
                      type="circle"
                      percent={60}
                      status="exception"
                      style={{ alignItems: "center",}}
                      size={80} />
                  <div className="mt-4">Oops! Something went wrong <br />Please upload your resume again.</div>

              </div><div className=" mt-4 space-x-2 flex flex-row justify-end">
                      <div>
                          <Button type="primary" onClick={handleCancel}>Restart</Button>
                      </div>
                  </div></>
        
      )}
    </div>
  );
}

const mapPropsToState = (dispatch: Dispatch) => ({
    addCandidateInPool: (candidate: any) => dispatch(addCandidateInPool(candidate)),
});
const mapStateToProps = (state: any) => ({
    currentTrackerForm: state.opening.currentTrackerForm,
    currentTrackerJd: state.opening.currentTrackerJd,
    candidateDBTableHeader: state.opening.candidateDBTableHeader,

  });
export default connect(mapStateToProps, mapPropsToState)(ResumeConversionPool);
