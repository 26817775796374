import React, { useState } from "react";
import { Modal, Input, List, Typography, Empty, Divider } from "antd";
import { Candidate } from "../../../type/type";
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";

type MailTagPreviewType = {
  recipients: Candidate[];
  previewTag: string;
  onClose: () => void;
};

const MailTagPreview = (props: MailTagPreviewType) => {
  const [searchedLabel, setSearchedLabel] = useState<string>("");

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchedLabel(event.target.value);
  };

  const filteredRecipients = searchedLabel.trim()
    ? props.recipients.filter((recipient) => {
        const searchLabel = searchedLabel.toUpperCase();
        return (
          recipient.first_name.toUpperCase().includes(searchLabel) ||
          recipient.last_name.toUpperCase().includes(searchLabel) ||
          recipient.email.toUpperCase().includes(searchLabel)
        );
      })
    : props.recipients;

  return (
    <Modal
      title={capitalizeFirstLetter("Preview mail merge tag")}
      visible={true}
      onCancel={props.onClose}
      bodyStyle={{
        maxHeight: '500px',
        // height:'300px',
        overflow: 'hidden', // Prevent the whole body from scrolling
      }}
      style={{
        padding: 0, // Remove any padding around the modal
      }}
      // footer={[
      //   <Button key="ok" type="primary" onClick={props.onClose}>
      //     OK
      //   </Button>,
      // ]}
      footer={null}
    >
      <div>
        {/* Fixed Search Input */}
        <Input
          placeholder="Search for recipient or merge tag"
          onChange={handleSearch}
          value={searchedLabel}
          style={{
            marginBottom: "8px",
            marginTop:'4px',
            borderRadius: 4, // Ensure no extra margins or rounded corners
          }}
        />

        {/* Scrollable recipient list */}
        <div
          style={{
            maxHeight: "400px", // Define the scrollable area
            overflowY: "auto", // Make the recipient list scrollable
          }}
        >
           <div style={{ display: "flex",marginTop:'16px'}}>
           <div style={{ width: "70%" }}>
            <Typography.Text strong style={{fontSize:'14.5px'}}>Recipient</Typography.Text>
            </div>
            <div style={{ width: "30%" }}>
            <Typography.Text strong style={{fontSize:'14.5px'}}>First Name</Typography.Text>
          </div>
          </div>
          <Divider className="mt-2 -mb-1"/>
          {filteredRecipients.length > 0 ? (
            <List
              dataSource={filteredRecipients}
              renderItem={(recipient) => (
                <List.Item  style={{
                  padding: "8px 16px",
                  transition: "background-color 0.3s ease",
                  cursor: "pointer",
                }}
                onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#EFEFEF")} // Hover effect
                onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "transparent")} // Reset on mouse leave
              >
                  <div style={{ width: "70%" ,paddingRight:'16px'}}>
                    <Typography.Text strong ellipsis>{`${recipient.first_name} ${recipient.last_name}`}</Typography.Text>
                    <br />
                    <Typography.Text ellipsis>{recipient.email}</Typography.Text>
                  </div>
                  <div style={{ width: "30%" }}>
                    <Typography.Text ellipsis>{recipient[props.previewTag]}</Typography.Text>
                  </div>
                </List.Item>
              )}
            />
          ) : (
            <Empty description="No recipients found"  className="mt-8px"/>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default MailTagPreview;
