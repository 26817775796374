import React from "react";
import PopupTitle from "../../common/popupTitle/PopupTitle";

import { BoardLabelProps } from "../../../type/sidePanalSlider";
import SettingsPage from "./MainCalSettingsPage";

// Api's

const CalSetting = (props: BoardLabelProps) => {
  return (
    <React.Fragment>
      <div className="">
        <PopupTitle
          PopupTitle="Calendar Settings"
          font="text-base font-medium text-black"
          close={props.close}
          back={props.prev}
        />
        {/* <BoardLabelInput flag={true} close={() => props.close} /> */}
        <SettingsPage />
      </div>
    </React.Fragment>
  );
};
export default CalSetting;
