import React from "react"
import { connect } from "react-redux"
import { CandidateReview, Scope } from "../../type/type.d"

import { addLabelsToMultipleReviews } from "../../redux/actions/opening/action"
import { Dispatch } from "redux"

import { Popconfirm, Typography } from "antd"
import { Overlay } from "../hook/Overlay"
const { Text, Paragraph } = Typography

const ClosedReviewPopup = (props: {
    selectedReview: CandidateReview,
    deleteResource: () => void,
    popupElement: React.ReactNode
}) => {
    const closedReviewPopupContent = (record: CandidateReview) => <>
        <Paragraph>
            Are you sure you want to close the review{' '}
            <div><Text strong>{record.candidates.first_name}</Text>{' '} <Text strong>{record.candidates.last_name}</Text>?</div>
        </Paragraph>
        <div>{`This action is irreversible.`}</div>
    </>

    return (
        <Popconfirm
            title={"Close review"}
            description={<Overlay onClose={() => { }} scope={Scope.Popover}>{closedReviewPopupContent(props.selectedReview)}</Overlay>
            } onConfirm={props.deleteResource}
            okText="Yes"
            cancelText="No"
            placement="bottom"
            trigger={'click'}
            className="w-full"
        >
            {props.popupElement}
        </Popconfirm>
    )
}
const mapStateToProps = (state: any) => ({
    applicationId: state.opening.applicationId,
    reviewLabels: state.opening.reviewLabels,
    candidatesInReview: state.opening.candidatesInReview,
})
const mapPropsToState = (dispatch: Dispatch) => ({
    addLabelsToMultipleReviews: (data: any) =>
        dispatch(addLabelsToMultipleReviews(data)),

})
export default connect(mapStateToProps, mapPropsToState)(ClosedReviewPopup)
