export const isMainProductURL = (text: string) => {
    const pattern: RegExp = /^\/o\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(\/|$)/;

    return pattern.test(text)
}

export const isCardURL = (text: string) => {
    const pattern: RegExp = /^\/o\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}\/b\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}\/c\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(\/|$)/;

    return pattern.test(text)
}

export const isBoardURL = (text: string) => {
    const pattern: RegExp = /^\/o\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}\/b\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(\/|$)/;

    return pattern.test(text)
}

export const isOnBoardingURL = (text: string) => {
    const pattern: RegExp = /^\/(register\/verification\/email\/[0-9a-fA-F]*|register\/verification\/phone|register\/setup\/username|register|o(?:\/(start-setup|workspace|choose-plan|subscription-expired)|$)|contact-admin|create-org)(\/|$)/
    return pattern.test(text)
}
export const isPublicRoute = (text: string) => {
    return text === '/register' || text === '/login'|| text === '/onboarding/:verificationId'
}