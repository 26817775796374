import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import Read from "../../../Read";
import {
  initMembership,
  removeMemberFromOpening,
} from "../../../redux/actions/opening/action";
import {
  currentApplicant,
  KeyWithArray,
  loginUser,
  Opening,
  OpeningMembers,
  openingMemberType,
  orgMember,
} from "../../../type/type.d";
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";
import { searchResultOfMember } from "../../candidateDB/hook/filterMember";
import MemberAccessPreview from "../../common/MemberAccessPreview";
import MemberlistOfService from "../../common/MemberlistOfService";
import MembersOfServicesPopup from "../../common/MembersOfServicesPopup";
import BoardMemberAction from "../memberProfile/memberActions/BoardMemberAction";
import { SEARCH_IN_BOARD_MEMBER } from "../../../constant"
export type ShowMemberProps = {
  click: (e: boolean) => void;
  currentOpening: Opening;
  members: orgMember;
  openingMembers: OpeningMembers;
  currentApplicant: currentApplicant;
  removeMember(memberID: string, applicantID: string): void;
  refer: any;
  applicantMembers: KeyWithArray;
  initMembers(members: any): void;
  applicationId: string;
  clientId: string;
  loginUser: loginUser;
};
const ShowMember = (props: ShowMemberProps) => {
  let refer = useRef(null);
  const [searchedMember, getSearchedMember] = useState("");
  const [member, setMember] = useState<openingMemberType[]>(
    props.openingMembers[props.currentOpening.id]
  );
  useEffect(() => {
    let orgMembers: openingMemberType[];
    if (searchedMember.trim()) {
      orgMembers = searchResultOfMember({
        serviceMembers: props.openingMembers[props.currentOpening.id],
        members: props.members,
        searchChar: searchedMember,
      });
      setMember(orgMembers);
    } else {
      setMember(props.openingMembers[props.currentOpening.id]);
    }
  }, [searchedMember]);

  useEffect(() => {
    setMember(props.openingMembers[props.currentOpening.id]);
  }, [props.openingMembers[props.currentOpening.id]]);

  const searchKeyword = (e: any) => {
    getSearchedMember(e.target.value);
  };

  if (!props.members) return null;

  return (
    <React.Fragment>
      <MembersOfServicesPopup
        closePopup={(e: any) => props.click(e)}
        searchKeyword={(e: string) => searchKeyword(e)}
      >
        {member.length > 0 ? (
          member.map(
            (member: openingMemberType) =>
              member && props.members[member.id]?.registrations[0].data.activated && (
                <MemberlistOfService key={member.id}>
                  <MemberAccessPreview member={member} />
                  <Read access={props.currentOpening.access} type={true}>
                    {props.loginUser.user.id != member.id ? (
                      <BoardMemberAction
                        selectedMember={member}
                        refer={refer}
                      />
                    ) : null}
                  </Read>
                </MemberlistOfService>
              )
          )
        ) : (
          <div className="text-xs text-gray-700 bg-gray-100 ml-2 rounded-default mb-2 px-2 py-2">
              {capitalizeFirstLetter(SEARCH_IN_BOARD_MEMBER)}
          </div>
        )}
      </MembersOfServicesPopup>
    </React.Fragment>
  );
};
const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
  members: state.opening.members,
  loginUser: state.opening.loginUser,
  openingMembers: state.opening.openingMembers,
  currentApplicant: state.opening.currentApplicant,
  applicantMembers: state.opening.applicantMembers,
  applicationId: state.opening.applicationId,
  clientId: state.opening.clientId,
});
const mapPropsToState = (dispatch: Dispatch) => ({
  removeMember: (memberID: string, applicantID: string) =>
    dispatch(removeMemberFromOpening(memberID, applicantID)),
  initMembers: (members: any) => dispatch(initMembership(members)),
});
export default connect(mapStateToProps, mapPropsToState)(ShowMember);
