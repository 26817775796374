import React, { useState, useRef, useEffect } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

interface GraphHeaderProps {
  Header: string;
  hover_title: string;
}

const GraphHeader: React.FC<GraphHeaderProps> = ({ Header, hover_title }) => {
  const [isTruncated, setIsTruncated] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (textRef.current) {
      setIsTruncated(textRef.current.scrollWidth > textRef.current.clientWidth);
    }
  }, [Header]);

  return (
    <div className="flex items-center w-full h-3">
      <div className="flex items-center w-full h-full ">
        <Tooltip title={isTruncated ? Header : ""}>
          <div
            ref={textRef}
            className="overflow-hidden mr-4 text-gray-700 text-sm truncate whitespace-nowrap"
          >
            {Header}
          </div>
        </Tooltip>
        <Tooltip title={hover_title}>
          <div style={{ display: "inline-block" }}>
            <InfoCircleOutlined style={{ cursor: "pointer" }} />
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default GraphHeader;
