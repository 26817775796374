import React from 'react';
import { Form,Typography} from 'antd';
import { FormItemProps } from 'antd/lib/form/FormItem';
const { Text } = Typography;


const FormItem: React.FC<FormItemProps> = ({children, name, label,rules }) => {
  return (
    <Form.Item name={name} labelAlign='left' label={<Text className="font-medium">{label}</Text>}
    rules={rules} >
        {children}
    </Form.Item> 
  );
};

export default FormItem;
