import React, { useEffect, useRef } from "react";
import { INPUT_PLACEHOLDER, SOCKET_CLIENT_ID } from "../../../constant";
import TextareaAutosize from "react-textarea-autosize";

// Redux stuff
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
  addList,
  attachmentLoader,
} from "../../../redux/actions/opening/action";
import OutsideClick from "../../OutsideClick";
// Api stuff
import { createList } from "../../../lib/api";
import { ListNameInputProps } from "../../../type/boardPanal";
import CloseIcon from "../../../assets/Close";
import { List } from "../../../type/type";
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";
// import useApi from "../useApi";

const ListNameInput = (props: ListNameInputProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  OutsideClick(ref, () => {
    props.click(false);
  });
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const ref1 = useRef<HTMLDivElement>(null);

  const scrollToLeft = () => {
    if (ref1.current) {
      ref1.current.scrollIntoView({ behavior: "auto" });
    }
  };
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const createNewList = (e: any) => {
    if ((e.keyCode === 13 && !e.shiftKey) || e.button == 0) {
      e.preventDefault();
      if (inputRef.current && inputRef.current.value) {
        let name = inputRef.current.value.trim();
        const newList = {
          name: inputRef.current?.value,
          opening_id: props.currentOpening.id,
          socket_client_id:sessionStorage.getItem(SOCKET_CLIENT_ID),
        };
        if (name !== "") {
          createList(newList)
            .then((list: List) => {
              props.addList(list);
              if (inputRef.current) {
                inputRef.current.value = "";
                inputRef.current.focus();
              }
              scrollToLeft();
            })
           
        } else {
          if (inputRef.current) {
            inputRef.current.select();
            inputRef.current.focus();
          }
        }
      }
    }
  };
  return (
    <React.Fragment>
      <div className="flex items-start" ref={ref1}>
        <div
          className="rounded bg-black bg-opacity-50 flex-no-shrink w-70 p-1 mr-2"
          ref={ref}
        >
          <div className="flex justify-between">
            <TextareaAutosize
              className=" p-2 h-8 w-full resize-none text-sm transition-colors bg-transparent ease-in duration-700 placeholder-white outline-none text-white"
              placeholder={INPUT_PLACEHOLDER}
              onKeyDown={(e) => createNewList(e)}
              maxLength={250}
              ref={inputRef}
            />
          </div>
          <div className="text-sm overflow-y-scroll ">
            <div className="flex mt-1 items-center cursor-pointer">
              <button
                className="submit-btn"
                onClick={createNewList}
                type="submit"
                data-testid="create-btn"
              >
                <span className="text-xs">
                  {capitalizeFirstLetter("add list")}
                </span>
              </button>
              <span
                onClick={() => props.click(false)}
                aria-hidden
                className="cursor-pointer ml-1 "
                data-testid="list-name"
              >
                <CloseIcon fill="#ffff" />
              </span>
            </div>
          </div>
        </div>
      </div>{" "}
      <div ref={messagesEndRef}></div>
    </React.Fragment>
  );
};
const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
  ws: state.opening.ws,
  clientId: state.opening.clientId,
});

const mapPropsToState = (dispatch: Dispatch) => ({
  addList: (list: any) => dispatch(addList(list)),
  attachmentLoader: () => dispatch(attachmentLoader()),
});
export default connect(mapStateToProps, mapPropsToState)(ListNameInput);
