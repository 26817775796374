// colorUtils.js
export function calculateBrightness(color: string) {
    var r, g, b, brightness
    // Convert hex color to RGB
    r = parseInt(color.substring(1, 3), 16)
    g = parseInt(color.substring(3, 5), 16)
    b = parseInt(color.substring(5, 7), 16)
    // Calculate brightness
    brightness = (r * 299 + g * 587 + b * 114) / 1000
    return brightness
}

export function determineTextColor(color: string) {
    var brightness = calculateBrightness(color)
    // Determine text color based on brightness
    return (brightness < 128) ? "#ffffff" : "#000000"
}
