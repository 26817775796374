import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { archieveUnArchiveList } from "../../../../lib/api";
import { archiveList } from "../../../../redux/actions/opening/action";
import { Opening } from "../../../../type/type";
import { SOCKET_CLIENT_ID } from "../../../../constant";
type ArchiveListType = {
  listId?: string;
  close: (e: boolean) => void;
  archiveList: (list: any) => void;
  clientId: string;
  currentOpening: Opening;
};
const ArchiveList = (props: ArchiveListType) => {
  useEffect(() => {
    const req = {
      id: props.listId,
      archive: true,
      socket_client_id:sessionStorage.getItem(SOCKET_CLIENT_ID),
      opening_id: props.currentOpening.id,
    };
    archieveUnArchiveList(req)
      .then((list: any) => {
        props.close(false);
        props.archiveList(list);
      })
     
    return () => {};
  }, []);
  return <></>;
};
const mapPropsToState = (dispatch: Dispatch) => ({
  archiveList: (list: any) => dispatch(archiveList(list)),
});
const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
  clientId: state.opening.clientId,
});
export default connect(mapStateToProps, mapPropsToState)(ArchiveList);
