import { Divider, Tooltip } from "antd";
import React, { FunctionComponent, CSSProperties } from "react";

interface GenericKpiData {
  data?: string;
  options:GenericKpiOptionData;
  type: string;
}
interface GenericKpiOptionData {
  data: string;
  last_week_data: string;
}
interface GenericKpiProps {
  data: GenericKpiData;
}

const GenericKpi: FunctionComponent<GenericKpiProps> = ({ data }) => {
  return (
    <div style={styles.container}>
      <div style={styles.value}>
        <Tooltip title={data?.data}>{data?.options.data}</Tooltip>
        </div> 
      <Divider style={{ margin: "16px" }} />
      <div style={styles.footer}>In last week : {data?.options.last_week_data}</div>
    </div>
  );
};

const styles: { [key: string]: CSSProperties } = {
  container: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "1.125rem",
  },
  value: {
    display: "flex",
    alignItems: "center",
    fontSize: "2.5rem",
    cursor: "pointer",
    height: "70%",
    width: "100%",
    fontWeight: "500",
    paddingTop: "20px",
  },
  footer: {
    display: "flex",
    fontSize: "15px",
    width: "100%",
    height: "20%",
  },
};

export default GenericKpi;
