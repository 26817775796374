// Board ActionsTypes

export const INIT_CURRUNT_BOARD = "INIT_CURRUNT_BOARD"
export const INIT_CURRUNT_TRACKER_JD = "INIT_CURRUNT_TRACKER_JD"
export const INIT_CURRUNT_TRACKER_JD_FORM = "INIT_CURRUNT_TRACKER_JD_FORM"
export const INIT_BOARDS = "INIT_BOARDS";
export const ADD_OPENING = "ADD_OPENING";
export const ADD_NEW_OPENINGS = "ADD_NEW_OPENINGS";
export const REMOVE_OPENING = "REMOVE_OPENING";
export const ADD_OPENING_FROM_WS="ADD_OPENING_FROM_WS";
export const UPDATE_OPENING_NAME = "UPDATE_OPENING_NAME";
export const UPDATE_OPENING_DESCRIPTION = "UPDATE_OPENING_DESCRIPTION";
export const INIT_OPENING_MEMBERS = "INIT_OPENING_MEMBERS";
export const ADD_MEMBER_ON_BOARD = "ADD_MEMBER_ON_BOARD";
export const REMOVE_MEMBER_FROM_OPENING = "REMOVE_MEMBER_FROM_OPENING";
export const ADD_BOARD_LISTS = "ADD_BOARD_LISTS";
export const ADD_APPLICANT = "ADD_APPLICANT";
export const REMOVE_CURRUNT_OPENING = "REMOVE_CURRUNT_OPENING";
export const UPDATE_CURRUNT_OPENING = "UPDATE_CURRUNT_OPENING"
export const REGISTER_CURRENT_ORG = "REGISTER_CURRENT_ORG";
export const ARCHIVED_APPLICANTS_OF_LIST = "ARCHIVED_APPLICANTS_OF_LIST";
export const ADD_NEW_LIST = "ADD_NEW_LIST";
export const ADD_LIST = "ADD_LIST";
export const SORTING_BOARD = "SORTING_BOARD";
export const EDIT_BOARD_ROLE = "EDIT_BOARD_ROLE";
export const CHANGE_BOARD_VISIBILITY = "CHANGE_BOARD_VISIBILITY";
export const GET_ACTIVE_BOARD = "GET_ACTIVE_BOARD";


export const ADD_CURRENT_APPLICANT = "ADD_CURRENT_APPLICANT";
export const ADD_LABELS_IN_CURRENT_APPLICANT =
  "ADD_LABELS_IN_CURRENT_APPLICANT";
export const SAVE_CARD_LABEL = "SAVE_CARD_LABEL";
export const SAVE_BOARD_LABEL = "SAVE_BOARD_LABEL";
export const SAVE_BOARD_LABEL_IN_CARD = "SAVE_BOARD_LABEL_IN_CARD";
export const REMOVE_LABEL_FROM_CARD = "REMOVE_LABEL_FROM_CARD";

export const SAVE_IMPORTED_LABELS = "SAVE_IMPORTED_LABELS";
export const GET_APPLICANT_LABELS = "GET_APPLICANT_LABELS";

export const STARRED_BOARD = "STARRED_BOARD";
export const STARRED_BOARD_PREVIEW = "STARRED_BOARD_PREVIEW";
export const ADD_CANDIDATE_IN_POOL = "ADD_CANDIDATE_IN_POOL";

// Update state
export const INIT_LISTS = "INIT_LISTS";
export const INIT_APPLICANT_LABEL = "INIT_APPLICANT_LABEL";
export const ARCHIVE_LIST = "ARCHIVE_LIST";
// current applicant
export const INIT_CURRENT_APPLICANT = "INIT_CURRENT_APPLICANT";
export const INIT_CURRENT_APPLICANT_MAILS_PREVIEW =
  "INIT_CURRENT_APPLICANT_MAILS_PREVIEW";
export const EDIT_LIST_NAME = "EDIT_LIST_NAME";
//analytics label object
export const UPDATE_ANALYTICS_LIST_LABEL_NAME ="UPDATE_ANALYTICS_LIST_LABEL_NAME";
//strore analytics label
export const INIT_ANALYTICS_LABELS="INIT_ANALYTICS_LABELS";

export const INIT_CURRENT_APPLICANT_MAIL_THREAD =
  "INIT_CURRENT_APPLICANT_MAIL_THREAD";
export const ADD_MAIL_IN_CURRENT_THREAD_OF_CARD =
  "ADD_MAIL_IN_CURRENT_THREAD_OF_CARD";
export const ADD_MAIL_ON_CURRENT_CARD = "ADD_MAIL_ON_CURRENT_CARD";

// Applicants Members

export const INIT_MEMBERS = "INIT_MEMBERS";
export const INIT_APPLICANT_MEMBERS = "INIT_APPLICANT_MEMBERS";
export const UPDATE_MEMBER = "UPDATE_MEMBER";

// Labels
export const INIT_LABELS = "INIT_LABELS";
export const INIT_BOARD_LABELS = "INIT_BOARD_LABELS";

// Comments
export const INIT_COMMENTS = "INIT_COMMENTS";
export const SAVE_COMMENT = "SAVE_COMMENT";

//activity
export const INIT_APPLICANT_ACTIVITY = "INIT_APPLICANT_ACTIVITY";
export const INIT_OPENING_ACTIVITY = "INIT_OPENING_ACTIVITY";
export const CLEAR_OPENING_ACTIVITY = "CLEAR_OPENING_ACTIVITY";

export const UPDATE_APPLICANT_DESC = "UPDATE_APPLICANT_DESC";
// export const EDIT_DESC = "EDIT_DESC";
export const EDIT_APPLICANT_NAME = "EDIT_APPLICANT_NAME";
export const CHANGE_CARD_VISIBILITY = "CHANGE_CARD_VISIBILITY";

export const MOVE_APPLICANT = "MOVE_APPLICANT";

export const UPDATE_LABEL = "UPDATE_LABEL";

export const ADD_MEMBER_ON_CARD = "ADD_MEMBER_ON_CARD";
export const ADD_CREATOR_ON_CARD = "ADD_CREATOR_ON_CARD";
export const CHANGE_STATE_OF_CLOSE_REVIEW = "CHANGE_STATE_OF_CLOSE_REVIEW";

export const REMOVE_MEMBER_FROM_CARD = "REMOVE_MEMBER_FROM_CARD";

export const COLLAPSE_LABEL = "COLLAPSE_LABEL";

export const UPDATE_ARCHIVE_STATUS = "UPDATE_ARCHIVE_STATUS";

export const REMOVE_ARCHIVED_APPLICANT = "REMOVE_ARCHIVED_APPLICANT";

export const UPDATE_BOARD_BACKGROUND_COLOR = "UPDATE_BOARD_BACKGROUND_COLOR";

export const INIT_ARCHIVED_APPLICANT = "INIT_ARCHIVED_APPLICANT";

export const SEND_BACK_ARCHIVED_ITEM = "SEND_BACK_ARCHIVED_ITEM";

export const SHOW_APPLICANT = "SHOW_APPLICANT";

export const CLOSE_APPLICANT = "CLOSE_APPLICANT";

export const SAVE_APPLICANT_TASKS = "SAVE_APPLICANT_TASKS";

export const ADD_NEW_TASK = "ADD_NEW_TASK";

export const REMOVE_TASK = "REMOVE_TASK";

export const UPDATE_TASK = "UPDATE_TASK";
export const UPDATE_COMMENT = "UPDATE_COMMENT";
export const ADD_EVENT_LISTS = "ADD_EVENT_LISTS";
export const ADD_NEW_EVENT = "ADD_NEW_EVENT";
export const ADD_NEW_RULE = "ADD_NEW_RULE";
export const UPDATE_EVENT = "UPDATE_EVENT";
export const REMOVE_EVENT = "REMOVE_EVENT";
export const MOVE_LIST = "MOVE_LIST";
export const MOVE_LIST_WS = "MOVE_LIST_WS";

export const MOVE_APPLICANTS_OF_LIST = "MOVE_APPLICANTS_OF_LIST";
export const MOVE_APPLICANT_TO_ANOTHER_OPENING =
  "MOVE_APPLICANT_TO_ANOTHER_OPENING";
export const COPY_APPLICANT_TO_ANOTHER_OPENING =
  "COPY_APPLICANT_TO_ANOTHER_OPENING";

//dnd
export const DRAG_CARD = "DRAG_CARD";
export const DRAG_LIST = "DRAG_LIST";

// copy

export const COPY_APPLICANT = "COPY_APPLICANT";

//login
export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";

export const ORG_TOKEN = "ORG_TOKEN";
// ws
export const INIT_WS = "INIT_WS";
export const SET_NEW_WS_CONNECTION = "SET_NEW_WS_CONNECTION";

//topic
export const TOPIC_SUBSCRIPTION = "TOPIC_SUBSCRIPTION";
//clientID
export const SET_CLIENT_ID = "SET_CLIENT_ID";

//attachment
export const ATTACHMENTS = "ATTACHMENTS";
export const ADD_ATTACHMENTS = "ADD_ATTACHMENTS";
export const ATTACHMENTS_LOADER = "ATTACHMENTS_LOADER";
export const DELETE_ATTACHMENT = "DELETE_ATTACHMENT";
export const UPDATE_ATTACHMENT = "UPDATE_ATTACHMENT";
export const COPY_ATTACHMENTS = "COPY_ATTACHMENTS";

export const ADD_LIST_FROM_SOCKET = "ADD_LIST_FROM_SOCKET";

//email verify
export const EMAIL_VERIFY = "EMAIL_VERIFY";

//phone verify

export const PHONE_VERIFY = "PHONE_VERIFY";

// org

export const INIT_ORG_MEMBERS = "INIT_ORG_MEMBERS";
export const SHOW_ORG = "SHOW_ORG";
//slack
export const SLACK_INTEGRATION = "SLACK_INTEGRATION";
export const RESET_SLACK_OPTIONS = "RESET_SLACK_OPTIONS";
export const INTEGRATED_SLACK_MEMBER = "INTEGRATED_SLACK_MEMBER"
export const INTEGRATE_SLACK_MEMBER = "INTEGRATE_SLACK_MEMBER";
export const DISCONNECT_SLACK_ACCOUNT_OF_MEMBER = "DISCONNECT_SLACK_ACCOUNT_OF_MEMBER";

// User Notification 
export const DOT_INITIAL_STATE="DOT_INITIAL_STATE"
export const INIT_NOTIFICATION_CENTER_UNREAD="INIT_NOTIFICATION_CENTER_UNREAD";
export const ADD_NOTIFICATION_FROM_WS="ADD_NOTIFICATION_FROM_WS";
export const UNREAD_NOTIFICATION_DOT_ADDER="UNREAD_NOTIFICATION_DOT_ADDER"
export const SHOW_DOT_OR_NOT_ON_UPDATES="SHOW_DOT_OR_NOT_ON_UPDATES"
export const UNREAD_NOTIFICATION_DOT_REMOVER="UNREAD_NOTIFICATION_DOT_REMOVER"

export const REMOVE_CARD_NOTIFICATION_DOT_OF_CARD="REMOVE_CARD_NOTIFICATION_DOT_OF_CARD";
export const REMOVE_SERVICE_NOTIFICATION_DOT="REMOVE_SERVICE_NOTIFICATION_DOT";
export const USER_NOTIFICATION_SETTINGS_INTEGRATION =
  "USER_NOTIFICATION_SETTINGS_INTEGRATION";
export const INIT_ALL_NOTIFICATIONS = "INIT_ALL_NOTIFICATIONS";
export const INIT_UNREAD_NOTIFICATIONS = "INIT_UNREAD_NOTIFICATIONS";
export const SET_NOTIFICATION_OPTIONS = "SET_NOTIFICATION_OPTIONS";
export const INIT_DASHBOARD_NOTIFICATIONS = "INIT_DASHBOARD_NOTIFICATIONS";
export const REMOVE_BOARD_NOTIFICATION_DOT_OF_BOARD="REMOVE_BOARD_NOTIFICATION_DOT_OF_BOARD";
export const CHANGE_SERVICE_DOT_OF_ENTITIES="change_service_dot_of_entities";
export const INIT_NOTIFICATIONS_DOT="INIT_NOTIFICATIONS_DOT";
export const REMOVE_NOTIFICATION_DOT_OF_ENTITY="REMOVE_NOTIFICATION_DOT_OF_ENTITY";
export const CHANGE_BOARD_ENTITIES_DOT="CHANGE_BOARD_ENTITIES_DOT";
export const INIT_ENTITY_UNREAD_NOTIFICATIONS =
  "INIT_ENTITY_UNREAD_NOTIFICATIONS";
  export const INIT_NOTIFICATIONS_INITIAL_STATE="INIT_NOTIFICATIONS_INITIAL_STATE";
export const INIT_CURRENT_CARD_WATCHER = "INIT_CURRENT_CARD_WATCHER";
export const INIT_CURRENT_BOARD_WATCHER = "INIT_CURRENT_BOARD_WATCHER";
export const INIT_ENTITY_UNREAD_NOTIFICATIONS_BOARD="INIT_ENTITY_UNREAD_NOTIFICATIONS_BOARD"

export const REMOVE_REVIEW_NOTIFICATION_DOT_OF_REVIEW="REMOVE_REVIEW_NOTIFICATION_DOT_OF_REVIEW";
export const REMOVE_MAIL_NOTIFICATION_DOT_OF_CARD="REMOVE_MAIL_NOTIFICATION_DOT_OF_CARD";
export const CHANGE_SERVICE_NOTIFICATIONS_COUNT="CHANGE_SERVICE_NOTIFICATIONS_COUNT";


export const INIT_OUTGOING_INVIATIONS = "INIT_OUTGOING_INVIATIONS";
export const ADD_INVITATION = "ADD_INVITATION";
export const WITHDRAW_INVITATION = "WITHDRAW_INVITATION";

export const INIT_INCOMING_INVIATIONS = "INIT_INCOMING_INVIATIONS";
export const ADD_INCOMING_INVITATION = "ADD_INCOMING_INVITATION";
export const CLEAR_DATA = "CLEAR_DATA";
export const CLEAR_CARD_DATA = "CLEAR_CARD_DATA";

export const UPDATE_USER = "UPDATE_USER";
export const SHOW_SEARCH = "SHOW_SEARCH";
export const UPDATE_SEARCH_FLAG = "UPDATE_SEARCH_FLAG";
//error
export const SET_ERROR = "SET_ERROR";
export const SET_SLACK_OPTIONS = "SET_SLACK_OPTIONS";

export const BOARD_SLACK_OPTIONS = "BOARD_SLACK_OPTIONS";
export const ADD_ACTIVITY = "ADD_ACTIVITY";

export const INIT_ARCHIVE_LISTS = "INIT_ARCHIVE_LISTS";

export const SEND_BACK_ARCHIVED_LIST = "SEND_BACK_ARCHIVED_LIST";
export const RESET_WS = "RESET_WS";

export const DEQUEUE_WS_REQUEST_ACTION = "DEQUEUE_WS_REQUEST_ACTION";
export const ADD_WS_REQUEST_ACTION = "ADD_WS_REQUEST_ACTION";
export const RECONNECTION_WS_REQUEST = "RECONNECTION_WS_REQUEST";
export const UPDATE_WS_REQUEST_STATUS = "UPDATE_WS_REQUEST_STATUS";
export const SUBSCRIBE = "subscribe";
export const UNSUBSCRIBE = "unsubscribe";
export const ORGANISATION = "organisation";
export const ATS_DASHBOARD = "ats"
export const OPENING = "board";
export const REQUEST_MADE = 0;
export const REQUEST_IN_PROCESS = 1;
export const REQUEST_IN_COMPLETE = 2;
export const UPDATE_ACTIVITY = "UPDATE_ACTIVITY";
export const ORG_SETTING_UPDATE = "ORG_SETTING_UPDATE";

export const CLEAR_TAB_DATA = "CLEAR_TAB_DATA";

export const FILTER = "FILTER";
export const REMOVE_FILTERS = "REMOVE_FILTERS";
export const FILTER_COUNT = "FILTER_COUNT";
export const UPDATE_ORG_INFO = "UPDATE_ORG_INFO";

// billing
export const INIT_RECENT_INVOICE = "INIT_RECENT_INVOICE";
export const INIT_PAYMENT_METHODS = "INIT_PAYMENT_METHODS";
export const UPDATE_DEFAULT_METHOD = "UPDATE_DEFAULT_METHOD";
export const ADD_PAYMENT_METHOD = "ADD_PAYMENT_METHOD";
export const ADD_BLOCK_STATUS = "ADD_BLOCK_STATUS";
export const INIT_BILLING_OPA_MEMBER = "INIT_BILLING_OPA_MEMBER";
export const ADD_MEMBER_IN_BILLING = "ADD_MEMBER_IN_BILLING";
export const UPDATE_BILLING_MEMBER_ROLE = "UPDATE_BILLING_MEMBER_ROLE";
export const REMOVE_BILLING_MEMBER = "REMOVE_BILLING_MEMBER";
export const ADD_CUSTOMER_STATUS="ADD_CUSTOMER_STATUS";
export const STORE_CUSTOMER_SELECTION_OF_PLAN="STORE_CUSTOMER_SELECTION_OF_PLAN";
export const UPDATE_CUSTOMER_STATUS="UPDATE_CUSTOMER_STATUS";
export const ADD_CURRENT_PLAN = "ADD_CURRENT_PLAN";
// Reward
export const CARD_REWARDS = "CARD_REWARDS";
export const UPDATE_CARD_REWARD = "UPDATE_CARD_REWARD";
export const ALL_REWARDS = "ALL_REWARDS";
export const CREDIT_OR_DEBIT_REWARD = "CREDIT_OR_DEBIT_REWARD";

//candidateDB
export const ADD_CANDIDATE_IN_DB = "ADD_CANDIDATE_IN_DB";
export const ADD_CANDIDATE_COMMENTS = "ADD_CANDIDATE_COMMENTS";
export const EDIT_CANDIDATE_COMMENTS = "EDIT_CANDIDATE_COMMENTS";
export const INIT_CANDIDATES_IN_REVIEW = "INIT_CANDIDATES_IN_REVIEW";
export const ADD_CDB_TABLE_HEADER = "ADD_CDB_TABLE_HEADER";
export const INIT_CURRENT_CANDIDATE_IN_REVIEW =
  "INIT_CURRENT_CANDIDATE_IN_REVIEW";
export const ADD_CANDIDATE_IN_REVIEW = "ADD_CANDIDATE_IN_REVIEW";

export const INIT_CANDIDATE_LABEL = "INIT_CANDIDATE_LABEL";
export const ADD_CANDIDATE_LABEL = "ADD_CANDIDATE_LABEL";
export const ADD_MEMBER_ON_CDB = "ADD_MEMBER_ON_CDB";
export const INIT_CDB_MEMBER = "INIT_CDB_MEMBER";
export const INIT_CDBTABLE_HEAD = "INIT_CDBTABLE_HEAD";
export const INIT_CANDIDATES = "INIT_CANDIDATES";
export const EDIT_CANDIDATE = "EDIT_CANDIDATE";
export const INIT_REVIEW_ATTACHMENTS = "INIT_REVIEW_ATTACHMENTS";
export const INIT_REVIEW_COMMENTS = "INIT_REVIEW_COMMENTS";
export const ADD_REVIEW_COMMENT = "ADD_REVIEW_COMMENT";
export const INIT_CURRENT_CANDIDATE_IN_DB = "INIT_CURRENT_CANDIDATE_IN_DB";
export const ADD_LABEL_TO_CUURENT_CANDIDATE_IN_CDB =
  "ADD_LABEL_TO_CUURENT_CANDIDATE_IN_CDB";
export const ADD_REVIEW_LABEL = "ADD_REVIEW_LABEL";
export const ADD_LABEL_TO_CANDIDATE_IN_REVIEW =
  "ADD_LABEL_TO_CANDIDATE_IN_REVIEW";
export const ADD_REVIEW_ATTACHMENT = "ADD_REVIEW_ATTACHMENT";
export const UPDATE_ATTACHMENT_OF_CANDIDATE = "UPDATE_ATTACHMENT_OF_CANDIDATE";
export const INIT_REVIEW_LABELS = "INIT_REVIEW_LABELS";
export const ADD_MEMBER_ON_REVIEW = "ADD_MEMBER_ON_REVIEW";
export const REMOVE_MEMBER_ON_REVIEW = "REMOVE_MEMBER_ON_REVIEW";
export const UPDATE_CDB_MEMBER_ROLE = "UPDATE_CDB_MEMBER_ROLE";
export const DELETE_CDB_MEMBER = "DELETE_CDB_MEMBER";
export const ADD_LABEL_ON_MULTIPLE_CANDIDATE_IN_CDB = "ADD_LABEL_ON_MULTIPLE_CANDIDATE_IN_CDB"
export const REMOVE_LABEL_FROM_TALENT_POOL_CANDIDATE="REMOVE_LABEL_FROM_TALENT_POOL_CANDIDATE"


export const ADD_LABEL_ON_MULTIPLE_REVIEWS = "ADD_LABEL_ON_MULTIPLE_REVIEWS"
export const ADD_MEMBERS_ON_MULTIPLE_REVIEWS =
  "ADD_MEMBERS_ON_MULTIPLE_REVIEWS";
export const UPDATE_COMMENT_OF_REVIEW = "UPDATE_COMMENT_OF_REVIEW";
export const ADD_CANDIDATE_ATTACHMENT = "ADD_CANDIDATE_ATTACHMENT";
export const INIT_CANDIDATE_ATTACHMENTS = "INIT_CANDIDATE_ATTACHMENTS";
export const UPDATE_CANDIDATE_LABEL = "UPDATE_CANDIDATE_LABEL";
export const UPDATE_REVIEW_LABEL = "UPDATE_REVIEW_LABEL";
export const CURRENT_CDB_ACTIVITY = "CURRENT_CDB_ACTIVITY";
export const DELETE_CANDIDATE_ATTACHMENT = "DELETE_CANDIDATE_ATTACHMENT";
export const ATS_ACTIVITY_OF_CANDIDATE = "ATS_ACTIVITY_OF_CANDIDATE";
export const REMOVE_LABEL_FROM_REVIEW = "REMOVE_LABEL_FROM_REVIEW";
export const REMOVE_LABEL_FROM_CANDIDATE = "REMOVE_LABEL_FROM_CANDIDATE";
export const CDB_REVIEW_ACTIVITY = "CDB_REVIEW_ACTIVITY";
export const REMOVE_LABEL_FROM_REVIEWS = "REMOVE_LABEL_FROM_REVIEWS";
export const CURRENT_CANDIDATE_MAILS = "CURRENT_CANDIDATE_MAILS";
export const CURRENT_CANDIDATE_MAIL_THREAD = "CURRENT_CANDIDATE_MAIL_THREAD";
export const ADD_MAIL_IN_CANDIDATE_MAIL_THREAD =
  "ADD_MAIL_IN_CANDIDATE_MAIL_THREAD";

export const INIT_CURRENT_CANDIDATE_LABELS = "INIT_CURRENT_CANDIDATE_LABELS";
// calender
export const   ADD_RRULE="ADD_RRULE";
export const REMOVE_RRULE="REMOVE_RRULE";
export const EDIT_RRULE="EDIT_RRULE";
export const INIT_RRULES="INIT_RRULES";
export const SET_CALENDER_ID = "SET_CALENDER_ID";
export const SET_CARD_CALENDAR_EVENTS = "SET_CARD_CALENDAR_EVENTS";
export const UPDATE_CARD_CALENDAR_EVENTS = "UPDATE_CARD_CALENDAR_EVENTS";
export const SET_BOARD_CAL_EVENTS = "SET_BOARD_CAL_EVENTS";
export const ADD_EVENT_ON_BOARD_CALENDER = "ADD_EVENT_ON_BOARD_CALENDER";
export const DELETE_BOARD_EVENT = "DELETE_BOARD_EVENT";
export const EDIT_BOARD_EVENT = "EDIT_BOARD_EVENT";
export const DELETE_CARD_EVENT = "DELETE_CARD_EVENT";
export const EDIT_CARD_EVENT = "EDIT_CARD_EVENT";
export const SET_CURRENT_CAL = "SET_CURRENT_CAL";
export const UPDATE_CURRENT_CALENDAR = "UPDATE_CURRENT_CALENDAR";
export const SET_CURRENT_USER_ZOOM_MEET_ACCOUNT =
  "SET_CURRENT_USER_ZOOM_MEET_ACCOUNT";
export const SET_CURRENT_CALENDAR_CONFERENCE_SETTINGS =
  "SET_CURRENT_CALENDAR_CONFERENCE_SETTINGS";
export const REMOVE_ZOOM_ACCESS = "REMOVE_ZOOM_ACCESS";

export const REMOVE_MEET_ACCESS = "REMOVE_MEET_ACCESS";
export const ADD_ZOOM_ACCESS = "ADD_ZOOM_ACCESS";
export const ADD_MEET_ACCESS = "ADD_MEET_ACCESS";

export const CHANGE_FILLEDFEEDBACKFORM_STATE =
  "CHANGE_FILLEDFEEDBACKFORM_STATE";

//Tacitmail
export const USER_MAILS = "USER_MAILS";
export const CURRENT_SELECTED_MAIL = "CURRENT_SELECTED_MAIL";
export const ADD_DOMAIN_OF_ORG = "ADD_DOMAIN_OF_ORG";
export const ADD_DOMAIN_INTEGRATION = "ADD_DOMAIN_INTEGRATION";
export const ADD_MAIL_IN_INBOX_THREAD = "ADD_MAIL_IN_INBOX_THREAD";
export const MARK_AS_READ_MAIL = "MARK_AS_READ_MAIL";
export const CHANGE_DOMAIN_STATUS = "CHANGE_DOMAIN_STATUS";

// Plugin
export const INIT_MAPPINGS = "INIT_MAPPINGS";
export const UPDATE_MAPPING = "UPDATE_MAPPING";
export const CURRENT_COMPANY = "CURRENT_COMPANY"

//JD
export const INIT_JD_MEMBER = "INIT_JD_MEMBER"
export const UPDATE_CURRENT_JD = "UPDATE_CURRENT_JD"
export const ADD_MEMBER_ON_JD = "ADD_MEMBER_ON_JD"
export const REMOVE_MEMBER_ON_JD = "REMOVE_MEMBER_ON_JD"
export const UPDATE_JD_MEMBER_ROLE = "UPDATE_JD_MEMBER_ROLE"
export const INIT_CURRENT_JD = "INIT_CURRENT_JD"
export const ADD_JD_REVIEWER = "ADD_JD_REVIEWER"
export const ADD_MEMBER_ON_COMPANY = "ADD_MEMBER_ON_COMPANY"
export const INIT_COMPANY_MEMBER = "INIT_COMPANY_MEMBER"
export const REMOVE_COMPANY_MEMBER = "REMOVE_COMPANY_MEMBER"
export const INIT_COMPANIES = "INIT_COMPANIES"
export const ADD_COMPANY = "ADD_COMPANY"
export const ADD_ASSIGNEE_TO_COMPANY = "ADD_ASSIGNEE_TO_COMPANY"
export const ADD_BOARD_TO_WORKSPACE = "ADD_BOARD_TO_WORKSPACE"
export const EDIT_WORKSPACE_DETAILS = "EDIT_WORKSPACE_DETAILS"
export const REMOVE_MEMBER_FROM_WORKSPACE = "REMOVE_MEMBER_FROM_WORKSPACE"

//CDBfilter
export const SEARCH_QUERY = "SEARCH_QUERY"
export const SEARCH_FILTER_QUERY = "SEARCH_FILTER_QUERY"


// ADD_CURRENT_ORG
export const ADD_CURRENT_ORG="ADD_CURRENT_ORG"
export const UPDATE_CURRENT_ORG_CREATION_STAGES="UPDATE_CURRENT_ORG_CREATION_STAGES"
export const INIT_CURRENT_ORG="INIT_CURRENT_ORG"
export const CHANGE_LOADER_STATUS="CHANGE_LOADER_STATUS"
export const ADD_CHAT_INTEGRATION="ADD_CHAT_INTEGRATION"
export const UPDATE_CHAT_INTEGRATION="UPDATE_CHAT_INTEGRATION"

// Add member to org
export const ADD_ORG_MEMBER="ADD_ORG_MEMBER"

// update user services
export const UPDATE_USER_SERVICES="UPDATE_USER_SERVICES"

// Ats pool
export const INIT_CURRENT_CANDIDATE_IN_ATS_POOL="INIT_CURRENT_CANDIDATE_IN_ATS_POOL"
export const   INIT_CANDIDATES_ATS_POOL="INIT_CANDIDATES_ATS_POOL"
export const INIT_CURRENT_CANDIDATE_LABELS_IN_ATS_POOL="INIT_CURRENT_CANDIDATE_LABELS_IN_ATS_POOL"
export const ADD_LABEL_TO_POOL_CANDIDATE_IN_ATS="ADD_LABEL_TO_POOL_CANDIDATE_IN_ATS"
export const REMOVE_LABEL_FROM_POOL_CANDIDATE_IN_ATS="REMOVE_LABEL_FROM_POOL_CANDIDATE_IN_ATS"
export const ADD_POOL_CANDIDATE_ATTACHMENT="ADD_POOL_CANDIDATE_ATTACHMENT"
export const ADD_ATTACHMENT_UPLOAD_STATUS="ADD_ATTACHMENT_UPLOAD_STATUS"
export const ADD_CDB_ATTACHMENT_UPLOAD_STATUS="ADD_CDB_ATTACHMENT_UPLOAD_STATUS"

export const  ADD_NEW_POOL_CANDIDATE_ATTACHMENT="ADD_NEW_POOL_CANDIDATE_ATTACHMENT"
export const REMOVE_LABEL_FROM_POOL_CANDIDATE="REMOVE_LABEL_FROM_POOL_CANDIDATE"
export const ADD_LABELS_ON_MULTIPLE_ATS_POOL_CANDIDATES='ADD_LABELS_ON_MULTIPLE_ATS_POOL_CANDIDATES'

export const UPDATE_ORG_SETTING_DETAILS = 'UPDATE_ORG_SETTING_DETAILS'


//candidate source
export const INIT_SOURCES = 'INIT_SOURCES'

//job platform
export const INIT_JOB_PLATFORM = 'INIT_JOB_PLATFORM'

export const APPLICANT_STATUS_FOR_TRACKER = 'APPLICANT_STATUS_FOR_TRACKER'

export const SET_RESUME_RELEVANCY_FILTER_NAME_AND_COUNT = 'SET_RESUME_RELEVANCY_FILTER_NAME_AND_COUNT'
export const SET_VIEW_TYPE="SET_VIEW_TYPE"

export const SET_USER_NOTIFICATION_TIMEZONE = 'SET_USER_NOTIFICATION_TIMEZONE'


