import React from "react";
import BoardActivity from "../../../sidePanal/navigationSlider/activity/BoardActivity";

const ActivityIndex = () => {
  return (
    <div className="   h-full flex flex-col">
      {/* <TabAction>
        <></>
      </TabAction> */}
      <div className="px-4 pb-4">
        <div className=" bg-black  bg-opacity-50  flex justify-end items-center ">
         
        </div>
        <div
          className=""
          style={{
            height: "calc(-118px + 100vh)",
          }}
        >
          <div className="tracker-jd-tab  w-full  h-full  bg-white rounded border flex   justify-center py-4 overflow-y-scroll">
            <BoardActivity />{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivityIndex;
