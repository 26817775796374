import { CloseOutlined, EditOutlined, SendOutlined } from "@ant-design/icons";
import { Button, Input } from "antd";
import React, { useState } from "react";
import { generateJDUsingGpt } from "../../../lib/api";
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";
import GPT from "../../../assets/GPT";
import { connect } from "react-redux";
const { TextArea } = Input;

// type of user input while creating job post with AI
interface Message {
  text: string;
  editable: boolean;
  edited: boolean; 
}

type ChatboxProps = {
  handleChatBoxFromParent: (value: boolean) => void;
  getJobDescription: (description: string) => void;
  title: string;
  handleLoading: (value: boolean) => void;
  getDisableState : (value: boolean) =>void;
  currentOrg?:any;
  companyDes?:string;
};

const Chatbox = (props: ChatboxProps) => {
  const [currentMessage, setCurrentMessage] = useState("");
  const [messages, setMessages] = useState<Message[]>([]);
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [disableButton, setDisableButton] = useState(false);
  const [chatVisibility, setChatVisibility] = useState(true);

  //after click on edit message the function will call
  // and clicked message will be in input for update
  const handleUserInput = () => {
    if (currentMessage.trim() !== "") {
      if (editIndex !== null) {
        const updatedMessages = [...messages];
        updatedMessages[editIndex].text = currentMessage;
        updatedMessages[editIndex].editable = false;
        updatedMessages[editIndex].edited = true;
        setMessages(updatedMessages);
        callApi(currentMessage);
        setEditIndex(null);
      } else {
        setMessages([...messages, { text: currentMessage, editable: false,edited:false }]);
        callApi(currentMessage);
      }
      setCurrentMessage("");
    }
  };

  const handleKeyPress = (e:any) => {
    if ((e.key === 'Enter' && (e.metaKey || e.ctrlKey))) {
      e.preventDefault(); // Prevents adding a new line
      handleUserInput();
    }
  };

  // calling GPT api
  const callApi = (messageText: string) => {
    let JDReq = {
      service: "email",
      purpose: "email",
      user_inputs: {
        subject: props.title,
        additional_info: props.companyDes,
        user_purpose: messageText,
        company:props.currentOrg.display_name,
      },
      threadId: "00000000-0000-0000-0000-000000000000",
    };
    setDisableButton(true);
    props.getDisableState(true);
    props.handleLoading(true);
    generateJDUsingGpt(JDReq)
      .then((res: any) => {
        props.getJobDescription(res.response.message);
        props.handleLoading(false);
        setDisableButton(false);
        props.getDisableState(false);
      })
      .catch((error) => {
        console.log(error);
        props.handleLoading(false);
        setDisableButton(false);
        props.getDisableState(false);
      });

  };

  // set edit click message index
  const handleEdit = (index: number) => {
    setEditIndex(index);
    setCurrentMessage(messages[index].text);
  };

  // after click on close button hiding the chatBox
  const handleChatBoxVisibility = () => {
    setChatVisibility(false);
    props.handleChatBoxFromParent(false);
  };

  return (
    <>
      {/* {chatVisibility && ( */}
      <div
        className={` sm:w-full md:w-4/6  ai-chatbox ${
          chatVisibility
            ? "animate-error_prompt_slide_in"
            : " animate-error_prompt_slide_out hidden "
        }`}
        style={{
          position: "relative",
          zIndex: 1,
          background: "white",
          backdropFilter: "none",
          transition: "transform 270ms ease",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          borderBottom: "1px solid rgba(0, 0, 0, 0.10)",
          borderLeft:"1px solid rgba(0, 0, 0, 0.10)",
          borderBottomRightRadius: "4px",
          borderTopRightRadius: "4px",
        //   height: "calc(-68px + 85vh)"
        height:'100%',
        maxWidth:'521.2px'
        }}
      >
        <div
          className="text-black w-full  p-1 py-1.5 rounded-tr"
          style={{ backgroundColor: "#F0EFFE", boxShadow:
          "var(--ds-shadow-raised,0 1px 1px #091e4240,0 0 1px #091e424f)", }}
        >
          <div className="flex justify-between px-3">
            <div className="flex items-center space-x-2 text-tacitbase-secondary3 font-medium text-sm">
              {" "}
              <div>
                {" "}
                <GPT fill="#4B48A5" />
              </div>
              <div className="">Generate with AI</div>
            </div>
            <CloseOutlined
              style={{ color: "#4B48A5" }}
              onClick={handleChatBoxVisibility}
            />
          </div>
        </div>
        <div
          className="p-4"
          style={{ flex: 1, paddingLeft: "10px", paddingRight:'10px',marginTop:"2px",paddingBottom:'10px', overflowY: "auto" }}
        >
          {messages.map((message: Message, index: number) => (
            <>
            <div key={index} className="w-full max-w-full flex justify-end items-end pl-2 space-x-2">
              <div
                className="h-auto text-black flex-wrap"
                style={{
                  borderRadius: "8px",
                  padding: "10px",
                  boxShadow:
                  "var(--ds-shadow-raised,0 1px 1px #091e4240)",
                  backgroundColor: "#F0EFFE",
                  maxWidth:'400px'
                }}
              >
                {message.text}
              </div>
              <Button type="text" size="small" icon={<EditOutlined onClick={() => handleEdit(index)} />}/>
            </div>
            <div className="w-full max-w-full flex justify-end items-end mb-4 pr-8">
            {message.edited && (
                <div className="text-gray-500 text-xs">edited</div>
              )}
              </div>
            </>
          ))}
        </div>
        <label className="pl-4 pb-1">
          <span className="text-red-400">*</span>
          {capitalizeFirstLetter("instructions")}
        </label>
        <div
          className="px-4"
          style={{
            position: "relative",
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <TextArea
            className="w-full mt-1 rounded "
            rows={3}
            autoSize={{ minRows: 3, maxRows: 6 }}

            maxLength={250}
            value={currentMessage}
            style={{
              resize: "none",paddingRight:55,
              marginBottom:"15px",
            }} onKeyDown={handleKeyPress}
            placeholder="Please provide a brief description of the email's purpose."
            onChange={(e) => setCurrentMessage(e.target.value)}
          />
          <SendOutlined  size={10}
            className="bg-green-600 rounded text-white p-2" 
            style={{ 
              position: "absolute",
              right: "25px",
              fontSize: "10px",
              cursor: "pointer",bottom:25
            }}
            onClick={handleUserInput}
            disabled={disableButton}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
    currentOrg: state.opening.currentOrg,
    companyDescription:state.opening.applicationDes,
  });
export default connect(mapStateToProps)(Chatbox);
