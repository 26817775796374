import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useState } from "react";

import {
  currentApplicant,
  GetOpening,
} from "../../../../type/type";
import { EventAttachment } from "../../../../lib/apiRes";
import AttachmentIcon from "../../../../assets/Attachment";
import { capitalizeFirstLetter } from "../../../../utils/capitalizeFirstLetter";
import { MAX_ATTACHMENT_SIZE } from "../../../../constant"

type AddAttachmentProps = {
  currentOpening: GetOpening;
  setPrevAttachment: (arr: any) => void;
  removeAttachment: (id: string) => void;
  currentApplicant: currentApplicant;
  prevAttachments?: EventAttachment[];
  refer: any;
  setFileName:(name: any) => void;
  file?: (file: any) => void;
  clientId: string;
};
const AttachmentInEvent = (props: AddAttachmentProps) => {
  // const [fileName, setFileName] = useState<string[]>([]);


  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const handleClick = (e: any) => {
    if (props.refer.current && !props.refer.current.contains(e.target)) {
      return;
    }
  };
  let fileInput = useRef<HTMLInputElement>(null);
  const [file_Exceed, isExceed] = useState(false);

  const onFileChange = (file: any) => {
    if (file.target.files[0].size > 10000000) {
      isExceed(true);
    } else {
      isExceed(false);

      for (let i = 0; i < file.target.files.length; i++) {
        props.file?.((obj:any)=>[...obj, file.target.files[i]])
        props.setFileName((obj:any[]) => [...obj, file.target.files[i].name]);
      }
    }
  };

  return (
    <React.Fragment>
      <div className="w-full flex flex-col space-y-2 " ref={props.refer}>
        {file_Exceed && (
          <div className="bg-red-50 p-4 mx-2">
            {MAX_ATTACHMENT_SIZE}
          </div>
        )}
        <div className="">
          <button
            className="bg-gray-200 hover:bg-gray-200      bg-opacity-60 py-1.5 px-3 text-xs font-medium rounded align-middle flex items-center "
            data-testid="testBtn"
            id="Attachments"
          >
            <input
              type="file"
              ref={fileInput}
              data-testid="input-attachment"
              style={{ display: "none" }}
              onChange={(file: any) => onFileChange(file)}
              accept="application/pdf,application/vnd.ms-excel"
              multiple
            />
            <div
              className="flex items-center justify-center space-x-1"
              onClick={() => fileInput.current?.click()}
              data-testid="add-attachment"
              aria-hidden
            >
              <AttachmentIcon />
              <div>{capitalizeFirstLetter("Add Attachments")} </div>
            </div>
          </button>
        </div>
        {/* {fileName.length > 0
          ? fileName.map((file: string, i: number) => (
              <div
                className=" input-box  h-max   flex items-center justify-between space-x-1 "
                key={i}
              >
                {" "}
                <div className=" truncate   ">{file} </div>
                <div>
                  {" "}
                  <button onClick={()=>removeFile(file)}>
                    <CloseIcon size="9" />
                  </button>
                </div>
              </div>
            ))
          : null} */}
       
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (state: any) => ({
  currentApplicant: state.opening.currentApplicant,
  clientId: state.opening.clientId,
  currentOpening: state.opening.currentOpening,
});

export default connect(mapStateToProps)(AttachmentInEvent);
