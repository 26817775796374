import { Domain, MailPreview, SendMail } from "../../type/type";
import { currentMailThread } from "./initialState";

export function user_mails_preview(state: any, action: any) {
    let mailPreviews: { [key: string]: MailPreview } = {}
    action.data.length > 0 ?
        action.data.map((mail: MailPreview) => {
            mailPreviews[mail.message.id] = mail
        }) : []
    return {
        ...state,
        userMails: {
            ...state.userMails,
            [action.tab]: {
                ...state.userMails[action.tab], // Keep the existing data for the tab
                [action.currentPage]: mailPreviews, // Update the current page data
                total_count: action.total_count !== undefined 
                    ? action.total_count 
                    : (state.userMails[action.tab]?.total_count || 0), // Keep existing total_count if not provided
                    current_page: action.currentPage ||"1" , // Store the current page number
            },
        },
        currentMailThread: currentMailThread,

    };
}
export function current_mail_thread(state: any, action: any) {

    return {
        ...state,
        currentMailThread: {
            [action.currentMailId]: { ...action.data }
        }
    };
}
export function add_mail_in_mail_thread(state: any, action: { data: SendMail, selectedMailId: string }) {

    return {
        ...state,
        currentMailThread: state.currentMailThread[action.selectedMailId] ? {
            [action.selectedMailId]: {
                ...state.currentMailThread[action.selectedMailId],
                messages: [...state.currentMailThread[action.selectedMailId].messages, ...action.data.mails.success]
            }
        } : {}
    };
}
export function current_candidate_mail_thread(state: any, action: any) {

    return {
        ...state,
        currentCandidateMailThread: {
            [action.currentMailId]: { ...action.data }
        }
    };
}

export function current_candidate_mails(state: any, action: any) {
    let mailPreviews: { [key: string]: MailPreview } = {}
    action.data.length > 0 ?
        action.data.map((mail: MailPreview) => {
            mailPreviews[mail.message.id] = mail
        }) : []
    return {
        ...state,
        currentCandidateMails: {
            [action.currentCandidateId]: mailPreviews
        }
    };
}
export function add_mail_in_current_candidate_mail_thread(state: any, action: { data: SendMail }) {

    return {
        ...state,
        currentCandidateMails: state.currentCandidateMails[action.data.mails.success[0].id] ? {
            [action.data.mails.success[0].id]: {
                ...state.currentCandidateMails[action.data.mails.success[0].id],
                messages: [...state.currentCandidateMails[action.data.mails.success[0].id].messages, ...action.data.mails.success]
            }
        } : {}
    };
}
export function add_domain_of_org(state: any, action: { domainRes: Domain }) {

    return {
        ...state,
        defaultDomain: {
            [action.domainRes.id]: {
                ...action.domainRes
            }
        }
    };
}
export function add_domain_integration(state: any, action: any) {

    return {
        ...state,
        defaultDomain: {
            [action.domainRes.id]: {
                ...state.defaultDomain.action.domainRes.id,
                integrations: action.integrations
            }
        }
    };
}
export function change_domain_status(state: any, action: any) {

    return {
        ...state,
        defaultDomain: {
            [action.data]: state.defaultDomain[action.data] ? {
                ...state.defaultDomain[action.data],
                status: "ACTIVE"
            } : state.defaultDomain
        }
    };
}
export function mark_as_read_mail(state: any, action: any) {
    return {
        ...state,
        userMails: {
            [action.tab]: {
                ...state.userMails[action.tab],
                [action.currentPage]: {
                    ...state.userMails[action.tab][action.currentPage],
                    [action.mailid]: {
                        ...state.userMails[action.tab][action.currentPage][action.mailid],
                        message: {
                            ...state.userMails[action.tab][action.currentPage][action.mailid].message,
                            read: true
                        }
                    }
                },
                total_count:state.userMails[action.tab].total_count - 1,
            }
        }
    };
}