import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Applicant, orgMember, Scope } from "../../../../type/type.d";
import { SecondaryButton } from "../../../../ant/Button";
import { Overlay } from "../../../hook/Overlay";
import CardEventPopupIndex from "../../../calender/cardScheduler/CardEventPopupIndex";
import { ScheduleOutlined } from "@ant-design/icons";
import { getCardEvents } from "../../../../lib/api";
import { useParams } from "react-router-dom";
import { Dispatch } from "redux";
import { setCardCalEvents } from "../../../../redux/actions/opening/action";
import { Event } from "../../../../lib/apiRes";
import moment from "moment";
type CardEventProps = {
  currentApplicant: Applicant;
  cardEvents: Event[];
  setCardEvents(events: Event[]): void;
  members: orgMember;
};
const CardEvent = (props: CardEventProps) => {
  const params: { card_id: string } = useParams();
  const [addEventPopup, setAddEventPopup] = useState(false);
  const [editEventPopup, setEditEventPopup] = useState(false);
  const [calenderEvents, setCalenderEvents] = useState<Event[]>();
  const [selectedEvent, setSelectedEvent] = useState("");
  useEffect(() => {
    // fetching card calendar events
    getCardEvents(params.card_id).then((res) => {
      res.Card_Events
        ? props.setCardEvents(res.Card_Events)
        : props.setCardEvents([]);
    });
  }, [params.card_id]);
  useEffect(() => {
    setCalenderEvents(Object.values(props.cardEvents));
  }, [props.cardEvents]);
  return (
    <>
      {props.currentApplicant ? (
        !props.currentApplicant.archive ? (
          <div>
            <SecondaryButton
              className="w-full flex truncate justify-start"
              onClick={() => {
                setAddEventPopup(true);
              }}
              icon={<ScheduleOutlined />}
            >
              Schedule interview
            </SecondaryButton>{" "}
            <div className="flex-col mt-3 flex space-y-2">
              {calenderEvents ? (
                calenderEvents.map((event: any, i: number) => {
                  return (
                    <div
                        aria-hidden
                        style={{
                          backgroundColor:
                            moment(new Date(event.start_date)).format("") <
                            moment(new Date().getTime()).format("")
                              ? "rgb(15, 10, 13,0.3)"
                              : event.color,
                        }}
                        className={`flex flex-col rounded text-xs items-start justify-center px-3 py-1.5 space-y-0  text-white text-opacity-90`}
                        key={i}
                        onClick={() => (
                          setSelectedEvent(event.id), setEditEventPopup(true)
                        )}
                      >
                     <div className="overflow-hidden whitespace-nowrap w-full capitalize">
                          {event.summary}
                        </div>
                        <div>
                          {moment(new Date(event.start_date)).format(
                            "DD MMM YYYY - h:mm a"
                          )}
                        </div>
                    </div>
                  );
                })
              ) : (
                <></>
              )}
            </div>
            <div>
              {editEventPopup ? (
                <Overlay
                  onClose={() => {
                    setEditEventPopup(false);
                  }}
                  scope={Scope.Popover}
                >
                  <CardEventPopupIndex
                    type="edit"
                    card_id={props.currentApplicant.id}
                    event_id={selectedEvent}
                    click={(e: boolean) => setEditEventPopup(e)}
                  />
                </Overlay>
              ) : null}
            </div>
          </div>
        ) : null
      ) : null}
      {addEventPopup ? (
        <CardEventPopupIndex
          click={(e: boolean) => setAddEventPopup(e)}
          type="create"
        />
      ) : null}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  currentApplicant: state.opening.currentApplicant,
  clientId: state.opening.clientId,
  roles: state.opening.roles,
  cal_id: state.opening.calendar.currentCalenderId,
  cardEvents: state.opening.calendar.currentCardEvents,
  boardEvents: state.opening.calendar.currentBoardEvents,
  currentApplicantRewards: state.opening.currentApplicantRewards,
  members: state.opening.members,
});
const mapPropsToState = (dispatch: Dispatch) => ({
  setCardEvents: (events: Event[]) => dispatch(setCardCalEvents(events)),
});

export default connect(mapStateToProps, mapPropsToState)(CardEvent);
