import React from "react";
const EditIcon = (props: any) => {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Edit</title>
      <g
        id="Icons-/-16x16-/-Edit"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M7.5,0 C7.77614237,-5.07265313e-17 8,0.223857625 8,0.5 C8,0.776142375 7.77614237,1 7.5,1 L7.5,1 L2,1 C1.44771525,1 1,1.44771525 1,2 L1,2 L1,14 C1,14.5522847 1.44771525,15 2,15 L2,15 L14,15 C14.5522847,15 15,14.5522847 15,14 L15,14 L15,8.5 C15,8.22385763 15.2238576,8 15.5,8 C15.7761424,8 16,8.22385763 16,8.5 L16,8.5 L16,14 C16,15.1045695 15.1045695,16 14,16 L14,16 L2,16 C0.8954305,16 1.3527075e-16,15.1045695 0,14 L0,14 L0,2 C-1.3527075e-16,0.8954305 0.8954305,2.02906125e-16 2,0 L2,0 Z M15.295689,0.767766953 C16.2719997,1.74407768 16.2719997,3.32699013 15.295689,4.30330086 L8.68696025,10.9120296 L2.56776695,13.495689 L5.15142635,7.37649572 L11.7601551,0.767766953 C12.7364658,-0.208543776 14.3193783,-0.208543776 15.295689,0.767766953 Z M5.80065916,8.24329984 L4.33553391,11.7279221 L7.82015612,10.2627968 L5.80065916,8.24329984 Z M12.4672619,1.47487373 L6.45685425,7.48528137 L8.57817459,9.60660172 L14.5885822,3.59619408 C15.1743687,3.01040764 15.1743687,2.06066017 14.5885822,1.47487373 C14.0027958,0.889087297 13.0530483,0.889087297 12.4672619,1.47487373 Z"
          id="Combined-Shape"
          fill={`${props.fill ? props.fill : "#161E25"}`}
        ></path>
      </g>
    </svg>
  );
};
export default EditIcon;
