import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { CurrentCandidateInPool } from "../../../../../redux/reducers/initialState";
import { Button, Dropdown, Menu, Skeleton, Space,  Tag, Typography } from "antd";
import { EllipsisOutlined, FilePdfOutlined } from "@ant-design/icons";
import { Attachment } from "../../../../../type/type";
import { ATTACHMENTS, CANDIDATES } from "../../../../../constant";
import { PDFRenderer } from "../../../../card/PDFViewer";
import { getAttachments } from "../../../../../lib/api";
import { Dispatch } from "redux";
import { addAttachmentToCurrentCandidateInAtsPool } from "../../../../../redux/actions/opening/action";
import DownloadFile from "../../../../CDB/AttachmentAction/DownloadAttachment";
import DeleteCandidateAttachment from "../../../../CDB/AttachmentAction/DeleteAttachment";
import EditCandidateAttachment from "../../../../CDB/AttachmentAction/EditAttachment";
import OpenFileInNewTab from "../../../../CDB/AttachmentAction/OpenFileInNewTab";
import { useParams } from "react-router-dom";
const { Text } = Typography;

type candidateInPoolAttachmentsProps = {
  currentCandidateInPool: CurrentCandidateInPool;
  addAttachmentToCurrentCandidateInAtsPool: (attachments: Attachment[]) => void;
};
const CandidateInPoolAttachments = (props: candidateInPoolAttachmentsProps) => {
  const params: { board_id: string; candidate_id: string; tabname: string } =
    useParams();
  const [attachments, setAttachments] = useState<Attachment[]>([
    {
      id: "",
      name: "",
      objectKey: "",
      modelName: "",
      recordID: "",
      presignedLink: "",
      created_at: "",
      updated_at: "",
      opening_id: "",
      converted_presigned_link:"",
      parent:"",
      converted_object_key:"",
    },
  ]);
  const [selectedCandidateAttachement, setSelectedAttachment] =
    useState<Attachment>({
      id: "",
      name: "",
      objectKey: "",
      modelName: "",
      recordID: "",
      presignedLink: "",
      created_at: "",
      updated_at: "",
      opening_id: "",
      converted_presigned_link:"",
      parent:"",
      converted_object_key:"",
    });
  const onCloseViewer = () => {
    setSelectedAttachment({
      id: "",
      name: "",
      objectKey: "",
      modelName: "",
      recordID: "",
      presignedLink: "",
      created_at: "",
      updated_at: "",
      opening_id: "",
      converted_presigned_link:"",
      parent:"",
      converted_object_key:"",
    });
  };

  const handleSelectedAttachment = (attachment: Attachment) => {
    setSelectedAttachment(attachment);
  };
  if (!props.currentCandidateInPool?.attachments) return null;
  useEffect(() => {
    if(params.candidate_id){
    getAttachments(CANDIDATES,params.candidate_id).then(
      (res) => {

        props.addAttachmentToCurrentCandidateInAtsPool(res);
      }
    );
  }}, [params.candidate_id]);

  useEffect(() => {
    setAttachments(props.currentCandidateInPool?.attachments);
  }, [props.currentCandidateInPool?.attachments]);

  const renderMenu = (attachment: Attachment) => (
    <Menu
      onClick={(e) => {
        e.domEvent.stopPropagation();
        e.domEvent.preventDefault(); // Additional prevention of default behavior
      }}
    >
      <div
        className="flex flex-col items-start space-y-2 p-2"
        role="button"
        tabIndex={0}
        onClick={(e) => e.stopPropagation()}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            e.stopPropagation();
          }
        }}
      >
        {" "}
        <div>
          <DownloadFile attachment={attachment} />
        </div>
        <div>
          {""} <EditCandidateAttachment attachment={attachment} />
        </div>
        <div>
          <DeleteCandidateAttachment attachment={attachment} />
        </div>
        <div>
          <OpenFileInNewTab attachment={attachment} />
        </div>
      </div>
    </Menu>
  );
  
  return (
    <div>
      <>
      {attachments.length > 0 && (
          <Space direction="vertical" className="w-full">
            <Text strong>{ATTACHMENTS}</Text>
            <div className="flex flex-wrap gap-4 pt-2 w-full">
              {attachments.map((attachment: Attachment) => (
                <Tag
                  className="cursor-pointer flex items-center border border-black border-opacity-5 p-2 h-10"
                  style={{ width: "47%" }}
                  bordered={false}
                  key={attachment.id}
                  onClick={() => handleSelectedAttachment(attachment)}
                >
                  <FilePdfOutlined style={{ fontSize: "22px" }} />
                  <Text
                    ellipsis={{ tooltip: attachment.name }}
                    style={{ width: "100%" }}
                  >
                    {attachment.name}
                  </Text>
                  {/* <DownloadFile
                                      attachment={attachment}
                                    /> */}
                  <Dropdown
                    overlay={renderMenu(attachment)}
                    trigger={["click"]}
                  >
                    <Button
                      type="text"
                      tabIndex={-1}
                      icon={<EllipsisOutlined />}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                    />
                  </Dropdown>
                </Tag>
              ))}
            </div>
          </Space>
        )}
        {selectedCandidateAttachement.id.length > 0 && (
          <PDFRenderer
            title={selectedCandidateAttachement.name}
            attachmentOfwhom={
              props.currentCandidateInPool.candidate.first_name +
              " " +
              props.currentCandidateInPool.candidate.last_name
            }
            onCloseViewer={onCloseViewer}
            url={selectedCandidateAttachement.converted_presigned_link}
          ></PDFRenderer>
        )}
          {props.currentCandidateInPool.newAttachmentUploader?.fileList?.length > 0 && (
          <div className="flex flex-wrap gap-4 pt-2 w-full">
          {[
            ...Array(
              props.currentCandidateInPool.newAttachmentUploader
                ?.fileList?.length
            ),
          ].map((_, index) => (
            <Tag
              key={index}
              style={{ width: "47%" }}
              className="cursor-pointer w-full flex items-center border border-black border-opacity-5 p-2 h-10"
              bordered={false}
            >
              <Skeleton.Avatar active shape="square" size="small" />
              <Skeleton.Input
                active
                size="default"
                style={{ width: "100%",marginLeft:'4px' }}
              />
            </Tag>
          ))}
        </div>
      )}
       
      </>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  currentCandidateInPool: state.opening.currentCandidateInPool,
});

const mapPropsToState = (dispatch: Dispatch) => ({
  addAttachmentToCurrentCandidateInAtsPool: (attachments: Attachment[]) =>
    dispatch(addAttachmentToCurrentCandidateInAtsPool(attachments)),
});

export default connect(
  mapStateToProps,
  mapPropsToState
)(CandidateInPoolAttachments);
