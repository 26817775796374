import React from "react";

const Image = (props: any) => {
  return (
    <>
      <img
        src={props.src ? props.src : null}
        alt=""
        title={props.title ? props.title : null}
        className={`${
          props.size ? `h-${props.size} w-${props.size}` : "h-7 w-7"
        } ${
          props.style ? props.style : null
        } inline rounded object-scale-down `} 
      />
    </>
  );
};
export default Image;
