import React, { useState } from "react"
import parse from "html-react-parser"
import { SHOW_LESS, SHOW_MORE } from "../../../constant"
type ExpandCollapsDescriptionType = {
    data: string
}
const ExpandCollapsDescription = (props: ExpandCollapsDescriptionType) => {
    const [showMore, isShowMoreTrue] = useState(false)
    const stripHtmlTags = (html: string) => {
        const encoder = new TextEncoder()
        const bytes = encoder.encode(html);

        return bytes
    }

   
    const visibleLength = stripHtmlTags(props.data).length;
    
    return (
        <><div
            className={`${visibleLength < 100
                ? "h-auto"
                : visibleLength > 100 && !showMore
                    ? "h-24 overflow-hidden"
                    : "h-auto "
                } leading-5 trix-content`}

        >
            {parse(props.data)}
        </div>
            {visibleLength > 100 ? (               
                <button
                    className=" underline text-tacitbase-secondary3 opacity-80"
                    onClick={
                        showMore
                            ? () => isShowMoreTrue(false)
                            : () => isShowMoreTrue(true)
                    }
                >
                    {showMore ? SHOW_LESS : SHOW_MORE}
                </button>
            ) : null}</>
    )
}
export default ExpandCollapsDescription
